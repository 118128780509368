import React, { useCallback, useEffect, useMemo } from 'react';
import FlexLayoutReact, {
  TabNode,
  Action,
  Node,
  Actions,
  BorderNode,
  TabSetNode,
} from 'flexlayout-react';
import useFlexLayoutStyles from './styles';
import ComponentsDrawer from './ComponentsDrawer';
import ExternalWindows from './ExternalWindows';
import { useFlexLayoutContext } from './FlexLayoutContext';
import InternalWindowRefWrapper from './InternalWindowRefWrapper';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSidebarData,
  setSidebarWidth,
} from 'store/actions/flexLayoutActions';
import useDynamicIntl from 'hooks/useDynamicIntl';
import { updatePreferences } from 'store/actions/preferencesActions';
import { getSidebarWidth } from 'store/selectors/flexLayoutSelectors';
import Tooltip from '../Tooltip';
import { useIntl } from 'react-intl';
import { useConfirmationModalContext } from 'contexts/ConfirmationModalContext';
import { CONTENT_ID } from 'utils/elementsIds';
import { useLocation } from 'react-router';
import { checkIsVisibleComponent } from './utils';
import { blockedWindows } from './consts';
import {
  CONTENT_TESTID,
  OPEN_EXTERNAL_WINDOW_TESTID,
  SIDE_PANEL_TAB_TESTID,
} from 'utils/testIds';
import FlexLayoutWindows from '../../../utils/Enums/FlexLayoutWindows';
import TabCloseIcon from './components/TabCloseIcon';
import { MappedObjectShouldBeDisplayed } from 'contexts/types';
import useKeepActiveWindow from './useKeepActiveWindow';
import { PreferencesTypes } from 'utils/types/api/preferences.types';
import { useHistory } from 'react-router-dom';
import { SelectOutlined } from '@ant-design/icons';

const FlexLayout: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const classes = useFlexLayoutStyles({});
  const dynamicIntl = useDynamicIntl();
  const intl = useIntl();
  const {
    model,
    flexLayoutMethods,
    globalMethods,
    externalWindowsMethods,
    availableComponents,
    isComponentOpen,
  } = useFlexLayoutContext();
  const { setLayoutRef, onRemoveTab } = flexLayoutMethods;
  const { onSelectTab } = useKeepActiveWindow();
  const history = useHistory();

  // Listen on path change and close all components that are not visible.
  // Issue happens across all panels which aren't using useSelectedResource hook.
  // Described under ticket id 36616.

  useEffect(() => {
    const unlisten = history.listen(() => {
      let hasChanged = false;
      Object.entries(availableComponents).forEach(
        ([componentKey, { availablePaths }]) => {
          if (
            !checkIsVisibleComponent(
              availablePaths,
              history.location.pathname
            ) &&
            isComponentOpen(componentKey as FlexLayoutWindows)
          ) {
            globalMethods.closeComponent(componentKey as FlexLayoutWindows);
            hasChanged = true;
          }

          if (hasChanged) {
            dispatch(
              updatePreferences(PreferencesTypes.LayoutPreferences, {
                flexLayoutJSONModel: model.toJson(),
              })
            );
          }
        }
      );
    });
    return () => {
      unlisten();
    };
  }, [
    availableComponents,
    dispatch,
    globalMethods,
    history,
    history.location.pathname,
    isComponentOpen,
    model,
  ]);
  const {
    closeExternalComponent,
    reatachExternalWindow,
    moveTabToExternalWindow,
    externalComponents,
  } = externalWindowsMethods;

  const {
    shouldBeDisplayed = {} as MappedObjectShouldBeDisplayed,
    storedModalFunctions,
  } = useConfirmationModalContext();

  const filtersSidebarWidth = useSelector(getSidebarWidth);

  const { pathname } = useLocation();

  const {
    globalMethods: { focusComponent },
  } = useFlexLayoutContext();

  const onMouseEnter = useCallback(
    (windowId: string) => {
      if (windowId) focusComponent(windowId as FlexLayoutWindows);
    },
    [focusComponent]
  );

  const factory = useCallback(
    (node: TabNode) => {
      const componentName = node.getComponent();
      const key = node.getId();

      if (!componentName) {
        return undefined;
      }

      // this is special node without tab strip for displaying main page content
      if (componentName === 'children') {
        return (
          <div
            id={CONTENT_ID}
            className={classes.childrenWrapper}
            data-testid={CONTENT_TESTID}
            onMouseEnter={() => onMouseEnter(key)}
          >
            {children}
          </div>
        );
      }

      const { Component, availablePaths } =
        availableComponents[componentName] || {};

      return Component ? (
        <InternalWindowRefWrapper
          {...{
            isVisible: checkIsVisibleComponent(availablePaths, pathname),
            onForceClose: () => onRemoveTab(key),
            onMouseEnter: () => onMouseEnter(key),
          }}
        >
          <Component />
        </InternalWindowRefWrapper>
      ) : undefined;
    },
    [
      availableComponents,
      children,
      classes.childrenWrapper,
      onMouseEnter,
      onRemoveTab,
      pathname,
    ]
  );

  const onRenderTabSet = (
    tabSetNode: TabSetNode | BorderNode,
    renderValues: {
      headerContent?: React.ReactNode;
      buttons: React.ReactNode[];
    }
  ) => {
    const node = tabSetNode.getSelectedNode();
    if (!node) {
      return;
    }

    const tabSetNodeId = tabSetNode.getId();
    const id = 'tab_settings_popover_' + tabSetNodeId;

    const selectedNode = tabSetNode.getSelectedNode();

    if (selectedNode instanceof TabNode && !selectedNode.isEnableFloat()) {
      return;
    }

    renderValues.buttons.push(
      <Tooltip
        title={intl.formatMessage({
          id: 'misc.moveToExternalWindow',
          defaultMessage: 'Open tab in external window',
        })}
        placement='topRight'
        arrowPointAtCenter
        key={`external-${tabSetNodeId}`}
      >
        <div
          className={classes.iconContainer}
          onClick={() => moveTabToExternalWindow(selectedNode)}
          data-testid={OPEN_EXTERNAL_WINDOW_TESTID}
        >
          <SelectOutlined key={`${id}-toNewWindow`} />
        </div>
      </Tooltip>
    );
  };

  const onRenderTab = useCallback(
    (
      node: TabNode,
      renderValues: {
        leading: React.ReactNode;
        content: React.ReactNode;
      }
    ) => {
      const { content } = renderValues;

      renderValues.content = (
        <Tooltip
          title={intl.formatMessage({
            id: 'misc.dragToMove',
            defaultMessage: 'Drag to move',
          })}
          placement='top'
        >
          <div
            className={classes.tabNameWrapper}
            data-testid={`${SIDE_PANEL_TAB_TESTID}${node.getId()}`}
          >
            {content}
          </div>
        </Tooltip>
      );
    },
    [intl, classes]
  );

  const flexLayoutI18nMapper = useCallback(
    (id, param) => {
      return `${dynamicIntl({
        id: `flexLayout.${id}`,
        defaultMessage: id,
      })}${param || ''}`;
    },
    [dynamicIntl]
  );

  const onComponentClose = useCallback(
    (componentKey: string) => {
      const onClose = !!componentKey
        ? availableComponents[componentKey]?.onClose
        : undefined;

      if (!!onClose) onClose();
    },
    [availableComponents]
  );

  const actionMoveNode = useCallback(
    (action: Action) => {
      if (action.data.location === 'left' || action.data.location === 'right') {
        if (filtersSidebarWidth) {
          dispatch(setSidebarWidth(filtersSidebarWidth));
        } else {
          const { width = 0 } =
            window.document
              .querySelector('.flexlayout__layout')
              ?.getBoundingClientRect() || {};

          dispatch(setSidebarWidth(width / 2));
        }
      } else {
        dispatch(setSidebarWidth(0));
      }
    },
    [dispatch, filtersSidebarWidth]
  );

  const onAction = useCallback(
    (action: Action) => {
      if (action.type === 'FlexLayout_SelectTab') onSelectTab(action);

      if (action.type === 'FlexLayout_MoveNode') {
        actionMoveNode(action);
      }

      if (action.type === 'FlexLayout_AdjustSplit') {
        const sidebarWidth = action.data.pixelWidth2;

        dispatch(setSidebarWidth(sidebarWidth));
      }

      //it is here because we can not save preferences in model change method there was too many requests
      //not called when user just click on sidepanel
      if (action.type !== 'FlexLayout_SetActiveTabset')
        setTimeout(() => {
          dispatch(
            updatePreferences(PreferencesTypes.LayoutPreferences, {
              flexLayoutJSONModel: model.toJson(),
            })
          );
        }, 0);

      //It fix prevented click action on sidepanel
      if (action.type === 'FlexLayout_SetActiveTabset') return;

      if (action.type !== 'FlexLayout_DeleteTab') return action;

      const deletedNodeName = action.data.node as FlexLayoutWindows;
      const actions = storedModalFunctions
        ? storedModalFunctions[deletedNodeName]
        : undefined;

      if (
        shouldBeDisplayed[deletedNodeName] &&
        actions &&
        blockedWindows.includes(deletedNodeName)
      ) {
        if (actions.preventCloseTab) {
          actions.preventCloseTab();
        } else {
          if (actions.callback) {
            actions.callback();
          }
        }

        return {
          type: 'FlexLayout_SetActiveTabset',
          data: {
            tabsetNode: 'childrenTabset',
          },
        };
      }

      onComponentClose(deletedNodeName);
      dispatch(setSidebarData(deletedNodeName, {}));

      return action;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      filtersSidebarWidth,
      model,
      onComponentClose,
      shouldBeDisplayed,
      storedModalFunctions,
      actionMoveNode,
    ]
  );

  const beforeExternalComponentClose = useCallback(
    (componentKey: string) => {
      onComponentClose(componentKey);
      closeExternalComponent(componentKey);
    },
    [onComponentClose, closeExternalComponent]
  );

  const getAllTabIds = useCallback((node: Node, tabNodeIds: string[]) => {
    if (node.getChildren().length > 0) {
      node.getChildren().forEach(node => getAllTabIds(node, tabNodeIds));
    }

    /*
    no other way to get node attributes
    */
    //@ts-ignore
    if (node._attributes.type === 'tab') {
      //@ts-ignore
      tabNodeIds.push(node._attributes.id);
    }
  }, []);

  //this effect is responsible for renaming tabs when language is changed
  useEffect(() => {
    const tabNodeIds: string[] = [];

    getAllTabIds(model.getRoot(), tabNodeIds);
    tabNodeIds.forEach(nodeId =>
      model.doAction(
        Actions.renameTab(nodeId, availableComponents[nodeId]?.name)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const icons = useMemo(
    () => ({
      close: <TabCloseIcon />,
    }),
    []
  );

  return (
    <div className={classes.flexLayoutWrapper}>
      <FlexLayoutReact.Layout
        ref={setLayoutRef}
        supportsPopout={false}
        font={{ size: '13px' }}
        i18nMapper={flexLayoutI18nMapper}
        {...{
          model,
          factory,
          onAction,
          onRenderTabSet,
          onRenderTab,
          icons,
        }}
      />
      <ExternalWindows
        componentsDictionary={availableComponents}
        components={externalComponents}
        onCloseWindow={beforeExternalComponentClose}
        onReatachWindow={reatachExternalWindow}
      />
      <ComponentsDrawer />
    </div>
  );
};

export default FlexLayout;
