import React, { useCallback, SyntheticEvent } from 'react';
import { FilterIndicatorProps } from './types';
import useFilterIndicatorStyles from './styles';
import { CloseIcon } from 'components/Icon';
import Tooltip from 'components/lib/Tooltip';
import { useIntl } from 'react-intl';
import {
  FILTER_INDICATOR_CLEAR_FILTERS_TESTID,
  FILTER_INDICATOR_TESTID,
} from 'utils/testIds';
import GTag from 'providers/IntlProviderWrapper/gTag';
import { GTagEventButtonName } from 'providers/IntlProviderWrapper/gTag/types';

const FilterIndicator: React.FC<FilterIndicatorProps> = ({
  onClick,
  onClose,
}) => {
  const classes = useFilterIndicatorStyles({});
  const intl = useIntl();

  const onResetFilter = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      onClose();
    },
    [onClose]
  );

  const handleOnClick = () => {
    GTag.pushGtagButtonEvent(GTagEventButtonName.FilteredResults);
    onClick();
  };

  return (
    <div
      className={classes.filterIndicator}
      onClick={handleOnClick}
      data-testid={FILTER_INDICATOR_TESTID}
    >
      <Tooltip
        placement='top'
        title={intl.formatMessage({
          id: 'misc.openFilter',
          defaultMessage: 'Open filter',
        })}
        destroyTooltipOnHide
      >
        <span className={classes.filterIndicatorText}>
          {intl.formatMessage({
            id: 'misc.filteredResults',
            defaultMessage: 'Filtered results',
          })}
        </span>
      </Tooltip>
      <Tooltip
        placement='top'
        title={intl.formatMessage({
          id: 'misc.clearFilter',
          defaultMessage: 'Clear filter',
        })}
      >
        <div
          className={classes.iconContainer}
          onClick={onResetFilter}
          data-testid={FILTER_INDICATOR_CLEAR_FILTERS_TESTID}
        >
          <CloseIcon size={13} />
        </div>
      </Tooltip>
    </div>
  );
};

export default FilterIndicator;
