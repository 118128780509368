import clsx from 'clsx';
import { ArrowDownIcon, ArrowUpIcon } from 'components/Icon';
import React from 'react';
import {
  ARROW_UP_ID,
  ARROW_DOWN_ID,
  ARROWS_WRAPPER_ID,
} from 'utils/elementsIds';
import useInputNumberStyles from './styles';
import { ArrowsWrapperProps } from './types';

const ArrowsWrapper = ({
  onArrowUpPress,
  onArrowDownPress,
}: ArrowsWrapperProps) => {
  const inputNumberClasses = useInputNumberStyles({});

  return (
    <div className={inputNumberClasses.arrowsWrapper} id={ARROWS_WRAPPER_ID}>
      <div
        className={clsx([
          inputNumberClasses.arrowWrapper,
          inputNumberClasses.topWrapper,
        ])}
        onClick={onArrowUpPress}
        id={ARROW_UP_ID}
      >
        <ArrowUpIcon size={7} />
      </div>
      <div
        id={ARROW_DOWN_ID}
        className={clsx([
          inputNumberClasses.arrowWrapper,
          inputNumberClasses.bottomWrapper,
        ])}
        onClick={onArrowDownPress}
      >
        <ArrowDownIcon size={7} />
      </div>
    </div>
  );
};

export default ArrowsWrapper;
