import { Reducer } from 'redux';
import {
  SET_USER_GROUP_PERMISSIONS_OWNERS,
  SET_USER_GROUP_PERMISSIONS_OWNERS_FETCHING,
  SET_USER_GROUP_PERMISSIONS_OWNERS_OPTIONS,
  USER_GROUP_PERMISSIONS_IS_REMOVING_HIMSELF,
  SET_USER_GROUP_SELECTED_PERMISSION_SET,
  CLEAR_USER_GROUP_PERMISSION_SETS_ASSIGNEES,
  SET_USER_GROUP_PERMISSION_SET_ASSIGNEES,
  SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_FETCHING,
  SET_USER_GROUP_PERMISSIONS_USER_GROUP,
  SET_USER_GROUP_PERMISSIONS_PERMISSION_SETS,
  SET_USER_GROUP_PERMISSIONS_PERMISSION_SET,
  SET_USER_GROUP_PERMISSIONS_PERMISSION_SETS_FETCHING,
  RESET_USER_GROUP_PERMISSIONS_STATE,
  REMOVE_USER_GROUP_PERMISSION_SET,
  SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_OPTIONS,
  CLEAR_USER_GROUP_PERMISSION_SETS_ASSIGNEES_GROUPS,
  SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS,
  SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS_FETCHING,
  SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS_OPTIONS,
  SET_USER_GROUP_PERMISSIONS_USER_GROUP_PERMISSION_SET_OPTIONS,
} from 'store/constants/userGroupPermissions.consts';
import { RootAction } from '.';
import {
  AssigneesType,
  UserGroupPermissionsState,
} from './types/userGroupPermissions.types';
import omit from 'lodash/omit';
import { USERS_LIST_AUTOCOMPLETE } from 'utils/endpoints';

const initialState: UserGroupPermissionsState = {
  isRemovingHimself: false,
  selectedPermissionSet: undefined,
  userGroup: undefined,
  groupPermissions: {
    owners: {
      fetching: false,
      list: [],
      avatars: [],
      totalCount: 0,
      options: {
        restrictions: {
          limit_items: undefined,
        },
        batch: {
          autocomplete: USERS_LIST_AUTOCOMPLETE,
          required: false,
          type: 'set',
        },
      },
    },
    assignees: {},
    groupAssignees: {},
  },
  permissionSets: [],
  permissionSetsFetching: false,
  permissionSetsMeta: undefined,
};

const userGroupsPermissionsReducer: Reducer<
  UserGroupPermissionsState,
  RootAction
> = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_GROUP_PERMISSIONS_OWNERS:
      return {
        ...state,
        groupPermissions: {
          ...state.groupPermissions,
          owners: {
            ...state.groupPermissions.owners,
            ...action.payload,
          },
        },
      };
    case SET_USER_GROUP_PERMISSIONS_OWNERS_FETCHING:
      return {
        ...state,
        groupPermissions: {
          ...state.groupPermissions,
          owners: {
            ...state.groupPermissions.owners,
            fetching: action.payload,
          },
        },
      };
    case SET_USER_GROUP_PERMISSIONS_OWNERS_OPTIONS:
      return {
        ...state,
        groupPermissions: {
          ...state.groupPermissions,
          owners: {
            ...state.groupPermissions.owners,
            options: {
              ...state.groupPermissions.owners.options,
              ...action.payload,
            },
          },
        },
      };
    case USER_GROUP_PERMISSIONS_IS_REMOVING_HIMSELF:
      return {
        ...state,
        isRemovingHimself: action.payload,
      };
    case SET_USER_GROUP_SELECTED_PERMISSION_SET:
      return {
        ...state,
        selectedPermissionSet: action.payload,
      };
    case CLEAR_USER_GROUP_PERMISSION_SETS_ASSIGNEES:
      return {
        ...state,
        groupPermissions: {
          ...state.groupPermissions,
          assignees: {},
        },
      };
    case SET_USER_GROUP_PERMISSION_SET_ASSIGNEES:
      return {
        ...state,
        groupPermissions: {
          ...state.groupPermissions,
          assignees: {
            ...state.groupPermissions.assignees,
            [action.payload.permissionSetId]: {
              ...(state.groupPermissions.assignees[
                action.payload.permissionSetId
              ] || {}),
              ...action.payload,
            },
          },
        },
      };
    case SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_OPTIONS: {
      return {
        ...state,
        groupPermissions: {
          ...state.groupPermissions,
          assignees: {
            ...state.groupPermissions.assignees,
            [action.payload.permissionSetId]: {
              ...(state.groupPermissions.assignees[
                action.payload.permissionSetId
              ] || {}),
              options: {
                ...action.payload.options,
              },
            },
          },
        },
      };
    }
    case SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_FETCHING:
      return {
        ...state,
        groupPermissions: {
          ...state.groupPermissions,
          assignees: {
            ...state.groupPermissions.assignees,
            [action.payload.permissionSetId]: {
              ...(state.groupPermissions.assignees[
                action.payload.permissionSetId
              ] || {}),
              fetching: action.payload.fetching,
            },
          },
        },
      };

    case SET_USER_GROUP_PERMISSIONS_USER_GROUP: {
      return {
        ...state,
        userGroup: action.payload,
      };
    }
    case SET_USER_GROUP_PERMISSIONS_PERMISSION_SETS: {
      return {
        ...state,
        permissionSets: action.payload,
      };
    }
    case SET_USER_GROUP_PERMISSIONS_PERMISSION_SETS_FETCHING: {
      return {
        ...state,
        permissionSetsFetching: action.payload,
      };
    }
    case SET_USER_GROUP_PERMISSIONS_PERMISSION_SET: {
      const updatedPermissionSet = action.payload;
      const permisionSets = state.permissionSets;
      const updatedPermissionSets = permisionSets.some(
        ({ id }) => id === updatedPermissionSet.id
      )
        ? permisionSets.map(result =>
            result.id === updatedPermissionSet.id
              ? updatedPermissionSet
              : result
          )
        : [...permisionSets, updatedPermissionSet];

      return {
        ...state,
        permissionSets: updatedPermissionSets,
      };
    }
    case REMOVE_USER_GROUP_PERMISSION_SET: {
      const permisionSets = state.permissionSets;
      const updatedPermissionSets = permisionSets.filter(pm => {
        return pm.id !== action.payload;
      });
      const assignees = state.groupPermissions.assignees;
      const updatedAssignees = omit(assignees, [
        action.payload,
      ]) as MappedObject<AssigneesType, string>;
      return {
        ...state,
        permissionSets: updatedPermissionSets,
        groupPermissions: {
          ...state.groupPermissions,
          assignees: updatedAssignees,
        },
      };
    }

    case CLEAR_USER_GROUP_PERMISSION_SETS_ASSIGNEES_GROUPS:
      return {
        ...state,
        groupPermissions: {
          ...state.groupPermissions,
          groupAssignees: {},
        },
      };
    case SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS:
      return {
        ...state,
        groupPermissions: {
          ...state.groupPermissions,
          groupAssignees: {
            ...state.groupPermissions.groupAssignees,
            [action.payload.permissionSetId]: {
              ...(state.groupPermissions.groupAssignees[
                action.payload.permissionSetId
              ] || {}),
              ...action.payload,
            },
          },
        },
      };
    case SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS_OPTIONS: {
      return {
        ...state,
        groupPermissions: {
          ...state.groupPermissions,
          groupAssignees: {
            ...state.groupPermissions.groupAssignees,
            [action.payload.permissionSetId]: {
              ...(state.groupPermissions.groupAssignees[
                action.payload.permissionSetId
              ] || {}),
              options: {
                ...action.payload.options,
              },
            },
          },
        },
      };
    }
    case SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS_FETCHING:
      return {
        ...state,
        groupPermissions: {
          ...state.groupPermissions,
          groupAssignees: {
            ...state.groupPermissions.groupAssignees,
            [action.payload.permissionSetId]: {
              ...(state.groupPermissions.groupAssignees[
                action.payload.permissionSetId
              ] || {}),
              fetching: action.payload.fetching,
            },
          },
        },
      };

    case SET_USER_GROUP_PERMISSIONS_USER_GROUP_PERMISSION_SET_OPTIONS: {
      return {
        ...state,
        permissionSetsMeta: action.payload,
      };
    }

    case RESET_USER_GROUP_PERMISSIONS_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default userGroupsPermissionsReducer;
