import { RootAction } from 'store/reducers';
import { Reducer } from 'redux';
import {
  SET_GLOBAL_HELP_MAPPING,
  SET_GLOBAL_LANDING_PAGE,
  SET_GLOBAL_PREFERENCES_LOADING,
  SET_SAVING_GLOBAL_PREFERENCES,
} from 'store/constants/globalPreferences.consts';
import { GlobalPreferencesState } from './types/globalPreferences';

const initialState: GlobalPreferencesState = {
  loading: true,
  saving: false,
  data: {
    mappedAppRoutesToHelpPanel: {},
    landingPageData: {
      enabled: false,
      configuration: { data_schema: '', ui_schema: '' },
    },
  },
};

const globalPreferencesReducer: Reducer<GlobalPreferencesState, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_GLOBAL_HELP_MAPPING: {
      return {
        ...state,
        loading: false,
        saving: false,
        data: {
          ...state.data,
          mappedAppRoutesToHelpPanel: action.payload,
        },
      };
    }
    case SET_GLOBAL_LANDING_PAGE: {
      return {
        ...state,
        loading: false,
        saving: false,
        data: {
          ...state.data,
          landingPageData: action.payload,
        },
      };
    }
    case SET_GLOBAL_PREFERENCES_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case SET_SAVING_GLOBAL_PREFERENCES: {
      return {
        ...state,
        saving: action.saving,
      };
    }

    default:
      return state;
  }
};

export default globalPreferencesReducer;
