import { EyeIcon, MainWindow } from 'components/Icon';
import Tooltip from 'components/lib/Tooltip';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useChildRecordSummaryPanelHeaderStyles from './styles';
import clsx from 'clsx';
import { ButtonPrimary } from 'components/lib/Button';
import { useDispatch } from 'react-redux';
import { pushStackWithTreeUpdate } from 'store/actions/nestedObjectRecordsActions';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { ObjectClassDetailsFromApi } from 'pages/ObjectClasses/components/ObjectClassForm/hooks/useObjectClassDetailsData/types';
import { ObjectRecordDetails } from 'utils/types/api/objectRecords.types';

const ChildRecordSummaryPanelHeader = ({
  objectClass,
  objectRecord,
}: {
  objectClass: ObjectClassDetailsFromApi;
  objectRecord: ObjectRecordDetails;
}) => {
  const classes = useChildRecordSummaryPanelHeaderStyles({});
  const dispatch = useDispatch();
  const { setAdditionalSelectedResource } = useSelectedResourceContext();
  const intl = useIntl();
  const message = intl.formatMessage(
    {
      id: 'nestedObjectRecords.childRecordSummary',
      defaultMessage:
        'Previewing <b>{objectClassName} - {recordIdentifier}</b>',
    },
    {
      objectClassName: objectClass.name,
      recordIdentifier: objectRecord.object_name ?? objectRecord.id,
      b: (...chunks: string[]) => <b>{chunks}</b>,
    }
  );

  const buttonTooltip = !objectClass.display_configuration?.recordView
    ?.data_schema?.enabled && (
    <FormattedMessage
      id='nestedObjectRecords.noCreateViewEnabled'
      defaultMessage='You cannot open this record as no display has been configured for it.'
    />
  );

  return (
    <div className={classes.headerContainer}>
      <div className={classes.objectName}>
        <EyeIcon className={classes.eyeIcon} />
        <Tooltip overlay={message}>{message}</Tooltip>
      </div>
      <Tooltip overlay={buttonTooltip} placement='topLeft'>
        <ButtonPrimary
          disabled={!!buttonTooltip}
          className={clsx({
            [classes.button]: true,
            [classes.disabled]: !!buttonTooltip,
          })}
          onClick={() => {
            setAdditionalSelectedResource(undefined);

            dispatch(
              pushStackWithTreeUpdate({
                recordId: objectRecord.id.toString(),
                recordIdentifier: objectRecord.object_name,
                classId: objectClass.id.toString(),
                formData: undefined,
                className: objectClass.name,
              })
            );
          }}
        >
          <MainWindow size={13} />
          <FormattedMessage
            id='nestedObjectRecords.openInMainWindow'
            defaultMessage='Open in main window'
          />
        </ButtonPrimary>
      </Tooltip>
    </div>
  );
};

export default ChildRecordSummaryPanelHeader;
