import React, { forwardRef, useLayoutEffect, useRef } from 'react';
import { Input as AntInput } from 'antd';
import {
  InputProps,
  SearchProps,
  PasswordProps,
  TextAreaProps,
} from 'antd/lib/input';
import useInputStyles from './styles';
import AntTextAreaType from 'antd/lib/input/TextArea';
import clsx from 'clsx';
import {
  Input as FInput,
  InputProps as FInputProps,
  PasswordProps as FPasswordProps,
  TextAreaProps as FTextAreaProps,
} from 'formik-antd';
import useGlobalStyle from 'hooks/useGlobalStyle';
import useCustomInputStyles from 'components/Inputs/styles';
import {
  FTextAreaCustomProps,
  InputCustomProps,
  TextareaCustomProps,
} from './types';
import onChangeTrimSpaces from 'utils/functions/onChangeTrimSpaces';
import { PhoneIcon, EmailIcon, URLIcon } from 'components/Icon';
import { ANT_TEXTAREA_TESTID } from 'utils/testIds';
import textIcon from 'img/icons/textbox-blue-icon.svg';
import useFormatNumber from 'hooks/useFormatNumber';
import { Select } from '../Select';
import { PROTOCOL_SELECT_OPTIONS } from 'components/FormPreview2/widgets/standard/CustomURLWidget/consts';

const {
  Search: AntSearch,
  Password: AntPassword,
  TextArea: AntTextArea,
} = AntInput;

const { Password: FPassword, TextArea: FTextArea } = FInput;

const Input = forwardRef<AntInput, InputCustomProps>(
  (
    {
      className,
      disabled,
      maxLength,
      value,
      onChange,
      withCounter = true,
      withIcon,
      customIcon,
      disableTextTrim,
      ...rest
    },
    ref
  ) => {
    const classes = useInputStyles({ icon: customIcon ?? textIcon });
    const globalClasses = useGlobalStyle();
    const formatNumberWithSeparators = useFormatNumber();

    const slicedValue =
      maxLength !== undefined && !disableTextTrim
        ? value?.toString()?.slice(0, maxLength)
        : value;

    return (
      <>
        <AntInput
          {...{ ...rest, disabled, ref, maxLength }}
          value={slicedValue}
          onChange={disableTextTrim ? onChange : onChangeTrimSpaces(onChange)}
          className={clsx([
            classes.input,
            className,
            {
              [globalClasses.disabledInput]: disabled,
              [classes.inputIcon]: withIcon,
            },
          ])}
        />
        {withCounter && !!maxLength ? (
          <span className={classes.counter}>{`${formatNumberWithSeparators(
            slicedValue?.toString().length || 0
          )} / ${formatNumberWithSeparators(maxLength)}`}</span>
        ) : null}
      </>
    );
  }
);

const FormikInput = forwardRef<AntInput, FInputProps>(
  ({ className, disabled, ...rest }, ref) => {
    const classes = useInputStyles({});
    const globalClasses = useGlobalStyle();

    return (
      <FInput
        {...{ ...rest, disabled }}
        className={clsx([
          classes.input,
          className,
          { [globalClasses.disabledInput]: disabled },
        ])}
        {...{ ref }}
      />
    );
  }
);

const Search = forwardRef<AntInput, SearchProps>(
  ({ className, disabled, ...rest }, ref) => {
    const classes = useInputStyles({});
    const globalClasses = useGlobalStyle();

    return (
      <AntSearch
        {...{ ...rest, disabled }}
        className={clsx([
          classes.input,
          className,
          { [globalClasses.disabledInput]: disabled },
        ])}
        {...{ ref }}
      />
    );
  }
);

const Password = forwardRef<AntInput, PasswordProps>(
  ({ className, disabled, visibilityToggle, ...rest }, ref) => {
    const classes = useInputStyles({});
    const globalClasses = useGlobalStyle();

    return (
      <AntPassword
        {...{ ...rest, disabled, visibilityToggle }}
        className={clsx([
          classes.input,
          className,
          { [globalClasses.disabledInput]: disabled },
        ])}
        {...{ ref }}
      />
    );
  }
);

const FormikPassword = forwardRef<AntInput, FPasswordProps>(
  ({ className, disabled, ...rest }, ref) => {
    const classes = useInputStyles({});
    const globalClasses = useGlobalStyle();

    return (
      <FPassword
        {...{ ...rest, disabled }}
        className={clsx([
          classes.input,
          className,
          { [globalClasses.disabledInput]: disabled },
        ])}
        {...{ ref }}
      />
    );
  }
);

const TextArea = forwardRef<
  AntTextAreaType,
  TextAreaProps & TextareaCustomProps
>(
  (
    {
      className,
      disabled,
      disableResize,
      disableTrimSpaces,
      withCounter,
      withIcon,
      icon = textIcon,
      maxLength,
      value,
      onChange,
      disableTextTrim,
      textareaElementRef,
      placeholder,
      readOnly,
      withLineCounter,
      onScroll,
      rows,
      ...rest
    },
    ref
  ) => {
    const classes = useInputStyles({ icon });
    const styles = useCustomInputStyles({});
    const globalClasses = useGlobalStyle();
    const formatNumberWithSeparators = useFormatNumber();

    const textareaWrapperRef = useRef<HTMLDivElement>(null);
    const lineCounterRef = useRef<HTMLTextAreaElement>(null);

    const handleScroll = (e: React.UIEvent<HTMLTextAreaElement, UIEvent>) => {
      if (lineCounterRef?.current) {
        lineCounterRef.current.scrollTop = e.currentTarget.scrollTop;
      }
      if (onScroll) onScroll(e);
    };

    // workaround to get real reference to textarea
    useLayoutEffect(() => {
      if (!textareaWrapperRef?.current || !textareaElementRef) return;

      const textareaElement = textareaWrapperRef.current?.firstElementChild
        ?.firstElementChild as HTMLTextAreaElement;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      textareaElementRef.current = textareaElement;
      if (maxLength) textareaElementRef.current.maxLength = maxLength;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const slicedValue =
      maxLength !== undefined && maxLength !== null && !disableTextTrim
        ? value?.toString()?.slice(0, maxLength)
        : value;

    const lineCount =
      Math.max(rows || 0, ((value as string) || '').split('\n').length) + 1;
    const lines = Array.from(
      {
        length: lineCount,
      },
      (_, i) => i + 1
    ).join('\n');

    return (
      <div className={styles.textareaWrapper} ref={textareaWrapperRef}>
        <AntTextArea
          data-testid={ANT_TEXTAREA_TESTID}
          {...{ ...rest, disabled }}
          onChange={
            !disableTrimSpaces ? onChangeTrimSpaces(onChange) : onChange
          }
          value={slicedValue as string}
          {...{ placeholder, rows, readOnly }}
          className={clsx([
            classes.input,
            classes.cursorAuto,
            className,
            {
              [globalClasses.disabledInput]: disabled,
              [classes.noResize]: disableResize,
              [classes.textareaIcon]: withIcon,
              [classes.withLineCounter]: withLineCounter,
            },
          ])}
          onScroll={handleScroll}
          {...{ ref }}
        />
        {withLineCounter && (
          <textarea
            value={lines}
            className={clsx([classes.lineCounter, classes.noResize])}
            readOnly
            rows={rows}
            wrap='off'
            ref={lineCounterRef}
          />
        )}
        {withCounter && !!maxLength ? (
          <span className={classes.counter}>{`${formatNumberWithSeparators(
            slicedValue?.toString().length || 0
          )} / ${formatNumberWithSeparators(maxLength)}`}</span>
        ) : null}
      </div>
    );
  }
);

const FormikTextArea = forwardRef<
  AntTextAreaType,
  FTextAreaProps & FTextAreaCustomProps
>(({ className, disabled = false, disableResize = false, ...rest }, ref) => {
  const inputStyles = useInputStyles();
  const globalStyles = useGlobalStyle();

  return (
    <FTextArea
      className={clsx(inputStyles.input, className, {
        [globalStyles.disabledInput]: disabled,
        [inputStyles.noResize]: disableResize,
      })}
      {...{ ...rest, disabled, ref }}
    />
  );
});

const InputPhone = forwardRef<AntInput, InputProps>(({ ...rest }, ref) => {
  const classes = useInputStyles({});

  return (
    <div className={classes.customInput}>
      <Input {...{ ...rest, ref }} />
      <div className={classes.inputIconWrapper}>
        <PhoneIcon className={classes.icon} size={16} />
      </div>
    </div>
  );
});

const InputEmail = forwardRef<AntInput, InputProps>(({ ...rest }, ref) => {
  const classes = useInputStyles({});

  return (
    <div className={classes.customInput}>
      <Input {...{ ...rest, ref }} />
      <div className={classes.inputIconWrapper}>
        <EmailIcon className={classes.icon} size={16} />
      </div>
    </div>
  );
});

const InputURL = forwardRef<AntInput, InputProps>(({ ...rest }, ref) => {
  const classes = useInputStyles({});

  return (
    <div className={classes.customInput}>
      <div className={classes.urlInputsWrapper}>
        <div className={classes.urlInputsProtocol}>
          <Select
            options={PROTOCOL_SELECT_OPTIONS}
            value={PROTOCOL_SELECT_OPTIONS[0].value}
          />
        </div>
        <Input {...{ ...rest, ref }} />
      </div>
      <div className={classes.inputIconWrapper}>
        <URLIcon className={classes.icon} size={16} />
      </div>
    </div>
  );
});

export {
  Input,
  Search,
  Password,
  TextArea,
  FormikInput,
  FormikPassword,
  FormikTextArea,
  InputPhone,
  InputEmail,
  InputURL,
};
