import React from 'react';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import {
  DocumentStatus,
  DocumentTemplatePanel,
} from 'utils/types/api/documentTemplate.types';
import { useDocumentTemplateStatusStyles } from './styles';
import DocumentTemplateDownload from './DocumentTemplateDownload';
import DocumentTemplateProcessing from './DocumentTemplateProcessing';

const { Processing } = DocumentStatus;

const DocumentTemplateStatus = ({
  id: documentTemplateId,
  generated_document,
}: DocumentTemplatePanel) => {
  const classes = useDocumentTemplateStatusStyles();
  const { selectedResource } = useSelectedResourceContext();

  const recordId = selectedResource?.record?.recordId;
  const { status } = generated_document ?? {};

  if (!status || !recordId) return null;

  return (
    <div className={classes.icons}>
      {status === Processing && (
        <DocumentTemplateProcessing {...{ recordId, documentTemplateId }} />
      )}
      {generated_document?.document && status !== Processing && (
        <DocumentTemplateDownload
          {...{ recordId, generated_document, documentTemplateId }}
        />
      )}
    </div>
  );
};

export default DocumentTemplateStatus;
