import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useTaskDrawerStyles = createUseStyles((theme: ThemeState) => {
  return {
    drawer: {
      position: 'absolute',
    },
    closeButtonContainer: {
      height: '100%',
      width: 36,
      display: 'flex',
      backgroundColor: theme['form-builder-background'],
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingTop: 10,
      color: theme['secondary-color'],
    },
    drawerContentWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      overflow: 'auto',
      position: 'relative',
    },
  };
});

export default useTaskDrawerStyles;
