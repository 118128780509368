import { ErrorTranslations } from 'alx-dynamic-form';
import { useIntl } from 'react-intl';

const useTranslateError = () => {
  const intl = useIntl();

  const translateError: ErrorTranslations = (
    errorKey,
    value,
    fieldLabel,
    fieldType,
    widgetType
  ) => {
    switch (errorKey) {
      case 'required':
        return intl.formatMessage(
          {
            id: 'formPreview2.validation.required',
            defaultMessage: '{label} is a required property',
          },
          {
            label: fieldLabel,
          }
        );
      case 'min_value':
        return intl.formatMessage(
          {
            id: 'formPreview2.validation.min_value',
            defaultMessage: '{label} must be greater than {value}',
          },
          {
            label: fieldLabel,
            value,
          }
        );
      case 'max_value':
        return intl.formatMessage(
          {
            id: 'formPreview2.validation.max_value',
            defaultMessage: '{label} must be lower than {value}',
          },
          {
            label: fieldLabel,
            value,
          }
        );
      case 'min_values':
        if (widgetType === 'user') {
          return intl.formatMessage(
            {
              id: 'errors.minSelectedUsers',
              defaultMessage: 'At least {minSelected} users must be selected',
            },
            { minSelected: value }
          );
        }
        return intl.formatMessage(
          {
            id: 'formPreview2.validation.min_values',
            defaultMessage:
              'At least {value, plural, one {# item} other {# items}} must be selected',
          },
          {
            value,
          }
        );
      case 'max_values':
        if (widgetType === 'user') {
          return intl.formatMessage(
            {
              id: 'errors.maxSelectedUsers',
              defaultMessage:
                'Maximum {maxSelected, plural, one {# user} other {# users}} can be selected',
            },
            { maxSelected: value }
          );
        }
        return intl.formatMessage(
          {
            id: 'formPreview2.validation.max_values',
            defaultMessage:
              'At most {value, plural, one {# item} other {# items}} can be selected',
          },
          {
            value,
          }
        );
      case 'max_length':
        return intl.formatMessage(
          {
            id: 'formPreview2.validation.max_length',
            defaultMessage: '{label} can have at most {value} characters',
          },
          {
            label: fieldLabel,
            value,
          }
        );
      case 'custom_format':
        return intl.formatMessage(
          {
            id: 'formPreview2.validation.custom_format',
            defaultMessage: '{label} has invalid format',
          },
          {
            label: fieldLabel,
          }
        );
      case 'minUsers':
        return intl.formatMessage(
          {
            id: 'errors.minSelectedUsers',
            defaultMessage: 'At least {minSelected} users must be selected',
          },
          { minSelected: value }
        );
      case 'maxUsers':
        return intl.formatMessage(
          {
            id: 'errors.maxSelectedUsers',
            defaultMessage:
              'Maximum {maxSelected, plural, one {# user} other {# users}} can be selected',
          },
          { maxSelected: value }
        );

      default:
        return intl.formatMessage(
          {
            id: 'formPreview2.validation.invalidValue',
            defaultMessage: '{label} has invalid value',
          },
          {
            label: fieldLabel,
          }
        );
    }
  };

  return translateError;
};

export default useTranslateError;
