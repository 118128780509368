import React, { useMemo } from 'react';
import { ExclamationMarkSquare } from 'components/Icon';
import { Alert } from 'components/lib/Alert';
import { getCurrentTableRestrictions } from 'store/selectors/generalSelectors';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import useLimitAlertStyles from './styles';
import { useTableContext } from 'contexts/TableContext';
import { TABLE_BULK_SELECTION_LIMIT_ALERT_TESTID } from 'utils/testIds';
import TablesType from 'utils/Enums/TablesType';

const LimitAlert = () => {
  const intl = useIntl();
  const classes = useLimitAlertStyles({});
  const {
    bulkSelectionList,
    searchValue,
    currentTableName,
    preferencesId,
  } = useTableContext();
  const { limit_items_in_batch: limitItems } = useSelector(
    getCurrentTableRestrictions(preferencesId?.toString())
  );

  const isVisible = !limitItems ? true : bulkSelectionList.length > limitItems;

  // @ts-ignore
  const messages: MappedObject<string, TablesType> = useMemo(
    () => ({
      [TablesType.Members]:
        {
          member: intl.formatMessage(
            {
              id: 'userGroup.memberLimitAlert',
              defaultMessage:
                'Maximum of {limitItems} {limitItems, plural, one {member} other {members}} can be removed at a time. {br}Only the first {limitItems} will be removed.',
            },
            { limitItems, br: <br /> }
          ),
          non_member: intl.formatMessage(
            {
              id: 'userGroup.nonMemberLimitAlert',
              defaultMessage:
                'Maximum of {limitItems} {limitItems, plural, one {member} other {members}} can be added at a time. {br}Only the first {limitItems} will be added.',
            },
            { limitItems, br: <br /> }
          ),
          // @ts-ignore
        }[searchValue?.value] || '',
    }),
    [searchValue, intl, limitItems]
  );

  const message = currentTableName ? messages[currentTableName] : '';

  return isVisible ? (
    <Alert
      banner
      {...{ message }}
      icon={<ExclamationMarkSquare size={18} />}
      className={classes.alert}
      data-testid={TABLE_BULK_SELECTION_LIMIT_ALERT_TESTID}
    />
  ) : null;
};

export default LimitAlert;
