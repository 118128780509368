import { createContext, useContext } from 'react';
import noop from 'lodash/noop';
import { ContentWrapperContextType } from './types';

const ContentWrapperContext = createContext<ContentWrapperContextType>({
  toggleDisabledEdition: noop,
  disabledEdition: false,
});

export const ContentWrapperContextProvider = ContentWrapperContext.Provider;
export const useContentWrapperContext = () => useContext(ContentWrapperContext);
