import React from 'react';
import Text from 'react-texty';
import { getShortText } from 'utils/functions/getShortText';
import { TooltipStringProps } from './types';
import 'react-texty/styles.css';

const TooltipString: React.FC<TooltipStringProps> = ({
  text,
  modifiedText,
  noStyle,
  hideArrow = true,
  withShortTooltipText = true,
  noDelay,
  tagName,
  href,
  onClick,
  target,
  className,
  testId,
}) => (
  <Text
    tooltip={withShortTooltipText ? getShortText(text) : text}
    hideArrow={hideArrow}
    tagName={tagName}
    data-testid={testId}
    className={className}
    href={href}
    onClick={onClick}
    target={target}
    tooltipMaxWidth={noStyle ? undefined : 260}
    showDelay={noDelay ? undefined : 1000}
    tooltipStyle={
      noStyle
        ? undefined
        : {
            fontSize: 12,
            fontWeight: 600,
            lineHeight: 1.3,
            padding: '8px 14px',
            overflowWrap: 'break-word',
          }
    }
  >
    {modifiedText || text}
  </Text>
);

export default TooltipString;
