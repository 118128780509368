import { ContentWrapperContextProvider } from 'contexts/ContentWrapperContext';
import React, { useCallback, useState } from 'react';
import useWrappersStyles from './styles';

const ContentWrapper: React.FC = ({ children }) => {
  const [disabledEdition, setDisabledEdition] = useState(false);

  const toggleDisabledEdition = useCallback((value: boolean) => {
    setDisabledEdition(value);
  }, []);

  const classes = useWrappersStyles({});

  return (
    <div className={classes.contentWrapper}>
      <ContentWrapperContextProvider
        value={{ disabledEdition, toggleDisabledEdition }}
      >
        {children}
      </ContentWrapperContextProvider>
    </div>
  );
};

export default ContentWrapper;
