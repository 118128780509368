import React, { useEffect, useState } from 'react';
import { FormValue, Widget } from 'alx-dynamic-form';
import { FormItem } from 'components/lib/Form';
import StandardFieldWrapper from 'components/FormPreview2/components/StandardFieldWrapper';
import { FormLabelWithToolTip } from 'pages/TaskTemplates/components/FormLabel';
import { SelectUserOption } from 'utils/types/selectInput.types';
import useCustomWidgetsStyles from '../../customWidgetsStyles';
import UsersSelect from './SelectUsers';
import useCustomUserWidgetStyles from './styles';
import { apiCall } from 'utils/api';
import { OBJECT_CLASS_FIELD_DETAILS } from 'utils/endpoints';
import { generatePath, useLocation } from 'react-router-dom';
import MinMaxInfo from 'components/MinMaxInfo';
import { MOCKED_USERS } from './consts';
import orderBy from 'lodash/orderBy';
import Tooltip from 'components/lib/Tooltip';
import { useHandleDeletedUsers } from './hooks';
import clsx from 'clsx';

const CustomUserWidget: Widget = ({
  required,
  label,
  fieldAlias,
  description,
  errors,
  value,
  onChange,
  reValidateField,
  additionalFieldProps,
  fieldId,
  maxUsers,
  minUsers,
  disabled,
}) => {
  const classes = useCustomWidgetsStyles({});
  const styles = useCustomUserWidgetStyles({});
  const [users, setUsers] = useState<SelectUserOption[]>([]);
  const [options, setOptions] = useState<SelectUserOption[]>([]);
  const { tooltip, customError, handleDeletedUsers } = useHandleDeletedUsers();
  const { search } = useLocation();
  const classId =
    additionalFieldProps?.classId ?? new URLSearchParams(search).get('classId'); //if classId is provided by additionalFieldProps (standalone form), use it, otherwise get it from url params

  useEffect(() => {
    if (!value) setUsers([]);
  }, [value]);

  const handleOnChange = (users: FormValue) => {
    const userIds = (Array.isArray(users) ? users : [])?.map(item => {
      return (item as { id: number })?.id;
    });
    onChange({ users: userIds, groups: [] });
  };

  useEffect(() => {
    if (fieldId && classId)
      apiCall
        .get(generatePath(OBJECT_CLASS_FIELD_DETAILS, { id: classId, fieldId }))
        .then(({ data }) => {
          const users: SelectUserOption[] = data?._meta?.users;
          const activeUsers = users.filter(({ is_deleted }) => !is_deleted);

          setOptions(
            orderBy(activeUsers, [
              ({ first_name, last_name, username }) =>
                (first_name + last_name + username).toLowerCase(),
            ])
          );

          handleDeletedUsers(activeUsers.length, required, minUsers);
        })
        .catch(() => setOptions([]));
    else setOptions(MOCKED_USERS);
  }, [classId, fieldId, handleDeletedUsers, minUsers, required]);

  return (
    <>
      <FormLabelWithToolTip
        dynamicSpacing
        {...{ required }}
        className={clsx([classes.inputLabel, classes.inputLabelEllipsis])}
        label={label}
      />
      <FormItem
        dynamicSpacing
        validateStatus={errors?.length || customError ? 'error' : undefined}
      >
        <StandardFieldWrapper
          {...{ description, errors: [...errors, customError] }}
        >
          <div
            className={styles.selectStyles}
            data-testid={`users-${fieldAlias}`}
          >
            <Tooltip title={tooltip} shouldBeRendered={!!tooltip}>
              <UsersSelect
                minValues={minUsers}
                maxValues={maxUsers}
                {...{
                  users,
                  setUsers,
                  options,
                  reValidateField,
                  required,
                  disabled: !!tooltip || disabled,
                  errors,
                }}
                onChange={handleOnChange}
              />
            </Tooltip>
          </div>
          <MinMaxInfo
            maximum={maxUsers ?? undefined}
            minimum={minUsers ?? undefined}
            checkboxField
          />
        </StandardFieldWrapper>
      </FormItem>
    </>
  );
};

export default CustomUserWidget;
