import { createUseStyles } from 'react-jss';

type UsePinStylesProps = {
  enabled?: boolean;
};

const usePinStyles = createUseStyles({
  button: {
    backgroundColor: 'transparent',
    borderRadius: '50%',
    border: 'none',
    '&:hover, &:active, &:focus': {
      backgroundColor: 'transparent',
    },
    transition: 'transform 200ms',
    transform: ({ enabled }: UsePinStylesProps) =>
      enabled ? 'rotate(90deg)' : 'rotate(0)',
  },
});

export default usePinStyles;
