import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useLocation } from 'react-router';
import { FormikErrors, FormikHelpers } from 'formik';
import { usePostWithToasts } from 'hooks/usePostWithToasts';
import {
  OBJECT_CLASS_DETAILS_FIELDS,
  OBJECT_CLASS_FIELD_DETAILS,
} from 'utils/endpoints';
import { FormMode } from 'utils/Enums/FormMode';
import {
  ClassFieldDetailLocationState,
  ClassFieldForm,
  ClassFieldFormFields,
  ClassFieldTypeOptions,
} from './types';
import { useDispatch, useSelector } from 'react-redux';
import useValidationSchemaBuilder from 'hooks/useValidationSchemaBuilder';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { useToggle } from 'hooks/useToggle';
import useFlexLayoutWindow from 'hooks/useFlexLayoutWindow';
import ErrorIllustration from 'img/illustrations/error.svg';
import { useIntl } from 'react-intl';
import { getAvailableUsers, parseTypes } from './utils';
import TablesType from 'utils/Enums/TablesType';
import { getObjectClassFieldsDetails } from 'store/selectors/objectClassesFieldsSelectors';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import { mapTaskOptionsToDictionary } from 'pages/TaskTemplates/utils';
import { useHistory, useParams } from 'react-router-dom';
import {
  addField,
  getObjectClassesFields,
  removeField,
  setObjectClassesFieldsSelectedRow,
  updateField,
} from 'store/actions/objectClassesFieldsActions';
import useClassFieldData from './useClassFieldData';
import objectRemoveKeysWithValue from 'utils/functions/objectRemoveKeysWithValue';
import {
  ObjectClassFieldTypes,
  ObjectClassFieldUpdated,
} from 'utils/types/api/objectClassesFields.types';
import * as yup from 'yup';
import { isBadRequest, isNotFound } from 'utils/apiUtils';
import { aliasNameErrorRegexp } from './consts';
import omit from 'lodash/omit';
import { maxUsersCount } from './components/consts';
import partition from 'lodash/partition';

export const useClassFieldForm = (
  mode: FormMode,
  panelId: FlexLayoutWindows
) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { state: { id } = {} } = useLocation<ClassFieldDetailLocationState>();
  const { id: classId } = useParams<{ id: string }>();
  const { getFieldData, data, ...rest } = useClassFieldData();
  const [
    isOpenNoExistsFieldModal,
    { toggleOn: openNoExistsFieldModal, toggleOff: closeNoExistsFieldModal },
  ] = useToggle(false);
  const history = useHistory();

  useEffect(() => {
    if (id === undefined || classId === undefined) return;

    getFieldData(classId, id);
  }, [getFieldData, classId, id]);

  const initialData = useMemo(() => {
    if (mode === FormMode.Create) return undefined;

    return data;
  }, [data, mode]);

  const {
    alias = '',
    label = '',
    type = ObjectClassFieldTypes.String,
    _meta: { users = [] } = {},
    ...defaultValues
  } = initialData || {};

  const [activeUsers, deletedUsers] = partition(
    users,
    ({ is_deleted }) => !is_deleted
  );

  const [initialValues, setInitialValues] = useState<ClassFieldForm>({
    [ClassFieldFormFields.Alias]: alias,
    [ClassFieldFormFields.Label]: label,
    [ClassFieldFormFields.Type]: type,
    [ClassFieldFormFields.Unique]: undefined,
    [ClassFieldFormFields.Identifier]: undefined,
    [ClassFieldFormFields.Users]: activeUsers,
    ...objectRemoveKeysWithValue(defaultValues, undefined),
  });

  useEffect(() => {
    setInitialValues({
      [ClassFieldFormFields.Alias]: alias,
      [ClassFieldFormFields.Label]: label,
      [ClassFieldFormFields.Type]: type,
      [ClassFieldFormFields.Unique]: undefined,
      [ClassFieldFormFields.Identifier]: undefined,
      [ClassFieldFormFields.Users]: activeUsers,
      ...objectRemoveKeysWithValue(defaultValues, undefined),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alias]);

  const { sendData, isLimitExceeded } = usePostWithToasts<
    ClassFieldForm,
    ObjectClassFieldUpdated
  >(mode, TablesType.ObjectClassesFields);

  const parseFormDataToApi = (formData: ClassFieldForm) => {
    const { users = [], ...values } = formData;
    const isUserType = values.type === ObjectClassFieldTypes.User;

    return {
      ...values,
      ...(isUserType && { options: { users: users.map(({ id }) => id) } }),
      default_value: getDefaultValue(formData),
      ...(mode === FormMode.Create && { order: 0 }),
    };
  };

  const getDefaultValue = (formData: ClassFieldForm) => {
    const { default_value: defaultValue, type } = formData;

    if (type === ObjectClassFieldTypes.Bool) return !!defaultValue;

    return defaultValue === '' ? null : defaultValue;
  };

  const parseAndSetErrors = useCallback(
    (setErrors: (errors: FormikErrors<ClassFieldForm>) => void) => (
      errors: FormikErrors<ClassFieldForm>
    ) => {
      if (errors.alias && aliasNameErrorRegexp.test(errors.alias)) {
        errors.alias = intl.formatMessage({
          id: 'objectClassesFields.errors.invalidAlias',
          defaultMessage: 'Invalid alias',
        });
      }

      setErrors(errors);
    },
    [intl]
  );

  const onSubmit = useCallback(
    async (
      formData: ClassFieldForm,
      { setErrors, setFieldValue, resetForm }: FormikHelpers<ClassFieldForm>
    ) => {
      const url =
        mode === FormMode.Edit
          ? generatePath(OBJECT_CLASS_FIELD_DETAILS, {
              id: classId,
              fieldId: id,
            })
          : generatePath(OBJECT_CLASS_DETAILS_FIELDS, {
              id: classId,
            });

      const initial = omit(initialValues, 'users');

      try {
        await sendData({
          url,
          data: parseFormDataToApi(formData) as ClassFieldForm,
          fields: ClassFieldFormFields,
          setErrors: parseAndSetErrors(setErrors),
          initialData: initial,
          successMessage:
            mode === FormMode.Create
              ? {
                  title: intl.formatMessage({
                    id: 'misc.success',
                    defaultMessage: 'Success!',
                  }),
                  subtitle: intl.formatMessage({
                    id: 'objectClasses.fields.fieldHasBeenCreated',
                    defaultMessage: 'Field has been created.',
                  }),
                }
              : undefined,
          callback: async data => {
            if (!data) return;

            const { order, ...apiField } = data;

            if (mode === FormMode.Create) {
              dispatch(addField({ ...apiField, order }));

              resetForm({});
            } else {
              dispatch(updateField(apiField));

              setInitialValues({
                ...(Object.fromEntries(
                  Object.entries(data).map(([key, value]) => [
                    key,
                    value ?? undefined,
                  ])
                ) as ClassFieldForm),
                users: formData.users,
              });
            }

            dispatch(setSidebarData(panelId, {}));
          },
        });
      } catch (e) {
        if (isNotFound(e) && mode === FormMode.Edit) openNoExistsFieldModal();
        if (isBadRequest(e) && formData.type === ObjectClassFieldTypes.User) {
          const availableUsers = await getAvailableUsers(formData.users);
          setFieldValue(ClassFieldFormFields.Users, availableUsers);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, id, initialValues, mode, sendData]
  );

  const onConfirmNoExistsField = useCallback(() => {
    if (id) {
      dispatch(setObjectClassesFieldsSelectedRow(undefined));
      history.replace(history.location.pathname, {}); // field's ID is cleaned up from state

      dispatch(removeField(id));
      closeNoExistsFieldModal();
    }
  }, [closeNoExistsFieldModal, dispatch, history, id]);

  return {
    onSubmit,
    initialValues,
    setInitialValues,
    data,
    isLimitExceeded,
    isOpenNoExistsFieldModal,
    onConfirmNoExistsField,
    deletedUsers,
    ...rest,
  };
};

export const useClassFieldOptions = () => {
  const intl = useIntl();
  const data = useSelector(getObjectClassFieldsDetails);
  const options = data ? mapTaskOptionsToDictionary(data.schema) : {};
  const {
    rawValidationSchema,
    buildValidationSchema,
  } = useValidationSchemaBuilder<ClassFieldTypeOptions | {}>(options, {
    max_num_of_files: yup.number().when('type', {
      is: 'document',
      then: yup.number().required(
        intl.formatMessage({
          id: 'errors.fieldIsRequired',
          defaultMessage: 'Field is required',
        })
      ),
    }),
    min_values: yup.number().test({
      name: 'min',
      params: {},
      message: intl.formatMessage({
        id: 'errors.notEnoughOptionsProvided',
        defaultMessage: 'Not enough options provided',
      }),
      test: function (value) {
        const length =
          this.parent?.users?.length || this.parent?.options?.length || 0;

        if (typeof value === 'number') {
          return value <= length;
        }
        return true;
      },
    }),
    max_values: yup.number().test({
      name: 'max',
      params: {},
      message: intl.formatMessage({
        id: 'errors.notEnoughOptionsProvided',
        defaultMessage: 'Not enough options provided',
      }),
      test: function (value) {
        const length =
          this.parent?.users?.length || this.parent?.options?.length || 0;

        if (typeof value === 'number') {
          return value <= length;
        }
        return true;
      },
    }),
    min_users_values: yup.number().test({
      name: 'min',
      params: {},
      message: intl.formatMessage({
        id: 'errors.notEnoughOptionsProvided',
        defaultMessage: 'Not enough options provided',
      }),
      test: function (value) {
        const length = this.parent?.users?.length ?? 0;

        if (typeof value === 'number') {
          return value <= length;
        }
        return true;
      },
    }),
    max_users_values: yup.number().test({
      name: 'max',
      params: {},
      message: intl.formatMessage({
        id: 'errors.notEnoughOptionsProvided',
        defaultMessage: 'Not enough options provided',
      }),
      test: function (value) {
        const length = this.parent?.users?.length ?? 0;

        if (typeof value === 'number') {
          return value <= length;
        }
        return true;
      },
    }),
    users: yup.array().max(maxUsersCount),
  });

  const parsedTypes = parseTypes(options?.type?.values || []);

  return { options, rawValidationSchema, buildValidationSchema, parsedTypes };
};

export const useLimitExceededModal = (isLimitExceeded?: boolean) => {
  const dispatch = useDispatch();
  const { closeComponent } = useFlexLayoutWindow(
    FlexLayoutWindows.ObjectClassAddField
  );
  const intl = useIntl();
  const [
    isLimitExceededModalOpen,
    { toggleOn: openLimitExceededModal, toggleOff: closeLimitExceededModal },
  ] = useToggle(false);

  useEffect(() => {
    if (!!isLimitExceeded) openLimitExceededModal();
  }, [isLimitExceeded, openLimitExceededModal]);

  const modalProps = useMemo(
    () => ({
      visible: isLimitExceededModalOpen,
      confirmationButtonLabel: intl.formatMessage({
        id: 'misc.ok',
        defaultMessage: 'Ok',
      }),
      image: ErrorIllustration,
      title: intl.formatMessage({
        id: 'objectClasses.fields.theFieldWasNotCreated',
        defaultMessage: 'The field was not created',
      }),
      subtitle: intl.formatMessage({
        id: 'objectClasses.fields.maximumNumberExceeded',
        defaultMessage: 'Maximum number of fields has been reached.',
      }),
    }),
    [intl, isLimitExceededModalOpen]
  );

  const onLimitExceededModalClose = () => {
    closeLimitExceededModal();
    closeComponent(FlexLayoutWindows.ObjectClassAddField);
    dispatch(getObjectClassesFields());
  };

  return {
    onLimitExceededModalClose,
    modalProps,
    isLimitExceededModalOpen,
  };
};

export const useNoExistsFieldModal = (fieldLabel: string) => {
  const intl = useIntl();

  const noExistsFieldModalProps = useMemo(
    () => ({
      confirmationButtonLabel: intl.formatMessage({
        id: 'misc.ok',
        defaultMessage: 'Ok',
      }),
      image: ErrorIllustration,
      title: intl.formatMessage(
        {
          id: 'misc.fieldNoLongerExists',
          defaultMessage: 'Field "{fieldLabel}" no longer exists',
        },
        {
          fieldLabel,
        }
      ),
      subtitle: intl.formatMessage({
        id: 'misc.yourChangesCannotBeSaved',
        defaultMessage: 'Your changes cannot be saved.',
      }),
    }),
    [intl, fieldLabel]
  );

  return {
    noExistsFieldModalProps,
  };
};
