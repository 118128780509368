import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const usePasswordRuleChipStyles = createUseStyles((theme: ThemeState) => {
  return {
    chips: {
      alignItems: 'center',
      borderRadius: '2px',
      display: 'inline-flex',
      justifyContent: 'center',
      marginRight: '9px',
      marginTop: '9px',
      padding: '4px 7px',
      width: 'auto',
    },
    chipsSuccess: {
      backgroundColor: '#ECF6EC',
      '& > svg': {
        color: theme['chips-color'],
      },
      '& > span': {
        color: theme['chips-color'],
      },
    },
    chipsUnSuccess: {
      backgroundColor: theme['chips-greyed-background-opacity'],
      '& > svg': {
        color: theme['secondary-color-light'],
      },
      '& > span': {
        color: theme['secondary-color-light'],
      },
    },
    icon: {
      marginRight: 3,
      position: 'relative',
      top: 1,
    },
    text: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '16px',
    },
  };
});

export default usePasswordRuleChipStyles;
