import React, { forwardRef } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import useMultiselectStyles from './styles';
import clsx from 'clsx';
import { Select as FSelect } from 'formik-antd';
import { FormikSelectProps, FormikSelect } from '../Select';
import usePopupContainer from 'hooks/usePopupContainer';
import { Tag } from '../Tag';
import useGlobalStyle from 'hooks/useGlobalStyle';
import MinMaxInfo from 'components/MinMaxInfo';
import { ANT_MULTI_SELECT_TESTID } from 'utils/testIds';
import { ArrowDownIcon } from 'components/Icon';

type MultiselectProps = Omit<SelectProps<any>, 'mode'> & {
  icon?: React.ReactNode;
};

interface ExtendedMultiselectProps extends MultiselectProps {
  showMinMax?: boolean;
  minimum?: number;
  maximum?: number;
}

export const Multiselect = forwardRef<Select, ExtendedMultiselectProps>(
  (
    {
      className,
      children,
      disabled,
      getPopupContainer,
      minimum,
      maximum,
      showMinMax,
      icon,
      ...rest
    },
    ref
  ) => {
    const classes = useMultiselectStyles({});
    const globalClasses = useGlobalStyle();
    const popupContainer = usePopupContainer();

    return (
      <>
        <Select
          mode='multiple'
          className={clsx([
            classes.multiselect,
            className,
            {
              [globalClasses.disabledInput]: disabled,
              [classes.withIcon]: !!icon,
            },
          ])}
          dropdownClassName={classes.dropdown}
          getPopupContainer={getPopupContainer || popupContainer}
          {...{ ...rest, disabled, ref }}
          // @ts-ignore
          data-testid={rest['data-testid'] || ANT_MULTI_SELECT_TESTID}
          suffixIcon={<ArrowDownIcon size={7} className={classes.arrowDown} />}
        >
          {children}
        </Select>
        {icon}
        {showMinMax && <MinMaxInfo {...{ maximum, minimum }} />}
      </>
    );
  }
);

export const FormikMultiselect: React.FC<FormikSelectProps> = ({
  className,
  children,
  disabled,
  loading,
  ...rest
}) => {
  const classes = useMultiselectStyles({});
  const globalClasses = useGlobalStyle();
  const getPopupContainer = usePopupContainer();

  return (
    <FSelect
      mode='multiple'
      className={clsx([
        classes.multiselect,
        className,
        {
          [globalClasses.disabledInput]: disabled,
        },
      ])}
      dropdownClassName={classes.dropdown}
      {...{ ...rest, getPopupContainer, loading }}
      data-testid={ANT_MULTI_SELECT_TESTID}
      suffixIcon={
        loading ? undefined : (
          <ArrowDownIcon size={7} className={classes.arrowDown} />
        )
      }
    >
      {children}
    </FSelect>
  );
};

export const MultiselectTag: React.FC<MultiselectProps> = ({
  className,
  disabled,
  children,
  ...rest
}) => {
  const classes = useMultiselectStyles({});
  const globalClasses = useGlobalStyle();

  return (
    <Select
      mode='tags'
      className={clsx([
        classes.multiselect,
        className,
        { [globalClasses.disabledInput]: disabled },
      ])}
      dropdownClassName={classes.dropdown}
      {...rest}
      data-testid={ANT_MULTI_SELECT_TESTID}
    >
      {children}
    </Select>
  );
};

export const FormikMultiselectTag: React.FC<FormikSelectProps> = ({
  className,
  children,
  disabled,
  ...rest
}) => {
  const classes = useMultiselectStyles({});
  const globalClasses = useGlobalStyle();

  if (disabled) {
    return (
      <FormikSelect
        mode='tags'
        className={clsx([
          classes.multiselect,
          className,
          globalClasses.disabledInput,
        ])}
        data-testid={ANT_MULTI_SELECT_TESTID}
        tagRender={({ label }) => (
          <Tag
            className={clsx(['ant-select-selection-item', classes.tagPadding])}
            closable={false}
          >
            {label}
          </Tag>
        )}
        dropdownClassName={classes.dropdown}
        disabled
        {...rest}
      />
    );
  }

  return (
    <FSelect
      mode='tags'
      className={clsx([classes.multiselect, className])}
      dropdownClassName={classes.dropdown}
      {...rest}
      data-testid={ANT_MULTI_SELECT_TESTID}
    >
      {children}
    </FSelect>
  );
};
