import { ExtendedTableState } from 'utils/types/api/preferences.types';
import { TableState } from 'react-table';

export const createTablePreferences = (
  currentTable: Partial<TableState<{}>>,
  additionalPreferences: object,
  preferencesId?: string | number
) =>
  preferencesId
    ? {
        ...currentTable,
        [preferencesId]: {
          ...((currentTable as ExtendedTableState)?.[preferencesId] || {}),
          ...additionalPreferences,
        },
      }
    : {
        ...currentTable,
        ...additionalPreferences,
      };
