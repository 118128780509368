import { RootState } from 'store/reducers';

export const selectChildClasses = (state: RootState) => state.childClasses;

export const selectChildClassById = (id: string | number) => (
  state: RootState
) => state.childClasses.find(child => child.id.toString() === id.toString());

export const selectChildClassesByParentId = (parentId?: number) => (
  state: RootState
) => state.childClasses.filter(child => child.parentId === parentId);
