import { Loader } from 'components/lib/Loader';
import React from 'react';
import useLoaderOverlayStyles from './styles';

const LoaderOverlay = () => {
  const classes = useLoaderOverlayStyles({});

  return (
    <div className={classes.overlay}>
      <Loader size='large' />
    </div>
  );
};

export default LoaderOverlay;
