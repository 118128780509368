import { RootState } from 'store/reducers';
import { ObjectClass } from 'utils/types/api/objectClasses.types';

export const selectObjectClasses = (state: RootState) =>
  state.objectClasses.allIds.map(id => {
    const objectClasses = state.objectClasses.byId[id];

    return {
      ...objectClasses,
      key: objectClasses.id,
    };
  });

export const totalObjectClasses = (state: RootState) => {
  return state.objectClasses.total;
};

export const filteredObjectClassesCount = (state: RootState) => {
  return state.objectClasses.filtered;
};
export const getObjectClassesColumns = (state: RootState) => {
  return state.objectClasses.columns;
};

export const getObjectClassesSelectedRow = (state: RootState) => {
  return state.objectClasses?.selectedRow;
};
export const getObjectClassesRestrictionsLimitItems = (state: RootState) => {
  return state.objectClasses?.restrictions.limit_items;
};

export const getObjectClassesRow = (id: string | undefined) => (
  state: RootState
): ObjectClass => {
  if (!id) return {} as ObjectClass;

  return state.objectClasses.byId[id];
};

export const getObjectClassesResponse = (state: RootState) => {
  return state.objectClasses.response;
};
