import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Space } from 'antd';
import { Select } from 'components/lib/Select';
import { TASK_QUICK_FILTER_OWNERSHIP } from 'utils/testIds';
import { useTasksQuickFiltersOwnership } from './hooks';
import { useTasksQuickFiltersStyle } from '../../styles';

const TasksQuickFiltersOwnership = () => {
  const classes = useTasksQuickFiltersStyle();
  const ref = useRef(null);

  const {
    ownership,
    ownershipOptions,
    handleChangeOwnership,
  } = useTasksQuickFiltersOwnership();

  return (
    <Space direction='vertical'>
      <FormattedMessage id='tasks.ownership' defaultMessage='Ownership' />
      <div ref={ref} data-testid={TASK_QUICK_FILTER_OWNERSHIP}>
        <Select
          className={classes.quickFilterSelect}
          options={ownershipOptions}
          value={ownership}
          onChange={handleChangeOwnership}
          getPopupContainer={() => ref.current || document.body}
          placeholder={
            <FormattedMessage id='misc.custom' defaultMessage='Custom' />
          }
        />
      </div>
    </Space>
  );
};

export default TasksQuickFiltersOwnership;
