import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { OwnersMode } from 'pages/ObjectClasses/components/ObjectClassForm/ObjectClassPermissions/components/AddUsersPanel/types';
import { OwnerToDeleteType } from '../../types';
import { generatePath, useHistory } from 'react-router-dom';
import { OBJECT_RECORD_DETAILS, OBJECT_RECORD_OWNER } from 'utils/endpoints';
import { apiCall } from 'utils/api';
import { StatusCodes } from 'http-status-codes';
import errorToast from 'utils/functions/errorToast';
import useTokenDecode from 'hooks/useTokenDecode';
import { getRemoveOwnerCallback } from 'store/selectors/objectsSelector';
import routes from 'utils/routingPaths';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import usePreviousState from 'hooks/usePreviousState';
import { Input as AntInput } from 'antd';
import { getTablesViewMode } from 'store/selectors/preferencesSelectors';
import TablesType from 'utils/Enums/TablesType';
import { RefetchResources, ViewMode } from 'contexts/types';
import { getObjectRecords } from 'store/actions/objectRecordsActions';
import { getPermissions } from 'store/actions/permissionsActions';
import {
  getObjectRecordUsers,
  setObjectRecordUsersError,
} from 'store/actions/objectRecordActions';
import {
  getClassPermissionSets,
  getObjectRecordUsersData,
  getObjectRecordUsersError,
  getObjectRecordUsersLoading,
} from 'store/selectors/objectRecordSelectors';
import { SearchDataParams } from 'components/SearchInput/types';
import { updatePreferences } from 'store/actions/preferencesActions';
import useCheckAccessToSelectedObjectClass from 'hooks/useCheckAccessToSelectedObjectClass';
import useCloseRecordPanels from 'hooks/useCloseRecordPanels';
import { useIntl } from 'react-intl';
import { PermissionSetSelectOptions } from './types';
import usePermissionSetOptions from '../../hooks/usePermissionSetOptions';
import { PeopleListElementProps } from '../PeopleListElement/types';
import { useRefetchResourceContext } from 'contexts/RefetchResourceContext';
import { PreferencesTypes } from 'utils/types/api/preferences.types';

const useRecordOwnersPanel = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user_id: userId } = useTokenDecode() || {};
  const [isRemovingHimself, setIsRemovingHimself] = useState(false);
  const [isLoadingRemovingOwner, setIsLoadingRemovingOwner] = useState(false);
  const [selectedPermissionSet, setSelectedPermissionSet] = useState(
    PermissionSetSelectOptions.All
  );
  const { refetchData } = useRefetchResourceContext();
  const ref = useRef<HTMLDivElement>(null);

  const {
    options,
    loading: optionsLoading,
    error: optionsError,
  } = usePermissionSetOptions(selectedPermissionSet);

  const {
    [TablesType.ObjectRecords]: objectRecordsViewMode = ViewMode.Cards,
  } = useSelector(getTablesViewMode);
  const classPermissionSets = useSelector(getClassPermissionSets);
  const removeOwnerCallback = useSelector(getRemoveOwnerCallback);
  const {
    checkAccessToSelectedObjectClassId,
  } = useCheckAccessToSelectedObjectClass();
  const { closeRecordPanels } = useCloseRecordPanels();

  const {
    selectedResource: {
      objectClassId = '0',
      record: { recordId = undefined, identifier = '' } = {},
    } = {},
  } = useSelectedResourceContext();

  const error = useSelector(getObjectRecordUsersError);
  const isFetchingOwners = useSelector(getObjectRecordUsersLoading);
  const { results: data, total } = useSelector(
    getObjectRecordUsersData(recordId)
  );

  const sortedData = useMemo(
    () =>
      data?.sort((a, b) => {
        const name1 = (
          a.firstName ||
          a.lastName ||
          a.company ||
          ''
        ).toLowerCase();
        const name2 = (
          b.firstName ||
          b.lastName ||
          b.company ||
          ''
        ).toLowerCase();

        if (name1 > name2) return 1;
        if (name1 < name2) return -1;
        return 0;
      }),
    [data]
  );

  const getUsers = () => {
    if (recordId) {
      dispatch(
        getObjectRecordUsers(recordId, selectedPermissionSet, objectClassId)
      );
    }
  };

  const [debouncedSearchValue, setSebouncedSearchValue] = useState('');
  const searchData = ({ value }: SearchDataParams) => {
    setSebouncedSearchValue(value);
  };

  const handleSelectPermissionSet = (value: number) => {
    setSelectedPermissionSet(value);
  };

  const permissionSetSelectOptions = useMemo(
    () => [
      {
        value: PermissionSetSelectOptions.All,
        label: intl.formatMessage({
          id: 'filterSelect.all',
          defaultMessage: 'All',
        }),
      },
      {
        value: PermissionSetSelectOptions.Owners,
        label: intl.formatMessage({
          id: 'filterSelect.owner',
          defaultMessage: 'Owner',
        }),
      },
      ...(classPermissionSets
        .map(({ id, name }) => ({ value: id, label: name }))
        .sort((a, b) => {
          const aLabel = a.label.toLocaleLowerCase();
          const bLabel = b.label.toLocaleLowerCase();

          if (aLabel > bLabel) return 1;
          if (aLabel < bLabel) return -1;
          return 0;
        }) || []),
    ],
    [classPermissionSets, intl]
  );

  const permissionSetNames = useMemo<MappedObject<string, number>>(
    () =>
      permissionSetSelectOptions?.reduce(
        (total, curr) => ({ ...total, [curr.value]: curr.label }),
        {}
      ) || {},
    [permissionSetSelectOptions]
  );

  const selectedPermissionSetName = useMemo(
    () =>
      permissionSetSelectOptions?.find(
        ({ value }) => value === selectedPermissionSet
      )?.label || '',
    [permissionSetSelectOptions, selectedPermissionSet]
  );

  const itemsLimit = options?.restrictions?.limit_items;

  const filteredValue = useMemo(
    () =>
      (debouncedSearchValue.length
        ? sortedData.filter(({ firstName, lastName }) =>
            `${firstName} ${lastName}`
              .toLowerCase()
              .includes(debouncedSearchValue.toLowerCase())
          )
        : sortedData) || [],
    [debouncedSearchValue, sortedData]
  );
  const filteredCount = filteredValue.length;

  const searchInputRef = useRef<AntInput | null>(null);
  const searchValue = searchInputRef?.current?.state?.value;
  const prevId = usePreviousState(recordId);
  const objectRecordId = (recordId ?? '') as string;

  const isLimitExceeded =
    options?.restrictions?.limit_items !== undefined &&
    total >= options.restrictions.limit_items;

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPermissionSet, debouncedSearchValue]);

  useEffect(() => {
    if (!prevId) {
      getUsers();

      return;
    }

    if (prevId !== recordId && prevId !== undefined) {
      // need to clear search state because it is shared within all records
      const search = searchInputRef?.current;

      if (search?.state?.value) {
        // handleChange trigers fetchData so we avoid to call it directly
        //@ts-ignore
        search.handleChange({ target: { value: '' } });
      } else {
        getUsers();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId]);

  const isRecordDetalis = /\/workspace\/records\/\d+/.test(
    history.location.pathname
  );

  const [ownerToDelete, setOwnerToDelete] = useState<OwnerToDeleteType>();

  const openAddOwnersPanel = useCallback(
    () =>
      dispatch(
        setSidebarData(FlexLayoutWindows.RecordAccess, {
          mode: OwnersMode.Adding,
          selectedPermissionSet:
            selectedPermissionSet === PermissionSetSelectOptions.All
              ? PermissionSetSelectOptions.Owners
              : selectedPermissionSet,
        })
      ),
    [dispatch, selectedPermissionSet]
  );

  const onDeleteFabric = ({
    ownershipId,
    userGroup,
    firstName,
    lastName,
    userId: idToRemove,
  }: PeopleListElementProps) => () => {
    if (!ownershipId) return;

    setIsRemovingHimself(userId === idToRemove);

    setOwnerToDelete({
      ownershipId,
      displayName: userGroup ? userGroup : `${firstName} ${lastName}`,
    });
  };

  const checkAccessToObjectRecord = useCallback(async () => {
    try {
      const { status } = await apiCall.get(
        generatePath(OBJECT_RECORD_DETAILS, {
          id: objectRecordId,
        })
      );

      if (status === StatusCodes.OK) return true;
    } catch {}

    return false;
  }, [objectRecordId]);

  const removeObjectRecordOwner = useCallback(
    async (objectRecordId: string, ownershipId: number) => {
      setIsLoadingRemovingOwner(true);

      try {
        const { status } = await apiCall.delete(
          generatePath(OBJECT_RECORD_OWNER, {
            objectRecordId,
            ownershipId,
          })
        );

        const hasAccessToObjectRecord = await checkAccessToObjectRecord();

        dispatch(
          getPermissions(async data => {
            if (isRemovingHimself && !hasAccessToObjectRecord)
              closeRecordPanels(!data?.object_records?.list);

            if (!data?.object_records?.list) {
              history.push(routes.WORKSPACE);
            } else {
              if (status === StatusCodes.NO_CONTENT) {
                dispatch(
                  getObjectRecordUsers(
                    recordId,
                    selectedPermissionSet,
                    objectClassId
                  )
                );
                refetchData(RefetchResources.RecordAccess);
              }

              if (
                isRemovingHimself &&
                !hasAccessToObjectRecord &&
                isRecordDetalis
              ) {
                history.push(routes.RECORDS);
              }

              dispatch(setObjectRecordUsersError(undefined));

              if (objectRecordsViewMode === ViewMode.Cards) {
                if (removeOwnerCallback) removeOwnerCallback();
              } else {
                dispatch(getObjectRecords());
              }

              if (isRemovingHimself && !isRecordDetalis) {
                const hasStillAccessToObjectClass = await checkAccessToSelectedObjectClassId();

                if (!hasStillAccessToObjectClass) {
                  dispatch(
                    updatePreferences(PreferencesTypes.GeneralPreferences, {
                      selectedClassId: undefined,
                    })
                  );
                }
              }
            }
          })
        );
      } catch {
        errorToast();
      } finally {
        setIsLoadingRemovingOwner(false);
      }
    },
    [
      checkAccessToObjectRecord,
      dispatch,
      isRemovingHimself,
      closeRecordPanels,
      history,
      isRecordDetalis,
      objectRecordsViewMode,
      recordId,
      selectedPermissionSet,
      objectClassId,
      refetchData,
      removeOwnerCallback,
      checkAccessToSelectedObjectClassId,
    ]
  );

  const limitErrorMessage = useMemo(
    () =>
      selectedPermissionSet === PermissionSetSelectOptions.Owners
        ? intl.formatMessage(
            {
              id: 'recordAccess.ownersLimitReached',
              defaultMessage:
                'Maximum {limit} {limit, plural, one {owner} other {owners}} in {identifier} has been reached.',
            },
            {
              limit: itemsLimit,
              identifier,
            }
          )
        : intl.formatMessage(
            {
              id: 'recordAccess.assigneesLimitReached',
              defaultMessage:
                'Maximum {limit} {limit, plural, one {assignee} other {assignees}} in {name} has been reached.',
            },
            {
              limit: itemsLimit,
              name: selectedPermissionSetName,
            }
          ),
    [
      identifier,
      intl,
      itemsLimit,
      selectedPermissionSet,
      selectedPermissionSetName,
    ]
  );

  const limitWarningMessage = useMemo(
    () =>
      selectedPermissionSet === PermissionSetSelectOptions.Owners
        ? intl.formatMessage(
            {
              id: 'recordAccess.ownersLimitWarning',
              defaultMessage:
                '{identifier} can have up to {limit} {limit, plural, one {owner} other {owners}}. This currently has {usersSum}.',
            },
            {
              limit: itemsLimit,
              identifier,
              usersSum: total,
            }
          )
        : intl.formatMessage(
            {
              id: 'recordAccess.assigneesLimitWarning',
              defaultMessage:
                '{name} can have up to {limit} {limit, plural, one {assignee} other {assignees}}. This currently has {usersSum}.',
            },
            {
              limit: itemsLimit,
              name: selectedPermissionSetName,
              usersSum: total,
            }
          ),
    [
      identifier,
      intl,
      itemsLimit,
      selectedPermissionSet,
      selectedPermissionSetName,
      total,
    ]
  );

  const giveAccessTooltipMessage = useMemo(
    () =>
      selectedPermissionSet === PermissionSetSelectOptions.Owners
        ? intl.formatMessage({
            id: 'owners.disabledButtonMessage',
            defaultMessage:
              'The maximum number of owners has been reached. Remove owners to add new ones.',
          })
        : intl.formatMessage({
            id: 'owners.disabledButtonMessageForAssignees',
            defaultMessage:
              'The maximum number of assignees has been reached. Remove assignees to add new ones.',
          }),
    [intl, selectedPermissionSet]
  );

  return {
    openAddOwnersPanel,
    onDeleteFabric,
    isRemovingHimself,
    ownerToDelete,
    isLoadingRemovingOwner,
    setOwnerToDelete,
    removeObjectRecordOwner,
    objectRecordId,
    isLimitExceeded,
    error: error || optionsError,
    searchValue,
    filteredCount,
    identifier,
    isFetchingOwners,
    loading: isFetchingOwners || isLoadingRemovingOwner || optionsLoading,
    data: filteredValue,
    total,
    searchData,
    searchInputRef,
    ref,
    permissionSetSelectOptions,
    selectedPermissionSet,
    handleSelectPermissionSet,
    permissionSetNames,
    getUsers,
    limitErrorMessage,
    limitWarningMessage,
    itemsLimit,
    giveAccessTooltipMessage,
    options,
  };
};

export default useRecordOwnersPanel;
