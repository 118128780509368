import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { getInPlaceEditFontSize } from '../components/commonStyles';

type UseInPlaceEditSingleSelectStylesProps = {
  size?: 'small' | 'medium';
  errorMessage?: string;
};

const useInPlaceEditSingleSelectStyles = createUseStyles(
  (theme: ThemeState) => {
    return {
      singleLinePreview: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      radiosList: {
        background: 'white',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 4px 4px rgba(46, 63, 87, 0.3)',
        borderRadius: 3,
        padding: '0 !important',
        minWidth: 300,
        maxHeight: 400,
        overflowY: 'auto',
        overflowX: 'hidden',
      },
      radioWrapper: {
        display: 'flex',
        alignItems: 'baseline',
        padding: '4px 0',
        whiteSpace: 'break-spaces',
        fontSize: ({ size }: UseInPlaceEditSingleSelectStylesProps = {}) =>
          getInPlaceEditFontSize(size),
        lineHeight: 1.3,
      },
      singleSelectWrapper: {
        padding: [0, 2],
        '& .ant-spin': {
          right: -20,
          top: 4,
          position: 'absolute',
        },
      },
      dropdownWrapper: {
        width: 350,
        height: 25,
        '&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector': {
          height: 25,
          border: 'none',
        },
        '& .ant-select-arrow': {
          top: 12,
        },
        '&.ant-select-open': {
          borderColor: ({
            errorMessage,
          }: UseInPlaceEditSingleSelectStylesProps = {}) =>
            `${!!errorMessage ? theme['error-color'] : theme['primary-color']}`,
          boxShadow: ({
            errorMessage,
          }: UseInPlaceEditSingleSelectStylesProps = {}) =>
            `0px 0px 0px 1px ${
              !!errorMessage ? theme['error-color'] : theme['primary-color']
            }`,
          borderRadius: 3,
        },
        '&.ant-select-single .ant-select-selection-item': {
          fontSize: 14,
          lineHeight: 2,
          color: theme['heading-color'],
        },
      },
      dropdownOptionsWrapper: {
        '& .ant-select-item-option-selected:not(.ant-select-item-option-disabled)': {
          backgroundColor: `${theme['disabled-ant-bg-color']}`,
          color: `${theme['primary-color']}`,
        },
        '& .ant-select-item': {
          fontSize: 14,
          lineHeight: 1.3,
          minHeight: 30,
          alignItems: 'center',
        },
      },
      dropdownOptionsWithError: {
        '& .rc-virtual-list': {
          boxShadow: 'rgb(46 63 87 / 20%) 0px 2px 4px',
          paddingBottom: 4,
        },
      },
      dropdownError: {
        boxShadow: 'none',
        fontSize: 12,
        lineHeight: 1.3,
        padding: '10px 8px 4px',
      },
      radiosOptions: {
        border: ({
          errorMessage,
        }: UseInPlaceEditSingleSelectStylesProps = {}) =>
          !!errorMessage ? `1px solid ${theme['error-color']}` : 'none',
        padding: 10,
        borderRadius: '3px 3px 0 0',
      },
      dropdownOverlay: {
        zIndex: 1060,
      },
      selectContainer: {
        width: '100%',
        position: 'relative',

        '& > span': {
          opacity: 0,
        },

        '&:hover > span': {
          opacity: 1,
        },

        '&:hover .ant-select-selector': {
          borderColor: '#028FDF',
        },
      },
      selectClearIconWrapper: {
        position: 'absolute',
        top: '50%',
        right: '11px',
        transform: 'translateY(-50%)',
        height: '14px',
        width: '14px',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        color: 'rgba(46, 63, 87, 0.6)',
        cursor: 'pointer',
      },
    };
  }
);

export default useInPlaceEditSingleSelectStyles;
