import { FormValuePrimative, WidgetProps } from 'alx-dynamic-form';
import { InPlaceEditProps } from 'components/InPlaceEditControls/types';
import { FB_FORM_MODAL_WRAPPER } from 'utils/elementsIds';

const remountChangeTarget = () =>
  document.getElementById(FB_FORM_MODAL_WRAPPER) || document.body;

export const mapCustomWidgetPropsToBaseInPlaceEditProps = <
  V extends FormValuePrimative,
  R = any
>(
  widgetProps: WidgetProps
): InPlaceEditProps<V, R> => {
  const {
    value,
    label,
    required,
    disabled,
    isProperty,
    additionalFieldProps,
    fieldAlias,
    onChange,
    fieldId,
  } = widgetProps;

  const {
    inPlaceEditUrl,
    onInPlaceSaveSuccess,
    readOnly,
    inPlaceEditSize: size,
    getPopupContainer,
    objectClassId,
  } = additionalFieldProps || {};

  const onInPlaceSaveSuccessProxy = (
    response: R,
    value: V | null | undefined
  ) => {
    onChange(value ?? null);

    if (onInPlaceSaveSuccess) onInPlaceSaveSuccess(response, value);
  };

  return {
    propertyName: fieldAlias,
    value: value as V,
    label,
    required,
    disabled: isProperty || disabled,
    patchUrl: inPlaceEditUrl,
    onSaveSuccess: onInPlaceSaveSuccessProxy,
    getPopupContainer,
    remountChangeTarget,
    readOnly,
    size,
    fieldId,
    objectClassId,
  };
};
