import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import {
  headerWrapper,
  getResultsInfo,
  panelWrapper,
} from 'styles/panelsStyles';

const useDefaultAssigneesStyles = createUseStyles((theme: ThemeState) => {
  return {
    resultsInfo: getResultsInfo(theme),
    panelWrapper: {
      ...panelWrapper,
      justifyContent: 'space-between',
    },
    addDefaultAssigneesPanel: {
      ...panelWrapper,
      padding: 0,
      '& .ant-spin-nested-loading, & .ant-spin-container': {
        height: '100%',
      },
    },
    headerWrapper,
    cancelButton: {
      display: 'flex',
      alignSelf: 'flex-end',
    },
  };
});

export default useDefaultAssigneesStyles;
