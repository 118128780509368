import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useResizerStyles } from './styles';
import { ResizerProps } from './types';
import { TableDensity } from 'components/Table/enums';
import { useSelector } from 'react-redux';
import { getTableDensity } from 'store/selectors/preferencesSelectors';

const Resizer = ({ onMouseDownCallback, disabled }: ResizerProps) => {
  const onMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (disabled) {
        return;
      }
      if (onMouseDownCallback) onMouseDownCallback(event);
      document.body.setAttribute('style', 'cursor: col-resize !important');
    },
    [onMouseDownCallback, disabled]
  );
  const density = useSelector(getTableDensity) ?? TableDensity.Default;
  const classes = useResizerStyles({ density });

  return (
    <div
      className={clsx([classes.resizer, { disabled: disabled }, 'resizer'])}
      onMouseDown={onMouseDown}
    />
  );
};

export default Resizer;
