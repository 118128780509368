import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type CopyTextStylesProps = {
  buttonHeight?: number;
};

export const useCopyTextStyles = createUseStyles((theme: ThemeState) => {
  return {
    wrapper: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      '& > div:nth-child(2)': {
        width: 24, // show tooltip
      },
    },
    button: ({ buttonHeight }: CopyTextStylesProps) => ({
      border: `1px solid ${theme['border-color-base']}`,
      borderRightWidth: 2,
      padding: [1, 3],
      borderRadius: 3,
      height: buttonHeight ?? 24,
      lineHeight: '20px',
    }),
    buttonWrapper: {
      width: 24,
    },
    textWrapper: {
      flex: 1,
      maxWidth: 'calc(100% - 24px)',
    },
    copyIcon: {
      color: theme['layout-trigger-color'],
    },
  };
});
