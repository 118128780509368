import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { languageSelector } from 'store/selectors/preferencesSelectors';
import { AntLanguages } from 'utils/Enums/SupportedLanguages';

const useAntLanguage = () => {
  const currentLanguage = useSelector(languageSelector);

  return useMemo(() => AntLanguages[currentLanguage], [currentLanguage]);
};

export default useAntLanguage;
