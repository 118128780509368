import React from 'react';
import SummaryPanel from '../SummaryPanel';
import { SummaryPanelSource } from '../SummaryPanel/types';
import ChildRecordSummaryPanelHeader from './components/ChildRecordSummaryPanelHeader';
import ChildRecordSummaryPermissionDenied from './components/ChildRecordSummaryPanelPermissionDenied';

const ChildRecordSummaryPanel = () => (
  <SummaryPanel
    dataSource={SummaryPanelSource.ADDITIONAL_DATA}
    RenderHeader={ChildRecordSummaryPanelHeader}
    RenderPermissionDenied={ChildRecordSummaryPermissionDenied}
  />
);

export default ChildRecordSummaryPanel;
