import { ReactComponent as InputIcon } from 'img/icons/textbox-icon.svg';
import { ReactComponent as NumericIcon } from 'img/icons/numeric-icon.svg';
import { ReactComponent as SingleSelectIcon } from 'img/icons/single-select-icon.svg';
import { ReactComponent as DatepickerIcon } from 'img/icons/datepicker-icon.svg';
import { ReactComponent as EmailIcon } from 'img/icons/at-icon.svg';
import { ReactComponent as PhoneIcon } from 'img/icons/phone-icon.svg';
import { ReactComponent as MultiselectIcon } from 'img/icons/multiselect-icon.svg';
import { ReactComponent as SectionIcon } from 'img/icons/section-icon.svg';
import { ReactComponent as CheckboxIcon } from 'img/icons/checkbox-icon.svg';
import { ReactComponent as UserIcon } from 'img/icons/user-icon.svg';
import { ReactComponent as FileUploadIcon } from 'img/icons/file-upload.svg';
import { ReactComponent as URLIcon } from 'img/icons/url-icon.svg';
import { ReactComponent as JSONIcon } from 'img/icons/json-icon.svg';
import { DraggableComponents } from './enums';

export const getFormBuilderFieldIcon = (type?: string) => {
  switch (type) {
    case 'string':
      return InputIcon;
    case 'url':
      return URLIcon;
    case 'int':
    case 'float':
      return NumericIcon;
    case 'enum':
      return SingleSelectIcon;
    case 'time':
    case 'datetime':
    case 'date':
    case 'creationtime':
      return DatepickerIcon;
    case 'phone':
      return PhoneIcon;
    case 'set':
      return MultiselectIcon;
    case 'email':
      return EmailIcon;
    case 'bool':
      return CheckboxIcon;
    case 'array':
      return UserIcon;
    case 'document':
      return FileUploadIcon;
    case 'json':
      return JSONIcon;
    case 'user':
      return UserIcon;

    default:
      return SectionIcon;
  }
};

export const getComponentTypeFromFieldType = (type: string) => {
  switch (type) {
    case 'string':
      return DraggableComponents.Input;
    case 'url':
      return DraggableComponents.URL;
    case 'int':
    case 'float':
      return DraggableComponents.Numeric;
    case 'enum':
      return DraggableComponents.Radio;
    case 'time':
    case 'datetime':
    case 'date':
    case 'creationtime':
      return DraggableComponents.DateTimePicker;
    case 'phone':
      return DraggableComponents.PhoneNumber;
    case 'set':
      return DraggableComponents.Multiselect;
    case 'email':
      return DraggableComponents.Email;
    case 'bool':
      return DraggableComponents.Checkbox;
    case 'array':
      return DraggableComponents.Owners;
    case 'document':
      return DraggableComponents.File;
    case 'json':
      return DraggableComponents.JSON;
    case 'user':
      return DraggableComponents.User;

    default:
      return DraggableComponents.Input;
  }
};
