import React from 'react';
import { FormattedMessage } from 'react-intl';
import useHiddenMessageStyles from './styles';

const HiddenMessage = () => {
  const classes = useHiddenMessageStyles({});

  return (
    <span className={classes.text}>
      <FormattedMessage id='misc.hidden' defaultMessage='Hidden' />
    </span>
  );
};

export default HiddenMessage;
