/*
This file is responsible for setting all possible languages in app.
Each language should have import its ant language file & moment language file
Moment en-us is default imported
*/

import antEn from 'antd/lib/locale/en_US';
import customizedLanguages from 'lang/customizedAntLanguages';
import 'moment/locale/pl';
import 'moment/locale/en-gb';

enum SupportedLanguages {
  enGB = 'en-GB',
  enUS = 'en-US',
  pl = 'pl-PL',
}

export const LanguageFullStrings = {
  [SupportedLanguages.enGB]: 'English (UK)',
  [SupportedLanguages.enUS]: 'English (USA)',
  [SupportedLanguages.pl]: 'Polish',
};

export const AntLanguages = {
  [SupportedLanguages.enGB]: antEn,
  [SupportedLanguages.enUS]: antEn,
  [SupportedLanguages.pl]: customizedLanguages.pl,
};

export default SupportedLanguages;
