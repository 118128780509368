import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonPrimary, ButtonSecondaryOutlined } from 'components/lib/Button';
import { ADD_ASSIGNEES_BTN, BUTTON_CLOSE_TESTID } from 'utils/testIds';
import { useTasksPanelContext } from '../../TasksPanelModeContext';
import { TasksPanelMode } from '../../types';
import FormCategory from 'pages/TaskTemplates/components/FormCategory';
import { AvatarItem } from 'components/lib/Avatar/types';
import PeopleListElement from 'pages/Records/RecordsListing/RecordAccessPanel/components/PeopleListElement';
import List from 'components/List';
import { Loader } from 'components/lib/Loader';
import ErrorComponent from 'components/ErrorComponent';
import { useStagesAssignees } from './hooks';
import useReassignTaskStyles from './styles';
import { CheckIcon, PlusIcon } from 'components/Icon';
import ReassignTaskHeader from './ReassignTaskHeader';
import { StageNames } from 'utils/types/api/tasks.types';
import BackToTaskListWrapper from './BackToTaskListWrapper';
import ConfirmationModal from 'components/ConfirmationModal';
import WarningIllustration from 'img/illustrations/warning-triangle.svg';
import { DataToRemove } from '../types';
import FlexContentWrapper from 'components/wrappers/FlexContentWrapper';
import EmptyData from 'components/EmptyData';
import { ReactComponent as EmptyBox } from 'img/illustrations/empty-box.svg';
import { useTaskOptions } from '../hooks/useTaskOptions';
import LimitMessage from 'pages/ObjectClasses/components/ObjectClassForm/ObjectClassPermissions/components/LimitMessage';
import Tooltip from 'components/lib/Tooltip';
import { selectLimitForUserGroup } from './utils';
import { AccountTypeOptions } from 'pages/Users/enums';

const ReassignTask = () => {
  const classes = useReassignTaskStyles({});
  const { setMode, setStage } = useTasksPanelContext();

  const intl = useIntl();
  const [assigneeToRemove, setAssigneeToRemove] = useState<
    undefined | DataToRemove
  >();
  const {
    error,
    loading,
    stagesAssignees,
    taskDetails,
    fetchData,
    handleDeleteAssignee,
    mappedSectionName,
  } = useStagesAssignees(setAssigneeToRemove);

  const stageAssignees = stagesAssignees?.find(
    ({ id }) => id === StageNames.Stage1
  );

  const { options } = useTaskOptions();

  const limit =
    selectLimitForUserGroup(
      stageAssignees?.users,
      options?.stage1?.limit_assignees_one_time_completion,
      options?.stage1?.limit_assignees_standard
    ) ?? 100;

  const isLimitAchieved = (stageAssignees?.users?.length ?? 0) >= limit;

  const tooltipTitle = useMemo(
    () =>
      !!stageAssignees?.users?.find(
        assignee =>
          assignee.accountType === AccountTypeOptions.OneTimeCompletion
      )
        ? intl.formatMessage({
            id: 'owners.maximumNumberReached1TC',
            defaultMessage:
              'A one-time completion user has been assigned, therefore no further user can be assigned.',
          })
        : intl.formatMessage({
            id: 'owners.maximumNumberReached',
            defaultMessage:
              'The maximum number of assignees has been reached. Remove assignees to add new ones.',
          }),
    [intl, stageAssignees]
  );

  if (error) return <ErrorComponent error={error?.status} />;

  const handleAddAssignees = () => {
    setMode(TasksPanelMode.AddAssignees);
    setStage({
      name: StageNames.Stage1,
      assignees: stageAssignees?.users,
      fetchTask: fetchData,
    });
  };

  return (
    <BackToTaskListWrapper>
      <Loader spinning={loading}>
        <div className={classes.reassigneTaskPanelWrapper}>
          <div className={classes.contentWrapper}>
            <ReassignTaskHeader
              taskName={taskDetails?.name}
              recordId={taskDetails?.object_record}
            >
              <Tooltip
                placement='topRight'
                shouldBeRendered={isLimitAchieved}
                title={tooltipTitle}
                addOverlay
              >
                <div>
                  <ButtonPrimary
                    disabled={isLimitAchieved}
                    onClick={handleAddAssignees}
                    icon={<PlusIcon size={9} />}
                    data-testid={ADD_ASSIGNEES_BTN}
                  >
                    <FormattedMessage
                      id='tasksPanel.addAssignees'
                      defaultMessage='Add assignees'
                    />
                  </ButtonPrimary>
                </div>
              </Tooltip>
            </ReassignTaskHeader>
            <div className={classes.listWrapper}>
              {stagesAssignees?.length ? (
                stagesAssignees.map(({ id, name, users }) => (
                  <FormCategory
                    key={id}
                    collapsible
                    noMarginTop
                    collapsibleContent={
                      <div className={classes.stageWrapper}>
                        <div className={classes.usersInfoContainer}>
                          <p className={classes.resultsCounter}>
                            <FormattedMessage
                              id='tasksPanel.usersCount'
                              defaultMessage='{count} {count, plural, one {user} other {users}}'
                              values={{ count: users?.length }}
                            />
                          </p>
                          <LimitMessage
                            itemsLimit={limit}
                            count={users?.length ?? 0}
                            customErrorMessage={intl.formatMessage(
                              {
                                id: 'tasksPanel.assigneesLimitErrorMessage',
                                defaultMessage:
                                  'Maximum of {limit} {limit, plural, one {assignee} other {assignees}} has been reached.',
                              },
                              { limit }
                            )}
                            customWarningMessage={intl.formatMessage(
                              {
                                id: 'tasksPanel.assigneesLimitWarningMessage',
                                defaultMessage:
                                  'A stage can have up to {limit} {limit, plural, one {assignee} other {assignees}}. This currently has {current}.',
                              },
                              { limit, current: users?.length }
                            )}
                          />
                        </div>
                        <List<AvatarItem>
                          emptyDataDescription={intl.formatMessage({
                            id: 'tasksPanel.noAssigneesForThisStage',
                            defaultMessage: 'No assignees for this stage.',
                          })}
                          items={users}
                          renderItem={item => (
                            <PeopleListElement
                              {...item}
                              onDelete={() =>
                                setAssigneeToRemove({
                                  id: item.id,
                                  stage: name,
                                  userName: `${item.firstName} ${item.lastName}`,
                                  stageId: id,
                                })
                              }
                            />
                          )}
                        />
                      </div>
                    }
                  >
                    {name}
                  </FormCategory>
                ))
              ) : (
                <FormCategory
                  key={StageNames.Stage1}
                  collapsible
                  noMarginTop
                  collapsibleContent={
                    <FlexContentWrapper>
                      <EmptyData
                        title=''
                        description={intl.formatMessage({
                          id: 'tasksPanel.noAssigneesForThisStage',
                          defaultMessage: 'No assignees for this stage.',
                        })}
                        image={<EmptyBox />}
                      />
                    </FlexContentWrapper>
                  }
                >
                  {mappedSectionName[StageNames.Stage1]}
                </FormCategory>
              )}
            </div>
          </div>
          <div className={classes.buttonsWrapper}>
            <ButtonSecondaryOutlined
              onClick={() => setMode(TasksPanelMode.List)}
              data-testid={BUTTON_CLOSE_TESTID}
            >
              <FormattedMessage id='misc.close' defaultMessage='Close' />
            </ButtonSecondaryOutlined>
          </div>
        </div>
      </Loader>
      <ConfirmationModal
        title={intl.formatMessage(
          {
            id: 'tasksPanel.removeAssigneeConfirmationTitle',
            defaultMessage:
              'Are you sure you want to remove {name} from {stage}?',
          },
          {
            name: assigneeToRemove?.userName,
            stage: assigneeToRemove?.stageId?.replace('_', ' '),
          }
        )}
        subtitle={intl.formatMessage({
          id: 'tasksPanel.removeAssigneeConfirmationSubtitle',
          defaultMessage: 'They may lose access to this task.',
        })}
        image={WarningIllustration}
        confirmationButtonIcon={<CheckIcon size={12} />}
        confirmationButtonLabel={intl.formatMessage({
          id: 'misc.yesRemove',
          defaultMessage: 'Yes, remove',
        })}
        withCancel
        onClose={() => setAssigneeToRemove(undefined)}
        onConfirm={() =>
          !!assigneeToRemove && handleDeleteAssignee(assigneeToRemove)
        }
        visible={!!assigneeToRemove}
      />
    </BackToTaskListWrapper>
  );
};

export default ReassignTask;
