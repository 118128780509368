import { toast } from 'components/lib/toast';
import ToastType from 'utils/Enums/ToastType';
import globalIntlSingleton from '../providers/IntlProviderWrapper/globalIntlSingleton';

const useSuccessToast = () => {
  const commonMessage = {
    title: globalIntlSingleton.intl.formatMessage({
      id: 'misc.success',
      defaultMessage: 'Success!',
    }),
    subtitle: globalIntlSingleton.intl.formatMessage({
      id: 'misc.changesSaved',
      defaultMessage: 'Your changes have been saved successfully.',
    }),
  };

  return (message?: { title: string; subtitle: string }) => {
    toast(message ? message : commonMessage, ToastType.Success);
  };
};

export default useSuccessToast;
