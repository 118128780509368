import {
  getCurrentTableFilters,
  getCurrentTable,
} from 'store/selectors/filtersSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import {
  setFilters,
  setApplyFilter,
  FilterValue,
  resetCurrentFilters,
  setAppliedFiltersForTable,
  clearFilters,
  removeFilterColumn,
} from 'store/actions/filtersActions';
import usePredicates from 'components/Filters/usePredicates';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import usePreviousState from 'hooks/usePreviousState';
import { ClassID, ColumnsMetadata } from 'utils/types/api/table.types';
import { withoutEmptyPredicates } from 'utils/functions/withoutEmptyPredicates';
import {
  toggleSidebar,
  setSidebarWidth,
} from 'store/actions/flexLayoutActions';
import { filtersWrapper } from 'utils/elementsIds';
import useFlexLayoutWindow from 'hooks/useFlexLayoutWindow';
import useAppliedFilters from 'hooks/useAppliedFilters';
import useCurrentTableSupportedColumns from 'hooks/useCurrentTableSupportedColumns';
import isEmpty from 'lodash/isEmpty';
import { PredicateSet } from 'components/Filters/ColumnSelect/types';
import TablesType from '../../utils/Enums/TablesType';

const useFilterEditor = (classID?: ClassID) => {
  const dispatch = useDispatch();
  const filters = useSelector(getCurrentTableFilters) || [];
  const prevFilters = usePreviousState(filters) || [];
  const appliedFilters = useAppliedFilters();
  const currentTable = useSelector(getCurrentTable);
  const columns = useCurrentTableSupportedColumns(classID);

  const PREDICATES = usePredicates();
  const [showClearFilterModal, setShowClearFilterModal] = useState(false);
  const filtersSidebarWidth = useRef<null | DOMRect>(null);
  const { isExternal, onOpenTab } = useFlexLayoutWindow(
    FlexLayoutWindows.FilterEditor
  );

  const [tmpPredicateSet, setTmpPredicateSet] = useState<PredicateSet>();

  const onPredicateSetChange = useCallback(
    (predicateSet: PredicateSet | undefined) =>
      setTmpPredicateSet(predicateSet),
    []
  );

  const onCreateFilter = useCallback(
    data => {
      const columnName = columns[data.value ?? data]?.alias ?? data.key; //verify if selected column exists in already redux-stored columns

      if (currentTable) {
        const newFilters: FilterValue[] = !filters.length
          ? [
              ...filters,
              {
                column: columnName,
                type: columns[columnName]?.type ?? data.field_type, //if selected column isn't in redux store, select type based on incoming data (only data from classfields contains such info)
                predicateSet: tmpPredicateSet,
                label: columns[columnName]?.label ?? data.label,
                predicates: columns[columnName]?.predicates ?? data.predicates,
                values: columns[columnName]?.values ?? data.values,
                value: {
                  predicateKey: '',
                  predicateValues: {},
                  predicateArgs: [],
                },
              },
            ]
          : [
              {
                column: '',
                type: '',
                label: data.label,
                predicateSet: tmpPredicateSet,
                predicates: [],
                value: {
                  predicateKey: '',
                  predicateValues: {},
                  predicateArgs: [],
                },
              },
              ...filters,
            ];

        dispatch(setFilters(currentTable, newFilters));
        setTmpPredicateSet(undefined);
      }
    },
    [columns, currentTable, dispatch, filters, tmpPredicateSet]
  );

  const areFiltersValid = (allowEmptyFilter = false) => {
    const allFilters = Object.values(filters);

    const hasError = allFilters.some(filter => {
      if (filter.column === '' || filter.value.predicateKey === '') {
        if (allowEmptyFilter && filter.column === '') return false;

        return true;
      }

      const predicate = PREDICATES[filter.type][filter.value.predicateKey];
      const filterPredicateValues = Object.values(filter.value.predicateValues);

      if (filterPredicateValues.length < predicate?.args?.length) {
        return true;
      }

      return filterPredicateValues.some(predicateValue => {
        if (
          (typeof predicateValue === 'string' &&
            predicateValue.trim() === '') ||
          (Array.isArray(predicateValue) && predicateValue.length === 0)
        ) {
          return true;
        }

        return false;
      });
    });

    return !hasError;
  };

  const removeEmptyFilters = useCallback(() => {
    if (currentTable) {
      const allFilters = Object.values(filters);
      const filledFilters = allFilters.filter(filter => filter.column !== '');
      dispatch(setFilters(currentTable, filledFilters));
    }
  }, [currentTable, dispatch, filters]);

  const resetFilters = useCallback(() => {
    dispatch(resetCurrentFilters());
    dispatch(setAppliedFiltersForTable({ id: currentTable, value: false }));
  }, [currentTable, dispatch]);

  const usedColumns = useMemo(
    () => (!!filters ? filters.map(filter => filter.column) : []),
    [filters]
  );

  const onApplyFilters = useCallback(() => {
    if (filters.length > 0 || prevFilters.length > 0 || appliedFilters) {
      if (filters.length === 0 && (prevFilters.length > 0 || appliedFilters)) {
        resetFilters();
      } else {
        removeEmptyFilters();
        dispatch(setApplyFilter(true, { sendToApi: true }));
      }
    }
  }, [
    dispatch,
    filters.length,
    prevFilters.length,
    removeEmptyFilters,
    resetFilters,
    appliedFilters,
  ]);

  const hasNoFilters = useMemo(
    () =>
      Object.values<ColumnsMetadata>(columns).every(
        ({ predicates, search_key }) => !predicates?.length || search_key
      ),
    [columns]
  );

  const onClearFilter = useCallback(() => {
    dispatch(clearFilters());
    resetFilters();
    setShowClearFilterModal(false);
  }, [dispatch, resetFilters]);

  const allFiltersSet = useMemo(() => {
    /* Filters in the records table are fetched from two separate endpoints.
     The data from autocomplete is stored only within it, so it's not possible to calculate the min limit */
    if (currentTable === TablesType.ObjectRecords) {
      return usedColumns.length >= 10;
    }

    const filterLimit = Math.min(
      10,
      Object.keys(columns).filter(key => withoutEmptyPredicates(columns)(key))
        .length
    );

    return filterLimit === usedColumns.length;
  }, [columns, currentTable, usedColumns.length]);

  const onClickClearFilters = useCallback(() => {
    if (!isExternal) {
      setShowClearFilterModal(true);
    } else {
      onClearFilter();
    }
  }, [isExternal, onClearFilter, setShowClearFilterModal]);

  useEffect(() => {
    if (filtersSidebarWidth.current === null) {
      const sidebarElement =
        window.document
          .querySelector(`#${filtersWrapper}`)
          ?.getBoundingClientRect() || null;

      filtersSidebarWidth.current = sidebarElement;

      if (sidebarElement) {
        dispatch(toggleSidebar(true));
        dispatch(setSidebarWidth(sidebarElement.width));
      }
    }
  }, [dispatch]);

  const onRemoveFilter = useCallback(
    async (columnName: string) => {
      dispatch(removeFilterColumn(currentTable || '', columnName));
      dispatch(
        setAppliedFiltersForTable({ id: currentTable, value: appliedFilters })
      );
    },
    [appliedFilters, currentTable, dispatch]
  );

  // hide columns which have empty PREDICATES
  const columnsWithFilters = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(columns).filter(
          ([_, { type }]) => !isEmpty(PREDICATES[type])
        )
      ),
    [PREDICATES, columns]
  );

  return {
    filters,
    onApplyFilters,
    onCreateFilter,
    columns: columnsWithFilters,
    areFiltersValid,
    onClearFilter,
    usedColumns,
    showFilters: onOpenTab,
    resetFilters,
    showClearFilterModal,
    setShowClearFilterModal,
    isExternal,
    hasNoFilters,
    allFiltersSet,
    onRemoveFilter,
    onClickClearFilters,
    currentTable,
    tmpPredicateSet,
    onPredicateSetChange,
  };
};

export default useFilterEditor;
