import { Space } from 'antd';
import clsx from 'clsx';
import {
  PreviousPageIcon,
  NextPageIcon,
  FirstPageIcon,
  LastPageIcon,
} from 'components/Icon';
import { ToolButtonWithTooltip } from 'components/ToolButton';
import { PaginationRender } from 'contexts/types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  TABLE_PAGINATION_GO_TO_FIRST_TESTID,
  TABLE_PAGINATION_GO_TO_LAST_TESTID,
  TABLE_PAGINATION_GO_TO_NEXT_TESTID,
  TABLE_PAGINATION_GO_TO_PREVIOUS_TESTID,
  TABLE_PAGINATION_WRAPPER_TESTID,
} from 'utils/testIds';
import useChildClassPaginationStyles from './styles';

const ChildClassPagination = ({
  isInfinite,
  lastPage,
  currentPage,
  loading,
  onNext,
  onPrevious,
  goToFirst,
  goToLast,
}: PaginationRender) => {
  const classes = useChildClassPaginationStyles({});
  return (
    <Space
      className={clsx(classes.paginationWrapper)}
      data-testid={TABLE_PAGINATION_WRAPPER_TESTID}
    >
      {!isInfinite && (
        <>
          <Space className={classes.buttonsWrapper}>
            <ToolButtonWithTooltip
              tooltipTitle={
                <FormattedMessage
                  id='misc.firstPage'
                  defaultMessage='First page'
                />
              }
              disabled={currentPage === 1 || loading}
              onClick={goToFirst}
              icon={<FirstPageIcon size={8} />}
              data-testid={TABLE_PAGINATION_GO_TO_FIRST_TESTID}
            />
            <ToolButtonWithTooltip
              tooltipTitle={
                <FormattedMessage
                  id='misc.previousPage'
                  defaultMessage='Previous page'
                />
              }
              disabled={currentPage === 1 || loading}
              onClick={onPrevious}
              icon={<PreviousPageIcon size={8} />}
              data-testid={TABLE_PAGINATION_GO_TO_PREVIOUS_TESTID}
            />
            <ToolButtonWithTooltip
              tooltipTitle={
                <FormattedMessage
                  id='misc.nextPage'
                  defaultMessage='Next page'
                />
              }
              onClick={onNext}
              disabled={currentPage >= lastPage || loading}
              icon={<NextPageIcon size={8} />}
              data-testid={TABLE_PAGINATION_GO_TO_NEXT_TESTID}
            />
            <ToolButtonWithTooltip
              tooltipTitle={
                <FormattedMessage
                  id='misc.lastPage'
                  defaultMessage='Last page'
                />
              }
              onClick={goToLast}
              disabled={currentPage >= lastPage || loading}
              icon={<LastPageIcon size={8} />}
              data-testid={TABLE_PAGINATION_GO_TO_LAST_TESTID}
            />
          </Space>
        </>
      )}
    </Space>
  );
};

export const ChildClassPaginationDummy = ({
  isInfinite,
  lastPage,
  currentPage,
  loading,
  onNext,
  onPrevious,
  goToFirst,
  goToLast,
}: PaginationRender) => {
  return null;
};

export const childClassTablePaginationWithHidden = (hidden: boolean) => {
  return (props: PaginationRender) => {
    if (hidden) {
      return <ChildClassPaginationDummy {...props} />;
    }
    return <ChildClassPagination {...props} />;
  };
};

export default ChildClassPagination;
