import React, { useEffect, useRef } from 'react';
import Tooltip from 'components/lib/Tooltip';
import useFlexLayoutStyles from '../../styles';
import { useIntl } from 'react-intl';
import { SIDE_PANEL_CLOSE_TAB_ICON_TESTID } from 'utils/testIds';
import { CloseIcon } from 'components/Icon';

const TabCloseIcon = () => {
  const intl = useIntl();
  const classes = useFlexLayoutStyles({});
  const closeButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (closeButtonRef.current?.parentElement?.removeAttribute)
      closeButtonRef.current.parentElement.removeAttribute('title');
  }, []);

  return (
    <div ref={closeButtonRef}>
      <Tooltip
        title={intl.formatMessage({
          id: 'misc.closeTab',
          defaultMessage: 'Close tab',
        })}
      >
        <div
          className={classes.closeWrapper}
          data-testid={SIDE_PANEL_CLOSE_TAB_ICON_TESTID}
        >
          <CloseIcon size={16} />
        </div>
      </Tooltip>
    </div>
  );
};

export default TabCloseIcon;
