export enum DraggableComponents {
  Input = 'shortAnswer',
  Textarea = 'longAnswer',
  Checkbox = 'checkbox',
  Radio = 'radio',
  Select = 'dropdown',
  Numeric = 'number',
  DateTimePicker = 'date',
  Email = 'email',
  CountrySelect = 'country-select',
  PhoneNumber = 'phoneNumber',
  File = 'file',
  Multiselect = 'multiselect',
  Section = 'section',
  Mock = 'mock',
  Instruction = 'instruction',
  Owners = 'owners',
  PropertyId = 'id',
  PropertyCreatedDateTime = 'created_at',
  PropertyUpdatedDateTime = 'modified_at',
  HTML = 'HTML',
  URL = 'url',
  JSON = 'json',
  User = 'user',
  ExternalComponent = 'external_content',
}
