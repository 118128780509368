import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useTaskOwnerStyles = createUseStyles((theme: ThemeState) => {
  return {
    avatarWrapper: {
      marginLeft: 1,
    },
    takeOwnership: {
      marginLeft: 8,
    },
    ownershipAction: {
      color: theme['primary-color'],
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  };
});

export default useTaskOwnerStyles;
