import React from 'react';
import Tooltip from 'components/lib/Tooltip';
import Button from 'components/lib/Button';
import PinIcon from 'img/icons/pin.svg';
import { PinProps } from './types';
import usePinStyles from './styles';
import { useIntl } from 'react-intl';
import { SIDEBAR_MENU_PIN_TESTID } from 'utils/testIds';

const Pin = ({ title, enabled, handleToggle }: PinProps) => {
  const classes = usePinStyles({ enabled });
  const intl = useIntl();

  return (
    <Tooltip placement='top' {...{ title }}>
      <Button
        className={classes.button}
        onClick={handleToggle}
        data-testid={SIDEBAR_MENU_PIN_TESTID}
        icon={
          <img
            src={PinIcon}
            alt={intl.formatMessage({
              id: 'enums.pin',
              defaultMessage: 'Pin',
            })}
          />
        }
      />
    </Tooltip>
  );
};

export default Pin;
