import CancelConfigurationModal from 'components/CancelConfigurationModal';
import ContentDrawer from 'components/ContentDrawer';
import TaskDisplay from 'components/TaskDisplay';
import { useConfirmationModalContext } from 'contexts/ConfirmationModalContext';
import noop from 'lodash/noop';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedTask } from 'store/actions/taskActions';
import { getSelectedTask } from 'store/selectors/taskSelectors';
import { useRefetchResourceContext } from 'contexts/RefetchResourceContext';
import { RefetchResources } from 'contexts/types';
import useCheckAccessToSelectedObjectClass from 'hooks/useCheckAccessToSelectedObjectClass';
import useCloseRecordPanels from 'hooks/useCloseRecordPanels';
import { StatusCodes } from 'http-status-codes';
import { generatePath, matchPath, useHistory } from 'react-router-dom';
import { setObjectRecordsSelectedRow } from 'store/actions/objectRecordsActions';
import { getPermissions } from 'store/actions/permissionsActions';
import { updatePreferences } from 'store/actions/preferencesActions';
import { apiCall } from 'utils/api';
import { OBJECT_RECORD_DETAILS } from 'utils/endpoints';
import routes from 'utils/routingPaths';
import { TASK_COMPLETE_FORM_MODAL_ID } from './consts';
import { AppDispatch } from 'store/store';
import { PreferencesTypes } from 'utils/types/api/preferences.types';

const TaskDisplayDrawer = () => {
  const history = useHistory();
  const { closeRecordPanels } = useCloseRecordPanels();
  const { refetchData } = useRefetchResourceContext();
  const {
    checkAccessToSelectedObjectClassId,
  } = useCheckAccessToSelectedObjectClass();
  const selectedTask = useSelector(getSelectedTask);
  const dispatch = useDispatch<AppDispatch>();
  const {
    shouldBeDisplayed,
    setShouldBeDisplayed,
    storedModalFunctions,
    setStoredModalFunctions,
  } = useConfirmationModalContext();

  const cleanupModal = useCallback(() => {
    setShouldBeDisplayed(TASK_COMPLETE_FORM_MODAL_ID, false);
    setStoredModalFunctions(TASK_COMPLETE_FORM_MODAL_ID, {});
  }, [setShouldBeDisplayed, setStoredModalFunctions]);

  const handleGetPermissions = useCallback(
    async (hasStillAccessToSelectedRecord: boolean) => {
      await dispatch(
        getPermissions(async ({ object_records }) => {
          if (!object_records?.list) {
            history.push(routes.WORKSPACE);

            return;
          }

          const isRecordDetailsPage = !!matchPath(history.location.pathname, {
            exact: true,
            path: routes.RECORD,
          });

          if (isRecordDetailsPage) {
            if (!hasStillAccessToSelectedRecord) history.push(routes.RECORDS);

            return;
          }

          // Records list
          if (!hasStillAccessToSelectedRecord) {
            await dispatch(setObjectRecordsSelectedRow(undefined));

            refetchData(RefetchResources.Records);
          }

          const hasStillAccessToObjectClass = await checkAccessToSelectedObjectClassId();

          if (!hasStillAccessToObjectClass)
            dispatch(
              updatePreferences(PreferencesTypes.GeneralPreferences, {
                selectedClassId: undefined,
              })
            );
        })
      );
    },
    [checkAccessToSelectedObjectClassId, dispatch, history, refetchData]
  );

  const onCloseNoPermissionsModal = useCallback(async () => {
    const checkAccessToSelectedRecord = async () => {
      try {
        const { status } = await apiCall.get(
          generatePath(OBJECT_RECORD_DETAILS, {
            id: selectedTask?.objectRecord,
          })
        );

        return status === StatusCodes.OK;
      } catch {
        return false;
      }
    };

    const hasStillAccessToSelectedRecord = await checkAccessToSelectedRecord();

    if (!hasStillAccessToSelectedRecord) closeRecordPanels();

    const isTasksListPage = !!matchPath(history.location.pathname, {
      exact: true,
      path: routes.TASKS,
    });

    if (isTasksListPage) return;

    await handleGetPermissions(hasStillAccessToSelectedRecord);
  }, [
    closeRecordPanels,
    handleGetPermissions,
    history.location.pathname,
    selectedTask,
  ]);

  const onTaskDrawerCloseClick = useCallback(
    async (shouldRefetchData?: boolean, validateModal = false) => {
      if (!shouldBeDisplayed?.[TASK_COMPLETE_FORM_MODAL_ID] || !validateModal) {
        cleanupModal();

        if (shouldRefetchData) {
          if (selectedTask?.refetchTasksList)
            await selectedTask.refetchTasksList();

          await onCloseNoPermissionsModal();
        }

        dispatch(clearSelectedTask());

        return;
      }

      setStoredModalFunctions(TASK_COMPLETE_FORM_MODAL_ID, {
        onSubmit: async () => {
          cleanupModal();

          if (shouldRefetchData && selectedTask?.refetchTasksList)
            await selectedTask.refetchTasksList();
          dispatch(clearSelectedTask());
        },
        callback: () =>
          setStoredModalFunctions(TASK_COMPLETE_FORM_MODAL_ID, {}),
      });
    },
    [
      shouldBeDisplayed,
      setStoredModalFunctions,
      cleanupModal,
      selectedTask,
      dispatch,
      onCloseNoPermissionsModal,
    ]
  );

  // clear selected task when unmounting the drawer (when page is changed)
  // so user doesnt have it open when he returns to previous page again
  // clear the need to display a modal
  useEffect(() => {
    return () => {
      dispatch(clearSelectedTask());
      cleanupModal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentDrawer
      isOpen={!!selectedTask}
      onCloseClick={() => onTaskDrawerCloseClick(false, true)}
    >
      {selectedTask?.id !== undefined && (
        <TaskDisplay
          taskId={selectedTask.id}
          onCancelClick={onTaskDrawerCloseClick}
          readOnly={selectedTask.readOnly}
        />
      )}
      <CancelConfigurationModal
        visible={
          !!shouldBeDisplayed?.[TASK_COMPLETE_FORM_MODAL_ID] &&
          !!storedModalFunctions?.[TASK_COMPLETE_FORM_MODAL_ID]?.onSubmit
        }
        onConfirm={
          storedModalFunctions?.[TASK_COMPLETE_FORM_MODAL_ID]?.onSubmit ?? noop
        }
        onCancel={
          storedModalFunctions?.[TASK_COMPLETE_FORM_MODAL_ID]?.callback ?? noop
        }
      />
    </ContentDrawer>
  );
};

export default TaskDisplayDrawer;
