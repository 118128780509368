import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { commonPredicates } from './commonPredicates';

export const useStringPredicates = () => {
  const intl = useIntl();

  return useMemo(
    () => ({
      is_empty: {
        label: intl.formatMessage({
          id: 'predicates.isEmpty',
          defaultMessage: 'is empty',
        }),
        value: 'is_empty',
        query: '=',
        args: [],
      },
      not_is_empty: {
        label: intl.formatMessage({
          id: 'predicates.isNotEmpty',
          defaultMessage: 'is not empty',
        }),
        value: 'not_is_empty',
        query: '!=',
        args: [],
      },
      icontains: {
        label: intl.formatMessage({
          id: 'predicates.contains',
          defaultMessage: 'contains',
        }),
        value: 'icontains',
        query: '__icontains={value}',
        args: ['value'],
      },
      not_icontains: {
        label: intl.formatMessage({
          id: 'predicates.doesNotContain',
          defaultMessage: 'does not contain',
        }),
        value: 'not_icontains',
        query: '__icontains!={value}',
        args: ['value'],
      },
      istartswith: {
        label: intl.formatMessage({
          id: 'predicates.startsWith',
          defaultMessage: 'starts with',
        }),
        value: 'istartswith',
        query: '__istartswith={value}',
        args: ['value'],
      },
      iendswith: {
        label: intl.formatMessage({
          id: 'predicates.endsWith',
          defaultMessage: 'ends with',
        }),
        value: 'iendswith',
        query: '__iendswith={value}',
        args: ['value'],
      },
      iexact: {
        label: intl.formatMessage({
          id: 'predicates.isExactly',
          defaultMessage: 'is exactly',
        }),
        value: 'iexact',
        query: '__iexact={value}',
        args: ['value'],
      },
      ...commonPredicates(intl),
    }),
    [intl]
  );
};
