import { TPredicateInputTypes } from 'utils/types/predicates.types';
import {
  CUSTOM_DATE_FORMAT,
  CUSTOM_DATE_TIME_FORMAT,
} from 'pages/TaskTemplates/components/TaskForm/consts';

const usePredicateTimeDate = (
  filterKey: string,
  type: TPredicateInputTypes
) => {
  //created_at and modified_at columns return datetime but BE also takes date (then it looks for the whole day). Currently, these columns are meant to be the exception (act as date)

  const isTimeVisible =
    !['created_at', 'modified_at', 'added_at'].includes(filterKey) &&
    ['datetime', 'dateTimeRange', 'added_at'].includes(type);

  const dateFormat = isTimeVisible
    ? CUSTOM_DATE_TIME_FORMAT
    : CUSTOM_DATE_FORMAT;

  return { isTimeVisible, dateFormat };
};

export default usePredicateTimeDate;
