import { createUseStyles } from 'react-jss';

const useFilesLinkStyles = createUseStyles({
  anchor: {
    display: 'block',
    overflow: 'hidden',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: '#038fdf',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export default useFilesLinkStyles;
