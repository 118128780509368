import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useAutocompleteUserFieldFilterStyles = createUseStyles(
  (theme: ThemeState) => ({
    checkmarkIcon: {
      color: theme['primary-color'],
    },
    optionContent: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      '& > div:first-child': {
        marginRight: 6,
      },
    },
  })
);

export default useAutocompleteUserFieldFilterStyles;
