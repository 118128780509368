import React from 'react';
import Select from 'react-select';
import { Input } from 'components/lib/Input';
import FBCheckbox from '../../FBCheckbox';
import Tooltip from '../../Tooltip';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldProps } from '../defaultInputs/types';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import FormField from 'pages/TaskTemplates/components/FormField';
import { InputTypes } from 'components/formBuilder/formBuilder/FormBuilder/enums';
import { FORM_BUILDER_TEXT_DEFAULT_PARAMETER_TESTID } from 'utils/testIds';

const formatDictionary: MappedObject<string> = {
  '': 'None',
  'date-time': 'Date-Time',
  email: 'Email',
  hostname: 'Hostname',
  time: 'Time',
  uri: 'URI',
  url: 'URL',
  regex: 'Regular Expression',
};

const autoDictionary: MappedObject<string> = {
  '': 'None',
  email: 'Email',
  username: 'User Name',
  password: 'Password',
  'street-address': 'Street Address',
  country: 'Country',
};

// specify the inputs required for a string type object
const CardShortAnswerParameterInputs: React.FC<FieldProps> = ({
  parameters,
  onChange,
}) => {
  const intl = useIntl();

  const getSelectOptionData = (defaultValue: string) => {
    if (parameters['ui:autocomplete']) {
      return autoDictionary[
        typeof parameters['ui:autocomplete'] === 'string'
          ? parameters['ui:autocomplete']
          : ''
      ];
    } else {
      return defaultValue;
    }
  };

  return (
    <div>
      <h4>
        <FormattedMessage
          id='formBuilder.minimumLength'
          defaultMessage='Minimum length'
        />
      </h4>
      <Input
        value={parameters.minLength || ''}
        key='minLength'
        type='number'
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          onChange({
            ...parameters,
            minLength: parseInt(ev.target.value, 10),
          });
        }}
        className='card-modal-number'
      />
      <h4>
        <FormattedMessage
          id='formBuilder.maximumLength'
          defaultMessage='Maximum length'
        />
      </h4>
      <Input
        value={parameters.maxLength || ''}
        key='maxLength'
        type='number'
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          onChange({
            ...parameters,
            maxLength: parseInt(ev.target.value, 10),
          });
        }}
        className='card-modal-number'
      />
      <h4>
        <FormattedMessage
          id='formBuilder.regexpPattern'
          defaultMessage='Regular expression pattern'
        />
        <a
          href='https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions'
          target='_blank'
          rel='noopener noreferrer'
        >
          <Tooltip
            id={`${parameters.path}_regex`}
            type='help'
            text={intl.formatMessage({
              id: 'formBuilder.regexpHelp',
              defaultMessage:
                'Regular expression pattern that this must satisfy',
            })}
          />
        </a>
      </h4>
      <Input
        value={parameters.pattern || ''}
        key='pattern'
        type='text'
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          onChange({
            ...parameters,
            pattern: ev.target.value,
          });
        }}
        className='card-modal-text'
      />
      <h4>
        <FormattedMessage id='formBuilder.format' defaultMessage='Format' />
        <Tooltip
          id={`${parameters.path}_format`}
          type='help'
          text={intl.formatMessage({
            id: 'formBuilder.requireStringInput',
            defaultMessage:
              'Require string input to match a certain common format',
          })}
        />
      </h4>
      <Select
        value={{
          value:
            parameters.format !== undefined
              ? formatDictionary[parameters.format]
              : '',
          label:
            parameters.format !== undefined
              ? formatDictionary[parameters.format]
              : 'None',
        }}
        placeholder='Format'
        key='format'
        options={Object.keys(formatDictionary).map(key => ({
          value: key,
          label: formatDictionary[key],
        }))}
        onChange={({ value }: any) => {
          onChange({
            ...parameters,
            format: value,
          });
        }}
        className='card-modal-select'
      />
      <h5>
        <FormattedMessage
          id='formBuilder.autocompleteCategory'
          defaultMessage='Auto complete category'
        />
        <a
          href='https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete'
          target='_blank'
          rel='noopener noreferrer'
        >
          <Tooltip
            id={`${parameters.path}_autocomplete`}
            type='help'
            text={intl.formatMessage({
              id: 'formBuilder.suggestEntriesHelp',
              defaultMessage:
                "Suggest entries based on the user's browser history",
            })}
          />
        </a>
      </h5>
      <Select
        value={{
          value: getSelectOptionData(''),
          label: getSelectOptionData('None'),
        }}
        placeholder='Auto Complete'
        key='ui:autocomplete'
        options={Object.keys(autoDictionary).map(key => ({
          value: key,
          label: autoDictionary[key],
        }))}
        onChange={({ value }: any) => {
          onChange({
            ...parameters,
            'ui:autocomplete': value,
          });
        }}
        className='card-modal-select'
      />
      <div className='card-modal-boolean'>
        <FBCheckbox
          onChangeValue={() => {
            onChange({
              ...parameters,
              'ui:autofocus': parameters['ui:autofocus']
                ? parameters['ui:autofocus'] !== true
                : true,
            });
          }}
          isChecked={
            parameters['ui:autofocus']
              ? parameters['ui:autofocus'] === true
              : false
          }
          label='Auto Focus'
        />
      </div>
    </div>
  );
};

const ShortAnswerField: React.FC<FieldProps> = ({ parameters, onChange }) => {
  const onBlur = () => {
    if (typeof parameters.default === 'string')
      onChange({
        ...parameters,
        default: parameters.default.trimStart().trimEnd(),
      });
  };

  return (
    <FormField gutter={0}>
      <FormLabel>
        <FormattedMessage
          id='formBuilder.defaultValue'
          defaultMessage='Default value'
        />
      </FormLabel>
      <Input
        maxLength={parameters.maxLength}
        value={parameters.default as string}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
          onChange({ ...parameters, default: ev.target.value })
        }
        className='card-text'
        {...{ onBlur }}
        data-testid={FORM_BUILDER_TEXT_DEFAULT_PARAMETER_TESTID}
        disableTextTrim
      />
    </FormField>
  );
};

export const Password: React.FC<FieldProps> = ({ parameters, onChange }) => {
  return (
    <>
      <h5>
        <FormattedMessage
          id='formBuilder.defaultPassword'
          defaultMessage='Default password'
        />
      </h5>
      <Input
        value={parameters.default as string}
        type='password'
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
          onChange({ ...parameters, default: ev.target.value })
        }
        className='card-text'
      />
    </>
  );
};

const shortAnswerInput = {
  shortAnswer: {
    displayName: GlobalIntlSingleton.intl.formatMessage({
      id: 'formBuilder.singleLine',
      defaultMessage: 'Single line',
    }),
    matchIf: [
      {
        types: ['string'],
      },
    ],
    defaultDataSchema: {},
    defaultUiSchema: {},
    type: 'string',
    cardBody: ShortAnswerField,
    modalBody: CardShortAnswerParameterInputs,
  },
  password: {
    displayName: 'Password',
    matchIf: [
      {
        types: ['string'],
        widget: 'password',
      },
    ],
    defaultDataSchema: {},
    defaultUiSchema: {
      'ui:widget': 'password',
    },
    type: 'string',
    cardBody: Password,
    modalBody: CardShortAnswerParameterInputs,
  },
  email: {
    displayName: 'Email',
    matchIf: [
      {
        types: ['string'],
        format: 'email',
      },
    ],
    defaultDataSchema: {
      format: 'email',
    },
    defaultUiSchema: {},
    type: 'string',
    cardBody: ShortAnswerField,
    modalBody: CardShortAnswerParameterInputs,
  },
  url: {
    displayName: 'URL',
    matchIf: [
      {
        types: ['string'],
        format: 'url',
      },
    ],
    defaultDataSchema: {
      format: 'url',
    },
    defaultUiSchema: {},
    type: 'string',
    cardBody: ShortAnswerField,
    modalBody: CardShortAnswerParameterInputs,
  },
  [InputTypes.HTML]: {
    displayName: 'HTML',
    matchIf: [
      {
        types: ['string'],
        format: InputTypes.HTML,
      },
    ],
    defaultDataSchema: {
      format: InputTypes.HTML,
    },
    defaultUiSchema: {},
    type: 'string',
    cardBody: ShortAnswerField,
    modalBody: CardShortAnswerParameterInputs,
  },
  phoneNumber: {
    displayName: GlobalIntlSingleton.intl.formatMessage({
      id: 'formBuilder.phoneNumber',
      defaultMessage: 'Phone number',
    }),
    matchIf: [
      {
        types: ['string'],
        widget: InputTypes.PhoneNumber,
      },
    ],
    defaultDataSchema: {},
    defaultUiSchema: {
      'ui:widget': InputTypes.PhoneNumber,
    },
    type: 'string',
    cardBody: ShortAnswerField,
    modalBody: CardShortAnswerParameterInputs,
  },
  [InputTypes.JSON]: {
    displayName: 'JSON',
    matchIf: [
      {
        types: ['string'],
        format: 'json',
      },
    ],
    defaultDataSchema: {
      format: 'json',
    },
    defaultUiSchema: {},
    type: 'string',
    cardBody: ShortAnswerField,
    modalBody: CardShortAnswerParameterInputs,
  },
};

export default shortAnswerInput;
