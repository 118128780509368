import { PasswordRulesOptions } from 'components/PasswordRules/types';
import { useEffect } from 'react';
import { PASSWORD_COMPLEXITY } from 'utils/endpoints';
import useData from './useData';

interface UsePasswordRulesDataProps {
  fetchOnLoad?: boolean;
  shouldFetch?: boolean;
}

export const usePasswordRulesData = ({
  fetchOnLoad = true,
  shouldFetch,
}: UsePasswordRulesDataProps = {}) => {
  const [passwordComplexityData, { loading, error, fetchData }] = useData<
    PasswordRulesOptions
  >(PASSWORD_COMPLEXITY, { fetchOnLoad });

  useEffect(() => {
    if (shouldFetch) fetchData();
  }, [shouldFetch, fetchData]);

  return {
    passwordComplexityData,
    loadingPasswordRulesData: loading,
    errorPasswordComplexity: error,
  };
};
