import React from 'react';
import {
  ToastContainer as ToastifyToastContainer,
  Slide,
} from 'react-toastify';

import useToastStyles from './styles';

const ToastContainer = () => {
  const classes = useToastStyles({});

  return (
    <ToastifyToastContainer
      position='bottom-right'
      transition={Slide}
      closeOnClick={false}
      autoClose={3000}
      pauseOnFocusLoss
      pauseOnHover
      className={classes.toastify}
    />
  );
};

export default ToastContainer;
