import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { TextArea } from 'components/lib/Input';
import useInPlaceEditTextareaStyles from './styles';
import { InPlaceEditTextareaProps, TextareaTextProps } from './types';
import useInPlaceEdit from '../useInPlaceEdit';
import InPlaceEditWrapper from '../components/InPlaceEditWrapper';
import { ReactComponent as InputImage } from 'img/icons/textbox-icon.svg';
import { useErrorMessages } from '../../../utils/useErrorMessages';
import { InPlaceEditError } from '../utils';
import clsx from 'clsx';
import useCommonInPlaceEditStyles from '../components/commonStyles';
import FieldValidationMessage from '../components/FieldValidationMessage';
import { Dropdown } from 'components/lib/Dropdown';
import InPlaceInputAfter from '../components/InPlaceInputAfter';
import { useToggle } from 'hooks/useToggle';
import { FormattedMessage } from 'react-intl';
import { IN_PLACE_EDIT_TEXTAREA } from 'utils/testIds';

export const getViewMaxRows = (
  singleLineOnPreview: boolean | undefined,
  readOnly: boolean | undefined,
  isPreviewExpanded: boolean
) => {
  if (singleLineOnPreview && !readOnly) {
    return isPreviewExpanded ? 25 : 1;
  }

  if (readOnly && isPreviewExpanded) {
    return 20;
  }

  return 5;
};

const InPlaceEditTextarea = <R extends object>({
  label,
  required,
  value,
  disabled,
  propertyName,
  patchUrl,
  onSaveSuccess,
  maxLength,
  getPopupContainer,
  remountChangeTarget,
  readOnly,
  singleLineOnPreview,
  previewClassName,
  size,
  placeholder,
  withUnderline,
}: InPlaceEditTextareaProps<R> & TextareaTextProps) => {
  const {
    inputWrapperRef,
    isEditMode,
    setTempValue,
    tempValue,
    isSaving,
    setIsEditModeOff,
    setIsEditModeOn,
    errorMessage,
    setErrorMessage,
    editWrapperRef,
  } = useInPlaceEdit({
    initialValue: value,
    patchUrl,
    propertyName,
    onSaveSuccess,
  });

  const [isMoreButtonVisible, setIsMoreButtonVisible] = useState(false);
  const [
    isPreviewExpanded,
    { toggle: toggleIsPreviewExpanded, toggleOff: closePreview },
  ] = useToggle(false);

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const previewTextareaRef = useRef<HTMLTextAreaElement>(null);

  const classes = useCommonInPlaceEditStyles({
    isSaving,
    errorMessage,
    size,
  });
  const textareaClasses = useInPlaceEditTextareaStyles({ size });

  const errorMessages = useErrorMessages('', maxLength);

  const onViewClick = () => {
    if (disabled) return;

    setIsEditModeOn();
  };

  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;

    if (required && value.trim().length === 0)
      setErrorMessage(errorMessages[InPlaceEditError.FIELD_IS_REQUIRED_ERROR]);
    else if (maxLength && value.length > maxLength)
      setErrorMessage(errorMessages[InPlaceEditError.LIMIT_OF_CHARS_EXCEEDED]);
    else setErrorMessage(undefined);

    setTempValue(value);
  };

  const message =
    maxLength !== undefined && maxLength !== null
      ? `${tempValue?.length || 0}/${maxLength}`
      : undefined;

  const getViewValue = () => {
    if (!value?.length && !!placeholder) return undefined;

    if (!value?.length) return '-';

    if (singleLineOnPreview && !isPreviewExpanded) return value.split('\n')[0];

    return value;
  };

  //focus has to be delayed to prevent page jumping when dropdown with textarea
  // does not fit window
  useEffect(() => {
    if (!isEditMode) return;

    setTimeout(() => {
      if (!textareaRef.current) return;

      textareaRef.current.focus();
    }, 500);
  }, [isEditMode]);

  // determining if "show more" button should be visible
  useLayoutEffect(() => {
    if (!singleLineOnPreview && !readOnly) return;

    // create async call to wait for browser to break text lines and correctly determine scrollHeight
    setTimeout(() => {
      if (!previewTextareaRef.current) return;

      if (
        previewTextareaRef.current.clientHeight <
          previewTextareaRef.current.scrollHeight ||
        (!!value && value.split('\n').length > 1)
      )
        setIsMoreButtonVisible(true);
      else {
        setIsMoreButtonVisible(false);
        closePreview();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const editAbort = () => {
    setTempValue(value);
    setIsEditModeOff();
  };

  return (
    <InPlaceEditWrapper
      {...{
        isEditMode,
        isSaving,
        label,
        required,
        disabled,
        onViewClick,
        inputWrapperRef,
        readOnly,
        previewClassName,
        size,
        withUnderline,
      }}
      noAfter
      editContent={
        <Dropdown
          {...{ getPopupContainer, remountChangeTarget }}
          visible
          overlay={
            <div
              className={textareaClasses.dropdownWrapper}
              ref={editWrapperRef}
            >
              <div className={textareaClasses.dropdownContentWrapper}>
                <div
                  className={clsx(
                    classes.basicInPlaceInputWrapper,
                    textareaClasses.textareaWrapper
                  )}
                  data-testid={IN_PLACE_EDIT_TEXTAREA}
                >
                  <TextArea
                    autoSize={{ maxRows: 10 }}
                    disableResize
                    value={tempValue ?? ''}
                    onChange={onInputChange}
                    onKeyDown={({ key }) => key === 'Escape' && editAbort()}
                    className={clsx(
                      classes.basicInPlaceInput,
                      textareaClasses.textarea
                    )}
                    {...{
                      maxLength,
                    }}
                    textareaElementRef={textareaRef}
                    disableTextTrim
                    disableTrimSpaces
                  />
                  <InputImage width={16} height={16} />
                </div>
                {!isSaving && (
                  <FieldValidationMessage
                    error={errorMessage}
                    className={textareaClasses.messageBox}
                    {...{ message }}
                  />
                )}
              </div>
              <InPlaceInputAfter
                isLoading={isSaving}
                onCloseClick={setIsEditModeOff}
              />
            </div>
          }
        >
          <div className={classes.dropdownAnchor}></div>
        </Dropdown>
      }
      viewContent={
        <div className={textareaClasses.previewWrapper}>
          <TextArea
            key={getViewMaxRows(
              singleLineOnPreview,
              readOnly,
              isPreviewExpanded
            ).toString()}
            autoSize={{
              maxRows: getViewMaxRows(
                singleLineOnPreview,
                readOnly,
                isPreviewExpanded
              ),
            }}
            textareaElementRef={previewTextareaRef}
            disableResize
            value={getViewValue()}
            {...{
              placeholder,
            }}
            readOnly
            className={clsx(
              classes.basicInPlaceInput,
              textareaClasses.textarea,
              textareaClasses.textareaPreviewWrapper,
              {
                [textareaClasses.singleLinePreview]:
                  singleLineOnPreview && !isPreviewExpanded,
                [textareaClasses.textareaPreviewWrapperTrim]:
                  !singleLineOnPreview && !readOnly,
                [textareaClasses.showOverflowY]: isPreviewExpanded,
              }
            )}
          />
        </div>
      }
      viewContentAfter={
        (singleLineOnPreview || readOnly) && isMoreButtonVisible ? (
          <div>
            <span
              onClick={toggleIsPreviewExpanded}
              className={textareaClasses.showMoreButton}
            >
              {isPreviewExpanded ? (
                <FormattedMessage
                  id='misc.showLess'
                  defaultMessage='Show less'
                />
              ) : (
                <FormattedMessage
                  id='misc.showMore'
                  defaultMessage='Show more'
                />
              )}
            </span>
          </div>
        ) : undefined
      }
      actionButtonOnTop
    />
  );
};

export default InPlaceEditTextarea;
