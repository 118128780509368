export const TLN = {
  eventList: {} as MappedObject<
    { evt: keyof HTMLElementEventMap; hdlr: (e: Event) => void }[]
  >,
  update_line_numbers: function (
    textareaElem: HTMLTextAreaElement | null,
    numbersElem: HTMLElement | null
  ) {
    if (!textareaElem || !numbersElem) return;

    // Let's check if there are more or less lines than before
    const line_count = textareaElem.value?.split('\n')?.length || 0;
    const child_count = numbersElem.children.length;
    let difference = line_count - child_count;
    // If there is any positive difference, we need to add more line numbers
    if (difference > 0) {
      // Create a fragment to work with so we only have to update DOM once
      const frag = document.createDocumentFragment();
      // For each new line we need to add,
      while (difference > 0) {
        // Create a <span>, add TLN class name, append to fragment and
        // update difference
        const line_number = document.createElement('span');
        line_number.className = 'tln-line';
        frag.appendChild(line_number);
        difference--;
      }
      // Append fragment (with <span> children) to our wrapper element
      numbersElem.appendChild(frag);
    }
    // If, however, there's negative difference, we need to remove line numbers
    while (difference < 0) {
      // Simple stuff, remove last child and update difference
      if (numbersElem.lastChild) numbersElem.removeChild(numbersElem.lastChild);

      difference++;
    }
  },
  update_line_number_on_external_change: function (
    textareaId: string,
    windowDocument: Document | undefined
  ) {
    const wrapper = windowDocument ? windowDocument : document;
    const textareaElement = wrapper.getElementById(
      textareaId
    ) as HTMLTextAreaElement;
    const numbersElement = wrapper.getElementById(
      `${textareaId}-numbers-wrapper`
    );

    this.update_line_numbers(textareaElement, numbersElement);
  },
  append_line_numbers: function (
    id: string,
    windowDocument: Document | undefined
  ) {
    // Get reference to desired <textarea>
    const wrapper = windowDocument ? windowDocument : document;
    const textareaElem = wrapper.getElementById(
      id
    ) as HTMLTextAreaElement | null;
    // If getting reference to element fails, warn and leave
    if (textareaElem === null) {
      return;
    }
    // If <textarea> already has TLN active, warn and leave
    if (textareaElem.className.indexOf('tln-active') !== -1) {
      return;
    }
    // Otherwise, we're safe to add the class name and clear inline styles
    textareaElem.classList.add('tln-active');

    textareaElem.setAttribute('style', '');

    // Create line numbers wrapper, insert it before <textarea>
    const numbersElem = wrapper.createElement('div');
    numbersElem.id = `${id}-numbers-wrapper`;
    numbersElem.className = 'tln-wrapper';

    if (textareaElem.parentNode)
      textareaElem.parentNode.insertBefore(numbersElem, textareaElem);

    // Call update to actually insert line numbers to the wrapper
    TLN.update_line_numbers(textareaElem, numbersElem);
    // Initialize event listeners list for this element ID, so we can remove
    // them later if needed
    TLN.eventList[id] = [];

    // Constant list of input event names so we can iterate
    const __change_evts = [
      'propertychange',
      'input',
      'keydown',
      'keyup',
    ] as (keyof HTMLElementEventMap)[];
    // Default handler for input events
    const __change_hdlr = (function (
      providedTextareaElem,
      providedNumbersElem
    ) {
      return function (e: Event) {
        // If pressed key is Left Arrow (when cursor is on the first character),
        // or if it's Enter/Home, then we set horizontal scroll to 0
        // Check for .keyCode, .which, .code and .key, because the web is a mess
        // [Ref] stackoverflow.com/a/4471635/4824627
        const keybordEvent = e as KeyboardEvent;

        if (
          (+providedTextareaElem.scrollLeft === 10 &&
            (keybordEvent.code === 'ArrowLeft' ||
              keybordEvent.key === 'ArrowLeft')) ||
          ['Home', 'Enter'].includes(keybordEvent.key) ||
          ['Home', 'Enter', 'NumpadEnter'].includes(keybordEvent.code)
        )
          providedTextareaElem.scrollLeft = 0;
        // Whether we scrolled or not, let's check for any line count updates
        TLN.update_line_numbers(providedTextareaElem, providedNumbersElem);
      };
    })(textareaElem, numbersElem);

    // Finally, iterate through those event names, and add listeners to
    // <textarea> and to events list
    /// Performance gurus: is this suboptimal? Should we only add a few
    /// listeners? I feel the update method is optimal enough for this to not
    /// impact too much things.
    for (let i = __change_evts.length - 1; i >= 0; i--) {
      textareaElem.addEventListener(__change_evts[i], __change_hdlr);
      TLN.eventList[id].push({
        evt: __change_evts[i],
        hdlr: __change_hdlr,
      });
    }

    // Constant list of scroll event names so we can iterate
    const __scroll_evts = [
      'change',
      'mousewheel',
      'scroll',
    ] as (keyof HTMLElementEventMap)[];
    // Default handler for scroll events (pretty self explanatory)
    const __scroll_hdlr = (function (
      providedTextareaElem,
      providedNumbersElem
    ) {
      return function () {
        providedNumbersElem.scrollTop = providedTextareaElem.scrollTop;
      };
    })(textareaElem, numbersElem);
    // Just like before, iterate and add listeners to <textarea> and to list
    for (let i = __scroll_evts.length - 1; i >= 0; i--) {
      textareaElem.addEventListener(__scroll_evts[i], __scroll_hdlr);
      TLN.eventList[id].push({
        evt: __scroll_evts[i],
        hdlr: __scroll_hdlr,
      });
    }
  },
  remove_line_numbers: function (id: string) {
    // Get reference to <textarea>
    const textareaElem = document.getElementById(id);
    // If getting reference to element fails, warn and leave
    if (textareaElem === null) {
      return;
    }
    // If <textarea> already doesn't have TLN active, warn and leave
    if (textareaElem.className.indexOf('tln-active') === -1) {
      return;
    }
    // Otherwise, remove class name
    textareaElem.classList.remove('tln-active');

    // Remove previous sibling if it's our wrapper (otherwise, I guess 'woops'?)
    const __wrapper_chck = textareaElem.previousSibling as Element;
    if (__wrapper_chck?.className === 'tln-wrapper') __wrapper_chck.remove();

    // If somehow there's no event listeners list, we can leave
    if (!TLN?.eventList?.[id]) return;
    // Otherwise iterate through listeners list and remove each one
    for (let i = TLN.eventList[id].length - 1; i >= 0; i--) {
      const evt = TLN.eventList[id][i];
      textareaElem.removeEventListener(evt.evt, evt.hdlr);
    }
    // Finally, delete the listeners list for that ID
    delete TLN.eventList[id];
  },
};
