import React from 'react';
import { Checkbox } from 'components/lib/Checkbox';
import { Text } from 'components/lib/Text';
import { ClassFieldCheckboxProps } from './types';
import useClassFieldCheckboxStyles from './styles';

const ClassFieldCheckbox = ({
  alias,
  label,
  itemDisabled,
  onChange,
}: ClassFieldCheckboxProps) => {
  const classes = useClassFieldCheckboxStyles({});

  return (
    <Checkbox
      key={alias}
      value={alias}
      onChange={onChange}
      className={classes.checkbox}
      disabled={itemDisabled}
    >
      &nbsp;
      <Text strong>{label}</Text>
    </Checkbox>
  );
};
export default React.memo(
  ClassFieldCheckbox,
  (prev, next) => prev.itemDisabled === next.itemDisabled
);
