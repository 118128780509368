import { Tag } from 'components/lib/Tag';
import React from 'react';
import { MEMBERSHIP_TAG } from 'utils/testIds';
import { useMembership } from './hooks';
import useMembershipTagStyles from './styles';
import { MembershipTagProps } from './types';

const MembershipTag = ({ membership }: MembershipTagProps) => {
  const classes = useMembershipTagStyles({ membership });
  const membershipText = useMembership(membership);

  return (
    <Tag className={classes.membershipTag} data-testid={MEMBERSHIP_TAG}>
      {membershipText}
    </Tag>
  );
};

export default MembershipTag;
