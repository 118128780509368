import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { SequenceTaskDefaultAssigneesPanelModes } from './types';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import DefaultAssigneesList from './components/DefaultAssigneesList';
import AddDefaultAssignees from './components/AddDefaultAssignees';
import routes from 'utils/routingPaths';
import { AutoSaveErrorModalContextProvider } from 'contexts/AutoSaveErrorModalContext';
import { useSelector } from 'react-redux';
import { getSelectedSequence } from 'store/selectors/selectedSequenceSelectors';

const SequenceTaskDefaultAssignees = () => {
  const intl = useIntl();
  const [mode, setMode] = useState<SequenceTaskDefaultAssigneesPanelModes>(
    SequenceTaskDefaultAssigneesPanelModes.List
  );
  const {
    flexLayoutMethods: { onRenameTab },
  } = useFlexLayoutContext();

  const { name = '' } = useSelector(getSelectedSequence) || {};

  const Panel = useCallback(() => {
    if (mode === SequenceTaskDefaultAssigneesPanelModes.AddAssignees) {
      return <AddDefaultAssignees {...{ setMode }} />;
    }
    return <DefaultAssigneesList {...{ setMode }} />;
  }, [mode]);

  useEffect(() => {
    const tabName =
      mode === SequenceTaskDefaultAssigneesPanelModes.List
        ? intl.formatMessage({
            id: 'misc.manageDefaultTaskAssignees',
            defaultMessage: 'Manage default task assignees',
          })
        : intl.formatMessage({
            id: 'misc.addDefaultTaskAssignees',
            defaultMessage: 'Add default assignees',
          });

    onRenameTab(FlexLayoutWindows.SequenceDefaultAssignees, tabName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return (
    <AutoSaveErrorModalContextProvider
      title={intl.formatMessage(
        {
          id: 'sequences.deletedTitle',
          defaultMessage: '{name} has been deleted',
        },
        { name }
      )}
      subtitle={intl.formatMessage({
        id: 'sequences.deletedSubtitle',
        defaultMessage: 'Your changes can no longer be saved.',
      })}
      redirectUrl={[routes.SEQUENCES, routes.WORKSPACE]}
    >
      <Panel />
    </AutoSaveErrorModalContextProvider>
  );
};

export default SequenceTaskDefaultAssignees;
