import React from 'react';
import { FormattedMessage } from 'react-intl';
import useChildRecordSummaryPanelPermisssionDeniedStyles from './styles';

const ChildRecordSummaryPermissionDenied = () => {
  const styles = useChildRecordSummaryPanelPermisssionDeniedStyles({});

  return (
    <div className={styles.container}>
      <FormattedMessage
        id='nestedObjectRecords.noSummaryView'
        defaultMessage='No summary view display is available for this record.'
      />
    </div>
  );
};

export default ChildRecordSummaryPermissionDenied;
