import { AxiosError } from 'axios';
import { ErrorMessages, ResponseError } from './types/errorResponse';
import { getParsedErrorMessage } from './functions/getParsedErrorMessage';
import isPlainObject from 'lodash/isPlainObject';

const mapDataEntries = (
  data: string | MappedObject<string | string[], string>,
  formPrefix?: string,
  flatErrors?: boolean
) =>
  Object.entries(data).reduce<ErrorMessages>((result, [key, value]) => {
    if (typeof value === 'string') {
      return { ...result, [key]: value };
    }

    if (Array.isArray(value)) {
      return {
        ...result,
        [key]: getParsedErrorMessage(value, key, formPrefix),
      };
    }

    if (flatErrors && isPlainObject(value)) {
      const flattedMessages = Object.entries(value).reduce<ErrorMessages>(
        (results, [key, value]) => {
          if (typeof value === 'string') {
            return { ...results, [key]: value };
          }

          if (Array.isArray(value)) {
            return {
              ...results,
              [key]: getParsedErrorMessage(value, key, formPrefix),
            };
          }

          return results;
        },
        {}
      );

      return { ...result, ...flattedMessages };
    }

    return result;
  }, {});

export const parseError = (
  error: AxiosError<string | MappedObject<string | string[]>>,
  formPrefix?: string,
  flatErrors?: boolean
): ResponseError => {
  if (!error || !error.response) {
    return {
      status: true,
    };
  }

  const {
    response: { data, status = true },
  } = error;

  if (!data) {
    return {
      status,
    };
  }

  if (typeof data === 'string') {
    return {
      status,
      messages: { common: data },
    };
  }

  const messages = mapDataEntries(data, formPrefix, flatErrors);

  return { status, messages };
};
