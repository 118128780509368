import React, { useMemo } from 'react';
import useInPlaceEdit from '../useInPlaceEdit';
import InPlaceEditWrapper from '../components/InPlaceEditWrapper';
import FieldValidationMessage from '../components/FieldValidationMessage';
import useCommonInPlaceEditStyles from '../components/commonStyles';
import { InPlaceEditNumberInputProps } from './types';
import { InputNumber } from 'components/lib/InputNumber';
import useInPlaceEditNumberInputStyles from './styles';
import clsx from 'clsx';
import fixValue from 'utils/functions/fixValue';
import { MAX_DECIMAL_NUMBERS } from 'components/lib/InputNumber/consts';
import MinMaxInfo from 'components/MinMaxInfo';
import { useErrorMessages } from 'utils/useErrorMessages';
import { InPlaceEditError } from '../utils';
import { Dropdown } from 'components/lib/Dropdown';
import { IN_PLACE_EDIT_NUMERIC } from 'utils/testIds';
import useFormatNumber from 'hooks/useFormatNumber';

const InPlaceEditNumberInput = <R extends Object>({
  label,
  required,
  value,
  disabled,
  propertyName,
  patchUrl,
  onSaveSuccess,
  multipleOf,
  max,
  min,
  getPopupContainer,
  remountChangeTarget,
  readOnly,
  size,
  withUnderline,
  showRawValue,
}: InPlaceEditNumberInputProps<R>) => {
  const formatNumberWithSeparators = useFormatNumber();
  const {
    inputWrapperRef,
    isEditMode,
    setTempValue,
    tempValue,
    isSaving,
    setIsEditModeOff,
    setIsEditModeOn,
    errorMessage,
    setErrorMessage,
  } = useInPlaceEdit({
    initialValue: value,
    patchUrl,
    propertyName,
    onSaveSuccess,
  });
  const hasMinMaxSet = max !== undefined || min !== undefined;

  const classes = useCommonInPlaceEditStyles({
    isSaving,
    errorMessage,
    size,
  });
  const numberClasses = useInPlaceEditNumberInputStyles({ size });

  const errorMessages = useErrorMessages();

  const onViewClick = () => {
    if (disabled) return;

    setIsEditModeOn();
  };

  const onInputChange = (value: string | number | undefined) => {
    setErrorMessage(undefined);

    const parsedNumber = typeof value === 'string' ? parseFloat(value) : value;
    const fixedNumber =
      parsedNumber !== undefined
        ? fixValue(parsedNumber, multipleOf || MAX_DECIMAL_NUMBERS)
        : null;

    if (fixedNumber === null) {
      setTempValue(null);

      if (required)
        setErrorMessage(
          errorMessages[InPlaceEditError.FIELD_IS_REQUIRED_ERROR]
        );

      return;
    }

    if (min !== undefined && fixedNumber < min) {
      setTempValue(min);

      return;
    }

    if (max !== undefined && fixedNumber > max) {
      setTempValue(max);

      return;
    }

    setTempValue(fixedNumber);
  };

  const displayValue = useMemo(() => {
    if (typeof value !== 'number') return '-';

    if (showRawValue) return value;

    return formatNumberWithSeparators(value);
  }, [formatNumberWithSeparators, showRawValue, value]);

  return (
    <InPlaceEditWrapper
      {...{
        isEditMode,
        isSaving,
        label,
        required,
        disabled,
        onViewClick,
        inputWrapperRef,
        readOnly,
        size,
        withUnderline,
      }}
      onCloseClick={setIsEditModeOff}
      maxWidth={200}
      editContent={
        <Dropdown
          {...{ getPopupContainer, remountChangeTarget }}
          visible={!isSaving && (hasMinMaxSet || !!errorMessage)}
          overlay={
            <FieldValidationMessage
              error={errorMessage}
              message={
                hasMinMaxSet ? (
                  <MinMaxInfo
                    className={numberClasses.messageWrapper}
                    maximum={max}
                    minimum={min}
                  />
                ) : undefined
              }
            />
          }
        >
          <div className={classes.basicInPlaceInputWrapper}>
            <InputNumber
              value={tempValue as number}
              onChange={onInputChange}
              className={clsx(
                classes.basicInPlaceInput,
                numberClasses.numberInput
              )}
              autoFocus
              type='number'
              onKeyDown={event => event.key === 'Escape' && setIsEditModeOff()}
              data-testid={IN_PLACE_EDIT_NUMERIC}
            />
          </div>
        </Dropdown>
      }
      viewContent={<span>{displayValue}</span>}
    />
  );
};

export default InPlaceEditNumberInput;
