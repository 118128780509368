import { createUseStyles } from 'react-jss';

const useChildRecordSummaryPanelPermisssionDeniedStyles = createUseStyles(
  () => ({
    container: {
      padding: 10,
    },
  })
);

export default useChildRecordSummaryPanelPermisssionDeniedStyles;
