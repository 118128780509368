export const SET_DOCUMENT_TEMPLATES = 'SET_DOCUMENT_TEMPLATES';
export const APPEND_DOCUMENT_TEMPLATES = 'APPEND_DOCUMENT_TEMPLATES';
export const SET_DOCUMENT_TEMPLATES_FETCHING =
  'SET_DOCUMENT_TEMPLATES_FETCHING';
export const SET_TOTAL_DOCUMENT_TEMPLATES = 'SET_TOTAL_DOCUMENT_TEMPLATES';
export const RESET_DOCUMENT_TEMPLATES = 'RESET_DOCUMENT_TEMPLATES';
export const SET_DOCUMENT_TEMPLATES_COLUMNS = 'SET_DOCUMENT_TEMPLATES_COLUMNS';
export const RESET_DOCUMENT_TEMPLATES_COLUMNS =
  'RESET_DOCUMENT_TEMPLATES_COLUMNS';
export const SET_DOCUMENT_TEMPLATES_SELECTED_ROW =
  'SET_DOCUMENT_TEMPLATES_SELECTED_ROW';
export const SET_DOCUMENT_TEMPLATES_RESTRICTIONS =
  'SET_DOCUMENT_TEMPLATES_RESTRICTIONS';
