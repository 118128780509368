import React, { useMemo } from 'react';
import { Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import clsx from 'clsx';
import useFileUploadStyles from './styles';
import FileUploadImage from 'img/illustrations/file-upload-img.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { DragAndDropProps, UploadResponse } from './types';
import useCustomWidgetsStyles from 'components/FormPreview/customWidgetsStyles';
import useFormPreviewStyles from 'components/FormPreview/styles';
import Dragger from 'antd/lib/upload/Dragger';
import { DEFAULT_MAX_ITEMS } from './consts';
import { ButtonSecondary } from '../Button';
import { UploadOutlined } from '@ant-design/icons';
import { FileUploadProgress } from '../Progress';
import { UploadFile } from 'antd/lib/upload/interface';
import { useFieldsValidation, useUploadFiles } from './hooks';
import {
  FILE_UPLOAD_INPUT,
  LISTED_UPLOADED_FILE,
  UPLOAD_FILE_ERROR,
} from 'utils/testIds';
import kebabCase from 'lodash/kebabCase';
import { orderBy, partition } from 'lodash';
import TooltipString from 'components/TooltipString';
import { FileViewerLauncher } from 'components/FileViewerLauncher';
import { FileActionsMenu } from 'components/FileActionsMenu';
import FileClickLink from 'components/FileLink';
import { FileType } from 'components/FileLink/types';

export const DragAndDrop = ({
  additionalText,
  description,
  fileList,
  maxItems = DEFAULT_MAX_ITEMS,
  onChange,
  skipUploading = false,
  onUpload,
  small,
  readonly,
  title,
  customLinkLogic,
  allowedExtensions,
  onUploadCompleted,
  customOnRemove,
  noSizeMessage,
  customUploadUrl,
  customProgressCalulator,
  fieldLabel,
  shouldDownloadFileOnClick = false,
  recordIdentifier,
  ...rest
}: DragAndDropProps) => {
  const intl = useIntl();
  const { limitExceeded, beforeUpload, error } = useFieldsValidation({
    files: fileList || [],
    maxItems,
    allowedExtensions,
    noSizeMessage,
  });
  const classes = useFileUploadStyles({
    limit: limitExceeded,
    small,
    readonly,
  });
  const wClasses = useCustomWidgetsStyles({});
  const styles = useFormPreviewStyles({});
  const {
    handleOnChange,
    uploadFile,
    progressData,
    skipUpload,
    cancelSources,
  } = useUploadFiles({
    onChange,
    onUpload,
    onUploadCompleted,
    customUploadUrl,
    customProgressCalulator,
  });

  const onRemove = async (removedFileId: string | number) => {
    if (customOnRemove) return customOnRemove(Number(removedFileId));

    if (!fileList || !onChange) return;

    const updatedList = fileList.filter(({ id }) => id !== removedFileId);

    onChange(updatedList);
  };

  const sortedFiles = useMemo(() => {
    if (!Array.isArray(fileList)) return [];

    const [savedFiles, uploadedFiles] = partition(fileList, 'url');

    const sortedSavedFiles = orderBy(savedFiles, 'name');

    return [...sortedSavedFiles, ...uploadedFiles];
  }, [fileList]);

  return (
    <>
      <Dragger
        data-testid={FILE_UPLOAD_INPUT}
        multiple
        accept={allowedExtensions?.join(', ')}
        showUploadList={false}
        className={classes.dnd}
        customRequest={skipUploading ? skipUpload : uploadFile}
        onChange={handleOnChange}
        fileList={
          Array.isArray(fileList)
            ? fileList.map<UploadFile<UploadResponse>>(
                ({ id, status, ...rest }) => ({
                  uid: id?.toString(),
                  status: status || 'done',
                  ...rest,
                })
              )
            : []
        }
        {...{
          beforeUpload,
          ...rest,
        }}
      >
        <div className={classes.contentWrapper}>
          <img
            src={FileUploadImage}
            alt={intl.formatMessage({
              id: 'formBuilder.upload',
              defaultMessage: 'Upload',
            })}
          />
          <div className={classes.textWrapper} data-hide-in-preview=''>
            <p className={classes.paragraphSecondary}>
              {title
                ? title
                : intl.formatMessage({
                    id: 'formBuilder.fileUploadInput.dndText',
                    defaultMessage: 'Drag files here or click to upload',
                  })}
            </p>
            {fileList?.length === 0 ? (
              <p className={classes.paragraphDescription}>
                <FormattedMessage
                  id='formBuilder.fileUploadInput.maxItems'
                  defaultMessage='You can upload max {maxItems, plural, one {# file} other {# files}}'
                  values={{ maxItems }}
                />
              </p>
            ) : (
              <p className={classes.paragraphDescription}>
                <FormattedMessage
                  id='formBuilder.fileUploadInput.remainingItems'
                  defaultMessage='You can upload {remainingItems, plural, one {# more file} other {# more files}}'
                  values={{
                    remainingItems: maxItems - (fileList?.length || 0),
                  }}
                />
              </p>
            )}
            {additionalText && (
              <p className='ant-upload-hint'>{additionalText}</p>
            )}
          </div>
        </div>
      </Dragger>
      <>
        {!error && description && (
          <span
            className={clsx([styles.description, wClasses.descriptionGrow])}
          >
            {description}
          </span>
        )}
        {!!error && (
          <span className={styles.errorMessage} data-testid={UPLOAD_FILE_ERROR}>
            {error}
          </span>
        )}
      </>

      {sortedFiles?.map(({ id, name, status, url }) => {
        if (!id) return null;

        return (
          <div
            className={classes.listItem}
            key={id}
            data-grid-item=''
            data-testid={`${LISTED_UPLOADED_FILE}-${kebabCase(name)}`}
          >
            {customLinkLogic ? (
              <>
                <div className={classes.itemTextWrapper}>
                  <>{customLinkLogic({ id, name, status, url })}</>
                </div>

                {!readonly && (
                  <FileUploadProgress
                    iconSize={32}
                    percent={progressData?.[id]}
                    fileStatus={status}
                    onRemove={() => onRemove(id)}
                    source={cancelSources?.[id]}
                  />
                )}
              </>
            ) : null}

            {!customLinkLogic && url && shouldDownloadFileOnClick ? (
              <>
                <div className={classes.itemTextWrapper}>
                  <FileClickLink
                    label={name}
                    type={FileType.Document}
                    url={url}
                  />
                </div>

                {!readonly && (
                  <FileUploadProgress
                    iconSize={32}
                    percent={progressData?.[id]}
                    fileStatus={status}
                    onRemove={() => onRemove(id)}
                    source={cancelSources?.[id]}
                  />
                )}
              </>
            ) : null}

            {!customLinkLogic && url && !shouldDownloadFileOnClick ? (
              <>
                <div className={classes.itemTextWrapper}>
                  <FileViewerLauncher
                    fileName={name}
                    fileUrl={url}
                    fieldLabel={fieldLabel}
                    recordIdentifier={recordIdentifier}
                  />
                </div>

                {!readonly && (
                  <FileActionsMenu
                    fileName={name}
                    fileUrl={url}
                    onDelete={() => onRemove(id)}
                  />
                )}
              </>
            ) : null}

            {!customLinkLogic && !url ? (
              <>
                <div className={classes.itemTextWrapper}>
                  <TooltipString className={classes.item} text={name} />
                </div>

                {!readonly && (
                  <FileUploadProgress
                    iconSize={32}
                    percent={progressData?.[id]}
                    fileStatus={status}
                    onRemove={() => onRemove(id)}
                    source={cancelSources?.[id]}
                  />
                )}
              </>
            ) : null}
          </div>
        );
      })}
    </>
  );
};

export const UploadButton: React.FC<UploadProps> = ({ disabled, ...rest }) => {
  return (
    <Upload {...rest} disabled={disabled}>
      <ButtonSecondary disabled={disabled}>
        <UploadOutlined /> Click to Upload
      </ButtonSecondary>
    </Upload>
  );
};
