import ActionMenu from 'components/ActionMenu';
import ActionMenuList from 'components/ActionMenu/ActionMenuList';
import { ActionMenuItem } from 'components/ActionMenu/ActionMenuList/types';
import { DeleteIcon, DownloadIcon } from 'components/Icon';
import React from 'react';
import { useIntl } from 'react-intl';
import { useFileActionsMenuStyles } from './FileActionsMenu.styles';
import { FileActionsMenuProps } from './FileActionsMenu.types';
import { downloadFile } from './utils/downloadFile';
import { FILE_ACTIONS_MENU_TESTID } from 'utils/testIds';

export const FileActionsMenu = ({
  fileName,
  fileUrl,
  showDelete = true,
  onDelete,
}: FileActionsMenuProps) => {
  const styles = useFileActionsMenuStyles();

  const intl = useIntl();

  const menuItems: ActionMenuItem[] = [
    {
      title: intl.formatMessage({
        id: 'misc.download',
        defaultMessage: 'Download',
      }),
      action: () => downloadFile(fileName, fileUrl),
      icon: <DownloadIcon size={18} />,
      hasPermission: true,
    },
    {
      title: intl.formatMessage({
        id: 'misc.delete',
        defaultMessage: 'Delete',
      }),
      action: onDelete,
      icon: <DeleteIcon size={18} />,
      hasPermission: showDelete,
    },
  ];

  const getPopupContainer = (triggerNode: HTMLElement) => {
    return triggerNode.parentElement ?? document.body;
  };

  return (
    <ActionMenu
      overlayClassName={styles.overlay}
      overlay={<ActionMenuList items={menuItems} />}
      shouldOverlayBeClosedOnClick={true}
      testId={FILE_ACTIONS_MENU_TESTID}
      getPopupContainer={getPopupContainer}
    />
  );
};
