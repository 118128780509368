import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useTasksQuickFiltersStyle = createUseStyles(
  (theme: ThemeState) => {
    return {
      quickFiltersWrapper: {
        display: 'flex',
        flexWrap: 'nowrap',
      },
      quickFilterSelect: {
        width: 250,
        '& .ant-select-selector': {
          height: '35px !important',
        },
      },
      datesWrapper: {
        paddingLeft: 9,
      },
      datesSelect: {
        paddingBottom: 10,
        '& .ant-select-item-group': {
          fontWeight: '700',
          textTransform: 'uppercase',
          borderTop: `1px solid ${theme['border-color-base']}`,
          color: theme['secondary-color'],
          marginTop: 5,
        },
        '& .ant-select-item-option-grouped': {
          paddingLeft: 12,
        },
        '& .add-border-top': {
          borderTop: `1px solid ${theme['border-color-base']}`,
          marginTop: 5,
          paddingBottom: 15,
        },
        '& .ant-select-item-option-selected:not(.ant-select-item-option-disabled)': {
          backgroundColor: theme['dropdown-hover-gray'],
          fontWeight: 700,
          color: theme['primary-color'],
        },
      },
    };
  }
);
