import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAccessToken } from 'store/selectors/authSelectors';
import { decodeToken } from 'utils/functions/decodeToken';

export interface Token {
  token_type: string;
  exp: number;
  iat: number;
  jti: string;
  user_id: number;
  user_login: string;
  user_full_name: string;
}

const useTokenDecode = () => {
  const token = useSelector(getAccessToken);

  return useMemo(() => {
    if (token) {
      try {
        return decodeToken(token);
      } catch {
        return null;
      }
    }

    return null;
  }, [token]);
};

export default useTokenDecode;
