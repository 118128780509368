import React from 'react';
import clsx from 'clsx';
import useWrappersStyles from './styles';
import { FlexContentWrapperProps } from './types';

const FlexContentWrapper: React.FC<FlexContentWrapperProps> = ({
  id,
  className,
  children,
}) => {
  const classes = useWrappersStyles({});

  return (
    <div {...{ id }} className={clsx(className, [classes.flexContentWrapper])}>
      {children}
    </div>
  );
};

export default FlexContentWrapper;
