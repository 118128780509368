import React, { useCallback, useState } from 'react';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import useActivateAccountFormStyles from './styles';
import FormField from '../../../TaskTemplates/components/FormField';
import { Col } from '../../../../components/lib/Grid';
import FormLabel from '../../../TaskTemplates/components/FormLabel';
import TextInput from '../../../../components/Inputs/TextInput';
import { useActivateAccountForm } from '../../hooks';
import { Checkbox } from '../../../../components/lib/Checkbox';
import { ActivateAccountFormFields } from '../../enums';
import { ButtonPrimary } from '../../../../components/lib/Button';
import PasswordInput from '../../../../components/Inputs/PasswordInput';
import PasswordRules from '../../../../components/PasswordRules';
import { useActivateAccountContext } from '../ActivateAccountContext';
import { BUTTON_SUBMIT_TESTID, SHOW_PASSWORD_TESTID } from 'utils/testIds';
import { ActivateAccountFormValues } from 'pages/ActivateAccount/types';

const ActivateAccountForm: React.FC = () => {
  const classes = useActivateAccountFormStyles({});
  const {
    onSubmit,
    initialValues,
    buildValidationSchema,
    isPasswordFulfilled,
    setIsPasswordFulfilled,
  } = useActivateAccountForm();
  const { options } = useActivateAccountContext();
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = useCallback(
    ({ target: { checked } }: CheckboxChangeEvent) => setShowPassword(checked),
    []
  );

  return (
    <Formik
      {...{ initialValues, onSubmit }}
      validationSchema={buildValidationSchema()}
      enableReinitialize
    >
      {({ handleSubmit, errors, dirty, isSubmitting }) => (
        <>
          <FormField>
            <Col span={24}>
              <FormLabel>
                <FormattedMessage
                  id='activateAccount.username'
                  defaultMessage='Username'
                />
              </FormLabel>
              <TextInput disabled name={ActivateAccountFormFields.Email} />
              <span className={classes.description}>
                <FormattedMessage
                  id='users.usernameHint'
                  defaultMessage='This must be valid email'
                />
              </span>
            </Col>
          </FormField>
          <FormField>
            <Col span={12}>
              <FormLabel required>
                <FormattedMessage
                  id='activateAccount.password'
                  defaultMessage='Password'
                />
              </FormLabel>
              <PasswordInput
                visibilityToggle={false}
                isPasswordVisible={showPassword}
                name={ActivateAccountFormFields.Password}
              />
            </Col>
            <Col span={12}>
              <FormLabel required>
                <FormattedMessage
                  id='activateAccount.confirmPassword'
                  defaultMessage='Confirm password'
                />
              </FormLabel>
              <PasswordInput
                visibilityToggle={false}
                isPasswordVisible={showPassword}
                name={ActivateAccountFormFields.ConfirmPassword}
              />
            </Col>
          </FormField>
          <Checkbox
            checked={showPassword}
            onChange={togglePassword}
            data-testid={SHOW_PASSWORD_TESTID}
          >
            <FormattedMessage
              id='activateAccount.showPassword'
              defaultMessage='Show password'
            />
          </Checkbox>
          <PasswordRules<ActivateAccountFormValues>
            setIsFulfilled={setIsPasswordFulfilled}
            {...{ options }}
            passwordKey={ActivateAccountFormFields.Password}
          />
          <div className={classes.buttonWrapper}>
            <ButtonPrimary
              disabled={
                Object.keys(errors).length > 0 ||
                isSubmitting ||
                !dirty ||
                !isPasswordFulfilled
              }
              onClick={() => handleSubmit()}
              data-testid={BUTTON_SUBMIT_TESTID}
            >
              <FormattedMessage
                id='activateAccount.activate'
                defaultMessage='Activate'
              />
            </ButtonPrimary>
          </div>
        </>
      )}
    </Formik>
  );
};

export default ActivateAccountForm;
