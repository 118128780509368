import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useToastStyles = createUseStyles((theme: ThemeState) => {
  return {
    toast: {
      borderRadius: theme['border-radius-base'],
    },
    messageWrapper: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    toastify: {
      width: 400,
      '& .Toastify__toast': {
        padding: 12,
        boxShadow: 'none',
      },
      '& .Toastify__toast--default': {},
      '& .Toastify__toast--info': {
        backgroundColor: '#DDEEF8',
        color: '#0087D3',
      },
      '& .Toastify__toast--success': {
        backgroundColor: '#DAEDDA',
        color: '#368636',
        fontSize: 13,
      },
      '& .Toastify__toast--warning': {
        backgroundColor: '#FEF2EA',
        color: theme['warning-color'],
        fontSize: 13,
      },
      '& .Toastify__toast--error': {
        backgroundColor: theme['error-bg-light'],
        color: theme['error-color'],
        fontSize: 13,
      },
      '& .Toastify__toast--body': {},
      '& .Toastify__progress-bar': {},
      '& .Toastify__progress-bar--animated': {},
      '& .Toastify__progress-bar--default': {},
      '& .Toastify__progress-bar--error': {
        backgroundColor: theme['error-progress'],
      },
      '& .Toastify__progress-bar--warning': {
        backgroundColor: '#F3802C33',
      },
      '& .Toastify__progress-bar--info': {
        backgroundColor: '#BADEF3',
      },
      '& .Toastify__close-button--warning': {
        color: theme['warning-color'],
      },
      '& .Toastify__close-button--error': {
        color: theme['error-color'],
      },
      '& .Toastify__progress-bar--success': {
        backgroundColor: '#B9DCB9',
      },
      '& .Toastify__close-button--success': {
        color: '#368636',
      },
      '& .Toastify__close-button--info': {
        color: '#028FDF',
      },
      '& .Toastify__close-button': {
        '& svg': {
          height: 12,
          width: 12,
        },
      },
    },
    toastTitle: {
      fontWeight: 'bold',
      wordBreak: 'break-word',
      marginBottom: 0,
    },
    toastSubtitle: {
      wordBreak: 'break-word',
      marginBottom: 0,
    },
    messageText: {
      wordBreak: 'break-word',
      marginLeft: 12,
    },
  };
});

export default useToastStyles;
