import React from 'react';
import useEventsListStyles from './styles';
import clsx from 'clsx';
import Tooltip from 'components/lib/Tooltip';
import EventData from './EventData';
import { EVENT_DATA_CHIP } from 'utils/testIds';
import { useEventIcon } from './hooks';
import { EventType } from '../types';

const EventItemErrorFallback = () => {
  const styles = useEventsListStyles();
  const { EventIcon, title } = useEventIcon(EventType.Error);
  return (
    <div className={styles.eventItemWrapper}>
      <div
        className={clsx(styles.eventItemDataWrapper, {
          [styles.eventItemExpand]: false,
        })}
      >
        <Tooltip title={title} addOverlay className={styles.eventItemCircle}>
          <EventIcon data-testid={EVENT_DATA_CHIP} />
        </Tooltip>
        <EventData
          {...{
            label: <div className={styles.eventErrorText}>{title}</div>,
            isExpanded: false,
            isMultiple: false,
          }}
        />
      </div>
    </div>
  );
};

export default EventItemErrorFallback;
