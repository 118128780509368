import React from 'react';
import { useIntl } from 'react-intl';
import useNoMatchesFoundStyles from './styles';
import { NoMatchFoundIcon } from 'components/Icon';

const NoMatchesFound = () => {
  const intl = useIntl();
  const classes = useNoMatchesFoundStyles({});

  const notFoundMessage = intl.formatMessage({
    id: 'misc.noMatchesFound',
    defaultMessage: 'No matches found',
  });

  return (
    <div className={classes.wrapper}>
      <NoMatchFoundIcon size={50} />
      <span>{notFoundMessage}</span>
    </div>
  );
};

export default NoMatchesFound;
