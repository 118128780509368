import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useAutocompleteChildRecordsSelectStyles = createUseStyles(
  (theme: ThemeState) => ({
    autocompleteSelect: {
      width: 200,
      height: `32px !important`,
      '& .ant-select-selector': {
        minHeight: `32px !important`,
        paddingTop: 4,
        '& span:not(.ant-select-selection-search)': {
          maxWidth: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
      '& .ant-select-selection-search-input': {
        fontWeight: 700,
      },
    },
    selectItem: {
      minHeight: 47,
      lineHeight: '19px',
      '& .ant-select-item-option-content': {
        display: 'flex',
        alignItems: 'center',
        '& > div:first-child': {
          marginRight: 6,
        },
      },
      '& mark': {
        padding: 0,
        backgroundColor: 'inherit',
      },
      '&.ant-select-item-option-disabled ': {
        '& .ant-avatar': {
          background: theme['border-color-base'],
        },
        '& .ant-select-item-option-content > div:not(:first-child)': {
          color: theme['disabled-switch-gray'],
          '& mark': {
            color: theme['disabled-switch-gray'],
          },
        },
      },
    },
    searchIcon: {
      color: ({ disabled }: { disabled?: boolean }) =>
        disabled ? theme['disabled-switch-gray'] : theme['primary-color'],
    },
  })
);

export default useAutocompleteChildRecordsSelectStyles;
