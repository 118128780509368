import React, { useRef, useEffect } from 'react';
import { SIDE_PANEL } from 'utils/elementsIds';
import { SIDE_PANEL_TESTID } from 'utils/testIds';
import { InternalWindowContextProvider } from './InternalWindowContext';
import useFlexLayoutStyles from './styles';

const InternalWindowRefWrapper: React.FC<{
  isVisible: boolean;
  onForceClose: VoidFunction;
  onMouseEnter: VoidFunction;
}> = ({ children, isVisible, onForceClose, onMouseEnter }) => {
  const windowRef = useRef<HTMLDivElement>(null);
  const classes = useFlexLayoutStyles({});

  useEffect(() => {
    if (!isVisible) onForceClose();
  }, [isVisible, onForceClose]);

  return (
    <div
      ref={windowRef}
      className={classes.wrapper}
      data-testid={SIDE_PANEL_TESTID}
      id={SIDE_PANEL}
      {...{ onMouseEnter }}
    >
      <InternalWindowContextProvider windowRef={windowRef}>
        {children}
      </InternalWindowContextProvider>
    </div>
  );
};

export default InternalWindowRefWrapper;
