import React from 'react';
import { Input } from 'reactstrap';
import Select from 'react-select';
import FBCheckbox from '../../FBCheckbox';
import CardEnumOptions from '../../CardEnumOptions';
import useDefaultInputStyles from './styles';
import { FieldProps } from './types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMultipleChoice } from './hooks';
import { InputTypes, WidgetTypes } from '../../FormBuilder/enums';
import { countries } from 'country-flags-svg';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';

// specify the inputs required for a string type object
export function CardDefaultParameterInputs() {
  return <div />;
}

export const TimeField: React.FC<FieldProps> = ({ parameters, onChange }) => {
  return (
    <React.Fragment>
      <h5>
        <FormattedMessage
          id='formBuilder.defaultTime'
          defaultMessage='Default time'
        />
      </h5>
      <Input
        value={typeof parameters.default === 'string' ? parameters.default : ''}
        placeholder='Default'
        type='datetime-local'
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
          onChange({ ...parameters, default: ev.target.value })
        }
        className='card-text'
      />
    </React.Fragment>
  );
};

export const Checkbox: React.FC<FieldProps> = ({ parameters, onChange }) => {
  return (
    <div className='card-boolean'>
      <FBCheckbox
        onChangeValue={() => {
          onChange({
            ...parameters,
            default: parameters.default ? parameters.default !== true : true,
          });
        }}
        isChecked={parameters.default ? parameters.default === true : false}
        label='Default'
      />
    </div>
  );
};

export const MultipleChoice: React.FC<FieldProps> = ({
  parameters,
  onChange,
}) => {
  const classes = useDefaultInputStyles({});
  const {
    containsUnparsableString,
    onPossibleValuesChange,
    enumArray,
    isNumber,
    onForceNumberChange,
  } = useMultipleChoice(parameters, onChange);
  const intl = useIntl();

  return (
    <div className='card-enum'>
      <h3>Possible Values</h3>
      <FBCheckbox
        onChangeValue={onPossibleValuesChange}
        isChecked={Array.isArray(parameters.enumNames)}
        label={intl.formatMessage({
          id: 'formBuilder.displayLabelMsg',
          defaultMessage: 'Display label is different from value',
        })}
      />
      <div
        className={
          containsUnparsableString || !enumArray.length ? classes.hidden : ''
        }
      >
        <FBCheckbox
          onChangeValue={onForceNumberChange}
          isChecked={isNumber}
          disabled={containsUnparsableString}
          label={intl.formatMessage({
            id: 'formBuilder.forceNumber',
            defaultMessage: 'Force number',
          })}
        />
      </div>
      <CardEnumOptions
        initialValues={enumArray}
        names={
          Array.isArray(parameters.enumNames)
            ? parameters.enumNames.map(val => `${val}`)
            : undefined
        }
        showNames={Array.isArray(parameters.enumNames)}
        onChange={(newEnum: Array<string>, newEnumNames?: Array<string>) =>
          onChange({
            ...parameters,
            enum: newEnum,
            enumNames: newEnumNames,
          })
        }
        type={isNumber ? 'number' : 'string'}
      />
    </div>
  );
};

export const RefChoice: React.FC<FieldProps> = ({ parameters, onChange }) => {
  return (
    <div className='card-select'>
      <Select
        value={{
          value: parameters.$ref,
          label: parameters.$ref,
        }}
        placeholder='Reference'
        options={Object.keys(parameters.definitionData || {}).map(key => ({
          value: `#/definitions/${key}`,
          label: `#/definitions/${key}`,
        }))}
        onChange={({ value }: any) => {
          onChange({ ...parameters, $ref: value });
        }}
        className='card-select'
      />
    </div>
  );
};

const defaultInputs = {
  date: {
    displayName: 'Date',
    matchIf: [
      {
        types: ['string'],
        format: 'date',
      },
      {
        types: ['string'],
        format: 'date-time',
      },
    ],
    defaultDataSchema: {
      format: 'date',
    },
    defaultUiSchema: {},
    type: 'string',
    cardBody: TimeField,
    modalBody: CardDefaultParameterInputs,
  },
  time: {
    displayName: 'Time',
    matchIf: [
      {
        types: ['string'],
        format: 'time',
      },
    ],
    defaultDataSchema: {
      format: 'time',
    },
    defaultUiSchema: {
      'ui:widget': InputTypes.Time,
    },
    type: 'string',
    cardBody: TimeField,
    modalBody: CardDefaultParameterInputs,
  },
  checkbox: {
    displayName: 'Checkbox',
    matchIf: [
      {
        types: ['boolean'],
      },
    ],
    defaultDataSchema: {},
    defaultUiSchema: {},
    type: 'boolean',
    cardBody: Checkbox,
    modalBody: CardDefaultParameterInputs,
  },
  ref: {
    displayName: 'Reference',
    matchIf: [
      {
        types: [null],
        $ref: true,
      },
    ],
    defaultDataSchema: {
      $ref: '',
    },
    defaultUiSchema: {},
    type: null,
    cardBody: RefChoice,
    modalBody: CardDefaultParameterInputs,
  },
  radio: {
    displayName: 'Radio',
    matchIf: [
      {
        types: ['string', 'number', 'integer', 'array', 'boolean', null],
        widget: 'radio',
        enum: true,
      },
    ],
    defaultDataSchema: { enum: [] },
    defaultUiSchema: {},
    type: 'string',
    cardBody: MultipleChoice,
    modalBody: CardDefaultParameterInputs,
  },
  dropdown: {
    displayName: 'Dropdown',
    matchIf: [
      {
        types: ['string', 'number', 'integer', 'array', 'boolean', null],
        enum: true,
      },
    ],
    defaultDataSchema: { enum: [] },
    defaultUiSchema: {
      'ui:widget': 'dropdown',
    },
    type: 'string',
    cardBody: MultipleChoice,
    modalBody: CardDefaultParameterInputs,
  },
  [InputTypes.CountrySelect]: {
    displayName: 'Country select',
    matchIf: [
      {
        types: ['string'],
        widget: InputTypes.CountrySelect,
        enum: true,
      },
    ],
    defaultDataSchema: {
      enum: countries.map(country => country.iso3),
      enumNames: countries.map(country => country.name),
    },
    defaultUiSchema: {
      'ui:widget': InputTypes.CountrySelect,
    },
    type: 'string',
    cardBody: MultipleChoice,
    modalBody: CardDefaultParameterInputs,
  },
  [InputTypes.Instruction]: {
    displayName: 'Instruction',
    matchIf: [
      {
        types: ['string'],
        widget: InputTypes.Instruction,
      },
    ],
    defaultDataSchema: {},
    defaultUiSchema: {
      'ui:widget': InputTypes.Instruction,
    },
    type: 'string',
    cardBody: CardDefaultParameterInputs,
    modalBody: CardDefaultParameterInputs,
  },
  [InputTypes.Multiselect]: {
    displayName: 'Multi select',
    matchIf: [
      {
        types: ['array', 'string'],
        widget: WidgetTypes.MultiselectCheckbox,
      },
    ],
    defaultDataSchema: {
      items: {
        type: 'string',
        enum: [],
      },
      uniqueItems: true,
    },
    defaultUiSchema: {
      'ui:widget': WidgetTypes.MultiselectCheckbox,
    },
    type: 'array',
    cardBody: MultipleChoice,
    modalBody: CardDefaultParameterInputs,
  },
  file: {
    displayName: GlobalIntlSingleton.intl.formatMessage({
      id: 'formBuilder.fileUpload',
      defaultMessage: 'File upload',
    }),
    matchIf: [
      {
        types: ['array'],
        widget: InputTypes.File,
      },
    ],
    defaultDataSchema: {
      items: { type: 'string', format: 'data-url' },
    },
    defaultUiSchema: {
      'ui:widget': InputTypes.File,
    },
    type: 'array',
    cardBody: CardDefaultParameterInputs,
    modalBody: CardDefaultParameterInputs,
  },
  [InputTypes.Owners]: {
    displayName: GlobalIntlSingleton.intl.formatMessage({
      id: 'formBuilder.owners',
      defaultMessage: 'Owners',
    }),
    matchIf: [
      {
        types: ['array'],
        widget: InputTypes.Owners,
      },
    ],
    defaultDataSchema: {
      items: { type: 'string', format: 'data-url' },
    },
    defaultUiSchema: {
      'ui:widget': InputTypes.Owners,
    },
    type: 'array',
    cardBody: CardDefaultParameterInputs,
    modalBody: CardDefaultParameterInputs,
  },
  child_class: {
    displayName: GlobalIntlSingleton.intl.formatMessage({
      id: 'formBuilder.childObjectClass',
      defaultMessage: 'Child Object Class',
    }),
    matchIf: [
      {
        types: ['child_class'],
        format: 'child_class',
        widget: 'child_class',
      },
    ],
    defaultDataSchema: {
      format: 'child_class',
    },
    defaultUiSchema: {
      'ui:widget': InputTypes.ChildClass,
    },
    type: 'child_class',
    cardBody: CardDefaultParameterInputs,
    modalBody: CardDefaultParameterInputs,
  },
  [InputTypes.JSON]: {
    displayName: GlobalIntlSingleton.intl.formatMessage({
      id: 'formBuilder.json',
      defaultMessage: 'JSON',
    }),
    matchIf: [
      {
        types: ['json'],
        format: 'json',
        widget: 'json',
      },
    ],
    defaultDataSchema: {
      format: 'json',
    },
    defaultUiSchema: {
      'ui:widget': InputTypes.JSON,
    },
    type: 'string',
    cardBody: CardDefaultParameterInputs,
    modalBody: CardDefaultParameterInputs,
  },
  [InputTypes.User]: {
    displayName: GlobalIntlSingleton.intl.formatMessage({
      id: 'formBuilder.user',
      defaultMessage: 'User',
    }),
    matchIf: [
      {
        types: ['array', 'string'],
        widget: InputTypes.User,
      },
    ],
    defaultDataSchema: {
      items: {
        type: 'string',
        users: [],
      },
      uniqueItems: true,
    },
    defaultUiSchema: {
      'ui:widget': InputTypes.User,
    },
    type: 'array',
    cardBody: CardDefaultParameterInputs,
    modalBody: CardDefaultParameterInputs,
  },
  [InputTypes.ExternalComponent]: {
    displayName: GlobalIntlSingleton.intl.formatMessage({
      id: 'formBuilder.ExternalComponent',
      defaultMessage: 'Custom Component',
    }),
    matchIf: [
      {
        types: [InputTypes.ExternalComponent],
        widget: InputTypes.ExternalComponent,
      },
    ],
    defaultDataSchema: {},
    defaultUiSchema: {
      'ui:widget': InputTypes.ExternalComponent,
    },
    type: InputTypes.ExternalComponent,
    cardBody: CardDefaultParameterInputs,
    modalBody: CardDefaultParameterInputs,
  },
};

export default defaultInputs;
