import React, { forwardRef, MutableRefObject, useEffect, useRef } from 'react';
import { InputNumber as AntNumberInput } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import useInputStyles from '../Input/styles';
import clsx from 'clsx';
import useGlobalStyle from 'hooks/useGlobalStyle';
import useInputNumberStyles from './styles';
import { Field, useField, useFormikContext } from 'formik';
import useStepChange from './hooks';
import ArrowsWrapper from './ArrowsWrapper';
import { FormikFormItem } from '../Form';
import fixValue from 'utils/functions/fixValue';
import { MAX_DECIMAL_NUMBERS } from './consts';
import { FormikNumericProps } from './types';
import MinMaxInfo from 'components/MinMaxInfo';
import useDefaultValueResetToast from '../../../hooks/useDefaultValueResetToast';
import { NUMBER_INPUT_TESTID } from 'utils/testIds';

interface ExtendedInputNumberProps extends InputNumberProps {
  inputClassName?: string;
  showMinMax?: boolean;
  onButtonChange?: (newValue: number) => void;
  disableAutoCorrectValue?: boolean;
}

const InputNumber = forwardRef<typeof AntNumberInput, ExtendedInputNumberProps>(
  (
    {
      className,
      inputClassName,
      disabled,
      value,
      onChange,
      onStep,
      min,
      max,
      showMinMax,
      onButtonChange,
      disableAutoCorrectValue,
      ...rest
    },
    ref
  ) => {
    const classes = useInputStyles({});
    const inputNumberClasses = useInputNumberStyles({});
    const globalClasses = useGlobalStyle();
    const stepChange = useStepChange();

    // this changes the value to respect min/max if set
    useEffect(() => {
      if (
        onChange === undefined ||
        value === undefined ||
        value === null ||
        disableAutoCorrectValue
      )
        return;

      if (min !== undefined && value < min) {
        onChange(min);

        return;
      }

      if (max !== undefined && value > max) onChange(max);
    }, [value, min, max, onChange, disableAutoCorrectValue]);

    const onCustomChange = (value: string | number | undefined) => {
      if (!onChange) return;

      if (value === '') {
        onChange(undefined);

        return;
      }

      //on blur numeric returns null when empty input - this prevents running onChange
      if (value !== null) {
        onChange(value);
      }
    };

    const onUpClick = () => {
      if (disabled) return;

      const numericValue = Number(value);

      if (ref) {
        const input = (ref as MutableRefObject<
          typeof AntNumberInput & HTMLInputElement
        >).current;
        input.focus();
      }

      if (onStep && !isNaN(numericValue)) {
        onStep(numericValue + 1, { offset: 1, type: 'up' });
      } else if (onStep && isNaN(numericValue)) {
        onStep(1, { offset: 1, type: 'up' });
      }
      const roundedValue = stepChange(1, value, onChange);

      if (!onButtonChange || roundedValue === undefined) return;

      if (max !== undefined && roundedValue > max) onButtonChange(max);
      else if (min !== undefined && roundedValue < min) onButtonChange(min);
      else onButtonChange(roundedValue);
    };

    const onDownClick = () => {
      if (disabled) return;

      const numericValue = Number(value);

      if (ref) {
        const input = (ref as MutableRefObject<
          typeof AntNumberInput & HTMLInputElement
        >).current;
        input.focus();
      }

      if (onStep && !isNaN(numericValue)) {
        onStep(numericValue - 1, { offset: 1, type: 'down' });
      } else if (onStep && isNaN(numericValue)) {
        onStep(-1, { offset: 1, type: 'down' });
      }

      const roundedValue = stepChange(-1, value, onChange);

      if (!onButtonChange || roundedValue === undefined) return;

      if (max !== undefined && roundedValue > max) onButtonChange(max);
      else if (min !== undefined && roundedValue < min) onButtonChange(min);
      else onButtonChange(roundedValue);
    };

    return (
      <>
        <div
          className={clsx([inputNumberClasses.inputNumberWrapper, className])}
        >
          <AntNumberInput
            {...{ ...rest, disabled, value, ref }}
            onChange={onCustomChange}
            className={clsx([
              classes.input,
              inputNumberClasses.inputNumber,
              { [globalClasses.disabledInput]: disabled },
              inputClassName,
            ])}
          />
          <ArrowsWrapper
            onArrowUpPress={onUpClick}
            onArrowDownPress={onDownClick}
          />
        </div>
        {showMinMax && <MinMaxInfo minimum={min} maximum={max} />}
      </>
    );
  }
);

const FormikInputNumber = <T extends object>({
  disabled,
  name,
  isDecimal = true,
  minimum,
  maximum,
  onBlur,
  ...rest
}: FormikNumericProps) => {
  const inputRef = useRef(null);
  const [{ value }, { touched, error }] = useField(name);
  const { setFieldValue, setFieldTouched } = useFormikContext<T>();
  const resetDefaultValueToast = useDefaultValueResetToast();

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = ({
    target: { value: changedValue },
  }) => {
    const parsedValue: number | undefined = fixValue(
      parseFloat(changedValue),
      MAX_DECIMAL_NUMBERS,
      isDecimal
    );
    setFieldTouched(name, true);

    if (
      parsedValue === null ||
      (minimum !== undefined && parsedValue < minimum) ||
      (maximum !== undefined && parsedValue > maximum)
    ) {
      resetDefaultValueToast();

      return setFieldValue(name, undefined);
    }

    setFieldValue(name, isNaN(parsedValue) ? undefined : parsedValue);

    if (onBlur) onBlur(name, value);
  };

  const handleChange = (value: string | number | undefined) => {
    if (value === null) return setFieldValue(name, undefined);

    if (value === '') {
      return setFieldValue(name, undefined);
    }

    let roundedValue = value?.toString().length !== 0 ? value : undefined;

    try {
      roundedValue = fixValue(value as number, MAX_DECIMAL_NUMBERS);
    } catch {}

    const parsedValue: number | undefined = parseFloat(
      roundedValue?.toString() || ''
    );

    if (
      parsedValue === null ||
      (minimum !== undefined && parsedValue < minimum) ||
      (maximum !== undefined && parsedValue > maximum)
    ) {
      return setFieldValue(name, undefined);
    }

    setFieldValue(name, isNaN(parsedValue) ? undefined : parsedValue);
  };

  return (
    <FormikFormItem
      {...{ name }}
      validateStatus={touched && error ? 'error' : undefined}
    >
      <Field {...{ name }}>
        {() => (
          <InputNumber
            type='number'
            onChange={handleChange}
            onBlur={handleBlur}
            ref={inputRef}
            {...{ disabled, value, ...rest }}
            data-testid={`${NUMBER_INPUT_TESTID}${name}`}
          />
        )}
      </Field>
    </FormikFormItem>
  );
};

export { InputNumber, FormikInputNumber };
