import React, { useCallback } from 'react';
import { ConfigProvider, Layout as AntLayout } from 'antd';
import { LogoIcon } from '../../components/Icon';
import { LayoutContextProvider } from 'components/lib/Layout/context';
import { Link } from 'react-router-dom';
import useLayoutStyles from 'components/lib/Layout/styles';
import SideMenu from './SideMenu';
import routes from 'utils/routingPaths';
import { SideMenuContextProvider } from './context/SideMenuContext';
import usePageLayoutStyles from './styles';
import { FlexLayoutContextProvider } from 'components/lib/FlexLayout/FlexLayoutContext';
import useAntLanguage from 'hooks/useAntLanguage';
import { useIntl } from 'react-intl';
import { ExtendedLayoutProps } from './types';
import { ConfirmationModalContextProvider } from 'contexts/ConfirmationModalContext';
import Pin from 'components/lib/Pin';
import { SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_EXPANDED_WIDTH } from 'utils/consts';
import {
  ALX_LOGO_WRAPPER_TESTID,
  SIDEBAR_MENU_WRAPPER_TESTID,
} from 'utils/testIds';
import { ViewMenuContextProvider } from './context/ViewMenuContext';
import { SelectedResourceContextProvider } from 'contexts/SelectedResourceContext';
import GTag from 'providers/IntlProviderWrapper/gTag';
import { GTagEventButtonName } from 'providers/IntlProviderWrapper/gTag/types';
import { FallbackLogoOptions } from 'components/Icon/types';
import CollapsedSideMenu from './CollapsedSideMenu';

const { Sider } = AntLayout;

const PageLayout: React.FC<ExtendedLayoutProps> = ({
  children,
  sidebarTheme = 'dark',
  isCollapsed,
  setIsCollapsed,
  isPinned,
  togglePinned,
}) => {
  const classes = useLayoutStyles({});
  const intl = useIntl();
  const antLanguage = useAntLanguage();
  const currentSidebarWidth =
    isCollapsed || !isPinned ? SIDEBAR_COLLAPSED_WIDTH : SIDEBAR_EXPANDED_WIDTH;
  const pageLayoutClasses = usePageLayoutStyles({ currentSidebarWidth });

  const pinMessage = isPinned
    ? intl.formatMessage({
        id: 'enums.unpinPanel',
        defaultMessage: 'Unpin expanded panel',
      })
    : intl.formatMessage({
        id: 'enums.pinPanel',
        defaultMessage: 'Pin expanded panel',
      });

  const onMouseEnter = useCallback(() => setIsCollapsed(false), [
    setIsCollapsed,
  ]);
  const onMouseLeave = useCallback(() => setIsCollapsed(!isPinned), [
    isPinned,
    setIsCollapsed,
  ]);
  const linkOnClick = useCallback(
    () => GTag.pushGtagButtonEvent(GTagEventButtonName.Logo),
    []
  );

  return (
    <ConfirmationModalContextProvider>
      <FlexLayoutContextProvider>
        <ConfigProvider locale={antLanguage}>
          <AntLayout className={classes.mainLayout}>
            <Sider
              collapsible
              collapsed={isCollapsed}
              theme={sidebarTheme}
              width={SIDEBAR_EXPANDED_WIDTH}
              collapsedWidth={SIDEBAR_COLLAPSED_WIDTH}
              className={pageLayoutClasses.sider}
              data-testid={SIDEBAR_MENU_WRAPPER_TESTID}
              trigger={null}
              {...{ onMouseEnter, onMouseLeave }}
            >
              <div className={classes.sidebarLogoWrapper}>
                <Link to={routes.WORKSPACE} onClick={linkOnClick}>
                  <div
                    className={classes.sidebarLogo}
                    data-testid={ALX_LOGO_WRAPPER_TESTID}
                  >
                    {isCollapsed ? (
                      <LogoIcon
                        fallbackLogo={FallbackLogoOptions.SMALL}
                        width={32}
                        height={32}
                      />
                    ) : (
                      <LogoIcon
                        fallbackLogo={FallbackLogoOptions.SIDEBAR}
                        width={121}
                        height={32}
                        style={{ marginLeft: -2 }}
                      />
                    )}
                  </div>
                </Link>
                <Pin
                  title={pinMessage}
                  enabled={isPinned}
                  handleToggle={togglePinned}
                />
              </div>
              <SideMenuContextProvider>
                {isCollapsed ? (
                  <CollapsedSideMenu />
                ) : (
                  <SideMenu
                    theme={sidebarTheme}
                    parentCollapsed={isCollapsed}
                  />
                )}
              </SideMenuContextProvider>
            </Sider>
            <AntLayout className={pageLayoutClasses.layout}>
              <ViewMenuContextProvider>
                <SelectedResourceContextProvider>
                  <LayoutContextProvider
                    value={{
                      isSidebarCollapsed: isCollapsed,
                    }}
                  >
                    {children}
                  </LayoutContextProvider>
                </SelectedResourceContextProvider>
              </ViewMenuContextProvider>
            </AntLayout>
          </AntLayout>
        </ConfigProvider>
      </FlexLayoutContextProvider>
    </ConfirmationModalContextProvider>
  );
};

export default PageLayout;
