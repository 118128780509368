import React, { useEffect } from 'react';
import { Input } from 'components/lib/Input';
import { InPlaceEditTextboxProps } from './types';
import useInPlaceEdit from '../useInPlaceEdit';
import InPlaceEditWrapper from '../components/InPlaceEditWrapper';
import { ReactComponent as InputImage } from 'img/icons/textbox-icon.svg';
import { useErrorMessages } from '../../../utils/useErrorMessages';
import { InPlaceEditError } from '../utils';
import FieldValidationMessage from '../components/FieldValidationMessage';
import useCommonInPlaceEditStyles from '../components/commonStyles';
import { Dropdown } from 'components/lib/Dropdown';
import useInPlaceEditTextinputStyles from './styles';
import { IN_PLACE_EDIT_TEXT_INPUT } from 'utils/testIds';
import TooltipString from 'components/TooltipString';

const InPlaceEditTextInput = <R extends Object>({
  label,
  required,
  value,
  disabled,
  propertyName,
  patchUrl,
  onSaveSuccess,
  maxLength,
  getPopupContainer,
  remountChangeTarget,
  readOnly,
  size,
  withUnderline,
}: InPlaceEditTextboxProps<R>) => {
  const {
    inputWrapperRef,
    isEditMode,
    setTempValue,
    tempValue,
    isSaving,
    setIsEditModeOff,
    setIsEditModeOn,
    errorMessage,
    setErrorMessage,
  } = useInPlaceEdit({
    initialValue: value,
    patchUrl,
    propertyName,
    onSaveSuccess,
  });

  const classes = useCommonInPlaceEditStyles({
    isSaving,
    errorMessage,
    size,
  });
  const textInputClasses = useInPlaceEditTextinputStyles({});
  const errorMessages = useErrorMessages('', maxLength);

  const onViewClick = () => {
    if (disabled) return;

    setIsEditModeOn();
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    if (required && value.trim().length === 0)
      setErrorMessage(errorMessages[InPlaceEditError.FIELD_IS_REQUIRED_ERROR]);
    else if (maxLength && value.length > maxLength)
      setErrorMessage(errorMessages[InPlaceEditError.LIMIT_OF_CHARS_EXCEEDED]);
    else setErrorMessage(undefined);

    setTempValue(value);
  };

  const message =
    maxLength !== undefined && maxLength !== null
      ? `${tempValue?.length || 0}/${maxLength}`
      : undefined;

  const editAbort = () => {
    setTempValue(value);
    setIsEditModeOff();
  };

  useEffect(() => {
    setTempValue(value);
  }, [value, setTempValue]);

  return (
    <InPlaceEditWrapper
      {...{
        isEditMode,
        isSaving,
        label,
        required,
        disabled,
        onViewClick,
        inputWrapperRef,
        readOnly,
        size,
        withUnderline,
      }}
      onCloseClick={editAbort}
      editContent={
        <Dropdown
          visible={!isSaving && (!!errorMessage || !!message)}
          {...{ getPopupContainer, remountChangeTarget }}
          overlay={
            <FieldValidationMessage error={errorMessage} {...{ message }} />
          }
        >
          <div className={classes.basicInPlaceInputWrapper}>
            <Input
              maxLength={maxLength ?? undefined}
              withCounter={false}
              value={tempValue ?? ''}
              onChange={onInputChange}
              className={classes.basicInPlaceInput}
              autoFocus
              onKeyDown={event => event.key === 'Escape' && editAbort()}
              data-testid={IN_PLACE_EDIT_TEXT_INPUT}
              disableTextTrim
            />
            <InputImage width={16} height={16} />
          </div>
        </Dropdown>
      }
      viewContent={
        !!tempValue?.length ? (
          <TooltipString
            withShortTooltipText={false}
            text={tempValue}
            className={textInputClasses.singleLinePreview}
          />
        ) : (
          '-'
        )
      }
    />
  );
};

export default InPlaceEditTextInput;
