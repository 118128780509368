import { DraggingStyle } from 'react-beautiful-dnd';
import { TRANSFORM_TRANSLATE_REGEX } from 'utils/consts';

export const calcTransformTranslate = (
  style?: DraggingStyle,
  topOffset?: number,
  leftOffset?: number,
  bottomOffset?: number
) => {
  if (style === undefined) return;

  const { height, top, left, transform } = style;

  const minTranslateY = (topOffset ?? 0) - top;
  const maxTranslateY = bottomOffset ?? window.innerHeight - top - height;
  const minTranslateX = (leftOffset ?? 0) - left - 1; //-1 makes the limit just before the end of the table. This allows the react dnd to place the droppable at the right place

  const extractedTranslateValue = transform?.match(TRANSFORM_TRANSLATE_REGEX);
  const currentX = parseInt(extractedTranslateValue?.[1] || '0');
  const currentY = parseInt(extractedTranslateValue?.[2] || '0');

  const tooFarLeft = currentX < minTranslateX;
  const tooFarTop = currentY < minTranslateY;
  const tooFarBottom = currentY > maxTranslateY;

  if (tooFarTop || tooFarLeft || tooFarBottom) {
    const newY = tooFarTop ? minTranslateY : currentY;
    const newX = tooFarLeft ? minTranslateX : currentX;

    return {
      transform: `translate(${newX}px, ${
        newY < maxTranslateY ? newY : maxTranslateY
      }px)`,
    };
  }
};
