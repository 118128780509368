import React from 'react';
import { TimePicker as AntTimePicker } from 'antd';
import usePickerStyles from '../DatePicker/styles';
import { TimePickerProps, TimeRangePickerProps } from 'antd/lib/time-picker';
import {
  TimePicker as FTimePicker,
  TimePickerProps as FTimePickerProps,
} from 'formik-antd';
import usePopupContainer from 'hooks/usePopupContainer';
import useGlobalStyle from 'hooks/useGlobalStyle';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

const { RangePicker: AntRangePicker } = AntTimePicker;

const localesWith12Hour = ['en-US'];

export const TimePicker: React.FC<TimePickerProps> = ({
  disabled,
  use12Hours,
  format,
  getPopupContainer,
  className,
  ...rest
}) => {
  const classes = usePickerStyles({});
  const globalClasses = useGlobalStyle();
  const defaultGetPopupContainer = usePopupContainer();
  const intl = useIntl();

  const doesLanguageUse12Hour = localesWith12Hour.includes(intl.locale);
  const localeTimeFormat = doesLanguageUse12Hour ? 'hh:mm A' : 'HH:mm';

  return (
    <AntTimePicker
      {...{ ...rest, disabled }}
      getPopupContainer={getPopupContainer || defaultGetPopupContainer}
      className={clsx([
        classes.datepicker,
        className,
        { [globalClasses.disabledInput]: disabled },
      ])}
      use12Hours={use12Hours ?? doesLanguageUse12Hour}
      format={format ?? localeTimeFormat}
    />
  );
};

export const FormikTimePicker: React.FC<FTimePickerProps> = ({
  disabled,
  ...rest
}) => {
  const classes = usePickerStyles({});
  const globalClasses = useGlobalStyle();
  const getPopupContainer = usePopupContainer();

  return (
    <FTimePicker
      {...{ ...rest, getPopupContainer, disabled }}
      className={clsx([
        classes.datepicker,
        { [globalClasses.disabledInput]: disabled },
      ])}
    />
  );
};

export const TimeRangePicker: React.FC<TimeRangePickerProps> = ({
  disabled,
  ...rest
}) => {
  const classes = usePickerStyles({});
  const globalClasses = useGlobalStyle();
  const getPopupContainer = usePopupContainer();

  return (
    <AntRangePicker
      {...{ ...rest, getPopupContainer, disabled }}
      className={clsx([
        classes.datepicker,
        { [globalClasses.disabledInput]: disabled },
      ])}
    />
  );
};
