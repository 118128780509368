import React, { useMemo } from 'react';
import CollapseWithHeaderButton from 'components/CollapseWithHeaderButton';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { updatePreferences } from 'store/actions/preferencesActions';
import { getTaskPropertiesOpen } from 'store/selectors/preferencesSelectors';
import useTaskPropertiesStyles from './styles';
import { TaskPropertiesProps } from './types';
import IncompleteTaskPropertiesContent from './components/IncompleteTaskPropertiesContent';
import CompleteTaskPropertiesContent from './components/CompleteTaskPropertiesContent';
import { TaskStatus } from 'utils/Enums/TaskStatus';
import { StageNames } from 'utils/types/api/tasks.types';
import { PreferencesTypes } from 'utils/types/api/preferences.types';

const TaskProperties = ({
  task: {
    id,
    status,
    completed_at: completedAt,
    completed_by: completedBy,
    due_date: dueDate,
    expiry_date: expiryDate,
    stages = [],
    next_actions,
  },
}: TaskPropertiesProps) => {
  const styles = useTaskPropertiesStyles({});
  const dispatch = useDispatch();
  const taskPropertiesInitiallyOpen = useSelector(getTaskPropertiesOpen);

  const onOpenChange = (isHeaderOpen: boolean) =>
    dispatch(
      updatePreferences(PreferencesTypes.LayoutPreferences, {
        tasks: { taskPropertiesOpen: isHeaderOpen },
      })
    );

  const stage = useMemo(
    () => stages.find(({ name }) => name === StageNames.Stage1),
    [stages]
  );

  return (
    <CollapseWithHeaderButton
      initialOpen={taskPropertiesInitiallyOpen}
      headerButtonContent={
        <FormattedMessage
          id='task.taskProperties'
          defaultMessage='Task properties'
        />
      }
      {...{ onOpenChange }}
    >
      <div className={styles.grid}>
        {status !== TaskStatus.Completed ? (
          <IncompleteTaskPropertiesContent
            {...{ dueDate, id, stage }}
            nextActions={next_actions}
          />
        ) : (
          <CompleteTaskPropertiesContent
            {...{ completedBy, expiryDate }}
            completionDate={completedAt}
          />
        )}
      </div>
    </CollapseWithHeaderButton>
  );
};

export default TaskProperties;
