export enum ColoursSectionFieldNames {
  PrimaryColour = 'primary-color',
  SecondaryColour = 'secondary-color',
  TertiaryColour = 'tertiary-color',
  SiderBg = 'sider-bg',
  SectionContentBG = 'section-content-bg',
  SectionHeaderBG = 'section-header-bg',
  LinkColour = 'link-color',
  PrimaryColourLight = 'primary-color-light',
  PrimaryColourMedium = 'primary-color-medium',
  PrimaryColourDark = 'primary-color-dark',
  SecondaryColourLight = 'secondary-color-light',
  SecondaryColourDark = 'secondary-color-dark',
  TertiaryDisabledColour = 'tertiary-disabled-color',
  SiderBGSecondary = 'sider-bg-secondary',
  SiderBGThird = 'sider-bg-third',
  SiderBGSeparator = 'sider-bg-separater',
  PrimaryColourOpacity = 'primary-color-opacity'
}

export enum ColoursSectionFieldFQNames {
  PrimaryColour = 'colours.primary-color',
  SecondaryColour = 'colours.secondary-color',
  TertiaryColour = 'colours.tertiary-color',
  SiderBg = 'colours.sider-bg',
  SectionContentBG = 'colours.section-content-bg',
  SectionHeaderBG = 'colours.section-header-bg',
  LinkColour = 'colours.link-color',
  PrimaryColourLight = 'colours.primary-color-light',
  PrimaryColourMedium = 'colours.primary-color-medium',
  PrimaryColourDark = 'colours.primary-color-dark',
  SecondaryColourLight = 'colours.secondary-color-light',
  SecondaryColourDark = 'colours.secondary-color-dark',
  TertiaryDisabledColour = 'colours.tertiary-disabled-color',
  SiderBGSecondary = 'colours.sider-bg-secondary',
  SiderBGThird = 'colours.sider-bg-third',
  SiderBGSeparator = 'colours.sider-bg-separater',
  PrimaryColourOpacity = 'colours.primary-color-opacity'
}
