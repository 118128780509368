import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { generatePath } from 'react-router-dom';
import { apiCall } from 'utils/api';
import { DOCUMENT_TEMPLATE_PANEL_FIELDS } from 'utils/endpoints';
import { GetResponse } from 'utils/types';
import { DocumentTemplateFields } from '../types';
import { useDispatch } from 'react-redux';
import {
  generateAndDownloadDocumentTemplatePanel,
  setDocumentTemplatePanelError,
} from 'store/actions/documentTemplatesPanelActions';

export const useSaveDocumentModal = (
  recordId: string,
  documentTemplateId: string
) => {
  const dispatch = useDispatch();
  const [documentFields, setDocumentFields] = useState<
    GetResponse<DocumentTemplateFields>
  >();
  const [loading, setLoading] = useState(false);

  const clearDocumentFields = useCallback(() => {
    setDocumentFields(undefined);
  }, []);

  const getDocumentTemplateFields = useCallback(
    async (offset = 0) => {
      setLoading(true);
      try {
        const { data } = await apiCall.get<GetResponse<DocumentTemplateFields>>(
          generatePath(DOCUMENT_TEMPLATE_PANEL_FIELDS, { recordId }),
          { params: { offset } }
        );

        setDocumentFields(prev =>
          prev?.results && offset > 0
            ? { ...data, results: [...prev.results, ...data.results] }
            : data
        );
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status)
          dispatch(
            setDocumentTemplatePanelError({ status: err.response?.status })
          );
      } finally {
        setLoading(false);
      }
    },
    [dispatch, recordId]
  );

  const fetchNextPage = useCallback(() => {
    if (documentFields) getDocumentTemplateFields(documentFields.offset + 50);
  }, [documentFields, getDocumentTemplateFields]);

  const saveDocumentTemplateToField = useCallback(
    (fieldAlias: string) => {
      dispatch(
        generateAndDownloadDocumentTemplatePanel(
          recordId,
          documentTemplateId,
          fieldAlias
        )
      );
    },
    [dispatch, documentTemplateId, recordId]
  );

  useEffect(() => {
    if (documentTemplateId) getDocumentTemplateFields();
  }, [documentTemplateId, getDocumentTemplateFields]);

  return {
    fetchNextPage,
    saveDocumentTemplateToField,
    clearDocumentFields,
    documentFields,
    loading,
  };
};
