import { ObjectClassField } from 'utils/types/api/objectClassesFields.types';

export const parseFields = (fields: ObjectClassField[]) =>
  fields.map(({ alias, is_identifier, is_required, is_unique, order }) => ({
    alias,
    is_identifier,
    is_required,
    is_unique,
    order,
  }));
