import React from 'react';
import { DatePicker as AntDatepicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import moment, { Moment } from 'moment';
import usePickerStyles from './styles';
import { ArrowRightWithTailIcon } from 'components/Icon';
import {
  DatePicker as FDatePicker,
  DatePickerProps as FDatePickerProps,
  RangePickerProps as FRangePickerProps,
} from 'formik-antd';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getSidebarWidth } from 'store/selectors/flexLayoutSelectors';
import { CustomRangeDatePicker, DatePickerProps } from './types';
import usePopupContainer from 'hooks/usePopupContainer';
import useGlobalStyle from 'hooks/useGlobalStyle';
import { ReactComponent as DatepickerIcon } from 'img/icons/datepicker-icon-control.svg';
import { useDatePicker } from './hooks';

const { RangePicker: AntRangePicker } = AntDatepicker;
const { RangePicker: FRangePicker } = FDatePicker;

const disabledDate = (current: Moment) =>
  current && current < moment('1800-01-01').startOf('day');

export const DatePicker: React.FC<DatePickerProps> = ({
  className,
  disabled,
  getPopupContainer,
  showTime,
  onOk,
  dateGreaterThanToday = false,
  dropdownClassName,
  ...rest
}) => {
  const classes = usePickerStyles({});
  const globalClasses = useGlobalStyle();
  const defaultPopupContainer = usePopupContainer();
  const { disabledTime, handleOnOk } = useDatePicker(
    showTime,
    dateGreaterThanToday,
    onOk
  );

  return (
    <AntDatepicker
      {...{ ...rest, disabled, showTime, disabledTime }}
      disabledDate={rest.disabledDate || disabledDate}
      getPopupContainer={getPopupContainer || defaultPopupContainer}
      suffixIcon={<DatepickerIcon />}
      className={clsx([
        classes.datepicker,
        className,
        {
          [globalClasses.disabledInput]: disabled,
        },
      ])}
      dropdownClassName={clsx([classes.pickerDropdown, dropdownClassName])}
      onOk={handleOnOk}
    />
  );
};

export const FormikDatePicker: React.FC<FDatePickerProps> = ({
  className,
  disabled,
  ...rest
}) => {
  const classes = usePickerStyles({});
  const globalClasses = useGlobalStyle();
  const getPopupContainer = usePopupContainer();

  return (
    <FDatePicker
      {...{ ...rest, getPopupContainer, disabled }}
      disabledDate={rest.disabledDate || disabledDate}
      suffixIcon={<DatepickerIcon />}
      className={clsx([
        classes.datepicker,
        className,
        {
          [globalClasses.disabledInput]: disabled,
        },
      ])}
      dropdownClassName={classes.pickerDropdown}
    />
  );
};

export const DateRangePicker: React.FC<
  RangePickerProps<Moment> & CustomRangeDatePicker
> = ({
  className,
  disabled,
  withCustomPopupStyles = false,
  isExternal,
  ...rest
}) => {
  const filtersSidebarWidth = useSelector(getSidebarWidth);

  const sidebarWidth = isExternal ? 0 : filtersSidebarWidth;
  const classes = usePickerStyles({ filtersSidebarWidth: sidebarWidth });
  const globalClasses = useGlobalStyle();
  const getPopupContainer = usePopupContainer();

  return (
    <AntRangePicker
      {...{ ...rest, getPopupContainer, disabled }}
      disabledDate={rest.disabledDate || disabledDate}
      className={clsx([
        classes.datepicker,
        className,
        {
          [globalClasses.disabledInput]: disabled,
        },
      ])}
      separator={<ArrowRightWithTailIcon />}
      dropdownClassName={clsx([
        classes.pickerDropdown,
        {
          [classes.popupWrapper]: withCustomPopupStyles,
        },
      ])}
    />
  );
};

export const FormikDateRangePicker: React.FC<FRangePickerProps> = ({
  className,
  disabled,
  ...rest
}) => {
  const classes = usePickerStyles({});
  const globalClasses = useGlobalStyle();
  const getPopupContainer = usePopupContainer();

  return (
    <FRangePicker
      {...{ ...rest, getPopupContainer, disabled }}
      disabledDate={rest.disabledDate || disabledDate}
      className={clsx([
        classes.datepicker,
        className,
        {
          [globalClasses.disabledInput]: disabled,
        },
      ])}
      dropdownClassName={classes.pickerDropdown}
      separator={<ArrowRightWithTailIcon />}
    />
  );
};
