import React, { useLayoutEffect } from 'react';
import { sheets } from 'components/App';
import debounce from 'lodash/debounce';
import { useWindowContext } from 'components/lib/FlexLayout/WindowContext';
import { setTimeout } from 'timers';

const ExternalWindowStylesheetSubscriber: React.FC = ({ children }) => {
  const { windowRef } = useWindowContext();

  useLayoutEffect(() => {
    const stylesElem = windowRef?.document.createElement('style');

    const removeSubscribe = sheets.subscribe(
      debounce(() => {
        if (stylesElem && windowRef) {
          stylesElem.innerHTML = sheets.toString();
        }
      }, 100)
    );

    setTimeout(() => {
      if (stylesElem && windowRef) {
        windowRef.document.head.appendChild(stylesElem);
      }
    }, 100);

    return () => {
      removeSubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default ExternalWindowStylesheetSubscriber;
