import { SimpleObjectValue } from 'utils/types';
import { OptionSelect } from 'utils/types/selectInput.types';

type Result<T> = MappedObject<MappedObject<OptionSelect<T>>>;

const mapSchemaByType = <T>(schema_by_type: any) =>
  Object.entries<Result<T>>(
    mapTaskOptionsToDictionary(schema_by_type ?? [])
  ).reduce<Result<T> | { [key: string]: {} }>((result, [key, value]) => {
    const [fieldValues] = Object.values(value);
    const [fieldKey] = Object.keys(value);

    if (!fieldValues) return result;

    const existedField = result[fieldKey];

    if (!!existedField) {
      return {
        ...result,
        [key]: {
          ...existedField,
          [fieldKey]: fieldValues,
        },
      };
    }

    return {
      ...result,
      [key]: {
        [fieldKey]: fieldValues,
      },
    };
  }, {});

export const mapTaskOptionsToDictionary = <T = any>(optionsTable: any[]) =>
  optionsTable.reduce<T>(
    (prev, { alias, task_type, schema, schema_by_type, ...rest }): any => {
      if (alias === 'configuration' || alias === 'plugin_config') {
        const mappedSchema = Object.entries<OptionSelect<T>>(
          mapTaskOptionsToDictionary(Array.isArray(schema) ? schema : [schema])
        ).reduce<MappedObject<OptionSelect<T>> | {}>((result, [key, value]) => {
          const values = Object.values(value);
          const [fieldValues] = values;

          return {
            ...result,
            [key]: values.length > 1 ? value : fieldValues,
          };
        }, {});

        const mappedSchemaByType = mapSchemaByType(schema_by_type);

        return {
          ...prev,
          ...mappedSchema,
          ...mappedSchemaByType,
        };
      }

      return {
        ...prev,
        [alias || task_type]: schema
          ? { ...rest, ...mapTaskOptionsToDictionary(schema) }
          : rest,
      };
    },
    {} as T
  );

export const listNames = (list: SimpleObjectValue[]) =>
  list.map(item => item.name);
