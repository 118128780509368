import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import useData from 'hooks/useData';
import { useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import {
  OBJECT_CLASS_RECORD_PERMISSION_SET_ASSIGNEES,
  OBJECT_RECORD_OWNERS,
} from 'utils/endpoints';
import { ObjectRecordOptions } from 'utils/types/api/objectRecords.types';
import { PermissionSetSelectOptions } from '../components/RecordOwners/types';

const usePermissionSetOptions = (selectedPermissionSet?: number) => {
  const {
    selectedResource: { record: { recordId = undefined } = {} } = {},
  } = useSelectedResourceContext();

  const [options, { loading, error, fetchData }] = useData<ObjectRecordOptions>(
    '',
    {
      requestType: 'options',
      fetchOnLoad: false,
    }
  );

  useEffect(() => {
    if (recordId === undefined) return;

    const endpoint =
      !selectedPermissionSet ||
      selectedPermissionSet === PermissionSetSelectOptions.Owners ||
      selectedPermissionSet === PermissionSetSelectOptions.All
        ? generatePath(OBJECT_RECORD_OWNERS, {
            id: recordId,
          })
        : generatePath(OBJECT_CLASS_RECORD_PERMISSION_SET_ASSIGNEES, {
            recordId,
            permissionSetId: selectedPermissionSet,
          });

    fetchData(endpoint);
  }, [recordId, selectedPermissionSet, fetchData]);

  return {
    loading: loading || (!options && !error), // to avoid unmounting loading should be true at the beginning
    options:
      selectedPermissionSet === PermissionSetSelectOptions.All
        ? ({
            ...options,
            restrictions: {
              limit_items: undefined,
              limit_items_in_batch: undefined,
            },
          } as ObjectRecordOptions)
        : options,
    error,
  };
};

export default usePermissionSetOptions;
