import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Errors,
  ExtendedJsonSchema,
  FieldError,
  FormDataValue,
  UseAPIValidationParams,
} from '../types';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import snakeCase from 'lodash/snakeCase';
import debounce from 'lodash/debounce';

export const PREFIX = 'field_';

const useAPIValidation = ({
  formData,
  apiErrors,
  schema,
}: UseAPIValidationParams) => {
  const parsedApiErrors = useValidationFromApi(schema, apiErrors);
  const savedDataWithErrors = useRef<MappedObject<FormDataValue> | undefined>(
    undefined
  );
  const [activeApiErrors, setActiveApiErrors] = useState<Errors | {}>(
    parsedApiErrors || {}
  );

  const saveValuesWithErrorsFromAPI = () => {
    if (!apiErrors) return;

    Object.keys(apiErrors).forEach(key => {
      const values = Object.values(formData).reduce(
        (result, sectionFields) => ({ ...result, ...sectionFields }),
        {}
      );
      const modifiedKey = key.replace(PREFIX, '');

      savedDataWithErrors.current = {
        ...(savedDataWithErrors.current || {}),
        [modifiedKey]: values[modifiedKey],
      };
    });
  };

  const validateErrorsAfterChange = useCallback(
    debounce(() => {
      if (!savedDataWithErrors.current) return;
      const currentFormValues = Object.values(formData).reduce(
        (result, sectionFields) => ({ ...result, ...sectionFields }),
        {}
      );
      let reducedErrors = { ...activeApiErrors };

      Object.entries(savedDataWithErrors.current || {}).forEach(
        ([key, value]) => {
          if (
            currentFormValues[key] !== value &&
            !!Object.values(activeApiErrors).find(
              fieldsWithError => fieldsWithError[key]
            )
          ) {
            // remove field from errors list
            const reducedErrorsList = Object.entries<FieldError>(
              activeApiErrors
            ).reduce((result, [sectionKey, sectionFields]) => {
              const updatedSectionFields = Object.fromEntries(
                Object.entries(sectionFields).filter(
                  ([fieldKey]) => fieldKey !== key
                )
              );

              if (JSON.stringify(updatedSectionFields) === '{}') return result;

              return {
                ...result,
                [sectionKey]: updatedSectionFields,
              };
            }, {});

            reducedErrors = reducedErrorsList;
          }
        }
      );

      if (JSON.stringify(reducedErrors) !== JSON.stringify(activeApiErrors)) {
        setActiveApiErrors(reducedErrors);
      }
    }, 200),
    [activeApiErrors, formData]
  );

  useEffect(() => {
    setActiveApiErrors(parsedApiErrors);
    saveValuesWithErrorsFromAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(parsedApiErrors)]);

  return {
    validateErrorsAfterChange,
    setActiveApiErrors,
    activeApiErrors,
  };
};

const useValidationFromApi = (
  { properties = {} }: ExtendedJsonSchema,
  errors: MappedObject<string[]> | undefined = {}
) => {
  return Object.entries(properties).reduce(
    (result, [sectionKey, { properties: fieldProperties = {} }]) => {
      const fieldsKeys = Object.keys(fieldProperties);
      const fieldsWithErrors = fieldsKeys.reduce((fieldResults, fieldKey) => {
        const fieldErrors = errors[`${PREFIX}${fieldKey}`];

        if (!!fieldErrors) {
          const fieldErrorsWithTranslation = fieldErrors.map(error =>
            GlobalIntlSingleton.dynamicFormatMessage({
              id: `errors.records.${snakeCase(error)}`,
              defaultMessage: error,
            })
          );

          return {
            ...fieldResults,
            [fieldKey]: {
              __errors: fieldErrorsWithTranslation,
            },
          };
        }

        return fieldResults;
      }, {});

      return Object.keys(fieldsWithErrors).length
        ? { ...result, [sectionKey]: fieldsWithErrors }
        : result;
    },
    {}
  );
};

export default useAPIValidation;
