import { Action } from 'redux';
import { OptionsResponseDetails } from '..';
import { TPredicateTypes } from '../predicates.types';
import { SelectOption } from '../selectInput.types';
import { ResponseError } from '../errorResponse';

export interface ColumnsMetadata {
  alias: string;
  type: TPredicateTypes;
  predicates?: string[];
  sort_ok?: boolean;
  values?: SelectOption[];
  autocomplete?: string;
  search_key?: string;
  groupKey?: string;
  width?: number;
  label?: string;
  order?: number;
  schema?: {
    alias: string;
    schema: {
      alias: string;
      type: TPredicateTypes;
      sort_ok?: boolean;
      values?: SelectOption[];
      predicates?: string[];
    }[];
  };
}

export type ClassID = string;

export interface ActionObject<T> {
  list: T[];
  total: number;
  filtered: number;
  byId: { [id: string]: T }[];
  allIds: string[];
  fetching: boolean;
  payload: ColumnsMetadata[];
  classID: ClassID;
  columns: MappedObject<ColumnsMetadata>;
  selectedRow?: string;
  restrictions: Restrictions;
}

export type SetTableAction<T, U> = Action<U> & T;

export type CommonTableState<T, K = {}> = K & {
  fetching: boolean;
  byId: { [id: string]: T };
  allIds: string[];
  total: number;
  filtered: number;
  columns: MappedObject<ColumnsMetadata>;
  selectedRow?: string;
  restrictions: Restrictions;
  name?: string;
  details?: OptionsResponseDetails;
  customOffset: number;
  error?: ResponseError;
};

export interface Restrictions {
  limit_items?: number;
  limit_identifiers?: number;
  limit_items_in_batch?: number;
  limit_items_in_object_class?: number;
}

export enum ColumnAliasNames {
  CurrentAssignees = 'current_assignees',
}

export interface ColumnsMetadataAssignees {
  alias: string;
  type?: TPredicateTypes;
  predicates?: string[];
  sort_ok?: boolean;
  values?: SelectOption[];
  autocomplete?: string;
  search_key?: string;
  groupKey?: string;
  label?: string;
  order?: number;
  schema?: {
    alias: string;
    type: TPredicateTypes;
    sort_ok?: boolean;
    values?: SelectOption[];
    predicates?: string[];
    autocomplete: string;
  }[];
}
