import { findFlagUrlByIso3Code } from 'country-flags-svg';
import EmptyFlagIcon from 'img/icons/empty-flag.svg';

// fixes outdated urls
const updatedFindCountryFlagByIso3 = (code: string) => {
  if (code === 'AFG') return EmptyFlagIcon;

  if (code !== 'HND') return findFlagUrlByIso3Code(code);

  return 'https://upload.wikimedia.org/wikipedia/commons/8/82/Flag_of_Honduras.svg';
};

export default updatedFindCountryFlagByIso3;
