import React from 'react';
import InPlaceEditTime from 'components/InPlaceEditControls/InPlaceEditTime';
import { mapCustomWidgetPropsToBaseInPlaceEditProps } from './utils';
import { Widget } from 'alx-dynamic-form';
import { Provider } from 'react-redux';
import { store } from 'store/store';

const AdaptedInPlaceEditTimePicker: Widget = widgetProps => {
  const baseInPlaceEditProps = mapCustomWidgetPropsToBaseInPlaceEditProps<
    string
  >(widgetProps);

  return (
    <Provider store={store}>
      <InPlaceEditTime {...baseInPlaceEditProps} withUnderline />
    </Provider>
  );
};

export default AdaptedInPlaceEditTimePicker;
