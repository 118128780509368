import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useParams } from 'react-router-dom';
import {
  getObjectClassPermissionsClassOwnersAutocompleteUrl,
  getObjectClassPermissionsClassOwnersAvatars,
  getObjectClassPermissionsClassOwnersCount,
  getObjectClassPermissionsClassOwnersItemsLimit,
} from 'store/selectors/objectClassPermissionsSelectors';
import { OBJECT_CLASS_OWNERS } from 'utils/endpoints';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import AddUsersPanel from '../AddUsersPanel';
import { ObjectClassOwner } from 'utils/types/api/objectClasses.types';
import { getObjectClassPermissionsClassOwners } from 'store/actions/objectClassPermissionsActions';
import { AppDispatch } from 'store/store';
import { CustomErrorMessage, OwnersMode } from '../AddUsersPanel/types';
import { useIntl } from 'react-intl';
import { ErrorCodes } from 'utils/types/errorResponse';

const AddObjectClassOwner = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { id: objectClassId } = useParams<{ id: string }>();
  const intl = useIntl();
  const avatars = useSelector(getObjectClassPermissionsClassOwnersAvatars);
  const usersCount = useSelector(getObjectClassPermissionsClassOwnersCount);
  const itemsLimit = useSelector(
    getObjectClassPermissionsClassOwnersItemsLimit
  );
  const autocompleteUrl = useSelector(
    getObjectClassPermissionsClassOwnersAutocompleteUrl
  );

  const postApiEndpoint = objectClassId
    ? generatePath(OBJECT_CLASS_OWNERS, {
        id: objectClassId,
      })
    : '';
  const customErrorMessage = useCallback(
    ({ errorCode, count, itemsLimit }: CustomErrorMessage) =>
      errorCode === ErrorCodes.ERR_LIMIT_EXCEEDED && itemsLimit
        ? {
            title: intl.formatMessage(
              {
                id: 'owners.countNotAddedOwners',
                defaultMessage: `{count, plural, one {# owner} other {# owners}} not added`,
              },
              { count }
            ),
            subtitle: intl.formatMessage(
              {
                id: 'owners.ownersLimitReached',
                defaultMessage:
                  'Maximum of {itemsLimit} {itemsLimit, plural, one {owner} other {owners}} has been reached.',
              },
              { itemsLimit }
            ),
          }
        : undefined,
    [intl]
  );

  return objectClassId !== undefined ? (
    <AddUsersPanel<ObjectClassOwner>
      {...{
        avatars,
        usersCount,
        itemsLimit,
        autocompleteUrl,
        postApiEndpoint,
        customErrorMessage,
      }}
      panelKey={FlexLayoutWindows.ClassPermissionsOwners}
      fetchOwners={() =>
        dispatch(getObjectClassPermissionsClassOwners(objectClassId))
      }
      shouldFetchOnSuccess
      defaultMode={OwnersMode.Manage}
    />
  ) : (
    <></>
  );
};

export default AddObjectClassOwner;
