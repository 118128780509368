import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import WarningIllustration from 'img/illustrations/warning-triangle.svg';
import ConfirmationModal from 'components/ConfirmationModal';
import { CloseIcon } from 'components/Icon';
import { RemoveOwnerModalProps } from './types';
import useManageOwnersPanel from './hooks';
import {
  getObjectClassOwnersFetching,
  getObjectClassPermissionsIsRemovingHimself,
} from 'store/selectors/objectClassPermissionsSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { setObjectClassPermissionsClassOwnersIsRemovingHimself } from 'store/actions/objectClassPermissionsActions';

const RemoveOwnerModal = ({
  objectClassId,
  ownerToDelete,
  setOwnerToDelete,
}: RemoveOwnerModalProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isLoading = useSelector(getObjectClassOwnersFetching);
  const { removeObjectClassPermissionsClassOwner } = useManageOwnersPanel();
  const isRemovingHimself = useSelector(
    getObjectClassPermissionsIsRemovingHimself
  );

  const title = useMemo(
    () =>
      isRemovingHimself
        ? intl.formatMessage({
            id: 'misc.sureToRemoveYourself',
            defaultMessage: 'Are you sure you want to remove yourself?',
          })
        : intl.formatMessage(
            {
              id: 'misc.sureToRemoveAnotherUser',
              defaultMessage: 'Are you sure you want to remove {name}?',
            },
            {
              name: ownerToDelete?.displayName,
            }
          ),
    [intl, isRemovingHimself, ownerToDelete]
  );

  const subtitle = useMemo(
    () =>
      isRemovingHimself
        ? intl.formatMessage({
            id: 'misc.youMayLoseAccessToObjectClass',
            defaultMessage: 'You may lose access to this object class.',
          })
        : intl.formatMessage({
            id: 'misc.theyMayLoseAccessToObjectClass',
            defaultMessage: 'They may lose access to this object class.',
          }),
    [intl, isRemovingHimself]
  );

  const onClose = () => {
    setOwnerToDelete(undefined);
    dispatch(setObjectClassPermissionsClassOwnersIsRemovingHimself(false));
  };

  const onConfirm = async () => {
    if (ownerToDelete) {
      await removeObjectClassPermissionsClassOwner(
        objectClassId,
        ownerToDelete?.ownershipId
      );
    }

    setOwnerToDelete(undefined);
  };

  return (
    <ConfirmationModal
      {...{ isLoading, onClose, onConfirm, title, subtitle }}
      visible={!!ownerToDelete}
      confirmationButtonLabel={intl.formatMessage({
        id: 'misc.yesRemove',
        defaultMessage: 'Yes, remove',
      })}
      withCancel
      image={WarningIllustration}
      cancelButtonIcon={<CloseIcon size={18} />}
    />
  );
};

export default RemoveOwnerModal;
