import React from 'react';
import useFormPreviewStyles from 'components/FormPreview2/styles';
import DangerouslyRenderCkeditorContent from 'components/DangerouslyRenderCkeditorContent';
import { Widget } from 'alx-dynamic-form';
import { Provider } from 'react-redux';
import { store } from 'store/store';

const CustomInstructionWidget: Widget = ({ description, fieldAlias }) => {
  const styles = useFormPreviewStyles({});

  return (
    <Provider store={store}>
      <div
        className={styles.InstructionWrapper}
        data-testid={`instruction-${fieldAlias}`}
      >
        <DangerouslyRenderCkeditorContent value={description} />
      </div>
    </Provider>
  );
};

export default CustomInstructionWidget;
