import React, { useEffect, useMemo, useState } from 'react';
import useLayoutStyles from '../lib/Layout/styles';
import PermissionMenuItem from 'components/PageLayout/PermissionMenuItem';
import { Menu } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import routes, { nonProtectedRoutes } from 'utils/routingPaths';
import { FormattedMessage } from 'react-intl';
import UserIdentification from 'components/UserIdentification';
import {
  AvatarIcon,
  LogoutIcon,
  ArrowDownIcon,
  CheckMarkIcon,
} from 'components/Icon';
import { Space } from 'components/lib/Space';
import { Dropdown } from 'components/lib/Dropdown';
import { TopMenuProps } from './types';
import clsx from 'clsx';
import {
  VIEW_OPTION_BUTTON_TESTID,
  LOGOUT_BUTTON_TESTID,
  MY_ACCOUNT_TESTID,
  VIEW_TESTID,
} from 'utils/testIds';
import { BREADCRUMBS_WRAPPER_ID, TOP_MENU_ID } from 'utils/elementsIds';
import { getElementSize } from 'utils/functions/getElementSize';
import useAppliedFilters from 'hooks/useAppliedFilters';
import { useIsViewVisible, useViewOptions } from './hooks';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import useFlexLayoutWindow from 'hooks/useFlexLayoutWindow';
import GTag from 'providers/IntlProviderWrapper/gTag';
import { GTagEventButtonName } from 'providers/IntlProviderWrapper/gTag/types';
import { useDispatch } from 'react-redux';
import { setIsAboutModalVisible } from 'store/actions/miscActions';

const TopMenu = ({ theme }: TopMenuProps) => {
  const { pathname } = useLocation();
  const [breadcrumbsWidth, setBreadcrumbsWidth] = useState(0);
  const appliedFilters = useAppliedFilters();
  const classes = useLayoutStyles({ breadcrumbsWidth });
  const viewOptions = useViewOptions();
  const isViewVisible = useIsViewVisible();
  const history = useHistory();
  const dispatch = useDispatch();
  const { onOpenTab } = useFlexLayoutWindow(FlexLayoutWindows.HelpPanel);

  const dropdownItems = useMemo(
    () => (
      <Menu
        {...{ theme }}
        className={classes.headerMenu}
        selectedKeys={[pathname]}
      >
        <PermissionMenuItem
          key={routes.MY_ACCOUNT}
          visible
          onClick={() => history.push(routes.MY_ACCOUNT)}
          data-testid={MY_ACCOUNT_TESTID}
        >
          <Space align='center' size={5}>
            <AvatarIcon />
            <FormattedMessage id='misc.myAccount' defaultMessage='My account' />
          </Space>
        </PermissionMenuItem>
        <Menu.Item
          key='logout'
          data-testid={LOGOUT_BUTTON_TESTID}
          onClick={() => history.push(nonProtectedRoutes.LOGOUT)}
        >
          <Space align='center' size={5}>
            <LogoutIcon />
            <FormattedMessage id='misc.logout' defaultMessage='Log out' />
          </Space>
        </Menu.Item>
      </Menu>
    ),
    [classes.headerMenu, history, pathname, theme]
  );

  useEffect(() => {
    setBreadcrumbsWidth(getElementSize(BREADCRUMBS_WRAPPER_ID) ?? 0);
  }, [appliedFilters]);

  const onHelpPanelClick = () => {
    GTag.pushGtagButtonEvent(GTagEventButtonName.Help);
    onOpenTab();
  };

  const onViewClick = (key: string, onClick: Function) => {
    GTag.pushGtagButtonEvent(key);
    onClick();
  };

  const onAboutClick = () => {
    dispatch(setIsAboutModalVisible(true));
  };

  return (
    <Menu
      theme={theme}
      mode='horizontal'
      className={clsx([classes.headerMenu, classes.topMenu])}
      selectedKeys={[pathname]}
      triggerSubMenuAction='click'
      id={TOP_MENU_ID}
    >
      <PermissionMenuItem visible={isViewVisible} data-testid={VIEW_TESTID}>
        <Dropdown
          placement='bottomCenter'
          overlay={
            <Menu {...{ theme }} className={classes.headerMenu}>
              {viewOptions.map(
                section =>
                  // update styles of menu
                  // <div key={`section-${index}`} className={classes.section}>
                  section.map(({ key, label, disabled, opened, onClick }) => (
                    <PermissionMenuItem
                      visible
                      key={key}
                      onClick={() => onViewClick(key, onClick)}
                      data-testid={`${VIEW_OPTION_BUTTON_TESTID}-${key}`}
                    >
                      <span className={classes.iconWrapper}>
                        {opened && <CheckMarkIcon size={12} />}
                      </span>
                      <span
                        {...{ disabled }}
                        className={clsx({
                          [classes.disabledLabel]: disabled,
                        })}
                      >
                        {label}
                      </span>
                    </PermissionMenuItem>
                  ))
                // </div>
              )}
            </Menu>
          }
          trigger={['click']}
          className={classes.dropdownMenuWrapper}
          overlayClassName={clsx([
            classes.dropdownMenu,
            { [classes.dropdownMenuEmpty]: viewOptions.length === 0 },
          ])}
        >
          <Space size={0} className={classes.submenuContent}>
            <FormattedMessage id='misc.view' defaultMessage='View' />
            <ArrowDownIcon size={7} className={classes.topMenuArrow} />
          </Space>
        </Dropdown>
      </PermissionMenuItem>
      <PermissionMenuItem visible key='help'>
        <button onClick={onHelpPanelClick} className={classes.helpButton}>
          <FormattedMessage id='misc.help' defaultMessage='Help' />
        </button>
      </PermissionMenuItem>
      <PermissionMenuItem visible key='about'>
        <button onClick={onAboutClick} className={classes.aboutButton}>
          <FormattedMessage id='misc.about' defaultMessage='About' />
        </button>
      </PermissionMenuItem>
      <PermissionMenuItem visible key='account'>
        <Dropdown
          overlay={dropdownItems}
          trigger={['click']}
          className={classes.dropdownMenuWrapper}
          overlayClassName={classes.dropdownMenu}
          placement='bottomCenter'
        >
          <Space
            size={0}
            className={classes.submenuContent}
            data-testid='top-menu-dropdown-account'
          >
            <UserIdentification />
            <ArrowDownIcon size={7} className={classes.topMenuArrow} />
          </Space>
        </Dropdown>
      </PermissionMenuItem>
    </Menu>
  );
};

export default TopMenu;
