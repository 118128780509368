import { Reducer } from 'redux';
import {
  APPEND_EXTERNAL_COMPONENTS,
  SET_EXTERNAL_COMPONENTS,
} from 'store/constants/externalComponents.consts';
import { RootAction } from '.';
import { ExternalComponentApiListElement } from './types/externalComponents.types';
import { GetResponse } from 'utils/types';

const initialState = {
  componentsData: {},
} as ExternalComponentsState;

export type ExternalComponentsState = {
  componentsData: GetResponse<ExternalComponentApiListElement>;
};

export const externalComponentsReducer: Reducer<
  ExternalComponentsState,
  RootAction
> = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXTERNAL_COMPONENTS: {
      return {
        ...state,
        componentsData: action.payload,
      };
    }
    case APPEND_EXTERNAL_COMPONENTS: {
      return {
        ...state,
        componentsData: {
          ...state.componentsData,
          ...action.payload,
          results: [
            ...(state.componentsData.results ?? []),
            ...(action.payload.results ?? []),
          ],
        },
      };
    }
    default: {
      return state;
    }
  }
};
