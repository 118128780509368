import React, { useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  BROKEN_SRC_REGEXP,
  getZendeskApiUrl,
  getZendeskArticlesUrl,
  XSS_REGEXP,
} from './consts';
import LoadingWrapper from 'components/wrappers/LoadingWrapper';
import useHelpPanelStyles from './styles';
import { PanelData, JSONResponse } from './types';
import {
  HELP_PANEL,
  HELP_PANEL_CONTENT,
  HELP_PANEL_DEFAULT_VIEW,
  HELP_PANEL_TITLE,
} from 'utils/testIds';
import { useSelector } from 'react-redux';
import { getMappedAppRoutesToHelpPanelSelector } from 'store/selectors/globalPreferencesSelectors';
import { parseZendeskArticleUrl } from './utils';

interface IHelpMenu {
  loadArticle: (path: string) => Promise<void>;
  mappedAppRoutes: Record<string, string>;
}
const HelpMenu = ({ loadArticle, mappedAppRoutes }: IHelpMenu) => {
  const handleLinkFabric = (key: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    void loadArticle(key);
  };

  const filteredMappedRoutes = Object.fromEntries(
    Object.entries(mappedAppRoutes).filter(([, articleUrl]) => articleUrl)
  );

  return (
    <ul>
      {Object.keys(filteredMappedRoutes).map(key => {
        const { articleTitle, lastSegement } = parseZendeskArticleUrl(
          filteredMappedRoutes[key]
        );

        const articlesURL = getZendeskArticlesUrl();

        return (
          <li key={key}>
            <a
              href={`${articlesURL}${lastSegement}`}
              onMouseDown={handleLinkFabric(key)}
            >
              {articleTitle}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

const HelpPanel = () => {
  const { pathname } = useLocation();
  const mappedAppRoutes = useSelector(getMappedAppRoutesToHelpPanelSelector);

  const classes = useHelpPanelStyles({});
  const [{ html, title }, setData] = useState<PanelData>({
    html: '',
    title: '',
  });
  const [showHelpMenu, setShowHelpMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loadArticle = useCallback(
    async path => {
      setShowHelpMenu(false);

      const pathKey = path.replaceAll(/\d+/g, ':id').replace(/\/$/, '');

      if (!!mappedAppRoutes[pathKey]) {
        const { lastSegement } = parseZendeskArticleUrl(
          mappedAppRoutes[pathKey]
        );

        const apiUrl = getZendeskApiUrl();

        setIsLoading(true);

        try {
          const response = await fetch(`${apiUrl}${lastSegement}`);
          const {
            article: { body = '', title = '' },
          }: JSONResponse = await response.json();

          const bodyWithFixedSrc = body.replaceAll(
            BROKEN_SRC_REGEXP,
            'src="https://'
          );
          const XSSProtectedBody = bodyWithFixedSrc.replaceAll(XSS_REGEXP, '');

          setData({ html: XSSProtectedBody, title });
        } catch (err) {
          setData({
            html: `<p>Error: ${err.message}</p>`,
            title: 'Unexpected error',
          });
        } finally {
          setIsLoading(false);
        }
      } else {
        setShowHelpMenu(true);
      }
    },
    [mappedAppRoutes]
  );

  useEffect(() => {
    loadArticle(pathname);
  }, [loadArticle, pathname]);

  if (showHelpMenu)
    return (
      <div data-testid={HELP_PANEL_DEFAULT_VIEW} className={classes.wrapper}>
        <h3>How can we help?</h3>
        <HelpMenu mappedAppRoutes={mappedAppRoutes} loadArticle={loadArticle} />
      </div>
    );

  return (
    <LoadingWrapper loading={isLoading}>
      <div className={classes.wrapper} data-testid={HELP_PANEL}>
        <h3 className={classes.title} data-testid={HELP_PANEL_TITLE}>
          {title}
        </h3>
        <div
          data-testid={HELP_PANEL_CONTENT}
          className={classes.helpPanel}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </LoadingWrapper>
  );
};

export default HelpPanel;
