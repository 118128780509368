import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { getInPlaceEditFontSize } from '../components/commonStyles';
import { InPlaceEditInputSize } from '../types';

const getMaxHeightBySize = (size: InPlaceEditInputSize | undefined) => {
  switch (size) {
    case 'small':
      return 19;
    case 'medium':
      return 22;
    default:
      return 19;
  }
};

type UseInPlaceEditTextareaStylesProps = {
  size?: InPlaceEditInputSize;
};

const useInPlaceEditTextareaStyles = createUseStyles((theme: ThemeState) => {
  return {
    textareaWrapper: () => ({
      height: 'initial',
      alignItems: 'flex-start',
    }),
    textarea: ({ size }: UseInPlaceEditTextareaStylesProps) => ({
      fontSize: getInPlaceEditFontSize(size),
      lineHeight: 1.3,
      outline: 'none',
      '&:focus': {
        boxShadow: 'none',
        borderColor: 'none',
      },
    }),
    textareaPreviewWrapper: {
      background: 'transparent !important',
      overflow: 'hidden',
    },
    textareaPreviewWrapperTrim: {
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 5,
      '-webkit-box-orient': 'vertical',
    },
    dropdownWrapper: {
      display: 'flex',
    },
    dropdownContentWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    messageBox: {
      marginTop: 1,
    },
    singleLinePreview: ({ size }: UseInPlaceEditTextareaStylesProps) => ({
      minHeight: '0px !important',
      verticalAlign: 'top !important',
      maxHeight: `${getMaxHeightBySize(size)}px !important`,
    }),
    showMoreButton: {
      color: theme['primary-color'],
      fontSize: 12,
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    previewWrapper: {
      width: '100%',
    },
    showOverflowY: {
      overflowY: 'auto',
    },
  };
});

export default useInPlaceEditTextareaStyles;
