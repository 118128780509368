import { Reducer } from 'redux';
import {
  TOGGLE_SIDEBAR,
  SET_SIDEBAR_WIDTH,
  SET_SIDEBAR_DATA,
} from 'store/constants/flexLayout.const';
import { RootAction } from '.';
import { FlexLayoutState } from './types/flexLayout.types';

const initialState = {
  isSidebarOpened: false,
  sidebarWidth: 0,
  data: {},
};

const flexLayoutReducer: Reducer<FlexLayoutState, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR: {
      return {
        ...state,
        isSidebarOpened: action.payload,
      };
    }
    case SET_SIDEBAR_WIDTH: {
      return {
        ...state,
        sidebarWidth: action.payload,
      };
    }
    case SET_SIDEBAR_DATA: {
      if (action.sidebarData)
        return {
          ...state,
          data: {
            ...state.data,
            [action.sidebarData.id]: action.sidebarData.data,
          },
        };
      else
        return {
          ...state,
        };
    }
    default:
      return state;
  }
};

export default flexLayoutReducer;
