import { createUseStyles } from 'react-jss';
import defaultTheme from 'utils/defaultTheme';

const theme = defaultTheme
const maxWidth = 991;

const useExpiredPasswordStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    alignItems: 'center',
  },
  notFoundWrapper: {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
  },
  buttonWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  informationWrapper: {
    width: '100%',
  },
  footer: {
    position: 'absolute',
    bottom: '10px',
    textAlign: 'center',
    fontSize: 12,
  },
  footerText: {
    fontSize: 12,
    fontWeight: 400,
  },
  subtitle: {
    textAlign: 'center',
    display: 'block',
    fontSize: 12,
    fontWeight: 400,
    color: theme['heading-color'],
    opacity: .6,
  },
  card: {
    borderRadius: 5,
    boxShadow: '0px 4px 50px rgba(46, 63, 87, 0.19)',
    minWidth: 500,
    padding: '92px 30px 42px 30px',
  },
  logo: {
    position: 'absolute',
    top: '-10px',
    margin: 'auto',
    left: 0,
    right: 0,
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    margin: '10px 0',
    lineHeight: '34.5px',
    fontWeight: 700,
    color: theme['heading-color'],
  },
  input: {
    width: '50%',
    marginTop: 30,
  },
  [`@media (max-width: ${maxWidth}px)`]: {
    informationWrapper: {
      display: 'none',
    }
  }
});

export default useExpiredPasswordStyles;
