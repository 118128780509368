import { useSelector } from 'react-redux';
import {
  getAppliedFilters,
  getCurrentTable,
} from 'store/selectors/filtersSelectors';
import { useHistory } from 'react-router-dom';
import { tablePaths } from '../utils/tablePaths';
import { getCurrentTablePreferenceFilter } from 'store/selectors/preferencesSelectors';
import { useCallback, useEffect, useState } from 'react';

const useAppliedFilters = (): boolean => {
  const history = useHistory();
  const appliedFilters = useSelector(getAppliedFilters);
  const currentTable = useSelector(getCurrentTable);
  const currentTableFilters = useSelector(getCurrentTablePreferenceFilter);

  const [hasTableAppliedFilters, setHasTableAppliedFilters] = useState(false);

  const getIfTableHasAppliedFilters = useCallback(() => {
    if (currentTableFilters.length === 0) {
      return false;
    }

    const currentTableFiltersInfo = appliedFilters.find(({ id }) => {
      return id === currentTable;
    });

    return !!(
      currentTableFiltersInfo?.value &&
      currentTableFiltersInfo?.id &&
      tablePaths[currentTableFiltersInfo.id] === history.location.pathname
    );
  }, [
    appliedFilters,
    currentTable,
    currentTableFilters.length,
    history.location.pathname,
  ]);

  useEffect(() => {
    setHasTableAppliedFilters(getIfTableHasAppliedFilters);
  }, [getIfTableHasAppliedFilters]);

  return hasTableAppliedFilters;
};

export default useAppliedFilters;
