import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseMessageBoxStylesProps = {
  shouldBeOnTop?: boolean;
  isError?: boolean;
  fitContent?: boolean;
};

const useMessageBoxStyles = createUseStyles((theme: ThemeState) => {
  return {
    box: ({ isError }: UseMessageBoxStylesProps) => ({
      boxShadow: '0px 2px 4px rgba(46, 63, 87, 0.2)',
      background: '#FFFFFF',
      borderRadius: 2,
      padding: '10px 8px 9px 8px',
      color: isError ? theme['error-color'] : theme['text-color'],
      zIndex: 10,
      fontSize: 12,
    }),
    displayAfterParent: ({
      shouldBeOnTop,
      fitContent,
    }: UseMessageBoxStylesProps) => ({
      position: 'absolute',
      left: 0,
      right: fitContent ? 'initial' : 0,
      top: shouldBeOnTop ? 'initial' : 'calc(100% + 1px)',
      bottom: shouldBeOnTop ? 'calc(100% + 1px)' : 'initial',
    }),
  };
});

export default useMessageBoxStyles;
