import React from 'react';
import { ReactComponent as InputImage } from 'img/icons/textbox-icon.svg';
import { Input } from 'components/lib/Input';
import useCustomWidgetsStyles from '../../customWidgetsStyles';
import { FormLabelWithToolTip } from 'pages/TaskTemplates/components/FormLabel';
import { FormItem } from 'components/lib/Form';
import { Widget } from 'alx-dynamic-form';
import StandardFieldWrapper from 'components/FormPreview2/components/StandardFieldWrapper';
import clsx from 'clsx';

const CustomTextboxWidget: Widget = ({
  value,
  maxLength,
  onChange,
  description,
  errors,
  required,
  label,
  reValidateField,
  disabled,
  fieldAlias,
}) => {
  const classes = useCustomWidgetsStyles({});

  const valueLength = (value as string)?.length || 0;

  const customOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    return onChange(newValue);
  };

  const onBlur = () => {
    if (typeof value === 'string') {
      const trimmedValue = value.replace(/\s+/g, ' ').trim();
      reValidateField();
      return onChange(trimmedValue);
    }
  };

  return (
    <>
      <FormLabelWithToolTip
        dynamicSpacing
        {...{ required }}
        className={clsx([classes.inputLabel, classes.inputLabelEllipsis])}
        label={label}
      />
      <FormItem
        dynamicSpacing
        validateStatus={errors?.length ? 'error' : undefined}
      >
        <StandardFieldWrapper
          {...{ description, errors, valueLength, maxLength }}
        >
          <div className={classes.textWidget}>
            <div className={classes.textWidgetWrapper}>
              <Input
                data-testid={`single-line-input-${fieldAlias}`}
                onChange={customOnChange}
                value={value as string}
                onBlur={onBlur}
                className={classes.input}
                disableTextTrim
                maxLength={maxLength}
                withCounter={false}
                {...{ disabled }}
              />
              <div className={classes.inputIconWrapper}>
                <InputImage className={classes.icon} width={16} height={16} />
              </div>
            </div>
          </div>
        </StandardFieldWrapper>
      </FormItem>
    </>
  );
};

export default CustomTextboxWidget;
