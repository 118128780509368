import TablesType from './Enums/TablesType';

export default {
  [TablesType.TaskTemplates]: [
    'id',
    'name',
    'task_creation',
    'task_type',
    'description',
    'num_of_responses',
    'tags',
    'actions',
  ],
  [TablesType.ObjectRecords]: ['id', 'created_at', 'actions'],
  [TablesType.TaskGroups]: [
    'id',
    'name',
    'num_of_task_templates',
    'created_at',
  ],
  [TablesType.Sequences]: [
    'id',
    'name',
    'object_class',
    'task_group_templates',
    'state',
    'actions',
  ],
  [TablesType.ObjectClasses]: ['id', 'name', 'created_at'],
  [TablesType.ClassForms]: ['name', 'uuid', 'is_enabled'],
  [TablesType.ObjectClassesFields]: [
    'label',
    'type',
    'alias',
    'is_required',
    'is_unique',
    'actions',
  ],
  [TablesType.UserGroups]: ['id', 'name', 'description', 'actions'],
  [TablesType.SingleSignOn]: [
    'name',
    'provider',
    'is_enabled',
    'auto_create_user',
    'actions',
  ],
  [TablesType.Users]: [
    'id',
    'username',
    'account_type',
    'roles',
    'is_available',
    'actions',
  ],
  [TablesType.Roles]: [
    'id',
    'name',
    'description',
    'object_classes',
    'actions',
  ],
  [TablesType.Tasks]: ['id', 'name', 'status', 'actions'],
  [TablesType.Forms]: [
    'id',
    'name',
    'object_class',
    'created_by',
    'created_at',
  ],
  [TablesType.MessageTemplates]: [
    'id',
    'name',
    'type',
    'description',
    'created_by',
    'created_at',
    'actions',
  ],
  [TablesType.EmailCollectors]: ['id', 'name', 'object_classes', 'is_active'],
  [TablesType.AuthenticationObjects]: ['id', 'name', 'provider', 'description'],
  [TablesType.Members]: ['name', 'username', 'membership', 'added_at'],
  [TablesType.DocumentTemplates]: ['id', 'name', 'template_file_name'],
  [TablesType.DocumentTemplatesPanel]: ['id', 'name', 'generated_document'],
  [TablesType.NestedTable]: ['id', 'actions'],
  [TablesType.CustomComponents]: [
    'id',
    'name',
    'publisher',
    'is_enabled',
    'status',
  ],
};
