import { getDisabledInputStyle } from 'hooks/useGlobalStyle';
import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const getLneTextAreaStyles = (theme: ThemeState) => ({
  border: `1px solid ${theme['border-color-base']}`,
  height: 300,
  transition: 'all 0.3s, height 0s',
  borderRadius: 2,
  '&:hover': {
    borderColor: theme['primary-color-medium'],
  },
  '&:focus, &:focus-within': {
    borderColor: theme['primary-color-medium'],
    boxShadow: theme['input-focus-shadow'],
  },
  '&:active': {
    borderColor: theme['primary-color-dark'],
  },
  '& div': {
    height: '100%',
  },
  '& .ant-input-textarea': {
    display: 'flex',
    '& textarea': {
      border: 'none',
      flex: 1,
      paddingLeft: 2,
      cursor: 'auto',
    },
    '&:hover': {
      border: 'none',
    },
    '&:focus, &:focus-within': {
      border: 'none',
      boxShadow: 'none',
    },
    '&:active': {
      border: 'none',
    },
    '& .ant-input:hover, & textarea:hover': {
      border: 'none',
    },
    '& .ant-input:focus, .ant-input-focused': {
      border: 'none',
      boxShadow: 'none',
    },
  },
});

const useLineCountTextareaStyles = createUseStyles((theme: ThemeState) => {
  return {
    LineCountTextarea: getLneTextAreaStyles(theme),
    disabledTextarea: {
      backgroundColor: `${theme['disabled-input-gray']}`,
      height: '100%',
      '& #options-numbers-wrapper': getDisabledInputStyle(theme),
      '& .tln-wrapper': {
        paddingRight: 8,
        marginRight: 0,
      },
    },
  };
});

export default useLineCountTextareaStyles;
