import React, { memo, useCallback, useMemo, useRef } from 'react';
import { Select } from 'components/lib/Select';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentTable, getSourceId } from 'store/selectors/filtersSelectors';
import useColumnSelectStyles from './styles';
import { withoutEmptyPredicates } from 'utils/functions/withoutEmptyPredicates';
import {
  AutocompleteObjectClassFieldFilterValue,
  ColumnSelectProps,
  PredicateSet,
} from './types';
import { FILTER_COLUMN_SELECT_TESTID } from 'utils/testIds';
import TablesType from 'utils/Enums/TablesType';
import { CustomTableGroupKeys } from 'contexts/types';
import useScrollableParent from 'hooks/useScrollableParent';
import { getFiledsWithLabels, sortColumnFields } from './utils';
import { nameFields } from './consts';
import AutocompleteSelect from 'components/AutocompleteSelect';
import { generatePath } from 'react-router-dom';
import { OBJECT_CLASS_FIELDS_AUTOCOMPLETE } from 'utils/endpoints';
import {
  AutocompleteSelectValue,
  SelectOption as SelectOptionType,
} from 'utils/types/selectInput.types';
import SelectColumn from './SelectColumn';

const ColumnSelect: React.FC<ColumnSelectProps> = ({
  columns,
  usedColumns,
  onChange,
  defaultValue,
  additionalClassName,
  onPredicateSetChange,
  predicateSet,
  label,
}) => {
  const intl = useIntl();
  const currentTableName = useSelector(getCurrentTable);
  const classes = useColumnSelectStyles({});
  const elementRef = useRef<HTMLDivElement>(null);

  const sourceId = useSelector(getSourceId);

  const handleChangeAutocomplete = (
    value:
      | AutocompleteSelectValue
      | AutocompleteSelectValue[]
      | AutocompleteObjectClassFieldFilterValue
      | string
  ) => {
    if (Array.isArray(value)) return;

    onChange(value);
  };

  const autocompleteUrl = generatePath(OBJECT_CLASS_FIELDS_AUTOCOMPLETE, {
    id: sourceId ?? '0',
  });

  const getScrollableParent = useScrollableParent(elementRef);

  const isObjectRecords = currentTableName === TablesType.ObjectRecords;

  const predicateSetOptions = useMemo(
    () => [
      {
        label: intl.formatMessage({
          id: 'misc.classFields',
          defaultMessage: 'Class fields',
        }),
        value: PredicateSet.ClassFields,
      },
      {
        label: intl.formatMessage({
          id: 'misc.recordProperties',
          defaultMessage: 'Record properties',
        }),
        value: PredicateSet.RecordProperties,
      },
    ],
    [intl]
  );

  const autocompleteValue = useMemo(() => {
    if (!defaultValue) return undefined;

    const [value] = defaultValue;

    return [
      {
        value,
        label: label ?? columns?.[value]?.label ?? value,
      },
    ];
  }, [defaultValue, label, columns]);

  const fields = useMemo(() => {
    const tmpFields = Object.keys(columns).filter(
      key =>
        (withoutEmptyPredicates(columns)(key) && !usedColumns?.includes(key)) ||
        defaultValue?.includes(key)
    );

    const fieldsWithLabels = getFiledsWithLabels(
      tmpFields,
      columns,
      currentTableName
    );

    const filteredFieldsWithLabels = isObjectRecords
      ? fieldsWithLabels.filter(
          ({ groupKey }) =>
            predicateSet &&
            ((predicateSet === PredicateSet.ClassFields &&
              groupKey === CustomTableGroupKeys.ObjectClassFields) ||
              (predicateSet === PredicateSet.RecordProperties &&
                (groupKey === CustomTableGroupKeys.Default || !groupKey)))
        )
      : fieldsWithLabels;

    return filteredFieldsWithLabels.sort(sortColumnFields);
  }, [
    columns,
    currentTableName,
    defaultValue,
    isObjectRecords,
    predicateSet,
    usedColumns,
  ]);

  const filterAutocomplete = useCallback(
    (options: SelectOptionType[]) =>
      options.filter(({ value }) => !usedColumns?.includes(value.toString())),
    [usedColumns]
  );

  if (isObjectRecords)
    return (
      <div className={classes.selectSetWrapper} ref={elementRef}>
        <Select
          value={predicateSet}
          placeholder={intl.formatMessage({
            id: 'misc.select',
            defaultMessage: 'Select',
          })}
          options={predicateSetOptions}
          onChange={onPredicateSetChange}
          getPopupContainer={getScrollableParent}
        />

        {predicateSet === PredicateSet.ClassFields ? (
          <AutocompleteSelect
            addOptionData
            selectMultiple={false}
            value={autocompleteValue}
            onChange={handleChangeAutocomplete}
            autocompleteUrl={autocompleteUrl}
            dataTestId={FILTER_COLUMN_SELECT_TESTID}
            placeholder={intl.formatMessage({
              id: 'misc.searchForFields',
              defaultMessage: 'Search for fields',
            })}
            withSorting
            filterResults={filterAutocomplete}
          />
        ) : (
          <SelectColumn
            {...{
              fields,
              getScrollableParent,
              isObjectRecords,
              nameFields,
              onChange,
              predicateSet,
              additionalClassName,
              defaultValue,
            }}
          />
        )}
      </div>
    );

  return (
    <SelectColumn
      {...{
        fields,
        getScrollableParent,
        isObjectRecords,
        nameFields,
        onChange,
        predicateSet,
        additionalClassName,
        defaultValue,
      }}
    />
  );
};

export default memo(ColumnSelect);
