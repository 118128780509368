import React from 'react';
import clsx from 'clsx';
import { Tag } from 'components/lib/Tag';
import { TagContainerProps } from './types';
import DynamicFormattedMessage from 'components/DynamicFormattedMessage';
import useTagContainerStyles from './styles';
import { parseToIntlKey } from 'utils/functions/parseToIntlKey';

const TagContainer: React.FC<TagContainerProps> = ({ type, name }) => {
  const classes = useTagContainerStyles({});

  return (
    <div className={classes.tagContainer}>
      <Tag className={clsx([classes.tag, classes[type]])}>
        <DynamicFormattedMessage
          id={`enums.${parseToIntlKey(name)}`}
          defaultMessage={name}
        />
      </Tag>
    </div>
  );
};

export default TagContainer;
