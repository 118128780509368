import React from 'react';
import { Row as AntRow, Col as AntCol } from 'antd';
import { RowProps } from 'antd/lib/row';
import { ColProps } from 'antd/lib/col';

export const Row: React.FC<RowProps> = ({ ...rest }) => {
  return <AntRow {...rest} />;
};

interface Props extends ColProps {
  maxWidth?: number;
  width?: string;
}

export const Col: React.FC<Props> = ({ width, maxWidth, ...rest }) => {
  return <AntCol {...rest} style={{ maxWidth: maxWidth || '100%', width }} />;
};
