import { FlatValidatorsObject } from 'utils/types';
import { StageNames, Task } from 'utils/types/api/tasks.types';
import { MergedTaskSchema } from './hooks/types';

export interface TaskListItemProps {
  isSelected?: boolean;
  refetchTasksList?: () => Promise<void>;
  task: Task;
  tasksListRef?: React.RefObject<HTMLDivElement>;
}

export interface CancelCreationModalsProps {
  isCancelModalVisible: boolean;
  toggleCancelModal: () => void;
  isNavigateAway: boolean;
}

export interface DueDateFormSectionProps {
  rawValidationSchema: FlatValidatorsObject;
  setDueDateOption: React.Dispatch<React.SetStateAction<DueDateOptions>>;
  dueDateOption: DueDateOptions;
}

export enum DueDateOptions {
  NoDueDate = 'no_due_date',
  DueDate = 'date',
}

export interface StagesAssignmentSectionProps {
  options: MergedTaskSchema;
}

export interface ReassignTaskHeaderProps {
  recordId?: number;
  taskName?: string;
}

export interface DataToRemove {
  id: number | string;
  stageId: StageNames;
  stage: string;
  userName: string;
}
