import { createUseStyles } from 'react-jss';

const maxWidth = 991;

const useLoginStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    alignItems: 'center',
  },
  informationWrapper: {
    width: '100%',
  },
  footer: {
    position: 'absolute',
    bottom: '10px',
    textAlign: 'center',
    fontSize: 12,
  },
  footerText: {
    fontSize: 12,
    fontWeight: 400,
  },
  card: {
    borderRadius: 5,
    boxShadow: '0px 4px 50px rgba(46, 63, 87, 0.19)',
    padding: '92px 0 42px 0',
  },
  logo: {
    position: 'absolute',
    top: '-10px',
    margin: 'auto',
    left: 0,
    right: 0,
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    margin: '20px 0',
    lineHeight: '34.5px',
    fontWeight: 700,
  },
  [`@media (max-width: ${maxWidth}px)`]: {
    informationWrapper: {
      display: 'none',
    }
  }
});

export default useLoginStyles;
