import { Mods } from '../types';

export interface FormBuilderProps {
  schema: string;
  uischema: string;
  mods?: Mods;
  className?: string;
  formName: string;
  formDescription: string;
  onChange: (schema: string, uiSchema: string) => void;
  isRecordSummary?: boolean;
}

export enum FormTypes {
  RecordListing = 'recordListing',
  RecordView = 'recordView',
  RecordSummary = 'recordSummary',
  TaskTemplates = 'taskTemplates',
  TaskTemplateWithRecordFields = 'taskTemplateWithRecordFields',
  LandingPage = 'landingPage',
}
