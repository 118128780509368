import { useCallback } from 'react';
import { Actions, Node, Model } from 'flexlayout-react';

import { ExternalComponent } from './types';
import { useIntl } from 'react-intl';
import ToastType from 'utils/Enums/ToastType';
import { toast } from '../toast';

const useExternalWindows = (
  model: Model,
  onAddTab: (componentKey: string, noExternalCheck?: boolean) => void,
  externalComponents: ExternalComponent[],
  setExternalComponents: React.Dispatch<
    React.SetStateAction<ExternalComponent[]>
  >
) => {
  const intl = useIntl();

  const checkIfExternalWindowExists = useCallback(
    (componentKey: string) => {
      return (
        externalComponents.find(component => component.id === componentKey) !==
        undefined
      );
    },
    [externalComponents]
  );

  const moveTabToExternalWindow = useCallback(
    (node?: Node) => {
      if (!node) {
        return;
      }

      if (checkIfExternalWindowExists(node.getId())) {
        toast(
          {
            title: intl.formatMessage({
              id: 'misc.info',
              defaultMessage: 'Info!',
            }),
            subtitle: intl.formatMessage({
              id: 'flexLayout.windowAlreadyOpened',
              defaultMessage: 'Window is already opened.',
            }),
          },
          ToastType.Info
        );

        return;
      }

      //@ts-ignore
      const componentName = node._attributes.component;
      setExternalComponents(prevState => [
        ...prevState,
        { componentName, id: node.getId() },
      ]);
      model.doAction(Actions.deleteTab(node.getId()));
    },
    [model, intl, checkIfExternalWindowExists, setExternalComponents]
  );

  const closeExternalComponent = useCallback(
    (id: string) => {
      setExternalComponents(prevState => [
        ...prevState.filter(component => component.id !== id),
      ]);
    },
    [setExternalComponents]
  );

  const reatachExternalWindow = useCallback(
    (id: string) => {
      setExternalComponents(prevState => {
        const component = prevState.find(component => component.id === id);

        if (!component) {
          return prevState;
        }

        onAddTab(component.componentName, true);
        return [...prevState.filter(component => component.id !== id)];
      });
    },
    [onAddTab, setExternalComponents]
  );

  return {
    moveTabToExternalWindow,
    reatachExternalWindow,
    externalComponents,
    closeExternalComponent,
    checkIfExternalWindowExists,
  };
};

export default useExternalWindows;
