import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import TablesType from 'utils/Enums/TablesType';

const useEmptyTableDescription = (
  currentTable: TablesType | undefined,
  hasPermissions: boolean,
  appliedFilters: boolean
) => {
  const intl = useIntl();

  const descriptions: MappedObject<string, TablesType> = useMemo(
    () => ({
      [TablesType.TaskTemplates]: intl.formatMessage({
        id: 'misc.noTaskTemplatesCreated',
        defaultMessage: 'No task templates created',
      }),
      [TablesType.ObjectClasses]: intl.formatMessage({
        id: 'misc.noObjectClassesCreated',
        defaultMessage: 'No object classes created',
      }),
      [TablesType.ObjectClassesFields]: intl.formatMessage({
        id: 'misc.noObjectClassesFieldsCreated',
        defaultMessage: 'No object classes fields created',
      }),
      [TablesType.ObjectRecords]: intl.formatMessage({
        id: 'workspace.noRecordsInSelectedClass',
        defaultMessage:
          'There are no records available for the selected object class.',
      }),
      [TablesType.Tasks]: intl.formatMessage({
        id: 'misc.noTasksAvailable',
        defaultMessage: 'No tasks are available for you to view or complete.',
      }),
      [TablesType.Sequences]: intl.formatMessage({
        id: 'misc.noSequencesCreated',
        defaultMessage: 'No sequences created',
      }),
      [TablesType.TaskGroups]: intl.formatMessage({
        id: 'misc.noTaskGroupTemplatesCreated',
        defaultMessage: 'No task group templates created',
      }),
      [TablesType.UserGroups]: intl.formatMessage({
        id: 'misc.noUserGroupsCreated',
        defaultMessage: 'No user groups created',
      }),
      [TablesType.Users]: intl.formatMessage({
        id: 'misc.noUsersCreated',
        defaultMessage: 'No users created',
      }),
      [TablesType.Roles]: intl.formatMessage({
        id: 'misc.noRolesCreated',
        defaultMessage: 'No roles created',
      }),
      [TablesType.Forms]: intl.formatMessage({
        id: 'misc.noFormsCreated',
        defaultMessage: 'No forms created',
      }),
      [TablesType.MessageTemplates]: intl.formatMessage({
        id: 'misc.noMessageTemplatesCreated',
        defaultMessage: 'No message templates created',
      }),
      [TablesType.EmailCollectors]: intl.formatMessage({
        id: 'misc.noEmailCollectorsCreated',
        defaultMessage: 'No email collectors created',
      }),
      [TablesType.SingleSignOn]: intl.formatMessage({
        id: 'misc.noSingleSignOnCreated',
        defaultMessage: 'No single sign-on created',
      }),
      [TablesType.AuthenticationObjects]: intl.formatMessage({
        id: 'misc.noAuthenticationObjectsCreated',
        defaultMessage: 'No authentication objects created',
      }),
      [TablesType.Members]: intl.formatMessage({
        id: 'misc.noResults',
        defaultMessage: 'No results',
      }),
      [TablesType.ClassForms]: intl.formatMessage({
        id: 'misc.noResults',
        defaultMessage: 'No results',
      }),
      [TablesType.DocumentTemplates]: intl.formatMessage({
        id: 'misc.noDocumentTemplates',
        defaultMessage: 'No document templates created',
      }),
      [TablesType.DocumentTemplatesPanel]: intl.formatMessage({
        id: 'misc.noDocumentTemplates',
        defaultMessage: 'No document templates created',
      }),
      [TablesType.NestedTable]: intl.formatMessage({
        id: 'misc.noRecordsCreated',
        defaultMessage: 'No records created',
      }),
      [TablesType.CustomComponents]: intl.formatMessage({
        id: 'misc.noCustomComponentsCreated',
        defaultMessage: 'No elements created',
      }),
    }),
    [intl]
  );

  const noPermissionDescriptions: MappedObject<string> = useMemo(
    () => ({
      [TablesType.ObjectClasses]: intl.formatMessage({
        id: 'objectClasses.noPermissionsToList',
        defaultMessage:
          'You do not have the permissions to view any object classes',
      }),
      [TablesType.UserGroups]: intl.formatMessage({
        id: 'userGroups.noPermissionsToList',
        defaultMessage:
          'You do not have the permission to view any user groups',
      }),
      [TablesType.ObjectRecords]: intl.formatMessage({
        id: 'workspace.noPermissionToViewRecords',
        defaultMessage: 'You do not have the permissions to view any records.',
      }),
    }),
    [intl]
  );

  const commonMessage = intl.formatMessage({
    id: 'misc.pleaseCheckFilters',
    defaultMessage: 'Please check your filters.',
  });

  if (currentTable) {
    if (!hasPermissions) return noPermissionDescriptions[currentTable];

    return appliedFilters
      ? commonMessage
      : descriptions[currentTable] ?? commonMessage;
  }

  return commonMessage;
};

export default useEmptyTableDescription;
