import { CustomFileItem } from 'components/lib/FileUpload/types';
import { EMAIL_REGEX, URL_REGEX } from 'utils/consts';
import { FormValue } from 'alx-dynamic-form';

const customFormats = {
  email: (value: any) => {
    if (!value) return true;

    return EMAIL_REGEX.test(value);
  },
  'data-url': (value: CustomFileItem[]) => {
    if (!value) return true;

    return value.every(({ status }) => status === 'done');
  },
  url: (value: FormValue) => {
    if (!value) return true;

    return URL_REGEX.test(value as string);
  },
  json: (value: FormValue) => {
    if (typeof value !== 'string') return true;

    try {
      JSON.parse(value);
      return true;
    } catch (err) {
      return false;
    }
  },
};

export default customFormats;
