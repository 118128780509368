import { createUseStyles } from 'react-jss';

const useInPlaceEditTextinputStyles = createUseStyles({
  singleLinePreview: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

export default useInPlaceEditTextinputStyles;
