import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PlusIcon } from 'components/Icon';
import { ButtonPrimary } from 'components/lib/Button';
import {
  TABLE_BULK_SELECTION_ACTION_ADD_BUTTON_TESTID,
  TABLE_BULK_SELECTION_ACTION_REMOVE_BUTTON_TESTID,
} from 'utils/testIds';
import useBulkSelectionActionButton from './hooks';
import { useActionButtonStyles } from './styles';
import { ActionButtonType } from './types';

const BulkSelectionActionButton = () => {
  const classes = useActionButtonStyles({});
  const {
    onClickAdd,
    onClickRemove,
    typeOfAction,
  } = useBulkSelectionActionButton();

  if (typeOfAction === undefined) return null;

  return (
    <div className={classes.wrapper}>
      {typeOfAction === ActionButtonType.Adding ? (
        <ButtonPrimary
          icon={<PlusIcon size={10} />}
          onClick={onClickAdd}
          data-testid={TABLE_BULK_SELECTION_ACTION_ADD_BUTTON_TESTID}
          className={classes.button}
        >
          <FormattedMessage
            id='misc.addAsMembers'
            defaultMessage='Add as members'
          />
        </ButtonPrimary>
      ) : (
        <ButtonPrimary
          onClick={onClickRemove}
          data-testid={TABLE_BULK_SELECTION_ACTION_REMOVE_BUTTON_TESTID}
          className={classes.button}
          danger
        >
          <FormattedMessage
            id='misc.removeFromGroup'
            defaultMessage='Remove from group'
          />
        </ButtonPrimary>
      )}
    </div>
  );
};

export default BulkSelectionActionButton;
