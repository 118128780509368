import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useParams } from 'react-router-dom';
import {
  getUserGroupPermissionsGetSelectedPermissionSet,
  getUserGroupPermissionSetAssigneesData,
} from 'store/selectors/userGroupPermissionsSelectors';
import { USER_GROUP_PERMISSION_SET_ASSIGNEES } from 'utils/endpoints';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import AddUsersPanel from 'pages/ObjectClasses/components/ObjectClassForm/ObjectClassPermissions/components/AddUsersPanel';
import { ObjectClassOwner } from 'utils/types/api/objectClasses.types';
import {
  AddUsersPanelType,
  CustomErrorMessage,
  OwnersMode,
} from 'pages/ObjectClasses/components/ObjectClassForm/ObjectClassPermissions/components/AddUsersPanel/types';
import { getUserGroupPermissionSetAssignees } from 'store/actions/userGroupPermissionsActions';
import { useIntl } from 'react-intl';
import { AppDispatch } from 'store/store';
import { ErrorCodes } from 'utils/types/errorResponse';

const AddPermissionSetAssignee = () => {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const { id: groupId = '0' } = useParams<{ id: string }>();
  const selectedPermissionSet = useSelector(
    getUserGroupPermissionsGetSelectedPermissionSet
  );

  const permissionSetId = selectedPermissionSet?.id?.toString() || '';

  const { avatars, totalCount: usersCount, options } = useSelector(
    getUserGroupPermissionSetAssigneesData(permissionSetId)
  );

  const itemsLimit = options?.restrictions?.limit_items || 0;
  const autocompleteUrl = options?.batch?.autocomplete || '';

  const postApiEndpoint = generatePath(USER_GROUP_PERMISSION_SET_ASSIGNEES, {
    groupId,
    permissionSetId,
  });

  const panelTitle = intl.formatMessage(
    {
      id: 'misc.assignUsersTo',
      defaultMessage: 'Assign users to {name}',
    },
    { name: selectedPermissionSet?.name }
  );

  const searchPlacehlderText = intl.formatMessage({
    id: 'placeholders.searchForUsers',
    defaultMessage: 'Search for users',
  });

  const customErrorMessage = useCallback(
    ({ count, itemsLimit, errorCode }: CustomErrorMessage) =>
      errorCode === ErrorCodes.ERR_LIMIT_EXCEEDED && itemsLimit
        ? {
            title: intl.formatMessage(
              {
                id: 'owners.countNotAddedAssignees',
                defaultMessage: `{count, plural, one {# assignee} other {# assignees}} not added`,
              },
              { count }
            ),
            subtitle: intl.formatMessage(
              {
                id: 'owners.assigneesLimitReached',
                defaultMessage:
                  'Maximum of {itemsLimit} {itemsLimit, plural, one {assignee} other {assignees}} has been reached.',
              },
              { itemsLimit }
            ),
          }
        : undefined,
    [intl]
  );

  const customSuccessMessage = (count: number) => {
    return intl.formatMessage(
      {
        id: 'owners.usersAssignedTo',
        defaultMessage:
          '{count, plural, one {# user has been} other {# users have been}} assigned to {name}.',
      },
      { count, name: selectedPermissionSet?.name }
    );
  };

  return (
    <AddUsersPanel<ObjectClassOwner>
      {...{
        avatars,
        usersCount,
        itemsLimit,
        autocompleteUrl,
        postApiEndpoint,
        panelTitle,
        customErrorMessage,
        searchPlacehlderText,
      }}
      panelKey={FlexLayoutWindows.UserGroupPermissionSetAssignees}
      panelType={AddUsersPanelType.PermissionSetAssignees}
      fetchOwners={() =>
        dispatch(getUserGroupPermissionSetAssignees(groupId, permissionSetId))
      }
      intlLimitMessage={intl.formatMessage(
        {
          id: 'owners.selectedAssigneesLimitReached',
          defaultMessage:
            'Maximum of {limit} new {limit, plural, one {assignee} other {assignees}} can be selected ({usersCount} {usersCount, plural, one {assignee} other {assignees}} already added).',
        },
        { limit: itemsLimit - usersCount, usersCount }
      )}
      shouldFetchOnSuccess
      defaultMode={OwnersMode.Manage}
      alreadyAddedDisabledMessage={intl.formatMessage({
        id: 'misc.alreadyAnAssigned',
        defaultMessage: 'Already assigned',
      })}
      getSelectedPermissionSet={getUserGroupPermissionsGetSelectedPermissionSet}
      customSuccessMessage={customSuccessMessage}
    />
  );
};

export default AddPermissionSetAssignee;
