import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckMarkIcon, SuccessMark } from 'components/Icon';
import { Modal } from 'components/lib/Modal';
import { useSaveProgressModalStyles } from './styles';
import { SaveProgressModalProps } from './types';
import { ButtonPrimary } from 'components/lib/Button';
import {
  TASK_1TC_SAVE_PROGRESS_DESCRIPTION,
  TASK_1TC_SAVE_PROGRESS_OK,
} from 'utils/testIds';

const SaveProgressModal = ({
  name,
  onOk,
  ...props
}: SaveProgressModalProps) => {
  const classes = useSaveProgressModalStyles({});

  return (
    <Modal {...props} closable={false} footer={null} centered width={490}>
      <div className={classes.modalContainer}>
        <SuccessMark size={86} />
        <div
          className={classes.modalDescription}
          data-testid={TASK_1TC_SAVE_PROGRESS_DESCRIPTION}
        >
          <h3>
            <FormattedMessage
              id='tasks.hasBeenSaved'
              defaultMessage='{name} has been saved'
              values={{ name }}
            />
          </h3>
          <p>
            <FormattedMessage
              id='tasks.youMayContinue'
              defaultMessage='You may continue to update the task, or close this page.'
            />
          </p>
        </div>
      </div>
      <div className={classes.modalButton}>
        <ButtonPrimary
          onClick={onOk}
          icon={<CheckMarkIcon size={14} />}
          data-testid={TASK_1TC_SAVE_PROGRESS_OK}
        >
          <FormattedMessage id='misc.uppercaseOk' defaultMessage='OK' />
        </ButtonPrimary>
      </div>
    </Modal>
  );
};

export default SaveProgressModal;
