import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Space } from 'antd';
import { Select } from 'components/lib/Select';
import { TASK_QUICK_FILTER_DATES } from 'utils/testIds';
import { useTasksQuickFiltersDates } from './hooks';
import { useTasksQuickFiltersStyle } from '../../styles';

const TasksQuickFiltersDates = () => {
  const classes = useTasksQuickFiltersStyle();
  const ref = useRef(null);

  const {
    datePeriod,
    datesOptions,
    handleChangeDatePeriod,
  } = useTasksQuickFiltersDates();

  return (
    <Space direction='vertical' className={classes.datesWrapper}>
      <FormattedMessage id='tasks.dates' defaultMessage='Dates' />
      <div ref={ref} data-testid={TASK_QUICK_FILTER_DATES}>
        <Select
          className={classes.quickFilterSelect}
          dropdownClassName={classes.datesSelect}
          options={datesOptions}
          value={datePeriod}
          onChange={handleChangeDatePeriod}
          getPopupContainer={() => ref.current || document.body}
          placeholder={
            <FormattedMessage id='misc.custom' defaultMessage='Custom' />
          }
        />
      </div>
    </Space>
  );
};

export default TasksQuickFiltersDates;
