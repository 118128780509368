import { useEffect, useMemo, useRef, useCallback, useState } from 'react';
import { useSaveChangesModal } from 'components/FormHeader/hooks';
import { useConfirmationModalContext } from 'contexts/ConfirmationModalContext';
import { useFormikContext } from 'formik';
import { useToggle } from 'hooks/useToggle';
import { useBeforeunload } from 'react-beforeunload';
import { useLocation } from 'react-router';
import { preventDefault } from 'utils/functions/preventDefault';
import {
  ClassFieldDetailLocationState,
  ClassFieldForm,
  ClassFieldFormFields,
} from '../../types';
import {
  ClassFieldChangesConfirmationModalsProps,
  ProvidedValue,
  StoredData,
} from './types';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import noop from 'lodash/noop';
import { useDispatch, useSelector } from 'react-redux';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import { ERROR } from 'components/FormHeader/consts';
import { getSidebarData } from 'store/selectors/flexLayoutSelectors';
import { Location } from 'history';
import { useClassFieldFormContext } from 'pages/ObjectClasses/components/ObjectClassForm/context/ClassFieldFormContext';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import { getObjectClassFieldsDetails } from 'store/selectors/objectClassesFieldsSelectors';
import { ObjectClassFieldTypes } from 'utils/types/api/objectClassesFields.types';

const useTogglePanel = () => {
  const {
    flexLayoutMethods: { onAddTab },
  } = useFlexLayoutContext();

  return (name?: FlexLayoutWindows) => {
    const panelsMappedToObjectAction: MappedObject<() => void> = {
      [FlexLayoutWindows.ObjectClassEditField]: () => {
        onAddTab(FlexLayoutWindows.ObjectClassEditField);
      },
      [FlexLayoutWindows.ObjectClassAddField]: () => {
        onAddTab(FlexLayoutWindows.ObjectClassAddField);
      },
      [FlexLayoutWindows.ObjectClassFieldViewUsage]: () => {
        onAddTab(FlexLayoutWindows.ObjectClassFieldViewUsage);
      },
    };

    return name
      ? panelsMappedToObjectAction[name] && panelsMappedToObjectAction[name]()
      : noop();
  };
};

export const useClassFieldModals = ({
  closePanel,
  onDontSaveClick,
  isCancelModalVisible,
  onSubmit,
  toggleCancelModal,
}: Pick<
  ClassFieldChangesConfirmationModalsProps,
  | 'closePanel'
  | 'onDontSaveClick'
  | 'onSubmit'
  | 'toggleCancelModal'
  | 'isCancelModalVisible'
>) => {
  const { state: { id } = {} } = useLocation<ClassFieldDetailLocationState>();
  const { readOnly, panelId } = useClassFieldFormContext();
  const {
    dirty,
    isSubmitting,
    resetForm,
    errors,
    setTouched,
  } = useFormikContext<ClassFieldForm>();
  const {
    setStoredModalFunctions,
    setShouldBeDisplayed,
  } = useConfirmationModalContext();
  const [
    showSaveChangesModal,
    { toggleOff: hideSaveChangesModal, toggleOn: displaySaveChangesModal },
  ] = useToggle(false);
  const eventCallback = useRef<ProvidedValue | null>(null);
  const togglePanels = useTogglePanel();
  const dispatch = useDispatch();

  const handleDisplaySaveChangesModal = useCallback(
    (value?: ProvidedValue) => {
      eventCallback.current = value ? value : null;
      displaySaveChangesModal();
    },
    [displaySaveChangesModal]
  );

  useBeforeunload(e => !readOnly && dirty && preventDefault(e));

  const { navigateTo, handleLocationChange } = useSaveChangesModal();

  useEffect(() => {
    setShouldBeDisplayed(panelId, dirty);

    // without this, there is a memory leak error
    return () => setShouldBeDisplayed(panelId, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty, panelId]);

  useEffect(() => {
    setStoredModalFunctions(panelId, {
      callback: handleDisplaySaveChangesModal,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelId]);

  const cancelSaveChangesModal = () => {
    if (!!eventCallback.current) {
      if (eventCallback.current.id.toString() === id) {
        resetForm({});
        hideSaveChangesModal();

        return;
      }

      const panelId = eventCallback.current?.panelId;

      if (panelId) {
        const action = () => {
          togglePanels(panelId);
        };
        eventCallback.current.callback(action);

        return;
      }

      eventCallback.current.callback();
    } else {
      closePanel();
    }

    hideSaveChangesModal();
  };

  const cancelSaveChangesPrompt = (value?: Location) => {
    if (value) {
      handleLocationChange(value.pathname);
    }
  };

  const handleFormErrors = () => {
    setTouched(
      Object.fromEntries(Object.entries(errors).map(([key]) => [key, true]))
    );
    hideSaveChangesModal();
  };

  const confirmSaveChangesModal = async () => {
    if (JSON.stringify(errors) !== '{}') {
      return handleFormErrors();
    }

    try {
      await onSubmit();

      if (eventCallback.current?.callback) {
        const panelId = eventCallback.current?.panelId;

        if (panelId) {
          const action = () => {
            togglePanels(panelId);
          };
          eventCallback.current.callback(action);
        } else {
          eventCallback.current.callback();
        }

        hideSaveChangesModal();
      } else closePanel();
    } catch (e) {
      hideSaveChangesModal();
    }
  };

  const confirmDiscardingChanges = () => {
    onDontSaveClick();
    closePanel();
  };

  const shouldDisplaySaveChangesPrompt = useMemo(
    () => !navigateTo && !isSubmitting && !readOnly && dirty,
    [dirty, isSubmitting, navigateTo, readOnly]
  );

  const onPromptSave = () =>
    new Promise(async (resolve, reject) => {
      if (JSON.stringify(errors) !== '{}') {
        handleFormErrors();
        reject('error');
      }

      try {
        await onSubmit();

        resolve(true);
      } catch (e) {
        handleLocationChange(ERROR);
        reject(e);
      }
    });

  const handleCancelCancelConfiguration = () => {
    if (isCancelModalVisible) {
      toggleCancelModal();
    } else {
      hideSaveChangesModal();
    }
  };

  const handleConfirmCancelConfiguration = () => {
    dispatch(setSidebarData(panelId, {}));

    if (eventCallback.current?.callback) {
      const panelId = eventCallback.current?.panelId;

      if (panelId) {
        const action = () => {
          togglePanels(panelId);
        };
        eventCallback.current.callback(action);
      } else {
        eventCallback.current.callback();
      }
    } else closePanel();

    if (isCancelModalVisible) {
      toggleCancelModal();
    } else {
      hideSaveChangesModal();
    }
  };

  return {
    handleCancelCancelConfiguration,
    handleConfirmCancelConfiguration,
    shouldDisplaySaveChangesPrompt,
    cancelSaveChangesPrompt,
    confirmSaveChangesModal,
    confirmDiscardingChanges,
    cancelSaveChangesModal,
    showSaveChangesModal,
    hideSaveChangesModal,
    onPromptSave,
  };
};

export const useObjectFieldFormValidation = () => {
  const {
    values: { options = [], users = [], type, min_values: optionsMinValues },
  } = useFormikContext<ClassFieldForm>();

  const checkIfSelectHasAnyOption = () => {
    if (
      [ObjectClassFieldTypes.Enum, ObjectClassFieldTypes.Set].includes(type)
    ) {
      const optionsCount = options.filter(item => !!item.trim()).length;

      return !!optionsMinValues
        ? optionsCount >= optionsMinValues
        : !!optionsCount;
    }

    return true;
  };

  const checkIfUsersSelected = () => {
    if (type === ObjectClassFieldTypes.User) {
      return users.length > 0;
    }

    return true;
  };

  return {
    isObjectFieldFormValid:
      checkIfSelectHasAnyOption() && checkIfUsersSelected(),
  };
};

export const useStoredData = <T>(panelId: FlexLayoutWindows) => {
  const savedData = (useSelector(getSidebarData) as unknown) as MappedObject<
    StoredData<T>,
    FlexLayoutWindows
  >;

  return useMemo(() => {
    const { values: storedValues, ...rest } = savedData[panelId] || {};

    return { storedValues, allStoredData: { ...rest, values: storedValues } };
  }, [panelId, savedData]);
};

export const useUniquenessValidation = (type: ObjectClassFieldTypes) => {
  const [uniqueSchemaTypes, setUniqueSchemaTypes] = useState<string[]>([]);
  const details = useSelector(getObjectClassFieldsDetails);

  const { uniquenessAllowed, schemaType } = useMemo(() => {
    const schemaType = details?.schema.find(
      ({ alias }) => alias === ClassFieldFormFields.Type
    );
    const uniquenessAllowed = uniqueSchemaTypes.includes(type);

    return { uniquenessAllowed, schemaType };
  }, [details, type, uniqueSchemaTypes]);

  useEffect(() => {
    if (schemaType) {
      setUniqueSchemaTypes(
        schemaType?.values
          ?.filter(({ is_unique: isUnique }) => !!isUnique)
          .map(({ value }) => value) || []
      );
    }
  }, [schemaType]);

  return { uniquenessAllowed };
};
