import React from 'react';
import { SubMenu } from 'components/lib/Menu';
import { PermissionSubMenuProps } from './types';
import { useSideMenuContext } from './context/SideMenuContext';

const PermissionSubMenu: React.FC<PermissionSubMenuProps> = ({
  visible,
  ...rest
}) => {
  const { eventKey = '', mode } = rest;
  const { activeMenuKey } = useSideMenuContext();

  return visible ? (
    <SubMenu
      {...rest}
      subMenuActive={mode === 'vertical' && activeMenuKey.includes(eventKey)}
      subMenuInlineActive={
        mode === 'inline' && activeMenuKey.includes(eventKey)
      }
    />
  ) : null;
};

export default PermissionSubMenu;
