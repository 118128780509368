import LoaderOverlay from 'components/LoaderOverlay';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCustomTheme } from 'store/actions/customThemeActions';
import { AppDispatch } from 'store/store';

const PreLoggedInGlobalActionsWrapper: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await dispatch(getCustomTheme());
      setIsLoading(false);
    };

    loadData();
  }, [dispatch]);

  return <>{isLoading ? <LoaderOverlay /> : children}</>;
};

export default PreLoggedInGlobalActionsWrapper;
