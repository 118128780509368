import { ExpiredPasswordFields } from './enums';

export const ExpiredPasswordValidation = {
  [ExpiredPasswordFields.Password]: {
    required: true,
  },
  [ExpiredPasswordFields.ConfirmPassword]: {
    required: true,
  },
};
