import React from 'react';
import { mapCustomWidgetPropsToBaseInPlaceEditProps } from './utils';
import { Widget } from 'alx-dynamic-form';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import InPlaceEditJson from 'components/InPlaceEditControls/InPlaceEditJson';

const AdaptedInPlaceEditJson: Widget = widgetProps => {
  const baseInPlaceEditProps = mapCustomWidgetPropsToBaseInPlaceEditProps<
    object
  >(widgetProps);

  return (
    <Provider store={store}>
      <InPlaceEditJson {...baseInPlaceEditProps} />
    </Provider>
  );
};

export default AdaptedInPlaceEditJson;
