import { AvatarItem } from 'components/lib/Avatar/types';

export const sortAvatarsAlphabetically = (avatars: AvatarItem[]) =>
  [...avatars].sort(sortAvatarsAlphabeticallyComparison);

export const sortAvatarsAlphabeticallyComparison = (
  a: AvatarItem,
  b: AvatarItem
) => {
  if (
    `${a.firstName} ${a.lastName}`.toLocaleLowerCase() >
    `${b.firstName} ${b.lastName}`.toLocaleLowerCase()
  )
    return 1;
  if (
    `${a.firstName} ${a.lastName}`.toLocaleLowerCase() <
    `${b.firstName} ${b.lastName}`.toLocaleLowerCase()
  )
    return -1;
  return 0;
};
