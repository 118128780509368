export enum ObjectClassPermissionsTabs {
  ClassPermissions = 'classPermissions',
  RecordPermissions = 'recordPermissions',
  FieldsPermissions = 'fieldsPermissions',
}

export interface ObjectClassPermissionsSubTab {
  key: ObjectClassPermissionsTabs;
  tabName: string;
  TabComponent: () => JSX.Element;
}
