import { RootState } from 'store/reducers';

export const selectObjectRecords = (state: RootState) =>
  state.objectRecords.allIds.map(id => {
    const tableNameSingle = state.objectRecords.byId[id];

    return {
      ...tableNameSingle,
      key: tableNameSingle.id,
    };
  });

export const totalObjectRecords = (state: RootState) => {
  return state.objectRecords.total;
};

export const filteredObjectRecordsCount = (state: RootState) => {
  return state.objectRecords.filtered;
};

export const getObjectsColumns = (state: RootState) => {
  return state.objectRecords.columns;
};

export const getObjectsSelectedRow = (state: RootState) => {
  return state.objectRecords?.selectedRow;
};

export const getRemoveOwnerCallback = (state: RootState) =>
  state.objectRecords.removeOwnerCallback;
