import React from 'react';
import { useTasksQuickFiltersStyle } from './styles';
import TasksQuickFiltersOwnership from './components/TasksQuickFilterOwnership';
import TasksQuickFiltersDates from './components/TasksQuickFilterDates';

const TasksQuickFilters = () => {
  const classes = useTasksQuickFiltersStyle();

  return (
    <div className={classes.quickFiltersWrapper}>
      <TasksQuickFiltersOwnership />
      <TasksQuickFiltersDates />
    </div>
  );
};

export default TasksQuickFilters;
