import React, {
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState,
  forwardRef,
} from 'react';
import {
  useTable,
  useSortBy,
  usePagination,
  useResizeColumns,
  useColumnOrder,
  TableState,
  useBlockLayout,
  HeaderGroup,
} from 'react-table';
import Sorter from './Sorter';
import { Loader } from 'components/lib/Loader';
import EmptyData from 'components/EmptyData';
import { FormattedMessage } from 'react-intl';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggingStyle,
  DraggableStateSnapshot,
  DraggableProvided,
} from 'react-beautiful-dnd';
import { CloseIcon } from 'components/Icon';
import AddColumn from 'components/AddColumn';
import { ButtonTransparent } from 'components/lib/Button';
import useDefaultColumns from 'hooks/useDefaultColumns';
import useButtonStyles from 'components/lib/Button/styles';
import {
  DEFAULT_COLUMNS_WIDTHS,
  ACTIONS_COLUMN_WIDTH,
  EMPTY_TABLE_HEIGHT,
  BULK_SELECTION_COLUMN_WIDTH,
} from './consts';
import Resizer from './Resizer';
import useTableWrapperStyles, {
  useTableBodyStyles,
  useTableStyles,
} from './styles';
import clsx from 'clsx';
import useEmptyTableMessage from 'hooks/useEmptyTableMessage';
import { useTableContext } from 'contexts/TableContext';
import { useTableRowContext } from 'contexts/TableRowContext';
import PaginationWrapper from './PaginationWrapper';
import { useContentWrapperContext } from 'contexts/ContentWrapperContext';
import useGlobalStyle from 'hooks/useGlobalStyle';
import useAppliedFilters from 'hooks/useAppliedFilters';
import { useMenuSidebarWidth } from 'components/PageLayout/hooks';
import { typedMemo } from 'utils/types';
import TableRow from './TableRow';
import { FixedSizeList, ListProps } from 'react-window';
import useDensity from 'hooks/useDensity';
import TableModals from './TableModals';
import { useStateReducer } from './hooks/useStateReducer';
import { useConfirmationModals } from './hooks/useConfirmationModals';
import { useHasVerticalScroll } from './hooks/useHasVerticalScroll';
import { useScrollbars } from './hooks/useScrollbars';
import { useDragAndDrop } from './hooks/useDragAndDrop';
import { useAddColumns } from './hooks/useAddColumns';
import { useTableHasChanges } from './hooks/useTableHasChanges';
import { useDispatch, useSelector } from 'react-redux';
import {
  TABLE_BODY_ID,
  TABLE_HEAD_ID,
  TABLE_PAGINATION_ID,
  TABLE_HEAD_ROW_ID,
  TABLE_WRAPPER,
} from 'utils/elementsIds';
import { CustomColumnProperties, RowsDisplay } from './types';
import SearchInput from 'components/SearchInput';
import {
  REMOVE_COLUMN_TESTID,
  TABLE_BODY_TESTID,
  TABLE_BULK_SELECTION_HEADER_TESTID,
  TABLE_EMPTY_COLUMN_TESTID,
  TABLE_SORTABLE_COLUMN_HEADER_TESTID,
  TABLE_SORTABLE_RESIZER_TESTID,
  TABLE_SORTABLE_TABLE_HEADER_TESTID,
} from 'utils/testIds';
import useEmptyTableDescription from '../../hooks/useEmptyTableDescription';
import { useBeforeunload } from 'react-beforeunload';
import BulkSelectionHeaderCheckbox from './BulkSelection/HeaderCheckbox';
import BulkSelectionCountMessage from './BulkSelection/CountMessage';
import BulkSelectionActionButton from './BulkSelection/ActionButton';
import LimitAlert from './BulkSelection/LimitAlert';
import TooltipString from 'components/TooltipString';
import { generateDraggableTransform } from 'utils/functions/generateDraggableTransform';
import isEqual from 'lodash/isEqual';
import { composeResizingColumnWidths, getColumnVarName } from './utils';

import ResizeProvider from './ResizeProvider';
import { useColumnStyles } from './hooks/useColumnStyles';
import omit from 'lodash/omit';
import EmptyTableRow from './EmptyTableRow';
import {
  getObjectRecordsInitialColumns,
  getObjectRecordsSelectedColumns,
} from 'store/selectors/objectRecordsSelectors';

export const Table: React.FC = () => {
  const {
    columns,
    data,
    isFetching: loading,
    sortParams = { id: 'id', desc: true },
    onChangeSort,
    initialState,
    editModeEnabled: editMode,
    onColumnResize,
    preventFromHideColumns: unhidenableColumns,
    currentTableName,
    currentPage,
    isInfinite,
    pageSize,
    withPagination = true,
    handleSearchChange,
    searchValue,
    getEmptyMessageDescription,
    isVisibleBulkSelectionColumn,
    bulkSelectionList,
    hasListPermissions,
    withoutSort,
    filterSelectOptions,
    withFilters,
    filteredCount,
    rowsDisplay,
    columnsConfigurationLoading,
    preventInteractionWithColumns,
    preventOverflow,
    withBordersAroundTable,
    fullWidth,
    renderEmptyTable,
    renderHeaderLimit,
    customTableId,
    setIsVisibleBulkSelectionColumn,
  } = useTableContext();
  const { density, setSelectedRow, onActionCellClick } = useTableRowContext();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      if (setSelectedRow) {
        dispatch(setSelectedRow(undefined));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialRecordsCols = useSelector(getObjectRecordsInitialColumns);
  const currentRecordsCols = useSelector(getObjectRecordsSelectedColumns);

  const cellHeight = useDensity(density);
  const emptyTableMessage = useEmptyTableMessage(currentTableName);
  const appliedFilters = useAppliedFilters();
  const emptyTableDescription = useEmptyTableDescription(
    currentTableName,
    hasListPermissions,
    appliedFilters
  );
  const sidebarWidth = useMenuSidebarWidth();
  const defaultTableColumns = useDefaultColumns(currentTableName);

  const [initialLoad, setInitialLoad] = useState(false);

  const [temporaryState, setTemporaryState] = useState<
    TableState<object> | undefined
  >();

  const defaultColumn = useMemo(() => DEFAULT_COLUMNS_WIDTHS, []);
  const { stateReducer, setConfirmedCancel } = useStateReducer({
    temporaryState,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setColumnOrder,
    setHiddenColumns,
    visibleColumns,
    resetResizing,
    totalColumnsWidth,
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      initialState: {
        ...initialState,
        sortBy: [sortParams],
      },
      autoResetHiddenColumns: false,
      defaultColumn,
      disableSortRemove: true,
      stateReducer,
    },
    useSortBy,
    usePagination,
    useResizeColumns,
    useBlockLayout,
    useColumnOrder
  );
  const { hiddenColumns, columnResizing, columnOrder } = state;

  useEffect(() => {
    if (editMode) return;

    const isSyncInitialColumnOrderWithState = isEqual(
      initialState?.columnOrder,
      columnOrder
    );
    const isSyncInitialHiddenColumnsWithState = isEqual(
      initialState?.hiddenColumns,
      hiddenColumns
    );

    if (!isSyncInitialColumnOrderWithState && initialState?.columnOrder)
      setColumnOrder(initialState?.columnOrder);

    if (!isSyncInitialHiddenColumnsWithState && initialState?.hiddenColumns)
      setHiddenColumns(initialState?.hiddenColumns);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState]);

  const {
    onColumnDragStart,
    onDragUpdate,
    onDragEnd,
    getItemStyle,
    isDraggingColumn,
  } = useDragAndDrop({
    setColumnOrder,
    visibleColumns,
    editMode,
  });
  const {
    onAddNewColumns,
    addColumnToHidden,
    onTogglePopover,
    shouldBeVisible,
    indexToAddColumns,
  } = useAddColumns({
    setHiddenColumns,
    hiddenColumns,
    visibleColumns,
    setColumnOrder,
  });
  const {
    setShouldShowConfirmation,
    setCancelConfigModalVisible,
    ...modalsActions
  } = useConfirmationModals({
    setConfirmedCancel,
    temporaryState,
    setColumnOrder,
  });
  const hasChanges = useTableHasChanges(
    state,
    temporaryState,
    initialRecordsCols,
    currentRecordsCols
  );

  const minWidth =
    totalColumnsWidth +
    (onActionCellClick ? ACTIONS_COLUMN_WIDTH : 0) +
    (isVisibleBulkSelectionColumn ? BULK_SELECTION_COLUMN_WIDTH : 0);

  const tableHeadRowArea = document.getElementById(TABLE_HEAD_ROW_ID);
  const tableHeaderRowOffsets = tableHeadRowArea?.getBoundingClientRect();

  const { disabledEdition } = useContentWrapperContext();

  const emptyTable = useMemo(
    () =>
      (!loading && !columnsConfigurationLoading && data.length === 0) ||
      !hasListPermissions,
    [columnsConfigurationLoading, data.length, hasListPermissions, loading]
  );

  const tableBodyRef = useRef<null | DOMRect>(null);
  const listRef = useRef<FixedSizeList | null>(null);

  const isInitialLoading = useMemo(
    () => loading && columnsConfigurationLoading && !data.length,
    [data, loading, columnsConfigurationLoading]
  );

  const hasScrollY = useHasVerticalScroll();
  const classes = useTableStyles({
    density,
    emptyTable,
    hasScrollY,
    preventOverflow,
    withBordersAroundTable,
    fullWidth,
  });
  const { tableBody } = useTableBodyStyles({
    tableBodyTopOffset: tableBodyRef?.current?.top,
    addColumnMode: disabledEdition,
    isInitialLoading,
    isNoData: !data.length,
    preventOverflow,
  });
  const globalClasses = useGlobalStyle();
  const buttonClasses = useButtonStyles({});
  const tableClasses = useTableWrapperStyles({
    ACTIONS_COLUMN_WIDTH,
  });

  useBeforeunload(event => {
    if (editMode && hasChanges) {
      event.preventDefault();
    }
  });

  useEffect(() => {
    setTemporaryState(editMode ? state : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  const customEmptyTable = renderEmptyTable?.({
    tableWidth: fullWidth ? '100%' : 'var(--table-row-width)',
  });

  useEffect(() => {
    if (!initialLoad) {
      setInitialLoad(true);
    }
  }, [initialLoad]);

  useEffect(() => {
    if (initialLoad) {
      const { id, desc = false } = state.sortBy[0];

      onChangeSort({ id, desc });
    }

    if (!!listRef.current) {
      listRef.current.scrollTo(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.sortBy]);
  // do not add initialLoad to dependencies, otherwise you'll break the loading currentPage number from query params

  useEffect(() => {
    if (!!listRef.current && !isInfinite) {
      listRef.current.scrollTo(0);
    }
  }, [currentPage, isInfinite, pageSize]);

  useEffect(() => {
    // scroll to the top after calling "onChange" action in <FilterSelect /> which uses "filterSelectOptions" to update "searchValue"
    if (!!listRef.current && !!filterSelectOptions) listRef.current.scrollTo(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue?.value]);

  useEffect(() => {
    const { isVisibleBulkSelection } =
      filterSelectOptions?.find(
        option => option.value === searchValue?.value
      ) || {};
    setIsVisibleBulkSelectionColumn(!!isVisibleBulkSelection as boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelectOptions, setIsVisibleBulkSelectionColumn]);

  useEffect(() => {
    if (tableBodyRef.current === null || (tableBodyRef.current && density)) {
      const sidebarElement =
        window.document
          .getElementById(TABLE_BODY_ID + customTableId)
          ?.getBoundingClientRect() || null;

      tableBodyRef.current = sidebarElement;
    }
  }, [density, data.length, customTableId]);

  const itemCount = useMemo(() => {
    if (rowsDisplay !== RowsDisplay.FIXED) {
      return rows.length;
    }

    return filteredCount - pageSize * currentPage < pageSize
      ? pageSize
      : rows.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredCount, pageSize, rows.length, rowsDisplay]); //currentPage wasn't included in deps array, because with it there is one render which is out of sync - currentPage shows new value, but rows haven't updated yet to reflect correct number for this page

  const emptyDataTitle = appliedFilters ? emptyTableMessage : '';

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];

      if (!row && rowsDisplay === RowsDisplay.FIXED) {
        return <EmptyTableRow index={index as number} />;
      }
      prepareRow(row);

      return (
        <TableRow
          {...{
            row,
            totalColumnsWidth,
            currentTableName,
            style,
            index,
          }}
        />
      );
    },
    [currentTableName, prepareRow, rows, totalColumnsWidth, rowsDisplay]
  );

  const { onBodyScroll, onHeaderScroll } = useScrollbars({
    appliedFilters,
    customTableId,
  });

  const onTableBodyScroll = useCallback(
    (event, virtualizedScrollMethod) => {
      virtualizedScrollMethod(event);
      onBodyScroll(event);
      onHeaderScroll(event);
    },
    [onBodyScroll, onHeaderScroll]
  );

  const onTableHeaderScroll = useCallback(
    event => {
      onBodyScroll(event);
      onHeaderScroll(event);
    },
    [onBodyScroll, onHeaderScroll]
  );

  const Outer = useMemo(
    () =>
      forwardRef<HTMLDivElement, ListProps>((props, ref) => {
        const { onScroll, style } = props;
        const restStyles = omit(style || {}, ['width', 'height']);

        return (
          <div
            {...{ ref, ...props }}
            {...getTableBodyProps({
              style: {
                ...restStyles,
                overflow: preventOverflow ? 'none' : restStyles.overflow,
              },
            })}
            className={tableBody}
            id={TABLE_BODY_ID + customTableId}
            onScroll={event => onTableBodyScroll(event, onScroll)}
          />
        );
      }),
    //please, do not add any other dependencies here if not necessary, especially getTableBodyProps
    // otherwise you'll spend hours trying to fix table virtualization
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onTableBodyScroll, isVisibleBulkSelectionColumn]
  );

  const currentRowsIds = useMemo(
    () =>
      rows.map(({ id, values, ...row }) => {
        const originalRow = (row.original as MappedObject<any>) || {};
        return originalRow.id !== undefined
          ? Number(originalRow.id)
          : Number(values.id || id);
      }),
    [rows]
  );

  const HeaderCell = ({
    column,
    provided,
    snapshot,
  }: {
    column: HeaderGroup<object>;
    provided: DraggableProvided;
    snapshot: DraggableStateSnapshot;
  }) => {
    const {
      searchKey,
      isSorted,
      isSortedDesc,
    } = column as CustomColumnProperties;

    return (
      <div
        {...(!editMode && column.canSort
          ? column.getSortByToggleProps({
              title: undefined,
            })
          : {})}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        style={{
          ...getItemStyle(snapshot, provided.draggableProps),
          ...(snapshot.isDragging
            ? generateDraggableTransform(
                snapshot.isDragging,
                provided.draggableProps.style as DraggingStyle,
                tableHeaderRowOffsets?.top,
                tableHeaderRowOffsets?.left,
                0
              )
            : {}),
        }}
        className={
          column.canSort && !columnResizing.isResizingColumn
            ? clsx([
                classes.sortableTableHeader,
                {
                  disabled: disabledEdition || preventInteractionWithColumns,
                },
              ])
            : ''
        }
        data-testid={`${TABLE_SORTABLE_TABLE_HEADER_TESTID}${column.id}`}
      >
        <span
          className={clsx(classes.ellipsis, {
            [classes.searchWrapper]: !!searchKey,
          })}
        >
          <div
            className={clsx({
              [classes.labelWithSearch]: !!searchKey,
            })}
          >
            <TooltipString text={column.render('Header') as string}>
              {column.render('Header')}
            </TooltipString>
          </div>
          {!!searchKey && (
            <SearchInput
              {...{
                searchKey,
                handleSearchChange,
              }}
            />
          )}
        </span>
        <Sorter
          {...{ isSorted, isSortedDesc }}
          isVisible={!withoutSort && column.canSort && !editMode}
        />
        {editMode &&
          indexToAddColumns === undefined &&
          !unhidenableColumns.includes(column.id) && (
            <ButtonTransparent
              onClick={() => addColumnToHidden(column.id)}
              className={`${classes.removeColumnButton} ${buttonClasses.buttonTransparent}`}
              data-testid={REMOVE_COLUMN_TESTID}
            >
              <CloseIcon size={18} />
            </ButtonTransparent>
          )}
      </div>
    );
  };

  const sizes = useColumnStyles(
    headerGroups,
    (isVisibleBulkSelectionColumn ? BULK_SELECTION_COLUMN_WIDTH : 0) +
      (onActionCellClick ? 0 : -ACTIONS_COLUMN_WIDTH)
  );

  const wrapperRef = useRef<HTMLDivElement>(null);

  let fixedSizeListSize = tableBodyRef.current
    ? window.innerHeight - tableBodyRef?.current?.top
    : 600;
  if (preventOverflow) {
    fixedSizeListSize = pageSize * cellHeight;
  }

  return (
    <>
      <TableModals
        {...{
          state,
          ...modalsActions,
          defaultTableColumns,
          setHiddenColumns,
          setColumnOrder,
          hasChanges,
          resetResizing,
          setShouldShowConfirmation,
          setCancelConfigModalVisible,
        }}
      />
      <div className={classes.tablePagination} id={TABLE_PAGINATION_ID}>
        {withPagination && (
          <PaginationWrapper
            tableState={{
              ...state,
              columnResizing: composeResizingColumnWidths(
                columnResizing,
                initialState?.columnResizing
              ),
            }}
            {...{
              setCancelConfigModalVisible,
              setShouldShowConfirmation,
              listRef,
              hasChanges,
            }}
          />
        )}
      </div>
      {renderHeaderLimit && renderHeaderLimit()}
      <div
        className={classes.tableContainer}
        ref={wrapperRef}
        style={sizes}
        id={TABLE_WRAPPER}
      >
        <Loader
          spinning={loading || columnsConfigurationLoading}
          style={{
            minHeight: isInitialLoading ? '400px' : 'auto',
          }}
        >
          <span
            className={clsx({
              [classes.tableWithHeaderWrapper]: withBordersAroundTable,
            })}
          >
            <div
              className={clsx({ [classes.leftBorder]: withBordersAroundTable })}
            />
            <div
              className={clsx({
                [classes.tableWithHeaderContainer]: withBordersAroundTable,
              })}
            >
              <div
                {...getTableProps()}
                className={clsx([
                  classes.table,
                  classes.tableHeaderWrapper,
                  { [classes.noScrollbar]: !emptyTable },
                ])}
                id={TABLE_HEAD_ID + customTableId}
                style={{
                  height:
                    emptyTable && !customEmptyTable
                      ? EMPTY_TABLE_HEIGHT
                      : 'auto',
                }}
                onScroll={onTableHeaderScroll}
              >
                <div
                  className={clsx(classes.tableHeader, {
                    [globalClasses.disabledTextHighlight]: !!columnResizing.isResizingColumn,
                  })}
                >
                  {headerGroups.map((headerGroup, index) =>
                    bulkSelectionList.length > 0 ? (
                      <div
                        {...headerGroup.getHeaderGroupProps({
                          style: { minWidth },
                        })}
                        className={classes.tableBulkSelectionHead}
                        data-testid={TABLE_BULK_SELECTION_HEADER_TESTID}
                      >
                        <div className={tableClasses.flexAlignCenter}>
                          <BulkSelectionHeaderCheckbox
                            {...{ currentRowsIds }}
                          />
                          <BulkSelectionCountMessage />
                          <LimitAlert />
                        </div>
                        <BulkSelectionActionButton />
                      </div>
                    ) : (
                      <DragDropContext
                        onDragStart={onColumnDragStart}
                        {...{ onDragUpdate, onDragEnd }}
                        key={index}
                      >
                        <Droppable
                          droppableId='droppable'
                          direction='horizontal'
                        >
                          {droppableProvided => (
                            <div
                              {...headerGroup.getHeaderGroupProps({
                                style: {
                                  width: 'var(--table-row-width)',
                                },
                              })}
                              ref={droppableProvided.innerRef}
                              className={classes.tableHead}
                              id={TABLE_HEAD_ROW_ID}
                            >
                              {isVisibleBulkSelectionColumn && (
                                <BulkSelectionHeaderCheckbox
                                  {...{ currentRowsIds }}
                                />
                              )}
                              {headerGroup.headers.map((column, index) => (
                                <Draggable
                                  key={column.id}
                                  draggableId={column.id}
                                  index={index}
                                  isDragDisabled={
                                    !column.accessor ||
                                    !editMode ||
                                    indexToAddColumns !== undefined
                                  }
                                >
                                  {(provided, snapshot) => (
                                    <ResizeProvider
                                      {...{ wrapperRef, onColumnResize }}
                                      columnKey={column.id}
                                    >
                                      {({ cellRef, initDrag }) => (
                                        <div
                                          {...column.getHeaderProps({
                                            style: {
                                              minWidth:
                                                DEFAULT_COLUMNS_WIDTHS.minWidth,
                                              width: `var(${getColumnVarName(
                                                column.id
                                              )})`,
                                            },
                                          })}
                                          className={clsx(
                                            classes.tableHeadCell,
                                            {
                                              [classes.tableHeadCellDragging]:
                                                snapshot.isDragging,
                                            }
                                          )}
                                          data-testid={`${TABLE_SORTABLE_COLUMN_HEADER_TESTID}${column.id}`}
                                          ref={cellRef}
                                        >
                                          <HeaderCell
                                            {...{ provided, column, snapshot }}
                                          />
                                          <>
                                            {editMode && (
                                              <AddColumn
                                                onAddNewColumns={columns =>
                                                  onAddNewColumns(
                                                    columns,
                                                    index
                                                  )
                                                }
                                                {...{
                                                  onTogglePopover,
                                                  hiddenColumns,
                                                }}
                                                sourceIndex={index}
                                                visible={shouldBeVisible(index)}
                                                columnsWidth={headerGroup.headers.map(
                                                  ({ width }) =>
                                                    width ? Number(width) : 0
                                                )}
                                                columnsResizing={headerGroup.headers.map(
                                                  ({ isResizing }) => isResizing
                                                )}
                                                indexToAdd={indexToAddColumns}
                                              />
                                            )}
                                            {(onActionCellClick ||
                                              index <
                                                headerGroup.headers.length -
                                                  1) && (
                                              <Resizer
                                                onMouseDown={initDrag}
                                                className={clsx([
                                                  'resizer',
                                                  {
                                                    resizing: column.isResizing,
                                                    [globalClasses.noPaddingRight]: isDraggingColumn,
                                                  },
                                                ])}
                                                data-testid={`${TABLE_SORTABLE_RESIZER_TESTID}${column.id}`}
                                              />
                                            )}
                                          </>
                                        </div>
                                      )}
                                    </ResizeProvider>
                                  )}
                                </Draggable>
                              ))}
                              {onActionCellClick && (
                                <div
                                  className={clsx([
                                    tableClasses.tableCell,
                                    classes.tableHeadCell,
                                  ])}
                                  style={{
                                    minWidth: ACTIONS_COLUMN_WIDTH,
                                    marginLeft: 'auto',
                                  }}
                                  data-testid={`${TABLE_SORTABLE_RESIZER_TESTID}actions`}
                                >
                                  <div className={tableClasses.flexAlignCenter}>
                                    <span>
                                      <FormattedMessage
                                        id='misc.actions'
                                        defaultMessage='Actions'
                                      />
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    )
                  )}
                </div>
                {emptyTable &&
                  (customEmptyTable ? (
                    customEmptyTable
                  ) : (
                    <div
                      className={tableClasses.emptyTableWrapper}
                      style={{
                        maxWidth:
                          window.document.body.offsetWidth - sidebarWidth <
                            totalColumnsWidth || !columns.length
                            ? '100%'
                            : totalColumnsWidth,
                      }}
                      data-testid={TABLE_EMPTY_COLUMN_TESTID}
                    >
                      <EmptyData
                        title={emptyDataTitle}
                        {...{ hasListPermissions }}
                        description={
                          getEmptyMessageDescription?.(
                            emptyTableDescription,
                            searchValue,
                            appliedFilters && withFilters
                          ) || emptyTableDescription
                        }
                      />
                    </div>
                  ))}
              </div>
              <div
                {...getTableProps()}
                className={clsx(classes.table, classes.filledTable)}
                data-testid={TABLE_BODY_TESTID}
              >
                <FixedSizeList
                  itemCount={itemCount}
                  itemSize={cellHeight}
                  height={fixedSizeListSize}
                  width={totalColumnsWidth}
                  outerElementType={Outer}
                  ref={listRef}
                >
                  {RenderRow}
                </FixedSizeList>
              </div>
            </div>
            <div
              className={clsx({
                [classes.rightBorder]: withBordersAroundTable,
              })}
            />
          </span>
        </Loader>
      </div>
    </>
  );
};

export default typedMemo(Table);
