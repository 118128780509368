import { createUseStyles } from 'react-jss';

const useTooltipStyles = createUseStyles({
  toolTip: {
    color: 'white',
    'background-color': 'black',
  },
  icon: { textDecoration: 'underline', color: 'blue' },
});

export default useTooltipStyles;
