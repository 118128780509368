import { LICENSE_DATA_STORAGE_KEY } from '../getPspdfkitLicense.consts';
import { LicenseData } from '../types';

export const loadLicenseData = (): LicenseData | null => {
  const licenseData = localStorage.getItem(LICENSE_DATA_STORAGE_KEY);

  if (!licenseData) {
    return null;
  }

  return JSON.parse(licenseData);
};
