import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CustomCells from 'utils/Enums/CustomCells';
import clsx from 'clsx';
import useTextStyles from 'components/lib/Text/styles';
import { getCurrentTablePreferenceFilter } from 'store/selectors/preferencesSelectors';
import sortBy from 'lodash/sortBy';
import { AutocompleteSelectValue } from 'utils/types/selectInput.types';

export const useHighlightedAppliedFilterValue = (
  text: string[],
  highlightedColumnKey?: CustomCells
): { rawText: string; highlightedText: string | JSX.Element } => {
  const appliedFilters = useSelector(getCurrentTablePreferenceFilter);
  const styles = useTextStyles({ fontWeight: 700 });
  const columnFilter = appliedFilters.find(
    ({ column }) => column === highlightedColumnKey
  );
  const initialText = useMemo(() => text.join(', '), [text]);

  if (!highlightedColumnKey || !columnFilter)
    return {
      rawText: initialText,
      highlightedText: initialText,
    };

  const {
    predicateValues: { value: filterValue },
  } = columnFilter.value;

  const filteredValues = (Array.isArray(filterValue)
    ? filterValue
    : [filterValue]) as AutocompleteSelectValue[];

  const filteredValueLabels = filteredValues.map(item => {
    if (typeof item === 'string' || typeof item === 'number') {
      return item;
    }

    return item?.label || '';
  });

  const textWithoutFilteredValues = text.filter(
    word => !filteredValueLabels.includes(word)
  );

  const allLabelsWithFilteredFirst = [
    ...sortBy(filteredValueLabels.filter(value => text.includes(`${value}`))),
    ...textWithoutFilteredValues,
  ];

  const highlightedText = allLabelsWithFilteredFirst.length ? (
    <>
      {allLabelsWithFilteredFirst.map((word, index) => (
        <span key={`${word}-${index}`}>
          <span
            className={clsx({
              [styles.highlighted]: filteredValueLabels.includes(word),
            })}
          >
            {word}
          </span>
          {index !== allLabelsWithFilteredFirst.length - 1 ? ', ' : ''}
        </span>
      ))}
    </>
  ) : (
    initialText
  );

  return {
    rawText: allLabelsWithFilteredFirst.join(', '),
    highlightedText,
  };
};
