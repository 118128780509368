import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const OPACITY = 19;

const useAddColumnStyles = createUseStyles((theme: ThemeState) => {
  return {
    columnIndicator: {
      color: theme['error-color-pink'],
      transition: 'color 0.2s ease-in-out',
      '&:hover': {
        color: theme['error-color-dark-pink'],
      },
    },
    disabledColumnIndicator: {
      color: theme['error-color-pink'],
      cursor: 'default',
    },
    wrapper: {
      width: 330,
    },
    heading: { fontWeight: 'bold', marginBottom: 25 },
    checkbox: { marginBottom: 20, marginLeft: '0px !important' },
    buttonsWrapper: {
      marginTop: 10,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    popoverWrapper: {
      position: 'absolute',
      top: -10,
      right: -12,
      zIndex: 30,
    },
    pin: { padding: 0 },
    scroll: {
      overflow: 'auto',
      maxHeight: 400,
    },
    checkboxList: {
      display: 'flex',
      flexDirection: 'column',
    },
  };
});

export const useColumnsAmmountAlertStyles = createUseStyles(
  (theme: ThemeState) => {
    return {
      alert: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start !important',
        padding: '6px 9px 6px 7px',
        backgroundColor: `${theme['error-color'] + OPACITY} !important`,
        marginTop: -2,
        marginBottom: 7,
        overflow: 'initial !important',
        '& svg': {
          position: 'initial',
          height: 18,
          color: `${theme['error-color']} !important`,
        },
        '& span': {
          color: `${theme['error-color']} !important`,
        },
      },
    };
  }
);
export default useAddColumnStyles;
