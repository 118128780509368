import React from 'react';
import InPlaceEditCheckbox from 'components/InPlaceEditControls/InPlaceEditCheckbox';
import { mapCustomWidgetPropsToBaseInPlaceEditProps } from './utils';
import { Widget } from 'alx-dynamic-form';
import { Provider } from 'react-redux';
import { store } from 'store/store';

const AdaptedInPlaceEditCheckbox: Widget = widgetProps => {
  const baseInPlaceEditProps = mapCustomWidgetPropsToBaseInPlaceEditProps<
    boolean
  >(widgetProps);

  return (
    <Provider store={store}>
      <InPlaceEditCheckbox {...{ ...baseInPlaceEditProps }} />
    </Provider>
  );
};

export default AdaptedInPlaceEditCheckbox;
