import ButtonType from '../Enums/ButtonType';
import {
  ButtonPrimary,
  ButtonDanger,
  Button,
  ButtonLink,
  ButtonWarning,
  ButtonSecondary,
} from 'components/lib/Button';

const getButtonByType = (buttonType?: ButtonType) => {
  switch (buttonType) {
    case ButtonType.Danger:
      return ButtonDanger;
    case ButtonType.Link:
      return ButtonLink;
    case ButtonType.Primary:
      return ButtonPrimary;
    case ButtonType.Secondary:
      return ButtonSecondary;
    case ButtonType.Warning:
      return ButtonWarning;
    default:
      return Button;
  }
};

export default getButtonByType;
