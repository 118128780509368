import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { TaskFormFields, TaskFormType } from './hooks/types';
import useTaskForm from './hooks/useTaskForm';
import { SelectOption as Option } from 'components/lib/Select';
import FormField from 'pages/TaskTemplates/components/FormField';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import { FormattedMessage, useIntl } from 'react-intl';
import TextInput from 'components/Inputs/TextInput';
import { Col } from 'components/lib/Grid';
import FAutocompleteSelect from 'components/AutocompleteSelect/FAutocompleteSelect';
import { AutocompleteSelectValue } from 'utils/types/selectInput.types';
import { ButtonSecondaryOutlined, ButtonTertiary } from 'components/lib/Button';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import {
  BUTTON_CANCEL_TESTID,
  BUTTON_SUBMIT_TESTID,
  TASK_FORM,
  UPDATE_OBJECT_RECORD_TASK_TYPE_OPTION,
} from 'utils/testIds';
import { PlusIcon } from 'components/Icon';
import useTasksPanelStyles from '../styles';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { useTasksPanelContext } from '../TasksPanelModeContext';
import { TasksPanelMode } from '../types';
import CancelCreationModals from './CancelCreationModals';
import useFormHeader from 'components/FormHeader/hooks';
import DueDateFormSection from './DueDateFormSection';
import InstructionsSection from './InstructionsSection';
import StagesAssigmentSection from './StagesAssignmentSection';
import { DueDateOptions } from './types';
import { Loader } from 'components/lib/Loader';
import ErrorComponent from 'components/ErrorComponent';
import { useTaskOptions } from './hooks/useTaskOptions';
import Select from 'components/Inputs/Select';
import { TaskTypes } from 'pages/TaskTemplates/enums';
import useVerifyIfObjectExists from './hooks/useVerifyIfObjectExists';

const TaskForm = () => {
  const intl = useIntl();
  const classes = useTasksPanelStyles({});
  const {
    selectedResource: {
      record: {
        recordId = undefined,
        permissions: {
          tasks: { create: taskCreatePermission = false } = {},
          edit: editRecordPermission = false,
        } = {},
      } = {},
      objectClassId,
    } = {},
  } = useSelectedResourceContext();
  const { setMode } = useTasksPanelContext();
  const { toggleCancelModal, ...modalProps } = useFormHeader();
  const [dueDateOption, setDueDateOption] = useState<DueDateOptions>(
    DueDateOptions.NoDueDate
  );

  const changePanelToList = (dirty?: boolean) => {
    if (!dirty) setMode(TasksPanelMode.List);
    else toggleCancelModal(true);
  };

  const { initialValues, onSubmit } = useTaskForm(recordId, changePanelToList);
  const {
    buildValidationSchema,
    rawValidationSchema,
    options,
    optionsLoading,
    optionsError,
  } = useTaskOptions();

  const [taskTemplate, setTaskTemplate] = useState<AutocompleteSelectValue[]>();
  const [autocompleteUrl, setAutocompleteUrl] = useState('');

  const { isLoading, doesExist } = useVerifyIfObjectExists({
    validate: editRecordPermission && taskCreatePermission,
    autocompleteUrl: options[TaskTypes.RecordUpdate]?.autocomplete,
    replaceValues: {
      '{object_id}': objectClassId ?? '0',
    },
  });

  useEffect(() => {
    const formAutocompleteUrl = options[TaskTypes.Form]?.autocomplete;

    if (!autocompleteUrl && formAutocompleteUrl)
      setAutocompleteUrl(formAutocompleteUrl);
  }, [options, autocompleteUrl]);

  const renderTaskTypeOptions = useMemo(() => {
    const [completeStandaloneform, updateObjectRecord] =
      options?.task_type?.values ?? [];

    if (!completeStandaloneform || !updateObjectRecord) {
      return;
    }

    return (
      <>
        <Option
          key={completeStandaloneform.value}
          value={completeStandaloneform.value}
        >
          {GlobalIntlSingleton.dynamicFormatMessage({
            id: `tasksPanel.${completeStandaloneform.value}`,
          })}
        </Option>
        <Option
          key={updateObjectRecord.value}
          value={updateObjectRecord.value}
          disabled={!doesExist}
          data-testid={UPDATE_OBJECT_RECORD_TASK_TYPE_OPTION}
        >
          {GlobalIntlSingleton.dynamicFormatMessage({
            id: `tasksPanel.${updateObjectRecord.value}`,
          })}
        </Option>
      </>
    );
  }, [options, doesExist]);

  const handleAutocompleteOptionChange = (
    options: AutocompleteSelectValue | AutocompleteSelectValue[]
  ) => {
    // here only single option is allowed
    if (!Array.isArray(options)) setTaskTemplate([options]);
  };

  const onTaskTypeChange = useCallback(
    (e: TaskTypes) => {
      setAutocompleteUrl(
        options[e].autocomplete?.replace('{object_id}', objectClassId ?? '0') ||
          ''
      );
      setTaskTemplate(undefined);
    },
    [options, objectClassId]
  );

  if (optionsError) return <ErrorComponent error={optionsError.status} />;

  return (
    <Loader spinning={optionsLoading || isLoading}>
      {!optionsLoading && !isLoading ? (
        <Formik<TaskFormType>
          enableReinitialize
          {...{
            initialValues,
            onSubmit,
          }}
          validationSchema={buildValidationSchema()}
        >
          {({
            handleSubmit,
            errors,
            isSubmitting,
            dirty,
            values: { due_date: dueDate },
          }) => (
            <div className={classes.formWrapper} data-testid={TASK_FORM}>
              <div>
                <FormField>
                  <Col span={24}>
                    <FormLabel
                      bolded={false}
                      required={
                        rawValidationSchema[TaskFormFields.Name]?.required
                      }
                    >
                      <FormattedMessage
                        id='tasksPanel.form.taskName'
                        defaultMessage='Task name'
                      />
                    </FormLabel>
                    <TextInput
                      name={TaskFormFields.Name}
                      minLength={
                        rawValidationSchema[TaskFormFields.Name]?.min_length
                      }
                      maxLength={
                        rawValidationSchema[TaskFormFields.Name]?.max_length
                      }
                    />
                  </Col>
                </FormField>
                <FormField>
                  <Col span={24}>
                    <FormLabel
                      bolded={false}
                      required={
                        rawValidationSchema[TaskFormFields.TaskType]?.required
                      }
                    >
                      <FormattedMessage
                        id='tasksPanel.form.taskType'
                        defaultMessage='Task type'
                      />
                    </FormLabel>
                    <Select
                      name={TaskFormFields.TaskType}
                      onChange={onTaskTypeChange}
                    >
                      {renderTaskTypeOptions}
                    </Select>
                  </Col>
                </FormField>
                <FormField>
                  <Col span={24}>
                    <FormLabel
                      bolded={false}
                      required={
                        rawValidationSchema[TaskFormFields.TaskTemplate]
                          ?.required
                      }
                    >
                      <FormattedMessage
                        id='tasksPanel.form.taskTemplate'
                        defaultMessage='Task template'
                      />
                    </FormLabel>
                    <FAutocompleteSelect
                      key={autocompleteUrl}
                      name={TaskFormFields.TaskTemplate}
                      value={taskTemplate}
                      placeholder={intl.formatMessage({
                        id: 'tasksPanel.form.taskTemplatePlaceholder',
                        defaultMessage: 'Search for task templates',
                      })}
                      autocompleteUrl={autocompleteUrl}
                      onChange={handleAutocompleteOptionChange}
                      withBoldedSearchPhrase
                    />
                  </Col>
                </FormField>
                <DueDateFormSection
                  {...{ rawValidationSchema, dueDateOption, setDueDateOption }}
                />
                <StagesAssigmentSection {...{ options }} />
                <InstructionsSection />
              </div>
              <div className={classes.buttonsWrapper}>
                <ButtonSecondaryOutlined
                  onClick={() => changePanelToList(dirty)}
                  data-testid={BUTTON_CANCEL_TESTID}
                >
                  <FormattedMessage id='misc.cancel' defaultMessage='Cancel' />
                </ButtonSecondaryOutlined>
                <ButtonTertiary
                  disabled={
                    Object.keys(errors).length > 0 ||
                    isSubmitting ||
                    !dirty ||
                    (dueDateOption === DueDateOptions.DueDate && !dueDate)
                  }
                  onClick={() => handleSubmit()}
                  loading={isSubmitting}
                  data-testid={BUTTON_SUBMIT_TESTID}
                  icon={<PlusIcon size={9} />}
                >
                  <FormattedMessage id='misc.add' defaultMessage='Add' />
                </ButtonTertiary>
              </div>
              <CancelCreationModals {...{ ...modalProps, toggleCancelModal }} />
            </div>
          )}
        </Formik>
      ) : (
        <div className={classes.formContent} />
      )}
    </Loader>
  );
};

export default TaskForm;
