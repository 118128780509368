import { RootState } from 'store/reducers';

export const selectSequences = (state: RootState) =>
  state.sequences.allIds.map(id => {
    const process = state.sequences.byId[id];

    return {
      ...process,
      key: process.id,
    };
  });

export const totalSequences = (state: RootState) => {
  return state.sequences.total;
};

export const filteredSequencesCount = (state: RootState) => {
  return state.sequences.filtered;
};

export const getSequencesColumns = (state: RootState) => {
  return state.sequences.columns;
};

export const getSequencesSelectedRow = (state: RootState) => {
  return state.sequences?.selectedRow;
};

export const getSequenceDefaultTaskAssignees = (state: RootState) => {
  return state.sequences?.defaultTaskAssignees;
};
