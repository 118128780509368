import { combineReducers, Reducer } from 'redux';

import auth from './authReducer';
import { AuthAction } from 'store/actions/authActions';
import { PermissionsAction } from 'store/actions/permissionsActions';
import permissions from './permissionsReducer';
import { SequencesAction } from 'store/actions/sequencesActions';
import sequences from './sequencesReducer';
import { PreferencesAction } from 'store/actions/preferencesActions';
import preferences from './preferencesReducer';
import { GlobalPreferencesAction } from 'store/actions/globalPreferencesActions';
import globalPreferences from './globalPreferencesReducer';
import { TaskTemplatesAction } from 'store/actions/taskTemplatesActions';
import taskTemplates from './taskTemplatesReducer';
import { ObjectsAction } from 'store/actions/objectRecordsActions';
import objectRecords, { ObjectRecordsState } from './objectRecordsReducer';
import objectRecord, { ObjectRecordState } from './objectRecordReducer';
import { FiltersAction } from 'store/actions/filtersActions';
import filters from './filtersReducer';
import { storeActions } from 'store/actions/storeActions';
import { RESET_STORE } from 'store/constants/store.consts';
import { TaskGroupsAction } from 'store/actions/taskGroupsActions';
import taskGroups from './taskGroupsReducer';
import users from './usersReducer';
import members from './membersReducer';
import userGroups from './userGroupsReducer';
import singleSignOn from './singleSignOnReducer';
import authenticationObjects from './authenticationObjectsReducer';
import flexLayout from './flexLayoutReducer';
import { SidebarActions } from 'store/actions/flexLayoutActions';
import { ObjectClassesAction } from 'store/actions/objectClassesActions';
import objectClasses, { ObjectClasses } from './objectClassesReducer';
import objectClassesFields, {
  ObjectClassFieldState,
} from './objectClassesFieldsReducer';
import misc from './miscReducer';
import { MiscAction } from 'store/actions/miscActions';
import { UsersAction } from 'store/actions/usersActions';
import roles from './rolesReducer';
import { RolesAction } from 'store/actions/rolesActions';
import { FormsAction } from 'store/actions/formsActions';
import { PermissionsState } from 'utils/types/api/permissions.types';
import { Task, TaskTemplate } from 'utils/types/api/tasks.types';
import { AuthState } from 'utils/types/api/auth.types';
import { FiltersState } from './types/filters.types';
import { MiscState } from './types/misc.types';
import { PreferencesState } from './types/preferences.types';
import { GlobalPreferencesState } from './types/globalPreferences';
import { Role } from 'utils/types/api/roles.types';
import { CommonTableState } from 'utils/types/api/table.types';
import { TaskGroup } from 'utils/types/api/taskGroups.types';
import { User } from 'utils/types/api/users.types';
import { Form } from 'utils/types/api/forms.types';
import forms from './formsReducer';
import { MessageTemplatesAction } from 'store/actions/messageTemplatesActions';
import { MessageTemplate } from 'utils/types/api/messageTemplates.types';
import messageTemplates from './messageTemplatesReducer';
import emailCollectors from './emailCollectorsReducer';
import { EmailCollectorsAction } from 'store/actions/emailCollectorsActions';
import { EmailCollector } from 'utils/types/api/emailCollectors.types';
import { FlexLayoutState } from './types/flexLayout.types';
import { ObjectClassesFieldsAction } from '../actions/objectClassesFieldsActions';
import { UserGroupsAction } from '../actions/userGroupsActions';
import { UserGroup } from '../../utils/types/api/userGroups.types';
import { SingleSignOn } from 'utils/types/api/singleSignOn.types';
import { SingleSignOnAction } from 'store/actions/singleSignOnActions';
import { AuthenticationObjects } from 'utils/types/api/authenticationObjects.types';
import { AuthenticationObjectsAction } from 'store/actions/authenticationObjectsActions';
import { MembersAction } from 'store/actions/membersActions';
import { Member } from 'utils/types/api/members.types';
import objectClassPermissions from './objectClassPermissionsReducer';
import { ObjectClassPermissionsActions } from 'store/actions/objectClassPermissionsActions';
import { ObjectClassPermissionsState } from './types/objectClassPermissions';
import { TaskAction } from 'store/actions/taskActions';
import { TaskState } from './types/task.types';
import task from './taskReducer';
import { TasksAction } from 'store/actions/tasksActions';
import tasks from './tasksReducer';
import tasksAssignees from './tasksAssigneesReducer';
import { ObjectRecordAction } from 'store/actions/objectRecordActions';
import { SequencesReducerState } from 'utils/types/api/sequences.types';
import { TasksAssigneesState } from './types/tasksAssignees.types';
import { TasksAssigneesAction } from 'store/actions/tasksAssigneesActions';
import { ClassFormsAction } from 'store/actions/classFormsActions';
import { ClassForm } from 'utils/types/api/classForms.types';
import classForms from './classFormsReducer';
import selectedSequence from './selectedSequenceReducer';
import { SelectedSequenceState } from './types/selectedSequence.types';
import { SelectedSequenceAction } from 'store/actions/selectedSequenceActions';
import documentTemplates from './documentTemplateReducer';
import documentTemplatesPanel from './documentTemplatePanelReducer';
import {
  DocumentTemplate,
  DocumentTemplatePanel,
} from 'utils/types/api/documentTemplate.types';
import { DocumentTemplatesAction } from 'store/actions/documentTemplatesActions';
import { DocumentTemplatesPanelAction } from 'store/actions/documentTemplatesPanelActions';
import { CustomThemeAction } from 'store/actions/customThemeActions';
import customTheme from './customThemeReducer';
import { CustomThemeState } from './types/customTheme.types';
import formBuilder from './formBuilderReducer';
import childClasses from './childClassesReducer';
import { FormBuilderState } from './types/formBuilder.types';
import { FormBuilderActions } from 'store/actions/formBuilderActions';
import { ChildClassesActions } from 'store/actions/childClasses.actions';
import { ChildClassesState } from './types/childClasses.types';
import nestedObjectRecords from './nestedObjectRecordsReducer';
import { externalComponentsReducer as externalComponents } from './externalComponetsReducer';
import { NestedObjectsAction } from 'store/actions/nestedObjectRecordsActions';
import { NestedObjectRecordsState } from './types/nestedObjectRecords.types';
import { ExternalComponentsState } from './externalComponetsReducer';
import { ExternalComponentsActions } from 'store/actions/externalComponentsActions';
import { CustomComponent } from 'utils/types/api/customComponents.types';
import { CustomComponentsAction } from 'store/actions/customComponentsActions';
import customComponents from 'store/reducers/customComponentsReducer';
import { UserGroupPermissionsActions } from 'store/actions/userGroupPermissionsActions';
import { UserGroupPermissionsState } from './types/userGroupPermissions.types';
import userGroupPermissions from './userGroupPermissionsReducer';

export type RootAction =
  | AuthAction
  | PermissionsAction
  | SequencesAction
  | PreferencesAction
  | GlobalPreferencesAction
  | TaskTemplatesAction
  | ObjectsAction
  | ObjectRecordAction
  | FiltersAction
  | storeActions
  | TaskGroupsAction
  | SidebarActions
  | ObjectClassesAction
  | ObjectClassesFieldsAction
  | MiscAction
  | UsersAction
  | RolesAction
  | FormsAction
  | MessageTemplatesAction
  | EmailCollectorsAction
  | UserGroupsAction
  | SingleSignOnAction
  | AuthenticationObjectsAction
  | MembersAction
  | ObjectClassPermissionsActions
  | TaskAction
  | TasksAssigneesAction
  | TasksAction
  | ClassFormsAction
  | SelectedSequenceAction
  | DocumentTemplatesAction
  | DocumentTemplatesPanelAction
  | CustomThemeAction
  | FormBuilderActions
  | NestedObjectsAction
  | ChildClassesActions
  | ExternalComponentsActions
  | CustomComponentsAction
  | UserGroupPermissionsActions;

export interface RootState {
  auth: AuthState;
  permissions: PermissionsState;
  sequences: SequencesReducerState;
  globalPreferences: GlobalPreferencesState;
  preferences: PreferencesState;
  taskTemplates: CommonTableState<TaskTemplate>;
  objectRecords: ObjectRecordsState;
  objectRecord: ObjectRecordState;
  filters: FiltersState;
  taskGroups: CommonTableState<TaskGroup>;
  flexLayout: FlexLayoutState;
  objectClasses: ObjectClasses;
  objectClassesFields: ObjectClassFieldState;
  objectClassPermissions: ObjectClassPermissionsState;
  misc: MiscState;
  nestedObjectRecords: NestedObjectRecordsState;
  users: CommonTableState<User>;
  userGroups: CommonTableState<UserGroup>;
  roles: CommonTableState<Role>;
  forms: CommonTableState<Form>;
  messageTemplates: CommonTableState<MessageTemplate>;
  members: CommonTableState<Member>;
  emailCollectors: CommonTableState<EmailCollector>;
  singleSignOn: CommonTableState<SingleSignOn>;
  authenticationObjects: CommonTableState<AuthenticationObjects>;
  task: TaskState;
  selectedSequence: SelectedSequenceState;
  tasksAssignees: TasksAssigneesState;
  tasks: CommonTableState<Task>;
  classForms: CommonTableState<ClassForm>;
  documentTemplates: CommonTableState<DocumentTemplate>;
  documentTemplatesPanel: CommonTableState<DocumentTemplatePanel>;
  customTheme: CustomThemeState;
  formBuilder: FormBuilderState;
  childClasses: ChildClassesState;
  externalComponents: ExternalComponentsState;
  customComponents: CommonTableState<CustomComponent>;
  userGroupPermissions: UserGroupPermissionsState;
}

const appReducer = combineReducers<RootState>({
  auth,
  permissions,
  sequences,
  globalPreferences,
  preferences,
  taskTemplates,
  objectRecords,
  objectRecord,
  filters,
  taskGroups,
  flexLayout,
  objectClasses,
  objectClassesFields,
  objectClassPermissions,
  misc,
  users,
  userGroups,
  members,
  roles,
  forms,
  messageTemplates,
  emailCollectors,
  singleSignOn,
  authenticationObjects,
  task,
  tasksAssignees,
  tasks,
  classForms,
  selectedSequence,
  documentTemplates,
  documentTemplatesPanel,
  customTheme,
  formBuilder,
  childClasses,
  nestedObjectRecords,
  externalComponents,
  customComponents,
  userGroupPermissions,
});

const rootReducer: Reducer<RootState, RootAction> = (state, action) => {
  if (action.type === RESET_STORE) {
    // @ts-ignore
    state = {
      // we need "auth" empty values to let know for "redux-deep-persist" that tokens were changed (to logout from browser's tabs).
      // the "undefined" value will not be detected by "redux-deep-persist"
      auth: {
        access: '',
        refresh: '',
      },
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
