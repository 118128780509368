import { commonPredicates } from 'components/Filters/hooks/commonPredicates';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const useUserPredicates = () => {
  const intl = useIntl();

  return useMemo(
    () => ({
      exact: {
        label: intl.formatMessage({
          id: 'predicates.isEqual',
          defaultMessage: 'is equal to',
        }),
        value: 'exact',
        query: '={value}',
        args: ['value'],
      },
      not_exact: {
        label: intl.formatMessage({
          id: 'predicates.isNotEqual',
          defaultMessage: 'is not equal to',
        }),
        value: 'not_exact',
        query: '!={value}',
        args: ['value'],
      },
      in: {
        label: intl.formatMessage({
          id: 'predicates.isOneOf',
          defaultMessage: 'is one of',
        }),
        value: 'in',
        query: '__in={value}',
        args: ['value'],
        selectMultiple: true,
      },
      not_in: {
        label: intl.formatMessage({
          id: 'predicates.isNotOneOf',
          defaultMessage: 'is not one of',
        }),
        value: 'not_in',
        query: '__in!={value}',
        args: ['value'],
        selectMultiple: true,
      },
      ...commonPredicates(intl),
    }),
    [intl]
  );
};
