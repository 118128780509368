import { Action } from 'redux';
import { CLEAR_SELECTED_TASK, SELECT_TASK } from 'store/constants/task.consts';
import { SelectedTaskInfo } from 'store/reducers/types/task.types';

export interface SelectTaskAction extends Action<typeof SELECT_TASK> {
  payload: SelectedTaskInfo;
}

export type ClearSelectedTaskAction = Action<typeof CLEAR_SELECTED_TASK>;

export type TaskAction = SelectTaskAction | ClearSelectedTaskAction;

export const selectTask = (payload: SelectedTaskInfo): SelectTaskAction => {
  return {
    type: SELECT_TASK,
    payload,
  };
};

export const clearSelectedTask = (): ClearSelectedTaskAction => {
  return {
    type: CLEAR_SELECTED_TASK,
  };
};
