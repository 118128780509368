import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTasksPanelContext } from '../../TasksPanelModeContext';
import { TasksPanelMode } from '../../types';
import { Loader } from 'components/lib/Loader';
import useReassignTaskStyles from './styles';
import ReassignTaskHeader from './ReassignTaskHeader';
import { StageNames } from 'utils/types/api/tasks.types';
import BackToTaskListWrapper from './BackToTaskListWrapper';
import AddUsersPanel from 'pages/ObjectClasses/components/ObjectClassForm/ObjectClassPermissions/components/AddUsersPanel';
import { useTaskOptions } from '../hooks/useTaskOptions';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { AvatarItem } from 'components/lib/Avatar/types';
import { generatePath } from 'react-router';
import { TASK_STAGE_ASSIGNES } from 'utils/endpoints';
import { AccountTypeOptions } from 'pages/Users/enums';
import { selectLimitForUserGroup } from './utils';
import { ErrorCodes } from 'utils/types/errorResponse';
import { CustomErrorMessage } from 'pages/ObjectClasses/components/ObjectClassForm/ObjectClassPermissions/components/AddUsersPanel/types';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';

const AddAssignees = () => {
  const classes = useReassignTaskStyles({});
  const {
    setMode,
    task,
    setStage,
    stage: {
      name: stageName = StageNames.Stage1,
      assignees = [],
      fetchTask,
    } = {},
  } = useTasksPanelContext();
  const intl = useIntl();
  const { options } = useTaskOptions();

  const fetchOwners = async () => {
    if (!fetchTask) {
      return;
    }
    const { data: { stages: stage = [] } = {} } = await fetchTask();
    setStage({
      ...stage,
      fetchTask,
      name: stageName,
      assignees: stage?.[0]?.assignees?.users,
    });
  };

  const mappedStagesName: MappedObject<string, StageNames> = {
    [StageNames.Stage1]: intl.formatMessage({
      id: 'tasksPanel.stage_1',
      defaultMessage: 'Stage 1 ',
    }),
    [StageNames.Stage2]: intl.formatMessage({
      id: 'tasksPanel.stage_2',
      defaultMessage: 'Stage 2',
    }),
  };

  const checkAssignees = useCallback(
    (
      _: number,
      accountType: AccountTypeOptions,
      selectedUsers: AvatarItem[]
    ) => {
      if (!selectedUsers.length && !assignees.length) return false;

      const areSelectedUsers1TC =
        selectedUsers.length === 1 &&
        selectedUsers[0].accountType === AccountTypeOptions.OneTimeCompletion;
      const areAssignedUsers1TC =
        assignees.length === 1 &&
        assignees[0].accountType === AccountTypeOptions.OneTimeCompletion;

      return areSelectedUsers1TC || areAssignedUsers1TC
        ? true
        : accountType === AccountTypeOptions.OneTimeCompletion;
    },
    [assignees]
  );

  const generateCustomSuccessMsg = useCallback(
    (count: number, users?: AvatarItem[]) => {
      const firstUser = users?.[0];

      if (
        firstUser &&
        firstUser.accountType === AccountTypeOptions.OneTimeCompletion
      ) {
        const translatedStageName = GlobalIntlSingleton.dynamicFormatMessage({
          id: `tasksPanel.${stageName}`,
        });

        return intl.formatMessage(
          {
            id: 'tasksPanel.1TCAssignedTo',
            defaultMessage:
              '{userName} has been assigned to stage {stageName} of {taskName}',
          },
          {
            userName: `${firstUser.firstName} ${firstUser.lastName}`,
            stageName: translatedStageName,
            taskName: task?.name,
          }
        );
      }

      return intl.formatMessage(
        {
          id: 'tasksPanel.assigneesAddedTo',
          defaultMessage: `{count, plural, one {# assignee} other {# assignees}} added to {taskName}.`,
        },
        { count, taskName: task?.name }
      );
    },
    [intl, task, stageName]
  );

  const generateCustomErrorMsg = useCallback(
    ({ errorCode, itemsLimit, count }: CustomErrorMessage) =>
      errorCode === ErrorCodes.ERR_LIMIT_EXCEEDED && itemsLimit
        ? {
            title: intl.formatMessage(
              {
                id: 'tasksPanel.countNotAddedAssignees',
                defaultMessage: `{count, plural, one {# assignee} other {# assignees}} not added`,
              },
              { count }
            ),
            subtitle: intl.formatMessage(
              {
                id: 'tasksPanel.assigneesStageLimitReached',
                defaultMessage:
                  'Maximum of {itemsLimit} {itemsLimit, plural, one {assignee} other {assignees}} for this stage has been reached.',
              },
              { itemsLimit }
            ),
          }
        : undefined,
    [intl]
  );

  const itemsLimit = selectLimitForUserGroup(
    assignees,
    options?.stage1?.limit_assignees_one_time_completion,
    options?.stage1?.limit_assignees_standard
  );

  return (
    <BackToTaskListWrapper>
      <Loader spinning={false}>
        <div className={classes.reassigneTaskPanelWrapper}>
          <ReassignTaskHeader
            taskName={task?.name}
            recordId={task?.object_record}
          />
          <p className={classes.subtitle}>
            <FormattedMessage
              id='tasksPanel.assignUsersToStage'
              defaultMessage='Assign users to {stage}'
              values={{
                stage: mappedStagesName[stageName],
              }}
            />
          </p>
          <div className={classes.listWrapper}>
            <AddUsersPanel<AvatarItem[]>
              {...{ itemsLimit, fetchOwners }}
              postApiEndpoint={generatePath(TASK_STAGE_ASSIGNES, {
                id: task?.id,
                stageId: stageName,
              })}
              avatars={assignees}
              usersCount={assignees?.length}
              panelKey={FlexLayoutWindows.Tasks}
              autocompleteUrl={options?.stage1?.autocomplete || ''}
              onCancelClick={() => setMode(TasksPanelMode.Reassign)}
              postDataKey='users'
              checkCustomCondition={checkAssignees}
              customErrorMessage={generateCustomErrorMsg}
              customSuccessMessage={generateCustomSuccessMsg}
              alreadyAddedDisabledMessage={intl.formatMessage({
                id: 'misc.alreadyAnAssignee',
                defaultMessage: 'Already an assignee',
              })}
              defaultMode={TasksPanelMode.Reassign}
              intlLimitMessage={intl.formatMessage(
                {
                  id: 'tasksPanel.assigneesLimitExceeded',
                  defaultMessage:
                    'Maximum of {addedCount} new {addedCount, plural, one {user} other {users}} has been reached. There {existingCount, plural, one {is # user} other {are # users}} already assigned.',
                },
                {
                  addedCount:
                    itemsLimit === undefined
                      ? 0
                      : itemsLimit - assignees.length,
                  existingCount: assignees.length,
                }
              )}
            />
          </div>
        </div>
      </Loader>
    </BackToTaskListWrapper>
  );
};

export default AddAssignees;
