import React, { useCallback, useEffect, useState } from 'react';
import useTasksPanelStyles from './styles';
import TasksList from './components/TasksList';
import { StageDetails, TasksPanelMode } from './types';
import { TasksPanelContextProvider } from './TasksPanelModeContext';
import CreateTask from './components/CreateTask';
import ReassignTask from './components/ReassignTask';
import { Task } from 'utils/types/api/tasks.types';
import AddAssignees from './components/ReassignTask/AddAssignees';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';

const TasksPanel = () => {
  const classes = useTasksPanelStyles({});
  const [mode, setMode] = useState<TasksPanelMode>(TasksPanelMode.List);
  const [task, setTask] = useState<Task | undefined>();
  // later will be more stage so that's why we need to store it in context
  const [stage, setStage] = useState<StageDetails | undefined>();
  const {
    selectedResource: { record: { recordId = undefined } = {} } = {},
  } = useSelectedResourceContext();

  useEffect(() => {
    // resetting mode to initial after select new selected record
    setMode(TasksPanelMode.List);
  }, [recordId]);

  const Panel = useCallback(() => {
    switch (mode) {
      case TasksPanelMode.Create: {
        return <CreateTask />;
      }
      case TasksPanelMode.Reassign: {
        return <ReassignTask />;
      }
      case TasksPanelMode.AddAssignees: {
        return <AddAssignees />;
      }

      default:
        return <TasksList />;
    }
  }, [mode]);

  return (
    <div className={classes.container}>
      <TasksPanelContextProvider
        value={{ setMode, task, setTask, stage, setStage }}
      >
        <Panel />
      </TasksPanelContextProvider>
    </div>
  );
};

export default TasksPanel;
