import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useChildClassFormBuilderNoPermissionStyles = createUseStyles(
  (theme: ThemeState) => ({
    content: {
      color: theme['secondary-color'],
    },
  })
);

export default useChildClassFormBuilderNoPermissionStyles;
