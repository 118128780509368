export enum TasksQuickFilterOwnership {
  Default = '',
  Assignees = 'current_assignees',
  Owners = 'current_owner',
}

export enum TasksQuickFilterDates {
  Default = '',
  NoDueDate = 'isnull',
  Today = 'today',
  ThisWeek = 'this_week',
  WithinAWeek = 'within_week',
  Within30Days = 'within_30_days',
  Yesterday = 'yesterday',
  WithinTheLastWeek = 'within_last_week',
  WithinTheLast30Days = 'within_last_30_days',
  ExpiredTasks = 'expired_tasks',
}

export enum TasksQuickFilterDatesTypes {
  DueDate = 'due_date',
  ExpiryDate = 'expiry_date',
  Status = 'status',
}
