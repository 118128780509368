/*
Here we place all ant languages that have to be customized before importing by ant
*/

import { Locale } from 'antd/lib/locale-provider';
import antPl from 'antd/lib/locale/pl_PL';

const preparePolsihLanguage = () => {
  if (antPl.DatePicker?.lang !== undefined) {
    const customAntPl: Locale = {
      ...antPl,
      DatePicker: {
        ...antPl.DatePicker,
        lang: { ...antPl.DatePicker.lang, rangePlaceholder: ['Od', 'Do'] },
      },
    };

    return customAntPl;
  }

  return antPl;
};

const customizedLanguages = { pl: preparePolsihLanguage() };

export default customizedLanguages;
