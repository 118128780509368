import { ExclamationMarkSquare } from 'components/Icon';
import { Alert } from 'components/lib/Alert';
import React from 'react';
import { useIntl } from 'react-intl';
import { ALERT_TYPE_TESTID } from 'utils/testIds';
import { LimitExceededNotificationProps } from '../../types';
import { useLimitExceededNotificationStyles } from './styles';

const LimitExceededNotification = ({
  itemsCount,
  itemsLimit,
  customMessage,
}: LimitExceededNotificationProps) => {
  const intl = useIntl();
  const classes = useLimitExceededNotificationStyles({});

  const message =
    customMessage ??
    intl.formatMessage(
      {
        id: 'owners.selectedUsersLimitReached',
        defaultMessage:
          'Maximum of {limit} new {limit, plural, one {owner} other {owners}} can be selected ({ownersCount} {ownersCount, plural, one {owner} other {owners}} already added).',
      },
      {
        ownersCount: itemsCount,
        limit: itemsLimit !== undefined ? itemsLimit - itemsCount : 0,
      }
    );

  return (
    <div className={classes.wrapper} data-testid={`${ALERT_TYPE_TESTID}error`}>
      <Alert
        banner
        {...{ message }}
        icon={<ExclamationMarkSquare size={18} />}
        className={classes.alert}
      />
    </div>
  );
};

export default LimitExceededNotification;
