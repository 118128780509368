import { RootAction } from 'store/reducers';
import { Reducer } from 'redux';
import {
  SET_PERMISSIONS,
  SET_PERMISSIONS_LOADING,
  RESET_PERMISSIONS,
} from 'store/constants/permissions.consts';
import { PermissionsState } from 'utils/types/api/permissions.types';

export const initialState: PermissionsState = {
  loading: true,
  sequences: {
    list: false,
    create: false,
  },
  work_queue: {
    view: false,
  },
  object_records: {
    list: false,
    create: false,
    edit: false,
    view: false,
  },
  truth_tables: {
    list: false,
  },
  task_templates: {
    list: false,
    create: false,
    edit: false,
  },
  task_group_templates: {
    list: false,
    create: false,
    edit: false,
  },
  object_classes: {
    list: false,
    edit: false,
    create: false,
  },
  object_models: {
    create: false,
    edit: false,
    view: false,
    list: false,
  },
  users: {
    list: false,
    create: false,
    edit: false,
    view: false,
  },
  roles: {
    list: false,
    create: false,
    view: false,
    edit: false,
  },
  forms: {
    list: false,
  },
  message_templates: {
    list: false,
  },
  email_collectors: {
    list: false,
  },
  object_imports: {
    list: false,
  },
  work_queue_portal: {
    view: false,
  },
  outlook_settings: {
    view: false,
  },
  webchecks: {
    list: false,
  },
  relationships: {
    view: false,
  },
  document_store: {
    list: false,
  },
  document_templates: {
    list: false,
  },
  document_packs: {
    list: false,
  },
  connectors: {
    list: false,
  },
  authentication_objects: {
    list: false,
    create: false,
    delete: false,
    edit: false,
    view: false,
  },
  api_connectors_v2: {
    list: false,
  },
  documentation: {
    view: false,
  },
  api_connectors_v1: {
    list: false,
  },
  watchers: {
    list: false,
  },
  buckets: {
    list: false,
  },
  validators: {
    list: false,
  },
  user_groups: {
    list: false,
    create: false,
    delete: false,
    edit: false,
    view: false,
  },
  homepage_templates: {
    list: false,
  },
  saved_views: {
    list: false,
  },
  company_details: {
    edit: false,
    view: false,
  },
  standard_authentication: {
    edit: false,
  },
  single_sign_on: {
    list: false,
    create: false,
    delete: false,
    edit: false,
    view: false,
  },
  history_events: {
    list: false,
  },
  object_reallocation: {
    view: false,
  },
  task_reallocation: {
    view: false,
  },
  data_and_compliance: {
    view: false,
  },
  outbound_mail_setup: {
    view: false,
    edit: false,
  },
  notifications: {
    view: false,
  },
  message_queue: {
    list: false,
  },
  ui_config: {
    view: false,
    create: false,
  },
  custom_components: {
    list: false,
    create: false,
    edit: false,
    delete: false,
    view: false,
  },
};

const permissionsReducer: Reducer<PermissionsState, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_PERMISSIONS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }
    case SET_PERMISSIONS_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case RESET_PERMISSIONS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default permissionsReducer;
