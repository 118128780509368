import { FormMode } from 'utils/Enums/FormMode';
import {
  ObjectClassFieldTypes,
  UserMeta,
} from 'utils/types/api/objectClassesFields.types';
import { OptionSelect } from 'utils/types/selectInput.types';

export interface ClassFieldFormWrapperProps {
  mode: FormMode;
}

export enum ClassFieldFormFields {
  Label = 'label',
  Alias = 'alias',
  Type = 'type',
  DefaultValue = 'default_value',
  SelectionOptions = 'options',
  Users = 'users',
  MaxLength = 'max_length',
  MaxValue = 'max_value',
  MinValue = 'min_value',
  MaxValues = 'max_values',
  MinValues = 'min_values',
  Required = 'is_required',
  Identifier = 'is_identifier',
  Unique = 'is_unique',
  Order = 'order',
  Duplicates = 'has_duplicates',
  MaxFiles = 'max_num_of_files',
  MinUsersValues = 'min_users_values',
  MaxUsersValues = 'max_users_values',
}

export interface ClassFieldForm {
  [ClassFieldFormFields.Alias]: string;
  [ClassFieldFormFields.Label]: string;
  [ClassFieldFormFields.Type]: ObjectClassFieldTypes;
  [ClassFieldFormFields.DefaultValue]?: number | string;
  [ClassFieldFormFields.SelectionOptions]?: string[];
  [ClassFieldFormFields.Users]?: UserMeta[];
  [ClassFieldFormFields.MaxLength]?: number;
  [ClassFieldFormFields.MaxValue]?: number;
  [ClassFieldFormFields.MinValue]?: number;
  [ClassFieldFormFields.MaxValues]?: number;
  [ClassFieldFormFields.MinValues]?: number;
  [ClassFieldFormFields.MaxUsersValues]?: number;
  [ClassFieldFormFields.MinUsersValues]?: number;
  [ClassFieldFormFields.MaxFiles]?: number;
  [ClassFieldFormFields.Identifier]?: boolean;
  [ClassFieldFormFields.Unique]?: boolean;
  readonly [ClassFieldFormFields.Duplicates]?: boolean;
}

export interface ClassFieldDetailLocationState {
  id?: string;
  label?: string;
  className?: string;
}

export interface ClassFieldTypeOptions {
  label: Omit<OptionSelect, 'values'>;
  alias: Omit<OptionSelect, 'values'>;
  type: OptionSelect;
}

export enum FieldFormCategories {
  FieldProperties = 'fieldProperties',
  SelectionOptions = 'selectionOptions',
  Validation = 'validation',
}

export interface ObjectClassFieldLocationState {
  id?: number | string;
}

export const IdentifierAllowedTypes = [
  ObjectClassFieldTypes.String,
  ObjectClassFieldTypes.Email,
  ObjectClassFieldTypes.Phone,
  ObjectClassFieldTypes.Int,
  ObjectClassFieldTypes.Date,
  ObjectClassFieldTypes.Datetime,
  ObjectClassFieldTypes.Url,
];
