import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useHiddenMessageStyles = createUseStyles((theme: ThemeState) => {
  return {
    text: {
      color: theme['disabled-switch-gray'],
    },
  };
});

export default useHiddenMessageStyles;
