import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ViewFieldUsageWindow from 'components/ViewFieldUsageWindow';
import useFlexLayoutWindow from 'hooks/useFlexLayoutWindow';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import useTaskTemplatesViewUsage from './hooks';
import ErrorComponent from 'components/ErrorComponent';
import {
  getTaskTemplatesRow,
  getTaskTemplatesSelectedRow,
} from 'store/selectors/taskTemplatesSelectors';
import useClassFieldPropertiesStyles from 'pages/ObjectClasses/components/ObjectClassForm/components/NewAddField/styles';
import FormCategory from '../FormCategory';
import { createTestId } from 'utils/functions/createTestId';
import {
  VIEW_USAGE_LIST_ITEM_TESTID,
  VIEW_USAGE_SECTION_TESTID,
} from 'utils/testIds';

const TaskTemplateViewUsagePanel = () => {
  const intl = useIntl();
  const classes = useClassFieldPropertiesStyles({ isLight: true });
  const { closeComponent, isExternal } = useFlexLayoutWindow(
    FlexLayoutWindows.TaskTemplateViewUsage
  );
  const selectedRowId = useSelector(getTaskTemplatesSelectedRow);
  const {
    name = '',
    id,
    num_of_tasks_created: numberOfTasksCreated,
  } = useSelector(getTaskTemplatesRow(selectedRowId));

  const { displayConfig, loading, error } = useTaskTemplatesViewUsage(id);

  useEffect(() => {
    if (!selectedRowId) closeComponent(FlexLayoutWindows.TaskTemplateViewUsage);
  }, [closeComponent, selectedRowId]);

  if (error) return <ErrorComponent error={error?.status} />;

  const hasAnyUsage = !!displayConfig.flatMap(config => config.usage).length;

  return (
    <ViewFieldUsageWindow
      {...{ displayConfig, isExternal }}
      usageLabel={intl.formatMessage({
        id: 'taskTemplates.thisTemplateIsUsed',
        defaultMessage: 'This template is used in the following system areas:',
      })}
      noUsageLabel={intl.formatMessage({
        id: 'taskTemplates.thisTemplateIsNotUsed',
        defaultMessage: 'This template is not used anywhere in the system.',
      })}
      id={selectedRowId}
      label={name}
      isLoading={loading}
      forceHasUsage={!!numberOfTasksCreated}
      isLight
    >
      {(hasAnyUsage || !!numberOfTasksCreated) && (
        <div
          className={classes.sectionWrapper}
          data-testid={createTestId(VIEW_USAGE_SECTION_TESTID, 'tasks')}
        >
          <FormCategory
            noMarginTop
            collapsible
            isLight
            collapsibleContent={
              <div
                className={classes.textContainer}
                data-testid={VIEW_USAGE_LIST_ITEM_TESTID}
              >
                {numberOfTasksCreated ? (
                  <FormattedMessage
                    id='taskTemplates.createdWithThisTemplate'
                    defaultMessage='{tasksAmmount} tasks have been created with the template.'
                    values={{ tasksAmmount: numberOfTasksCreated }}
                  />
                ) : (
                  <FormattedMessage
                    id='taskTemplates.noTasksCreatedWithThisTemplate'
                    defaultMessage='No tasks have been created with the template.'
                  />
                )}
              </div>
            }
          >
            <span className={classes.sectionTitle}>
              <FormattedMessage id='misc.tasks' defaultMessage='Tasks' />
            </span>
          </FormCategory>
        </div>
      )}
    </ViewFieldUsageWindow>
  );
};

export default TaskTemplateViewUsagePanel;
