import React from 'react';
import { useActivateAccountData } from './hooks';
import { ActivateAccountProvider } from './components/ActivateAccountContext';
import LoaderOverlay from '../../components/LoaderOverlay';
import ActivateAccountPage from './components/ActivateAccountPage';
import { StatusCodes } from 'http-status-codes';
import { useParams } from 'react-router';
import { ActivateAccountParams } from './types';
import ErrorComponent from '../../components/ErrorComponent';
import useActivateAccountPageStyles from './styles';
import { ActivationTokenError } from './enums';
import ExpiredLinkPage from './components/ExpiredLinkPage';
import PendingRequestPage from './components/PendingRequestPage';
import { usePasswordRulesData } from 'hooks/usePasswordRulesData';

const ActivateAccount = () => {
  const classes = useActivateAccountPageStyles({});
  const { token } = useParams<ActivateAccountParams>();

  const {
    activateAccountData,
    loadingActivateAccountData,
    error,
  } = useActivateAccountData();
  const {
    passwordComplexityData,
    loadingPasswordRulesData,
  } = usePasswordRulesData({ fetchOnLoad: !!token });

  if (error?.messages?.error_code === ActivationTokenError.TokenExpired)
    return <ExpiredLinkPage />;

  if (error?.messages?.error_code === ActivationTokenError.TokenRequestPending)
    return <PendingRequestPage />;

  if (
    loadingActivateAccountData &&
    loadingPasswordRulesData &&
    !passwordComplexityData &&
    (!activateAccountData || !error)
  )
    return <LoaderOverlay />;

  if (!token)
    return (
      <div className={classes.wrapper}>
        <ErrorComponent error={StatusCodes.NOT_FOUND} />
      </div>
    );

  return (
    <ActivateAccountProvider
      value={{
        data: activateAccountData,
        options: passwordComplexityData,
      }}
    >
      <ActivateAccountPage />
    </ActivateAccountProvider>
  );
};

export default ActivateAccount;
