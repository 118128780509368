export const EMPTY_SCHEMA = {
  // "deps" is not standard property of jsonSchema, this if for custom implementation of dependency system
  deps: {},
  // "dependencies" is for original dependency system but it is not used
  dependencies: {},
  description: '',
  title: '',
  enabled: false,
  properties: {},
  required: [],
  schemaVersion: 0,
  type: 'object',
};

export const EMPTY_UI_SCHEMA = {
  'ui:order': ['*'],
  'ui:columns': [{ components: [] }],
  'ui:spacingAround': 0,
  'ui:spacingWithin': 0,
};
