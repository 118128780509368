import { OptionSelect } from 'utils/types/selectInput.types';
import ToastType from 'utils/Enums/ToastType';
import { apiCall } from 'utils/api';
import { USERS_LIST_AUTOCOMPLETE_IN } from 'utils/endpoints';
import {
  ObjectClassFieldTypes,
  UserMeta,
} from 'utils/types/api/objectClassesFields.types';
import { toast } from 'components/lib/toast/utils';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';

type ParsedOptions = MappedObject<Omit<OptionSelect, 'name'>>;
export type ParsedTypes = MappedObject<ParsedOptions, ObjectClassFieldTypes>;
interface TypeValues {
  value: ObjectClassFieldTypes;
  text: string;
  options: Omit<OptionSelect, 'name'> & { name: string }[];
}

export const parseTypes = (values: TypeValues[]) =>
  values.reduce<ParsedTypes>((result, { value: type, options }) => {
    const parseOptionsToObject = Array.isArray(options)
      ? options.reduce<ParsedOptions>(
          (items, { name, ...rest }) => ({ ...items, [name]: rest }),
          {}
        )
      : {};

    return {
      ...result,
      [type]: parseOptionsToObject,
    };
  }, {} as ParsedTypes);

export const getAvailableUsers = async (users?: UserMeta[]) => {
  const ids = users?.map(({ id }) => id) || []; //existing and deletetd user ids
  const url = `${USERS_LIST_AUTOCOMPLETE_IN}${ids.join(',')}`;
  try {
    const {
      data: { results }, //existing users
    } = await apiCall.get(url);

    return users?.filter(({ id }) =>
      results.some((u: UserMeta) => u.id === id)
    );
  } catch {
    return [];
  } finally {
    toast(
      {
        title: GlobalIntlSingleton.intl.formatMessage({
          id: 'misc.error',
          defaultMessage: 'Error!',
        }),
        subtitle: GlobalIntlSingleton.intl.formatMessage({
          id: 'errors.usersDeletetd',
          defaultMessage:
            'Users deleted from the system. Please review and update the user selection.',
        }),
      },
      ToastType.Error
    );
  }
};
