import React, {
  useContext,
  createContext,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import noop from 'lodash/noop';
import {
  RefetchResourceContextType,
  RefetchResourceState,
  RefetchResources,
  RefetchOptions,
} from './types';

/*
holds arrays of functions grouped by resource name that should be executed every time a resource changes
e.g. when record is updated we want to refresh its summary view and record listing immediately after update was submited.
to make a component listen to "refetchData" use useRefetchResource hook
*/

const initialRefetchResources: RefetchResourceState = {
  [RefetchResources.Records]: [],
  [RefetchResources.UserGroups]: [],
  [RefetchResources.ObjectClasses]: [],
  [RefetchResources.RecordAccess]: [],
};

export const RefetchResourceContext = createContext<RefetchResourceContextType>(
  {
    refetchResourcesRef: { current: initialRefetchResources },
    refetchData: noop,
  }
);

export const RefetchResourceContextProvider: React.FC = ({ children }) => {
  const refetchResourcesRef = useRef<RefetchResourceState>(
    initialRefetchResources
  );

  const refetchData = useCallback(
    (
      resourceName: RefetchResources,
      { refetchFnData, skipComponents }: RefetchOptions = {}
    ) => {
      refetchResourcesRef.current[resourceName].forEach(
        ({ componentName, refreshFunction }) => {
          if (
            skipComponents === componentName ||
            skipComponents?.includes(componentName)
          )
            return;

          refreshFunction(refetchFnData);
        }
      );
    },
    []
  );

  const contextData = useMemo(
    () => ({
      refetchResourcesRef,
      refetchData,
    }),
    [refetchData]
  );

  return (
    <RefetchResourceContext.Provider value={contextData}>
      {children}
    </RefetchResourceContext.Provider>
  );
};

export const useRefetchResourceContext = () =>
  useContext(RefetchResourceContext);
