import { Reducer } from 'redux';
import { RootAction } from '.';
import { TaskState } from './types/task.types';
import { CLEAR_SELECTED_TASK, SELECT_TASK } from 'store/constants/task.consts';

const initialState: TaskState = {
  taskInfo: undefined,
};

const taskReducer: Reducer<TaskState, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SELECT_TASK:
      return {
        ...state,
        taskInfo: action.payload,
      };
    case CLEAR_SELECTED_TASK:
      return {
        ...state,
        taskInfo: undefined,
      };
    default:
      return state;
  }
};

export default taskReducer;
