import { AdditionalFilterMap, DateAdditionalFilters } from './types';

// only needed because current requirements restrict these additional predicates to specific table type/field name combinations
export const ADDITIONAL_PREDICATE_MAP: AdditionalFilterMap = {
  tasks: {
    due_date: [
      DateAdditionalFilters.TODAY,
      DateAdditionalFilters.THIS_WEEK,
      DateAdditionalFilters.WITHIN_WEEK,
      DateAdditionalFilters.WITHIN_30_DAYS,
      DateAdditionalFilters.YESTERDAY,
      DateAdditionalFilters.WITHIN_LAST_WEEK,
      DateAdditionalFilters.WITHIN_LAST_30_DAYS,
    ],
    expiry_date: [
      DateAdditionalFilters.TODAY,
      DateAdditionalFilters.THIS_WEEK,
      DateAdditionalFilters.WITHIN_WEEK,
      DateAdditionalFilters.WITHIN_30_DAYS,
      DateAdditionalFilters.YESTERDAY,
      DateAdditionalFilters.WITHIN_LAST_WEEK,
      DateAdditionalFilters.WITHIN_LAST_30_DAYS,
    ],
  },
};
