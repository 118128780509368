import React from 'react';
import { useIntl } from 'react-intl';
import ConfirmationModal from 'components/ConfirmationModal';
import ErrorIcon from 'img/illustrations/error.svg';
import { ConfirmRemoveFileModalProps } from '../types';
import { CloseIcon } from 'components/Icon';
import { useToggle } from 'hooks/useToggle';
import { apiCall } from 'utils/api';
import errorToast from 'utils/functions/errorToast';

const ConfirmRemoveFileModal: React.FC<ConfirmRemoveFileModalProps> = ({
  fileIdToDelete,
  setFileIdToDelete,
  endpointUrl,
  updateRecord,
}) => {
  const intl = useIntl();
  const [
    isLoading,
    { toggleOff: stopLoading, toggleOn: startLoading },
  ] = useToggle();

  const onClose = () => {
    setFileIdToDelete(undefined);
  };

  const onConfirm = async () => {
    if (fileIdToDelete) {
      startLoading();

      try {
        await apiCall.delete(endpointUrl, { data: [fileIdToDelete] });
        await updateRecord();
      } catch {
        errorToast();
      } finally {
        stopLoading();
      }

      setFileIdToDelete(undefined);
    }
  };

  return (
    <ConfirmationModal
      {...{ isLoading, onClose, onConfirm }}
      title={intl.formatMessage({
        id: 'misc.deleteThisFilePermanently',
        defaultMessage: 'Delete this file permanently?',
      })}
      subtitle={intl.formatMessage({
        id: 'misc.youWillNotBeAbleToRetrieveIt',
        defaultMessage: 'You will not be able to retrieve it.',
      })}
      visible={!!fileIdToDelete}
      confirmationButtonLabel={intl.formatMessage({
        id: 'misc.delete',
        defaultMessage: 'Delete',
      })}
      withCancel
      image={ErrorIcon}
      confirmationButtonIcon={<CloseIcon size={18} />}
      isDangerAction
    />
  );
};

export default ConfirmRemoveFileModal;
