import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import {
  headerWrapper,
  panelWrapper,
  rightActionButton,
  getResultsInfo,
  getIdentifier,
} from 'styles/panelsStyles';

const useOwnersPanelStyles = createUseStyles((theme: ThemeState) => {
  return {
    panelWrapper,
    headerWrapper,
    identifier: getIdentifier(theme),
    resultsInfo: getResultsInfo(theme),
    container: {
      height: '100%',
      '& .ant-spin-nested-loading, .ant-spin-container': {
        height: '100%',
      },
    },
    limitWrapper: {
      width: '100%',
      '& > div > div': {
        width: '100%',
      },
    },
    ownersSearch: {
      width: '100%',
      borderRadius: 3,
      height: '35px !important',
    },
    permissionSelect: {
      width: '100%',
      '& .ant-select-selector': {
        height: '35px !important',
        padding: '1px 11px !important',
      },
    },
    inputsWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, calc(50% - 8px))',
      width: '100%',
      marginBottom: 10,
      gridGap: 16,
    },
    scrollableContainer: {
      overflow: 'auto',
      display: 'flex',
      height: '100%',
      position: 'relative',
    },
    buttonDisabled: {
      borderColor: `${theme['border-color-base']} !important`,
      background: `${theme['border-color-base']} !important`,
      '&:hover': {
        borderColor: `${theme['border-color-base']} !important`,
        background: `${theme['border-color-base']} !important`,
      },
    },
    buttonAddOwners: rightActionButton,
  };
});

export default useOwnersPanelStyles;
