export enum LogLevel {
  Verbose,
  Debug,
  Warning,
  Error,
}

/**
 * Default logger of the application. Log in your messages using various log levels.
 */
interface Logger {
  /**
   * Logs the message to the default output.
   * @param message what should be logged.
   * @param logLevel (default: debug) use consts like verbose, debug, warning, error to set the log level of the message.
   */
  log: (message: string, logLevel?: LogLevel) => void;
}

export const Logger: Logger = {
  log: (message: string, logLevel: LogLevel = LogLevel.Debug) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
      return;
    }

    switch (logLevel) {
      case LogLevel.Error:
        // eslint-disable-next-line no-console
        console.error(message);
        return;
      case LogLevel.Warning:
        // eslint-disable-next-line no-console
        console.warn(message);
        return;
      case LogLevel.Debug:
        // eslint-disable-next-line no-console
        console.log(message);
        return;
      default:
        // eslint-disable-next-line no-console
        console.debug(message);
        return;
    }
  },
};
