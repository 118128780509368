import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useFilterIndicatorStyles = createUseStyles((theme: ThemeState) => {
  return {
    filterIndicator: {
      backgroundColor: theme['primary-color-light'],
      cursor: 'pointer',
      display: 'flex',
      transition: 'background-color 0.3s ease',
      alignItems: 'center',
      lineHeight: 1.5,
      '&:hover': {
        backgroundColor: '#cce9f9',
      },
      marginLeft: 8,
    },
    filterIndicatorText: {
      color: theme['primary-color'],
      fontWeight: 600,
      fontSize: 14,
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 10,
    },
    iconContainer: {
      transition: 'background-color 0.3s ease',
      color: theme['primary-color'],
      marginLeft: 5,
      width: 17,
      height: 17,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 3,
      backgroundColor: 'transparent',
      marginRight: 10,
      '&:hover': {
        backgroundColor: '#8fcef1',
      },
    },
  };
});

export default useFilterIndicatorStyles;
