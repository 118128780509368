import React from 'react';
import { FormikInput, Input } from 'components/lib/Input';
import { TextInputProps } from '../types';
import useInputStyles from '../styles';
import useGlobalStyle from 'hooks/useGlobalStyle';
import { Field } from 'formik-antd';
import { useField } from 'formik';
import { FormikFormItem } from 'components/lib/Form';
import { useSetRequiredField, useTrimmedFunctions } from '../hooks';
import ErrorLabel from 'components/ErrorLabel';
import { TEXT_INPUT_TESTID } from 'utils/testIds';
import { useObfuscateInput } from './hooks';
import PasswordInput from '../PasswordInput';
import { FormattedMessage } from 'react-intl';
import { DisabledInput } from './DisabledInput';
import clsx from 'clsx';
import { autoCompleteOffProps } from '../consts';
import { onFocusNonAutoCompleteInput } from 'utils/functions/onFocusNonAutoCompleteInput';
import CopyText from 'components/CustomCell/CopyText';

const TextInput: React.FC<TextInputProps> = ({
  name,
  placeholder,
  className,
  wrapperClassName,
  disabled,
  readOnly,
  required,
  maxLength,
  handleOnBlur,
  onFocus,
  obfuscatePlaceholder,
  obfuscateWhenEmpty,
  obfuscateValueLength = 8,
  onKeyDown,
  cancelFunctions,
  inputRef,
  autoCompleteOff,
  showCopyText,
  copyTextMessage,
  copyTextSuccessMessage,
  copyButtonHeight,
  ...rest
}) => {
  const classes = useInputStyles({});
  const globalClasses = useGlobalStyle();
  const [{ value }, { touched, error }] = useField(name);
  const {
    handleBlur,
    handleFocus,
    isObfuscateFieldVisible,
    obfuscatedValue,
  } = useObfuscateInput(name, obfuscateWhenEmpty, obfuscateValueLength);

  //useEffect for handling error for trimmed value
  useSetRequiredField(name, required);

  const {
    handleOnChange: onChange,
    handleOnBlur: onBlur,
  } = useTrimmedFunctions(name, handleOnBlur);

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e);
    handleBlur();
  };

  const renderTextInput = () =>
    obfuscateWhenEmpty && isObfuscateFieldVisible ? (
      <PasswordInput
        visibilityToggle={false}
        onFocus={handleFocus}
        value={obfuscatedValue}
        {...{ name, className, wrapperClassName, autoCompleteOff }}
      />
    ) : (
      <FormikInput
        {...{
          value,
          name,
          className: clsx(className, {
            [classes.obfuscatePlaceholder]: obfuscatePlaceholder,
          }),
          placeholder: obfuscatePlaceholder
            ? '•'.repeat(obfuscateValueLength)
            : placeholder,
          required,
          maxLength,
          onChange,
          onKeyDown,
          ...(autoCompleteOff ? autoCompleteOffProps : {}),
        }}
        ref={inputRef}
        onBlur={handleInputBlur}
        autoFocus={obfuscateWhenEmpty}
        onFocus={element => {
          if (autoCompleteOff) onFocusNonAutoCompleteInput(element);

          if (onFocus) onFocus(element);
        }}
        //@ts-ignore
        data-testid={rest?.['data-testid'] || `${TEXT_INPUT_TESTID}${name}`}
      />
    );

  return (
    <FormikFormItem
      {...{ name }}
      className={globalClasses.inputWrapper}
      validateStatus={touched && error ? 'error' : undefined}
    >
      <Field {...{ name }}>
        {() => {
          if (disabled)
            return (
              <DisabledInput {...{ name, value, placeholder, className }} />
            );

          return readOnly ? (
            <div className={classes.readOnlyWrapper}>
              <Input
                readOnly
                {...{ value }}
                className={clsx([
                  classes.readonly,
                  globalClasses.disabledInput,
                  className,
                ])}
                data-testid={`${TEXT_INPUT_TESTID}${name}`}
              />
              {showCopyText && (
                <div className={classes.copyText}>
                  <CopyText
                    buttonHeight={copyButtonHeight}
                    textToCopy={value ?? ''}
                    hideTextToCopy={true}
                    successMessage={copyTextSuccessMessage ?? ''}
                    message={copyTextMessage ?? ''}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className={classes.inputWrapper}>
              {renderTextInput()}
              <div className={classes.addonsWrapper}>
                {required && <ErrorLabel />}
                {cancelFunctions && (
                  <span
                    onMouseEnter={cancelFunctions.cancelOn}
                    onMouseLeave={cancelFunctions.cancelOff}
                    onClick={cancelFunctions.cancelInputChange}
                    className={classes.cancelText}
                  >
                    <FormattedMessage
                      id='misc.cancel'
                      defaultMessage='Cancel'
                    />
                  </span>
                )}
              </div>
            </div>
          );
        }}
      </Field>
    </FormikFormItem>
  );
};

export default TextInput;
