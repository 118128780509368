import { useTaskOwnershipContext } from 'components/TaskDisplay/components/TaskOwnershipContext';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { getSelectedTask } from 'store/selectors/taskSelectors';
import { apiCall } from 'utils/api';
import { TASK_OWNERSHIP } from 'utils/endpoints';
import showDefaultErrorToast from 'utils/functions/showDefaultErrorToast';
import { TaskOwnershipProps } from './types';

const useTaskOwnership = ({
  taskId,
  stageId,
  owner,
}: Omit<TaskOwnershipProps, 'nextActions'>) => {
  const intl = useIntl();
  const selectedTask = useSelector(getSelectedTask);

  const {
    setOnConfirm,
    setCancelLabel,
    setShouldBeConfirmationDisplayed,
    setShouldBeAssignOwnerDisplayed,
    refetchData,
    setTitle,
    setConfirmLabel,
    setSubtitle,
  } = useTaskOwnershipContext();

  const onTakeOwnershipConfirm = useCallback(async () => {
    try {
      await apiCall.patch(
        generatePath(TASK_OWNERSHIP, {
          task_id: taskId,
          stage_id: stageId,
        })
      );
    } catch {
      showDefaultErrorToast();
    }

    if (refetchData) {
      refetchData();
    }

    if (selectedTask?.refetchTasksList) {
      selectedTask.refetchTasksList();
    }
  }, [refetchData, selectedTask, taskId, stageId]);

  const onClickAssignOwnership = useCallback(() => {
    setShouldBeAssignOwnerDisplayed(true);
  }, [setShouldBeAssignOwnerDisplayed]);

  const onGiveUpOwnershipConfirm = useCallback(async () => {
    try {
      await apiCall.delete(
        generatePath(TASK_OWNERSHIP, {
          task_id: taskId,
          stage_id: stageId,
        })
      );
    } catch {
      showDefaultErrorToast();
    } finally {
      if (refetchData) {
        refetchData();
      }
    }

    if (selectedTask?.refetchTasksList) {
      selectedTask.refetchTasksList();
    }
  }, [selectedTask, taskId, stageId, refetchData]);

  const onClickTakeOwnership = useCallback(async () => {
    if (owner) {
      setCancelLabel(
        intl.formatMessage({
          id: 'misc.cancel',
          defaultMessage: 'Cancel',
        })
      );
      setTitle(
        intl.formatMessage({
          id: 'tasks.makeYourselfOwner',
          defaultMessage: 'Make yourself the task owner?',
        })
      );
      setSubtitle(
        intl.formatMessage({
          id: 'tasks.makeYourselfOwnerSubtitle',
          defaultMessage:
            'This will remove the existing owner. The existing owner may no longer be able to complete this task.',
        })
      );
      setConfirmLabel(
        intl.formatMessage({
          id: 'tasks.makeMeOwnerButton',
          defaultMessage: 'Yes, make me owner',
        })
      );
      setOnConfirm(() => onTakeOwnershipConfirm);
      setShouldBeConfirmationDisplayed(true);

      return;
    }
    await onTakeOwnershipConfirm();
    await refetchData();
  }, [
    owner,
    onTakeOwnershipConfirm,
    refetchData,
    setCancelLabel,
    intl,
    setTitle,
    setSubtitle,
    setConfirmLabel,
    setOnConfirm,
    setShouldBeConfirmationDisplayed,
  ]);

  const onGiveUpOwnership = useCallback(async () => {
    setCancelLabel(
      intl.formatMessage({
        id: 'misc.cancel',
        defaultMessage: 'Cancel',
      })
    );
    setTitle(
      intl.formatMessage({
        id: 'tasks.GiveUpTaskOwnershipTitle',
        defaultMessage: 'Give up task ownership?',
      })
    );
    setSubtitle(
      intl.formatMessage({
        id: 'tasks.GiveUpTaskOwnershipSubtitle',
        defaultMessage:
          'You may no longer be able to complete this task and may be locked from editing it.',
      })
    );
    setConfirmLabel(
      intl.formatMessage({
        id: 'tasks.giveUpOwnershipConfirm',
        defaultMessage: 'Yes, give up ownership',
      })
    );
    setOnConfirm(() => onGiveUpOwnershipConfirm);
    setShouldBeConfirmationDisplayed(true);
  }, [
    setCancelLabel,
    intl,
    setTitle,
    setSubtitle,
    setConfirmLabel,
    setOnConfirm,
    setShouldBeConfirmationDisplayed,
    onGiveUpOwnershipConfirm,
  ]);

  return {
    onClickTakeOwnership,
    onGiveUpOwnership,
    onClickAssignOwnership,
  };
};

export default useTaskOwnership;
