import React from 'react';
import { toast as toastifyToast, ToastOptions } from 'react-toastify';
import ToastType from 'utils/Enums/ToastType';
import { Message } from '.';

export const toast = (
  {
    title,
    subtitle,
  }: {
    title: string;
    subtitle?: string | React.ReactNode;
  },
  type: ToastType = ToastType.Success,
  config?: ToastOptions
) =>
  toastifyToast(<Message text={title} {...{ subtitle, type }} />, {
    ...config,
    type,
  });
