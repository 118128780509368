import { createUseStyles } from 'react-jss';

export const useDraggableTableStyles = createUseStyles({
  tablePagination: {
    marginLeft: 15,
  },
  toRightWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonNoPointerEvents: {
    cursor: 'not-allowed',
    '& button': {
      pointerEvents: 'none',
    },
  },
  customDragLayerWrapper: {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  draggableRow: {
    transition: 'all 0.2s ease-out',
  },
  activeElem: {
    opacity: 0.2,
  },
  draggableTableOuter: {
    height: '1px',
  },
});
