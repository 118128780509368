import { createUseStyles } from 'react-jss';

const usePermissionDeniedStyles = createUseStyles({
  container: {
    position: 'relative',
    top: '20%',
  },
  illustration: {
    display: 'block',
    margin: '0 auto',
    width: '68%',
    height: 'auto',
  },
  textContainer: {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  title: {
    fontWeight: '700 !important',
    textAlign: 'center',
  },
  paragraph: {
    width: '60%',
    margin: '0 auto',
    textAlign: 'center',
  },
});

export default usePermissionDeniedStyles;
