import React from 'react';
import { EditIcon, LockIcon } from 'components/Icon';
import usePreviewComponentStyles from './styles';
import { PreviewComponentProps } from './types';
import { IN_PLACE_EDIT_PREVIEW } from 'utils/testIds';
import clsx from 'clsx';
import { getInPlaceEditIconSize } from '../commonStyles';
import Tooltip from 'components/lib/Tooltip';
import { useIntl } from 'react-intl';

const PreviewComponent: React.FC<PreviewComponentProps> = ({
  disabled,
  children,
  onClick,
  actionButtonOnTop,
  readOnly,
  className,
  size,
  withUnderline,
  tooltipText,
}) => {
  const classes = usePreviewComponentStyles({
    disabled,
    actionButtonOnTop,
    readOnly,
    size,
    withUnderline,
  });

  const intl = useIntl();

  const readOnlyMessage =
    !readOnly && disabled
      ? intl.formatMessage({
          id: 'forms.readOnlyField',
          defaultMessage: 'This field is read-only',
        })
      : '';

  let tooltipMergedText = '';
  if (readOnlyMessage && tooltipText) {
    tooltipMergedText = ((
      <div>
        <div>{readOnlyMessage}.</div>
        <div>{tooltipText}.</div>
      </div>
    ) as unknown) as string;
  } else if (readOnlyMessage) {
    tooltipMergedText = readOnlyMessage;
  } else if (tooltipText) {
    tooltipMergedText = tooltipText;
  }

  return (
    <Tooltip overlay={tooltipMergedText ?? ''}>
      <div
        className={clsx(classes.valueWrapper, className)}
        {...{ onClick }}
        data-testid={IN_PLACE_EDIT_PREVIEW}
      >
        {children}
        {!readOnly && (
          <>
            {disabled ? (
              <LockIcon size={getInPlaceEditIconSize(size)} />
            ) : (
              <EditIcon size={getInPlaceEditIconSize(size)} />
            )}
          </>
        )}
      </div>
    </Tooltip>
  );
};

export default PreviewComponent;
