import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as JsonIcon } from 'img/icons/json-icon.svg';
import { TextArea } from 'components/lib/Input';
import useCustomWidgetsStyles from '../../customWidgetsStyles';
import { FormLabelWithToolTip } from 'pages/TaskTemplates/components/FormLabel';
import { Widget } from 'alx-dynamic-form';
import StandardFieldWrapper from 'components/FormPreview2/components/StandardFieldWrapper';
import useInputStyles from 'components/lib/Input/styles';
import isEqual from 'lodash/isEqual';

const CustomJsonWidget: Widget = ({
  value,
  onChange,
  reValidateField,
  description,
  errors,
  required,
  label,
  disabled,
}) => {
  const inputClasses = useInputStyles({});
  const classes = useCustomWidgetsStyles({});
  const [jsonText, setJsonText] = useState(
    value === null ? '' : JSON.stringify(value)
  );
  const initialValue = useRef(value);

  useEffect(() => {
    if (isEqual(initialValue.current, value)) {
      setJsonText(prev => {
        if (value === null) {
          return prev === 'null' ? prev : ''; //we dont want to clear field when user type 'null'
        }
        return JSON.stringify(value);
      });
    }
  }, [value]);

  const customOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setJsonText(newValue);

    return onChange(newValue === '' ? null : newValue);
  };

  const onBlur = () => {
    try {
      const json = JSON.parse(jsonText);
      onChange(typeof json === 'string' ? `"${json}"` : json);
    } catch {} //nothing to handle - just go on and revalidate
    reValidateField();
  };

  const onIconClick = () => {
    try {
      const json = JSON.parse(jsonText);
      const beautified = JSON.stringify(json, null, 4);
      onChange(typeof json === 'string' ? `"${json}"` : json);
      setJsonText(beautified);
    } catch {} //nothing to handle - just go on and revalidate
    reValidateField();
  };

  return (
    <>
      <FormLabelWithToolTip
        dynamicSpacing
        {...{ required }}
        className={clsx([classes.inputLabel, classes.inputLabelEllipsis])}
        label={label}
      />
      <StandardFieldWrapper {...{ description, errors }}>
        <div
          className={clsx(inputClasses.customInput, {
            [inputClasses.error]: !!errors?.length,
          })}
        >
          <div className={classes.textWidgetWrapper}>
            <TextArea
              value={jsonText}
              onChange={customOnChange}
              rows={7}
              disableResize
              disableTextTrim
              disableTrimSpaces
              withLineCounter
              wrap='off'
              {...{ onBlur, disabled }}
            />
            <div className={classes.textareaIconWrapper}>
              <JsonIcon
                onClick={onIconClick}
                className={clsx(classes.icon, classes.iconClickable)}
                width={24}
                height={24}
              />
            </div>
          </div>
        </div>
      </StandardFieldWrapper>
    </>
  );
};

export default CustomJsonWidget;
