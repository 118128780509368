import axios from 'axios';
import { store } from 'store/store';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { REFRESH_TOKEN } from './endpoints';
import { setTokens, clearTokens } from 'store/actions/authActions';
import { getPermissions } from 'store/actions/permissionsActions';
import MockAdapter from 'axios-mock-adapter';
import moment from 'moment-timezone';
import { StatusCodes } from 'http-status-codes';
import { getSupportedTimezone } from './functions/getSupportedTimezone';
import { PreferencesTypes } from './types/api/preferences.types';

const AXIOS_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const basicInstance = axios.create(AXIOS_CONFIG);

basicInstance.interceptors.request.use(
  config => {
    const {
      preferences: {
        data: {
          [PreferencesTypes.GeneralPreferences]: {
            timezone = moment.tz.guess(),
          } = {},
        } = {},
      } = {},
      auth: { access },
    } = store.getState() || {};

    if (!!access) {
      config.headers.Authorization = `JWT ${access}`;
      config.headers.Timezone = getSupportedTimezone(timezone);
    }
    return config;
  },
  error => Promise.reject(error)
);

// separate exios instance with separate interceptors for handling token refresh
export const refreshInstance = axios.create(AXIOS_CONFIG);

refreshInstance.interceptors.response.use(
  response => {
    return response;
  },
  () => {
    store.dispatch(clearTokens());
    return Promise.reject();
  }
);

export const refreshAuth = async () => {
  const { refresh } = store.getState().auth;
  const { status, data } = await refreshInstance.post(REFRESH_TOKEN, {
    refresh,
  });

  if (status === StatusCodes.OK) {
    store.dispatch(setTokens(data));
    store.dispatch(getPermissions());
    return Promise.resolve();
  }
};

basicInstance.interceptors.response.use(response => {
  return response;
});

createAuthRefreshInterceptor(basicInstance, refreshAuth, {
  statusCodes: [StatusCodes.UNAUTHORIZED],
});

export default basicInstance;

export const apiCall = basicInstance;

export const mock = new MockAdapter(apiCall, { delayResponse: 500 });

mock.onAny().passThrough();
