import React from 'react';
import InPlaceEditNumberInput from 'components/InPlaceEditControls/InPlaceEditNumberInput';
import { mapCustomWidgetPropsToBaseInPlaceEditProps } from './utils';
import { Widget } from 'alx-dynamic-form';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { DraggableComponents } from 'components/formBuilder/formBuilder/InputsContainer/enums';

const AdaptedInPlaceEditNumberInput: Widget = widgetProps => {
  const baseInPlaceEditProps = mapCustomWidgetPropsToBaseInPlaceEditProps<
    number,
    any
  >(widgetProps);

  const { min, max, multipleOf } = widgetProps;

  const showRawValue =
    baseInPlaceEditProps.propertyName === DraggableComponents.PropertyId;

  return (
    <Provider store={store}>
      <InPlaceEditNumberInput
        {...{ ...baseInPlaceEditProps }}
        multipleOf={multipleOf ?? undefined}
        min={min ?? undefined}
        max={max ?? undefined}
        withUnderline
        {...{ showRawValue }}
      />
    </Provider>
  );
};

export default AdaptedInPlaceEditNumberInput;
