import { AutoSaveErrorModalContextProvider } from 'contexts/AutoSaveErrorModalContext';
import { useRefetchResourceContext } from 'contexts/RefetchResourceContext';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { RefetchResources } from 'contexts/types';
import useCheckAccessToSelectedObjectClass from 'hooks/useCheckAccessToSelectedObjectClass';
import useCloseRecordPanels from 'hooks/useCloseRecordPanels';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, matchPath } from 'react-router-dom';
import { getPermissions } from 'store/actions/permissionsActions';
import { updatePreferences } from 'store/actions/preferencesActions';
import routes from 'utils/routingPaths';
import useTasksPanelStyles from '../styles';
import TaskForm from './TaskForm';
import { PreferencesTypes } from 'utils/types/api/preferences.types';

const CreateTask = () => {
  const classes = useTasksPanelStyles({});
  const history = useHistory();
  const dispatch = useDispatch();
  const { refetchData } = useRefetchResourceContext();
  const { closeRecordPanels } = useCloseRecordPanels();
  const {
    checkAccessToSelectedObjectClassId,
  } = useCheckAccessToSelectedObjectClass();
  const {
    selectedResource: { record: { identifier = undefined } = {} } = {},
  } = useSelectedResourceContext();

  const isRecordDetailsPage = useMemo(
    () =>
      !!matchPath(history.location.pathname, {
        exact: true,
        path: routes.RECORD,
      }),
    [history.location.pathname]
  );

  const onCloseErrorModal = useCallback(async () => {
    await dispatch(
      getPermissions(async ({ object_records }) => {
        closeRecordPanels(!object_records?.list);

        if (!object_records?.list) history.push(routes.WORKSPACE);
        else {
          if (isRecordDetailsPage) history.push(routes.RECORDS);
          else {
            refetchData(RefetchResources.Records);

            const hasStillAccessToObjectClass = await checkAccessToSelectedObjectClassId();

            if (!hasStillAccessToObjectClass) {
              dispatch(
                updatePreferences(PreferencesTypes.GeneralPreferences, {
                  selectedClassId: undefined,
                })
              );
            }
          }
        }
      })
    );
  }, [
    checkAccessToSelectedObjectClassId,
    closeRecordPanels,
    dispatch,
    history,
    isRecordDetailsPage,
    refetchData,
  ]);

  return (
    <div className={classes.panelWrapper}>
      <div className={classes.identifier}>{identifier}</div>
      <div className={classes.panelTitle}>
        <FormattedMessage
          id='tasksPanel.createTask'
          defaultMessage='Create task'
        />
      </div>
      <AutoSaveErrorModalContextProvider
        title={
          <FormattedMessage
            id='misc.recordDeleted'
            defaultMessage='{name} has been deleted.'
            values={{
              name: identifier,
            }}
          />
        }
        subtitle={
          <FormattedMessage
            id='tasksPanel.taskCanNoLongerBeCreated'
            defaultMessage='The task can no longer be created.'
            values={{
              name: identifier,
            }}
          />
        }
        {...{ onCloseErrorModal }}
      >
        <TaskForm />
      </AutoSaveErrorModalContextProvider>
    </div>
  );
};

export default CreateTask;
