import Login from '../pages/Login';
import Logout from '../pages/Logout';
import ActivateAccount from '../pages/ActivateAccount';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import ExpiredPassword from '../pages/ExpiredPassword';
import SingleSignOnCallback from '../pages/SingleSignOnCallback';
import CompleteTaskBy1TC from '../pages/CompleteTaskBy1TC';
import { Route } from './types/routing.types';
import { nonProtectedRoutes } from './routingPaths';

const unprotectedRoutes: Route[] = [
  {
    path: nonProtectedRoutes.LOGIN,
    breadcrumbName: 'misc.login',
    Component: Login,
  },
  {
    path: nonProtectedRoutes.LOGOUT,
    breadcrumbName: 'misc.logout',
    Component: Logout,
  },
  {
    path: nonProtectedRoutes.FORGOTTEN_PASSWORD,
    breadcrumbName: 'misc.resetPassword',
    Component: ForgotPassword,
  },
  {
    path: nonProtectedRoutes.ACTIVATE_ACCOUNT,
    breadcrumbName: 'misc.activateAccount',
    Component: ActivateAccount,
  },
  {
    path: nonProtectedRoutes.RESET_PASSWORD,
    breadcrumbName: 'misc.resetPassword',
    Component: ResetPassword,
  },
  {
    path: nonProtectedRoutes.SET_EXPIRED_PASSWORD,
    breadcrumbName: 'misc.setExpiredPassword',
    Component: ExpiredPassword,
  },
  {
    path: nonProtectedRoutes.SINGLE_SIGN_ON_CALLBACK,
    breadcrumbName: '',
    Component: SingleSignOnCallback,
  },
  {
    path: nonProtectedRoutes.COMPLETE_TASK_BY_1TC,
    breadcrumbName: '',
    Component: CompleteTaskBy1TC,
  },
];

export default unprotectedRoutes;
