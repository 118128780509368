/**
 * Extracts the 'message' string from catched error of unknown type.
 * @param error Error from the try / catch
 * @param callerName Entity that tries to extract the error message. Used to help find the code snippet that is unable to extract the message.
 * @returns Error message string or a string containing the caller name with a warning that it was unable to retrieve message.
 */
export const extractErrorMessage = (
  error: unknown,
  callerName: string
): string => {
  let errorMessage = `${callerName}: Unknown error type.`;

  if (error instanceof Error) {
    errorMessage = error.message;
  }

  return errorMessage;
};
