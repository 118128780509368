import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseTaskPropertiesStylesProps = {
  expired?: boolean;
};

const useTaskPropertiesStyles = createUseStyles((theme: ThemeState) => {
  return {
    grid: {
      display: 'grid',
      gridTemplateColumns: 'auto auto auto',
      fontSize: 12,
    },
    gridItem: {
      padding: '2px 0px',
    },
    assigneItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    ownerWrapper: {
      marginLeft: '1px',
    },
    expiryItem: ({ expired }: UseTaskPropertiesStylesProps) => ({
      color: expired ? theme['error-color'] : theme['text-color'],
    }),
  };
});

export default useTaskPropertiesStyles;
