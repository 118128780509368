import { updateNestedRecordsStackModal } from 'store/actions/nestedObjectRecordsActions';
import {
  NestedObjectRecordsModalTypes,
  NestedObjectRecordsStack,
} from 'store/reducers/types/nestedObjectRecords.types';
import { AppDispatch } from 'store/store';

const hideModal = (
  dispatch: AppDispatch,
  modalKey: NestedObjectRecordsModalTypes
) => dispatch(updateNestedRecordsStackModal({ isVisible: false }, modalKey));

export const hasStackUnsavedChanges = (
  nestedRecordsStack: NestedObjectRecordsStack
) =>
  nestedRecordsStack.some(
    element =>
      !element?.recordId &&
      element.formData &&
      Object.values(element?.formData).some(value => !!value)
  );

export const updateNestedRecordsStackModalData = (
  dispatch: AppDispatch,
  onConfirmCallback: () => void,
  modalKey: NestedObjectRecordsModalTypes
) => {
  dispatch(
    updateNestedRecordsStackModal(
      {
        isVisible: true,
        onCancel: () => hideModal(dispatch, modalKey),
        onConfirm: () => {
          onConfirmCallback();
          hideModal(dispatch, modalKey);
        },
      },
      modalKey
    )
  );
};

export const verifyNestedRecordsUnsavedChanges = (
  dispatch: AppDispatch,
  nestedRecordsStack: NestedObjectRecordsStack,
  modalKey: NestedObjectRecordsModalTypes,
  onConfirmCallback: () => void
) => {
  if (hasStackUnsavedChanges(nestedRecordsStack)) {
    updateNestedRecordsStackModalData(dispatch, onConfirmCallback, modalKey);
    return;
  }
  onConfirmCallback();
};
