import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import WarningIllustration from 'img/illustrations/warning-triangle.svg';
import ConfirmationModal from 'components/ConfirmationModal';
import { CloseIcon } from 'components/Icon';
import { RemoveAssigneeModalProps } from './types';
import {
  getObjectClassPermissionSetAssigneesFetching,
  getObjectClassPermissionsIsRemovingHimself,
} from 'store/selectors/objectClassPermissionsSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { setUserGroupPermissionsGroupOwnersIsRemovingHimself } from 'store/actions/userGroupPermissionsActions';

const RemoveAssigneeModal = ({
  assigneeToDelete,
  setAssigneeToDelete,
  removePermissionSetAssignee,
}: RemoveAssigneeModalProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isLoading = useSelector(
    getObjectClassPermissionSetAssigneesFetching(
      assigneeToDelete?.permissionSetId
    )
  );
  const isRemovingHimself = useSelector(
    getObjectClassPermissionsIsRemovingHimself
  );

  const title = useMemo(
    () =>
      isRemovingHimself
        ? intl.formatMessage({
            id: 'misc.sureToUnassignYourself',
            defaultMessage: 'Are you sure you want to unassign yourself?',
          })
        : intl.formatMessage(
            {
              id: 'misc.sureToUnassignAnotherUser',
              defaultMessage: 'Are you sure you want to unassign {name}?',
            },
            {
              name: assigneeToDelete?.displayName,
            }
          ),
    [intl, isRemovingHimself, assigneeToDelete]
  );

  const subtitle = useMemo(
    () =>
      isRemovingHimself
        ? intl.formatMessage({
            id: 'misc.youMayLoseAccessToUserGroup',
            defaultMessage: 'You may lose access to this user group.',
          })
        : intl.formatMessage({
            id: 'misc.theyMayLoseAccessToUserGroup',
            defaultMessage: 'They may lose access to this user group.',
          }),
    [intl, isRemovingHimself]
  );

  const onClose = () => {
    setAssigneeToDelete(undefined);
    dispatch(setUserGroupPermissionsGroupOwnersIsRemovingHimself(false));
  };

  const onConfirm = async () => {
    if (assigneeToDelete) {
      await removePermissionSetAssignee();
    }

    setAssigneeToDelete(undefined);
  };

  return (
    <ConfirmationModal
      {...{ isLoading, onClose, onConfirm, title, subtitle }}
      visible={!!assigneeToDelete}
      confirmationButtonLabel={intl.formatMessage({
        id: 'misc.yesUnassign',
        defaultMessage: 'Yes, unassign',
      })}
      withCancel
      image={WarningIllustration}
      cancelButtonIcon={<CloseIcon size={18} />}
    />
  );
};

export default RemoveAssigneeModal;
