import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export type UseActionMenuListStylesProps = {
  y?: number;
  x?: number;
  displayToBottom?: boolean;
};

const useActionMenuListStyles = createUseStyles((theme: ThemeState) => {
  return {
    list: {
      minWidth: 130,
      maxWidth: 'calc(100% + 8px)',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.25) !important',
      padding: [4, 0],
    },
    listItem: {
      padding: '0px 10px !important',
      margin: '0 !important',
      '&:hover': {
        backgroundColor: theme['dropdown-hover-gray'],
        fontWeight: 'bold',
        '& svg': {
          color: theme['primary-color'],
        },
      },
      '& svg': {
        color: theme['layout-trigger-color'],
      },
    },
    title: {
      marginLeft: 10,
      '&::after': {
        content: 'attr(data-text)',
        marginLeft: 28,
        display: 'block',
        fontWeight: 'bold',
        height: 0,
        overflow: 'hidden',
      },
    },
    actionsWrapper: (props: UseActionMenuListStylesProps) => ({
      position: 'absolute',
      zIndex: 2,
      top: props.y,
      left: props.x,
      transform: `translate(-100%, ${props?.displayToBottom ? '0' : '-100%'})`,
    }),
  };
});
export default useActionMenuListStyles;
