import React from 'react';
import { Progress as AntProgress, Tooltip } from 'antd';
import { ProgressProps } from 'antd/lib/progress';
import useProgressStyle from './styles';
import { ReactComponent as RemoveFileFilledSvg } from 'img/icons/remove-file-filled.svg';
import { ReactComponent as RemoveFileSvg } from 'img/icons/remove-file.svg';
import { CANCEL_MSG } from '../FileUpload/consts';
import { CustomProgressProps } from './types';
import { useIntl } from 'react-intl';
import { REMOVE_FILE } from 'utils/testIds';

export const Progress: React.FC<ProgressProps> = ({ ...rest }) => {
  const classes = useProgressStyle();

  return <AntProgress {...rest} className={classes.progress} />;
};

export const FileUploadProgress: React.FC<CustomProgressProps> = ({
  percent,
  fileStatus,
  onRemove,
  source,
  iconSize = 35,
}) => {
  const intl = useIntl();
  const classes = useProgressStyle({ iconSize });

  const onRemoveClick = () => {
    if (fileStatus === 'uploading' && source) {
      source.cancel(CANCEL_MSG);

      return;
    }

    if (onRemove) onRemove();
  };

  const tooltipText =
    fileStatus === 'uploading'
      ? intl.formatMessage({
          id: 'formBuilder.cancelFileUploadTooltip',
          defaultMessage: 'Cancel upload',
        })
      : intl.formatMessage({
          id: 'formBuilder.removeUploadedFileTooltip',
          defaultMessage: 'Delete',
        });

  return (
    <div className={classes.progressWrapper}>
      {fileStatus === 'uploading' ? (
        <AntProgress
          className={classes.progress}
          type='circle'
          width={iconSize}
          {...{ percent }}
        />
      ) : (
        <div className={classes.removeButtonBg}>
          <RemoveFileSvg width={iconSize} height={iconSize} />
        </div>
      )}
      <div className={classes.buttonWrapper}>
        <Tooltip placement='top' title={tooltipText} destroyTooltipOnHide>
          <button
            className={classes.removeButton}
            onClick={onRemoveClick}
            data-testid={REMOVE_FILE}
          >
            <RemoveFileFilledSvg width={iconSize} height={iconSize} />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};
