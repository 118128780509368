import React from 'react';
import clsx from 'clsx';
import { DangerouslyRenderCkeditorContentProps } from './types';
import { ckeditorContentClassName } from 'components/formBuilder/formBuilder/FBGeneralInput/LandingPageHTMLEditor/consts';
import { useDangerouslyRenderCkeditorContentStyles } from './styles';
// import { toast } from 'components/lib/toast';
// import { useIntl } from 'react-intl';
// import ToastType from 'utils/Enums/ToastType';
// import { SCRIPT_REGEX, SCRIPT_TAG_REGEX } from './consts';

// !!! Upon the informed request of the client, this component is a potential critical threat to the application security. Use only when necessary in areas with very restrictive access

const DangerouslyRenderCkeditorContent = ({
  value,
  testId,
}: DangerouslyRenderCkeditorContentProps) => {
  const classes = useDangerouslyRenderCkeditorContentStyles({});
  //   !!! do not delete (code temporarily disabled at customer request, it will be unlocked in later versions)
  //   const intl = useIntl();

  //   useLayoutEffect(() => {
  //     let extractedScript: RegExpExecArray | null = null;

  //     while ((extractedScript = SCRIPT_REGEX.exec(value as string)) !== null) {
  //       try {
  //         // eslint-disable-next-line
  //         window.eval(extractedScript[0].replaceAll(SCRIPT_TAG_REGEX, ''));
  //       } catch (err) {
  //         toast(
  //           {
  //             title: intl.formatMessage({
  //               id: 'misc.error',
  //               defaultMessage: 'Error!',
  //             }),
  //             subtitle: intl.formatMessage({
  //               id: 'misc.scriptHasAnError',
  //               defaultMessage: 'The script has an error.',
  //             }),
  //           },
  //           ToastType.Error
  //         );
  //       }
  //     }
  //   }, [intl, value]);

  return (
    <div
      dangerouslySetInnerHTML={{ __html: value }}
      className={clsx(classes.valueWrapper, ckeditorContentClassName)}
      data-testid={testId}
    />
  );
};

export default DangerouslyRenderCkeditorContent;
