import React, { useEffect } from 'react';
import { Row, Col } from 'components/lib/Grid';
import { Text } from 'components/lib/Text';
import useLoginStyles from './styles';

import LoginForm from './components/LoginForm/LoginForm';
import { FormattedMessage, useIntl } from 'react-intl';
import AutologyxInformation from '../../components/AutologyxInformation';
import { LogoIcon } from '../../components/Icon';
import { LOGIN_FOOTER_TEXT_TESTID, LOGIN_TITLE_TESTID } from 'utils/testIds';
import { useProviders } from './hooks';
import LoaderOverlay from '../../components/LoaderOverlay';
import { useHelmetContext } from 'contexts/HelmetContext';
import { FallbackLogoOptions } from 'components/Icon/types';

const Login = () => {
  const intl = useIntl();
  const classes = useLoginStyles({});
  const { providers, loadingAuthenticationData } = useProviders();

  const title = intl.formatMessage({
    id: 'misc.autologyx',
    defaultMessage: 'Autologyx',
  });

  const { setPageTitle } = useHelmetContext();

  useEffect(() => {
    setPageTitle(title);
  }, [setPageTitle, title]);

  if (loadingAuthenticationData) return <LoaderOverlay />;

  return (
    <div className={classes.wrapper}>
      <Row justify='center' className='w-100'>
        <Col span={6} xxl={12} xl={14} lg={16} md={12} sm={16} xs={22}>
          <div className={classes.card}>
            <LogoIcon
              size={150}
              width={150}
              fallbackLogo={FallbackLogoOptions.NORMAL}
              className={classes.logo}
            />
            <p className={classes.title} data-testid={LOGIN_TITLE_TESTID}>
              <FormattedMessage
                id='login.title'
                defaultMessage='Log in to Autologyx'
              />
            </p>
            <LoginForm {...{ providers }} />
          </div>
        </Col>
        <div className={classes.footer}>
          <Text
            className={classes.footerText}
            data-testid={LOGIN_FOOTER_TEXT_TESTID}
          >
            <FormattedMessage
              id='login.footer'
              defaultMessage='{copy} {year} autologyx.com All rights reserved.'
              values={{
                copy: <span>&copy;</span>,
                year: new Date().getFullYear(),
                privacy: (
                  <u>
                    <FormattedMessage
                      id='login.privacy'
                      defaultMessage='Privacy'
                    />
                  </u>
                ),
              }}
            />
          </Text>
        </div>
      </Row>
      <div className={classes.informationWrapper}>
        <AutologyxInformation />
      </div>
    </div>
  );
};

export default Login;
