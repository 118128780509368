import { Reducer } from 'redux';
import { SELECT_SEQUENCE } from 'store/constants/selectedSequence.consts';
import { RootAction } from '.';
import { SelectedSequenceState } from './types/selectedSequence.types';

const initialState: SelectedSequenceState = {
  sequence: undefined,
};

const selectedSequenceReducer: Reducer<SelectedSequenceState, RootAction> = (
  state = initialState,
  action
) => {
  if (action.type === SELECT_SEQUENCE) {
    return {
      ...state,
      sequence: action.payload,
    };
  }
  return state;
};

export default selectedSequenceReducer;
