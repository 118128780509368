import SupportedLanguages from 'utils/Enums/SupportedLanguages';

export const getDefaultSupportedLanguage = () => {
  const browserLang = navigator.language;

  return (
    Object.values(SupportedLanguages).find(lang => lang === browserLang) ||
    SupportedLanguages.enGB
  );
};
