import React from 'react';
import { useIntl } from 'react-intl';
import WarningIllustration from 'img/illustrations/warning-triangle.svg';
import ConfirmationModal from 'components/ConfirmationModal';
import { CloseIcon } from 'components/Icon';
import { RemoveAssigneeModalProps } from './types';
import { getObjectClassPermissionSetAssigneesFetching } from 'store/selectors/objectClassPermissionsSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { setObjectClassPermissionsClassOwnersIsRemovingHimself } from 'store/actions/objectClassPermissionsActions';

const RemoveAssigneeModal = ({
  assigneeToDelete,
  setAssigneeToDelete,
  removePermissionSetAssignee,
}: RemoveAssigneeModalProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isLoading = useSelector(
    getObjectClassPermissionSetAssigneesFetching(
      assigneeToDelete?.permissionSetId
    )
  );

  const title = intl.formatMessage(
    {
      id: 'misc.sureToUnassignAnotherUser',
      defaultMessage: 'Are you sure you want to unassign {name}?',
    },
    {
      name: assigneeToDelete?.displayName,
    }
  );

  const subtitle = intl.formatMessage({
    id: 'misc.groupMayLoseAccessToUserGroup',
    defaultMessage: 'Members of the group may lose access to this user group.',
  });

  const onClose = () => {
    setAssigneeToDelete(undefined);
    dispatch(setObjectClassPermissionsClassOwnersIsRemovingHimself(false));
  };

  const onConfirm = async () => {
    if (assigneeToDelete) {
      await removePermissionSetAssignee();
    }

    setAssigneeToDelete(undefined);
  };

  return (
    <ConfirmationModal
      {...{ isLoading, onClose, onConfirm, title, subtitle }}
      visible={!!assigneeToDelete}
      confirmationButtonLabel={intl.formatMessage({
        id: 'misc.yesUnassign',
        defaultMessage: 'Yes, unassign',
      })}
      withCancel
      image={WarningIllustration}
      cancelButtonIcon={<CloseIcon size={18} />}
    />
  );
};

export default RemoveAssigneeModal;
