import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';

type InputType = HTMLInputElement | HTMLTextAreaElement;

export const fixMultipleWhiteSpaces = (value: string) =>
  value.replace(/ {2,}/gm, ' ');

export const fixMultipleWhiteSpacesInText = (value: string) =>
  value.replace(/\s+/g, ' ');

export const fixOnlyMultipleSpacesInText = (value: string) =>
  value.replace(/[^\S\r\n]+/g, ' ');

export const useTrimmedFunctions = (
  name?: string,
  onBlur?: (value: string) => void
) => {
  const { setFieldValue } = useFormikContext();
  const handleOnChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<InputType>) => {
      const trimmed = value.trimLeft();

      if (trimmed === '') {
        if (name) setFieldValue(name, trimmed);
      }

      return trimmed;
    },
    [name, setFieldValue]
  );

  const handleOnBlur = useCallback(
    ({ target: { value } }: React.ChangeEvent<InputType>) => {
      const fixedValue = fixMultipleWhiteSpaces(value.trim());

      if (name) setFieldValue(name, fixedValue);

      if (onBlur) {
        onBlur(fixedValue);
      }

      return fixedValue;
    },
    [onBlur, name, setFieldValue]
  );

  return {
    handleOnBlur,
    handleOnChange,
    fixMultipleWhiteSpaces,
  };
};

export const useSetRequiredField = (name: string, required?: boolean) => {
  const intl = useIntl();
  const { setFieldError, values, getFieldMeta } = useFormikContext();

  useEffect(() => {
    const { value } = getFieldMeta(name);

    if (!value && required) {
      setFieldError(
        name,
        intl.formatMessage({
          id: 'errors.fieldIsRequired',
          defaultMessage: 'Field is required',
        })
      );
    }
  }, [getFieldMeta, intl, name, required, setFieldError, values]);
};
