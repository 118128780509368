import React from 'react';
import { Input, TextArea } from 'components/lib/Input';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'react-select';
import FBCheckbox from '../../FBCheckbox';
import Tooltip from '../../Tooltip';
import { FieldProps } from '../defaultInputs/types';
import FormField from 'pages/TaskTemplates/components/FormField';

const formatDictionary: MappedObject<string> = {
  '': 'None',
  'date-time': 'Date-Time',
  email: 'Email',
  hostname: 'Hostname',
  time: 'Time',
  uri: 'URI',
  regex: 'Regular Expression',
};

// specify the inputs required for a string type object
const CardLongAnswerParameterInputs: React.FC<FieldProps> = ({
  parameters,
  onChange,
}) => {
  const intl = useIntl();

  return (
    <div>
      <h4>
        <FormattedMessage
          id='formBuilder.minimumLength'
          defaultMessage='Minimum length'
        />
      </h4>
      <Input
        value={parameters.minLength || ''}
        key='minLength'
        type='number'
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          onChange({
            ...parameters,
            minLength: parseInt(ev.target.value, 10),
          });
        }}
        className='card-modal-number'
      />
      <h4>
        <FormattedMessage
          id='formBuilder.maximumLength'
          defaultMessage='Maximum length'
        />
      </h4>
      <Input
        value={parameters.maxLength || ''}
        key='maxLength'
        type='number'
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          onChange({
            ...parameters,
            maxLength: parseInt(ev.target.value, 10),
          });
        }}
        className='card-modal-number'
      />
      <h4>
        <FormattedMessage
          id='formBuilder.regexpPattern'
          defaultMessage='Regular expression pattern'
        />
        <a href='https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions'>
          <Tooltip
            id={`${parameters.path}_regex`}
            type='help'
            text={intl.formatMessage({
              id: 'formBuilder.regexpHelp',
              defaultMessage:
                'Regular expression pattern that this must satisfy',
            })}
          />
        </a>
      </h4>
      <Input
        value={parameters.pattern || ''}
        key='pattern'
        type='text'
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange({
            ...parameters,
            pattern: event.target.value,
          });
        }}
        className='card-modal-text'
      />
      <h4>
        <FormattedMessage id='formBuilder.format' defaultMessage='Format' />
        <Tooltip
          id={`${parameters.path}_format`}
          type='help'
          text={intl.formatMessage({
            id: 'formBuilder.requireStringInput',
            defaultMessage:
              'Require string input to match a certain common format',
          })}
        />
      </h4>
      <Select
        value={{
          value: parameters.format
            ? formatDictionary[parameters.format ?? '']
            : '',
          label: parameters.format
            ? formatDictionary[parameters.format ?? '']
            : 'None',
        }}
        key='format'
        options={Object.keys(formatDictionary).map(key => ({
          value: key,
          label: formatDictionary[key],
        }))}
        onChange={({ value }: any) => {
          onChange({
            ...parameters,
            format: value,
          });
        }}
        className='card-modal-select'
      />
      <div className='card-modal-boolean'>
        <FBCheckbox
          onChangeValue={() => {
            onChange({
              ...parameters,
              'ui:autofocus': parameters['ui:autofocus']
                ? parameters['ui:autofocus'] !== true
                : true,
            });
          }}
          isChecked={
            parameters['ui:autofocus']
              ? parameters['ui:autofocus'] === true
              : false
          }
          label={intl.formatMessage({
            id: 'formBuilder.autoFocus',
            defaultMessage: 'Auto focus',
          })}
        />
      </div>
    </div>
  );
};

const LongAnswer: React.FC<FieldProps> = ({ parameters, onChange }) => {
  const { maxLength, default: defaultValue } = parameters;
  const value = !!defaultValue ? String(defaultValue) : '';
  const fixedValue = !!maxLength ? value.substring(0, maxLength) : value;

  const onBlurTrimText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange({
      ...parameters,
      default: e.target.value.trimStart().trimEnd(),
    });
  };

  return (
    <FormField gutter={0}>
      <FormLabel>
        <FormattedMessage
          id='formBuilder.defaultValue'
          defaultMessage='Default value'
        />
      </FormLabel>
      <TextArea
        value={fixedValue}
        onChange={e => {
          onChange({
            ...parameters,
            default: !!maxLength
              ? e.target.value.substring(0, maxLength)
              : e.target.value,
          });
        }}
        onBlur={onBlurTrimText}
        maxLength={!!maxLength ? maxLength : undefined}
        showCount={!!maxLength}
        disableTextTrim
        disableTrimSpaces
      />
    </FormField>
  );
};

const longAnswerInput = {
  longAnswer: {
    displayName: GlobalIntlSingleton.intl.formatMessage({
      id: 'formBuilder.multiLine',
      defaultMessage: 'Multi line',
    }),
    matchIf: [
      {
        types: ['string'],
        widget: 'textarea',
      },
    ],
    defaultDataSchema: {},
    defaultUiSchema: {
      'ui:widget': 'textarea',
    },
    type: 'string',
    cardBody: LongAnswer,
    modalBody: CardLongAnswerParameterInputs,
  },
};

export default longAnswerInput;
