import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  SET_MEMBERS,
  SET_MEMBERS_FETCHING,
  APPEND_MEMBERS,
  RESET_MEMBERS,
  SET_MEMBERS_COLUMNS,
  RESET_MEMBERS_COLUMNS,
  SET_MEMBERS_SELECTED_ROW,
  SET_MEMBERS_RESTRICTIONS,
} from 'store/constants/members.consts';
import {
  appendTableContent,
  resetTable,
  setTableContent,
  setTableLoading,
  resetTableColumns,
  setTableColumns,
  setSelectedRow,
} from './tablesCommonStateModificators';
import { Member } from 'utils/types/api/members.types';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import { CommonTableState } from 'utils/types/api/table.types';
import cloneDeep from 'lodash/cloneDeep';

const initialState = cloneDeep(INITIAL_TABLE_STATE);
const membersReducer: Reducer<CommonTableState<Member>, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_MEMBERS: {
      return setTableContent<Member, typeof SET_MEMBERS>(state, action);
    }
    case APPEND_MEMBERS:
      return appendTableContent<Member, typeof APPEND_MEMBERS>(state, action);

    case SET_MEMBERS_FETCHING:
      return setTableLoading<Member, typeof SET_MEMBERS_FETCHING>(
        state,
        action
      );

    case RESET_MEMBERS:
      return resetTable<Member>(state, initialState);

    case RESET_MEMBERS_COLUMNS:
      return resetTableColumns<Member>(state, initialState);

    case SET_MEMBERS_RESTRICTIONS:
      return { ...state, restrictions: action.restrictions };

    case SET_MEMBERS_COLUMNS:
      return setTableColumns<Member, typeof SET_MEMBERS_COLUMNS>(state, action);
    case SET_MEMBERS_SELECTED_ROW:
      return setSelectedRow<Member, typeof SET_MEMBERS_SELECTED_ROW>(
        state,
        action
      );
    default:
      return state;
  }
};

export default membersReducer;
