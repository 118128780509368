import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { Select as AntSelect } from 'antd';
import { SearchIcon, CheckMarkIcon } from 'components/Icon';
import { CustomAvatarGroup } from 'components/lib/Avatar';
import { SelectOption as Option, Select } from 'components/lib/Select';
import OptionAvatar from 'components/OptionAvatar';
import useCustomUserWidgetStyles from './styles';
import { UsersSelectProps } from './types';
import useSelectUsers from './hooks';
import Tooltip from 'components/lib/Tooltip';
import FieldValidationMessage from 'components/InPlaceEditControls/components/FieldValidationMessage';
import MinMaxInfo from 'components/MinMaxInfo';
import useShouldRenderOnTop from 'hooks/useShouldRenderOnTop';
import { getContainerRef } from 'components/InPlaceEditControls/InPlaceEditMultiSelect/utils';
import {
  USER_FIELD_CHECKMARK_ICON,
  USER_FIELD_DROPDOWN,
  USER_FIELD_OPTION,
  USER_FIELD_VALIDATION_TESTID,
} from 'utils/testIds';
import NoMatchesFound from 'components/NoMatchesFound';

const UsersSelect: React.FC<UsersSelectProps> = props => {
  const {
    users,
    options,
    disabled,
    withUnderline = true,
    minValues,
    maxValues,
    errors,
  } = props;
  const errorMessage = errors?.[0];
  const intl = useIntl();
  const styles = useCustomUserWidgetStyles({
    disabled,
    withUnderline,
    hasErrors: !!errorMessage,
  });
  const selectRef = useRef<AntSelect>(null);
  const [search, setSearch] = useState('');
  const {
    isOpenSelect,
    checkIfSelected,
    handleOpenSelect,
    handleCloseSelect,
    handleChange,
  } = useSelectUsers(props);

  const { elementRef, customHeight } = useShouldRenderOnTop<HTMLDivElement>(
    getContainerRef
  );

  useEffect(() => {
    if (isOpenSelect && selectRef.current) selectRef.current.focus();
  }, [isOpenSelect]);

  const avatarsItems = useMemo(
    () =>
      users.map(
        ({
          first_name: firstName,
          last_name: lastName,
          id,
          account_type: accountType,
        }) => ({ firstName, lastName, id, accountType })
      ),
    [users]
  );

  const filteredOptions = useMemo(
    () =>
      options.filter(user => {
        const { first_name, last_name, username } = user;
        return [first_name, last_name, username].some(el =>
          el.includes(search)
        );
      }),
    [options, search]
  );

  const isMaxReached = useMemo(() => !!maxValues && maxValues <= users.length, [
    maxValues,
    users.length,
  ]);

  const maxReachedMessage = intl.formatMessage(
    {
      id: 'errors.maxReachedMessage',
      defaultMessage:
        'The maximum number of users ({maxSelected}) have been added',
    },
    { maxSelected: maxValues }
  );

  const maxExceededMessage = intl.formatMessage({
    id: 'errors.maxExceededMessage',
    defaultMessage: 'Too many users selected',
  });

  const optionToolTipMessage = useMemo(() => {
    if (!!maxValues && maxValues < users.length) {
      return maxExceededMessage;
    } else if (!!maxValues && maxValues === users.length) {
      return maxReachedMessage;
    }
    return '';
  }, [maxValues, users, maxReachedMessage, maxExceededMessage]);

  const dropdownRender = useCallback(
    (menu: React.ReactElement) => (
      <div data-testid={USER_FIELD_DROPDOWN}>
        {menu}
        <div
          data-testid={USER_FIELD_VALIDATION_TESTID}
          onMouseDown={e => e.preventDefault()}
        >
          <FieldValidationMessage
            className={styles.messageBox}
            error={errorMessage}
            message={
              minValues || maxValues ? (
                <MinMaxInfo
                  className={styles.minMaxInfo}
                  maximum={maxValues ?? undefined}
                  minimum={minValues ?? undefined}
                />
              ) : undefined
            }
          />
        </div>
      </div>
    ),
    [maxValues, minValues, styles.messageBox, styles.minMaxInfo, errorMessage]
  );

  return isOpenSelect || users.length === 0 ? (
    <div
      ref={elementRef}
      className={styles.editUserWrapper}
      style={{ height: customHeight }}
    >
      <Select
        showSearch
        labelInValue
        allowClear
        value={[]}
        filterOption={false}
        ref={selectRef}
        open={isOpenSelect}
        searchValue={search}
        onSearch={setSearch}
        onChange={handleChange}
        onBlur={handleCloseSelect}
        onClick={handleOpenSelect}
        className={styles.selectStyles}
        dropdownClassName={styles.dropdownStyles}
        dropdownRender={dropdownRender}
        icon={<SearchIcon size={12} />}
        placeholder={intl.formatMessage({
          id: 'placeholders.searchForUsers',
          defaultMessage: 'Search for users',
        })}
        notFoundContent={<NoMatchesFound />}
        getPopupContainer={() => elementRef.current ?? getContainerRef()}
        {...{ disabled }}
      >
        {filteredOptions.map(option => {
          const isSelected = checkIfSelected(option);

          return (
            <Option
              key={option.id}
              value={option.id}
              className={styles.selectItem}
              disabled={isMaxReached && !isSelected}
              data-testid={USER_FIELD_OPTION}
            >
              <Tooltip
                shouldBeRendered={isMaxReached && !isSelected}
                title={optionToolTipMessage}
                className={styles.optionToolTip}
              >
                <div className={styles.optionContent}>
                  <OptionAvatar
                    searchValue={search}
                    disabledText={undefined}
                    disabled={isMaxReached}
                    {...{ option }}
                  />
                  {isSelected && (
                    <CheckMarkIcon
                      data-testid={USER_FIELD_CHECKMARK_ICON}
                      className={styles.checkmarkIcon}
                      size={14}
                    />
                  )}
                </div>
              </Tooltip>
            </Option>
          );
        })}
      </Select>
    </div>
  ) : (
    <div className={styles.avatars} onClick={handleOpenSelect}>
      <CustomAvatarGroup items={avatarsItems} disablePopover />
    </div>
  );
};

export default UsersSelect;
