import React from 'react';
import { useIntl } from 'react-intl';
import WarningIllustration from 'img/illustrations/warning-triangle.svg';
import ConfirmationModal from 'components/ConfirmationModal';
import { ConfirmationModalProps } from 'components/ConfirmationModal/types';
import { CloseIcon } from 'components/Icon';

const SaveChangesModal: React.FC<ConfirmationModalProps> = ({
  visible,
  onClose,
  onCancel,
  onConfirm,
  ...rest
}) => {
  const intl = useIntl();

  return (
    <ConfirmationModal
      {...{ visible, onClose, onCancel, onConfirm, ...rest }}
      cancelButtonLabel={intl.formatMessage({
        id: 'misc.dontSave',
        defaultMessage: "Don't save",
      })}
      withCancel
      title={intl.formatMessage({
        id: 'misc.unsavedChangesLost',
        defaultMessage: 'Unsaved changes will be lost',
      })}
      subtitle={intl.formatMessage({
        id: 'misc.ifYouNavigateAwayYouWillLoseChanges',
        defaultMessage:
          'If you navigate away you will lose your unsaved changes.\n What do you want to do?',
      })}
      image={WarningIllustration}
      cancelButtonIcon={<CloseIcon size={18} />}
    />
  );
};
export default SaveChangesModal;
