import { createUseStyles } from 'react-jss';

const usePageNotFoundStyles = createUseStyles({
  container: {
    height: 'calc(100vh - 45px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 10%',
  },
  illustrationWrapper: {
    width: '100%',
    height: 350,
    overflow: 'hidden',
  },
  illustration: {
    display: 'block',
    margin: '0 auto',
    width: '100%',
    height: 'auto',
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export default usePageNotFoundStyles;
