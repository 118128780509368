import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const usePredicatesStyles = createUseStyles((theme: ThemeState) => {
  return {
    wrapper: {
      width: '100%',
    },
    searchIcon: {
      color: theme['primary-color'],
    },
    multiSelectWrapper: {
      position: 'relative',
    },
    multiSelect: {
      width: '100%',
    },
    usersAutocomplete: {
      display: 'flex',
      flexDirection: 'column',
      gridGap: 10,
      position: 'relative',
    },
    fullWidth: {
      width: '100%',
    },
    divider: {
      position: 'absolute',
      width: '100%',
      top: -1,
      left: 0,
      borderBottom: `1px solid ${theme['border-color-base']}`,
    },
  };
});

export default usePredicatesStyles;
