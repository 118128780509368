import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseInputStylesProps = {
  shouldBeBolded?: boolean;
};

const useInputStyles = createUseStyles((theme: ThemeState) => {
  return {
    label: {
      fontWeight: 'bold',
      fontSize: 12,
    },
    input: {
      minHeight: 35,
      borderColor: `${theme['border-color-base']}`,
    },
    placeholder: {
      fontStyle: 'italic',
      color: theme['placeholder-text-color'],
    },
    noResize: {
      resize: 'none',
    },
    readOnlyWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    copyText: {
      paddingLeft: 8,
    },
    readonly: {
      '&:hover': {
        borderColor: `${theme['border-color-base']} !important`,
      },
    },
    inputWrapper: {
      display: 'flex',
    },
    textareaWrapper: {
      width: '100%',
      maxWidth: '700px',
      '& .ant-input-textarea-show-count::after': {
        color: theme['text-color'],
      },
    },
    addonsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '32px',
    },
    addonsWrapperArea: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '32px',
      alignSelf: 'flex-end',
      margin: '0 0 18px 8px',
      minWidth: '50px',
    },
    cancelText: {
      fontSize: '12px',
      marginLeft: '8px',
      color: `${theme['primary-color']}`,
      cursor: 'pointer',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
    obfuscatePlaceholder: {
      '&::placeholder': {
        color: 'black !important',
        fontSize: 13,
      },
      '&:focus::placeholder': {
        visibility: 'hidden',
      },
      '& .ant-select-selection-placeholder': {
        color: 'black !important',
        fontSize: 13,
      },
    },
    visibilityVisible: {
      visibility: 'visible !important',
    },
    autoSaveInputWrapper: ({ shouldBeBolded }: UseInputStylesProps) => ({
      '& input': {
        borderColor: 'transparent',
        fontSize: 16,
        paddingLeft: 1,
        fontWeight: shouldBeBolded ? 'bold' : 'initial',
        '&:hover': {
          borderColor: theme['border-color-base'],
        },
        '&:focus': {
          fontWeight: 'initial',
          borderColor: theme['primary-color'],
          boxShadow: theme['input-focus-shadow'],
          '& + [class^=addonsWrapper]': {
            visibility: 'visible',
          },
        },
      },
      '& [class^=addonsWrapper]': {
        visibility: 'hidden',
        '.ant-form-item-has-error &': {
          visibility: 'visible',
        },
      },
    }),
  };
});

export default useInputStyles;
