enum FlexLayoutWindows {
  MainWindow = '#1',
  FilterEditor = 'filterEditor',
  FormBuilderInputs = 'formBuilderInputs',
  ObjectClassAddField = 'objectClassesAddField',
  ObjectClassEditField = 'objectClassesEditField',
  ObjectClassFieldViewUsage = 'objectClassFieldViewUsage',
  ObjectClassViewUsage = 'objectClassViewUsage',
  ClassPermissionsOwners = 'classPermissionsOwners',
  RecordAccess = 'recordAccess',
  Summary = 'summary',
  HelpPanel = 'helpPanel',
  Tasks = 'tasks',
  ActivityLog = 'activityLog',
  Documents = 'documents',
  TaskGroupViewUsage = 'taskGroupViewUsage',
  SequenceDefaultAssignees = 'sequenceDefaultAssignees',
  TaskTemplateViewUsage = 'taskTemplateViewUsage',
  DocumentTemplateViewUsage = 'documentTemplateViewUsage',
  ChildRecordSummary = 'childRecordSummary',
  CustomComponentsViewUsage = 'customComponentsViewUsage',
  UserGroupsViewUsage = 'userGroupsViewUsage',
  UserGroupPermissionSetAssignees = 'userGroupPermissionSetAssignees',
  Viewer = 'viewer',
}

export default FlexLayoutWindows;
