import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useInPlaceInputAfterStyles = createUseStyles((theme: ThemeState) => {
  return {
    closeIcon: {
      color: theme['disabled-switch-gray'],
      cursor: 'pointer',
    },
    loader: {
      height: 16,
      marginLeft: 5,
      alignSelf: 'center',
    },
  };
});

export default useInPlaceInputAfterStyles;
