import React, { useEffect, useRef } from 'react';
import UserAvatar from 'components/UserAvatar';
import BoldedPartOfText from 'components/CustomCell/BoldedPartOfText';
import useOptionAvatarStyles from '../OptionAvatar/styles';
import { OptionAvatarGroupProps } from './types';
import {
  OPTION_AVATAR_BOTTOM_TEXT,
  OPTION_AVATAR_DISABLED_TEXT,
  OPTION_AVATAR_TEXT_WRAPPER,
} from 'utils/testIds';
import clsx from 'clsx';

const OptionGroupAvatar = ({
  option: { id, text, num_of_members },
  searchValue,
  disabledText,
  disabled,
  selected,
}: OptionAvatarGroupProps) => {
  const classes = useOptionAvatarStyles({});
  const optionTextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // optionTextRef.current.parentElement.parentElement === .ant-select-item-option
    if (optionTextRef.current?.parentElement?.parentElement?.removeAttribute)
      optionTextRef.current.parentElement.parentElement.removeAttribute(
        'title'
      );
  }, []);

  return (
    <>
      <UserAvatar
        {...{ id, disabled, num_of_members }}
        userGroup={text}
        size='large'
        disablePopover
      />
      <div
        ref={optionTextRef}
        className={clsx(classes.textWrapper, {
          [classes.highlightText]: selected,
        })}
        data-testid={OPTION_AVATAR_TEXT_WRAPPER}
      >
        <BoldedPartOfText value={text} boldPhrase={searchValue} />
        <div
          className={clsx(classes.bottomText, {
            [classes.disabledBottomText]: disabled,
            [classes.highlightText]: selected,
          })}
          data-testid={OPTION_AVATAR_BOTTOM_TEXT}
        >
          {!!disabledText && (
            <span
              className={classes.disabledText}
              data-testid={OPTION_AVATAR_DISABLED_TEXT}
            >
              {disabledText}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default OptionGroupAvatar;
