import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useBreadcrumbsSkeletonStyles = createUseStyles((theme: ThemeState) => {
  return {
    breadcrumbLink: {
      '&:hover, &:active, &:focus': {
        color: theme['primary-color'],
        cursor: 'default',
      },
    },
    separator: {
      margin: '0 8px',
    },
    breadcrumbs: {
      padding: '0px 15px',
      display: 'flex',
      whiteSpace: 'nowrap',
      width: 'calc(100% - 363px)',
    },
    breadcrumbText: {
      cursor: 'default',
      color: theme['text-color'],
    },
    lastArrow: {
      color: theme['default-red'],
    },
    textColor: {
      color: theme['text-color'],
    },
    link: {
      '& > button': {
        fontWeight: 400,
      },
    },
    bold: {
      fontWeight: 'bold',
    },
    list: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  };
});

export default useBreadcrumbsSkeletonStyles;
