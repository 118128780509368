import React, { useRef } from 'react';
import UserAvatar from 'components/UserAvatar';
import useDetailsStyles from '../styles';
import clsx from 'clsx';
import { UserDetailsComponentProps } from './types';
import useOutsideClick from 'hooks/useOutsideClick';
import { useSelector } from 'react-redux';
import { userGroupsPermissionEditSelector } from 'store/selectors/permissionsSelectors';
import { UserManagementIcon } from 'components/Icon';
import { generatePath, Link } from 'react-router-dom';
import routes from 'utils/routingPaths';
import { FormattedMessage } from 'react-intl';

const UserGroupDetails = ({
  groupData,
  closePopover,
  noItemViewPerm,
}: UserDetailsComponentProps) => {
  const { id, name, description, num_of_members: membersCount } =
    groupData || {};

  const classes = useDetailsStyles({});
  const hasEditUserGroupsPermission = useSelector(
    userGroupsPermissionEditSelector
  );

  const containerRef = useRef<HTMLDivElement>(null);
  useOutsideClick(containerRef, closePopover, { event: 'click' });

  return (
    <div className={classes.wrapper} ref={containerRef}>
      {name && (
        <div className={clsx([classes.center, classes.titleWrapper])}>
          <UserAvatar
            {...{ id }}
            userGroup={name}
            disablePopover
            size='small'
          />
          <span className={clsx([classes.title, classes.wrapText])}>
            {name}
          </span>
        </div>
      )}
      {description && !noItemViewPerm && (
        <p className={clsx(classes.descriptionWrapper, classes.wrapText)}>
          {description}
        </p>
      )}
      {noItemViewPerm && (
        <p className={clsx(classes.descriptionWrapper, classes.wrapText)}>
          <FormattedMessage
            id='misc.noPermToViewGroupDetails'
            defaultMessage='You do not have permission to view detailed information about this group.'
          />
        </p>
      )}
      {!noItemViewPerm && (
        <div className={classes.iconItem}>
          <UserManagementIcon className={classes.icon} size={16} />
          {hasEditUserGroupsPermission && false ? (
            <Link
              to={generatePath(routes.USER_GROUP_EDIT, { id })}
              target='_blank'
            >
              <FormattedMessage
                id='members.membersCount'
                defaultMessage='{membersCount, plural, one {# member} other {# members}}'
                values={{ membersCount }}
              />
            </Link>
          ) : (
            <span>
              <FormattedMessage
                id='members.membersCount'
                defaultMessage='{membersCount, plural, one {# member} other {# members}}'
                values={{ membersCount }}
              />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default UserGroupDetails;
