import React from 'react';
import { FormikFormItem } from 'components/lib/Form';
import { FormikSelectProps } from '../types';
import { FormikSelect } from 'components/lib/Select';
import { FORM_SELECT_TESTID } from 'utils/testIds';
import clsx from 'clsx';
import useInputStyles from '../styles';

const Select: React.FC<FormikSelectProps & { 'data-testid'?: string }> = ({
  name,
  readOnly,
  obfuscatePlaceholder,
  obfuscateValueLength = 8,
  placeholder,
  'data-testid': dataTestId,
  ...rest
}) => {
  const classes = useInputStyles({});

  return (
    <FormikFormItem
      {...{ name }}
      className={clsx({
        [classes.obfuscatePlaceholder]: obfuscatePlaceholder,
      })}
    >
      <FormikSelect
        data-testid={dataTestId ?? `${FORM_SELECT_TESTID}${name}`}
        {...{ name, readOnly, ...rest }}
        placeholder={
          obfuscatePlaceholder ? '•'.repeat(obfuscateValueLength) : placeholder
        }
      />
    </FormikFormItem>
  );
};

export default Select;
