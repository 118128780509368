import flatMapDeep from 'lodash/flatMapDeep';
import { CustomFileItem } from '../lib/FileUpload/types';
import { ApplyFilesMetaToFormFieldsParams } from './types';

export const getKeysOfFieldsWithFiles = (uischema: string) =>
  flatMapDeep(JSON.parse(uischema)).reduce<MappedObject<string>>(
    (result, item) => {
      const fieldsWithFiles = {} as MappedObject<string>;

      if (item && !Array.isArray(item) && typeof item === 'object') {
        Object.entries(item).forEach(([key, value]) => {
          if (value?.['ui:widget'] === 'file') {
            fieldsWithFiles[key] = key;
          }
        });
      }

      return { ...result, ...fieldsWithFiles };
    },
    {}
  );

export const getKeysOfFieldsWithUsers = (uischema: string) =>
  flatMapDeep(JSON.parse(uischema)).reduce<MappedObject<string>>(
    (result, item) => {
      const fieldsWithFiles = {} as MappedObject<string>;

      if (item && !Array.isArray(item) && typeof item === 'object') {
        Object.entries(item).forEach(([key, value]) => {
          if (value?.['ui:widget'] === 'user') {
            fieldsWithFiles[key] = key;
          }
        });
      }

      return { ...result, ...fieldsWithFiles };
    },
    {}
  );

export const getFilesTokens = (data: MappedObject<CustomFileItem[]>) =>
  Object.fromEntries(
    Object.entries(data).map(([key, value]) => [
      key,
      value ? value.map(({ token, url, id }) => (url ? id : token)) : [],
    ])
  );

export const applyFilesMetaToFormFields = ({
  uiSchema,
  formData,
  filesMeta,
  defaultKeysOfFieldsWithFiles,
}: ApplyFilesMetaToFormFieldsParams) => {
  if (!filesMeta) return formData;

  const keysOfFieldsWithFiles = uiSchema
    ? getKeysOfFieldsWithFiles(uiSchema)
    : defaultKeysOfFieldsWithFiles || {};

  return Object.fromEntries(
    Object.entries(formData).map(([key, value]) => {
      if (key in keysOfFieldsWithFiles) {
        // value is number when it contains only file id, when it is object it contains all file
        // data ant it does not have to be merged with meta
        const fieldFiles = value as (number | object)[] | null;

        const parsedField =
          fieldFiles?.map<CustomFileItem>(id => {
            if (typeof id !== 'number') return id as CustomFileItem;

            return {
              ...filesMeta[id],
              id,
              status: 'done',
            };
          }) || [];

        return [key, parsedField];
      }

      return [key, value];
    })
  );
};
