import { Space, Input } from 'antd';
import clsx from 'clsx';
import {
  ArrowDownIcon,
  FirstPageIcon,
  LastPageIcon,
  NextPageIcon,
  PlusIcon,
  PreviousPageIcon,
  SearchIcon,
} from 'components/Icon';
import { ButtonPrimary } from 'components/lib/Button';
import { ToolButtonWithTooltip } from 'components/ToolButton';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CHILD_CLASS_AUTOCOMPLETE_SELECT,
  CHILD_CLASS_COMPONENT_CREATE_BUTTON,
  CHILD_CLASS_COMPONENT_FIRST_PAGE,
  CHILD_CLASS_COMPONENT_LAST_PAGE,
  CHILD_CLASS_COMPONENT_NEXT_PAGE,
  CHILD_CLASS_COMPONENT_PREVIOUS_PAGE,
} from 'utils/testIds';
import { useChildClassControlsStyles } from './styles';
import { ChildClassControlsProps } from './types';
import { useSelector } from 'react-redux';
import { selectChildClassById } from 'store/selectors/childClassesSelectors';
import { ChildClassMultiplicity } from 'utils/types/api/objectClassModels.types';
const ChildClassControls = ({
  readOnly,
  childClassId,
}: ChildClassControlsProps) => {
  const styles = useChildClassControlsStyles({});
  const intl = useIntl();
  const childClass = useSelector(selectChildClassById(childClassId));
  return (
    <div className={styles.container}>
      {!readOnly ? (
        <div>
          <ButtonPrimary
            data-testid={CHILD_CLASS_COMPONENT_CREATE_BUTTON}
            role='pagination-button'
            icon={<PlusIcon className={styles.plusIcon} size={10} />}
            className={styles.buttonWrapper}
            disabled
          >
            <FormattedMessage id='misc.create' defaultMessage='Create' />
          </ButtonPrimary>
          <Input
            disabled={true}
            role='number-input'
            data-testid={CHILD_CLASS_AUTOCOMPLETE_SELECT}
            className={styles.searchBox}
            placeholder={intl.formatMessage({
              id: 'formBuilder.childClassComponent.inputPlaceHolder',
              defaultMessage: 'Search for a record',
            })}
            prefix={<SearchIcon size={10} />}
            suffix={<ArrowDownIcon width={7.5} height={4} />}
          />
        </div>
      ) : (
        <div />
      )}
      {childClass?.multiplicity === ChildClassMultiplicity.ZERO_OR_MORE && (
        <Space className={clsx(styles.paginationWrapper)}>
          <>
            <Space className={styles.buttonsWrapper}>
              <ToolButtonWithTooltip
                role='pagination-button'
                data-testid={CHILD_CLASS_COMPONENT_FIRST_PAGE}
                tooltipTitle={
                  <FormattedMessage
                    id='misc.firstPage'
                    defaultMessage='First page'
                  />
                }
                disabled
                icon={<FirstPageIcon size={8} />}
              />
              <ToolButtonWithTooltip
                role='pagination-button'
                data-testid={CHILD_CLASS_COMPONENT_PREVIOUS_PAGE}
                tooltipTitle={
                  <FormattedMessage
                    id='misc.previousPage'
                    defaultMessage='Previous page'
                  />
                }
                disabled
                icon={<PreviousPageIcon size={8} />}
              />
              <ToolButtonWithTooltip
                data-testid={CHILD_CLASS_COMPONENT_NEXT_PAGE}
                role='pagination-button'
                tooltipTitle={
                  <FormattedMessage
                    id='misc.nextPage'
                    defaultMessage='Next page'
                  />
                }
                disabled
                icon={<NextPageIcon size={8} />}
              />
              <ToolButtonWithTooltip
                data-testid={CHILD_CLASS_COMPONENT_LAST_PAGE}
                role='pagination-button'
                tooltipTitle={
                  <FormattedMessage
                    id='misc.lastPage'
                    defaultMessage='Last page'
                  />
                }
                disabled
                icon={<LastPageIcon size={8} />}
              />
            </Space>
          </>
        </Space>
      )}
    </div>
  );
};

export default ChildClassControls;
