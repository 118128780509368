import { createContext, useContext } from 'react';
import noop from 'lodash/noop';
import { TableContextType } from './types';
import { DEFAULT_TABLE_PAGE_SIZES } from 'utils/consts';

const TableContext = createContext<TableContextType>({
  onPageChange: noop,
  currentPage: 1,
  filteredCount: 0,
  pageSize: 10,
  tablePageSizes: [],
  onPageSizeChange: noop,
  isInfinite: false,
  // @ts-ignore
  onSaveTableConfiguration: noop,
  toggleEditMode: noop,
  setTotalColumnsWidth: noop,
  shouldShowConfirmation: false,
  setShouldShowConfirmation: noop,
  setTableState: noop,
  cancelConfigModalVisible: noop,
  setCancelConfigModalVisible: noop,
  loading: false,
  sortParams: { id: 'id', desc: true },
  totalCount: 0,
  preventFromHideColumns: [],
  withFilters: true,
  handleOrderChange: undefined,
  fetchData: noop as () => Promise<void>,
  newButtonLabel: undefined,
  onNewClick: undefined,
  newButtonDisabled: false,
  isDraggingEnabled: undefined,
  withColumnConfiguration: true,
  filterSelectOptions: [],
  getEmptyMessageDescription: () => '',
  isVisibleBulkSelectionColumn: false,
  setIsVisibleBulkSelectionColumn: noop,
  bulkSelectionList: [],
  setBulkSelectionList: noop,
  onBulkSelectionRemoveAction: async () => noop(),
  onBulkSelectionAddAction: async () => noop(),
  hasListPermissions: true,
  resetCustomOffset: noop,
  preferencesId: undefined,
  columnConfigurationLoading: false,
  withoutSort: false,
  isPageSizeDisabled: false,
  pageSizes: DEFAULT_TABLE_PAGE_SIZES,
  preventInteractionWithColumns: false,
  preventOverflow: false,
  withBordersAroundTable: false,
  fullWidth: false,
  alwaysDisplayPagination: false,
  customTableId: '',
});

export const TableContextProvider = TableContext.Provider;
export const useTableContext = () => useContext(TableContext);
