import { FilterAllowedValues } from 'store/actions/filtersActions';
import { ColumnsMetadata } from 'utils/types/api/table.types';
import { TPredicateTypes } from 'utils/types/predicates.types';

export interface ColumnFilter {
  alias: string;
  type: string;
}

export interface ColumnSelectProps {
  columns: MappedObject<ColumnsMetadata>;
  usedColumns?: string[];
  onChange: any;
  defaultValue?: string[];
  additionalClassName?: string;
  onPredicateSetChange: (predicateSet: PredicateSet | undefined) => void;
  predicateSet: PredicateSet | undefined;
  label?: string;
}

export enum PredicateSet {
  ClassFields = 'classFields',
  RecordProperties = 'recordProperties',
}

export interface FieldWithAliasAndLabel {
  alias: string;
  label: string;
}

export interface SelectColumnProps
  extends Omit<
    ColumnSelectProps,
    'columns' | 'usedColumns' | 'onPredicateSetChange'
  > {
  isObjectRecords: boolean;
  getScrollableParent: () => HTMLElement;
  fields: FieldWithAliasAndLabel[];
  nameFields: string[];
}

export interface AutocompleteObjectClassFieldFilterValue {
  field_type: TPredicateTypes;
  key: string;
  label: string;
  predicates: string[];
  text: string;
  value: string;
  values?: FilterAllowedValues;
}
