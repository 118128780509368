import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const usePageLimitExceededStyles = createUseStyles((theme: ThemeState) => {
  return {
    container: {
      position: 'relative',
      top: '20%',
    },
    illustration: {
      display: 'block',
      margin: '0 auto',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      marginTop: 20,
      fontSize: 14,
      linHeight: 1.3,
      color: theme['secondary-color'],
    },
  };
});

export default usePageLimitExceededStyles;
