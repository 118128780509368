import { createUseStyles } from 'react-jss';

const useHelpPanelStyles = createUseStyles({
  title: {
    fontSize: 32,
    fontWeight: 'bold',
    width: 'calc(100% - 30px)',
    maxWidth: '100%',
  },
  wrapper: {
    paddingTop: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  helpPanel: {
    padding: 15,
    maxWidth: '100%',
    '& p': {
      wordBreak: 'break-word',
    },
    '& img': {
      maxWidth: '100%',
    },
    '& pre': {
      background: '#f7f7f7',
      border: '1px solid #ddd',
      borderRadius: 3,
      padding: [10, 15],
      overflow: 'auto',
      whiteSpace: 'pre',
      margin: '1em 0',
    },
    '& blockquote': {
      borderLeft: '1px solid #ddd',
      color: '#5a6d7c',
      fontStyle: 'italic',
      padding: [0, 15],
      display: 'block',
      marginBlockStart: '1em',
      marginBlockEnd: '1em',
      marginInlineStart: '40px',
      marginInlineEnd: '40px',
    },
    '& iframe': {
      maxWidth: '100%',
    },
    '& .wysiwyg-color-black': { color: '#000' },
    '& .wysiwyg-color-black70': { color: '#4d4d4d' },
    '& .wysiwyg-color-black60': { color: '#666666' },
    '& .wysiwyg-color-black50': { color: 'gray' },
    '& .wysiwyg-color-black40': { color: '#999999' },
    '& .wysiwyg-color-black30': { color: '#b3b3b3' },
    '& .wysiwyg-color-black20': { color: '#cccccc' },
    '& .wysiwyg-color-black10': { color: '#e6e6e6' },
    '& .wysiwyg-color-red': { color: '#F00' },
    '& .wysiwyg-color-orange': { color: '#F90' },
    '& .wysiwyg-color-yellow': { color: '#FF0' },
    '& .wysiwyg-color-green': { color: '#0F0' },
    '& .wysiwyg-color-cyan': { color: '#0FF' },
    '& .wysiwyg-color-blue': { color: '#00F' },
    '& .wysiwyg-color-purple': { color: '#90F' },
    '& .wysiwyg-color-pink': { color: '#F0F' },
    '& .wysiwyg-color-red90': { color: '#ff3333' },
    '& .wysiwyg-color-red80': { color: '#ff6666' },
    '& .wysiwyg-color-red70': { color: '#ff9999' },
    '& .wysiwyg-color-red110': { color: '#cc0000' },
    '& .wysiwyg-color-red120': { color: '#990000' },
    '& .wysiwyg-color-red130': { color: '#660000' },
    '& .wysiwyg-color-orange90': { color: '#ffad33' },
    '& .wysiwyg-color-orange80': { color: '#ffc266' },
    '& .wysiwyg-color-orange70': { color: '#ffd699' },
    '& .wysiwyg-color-orange110': { color: '#cc7a00' },
    '& .wysiwyg-color-orange120': { color: '#995c00' },
    '& .wysiwyg-color-orange130': { color: '#663d00' },
    '& .wysiwyg-color-yellow90': { color: '#ffff33' },
    '& .wysiwyg-color-yellow80': { color: '#ffff66' },
    '& .wysiwyg-color-yellow70': { color: '#ffff99' },
    '& .wysiwyg-color-yellow110': { color: '#cccc00' },
    '& .wysiwyg-color-yellow120': { color: '#999900' },
    '& .wysiwyg-color-yellow130': { color: '#666600' },
    '& .wysiwyg-color-green90': { color: '#33ff33' },
    '& .wysiwyg-color-green80': { color: '#66ff66' },
    '& .wysiwyg-color-green70': { color: '#99ff99' },
    '& .wysiwyg-color-green110': { color: '#00cc00' },
    '& .wysiwyg-color-green120': { color: '#009900' },
    '& .wysiwyg-color-green130': { color: '#006600' },
    '& .wysiwyg-color-cyan90': { color: '#33ffff' },
    '& .wysiwyg-color-cyan80': { color: '#66ffff' },
    '& .wysiwyg-color-cyan70': { color: '#99ffff' },
    '& .wysiwyg-color-cyan110': { color: '#00cccc' },
    '& .wysiwyg-color-cyan120': { color: '#009999' },
    '& .wysiwyg-color-cyan130': { color: '#006666' },
    '& .wysiwyg-color-blue90': { color: '#3333ff' },
    '& .wysiwyg-color-blue80': { color: '#6666ff' },
    '& .wysiwyg-color-blue70': { color: '#9999ff' },
    '& .wysiwyg-color-blue110': { color: '#0000cc' },
    '& .wysiwyg-color-blue120': { color: '#000099' },
    '& .wysiwyg-color-blue130': { color: '#000066' },
    '& .wysiwyg-color-purple90': { color: '#ad33ff' },
    '& .wysiwyg-color-purple80': { color: '#c266ff' },
    '& .wysiwyg-color-purple70': { color: '#d699ff' },
    '& .wysiwyg-color-purple110': { color: '#7a00cc' },
    '& .wysiwyg-color-purple120': { color: '#5c0099' },
    '& .wysiwyg-color-purple130': { color: '#3d0066' },
    '& .wysiwyg-color-pink90': { color: '#ff33ff' },
    '& .wysiwyg-color-pink80': { color: '#ff66ff' },
    '& .wysiwyg-color-pink70': { color: '#ff99ff' },
    '& .wysiwyg-color-pink110': { color: '#cc00cc' },
    '& .wysiwyg-color-pink120': { color: '#990099' },
    '& .wysiwyg-color-pink130': { color: '#660066' },
    '& .wysiwyg-font-size-x-large': {
      fontSize: 'x-large',
      fontWeight: 800,
    },
    '& .wysiwyg-font-size-large': {
      fontSize: 'large',
      fontWeight: 600,
    },
    '& .wysiwyg-font-size-medium': {
      fontSize: 'small',
    },
    '& .wysiwyg-font-size-small': {
      fontSize: 'x-small',
    },
    '& .wysiwyg-underline': { textDecoration: 'underline' },
    '& .wysiwyg-text-align-right': { textAlign: 'right' },
    '& .wysiwyg-text-align-center': { textAlign: 'center' },
    '& .wysiwyg-text-align-left': { textAlign: 'left' },
    '& .wysiwyg-text-align-justify': { textAlign: 'justify' },
    '& .wysiwyg-indent1': { paddingLeft: 10 },
  },
});

export default useHelpPanelStyles;
