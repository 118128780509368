import { ColumnRatiosOptionType } from './types';

export const defaultColumnRatiosValue: { [key: number]: string } = {
  1: '100',
  2: '50:50',
  3: '33:33:33',
};

export const defaultColumnRatiosOptions: {
  [key: number]: ColumnRatiosOptionType[];
} = {
  1: [
    {
      value: '100',
      label: '100',
    },
  ],
  2: [
    {
      value: '50:50',
      label: '50:50',
    },
    {
      value: '33:67',
      label: '33:67',
    },
    {
      value: '67:33',
      label: '67:33',
    },
    {
      value: '25:75',
      label: '25:75',
    },
    {
      value: '75:25',
      label: '75:25',
    },
    {
      value: '12.5:87.5',
      label: '12.5:87.5',
    },
    {
      value: '87.5:12.5',
      label: '87.5:12.5',
    },
  ],
  3: [
    {
      value: '33:33:33',
      label: '33:33:33',
    },
    {
      value: '50:25:25',
      label: '50:25:25',
    },
    {
      value: '25:50:25',
      label: '25:50:25',
    },
    {
      value: '25:25:50',
      label: '25:25:50',
    },
    {
      value: '12.5:12.5:75',
      label: '12.5:12.5:75',
    },
    {
      value: '12.5:75:12.5',
      label: '12.5:75:12.5',
    },
    {
      value: '75:12.5:12.5',
      label: '75:12.5:12.5',
    },
  ],
};

export const columnsSizes: {
  [key: string]: number[];
} = {
  '100': [24],

  '50:50': [12, 12],
  '33:67': [8, 16],
  '67:33': [16, 8],
  '25:75': [6, 18],
  '75:25': [18, 6],
  '12.5:87.5': [3, 21],
  '87.5:12.5': [21, 3],

  '33:33:33': [8, 8, 8],
  '50:25:25': [12, 6, 6],
  '25:50:25': [6, 12, 6],
  '25:25:50': [6, 6, 12],
  '12.5:12.5:75': [3, 3, 18],
  '12.5:75:12.5': [3, 18, 3],
  '75:12.5:12.5': [18, 3, 3],
};

export const columnPercentageValue: {
  [key: number]: string;
} = {
  3: '12.5',
  6: '25',
  8: '33',
  12: '50',
  16: '67',
  18: '75',
  21: '87.5',
  24: '100',
};

export const defaultSpacingAround = 10;
export const defaultSpacingWithin = 10;
export const defaultLandingPageSpacing = 0;
