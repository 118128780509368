import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { permissionsSelector } from 'store/selectors/permissionsSelectors';
import { buildUrlPermissionDict } from 'utils/permissions';

const usePermissions = () => {
  const permissions = useSelector(permissionsSelector);

  const { pathname } = useLocation();

  const access = useMemo(() => buildUrlPermissionDict(permissions), [
    permissions,
  ]);

  const getMatchingPermission = useMemo(() => {
    const matchingKey = Object.keys(access).find(
      key => !!matchPath(pathname, key)
    );

    if (!matchingKey) return false;

    return access[matchingKey];
  }, [access, pathname]);

  const hasPermissionToRoute = useCallback(
    (route: string) => access[route] || false,
    [access]
  );

  return {
    loading: permissions.loading,
    access: getMatchingPermission,
    all: access,
    hasPermissionToRoute,
  };
};

export default usePermissions;
