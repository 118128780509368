import React, { useCallback } from 'react';
import { useWindowContext } from 'components/lib/FlexLayout/WindowContext';
import { CONTENT_ID, scrollableContainer, SIDE_PANEL } from 'utils/elementsIds';

const useScrollableParent = (
  elementRef: React.RefObject<HTMLElement>,
  scrollableParentId?: string
) => {
  const { windowRef } = useWindowContext();

  return useCallback(() => {
    let parent = elementRef.current?.parentElement;
    let count = 0;

    const scrollableElements = [
      CONTENT_ID,
      SIDE_PANEL,
      scrollableContainer,
      ...(scrollableParentId ? [scrollableParentId] : []),
    ];

    while (
      parent !== undefined &&
      parent !== null &&
      parent !== document.body &&
      count < 50
    ) {
      const elementId = parent?.getAttribute('id');

      if (!!elementId && scrollableElements.includes(elementId)) return parent;

      parent = parent?.parentElement;
      count++;
    }

    return windowRef?.document?.body || document.body;
  }, [windowRef, elementRef, scrollableParentId]);
};

export default useScrollableParent;
