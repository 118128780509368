import { GetResponse } from '../../utils/types';
import { SingleProvider } from './types';
import { AutocompleteSelectValue } from '../../utils/types/selectInput.types';

export const dataProvidersTransform = ({
  results,
}: GetResponse<SingleProvider>): AutocompleteSelectValue[] =>
  results.map(item => ({
    label: item.name,
    value: item.id,
  }));
