import React from 'react';
import clsx from 'clsx';
import { ReactComponent as InputImage } from 'img/icons/textbox-icon.svg';
import useCustomWidgetsStyles from '../../customWidgetsStyles';
import useFormPreviewStyles from 'components/FormPreview2/styles';
import { FormLabelWithToolTip } from 'pages/TaskTemplates/components/FormLabel';
import { FormItem } from 'components/lib/Form';
import { Widget } from 'alx-dynamic-form';
import { TextArea } from 'components/lib/Input';
import StandardFieldWrapper from 'components/FormPreview2/components/StandardFieldWrapper';
import { fixOnlyMultipleSpacesInText } from 'components/Inputs/hooks';

const CustomTextareaWidget: Widget = ({
  value,
  onChange,
  maxLength,
  description,
  errors,
  required,
  label,
  reValidateField,
  disabled,
  fieldAlias,
}) => {
  const classes = useCustomWidgetsStyles({});
  const styles = useFormPreviewStyles({});

  const valueLength = (value as string)?.length || 0;

  const customOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;

    if (maxLength && newValue?.length > maxLength) {
      return onChange(newValue.substring(0, maxLength));
    }

    return onChange(newValue);
  };

  const onBlur = () => {
    if (typeof value === 'string') {
      const trimmedValue = fixOnlyMultipleSpacesInText(value).trim();
      reValidateField();
      return onChange(trimmedValue);
    }
  };

  return (
    <>
      <FormLabelWithToolTip
        dynamicSpacing
        {...{ required }}
        className={clsx([classes.inputLabel, classes.inputLabelEllipsis])}
        label={label}
      />
      <FormItem
        dynamicSpacing
        validateStatus={errors?.length ? 'error' : undefined}
      >
        <StandardFieldWrapper {...{ description, errors }}>
          <div className={classes.textAreaWidget}>
            <div className={classes.textWidgetWrapper}>
              <TextArea
                data-testid={`multi-line-input-${fieldAlias}`}
                value={value as string}
                onChange={customOnChange}
                onBlur={onBlur}
                className={classes.textArea}
                disableTrimSpaces
                disableTextTrim
                {...{ disabled }}
              />
              <div className={classes.textareaIconWrapper}>
                <InputImage className={classes.icon} width={16} height={16} />
              </div>
            </div>
            {!!maxLength ? (
              <div
                className={clsx([classes.textAreaWrapper, classes.onlyCounter])}
              >
                <span
                  className={clsx([styles.counter, classes.counterShrink])}
                >{`${valueLength} / ${maxLength}`}</span>
              </div>
            ) : null}
          </div>
        </StandardFieldWrapper>
      </FormItem>
    </>
  );
};

export default CustomTextareaWidget;
