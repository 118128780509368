import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  SET_DOCUMENT_TEMPLATES,
  SET_DOCUMENT_TEMPLATES_FETCHING,
  APPEND_DOCUMENT_TEMPLATES,
  RESET_DOCUMENT_TEMPLATES,
  SET_DOCUMENT_TEMPLATES_COLUMNS,
  RESET_DOCUMENT_TEMPLATES_COLUMNS,
  SET_DOCUMENT_TEMPLATES_SELECTED_ROW,
  SET_DOCUMENT_TEMPLATES_RESTRICTIONS,
} from 'store/constants/documentTemplate.consts';
import {
  appendTableContent,
  resetTable,
  setTableContent,
  setTableLoading,
  resetTableColumns,
  setTableColumns,
  setSelectedRow,
} from './tablesCommonStateModificators';
import { DocumentTemplate } from 'utils/types/api/documentTemplate.types';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import { CommonTableState } from 'utils/types/api/table.types';
import cloneDeep from 'lodash/cloneDeep';

const initialState = cloneDeep(INITIAL_TABLE_STATE);
const documentTemplateReducer: Reducer<
  CommonTableState<DocumentTemplate>,
  RootAction
> = (state = initialState, action) => {
  switch (action.type) {
    case SET_DOCUMENT_TEMPLATES: {
      return setTableContent<DocumentTemplate, typeof SET_DOCUMENT_TEMPLATES>(
        state,
        action
      );
    }
    case APPEND_DOCUMENT_TEMPLATES:
      return appendTableContent<
        DocumentTemplate,
        typeof APPEND_DOCUMENT_TEMPLATES
      >(state, action);

    case SET_DOCUMENT_TEMPLATES_FETCHING:
      return setTableLoading<
        DocumentTemplate,
        typeof SET_DOCUMENT_TEMPLATES_FETCHING
      >(state, action);

    case RESET_DOCUMENT_TEMPLATES:
      return resetTable<DocumentTemplate>(state, initialState);

    case RESET_DOCUMENT_TEMPLATES_COLUMNS:
      return resetTableColumns<DocumentTemplate>(state, initialState);

    case SET_DOCUMENT_TEMPLATES_COLUMNS:
      return setTableColumns<
        DocumentTemplate,
        typeof SET_DOCUMENT_TEMPLATES_COLUMNS
      >(state, action);
    case SET_DOCUMENT_TEMPLATES_SELECTED_ROW:
      return setSelectedRow<
        DocumentTemplate,
        typeof SET_DOCUMENT_TEMPLATES_SELECTED_ROW
      >(state, action);
    case SET_DOCUMENT_TEMPLATES_RESTRICTIONS:
      return {
        ...state,
        restrictions: {
          limit_items: action.restrictions.limit_items_in_object_class,
        },
      };
    default:
      return state;
  }
};

export default documentTemplateReducer;
