import './why-did-you-render';
import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from 'components/App';

import 'antd/dist/antd.less';
import 'styles/index.css';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/utils.css';
import 'styles/flex-layout/style/style.css';

// transfers sessionStorage from one tab to another
const sessionStorageTransfer = function (event) {
  if (!event) {
    event = Event;
  }
  if (!event.newValue) return; // do nothing if no value to work with
  if (event.key === 'getSessionStorage') {
    // another tab asked for the sessionStorage -> send it
    localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
    // the other tab should now have it, so we're done with it.
    localStorage.removeItem('sessionStorage'); // <- could do short timeout as well.
  } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
    // another tab sent data <- get it
    const data = JSON.parse(event.newValue);
    for (let key in data) {
      sessionStorage.setItem(key, data[key]);
    }
  }
};

// listen for changes to localStorage
if (window.addEventListener) {
  window.addEventListener('storage', sessionStorageTransfer, false);
} else {
  window.attachEvent('onstorage', sessionStorageTransfer);
}

// Ask other tabs for session storage (this is ONLY to trigger event)
if (!sessionStorage.length) {
  localStorage.setItem('getSessionStorage', 'foobar');
  localStorage.removeItem('getSessionStorage', 'foobar');
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
