import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  getDecimalFormat,
  getThousandSeparator,
} from 'store/selectors/preferencesSelectors';

const useFormatNumber = () => {
  const thousandSeparator = useSelector(getThousandSeparator);
  const decimalFormat = useSelector(getDecimalFormat);

  const formatWithThousandSeparator = useCallback(
    (value: number) =>
      value
        ?.toString()
        ?.replace(/\B(?=(\d{3}){1,16}(?!\d))/g, thousandSeparator),
    [thousandSeparator]
  );

  return useCallback(
    (value: number | undefined) => {
      if (value === null || value === undefined) return '';

      const [integerNumber, decimalNumber] = value.toString().split(/[,. ]/);

      const formatedIntegerValue = formatWithThousandSeparator(
        Number(integerNumber)
      );

      if (decimalNumber === undefined) return formatedIntegerValue;

      return `${formatedIntegerValue}${decimalFormat}${decimalNumber}`;
    },
    [decimalFormat, formatWithThousandSeparator]
  );
};

export default useFormatNumber;
