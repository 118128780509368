import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import useRefetchResource from 'hooks/useRefetchResource';
import { RefetchResources } from 'contexts/types';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { apiCall } from 'utils/api';
import { OBJECT_RECORD_HISTORY } from 'utils/endpoints';
import { GetResponse } from 'utils/types';
import { isSuccess } from 'utils/apiUtils';
import { EventHistory, EventQueryParams, EventQueryPredicates } from './types';
import { separateUpdateFieldEvents } from './utils';
import axios from 'axios';

export const useRecordHistory = () => {
  const { selectedResource } = useSelectedResourceContext();
  const intl = useIntl();

  const [events, setEvents] = useState<GetResponse<EventHistory>>();
  const [queryParams, setQueryParams] = useState<EventQueryPredicates>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{
    title: string;
    description: string;
    status?: number;
  }>();

  const fetchRecordHistory = useCallback(
    async (id: string, offset = 0, limit = 50) => {
      setLoading(true);
      setError(undefined);
      const query: EventQueryParams = {
        event_type__in: queryParams?.event_type__in?.join(','),
        source_id__in: queryParams.source_id__in?.map(el => el.value).join(','),
        event_datetime__range: queryParams?.event_datetime__range
          ?.map(el => el.toISOString())
          .join(','),
        field_id: queryParams?.field_id?.id?.toString(),
        source_type__in: queryParams?.source_type__in?.toString(),
      };

      try {
        const { data, status } = await apiCall.get<GetResponse<EventHistory>>(
          generatePath(OBJECT_RECORD_HISTORY, { id }),
          { params: { offset, limit, ...query } }
        );

        if (isSuccess(status)) {
          setEvents(prev => {
            const newEvents =
              prev?.results && offset > 0
                ? { ...data, results: [...prev.results, ...data.results] }
                : data;

            if (!newEvents.results.length) {
              setError({
                title: intl.formatMessage({
                  id: 'misc.noResults',
                  defaultMessage: 'No results',
                }),
                description: intl.formatMessage({
                  id: 'misc.pleaseCheckFilters',
                  defaultMessage: 'Please check your filters.',
                }),
              });
            }

            return separateUpdateFieldEvents(
              newEvents,
              queryParams.field_id?.id
            );
          });
        }
      } catch (error) {
        setEvents(undefined);
        setError({
          title: '',
          description: '',
          status: axios.isAxiosError(error) ? error.response?.status : 500,
        });
      } finally {
        setLoading(false);
      }
    },
    [intl, queryParams]
  );

  //Refreshing activity log after some values has changed.
  //Delayed because events are being recorded asynchronously in database.
  //Workaround approved by Andrzej.
  const refreshData = () => {
    setTimeout(() => {
      if (selectedResource?.record?.recordId) fetchRecordHistory(selectedResource.record.recordId);
    }, 1500);
  };
  const refreshDataSync = () => {
    if (selectedResource?.record?.recordId) fetchRecordHistory(selectedResource.record.recordId);
  };
  useRefetchResource(RefetchResources.Records, refreshData, 'activityLog');
  useRefetchResource(RefetchResources.RecordAccess, refreshData, 'activityLog');

  const fetchNextPage = useCallback(() => {
    if (!!events && selectedResource?.record?.recordId) {
      fetchRecordHistory(selectedResource.record.recordId, events.offset + 1);
    }
  }, [selectedResource, events, fetchRecordHistory]);

  useEffect(() => {
    if (
      selectedResource?.record?.identifier &&
      selectedResource?.record?.recordId
    ) {
      setEvents(undefined);
      fetchRecordHistory(selectedResource.record.recordId);
    }
  }, [selectedResource, fetchRecordHistory, queryParams]);

  return {
    identifier: selectedResource?.record?.identifier,
    events,
    loading,
    error,
    hasMorePages: !!events && events.filtered_count > events.results.length,
    fetchNextPage,
    queryParams,
    setQueryParams,
    refreshDataSync,
  };
};
