export enum ActivateAccountFormFields {
  Email = 'email',
  Password = 'password',
  ConfirmPassword = 'confirm_password',
}

export enum ActivationTokenError {
  TokenRequestPending = 'ERR_TOKEN_REQUEST_PENDING',
  TokenExpired = 'ERR_TOKEN_EXPIRED',
}
