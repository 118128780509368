export const SET_USER_GROUP_PERMISSIONS_OWNERS =
  'SET_USER_GROUP_PERMISSIONS_OWNERS';
export const SET_USER_GROUP_PERMISSIONS_OWNERS_FETCHING =
  'SET_USER_GROUP_PERMISSIONS_OWNERS_FETCHING';
export const SET_USER_GROUP_PERMISSIONS_OWNERS_OPTIONS =
  'SET_USER_GROUP_PERMISSIONS_OWNERS_OPTIONS';
export const USER_GROUP_PERMISSIONS_IS_REMOVING_HIMSELF =
  'USER_GROUP_PERMISSIONS_IS_REMOVING_HIMSELF';
export const SET_USER_GROUP_SELECTED_PERMISSION_SET =
  'SET_USER_GROUP_SELECTED_PERMISSION_SET';
export const CLEAR_USER_GROUP_PERMISSION_SETS_ASSIGNEES =
  'CLEAR_USER_GROUP_PERMISSION_SETS_ASSIGNEES';
export const SET_USER_GROUP_PERMISSION_SET_ASSIGNEES =
  'SET_USER_GROUP_PERMISSION_SET_ASSIGNEES';
export const SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_FETCHING =
  'SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_FETCHING';
export const SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_OPTIONS =
  'SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_OPTIONS';
export const SET_USER_GROUP_PERMISSIONS_USER_GROUP =
  'SET_USER_GROUP_PERMISSIONS_USER_GROUP';
export const SET_USER_GROUP_PERMISSIONS_PERMISSION_SETS =
  'SET_USER_GROUP_PERMISSIONS_PERMISSION_SETS';
export const SET_USER_GROUP_PERMISSIONS_PERMISSION_SETS_FETCHING =
  'SET_USER_GROUP_PERMISSIONS_PERMISSION_SETS_FETCHING';
export const SET_USER_GROUP_PERMISSIONS_PERMISSION_SET =
  'SET_USER_GROUP_PERMISSIONS_PERMISSION_SET';
export const RESET_USER_GROUP_PERMISSIONS_STATE =
  'RESET_USER_GROUP_PERMISSIONS_STATE';
export const REMOVE_USER_GROUP_PERMISSION_SET =
  'REMOVE_USER_GROUP_PERMISSION_SET';
export const CLEAR_USER_GROUP_PERMISSION_SETS_ASSIGNEES_GROUPS =
  'CLEAR_USER_GROUP_PERMISSION_SETS_ASSIGNEES_GROUPS';
export const SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS =
  'SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS';
export const SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS_FETCHING =
  'SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS_FETCHING';
export const SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS_OPTIONS =
  'SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS_OPTIONS';
export const SET_USER_GROUP_PERMISSIONS_USER_GROUP_PERMISSION_SET_OPTIONS =
  'SET_USER_GROUP_PERMISSIONS_USER_GROUP_PERMISSION_SET_OPTIONS';
