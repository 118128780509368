import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useEditRecordFormStyles = createUseStyles((theme: ThemeState) => {
  return {
    wrapper: {
      paddingTop: 15,
      '&, & > div, & > div #formBody': {
        display: 'flex',
        flexDirection: 'column',
      },
      '& #formBody': {
        maxHeight: 'initial',
        padding: '0px 5px',
      },
    },
    loaderWrapper: {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    noRecordViewTitleRow: {
      padding: '0px 15px',
      marginBottom: 15,
      display: 'flex',
      justifyContent: 'space-between',
    },
    noRecordViewMessage: {
      textAlign: 'center',
    },
    title: {
      fontSize: 20,
      fontWeight: 700,
      color: theme['text-color'],
    },
  };
});

export default useEditRecordFormStyles;
