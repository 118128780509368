import React from 'react';
import { PermissionMenuItemsSeparatorProps } from './types';
import useMenuStyles from './styles';

const PermissionMenuItemsSeparator = ({
  visible,
}: PermissionMenuItemsSeparatorProps) => {
  const classes = useMenuStyles({});

  return visible ? <hr className={classes.menuItemsSeparator} /> : null;
};

export default PermissionMenuItemsSeparator;
