import { Action, AnyAction, Store } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getStoredState, REHYDRATE, PersistConfig } from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { RootState } from 'store/reducers';

type StoreType = Store<RootState & PersistPartial, Action<any>> & {
  dispatch: ThunkDispatch<RootState, undefined, AnyAction>;
};

export const crossBrowserListener = (
  { dispatch }: StoreType,
  persistConfig: PersistConfig<RootState>
) => async () => {
  const persistedState = await getStoredState(persistConfig);

  dispatch({
    type: REHYDRATE,
    key: persistConfig.key,
    payload: persistedState,
  });
};
