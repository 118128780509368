import React from 'react';
import LoaderOverlay from 'components/LoaderOverlay';
import { LoadingWrapperProps } from './types';

const LoadingWrapper: React.FC<LoadingWrapperProps> = ({
  loading,
  children,
}) => {
  return loading ? <LoaderOverlay /> : <>{children}</>;
};

export default LoadingWrapper;
