import isEmpty from 'lodash/isEmpty';
import { RootState } from 'store/reducers';

export const selectObjectClassesFieldsData = (state: RootState) =>
  state.objectClassesFields.allIds.map(id => {
    const objectClasses = state.objectClassesFields.byId[id];

    return {
      ...objectClasses,
      key: objectClasses.id,
    };
  });

export const getObjectClassesFieldsSelectedRow = (state: RootState) => {
  return state.objectClassesFields?.selectedRow;
};

export const getIsObjectClassesFieldsTableLoaded = (state: RootState) => {
  return !isEmpty(state.objectClassesFields?.columns);
};

export const getObjectClassInitialFields = (state: RootState) => {
  return state.objectClassesFields?.initialFields;
};

export const getObjectClassFieldsLoading = (state: RootState) => {
  return state.objectClassesFields?.fetching;
};

export const getObjectClassesFieldsTotal = (state: RootState) => {
  return state.objectClassesFields?.total || 0;
};

export const getObjectClassesSystemFieldsTotal = (state: RootState) => {
  return state.objectClassesFields?.details?.system_fields?.length || 0;
};

export const getObjectClassFieldsDetails = (state: RootState) => {
  return state.objectClassesFields?.details;
};
