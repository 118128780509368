import React from 'react';
import { Alert as AntAlert } from 'antd';
import { AlertProps } from 'antd/lib/alert';
import useAlertStyles from './styles';
import clsx from 'clsx';

export const Alert: React.FC<AlertProps> = ({ type, className, ...rest }) => {
  const classes = useAlertStyles({ type });

  return (
    <AntAlert
      {...{ type, ...rest }}
      className={clsx([classes.alert, className])}
    />
  );
};
