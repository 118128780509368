import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import NotificationType from 'utils/Enums/NotificationType';
import { UseLimitAlertParmas } from './types';

export default ({ currentAssigneesCount, limit }: UseLimitAlertParmas) => {
  const intl = useIntl();

  const notificationType: NotificationType | undefined = useMemo(() => {
    if (limit === undefined) return;

    if (currentAssigneesCount >= limit) return NotificationType.Error;
    else if (currentAssigneesCount >= limit * 0.9)
      return NotificationType.Warning;
    else if (currentAssigneesCount >= limit * 0.8) return NotificationType.Info;
  }, [currentAssigneesCount, limit]);

  const message = useMemo(() => {
    if (notificationType === undefined || limit === undefined) return;

    if (notificationType === NotificationType.Error)
      return intl.formatMessage(
        {
          id: 'tasksPanel.form.errorLimitMessage',
          defaultMessage:
            'Maximum of {limit} {limit, plural, one {assignee} other {assignees}} has been reached.',
        },
        { limit }
      );
    else if (
      [NotificationType.Warning, NotificationType.Info].includes(
        notificationType
      )
    ) {
      return intl.formatMessage(
        {
          id: 'tasksPanel.form.infoLimitMessage',
          defaultMessage:
            'Each stage can have up to {limit} {limit, plural, one {assignee} other {assignees}}. This currently has {currentAssigneesCount}.',
        },
        { limit, currentAssigneesCount }
      );
    }
  }, [notificationType, limit, intl, currentAssigneesCount]);

  return {
    message,
    notificationType,
  };
};
