import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import {
  panelWrapper,
  getPanelTitle,
  buttonsWrapper,
  getIdentifier,
} from 'styles/panelsStyles';

type UseTasksPanelStylesProps = {
  withMinHeight?: boolean;
};

const useTasksPanelStyles = createUseStyles((theme: ThemeState) => {
  return {
    container: {
      height: '100%',
      '& .ant-spin-nested-loading, .ant-spin-container': {
        height: '100%',
      },
    },
    panelWrapper,
    panelTitle: getPanelTitle(theme),
    identifier: {
      ...getIdentifier(theme),
      marginBottom: 15,
    },
    formWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    buttonsWrapper,
    listElementWrapper: {
      padding: 0,
    },
    dueDateSection: {
      display: 'flex',
      width: '100%',
      alignItems: 'flex-end',
    },
    dueDatePicker: {
      width: '100%',
    },
    sectionWrapper: ({ withMinHeight }: UseTasksPanelStylesProps) => ({
      '& .ant-collapse-header': {
        margin: '0px -15px 15px',
      },
      '& .ant-collapse-content': {
        minHeight: withMinHeight ? '200px' : 'unset',
      },
    }),
    formContent: {
      flex: 1,
      padding: [35, 28],
    },
    autocompleteSelectWrapper: {
      position: 'relative',
      paddingLeft: 1,
    },
    autocompleteSelect: {
      '& .ant-select-selector': {
        height: '34px !important',
      },
      '& .ant-select-selection-search-input': {
        height: '32px !important',
      },
      '& .ant-select-selection-placeholder': {
        lineHeight: '32px !important',
      },
    },
    stagesAssignmentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    assigneesWrapper: {
      display: 'flex',
      alignItems: 'baseline',
      flexDirection: 'column',
      '& .ant-col': {
        width: '100%',
      },
    },
    avatarWrapper: {
      display: 'flex',
      paddingTop: 4,
      alignItems: 'baseline',
    },
    editLink: {
      paddingLeft: 6,
      '& .ant-btn-link': {
        padding: '8px 0',
      },
    },
    popoverWrapper: {
      maxWidth: 250,
      width: '100%',
      '& .ant-popover-inner-content': {
        padding: '14px 2px',
      },
      '& .ant-popover-inner': {
        background: '#fff',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        borderRadius: 5,
      },
      '& .ant-popover-arrow': {
        display: 'none',
      },
    },
    selectedUsersList: {
      maxHeight: '270px',
      height: '100%',
      overflowY: 'auto',
      padding: '0px 10px',
    },
    userWrapper: {
      padding: '2px 0',
    },
    alertWrapper: {
      marginTop: 10,
    },
    assigneesLimitTooltip: {
      '& .ant-tooltip-arrow': {
        display: 'none',
      },
    },
  };
});

export default useTasksPanelStyles;
