import { FilterValue } from 'store/actions/filtersActions';
import { SelectedPredicate } from 'components/Filters/Predicate/types';
import { TPredicateValue } from 'utils/types/predicates.types';

export const getCurrentPredicateValue = (
  currentPredicate: FilterValue,
  selectedPredicate: SelectedPredicate
): MappedObject<TPredicateValue> => {
  const {
    value: {
      predicateKey: currentPredicateKey,
      predicateValues: currentPredicateValues,
      predicateArgs: currentPredicateArgs,
      isMultipleSelection,
    },
  } = currentPredicate;
  const {
    key: selectedPredicateKey,
    args: selectedPredicateArgs,
    selectMultiple,
  } = selectedPredicate;

  //prev condition empty
  if (!currentPredicateKey) {
    return {};
  }

  // same selection + opposites keys + same predicate args type = binary has one arg, ternary has more than one arg
  const areBothHasSameSelection = selectMultiple === isMultipleSelection;
  const isOpposite =
    currentPredicateKey.includes(selectedPredicateKey) ||
    selectedPredicateKey.includes(currentPredicateKey);
  const hasSameType =
    currentPredicateArgs?.length === selectedPredicateArgs?.length;

  if (areBothHasSameSelection && (isOpposite || hasSameType)) {
    return currentPredicateValues;
  }

  // any other case
  return {};
};
