import { mergeColours } from 'components/wrappers/ThemeWrapper';
import { RootState } from 'store/reducers';
import { ThemeState } from 'store/reducers/types/theme.types';
import defaultTheme from 'utils/defaultTheme';

export const customThemeSelector = (state: RootState) => state.customTheme.data;

export const customThemeWithDefaults = (state: RootState) => {
  const { colours, isEnabled } = state.customTheme.data;
  if (!colours) {
    return defaultTheme;
  }
  return (mergeColours(
    defaultTheme,
    (colours as unknown) as MappedObject<string>,
    isEnabled
  ) as unknown) as ThemeState;
};
