import React, { useCallback } from 'react';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import ColumnDataTypes from 'utils/Enums/ColumnDataTypes';
import useDateFormat from './useDateFormat';
import { parseToIntlKey } from 'utils/functions/parseToIntlKey';
import { SelectOption } from 'utils/types/selectInput.types';
import { ColumnsMetadata } from 'utils/types/api/table.types';
import { CustomTableGroupKeys } from 'contexts/types';
import MultiselectValues from 'components/Table/DisplayingCustomValues/MultiselectValues';
import { ObjectClassFieldTypes } from 'utils/types/api/objectClassesFields.types';
import { Checkbox } from 'antd';
import Email from 'components/Table/DisplayingCustomValues/Email';
import Documents from 'components/Table/DisplayingCustomValues/Documents';
import moment from 'moment';
import useFormatNumber from './useFormatNumber';
import { CUSTOM_DATE_FORMAT } from 'pages/TaskTemplates/components/TaskForm/consts';
import useTimezone from './useTimezone';

const useColumnsTranslations = (columns: MappedObject<ColumnsMetadata>) => {
  const { dateFormat, getTimeFormat } = useDateFormat();
  const { timezone } = useTimezone();
  const formatNumberWithSeparators = useFormatNumber();

  const getTranslation = useCallback(
    (key: string, value: any, row?: any) => {
      if (!columns) return '';

      const { type, values, autocomplete, groupKey, alias } =
        columns?.[key] ?? {};

      if (groupKey && groupKey !== CustomTableGroupKeys.Default) {
        switch (type as ObjectClassFieldTypes) {
          case ObjectClassFieldTypes.Set: {
            if (!value) {
              return '-';
            }
            return Array.isArray(value) && <MultiselectValues values={value} />;
          }
          case ObjectClassFieldTypes.Boolean:
          case ObjectClassFieldTypes.Bool: {
            return <Checkbox checked={!!value} />;
          }
          case ObjectClassFieldTypes.Email: {
            return !value ? '-' : <Email {...{ value }} />;
          }
          case ObjectClassFieldTypes.Document: {
            return (
              Array.isArray(value) && (
                <Documents {...{ value }} recordId={row?.values?.id} />
              )
            );
          }
          case ObjectClassFieldTypes.Datetime: {
            return value
              ? moment(value)
                  .tz(timezone)
                  .format(`${dateFormat}, ${getTimeFormat()}`)
              : null;
          }
          case ObjectClassFieldTypes.Date: {
            return value
              ? moment(value, CUSTOM_DATE_FORMAT).format(dateFormat)
              : null;
          }
          case ObjectClassFieldTypes.Time: {
            return value ? moment(value, 'HH:mm:ss').format('HH:mm') : null;
          }
          case ObjectClassFieldTypes.Int: {
            return formatNumberWithSeparators(value);
          }
          case ObjectClassFieldTypes.Float: {
            return formatNumberWithSeparators(value);
          }
        }

        return (Array.isArray(value) ? value.join(', ') : value) || '-';
      }

      if (type === ColumnDataTypes.DateTime) {
        if (!!value) {
          return moment(value)
            .tz(timezone)
            .format(`${dateFormat}, ${getTimeFormat()}`);
        }
      }

      if (type === ColumnDataTypes.Enum && values) {
        const valueText = (
          values?.find(({ value: selectValue }: SelectOption) => {
            return selectValue === value;
          }) || {}
        ).text;

        if (valueText) {
          return GlobalIntlSingleton.dynamicFormatMessage({
            id: `enums.${parseToIntlKey(
              typeof value === 'string' ? value : valueText
            )}`,
            defaultMessage: valueText,
          });
        }
      }

      if (
        type === ColumnDataTypes.Enum &&
        typeof value === 'string' &&
        !autocomplete
      ) {
        return GlobalIntlSingleton.dynamicFormatMessage({
          id: `enums.${parseToIntlKey(value)}`,
          defaultMessage: value,
        });
      }

      if (type === ColumnDataTypes.Boolean) {
        return GlobalIntlSingleton.dynamicFormatMessage({
          id: `enums.${value ? 'yes' : 'no'}`,
          defaultMessage: value ? 'Yes' : 'No',
        });
      }

      if (alias !== 'id' && typeof value === 'number')
        return formatNumberWithSeparators(value);

      return value ?? '';
    },
    [columns, dateFormat, getTimeFormat, formatNumberWithSeparators, timezone]
  );

  return {
    getTranslation,
  };
};

export default useColumnsTranslations;
