import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useFormPreviewStyles = createUseStyles((theme: ThemeState) => {
  return {
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    description: {
      maxWidth: 700,
      fontSize: 12,
      marginTop: 'var(--spacingWithin, 6px)',
      fontWeight: 400,
      color: theme['disabled-color'],
      display: 'block',
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap',
    },
    counterAndErrorContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      maxWidth: 700,

      '& [class*="errorMessage-"] ': {
        flex: 1,
        marginTop: 6,
      },
    },
    counter: {
      display: 'flex',
      justifyContent: 'flex-end',
      fontWeight: 400,
      color: theme['disabled-color'],
      fontSize: 12,
      marginTop: 6,
      marginLeft: 9,
    },
    numericField: {
      width: 200,
    },
    checkboxWrapper: {
      display: 'flex',
      alignItems: 'center',
      '& .ant-checkbox-wrapper span:not(.ant-checkbox)': {
        paddingRight: 0,
        wordBreak: 'break-word',
      },
      '& .ant-checkbox input, .ant-checkbox-inner': {
        width: 20,
        height: 20,
        borderColor: theme['checkbox-border-color'],
        borderWidth: 1.5,
      },
      '& .ant-checkbox-wrapper': {
        marginRight: 3,
      },
    },
    previewModalWrapper: {
      paddingTop: 25,
    },
    errorMessage: {
      fontSize: 12,
      color: theme['error-color-light'],
    },
    InstructionWrapper: {
      fontSize: 14,
      marginTop: 6,
      textAlign: 'justify',
      fontWeight: 400,
      color: theme['disabled-color'],
      lineHeight: '21px',
      display: 'block',
      wordBreak: 'break-word',
      whiteSpace: 'break-spaces',
      '& figure > img': {
        width: '100%',
      },
    },
    previewOnlyHeader: {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      backgroundColor: '#FFFFFF',
      padding: '0px 15px',
      height: 46,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    previewOnlyHeaderText: {
      fontSize: 16,
      fontWeight: 700,
    },
    previewOnlyCloseIcon: {
      color: theme['secondary-color'],
    },
    formWrapper: {
      position: 'relative',
      borderLeft: 0,
      borderRight: 0,
      width: '100%',
      '& div.ant-collapse-header': {
        backgroundColor: theme['section-header-bg'],
        color: '#fff !important',
      },
      '& .ant-picker-suffix': {
        color: `${theme['primary-color']} !important`,
      },
    },
  };
});

export default useFormPreviewStyles;
