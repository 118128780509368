import { createUseStyles } from 'react-jss';

const useTooltipStyles = createUseStyles({
  wrapper: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
});

export default useTooltipStyles;
