import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import {
  headerWrapper,
  getResultsInfo,
  panelWrapper,
  getPanelTitle,
  buttonsWrapper,
} from 'styles/panelsStyles';

const useReassignTaskStyles = createUseStyles((theme: ThemeState) => {
  return {
    resultsInfo: getResultsInfo(theme),
    headerWrapper: {
      ...headerWrapper,
      marginBottom: 0,
      padding: '0 15px',
    },
    usersInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    panelTitle: getPanelTitle(theme),
    buttonsWrapper: {
      ...buttonsWrapper,
      position: 'sticky',
      bottom: '0',
      background: '#fff',
      height: 45,
      padding: '0 15px',
      alignItems: 'flex-end',
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: 'calc(100% - 45px)',
      position: 'relative',
    },
    taskName: {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '19px',
    },
    nameWrapper: {
      ...headerWrapper,
      padding: '0 15px',
    },
    listWrapper: {
      height: '100%',
      overflow: 'auto',
    },
    stageWrapper: {
      padding: '0 15px',
    },
    resultsCounter: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '19px',
      color: theme['secondary-color'],
    },
    reassigneTaskPanelWrapper: {
      ...panelWrapper,
      padding: '15px 0',
      '& .ant-collapse-header': {
        margin: '0px 0px 10px',
      },
    },
    addAsssigneePanelWrapper: {
      ...panelWrapper,
      paddindTop: 15,
    },
    subtitle: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '22px',
      color: theme['heading-color'],
      padding: '0 15px',
      margin: 0,
    },
  };
});

export default useReassignTaskStyles;
