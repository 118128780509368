import React, { forwardRef } from 'react';
import { Button as AntButton } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import useButtonStyles from './styles';
import clsx from 'clsx';

export type ButtonPropsWithoutType = Omit<ButtonProps, 'type'>;

export interface ButtonLinkProps extends ButtonPropsWithoutType {
  underlined?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ ...rest }, ref) => <AntButton {...{ ref, ...rest }} />
);

export default Button;

export const ButtonPrimary = forwardRef<
  HTMLButtonElement,
  ButtonPropsWithoutType
>(({ className, ...rest }, ref) => {
  const classes = useButtonStyles({});

  return (
    <Button
      type='primary'
      {...{ ref, ...rest }}
      className={`${classes.button} ${classes.buttonPrimary} ${
        className || ''
      }`}
    />
  );
});

export const ButtonOutlined = forwardRef<
  HTMLButtonElement,
  ButtonPropsWithoutType
>(({ className, ...rest }, ref) => {
  const classes = useButtonStyles({});

  return (
    <Button
      type='default'
      {...{ ref, ...rest }}
      className={`${classes.button} ${classes.buttonOutlined} ${
        className || ''
      }`}
    />
  );
});

export const ButtonSecondaryOutlined = forwardRef<
  HTMLButtonElement,
  ButtonPropsWithoutType
>(({ className, ...rest }, ref) => {
  const classes = useButtonStyles({});

  return (
    <Button
      type='default'
      {...{ ref, ...rest }}
      className={`${classes.button} ${classes.buttonSecondaryOutlined} ${
        className || ''
      }`}
    />
  );
});

export const ButtonSecondary = forwardRef<
  HTMLButtonElement,
  ButtonPropsWithoutType
>(({ className, ...rest }, ref) => {
  const classes = useButtonStyles({});

  return (
    <Button
      type='default'
      {...{ ref, ...rest }}
      className={`${classes.buttonSecondary} ${className || ''}`}
    />
  );
});

export const ButtonTertiary = forwardRef<
  HTMLButtonElement,
  ButtonPropsWithoutType
>(({ className, ...rest }, ref) => {
  const classes = useButtonStyles({});

  return (
    <Button
      type='default'
      {...{ ref, ...rest }}
      className={clsx([classes.button, classes.buttonTertiary, className])}
    />
  );
});

export const ButtonWarning = forwardRef<
  HTMLButtonElement,
  ButtonPropsWithoutType
>(({ ...rest }, ref) => {
  const classes = useButtonStyles({});

  return (
    <Button
      type='primary'
      className={classes.buttonWarning}
      {...{ ref, ...rest }}
    />
  );
});

export const ButtonDanger = forwardRef<
  HTMLButtonElement,
  ButtonPropsWithoutType
>(({ className, ...rest }, ref) => {
  const classes = useButtonStyles({});

  return (
    <Button
      type='primary'
      className={clsx([classes.button, classes.buttonDanger, className])}
      {...{ ref, ...rest }}
    />
  );
});

export const ButtonLink = forwardRef<HTMLButtonElement, ButtonLinkProps>(
  ({ underlined = false, ...rest }, ref) => {
    const classes = useButtonStyles({});

    return (
      <Button
        type='link'
        className={
          !underlined
            ? `no-underline ${classes.buttonLink}`
            : classes.buttonLink
        }
        {...{ ref, ...rest }}
      />
    );
  }
);

export const ButtonDashed = forwardRef<
  HTMLButtonElement,
  ButtonPropsWithoutType
>(({ ...rest }, ref) => {
  const classes = useButtonStyles({});

  return (
    <Button
      type='dashed'
      className={classes.buttonSecondary}
      {...{ ref, ...rest }}
    />
  );
});

export const ButtonDefault = forwardRef<
  HTMLButtonElement,
  ButtonPropsWithoutType
>(({ className, ...rest }, ref) => {
  const classes = useButtonStyles({});

  return (
    <Button
      className={clsx([classes.button, classes.buttonDefault, className])}
      {...{ ref, ...rest }}
    />
  );
});

export const ButtonTransparent = forwardRef<
  HTMLButtonElement,
  ButtonPropsWithoutType
>(({ className, ...rest }, ref) => {
  const classes = useButtonStyles({});

  return (
    <button
      className={clsx([classes.buttonTransparent, className])}
      {...{ ref, ...rest }}
    />
  );
});
