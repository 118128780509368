import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useCustomSelectWidgetStyles = createUseStyles((theme: ThemeState) => {
  return {
    selectStyles: {
      width: 350,
    },
    selectPreview: {
      width: '100%',
      '& .ant-select-selector': {
        borderColor: theme['border-color-base'],
      },
    },
    limitedMaxSizeMid: {
      maxWidth: 350,
      width: 'auto',
    },
    selectContainer: {
      width: '100%',
      position: 'relative',

      '& > span': {
        opacity: 0,
      },

      '&:hover > span': {
        opacity: 1,
      },

      '&:hover .ant-select-selector': {
        borderColor: '#028FDF',
      },
    },
    selectClearIconWrapper: {
      position: 'absolute',
      top: '50%',
      right: '11px',
      transform: 'translateY(-50%)',
      height: '14px',
      width: '14px',
      backgroundColor: '#fff',
      display: 'flex',
      alignItems: 'center',
      color: 'rgba(46, 63, 87, 0.6)',
      cursor: 'pointer',
    },
  };
});

export default useCustomSelectWidgetStyles;
