import React from 'react';
import { CloseIcon } from 'components/Icon';
import { Loader } from 'components/lib/Loader';
import useInPlaceInputAfterStyles from './styles';
import { InPlaceInputAfterProps } from './types';
import clsx from 'clsx';

const InPlaceInputAfter = ({
  isLoading,
  onCloseClick,
  className,
}: InPlaceInputAfterProps) => {
  const classes = useInPlaceInputAfterStyles({});

  if (isLoading)
    return <Loader size='small' className={clsx(classes.loader, className)} />;

  return onCloseClick ? (
    <CloseIcon
      size={16}
      className={clsx(classes.closeIcon, className)}
      onClick={onCloseClick}
    />
  ) : null;
};

export default InPlaceInputAfter;
