import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import NotificationType from 'utils/Enums/NotificationType';

const getAlertColor = (
  notificationType: NotificationType,
  theme: ThemeState
) => {
  switch (notificationType) {
    case NotificationType.Error:
      return theme['error-color'];
    case NotificationType.Warning:
      return theme['avatar-color-2'];
    default:
      return theme['primary-color'];
  }
};

const getAlertBgColor = (
  notificationType: NotificationType,
  theme: ThemeState
) => {
  switch (notificationType) {
    case NotificationType.Error:
      return theme['error-bg-light'];
    case NotificationType.Warning:
      return theme['danger-color-light'];
    default:
      return theme['info-bg'];
  }
};

export const useLimitMessageStyles = createUseStyles((theme: ThemeState) => {
  return {
    wrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    alert: (notificationType: NotificationType) => ({
      display: 'flex',
      alignItems: 'center',
      padding: '9px 7px 7px 9px',
      backgroundColor: getAlertBgColor(notificationType, theme),
      marginBottom: 10,
      '& svg': {
        position: 'initial',
        marginRight: 7,
        color: `${getAlertColor(notificationType, theme)} !important`,
      },
      '& span.ant-alert-message': {
        color: `${getAlertColor(notificationType, theme)} !important`,
        fontSize: 11,
        fontWeight: 400,
        lineHeight: 1.36,
      },
    }),
  };
});
