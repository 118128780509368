import { ConfigProvider, Layout as AntLayout } from 'antd';
import clsx from 'clsx';
import { LogoIcon } from 'components/Icon';
import { FallbackLogoOptions } from 'components/Icon/types';
import useLayoutStyles from 'components/lib/Layout/styles';
import usePageLayoutStyles from 'components/PageLayout/styles';
import { ConfirmationModalContextProvider } from 'contexts/ConfirmationModalContext';
import useAntLanguage from 'hooks/useAntLanguage';
import React from 'react';
import { SIDEBAR_COLLAPSED_WIDTH } from 'utils/consts';
import {
  ALX_LOGO_SIMPLE_LAYOUT_TESTID,
  SIMPLE_LAYOUT_SIDEBAR_TESTID,
} from 'utils/testIds';
import useSimpleLayoutStyles from './styles';

const { Sider } = AntLayout;

const SimpleLayout: React.FC = ({ children }) => {
  const classes = useLayoutStyles({});
  const pageLayoutClasses = usePageLayoutStyles({
    currentSidebarWidth: SIDEBAR_COLLAPSED_WIDTH,
  });
  const simpleLayoutClasses = useSimpleLayoutStyles({});
  const antLanguage = useAntLanguage();

  return (
    <ConfirmationModalContextProvider>
      <ConfigProvider locale={antLanguage}>
        <AntLayout className={classes.mainLayout}>
          <Sider
            collapsed={true}
            theme={'dark'}
            collapsedWidth={SIDEBAR_COLLAPSED_WIDTH}
            className={pageLayoutClasses.sider}
            data-testid={SIMPLE_LAYOUT_SIDEBAR_TESTID}
            trigger={null}
          >
            <div className={classes.sidebarLogoWrapper}>
              <div
                className={classes.sidebarLogo}
                data-testid={ALX_LOGO_SIMPLE_LAYOUT_TESTID}
              >
                <LogoIcon
                  fallbackLogo={FallbackLogoOptions.SMALL}
                  width={32}
                  height={32}
                />
              </div>
            </div>
          </Sider>
          <AntLayout
            className={clsx(
              pageLayoutClasses.layout,
              simpleLayoutClasses.layout
            )}
          >
            {children}
          </AntLayout>
        </AntLayout>
      </ConfigProvider>
    </ConfirmationModalContextProvider>
  );
};

export default SimpleLayout;
