export const SET_OBJECT_CLASSES = 'SET_OBJECT_CLASSES';
export const APPEND_OBJECT_CLASSES = 'APPEND_OBJECT_CLASSES';
export const SET_OBJECT_CLASSES_FETCHING = 'SET_OBJECT_CLASSES_FETCHING';
export const SET_TOTAL_OBJECT_CLASSES = 'SET_TOTAL_OBJECT_CLASSES';
export const RESET_OBJECT_CLASSES = 'RESET_OBJECT_CLASSES';
export const SET_OBJECT_CLASSES_COLUMNS = 'SET_OBJECT_CLASSES_COLUMNS';
export const RESET_OBJECT_CLASSES_COLUMNS = 'RESET_OBJECT_CLASSES_COLUMNS';
export const SET_OBJECT_CLASSES_SELECTED_ROW =
  'SET_OBJECT_CLASSES_SELECTED_ROW';
export const SET_OBJECT_CLASSES_RESTRICTIONS =
  'SET_OBJECT_CLASSES_RESTRICTIONS';
export const SET_OBJECT_CLASS_FIELDS_RESTRICTIONS =
  'SET_OBJECT_CLASS_FIELDS_RESTRICTIONS';
export const SET_OBJECT_CLASSES_RESPONSE = 'SET_OBJECT_CLASSES_RESPONSE';
