export const SET_OBJECT_RECORDS = 'SET_OBJECT_RECORDS';
export const APPEND_OBJECT_RECORDS = 'APPEND_OBJECT_RECORDS';
export const SET_OBJECT_RECORDS_FETCHING = 'SET_OBJECT_RECORDS_FETCHING';
export const SET_TOTAL_OBJECTS = 'SET_TOTAL_OBJECTS';
export const RESET_OBJECT_RECORDS = 'RESET_OBJECT_RECORDS';
export const SET_OBJECT_RECORDS_COLUMNS = 'SET_OBJECT_RECORDS_COLUMNS';
export const RESET_OBJECT_RECORDS_COLUMNS = 'RESET_OBJECT_RECORDS_COLUMNS';
export const SET_OBJECT_RECORDS_SELECTED_ROW =
  'SET_OBJECT_RECORDS_SELECTED_ROW';
export const SET_INCOMPLETE_TASKS_OBJECT_RECORD_ID =
  'SET_INCOMPLETE_TASKS_OBJECT_RECORD_ID';
export const SET_INCOMPLETE_TASKS_FETCHING = 'SET_INCOMPLETE_TASKS_FETCHING';
export const SET_OBJECT_RECORD_REMOVE_OWNER_CALLBACK =
  'SET_OBJECT_RECORD_REMOVE_OWNER_CALLBACK';
export const SET_OBJECT_RECORDS_SELECTED_COLUMNS =
  'SET_OBJECT_RECORDS_SELECTED_COLUMNS';
export const SET_OBJECT_RECORDS_RESTRICTIONS =
  'SET_OBJECT_RECORDS_RESTRICTIONS';
export const SET_OBJECT_RECORDS_ERROR = 'SET_OBJECT_RECORDS_ERROR';
