import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  SET_ROLES,
  SET_ROLES_FETCHING,
  APPEND_ROLES,
  RESET_ROLES,
  SET_ROLES_COLUMNS,
  RESET_ROLES_COLUMNS,
  SET_ROLES_SELECTED_ROW,
  SET_ROLES_RESTRICTIONS,
} from 'store/constants/roles.consts';
import {
  appendTableContent,
  resetTable,
  setTableContent,
  setTableLoading,
  resetTableColumns,
  setTableColumns,
  setSelectedRow,
} from './tablesCommonStateModificators';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import { Role } from 'utils/types/api/roles.types';
import { CommonTableState } from 'utils/types/api/table.types';

import cloneDeep from 'lodash/cloneDeep';
const initialState = cloneDeep(INITIAL_TABLE_STATE);

const roles: Reducer<CommonTableState<Role>, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_ROLES: {
      return setTableContent<Role, typeof SET_ROLES>(state, action);
    }
    case APPEND_ROLES:
      return appendTableContent<Role, typeof APPEND_ROLES>(state, action);
    case SET_ROLES_FETCHING:
      return setTableLoading<Role, typeof SET_ROLES_FETCHING>(state, action);

    case RESET_ROLES:
      return resetTable<Role>(state, initialState);

    case RESET_ROLES_COLUMNS:
      return resetTableColumns<Role>(state, initialState);

    case SET_ROLES_COLUMNS:
      return setTableColumns<Role, typeof SET_ROLES_COLUMNS>(state, action);
    case SET_ROLES_SELECTED_ROW:
      return setSelectedRow<Role, typeof SET_ROLES_SELECTED_ROW>(state, action);
    case SET_ROLES_RESTRICTIONS:
      return { ...state, restrictions: action.restrictions };
    default:
      return state;
  }
};

export default roles;
