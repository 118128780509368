import { useTableContext } from 'contexts/TableContext';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setObjectRecordsSelectedColumns } from 'store/actions/objectRecordsActions';
import { getSelectedObjectClassId } from 'store/selectors/preferencesSelectors';
import { UseConfirmationModalsParams } from './types';
import { getObjectRecordsInitialColumns } from 'store/selectors/objectRecordsSelectors';

export const useConfirmationModals = ({
  setConfirmedCancel,
  temporaryState,
  setColumnOrder,
}: UseConfirmationModalsParams) => {
  const { toggleEditMode } = useTableContext();
  const [shouldShowConfirmation, setShouldShowConfirmation] = useState(false);
  const dispatch = useDispatch();
  const selectedClassId = useSelector(getSelectedObjectClassId);
  const initialColumns = useSelector(getObjectRecordsInitialColumns);
  const [cancelConfigModalVisible, setCancelConfigModalVisible] = useState(
    false
  );

  const onConfirmLeaveConfigMode = useCallback(() => {
    setConfirmedCancel(true);

    if (selectedClassId && initialColumns) {
      dispatch(
        setObjectRecordsSelectedColumns({
          selectedClassId,
          selectedColumns: initialColumns ?? [],
        })
      );
    }

    //workaround for handling exiting configuration mode - with setting column order it will get to reducer where it will set the temporary state
    if (temporaryState) {
      setColumnOrder(temporaryState?.columnOrder);
    }

    toggleEditMode();
    setCancelConfigModalVisible(false);
  }, [
    dispatch,
    selectedClassId,
    initialColumns,
    setColumnOrder,
    setConfirmedCancel,
    temporaryState,
    toggleEditMode,
  ]);

  return {
    onConfirmLeaveConfigMode,
    shouldShowConfirmation,
    cancelConfigModalVisible,
    setShouldShowConfirmation,
    setCancelConfigModalVisible,
  };
};
