import { Select } from 'components/lib/Select';
import React from 'react';
import useFilterSelectStyles from './styles';
import { FilterSelectProps } from './types';
import { CONTENT_ID } from 'utils/elementsIds';

const FilterSelect: React.FC<FilterSelectProps> = ({
  options,
  value,
  onChange,
}) => {
  const classes = useFilterSelectStyles({});

  const [{ value: defaultValue }] = options;

  return (
    <div className={classes.selectWrapper}>
      <Select
        value={value || defaultValue}
        className={classes.select}
        {...{ defaultValue, onChange, options }}
        getPopupContainer={() =>
          document.getElementById(CONTENT_ID) || document.body
        }
      />
    </div>
  );
};

export default FilterSelect;
