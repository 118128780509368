import { toast } from 'components/lib/toast';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import ToastType from 'utils/Enums/ToastType';

const showDefaultErrorToast = () =>
  toast(
    {
      title: GlobalIntlSingleton.intl.formatMessage({
        id: 'errors.somethingWentWrong',
        defaultMessage: 'Something went wrong',
      }),
      subtitle: GlobalIntlSingleton.intl.formatMessage({
        id: 'misc.pleaseTryAgainLater',
        defaultMessage: 'Please try again later.',
      }),
    },
    ToastType.Error
  );

export default showDefaultErrorToast;
