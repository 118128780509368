import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import { ButtonSecondary, ButtonPropsWithoutType } from 'components/lib/Button';
import useToolButtonStyles from './styles';
import Tooltip from 'components/lib/Tooltip';
import clsx from 'clsx';
import { ToolButtonWithTooltipProps } from './types';
import { useToggle } from 'hooks/useToggle';

const ToolButton = forwardRef<HTMLButtonElement, ButtonPropsWithoutType>(
  ({ className, ...rest }, ref) => {
    const classes = useToolButtonStyles({});

    return (
      <ButtonSecondary
        {...{ ...rest, ref }}
        className={clsx([classes.toolButton, className])}
      />
    );
  }
);

export const ToolButtonWithTooltip: React.FC<ToolButtonWithTooltipProps> = ({
  tooltipTitle,
  customButton,
  tooltipGetPopupContainer,
  disabled,
  placement,
  destroyTooltipOnHide,
  buttonClassName,
  disabledTooltipTitle,
  shouldCloseOnScroll = true,
  ...rest
}) => {
  const [showTooltip, { toggleOff, toggleOn }] = useToggle(false);
  const classes = useToolButtonStyles({});
  const timeoutRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const onMouseEnter = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      toggleOn();
    }, 1000);
  }, [toggleOn]);

  const onMouseLeave = useCallback(() => {
    clearTimeout(timeoutRef.current!);
    toggleOff();
  }, [toggleOff]);

  useEffect(() => {
    if (!showTooltip || !shouldCloseOnScroll) return;

    window.document.addEventListener('scroll', onMouseLeave, true);

    return () => {
      window.document.removeEventListener('scroll', onMouseLeave, true);
    };
  }, [shouldCloseOnScroll, showTooltip, onMouseLeave]);

  useEffect(() => {
    // hiding tooltip after switching between standard and disabled button
    onMouseLeave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, customButton?.props?.disabled]);

  if (disabled || customButton?.props?.disabled) {
    const button = !!customButton ? (
      customButton
    ) : (
      <ToolButton {...{ disabled, ...rest }} />
    );

    if (disabledTooltipTitle) {
      return (
        <div className={classes.disabledButtonWithTooltipWrapper}>
          <Tooltip
            title={disabledTooltipTitle}
            {...{ placement }}
            visible={showTooltip}
          >
            <div
              className={classes.disabledButtonFakeArea}
              {...{ onMouseEnter, onMouseLeave }}
            ></div>
          </Tooltip>
          {button}
        </div>
      );
    }

    return button;
  }

  return (
    <span
      className={classes.tooltipWrapper}
      {...{ onMouseEnter, onMouseLeave }}
    >
      <Tooltip
        autoAdjustOverflow={false}
        visible={showTooltip}
        title={tooltipTitle}
        getPopupContainer={tooltipGetPopupContainer}
        {...{ placement, destroyTooltipOnHide }}
      >
        {!!customButton ? (
          customButton
        ) : (
          <ToolButton className={buttonClassName} {...rest} />
        )}
      </Tooltip>
    </span>
  );
};

export default ToolButton;
