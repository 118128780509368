import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  SET_TASK_GROUPS,
  SET_TASK_GROUPS_FETCHING,
  APPEND_TASK_GROUPS,
  RESET_TASK_GROUPS,
  SET_TASK_GROUPS_COLUMNS,
  RESET_TASK_GROUPS_COLUMNS,
  SET_TASK_GROUPS_SELECTED_ROW,
  SET_TASK_GROUPS_RESTRICTIONS,
} from 'store/constants/taskGroups.consts';
import {
  appendTableContent,
  resetTable,
  setTableContent,
  setTableLoading,
  resetTableColumns,
  setTableColumns,
  setSelectedRow,
} from './tablesCommonStateModificators';
import { CommonTableState } from 'utils/types/api/table.types';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import { TaskGroup } from 'utils/types/api/taskGroups.types';

import cloneDeep from 'lodash/cloneDeep';
const initialState = cloneDeep(INITIAL_TABLE_STATE);

const taskGroupsReducer: Reducer<CommonTableState<TaskGroup>, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_TASK_GROUPS: {
      return setTableContent<TaskGroup, typeof SET_TASK_GROUPS>(state, action);
    }
    case APPEND_TASK_GROUPS:
      return appendTableContent<TaskGroup, typeof APPEND_TASK_GROUPS>(
        state,
        action
      );
    case SET_TASK_GROUPS_FETCHING:
      return setTableLoading<TaskGroup, typeof SET_TASK_GROUPS_FETCHING>(
        state,
        action
      );

    case RESET_TASK_GROUPS:
      return resetTable<TaskGroup>(state, initialState);

    case RESET_TASK_GROUPS_COLUMNS:
      return resetTableColumns<TaskGroup>(state, initialState);

    case SET_TASK_GROUPS_COLUMNS:
      return setTableColumns<TaskGroup, typeof SET_TASK_GROUPS_COLUMNS>(
        state,
        action
      );
    case SET_TASK_GROUPS_SELECTED_ROW:
      return setSelectedRow<TaskGroup, typeof SET_TASK_GROUPS_SELECTED_ROW>(
        state,
        action
      );
    case SET_TASK_GROUPS_RESTRICTIONS:
      return { ...state, restrictions: action.restrictions };
    default:
      return state;
  }
};

export default taskGroupsReducer;
