import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseEditContentStyles = {
  isSaving?: boolean;
  hasError?: boolean;
  hasMinMaxSet?: boolean;
};

const useEditContentStyles = createUseStyles((theme: ThemeState) => {
  return {
    checkboxesListWrapper: ({
      isSaving,
      hasError,
      hasMinMaxSet,
    }: UseEditContentStyles) => ({
      position: 'relative',
      background: 'white',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      boxShadow: '0px 2px 4px 2px rgba(46, 63, 87, 0.3)',
      borderRadius: 3,
      borderBottomLeftRadius: hasMinMaxSet ? 0 : null,
      borderBottomRightRadius: hasMinMaxSet ? 0 : null,
      border: hasError
        ? `1px solid ${theme['error-color']}`
        : '1px solid transparent',
      '& .ant-checkbox-group': {
        maxHeight: 400,
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: '10px 10px 0px 10px',
      },
      '& .ant-checkbox-wrapper + .ant-checkbox-wrapper': {
        marginLeft: 0,
      },
      '& .ant-checkbox-wrapper': {
        opacity: isSaving ? 0.6 : 1,
        pointerEvents: isSaving ? 'none' : 'all',
        display: 'flex',
        marginBottom: 11,
        fontSize: 12,
        '&:hover .ant-checkbox::after': {
          border: 'none',
        },
        '& .ant-checkbox': {
          width: 16,
          height: 16,
        },
        '& .ant-checkbox-inner': {
          width: 16,
          height: 16,
          '&::after': {
            left: '20%',
          },
        },
      },
    }),
    spacer: {
      width: '100%',
    },
    messageBox: ({ hasMinMaxSet }: UseEditContentStyles) => ({
      borderTopLeftRadius: hasMinMaxSet ? 0 : null,
      borderTopRightRadius: hasMinMaxSet ? 0 : null,
      borderTop: `1px solid ${theme['border-color-base']}`,
      left: -1,
      right: -1,
    }),
    messageBoxOnTop: {
      borderBottom: `1px solid ${theme['border-color-base']}`,
      borderTop: 'none !important',
      zIndex: -1,
    },
    inPlaceEditAfter: {
      alignSelf: 'initial',
    },
    editContentWrapper: {
      display: 'flex',
    },
  };
});

export default useEditContentStyles;
