import { RootAction } from 'store/reducers';
import { Reducer } from 'redux';
import {
  SET_CUSTOM_THEME,
  SET_CUSTOM_THEME_LOADING,
  SET_SAVING_SET_CUSTOM_THEME,
} from 'store/constants/customTheme.consts';
import { CustomThemeState } from './types/customTheme.types';
import { ThemeFormSchema } from 'pages/Theme/components/ThemeConfiguration/types';

import { ColoursSectionFieldNames } from 'pages/Theme/components/ThemeConfiguration/components/ColoursForm/enums';
import { TextOverridesFields } from 'pages/Theme/components/ThemeConfiguration/components/TextOverridesForm/enums';

const MediaSectionDefaultValues = { mainLogo: null };
const TextOverridesDefaultValues = {
  [TextOverridesFields.HEADING_TEXT]:
    'The Operations Platform for Legal and Compliance',
  [TextOverridesFields.BODY_TEXT]:
    'Build digital solutions for your workforce and customers on a single platform. Turn ideas into reality, fast.',
};
const ColorsSectionDefaultValues = {
  [ColoursSectionFieldNames.PrimaryColour]: '#028FDF',
  [ColoursSectionFieldNames.SecondaryColour]: '#586C93',
  [ColoursSectionFieldNames.TertiaryColour]: '#169A16',
  [ColoursSectionFieldNames.SiderBg]: '#030031',
  [ColoursSectionFieldNames.SectionHeaderBG]: '#3678D0',
  [ColoursSectionFieldNames.SectionContentBG]: '#F4F7FE',
  [ColoursSectionFieldNames.LinkColour]: '#1890FF',
  [ColoursSectionFieldNames.PrimaryColourDark]: '#007EC5',
  [ColoursSectionFieldNames.PrimaryColourLight]: '#E6F4FC',
  [ColoursSectionFieldNames.PrimaryColourMedium]: '#1890FF',
  [ColoursSectionFieldNames.SecondaryColourDark]: '#586C93',
  [ColoursSectionFieldNames.SecondaryColourLight]: '#586C93',
  [ColoursSectionFieldNames.TertiaryDisabledColour]: '#94D094',
  [ColoursSectionFieldNames.SiderBGSecondary]: '#151B44',
  [ColoursSectionFieldNames.SiderBGThird]: '#222851',
};

export const themeInitialisedDefaultValues: ThemeFormSchema = {
  media: MediaSectionDefaultValues,
  colours: ColorsSectionDefaultValues,
  textOverrides: TextOverridesDefaultValues,
  isEnabled: false,
};

export const initialState: CustomThemeState = {
  loading: true,
  saving: false,
  data: themeInitialisedDefaultValues,
};

const customThemeReducer: Reducer<CustomThemeState, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_CUSTOM_THEME: {
      return {
        ...state,
        loading: false,
        saving: false,
        data: {
          ...themeInitialisedDefaultValues,
          ...action.payload,
        },
      };
    }
    case SET_CUSTOM_THEME_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case SET_SAVING_SET_CUSTOM_THEME: {
      return {
        ...state,
        saving: action.saving,
      };
    }

    default:
      return state;
  }
};

export default customThemeReducer;
