import { Action } from 'flexlayout-react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { updatePreferences } from 'store/actions/preferencesActions';
import { getAllPreferences } from 'store/selectors/preferencesSelectors';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import routes from 'utils/routingPaths';
import { ActiveTabGroups } from './types';
import { PreferencesTypes } from 'utils/types/api/preferences.types';

const useKeepActiveWindow = () => {
  const dispatch = useDispatch();
  const preferences = useSelector(getAllPreferences);
  const { pathname } = useLocation();

  const onSelectTab = (action: Action) => {
    if (!!matchPath(pathname, { path: routes.RECORDS })) {
      updateActiveWindow(ActiveTabGroups.records, action.data.tabNode);
    }
  };

  const updateActiveWindow = (
    resource: ActiveTabGroups,
    tabNode: FlexLayoutWindows
  ) => {
    dispatch(
      updatePreferences(PreferencesTypes.LayoutPreferences, {
        panels: {
          ...preferences[PreferencesTypes.LayoutPreferences].panels,
          activePanels: { [resource]: tabNode },
        },
      })
    );
  };

  return { onSelectTab };
};

export default useKeepActiveWindow;
