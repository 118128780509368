import { DraggableComponents } from './enums';
import { ReactComponent as InputImage } from 'img/icons/textbox-icon.svg';
import { ReactComponent as NumericImage } from 'img/icons/numeric-icon.svg';
import { ReactComponent as CheckboxImage } from 'img/icons/checkbox-icon.svg';
import { ReactComponent as SingleSelectImage } from 'img/icons/single-select-icon.svg';
import { ReactComponent as DatepickerImage } from 'img/icons/datepicker-icon.svg';
import { ReactComponent as EmailImage } from 'img/icons/at-icon.svg';
import { ReactComponent as CountryPickerImage } from 'img/icons/country-picker-icon.svg';
import { ReactComponent as PhoneImage } from 'img/icons/phone-icon.svg';
import { ReactComponent as MultiselectImage } from 'img/icons/multiselect-icon.svg';
import { ReactComponent as FileUploadImage } from 'img/icons/file-upload.svg';
import { ReactComponent as SectionImage } from 'img/icons/section-icon.svg';
import { UiElementProps } from './types';
import { ReactComponent as InstructionImage } from 'img/icons/instruction-icon.svg';
import { ReactComponent as URLIcon } from 'img/icons/url-icon.svg';
import { ReactComponent as JSONIcon } from 'img/icons/json-icon.svg';
//import { ReactComponent as UserIcon } from 'img/icons/user-icon.svg';

const MAX_COMPONENTS_ON_LISTING_VIEW = 15;

const defaultDraggableFields: UiElementProps[] = [
  {
    key: DraggableComponents.Input,
    nameDescriptor: {
      id: 'misc.textbox',
      defaultMessage: 'Textbox',
    },
    Image: InputImage,
  },
  {
    key: DraggableComponents.Numeric,
    nameDescriptor: {
      id: 'formBuilder.numeric',
      defaultMessage: 'Numeric',
    },
    Image: NumericImage,
  },
  {
    key: DraggableComponents.DateTimePicker,
    nameDescriptor: {
      id: 'formBuilder.dateTimePicker',
      defaultMessage: 'Date / Time picker',
    },
    Image: DatepickerImage,
  },
  {
    key: DraggableComponents.Checkbox,
    nameDescriptor: {
      id: 'formBuilder.checkbox',
      defaultMessage: 'Checkbox',
    },
    Image: CheckboxImage,
  },
  {
    key: DraggableComponents.Radio,
    nameDescriptor: {
      id: 'formBuilder.singleSelect',
      defaultMessage: 'Single select',
    },
    Image: SingleSelectImage,
  },
  {
    key: DraggableComponents.Multiselect,
    nameDescriptor: {
      id: 'formBuilder.multiSelect',
      defaultMessage: 'Multiselect',
    },
    Image: MultiselectImage,
  },
  {
    key: DraggableComponents.Email,
    nameDescriptor: {
      id: 'formBuilder.email',
      defaultMessage: 'Email',
    },
    Image: EmailImage,
  },
  {
    key: DraggableComponents.CountrySelect,
    nameDescriptor: {
      id: 'formBuilder.countrySelector',
      defaultMessage: 'Country selector',
    },
    Image: CountryPickerImage,
  },
  {
    key: DraggableComponents.PhoneNumber,
    nameDescriptor: {
      id: 'formBuilder.phoneNumber',
      defaultMessage: 'Phone number',
    },
    Image: PhoneImage,
  },
  {
    key: DraggableComponents.File,
    nameDescriptor: {
      id: 'formBuilder.fileUpload',
      defaultMessage: 'File upload',
    },
    Image: FileUploadImage,
  },
  {
    key: DraggableComponents.Instruction,
    nameDescriptor: {
      id: 'formBuilder.instruction',
      defaultMessage: 'Instruction',
    },
    Image: InstructionImage,
    inUIFields: true,
  },
  {
    key: DraggableComponents.URL,
    nameDescriptor: {
      id: 'formBuilder.url',
      defaultMessage: 'URL',
    },
    Image: URLIcon,
  },
  {
    key: DraggableComponents.JSON,
    nameDescriptor: {
      id: 'formBuilder.json',
      defaultMessage: 'JSON Field',
    },
    Image: JSONIcon,
  },
  /*{
    key: DraggableComponents.User,
    nameDescriptor: {
      id: 'formBuilder.user',
      defaultMessage: 'User Field',
    },
    Image: UserIcon,
  },*/
];

const landingPageDraggableFields: UiElementProps[] = [
  {
    key: DraggableComponents.HTML,
    nameDescriptor: {
      id: 'misc.html',
      defaultMessage: 'HTML',
    },
    Image: InputImage,
  },
];

const defaultUiElements: UiElementProps[] = [
  {
    key: DraggableComponents.Section,
    nameDescriptor: {
      id: 'formBuilder.section',
      defaultMessage: 'Section',
    },
    Image: SectionImage,
  },
];

const mockItem = {
  key: DraggableComponents.Mock,
  nameDescriptor: {
    id: 'misc.component',
    defaultMessage: 'Mock',
  },
  Image: SingleSelectImage,
};

// Array containing basic fields that should be available to drag into canvas
// even if available fields are determined by the API
export const baseDraggableFields = [
  DraggableComponents.Instruction,
  DraggableComponents.Owners,
];

export {
  defaultDraggableFields,
  landingPageDraggableFields,
  defaultUiElements,
  mockItem,
  MAX_COMPONENTS_ON_LISTING_VIEW,
};

export const propertiesIds: string[] = [
  DraggableComponents.PropertyCreatedDateTime,
  DraggableComponents.PropertyId,
  DraggableComponents.PropertyUpdatedDateTime,
  DraggableComponents.Owners,
  DraggableComponents.HTML,
];

export const HIDDEN_FIELDS_ON_RECORD_LISTING: string[] = [
  DraggableComponents.Owners,
];
