import { SUPPORTED_FILE_FORMATS } from '../FileViewer.consts';
import { getFileExtension } from './getFileExtension';

/**
 * Checks if ALX FileViewer supports the file format based on its full name.
 */
export const isSupportedFileFormat = (fileName: string) => {
  const fileFormat = getFileExtension(fileName);

  if (!fileFormat) {
    return false;
  }

  return SUPPORTED_FILE_FORMATS.includes(fileFormat);
};
