import React, { Key, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import useDateFormat from 'hooks/useDateFormat';
import useFormatNumber from 'hooks/useFormatNumber';
import isMultiline from 'utils/functions/isMultiline';
import {
  EventType,
  EventData,
  EventUser,
  EventFieldType,
  FieldValueType,
  Items,
} from '../types';
import {
  getFieldValueDate,
  isAssigneesData,
  isDocumentData,
  isFieldValueData,
  isOwnersData,
  isUserFieldValueData,
} from '../utils';
import { useEventDataLabelStyles } from './styles';
import {
  DocumentGeneratedIcon,
  EventCircleIcon,
  FieldValueUpdatedIcon,
  OwnerChangedIcon,
  PermissionChangedIcon,
  RecordCreatedIcon,
  ErrorCircleIcon,
} from 'components/Icon';
import { IconProps } from 'components/Icon/types';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { apiCall } from 'utils/api';
import { toast } from 'components/lib/toast';
import ToastType from 'utils/Enums/ToastType';
import showDefaultErrorToast from 'utils/functions/showDefaultErrorToast';
import { generatePath } from 'react-router-dom';
import { OBJECT_RECORD_HISTORY_FILES } from 'utils/endpoints';
import axios from 'axios';

const nameOrDefaultValue = (
  name: string | undefined | null,
  defaultValue: string
) => {
  return name ?? defaultValue;
};

export const useEventIcon = (
  eventType: EventType
): { EventIcon: (props: IconProps) => JSX.Element; title: string } => {
  const intl = useIntl();

  switch (eventType) {
    case EventType.RecordCreated:
      return {
        EventIcon: RecordCreatedIcon,
        title: intl.formatMessage({
          id: 'activityLog.created',
          defaultMessage: 'Record created',
        }),
      };
    case EventType.OwnerInitialized:
      return {
        EventIcon: OwnerChangedIcon,
        title: intl.formatMessage({
          id: 'activityLog.ownerInitialized',
          defaultMessage: 'Record owner initialized',
        }),
      };
    case EventType.FieldValuesChanged:
      return {
        EventIcon: FieldValueUpdatedIcon,
        title: intl.formatMessage({
          id: 'activityLog.fieldValuesChanged',
          defaultMessage: 'Field value updated',
        }),
      };
    case EventType.DocumentGenerated:
      return {
        EventIcon: DocumentGeneratedIcon,
        title: intl.formatMessage({
          id: 'activityLog.documentGenerated',
          defaultMessage: 'Document generated',
        }),
      };
    case EventType.AssigneesAdded:
    case EventType.AssigneesRemoved:
    case EventType.OwnersAdded:
    case EventType.OwnersRemoved:
      return {
        EventIcon: PermissionChangedIcon,
        title: intl.formatMessage({
          id: 'activityLog.accessUpdate',
          defaultMessage: 'Record access updated',
        }),
      };
    case EventType.Error:
      return {
        EventIcon: ErrorCircleIcon,
        title: intl.formatMessage({
          id: 'activityLog.error',
          defaultMessage: 'Invalid data',
        }),
      };
    default:
      return {
        EventIcon: EventCircleIcon,
        title: '',
      };
  }
};

export const useEventDateTime = (eventDateTime: string): string => {
  const { dateFormat, getTimeWithSecondsFormat } = useDateFormat();

  return moment(eventDateTime).format(
    `${dateFormat} ${getTimeWithSecondsFormat()}`
  );
};

export const useGeneratedDocumentDownload = (
  eventId: string,
  refreshData: () => void
) => {
  const intl = useIntl();
  const { selectedResource } = useSelectedResourceContext();
  const knownErrorCodes = new Set([401, 403, 404]);

  const handleDocumentDownload = useCallback(
    (fileName?: string) => async () => {
      const recordId = selectedResource?.record?.recordId;
      if (recordId) {
        try {
          const url = generatePath(OBJECT_RECORD_HISTORY_FILES, {
            recordId,
            eventId,
          });

          const { data } = await apiCall.get(url, { responseType: 'blob' });

          const link = document.createElement('a');
          link.setAttribute('target', '_blank');
          link.href = URL.createObjectURL(data);
          link.download = fileName ?? 'file.docx';

          link.click();
          URL.revokeObjectURL(link.href);
          toast(
            {
              title: intl.formatMessage({
                id: 'misc.success',
                defaultMessage: 'Success!',
              }),
              subtitle: intl.formatMessage({
                id: 'activityLog.theDocumentHasBeenDownloaded',
                defaultMessage: 'The document has been downloaded.',
              }),
            },
            ToastType.Success
          );
        } catch (e) {
          if (axios.isAxiosError(e)) {
            if (knownErrorCodes.has(e.response?.status as number)) {
              refreshData();
              return;
            }
          }
          showDefaultErrorToast();
        }
      }
    },
    [eventId, selectedResource, intl, knownErrorCodes, refreshData]
  );

  return handleDocumentDownload;
};

export const useEventSourceName = (
  eventSourceName: string | null,
  sourceId: string | number
): string => {
  const intl = useIntl();

  return (
    eventSourceName ||
    intl.formatMessage(
      { id: 'activityLog.user', defaultMessage: 'user {sourceId}' },
      { sourceId }
    )
  );
};

export const useEventData = (eventData: EventData, eventType: EventType) => {
  const eventIcon = useEventIcon(eventType);
  const { items, value, fieldType } = useEventItems(eventData, eventType);
  const label = useEventLabel(items, eventType, value, fieldType);

  const isExpandableTextField =
    items.length > 0 &&
    fieldType === EventFieldType.string &&
    typeof items[0].value === 'string' &&
    (items[0].value.length > 100 || isMultiline(items[0].value));

  const isMultiple = useMemo(
    () =>
      items.length > 1 ||
      isExpandableTextField ||
      fieldType === EventFieldType.document ||
      fieldType === EventFieldType.user ||
      fieldType === EventFieldType.set ||
      fieldType === EventFieldType.json ||
      eventType === EventType.DocumentGenerated,
    [eventType, fieldType, isExpandableTextField, items.length]
  );

  return { items, label, fieldType, isMultiple, ...eventIcon };
};

const useEventItems = (eventData: EventData, eventType: EventType) => {
  const intl = useIntl();

  const mapUserEvent = useCallback(
    ({ id, name }: EventUser) => ({
      id,
      value: nameOrDefaultValue(
        name,
        intl.formatMessage(
          {
            id: 'activityLog.deletedUser',
            defaultMessage: 'Deleted user {id}',
          },
          { id }
        )
      ),
    }),
    [intl]
  );

  if (isUserFieldValueData(eventData, eventType)) {
    //type of event occuring in User field
    const { value_labels: valueLabels } = eventData[0]; //value_labels is an array of string which contains `${firstName} ${lastName}` of user
    // value is an array of user ids

    return {
      items: valueLabels.users.map((user: string) => ({
        id: user,
        value: user,
      })),
      value: nameOrDefaultValue(eventData[0].name, ''),
      fieldType: EventFieldType.user,
    };
  }

  if (isDocumentData(eventData)) {
    return {
      items: eventData.map(e => ({
        id: e.document_template_id,
        value: e.file_name,
        fileId: e.file_id,
      })),
      value: '',
    };
  }

  if (isOwnersData(eventData)) {
    const items = eventData.map(mapUserEvent);

    return { items, value: '' };
  }

  if (isAssigneesData(eventData)) {
    const items = eventData.assignees.map(mapUserEvent);
    const { id, name } = eventData.permission_set;
    const value = nameOrDefaultValue(
      name,
      intl.formatMessage(
        {
          id: 'activityLog.deletedPermission',
          defaultMessage: 'Deleted permission set {id}',
        },
        { id }
      )
    );

    return { items, value };
  }

  if (isFieldValueData(eventData) && eventData.length > 0) {
    const { id, value, name, type, value_labels: valueLabels } = eventData[0];
    let items: Items[] = [{ id, value }];
    const fieldValue = nameOrDefaultValue(
      name,
      intl.formatMessage(
        {
          id: 'activityLog.deletedField',
          defaultMessage: 'Deleted field {id}',
        },
        { id }
      )
    );

    if (type === EventFieldType.set && typeof value === 'string') {
      const values: string[] = JSON.parse(value);
      items = values.map(item => ({ id: item, value: item }));
    }

    if (
      type === EventFieldType.document &&
      Array.isArray(valueLabels) &&
      Array.isArray(value)
    ) {
      items = value.map((id, i) => ({ id: id, value: valueLabels[i] }));
    }

    return { items, value: fieldValue, fieldType: type };
  }

  return { items: [], value: '', fieldType: undefined };
};

const useIsExpandable = (
  value: FieldValueType,
  eventFieldType?: EventFieldType
) => {
  return (
    eventFieldType === EventFieldType.document ||
    eventFieldType === EventFieldType.user ||
    eventFieldType === EventFieldType.set ||
    eventFieldType === EventFieldType.json ||
    (eventFieldType === EventFieldType.string &&
      typeof value === 'string' &&
      (value.length > 100 || isMultiline(value)))
  );
};

const useEventLabel = (
  items: {
    id: Key;
    value: FieldValueType;
  }[],
  eventType: EventType,
  field = '',
  eventFieldType?: EventFieldType
) => {
  const intl = useIntl();
  const styles = useEventDataLabelStyles({});
  const { dateFormat, getTimeWithSecondsFormat } = useDateFormat();
  const formatNumber = useFormatNumber();
  const [{ value } = { value: '' }] = items;
  const isExpandable = useIsExpandable(value, eventFieldType);

  const { length } = items;

  switch (eventType) {
    case EventType.RecordCreated: {
      return intl.formatMessage(
        {
          id: 'activityLog.recordWasCreated',
          defaultMessage: '<b>Record was created</b>',
        },
        { ...tagsMapping(styles) }
      );
    }
    case EventType.OwnerInitialized: {
      return intl.formatMessage(
        {
          id: 'activityLog.wasMadeOwner',
          defaultMessage: '<b>{value}</b> was made owner',
        },
        { ...tagsMapping(styles), value }
      );
    }
    case EventType.OwnersAdded: {
      return intl.formatMessage(
        {
          id: 'activityLog.ownersAdded',
          defaultMessage: `{length, plural, one {<b>{value}</b> was <g>added</g> to <b>Owners</b>} other {<b>Owners</b> were <g>added</g>}}`,
        },
        { ...tagsMapping(styles), value, length }
      );
    }
    case EventType.OwnersRemoved: {
      return intl.formatMessage(
        {
          id: 'activityLog.ownersRemoved',
          defaultMessage: `{length, plural, one {<b>{value}</b> was <r>removed</r> from <b>Owners</b>} other {<b>Owners</b> were <r>removed</r>}}`,
        },
        { ...tagsMapping(styles), value, length }
      );
    }
    case EventType.AssigneesAdded: {
      return intl.formatMessage(
        {
          id: 'activityLog.assigneesAdded',
          defaultMessage: `{length, plural, one {<b>{value}</b> was <g>added</g> to <b>{field}</b>} other {<b>{field}</b> assignees were <g>added</g>}}`,
        },
        { ...tagsMapping(styles), value, length, field }
      );
    }
    case EventType.AssigneesRemoved: {
      return intl.formatMessage(
        {
          id: 'activityLog.assigneesRemoved',
          defaultMessage: `{length, plural, one {<b>{value}</b> was <r>removed</r> from <b>{field}</b>} other {<b>{field}</b> assignees were <r>removed</r>}}`,
        },
        { ...tagsMapping(styles), value, length, field }
      );
    }
    case EventType.DocumentGenerated: {
      return intl.formatMessage(
        {
          id: 'activityLog.documentWasCreated',
          defaultMessage: '<b>Document</b> was generated',
        },
        { ...tagsMapping(styles) }
      );
    }
    case EventType.FieldValuesChanged: {
      let formattedValue = value;

      switch (eventFieldType) {
        case EventFieldType.int:
        case EventFieldType.float: {
          if (typeof value === 'number') formattedValue = formatNumber(value);
          break;
        }
        case EventFieldType.date: {
          formattedValue = getFieldValueDate(value, dateFormat);
          break;
        }
        case EventFieldType.datetime: {
          formattedValue = getFieldValueDate(
            value,
            dateFormat,
            getTimeWithSecondsFormat()
          );
          break;
        }
        case EventFieldType.bool: {
          return intl.formatMessage(
            {
              id: 'activityLog.wasChecked',
              defaultMessage: `<b>{field}</b> was {value, select, true {checked} other {unchecked}}`,
            },
            { ...tagsMapping(styles), field, value }
          );
        }
        case EventFieldType.url: {
          return intl.formatMessage(
            {
              id: 'activityLog.wasChangedUrl',
              defaultMessage: `<b>{field}</b> was changed to <a>{value}</a>`,
            },
            { ...tagsMapping(styles), field, value: value ?? '\0' }
          );
        }
      }

      if (isExpandable) {
        return intl.formatMessage(
          {
            id: 'activityLog.wasChanged',
            defaultMessage: `<b>{field}</b> was changed`,
          },
          { ...tagsMapping(styles), field }
        );
      }

      return intl.formatMessage(
        {
          id: 'activityLog.wasChangedTo',
          defaultMessage: `<b>{field}</b> was changed to <v>{value}</v>`,
        },
        {
          ...tagsMapping(styles),
          field,
          value: formattedValue ?? '\0', //workaround because null is changed to '' inside intl.formatMessage
        }
      );
    }
    default:
      return intl.formatMessage(
        {
          id: 'activityLog.unknownEvent',
          defaultMessage: '<b>Unknown event</b>',
        },
        { ...tagsMapping(styles) }
      );
  }
};

const tagsMapping = (styles: ReturnType<typeof useEventDataLabelStyles>) => ({
  b: (...chunks: string[]) => <strong>{chunks}</strong>,
  g: (...chunks: string[]) => (
    <strong className={styles.eventDataLabelGreen}>{chunks}</strong>
  ),
  r: (...chunks: string[]) => (
    <strong className={styles.eventDataLabelRed}>{chunks}</strong>
  ),
  v: (chunks: string[]) => {
    if (chunks[0] === '')
      return (
        <strong className={styles.eventDataLabelGray}>
          <FormattedMessage id='activityLog.empty' defaultMessage='(empty)' />
        </strong>
      );
    if (chunks[0] === '\0')
      return (
        <strong className={styles.eventDataLabelRed}>
          <FormattedMessage id='activityLog.null' defaultMessage='NULL' />
        </strong>
      );
    return <strong>{chunks}</strong>;
  },
  a: (chunks: string[]) => {
    if (chunks[0] === '')
      return (
        <strong className={styles.eventDataLabelGray}>
          <FormattedMessage id='activityLog.empty' defaultMessage='(empty)' />
        </strong>
      );
    if (chunks[0] === '\0')
      return (
        <strong className={styles.eventDataLabelRed}>
          <FormattedMessage id='activityLog.null' defaultMessage='NULL' />
        </strong>
      );
    return (
      <a
        className={styles.eventDataLabelAnchor}
        href={chunks[0]}
        target='_blank'
        rel='noopener noreferrer'
      >
        {chunks}
      </a>
    );
  },
});
