import React from 'react';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { useChildCFormBuilderlassComponentStyles } from '../../styles';
import ChildClassControls from '../ChildClassControls';
import ChildClassTable from './components/ChildClassTable';
import { ChildClassComponentProps } from './types';

const ChildClassPreviewComponent = ({
  readOnly = false,
  passThrough,
}: ChildClassComponentProps) => {
  const childClassID = passThrough?.childClassID?.toString() ?? '';
  const styles = useChildCFormBuilderlassComponentStyles({});
  return (
    <Provider store={store}>
      <div className={styles.container}>
        <ChildClassControls readOnly={readOnly} childClassId={childClassID} />
        <ChildClassTable readOnly={readOnly} passThrough={passThrough} />
      </div>
    </Provider>
  );
};

export default ChildClassPreviewComponent;
