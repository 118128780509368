import { RootState } from 'store/reducers';
import { AssigneesType } from 'store/reducers/types/userGroupPermissions.types';

export const getUserGroupPermissionsOwnersCount = (state: RootState) =>
  state.userGroupPermissions.groupPermissions.owners.totalCount;

export const getUserGroupPermissionsOwnersAvatars = (state: RootState) =>
  state.userGroupPermissions.groupPermissions.owners.avatars;

export const getUserGroupOwnersFetching = (state: RootState) =>
  state.userGroupPermissions.groupPermissions.owners.fetching;

export const getUserGroupPermissioinsGroupSelector = (state: RootState) =>
  state.userGroupPermissions.userGroup;

export const getUserGroupPermisionSetsSelector = (state: RootState) =>
  state.userGroupPermissions.permissionSets;

export const getUserGroupPermissionSetAssigneesData = (
  permissionSetId: string
) => (state: RootState) =>
  state.userGroupPermissions.groupPermissions.assignees[permissionSetId] ||
  ({} as AssigneesType);

export const getUserGroupPermissionSetAssigneesAvatars = (
  permissionSetId: string
) => (state: RootState) =>
  state.userGroupPermissions.groupPermissions.assignees[permissionSetId]
    ?.avatars || [];

export const getUserGroupPermissionSetAssigneesFetching = (
  permissionSetId?: string
) => (state: RootState) =>
  !permissionSetId
    ? false
    : state.userGroupPermissions.groupPermissions.assignees[permissionSetId]
        ?.fetching;

export const getUserGroupPermissionSetAssigneesGroupsAvatars = (
  permissionSetId: string
) => (state: RootState) =>
  state.userGroupPermissions.groupPermissions.groupAssignees[permissionSetId]
    ?.avatars || [];

export const getUserGroupPermissionSetAssigneesGroupsFetching = (
  permissionSetId?: string
) => (state: RootState) =>
  !permissionSetId
    ? false
    : state.userGroupPermissions.groupPermissions.groupAssignees[
        permissionSetId
      ]?.fetching;

export const getUserGroupPermissionsGetSelectedPermissionSet = (
  state: RootState
) => {
  return state.userGroupPermissions.selectedPermissionSet;
};

export const getUserGroupPermissionsUserGroupSelector = (state: RootState) => {
  return state.userGroupPermissions.userGroup;
};

export const getUsergGroupPermissionSetsFetching = (state: RootState) => {
  return state.userGroupPermissions.permissionSetsFetching;
};

export const getUserGroupPermissionSetAssigneesCount = (
  permissionSetId: string
) => (state: RootState) =>
  state.userGroupPermissions.groupPermissions.assignees[permissionSetId]
    ?.totalCount;

export const getUserGroupPermissionSetAssigneesGroupsCount = (
  permissionSetId: string
) => (state: RootState) =>
  state.userGroupPermissions.groupPermissions.groupAssignees[permissionSetId]
    ?.totalCount;

export const getUserGroupPermissionSetAssigneesItemsLimit = (
  permissionSetId: string
) => (state: RootState) =>
  state.userGroupPermissions.groupPermissions.assignees[permissionSetId]
    ?.options?.restrictions?.limit_items;

export const getUserGroupPermissionSetAssigneesGroupsItemsLimit = (
  permissionSetId: string
) => (state: RootState) =>
  state.userGroupPermissions.groupPermissions.groupAssignees[permissionSetId]
    ?.options?.restrictions?.limit_items;

export const getUserGroupPermissionSetAssigneesGroupsData = (
  permissionSetId: string
) => (state: RootState) =>
  state.userGroupPermissions.groupPermissions.groupAssignees[permissionSetId] ||
  ({} as AssigneesType);

export const getUserGroupPemissionSetsMeta = (state: RootState) => {
  return state.userGroupPermissions.permissionSetsMeta;
};
