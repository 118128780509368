import { fixMultipleWhiteSpaces } from 'components/Inputs/hooks';

const onChangeTrimSpaces = <T extends HTMLTextAreaElement | HTMLInputElement>(
  onChange: ((e: React.ChangeEvent<T>) => void) | undefined
) => (newEvent: React.ChangeEvent<T>) => {
  if (!onChange) return;

  const valueBeforeTrim = newEvent.target.value;

  newEvent.target.value = fixMultipleWhiteSpaces(valueBeforeTrim);
  onChange(newEvent);
};

export default onChangeTrimSpaces;
