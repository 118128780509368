export const downloadFileFromBlob = (blob: Blob, filename: string) => {
  const blobUrl = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');

  tempLink.style.display = 'none';
  tempLink.href = blobUrl;
  tempLink.download = filename;

  document.body.appendChild(tempLink);

  tempLink.click();

  document.body.removeChild(tempLink);
};
