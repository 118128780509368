import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  SET_OBJECT_CLASSES_FIELDS,
  SET_OBJECT_CLASSES_FIELDS_FETCHING,
  APPEND_OBJECT_CLASSES_FIELDS,
  RESET_OBJECT_CLASSES_FIELDS,
  SET_OBJECT_CLASSES_FIELDS_COLUMNS,
  RESET_OBJECT_CLASSES_FIELDS_COLUMNS,
  SET_OBJECT_CLASSES_FIELDS_SELECTED_ROW,
  SET_OBJECT_CLASSES_FIELDS_DETAILS,
  ADD_FIELD,
  REMOVE_FIELD,
  UPDATE_FIELD,
  REMOVE_OBJECT_CLASSES_FIELDS,
  SET_OBJECT_CLASS_FIELDS_INITIAL_VALUES,
  REORDER_FIELDS,
  RESET_OBJECT_CLASSES_FIELDS_CUSTOM_OFFSET,
} from 'store/constants/objectClassesFields.consts';
import {
  appendTableContent,
  resetTable,
  setTableContent,
  setTableLoading,
  resetTableColumns,
  setTableColumns,
  setSelectedRow,
  removeTableContent,
} from './tablesCommonStateModificators';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import { CommonTableState } from 'utils/types/api/table.types';

import cloneDeep from 'lodash/cloneDeep';
import { ObjectClassField } from '../../utils/types/api/objectClassesFields.types';
import {
  SET_OBJECT_CLASSES_RESTRICTIONS,
  SET_OBJECT_CLASS_FIELDS_RESTRICTIONS,
} from '../constants/objectClasses.consts';
import { parseFields } from 'pages/ObjectClasses/components/ObjectClassForm/utils';
import {
  AddFieldAction,
  DeleteFieldAction,
  ReorderFieldsAction,
  UpdateFieldAction,
} from 'store/actions/objectClassesFieldsActions';

export interface ObjectClassFieldState
  extends CommonTableState<ObjectClassField> {
  initialFields?: Pick<
    ObjectClassField,
    'alias' | 'is_identifier' | 'is_required' | 'is_unique' | 'order'
  >[];
}

const addField = (state: ObjectClassFieldState, action: AddFieldAction) => {
  const udpatedById = Object.fromEntries(
    Object.entries(state.byId).map(([fieldId, field]) => {
      const updatedField: ObjectClassField = {
        ...field,
        order: field.order + 1,
        ...(action.payload.is_identifier ? { is_identifier: false } : {}),
      };

      return [fieldId, updatedField];
    })
  );

  return {
    ...state,
    total: state.total + 1,
    filtered: state.filtered + 1,
    customOffset: state.customOffset + 1,
    allIds: [action.payload.id, ...state.allIds],
    byId: {
      ...udpatedById,
      [action.payload.id]: action.payload,
    },
  };
};

const removeField = (
  state: ObjectClassFieldState,
  action: DeleteFieldAction
) => {
  const { order: removedOrder } = state.byId[action.payload];

  const byIdAfterRemove = Object.fromEntries(
    Object.entries(state.byId)
      // removing field
      .filter(([key]) => action.payload !== key)
      // decrease order for fields with order higher then removed field
      .map(([fieldId, field]) => {
        const fieldWithDecreasedOrder = {
          ...field,
          order: field.order > removedOrder ? field.order - 1 : field.order,
        };

        return [fieldId, fieldWithDecreasedOrder];
      })
  );

  return {
    ...state,
    total: state.total - 1,
    filtered: state.filtered - 1,
    customOffset: state.customOffset - 1,
    allIds: state.allIds.filter(id => id !== action.payload),
    byId: byIdAfterRemove,
  };
};

const updateField = (
  state: ObjectClassFieldState,
  action: UpdateFieldAction
) => {
  const removeIdentifier = () =>
    Object.fromEntries(
      Object.entries(state.byId).map(([fieldId, field]) => [
        fieldId,
        {
          ...field,
          is_identifier: false,
        },
      ])
    );

  return {
    ...state,
    byId: {
      ...(action.payload.is_identifier ? removeIdentifier() : state.byId),
      [action.payload.id]: {
        ...state.byId[action.payload.id],
        ...action.payload,
      },
    },
  };
};

const reorderFields = (
  state: ObjectClassFieldState,
  action: ReorderFieldsAction
) => {
  const { fromId, toOrder } = action.payload;
  const fromOrder = state.byId[fromId].order;
  const isDraggingFromTopToBottom = fromOrder < toOrder;

  const reorderedById = Object.fromEntries(
    Object.entries(state.byId).map(([fieldId, field]) => {
      const { order: fieldOrder } = field;

      if (fieldOrder === fromOrder) {
        return [fieldId, { ...field, order: toOrder }];
      }

      const updatedOrder = isDraggingFromTopToBottom
        ? {
            order:
              fieldOrder > fromOrder && fieldOrder <= toOrder
                ? fieldOrder - 1
                : fieldOrder,
          }
        : {
            order:
              fieldOrder < fromOrder && fieldOrder >= toOrder
                ? fieldOrder + 1
                : fieldOrder,
          };

      return [fieldId, { ...field, ...updatedOrder }];
    })
  );

  return {
    ...state,
    byId: reorderedById,
  };
};

const ObjectClassesFieldsReducer: Reducer<ObjectClassFieldState, RootAction> = (
  state = cloneDeep(INITIAL_TABLE_STATE),
  action
) => {
  switch (action.type) {
    case SET_OBJECT_CLASSES_FIELDS: {
      return setTableContent<
        ObjectClassField,
        typeof SET_OBJECT_CLASSES_FIELDS
      >(state, action);
    }
    case APPEND_OBJECT_CLASSES_FIELDS:
      return appendTableContent<
        ObjectClassField,
        typeof APPEND_OBJECT_CLASSES_FIELDS
      >(state, action, true);
    case SET_OBJECT_CLASSES_FIELDS_FETCHING:
      return setTableLoading<
        ObjectClassField,
        typeof SET_OBJECT_CLASSES_FIELDS_FETCHING
      >(state, action);
    case RESET_OBJECT_CLASSES_FIELDS:
      return resetTable<ObjectClassField>(
        state,
        cloneDeep(INITIAL_TABLE_STATE)
      );
    case RESET_OBJECT_CLASSES_FIELDS_COLUMNS:
      return resetTableColumns<ObjectClassField>(
        state,
        cloneDeep(INITIAL_TABLE_STATE)
      );
    case SET_OBJECT_CLASSES_FIELDS_COLUMNS:
      return setTableColumns<
        ObjectClassField,
        typeof SET_OBJECT_CLASSES_FIELDS_COLUMNS
      >(state, action);
    case SET_OBJECT_CLASSES_FIELDS_SELECTED_ROW:
      return setSelectedRow<
        ObjectClassField,
        typeof SET_OBJECT_CLASSES_FIELDS_SELECTED_ROW
      >(state, action);
    case SET_OBJECT_CLASSES_FIELDS_DETAILS:
      return {
        ...state,
        details: action.payload,
      };
    case SET_OBJECT_CLASSES_RESTRICTIONS:
      return { ...state, restrictions: action.restrictions };
    case SET_OBJECT_CLASS_FIELDS_RESTRICTIONS:
      return { ...state, restrictions: action.restrictions };
    case ADD_FIELD:
      return addField(state, action);
    case REMOVE_FIELD:
      return removeField(state, action);
    case UPDATE_FIELD:
      return updateField(state, action);
    case REORDER_FIELDS:
      return reorderFields(state, action);
    case RESET_OBJECT_CLASSES_FIELDS_CUSTOM_OFFSET:
      return {
        ...state,
        customOffset: INITIAL_TABLE_STATE.customOffset,
      };
    case REMOVE_OBJECT_CLASSES_FIELDS:
      return removeTableContent<
        ObjectClassField,
        typeof REMOVE_OBJECT_CLASSES_FIELDS
      >(state, action);
    case SET_OBJECT_CLASS_FIELDS_INITIAL_VALUES:
      return {
        ...state,
        initialFields: parseFields(action.list),
      };
    default:
      return state;
  }
};

export default ObjectClassesFieldsReducer;
