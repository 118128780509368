import routes from './routingPaths';
import { PermissionsState } from './types/api/permissions.types';

/*
specific permissions checkers e.g. (permissions.sequences.list)
*/
export const hasAllPermissions = (permissions: boolean[]) =>
  permissions.every(permission => permission === true);

export const hasAnyPermission = (permissions: boolean[]) =>
  permissions.some(permission => permission === true);

/*
overeall permissions checkers e.g. (permissions.sequences)
*/
export const hasAnyActionPermissions = (
  permissionsActions: MappedObject<boolean>[]
) =>
  permissionsActions.some(permissionActions =>
    hasAnyPermission(Object.values(permissionActions))
  );

/* !!!!!!!!!!!!!!!!!!!!!!!! - IMPORTANT !!!!!!!!!!!!!!!!!!!!!!!!!
  access routes should be always ordered from the most specific ones to least specific
  ie. /task-configuration/tasks/:id should be before /task-configuration/tasks, in other
  case getMatchingPermission() will always find less specific patch before more specific ones
  */
export const buildUrlPermissionDict = (permissions: PermissionsState) => ({
  // work management
  [routes.CREATE_RECORD]: true,
  [routes.RECORD]: true, // there is no global perm on it, only permissions of resource are checked
  [routes.RECORDS]: hasAnyPermission([
    permissions.object_records.list,
    permissions.object_records.create,
  ]),
  [routes.WORKSPACE]: true, // access to the workspace/tasks page in navigation should always be unlocked
  [routes.TASKS]: true, // same as above

  // system configuration
  [routes.LANDING_PAGE]: permissions.ui_config.create,
  [routes.THEME]: permissions.ui_config.create,
  [routes.HELP_MAPPING]: permissions.ui_config.create,
  [routes.OBJECT_CLASSES_CREATE]: hasAnyPermission([
    permissions.object_classes.create,
  ]),
  [routes.OBJECT_CLASSES_EDIT]: true, // there is no global perm on it, only permissions of resource are checked
  [routes.OBJECT_CLASSES]: hasAnyPermission([
    permissions.object_classes.list,
    permissions.object_classes.create, // Frontend allows to go on list page if user has create permission
  ]),
  [routes.RELATIONAL_MODELLER]: hasAnyPermission([
    permissions.object_models.edit, // Frontend allows to go on relational modeller page if user has edit permission
  ]),
  [routes.TASK_TEMPLATE_CREATE]: hasAnyPermission([
    permissions.task_templates.create,
  ]),
  [routes.TASK_TEMPLATE_EDIT]: true,
  [routes.TASK_TEMPLATES]: hasAnyPermission([permissions.task_templates.list]),
  [routes.TASK_GROUP_TEMPLATES_CREATE]: hasAnyPermission([
    permissions.task_group_templates.create,
  ]),
  [routes.TASK_GROUP_TEMPLATES_EDIT]: true,
  [routes.TASK_GROUPS]: hasAnyPermission([
    permissions.task_group_templates.list,
  ]),
  [routes.SEQUENCE_CREATE]: hasAnyPermission([permissions.sequences.create]),
  [routes.SEQUENCE_EDIT]: true,
  [routes.SEQUENCES]: hasAnyPermission([permissions.sequences.list]),
  [routes.SYSTEM_CONFIGURATION]: hasAnyPermission([
    // permissions.forms.list,
    permissions.object_classes.list,
    permissions.object_classes.create,
    permissions.task_templates.list,
    permissions.task_group_templates.list,
    permissions.sequences.list,
  ]),
  // administration
  [routes.USER_CREATE]: hasAnyPermission([permissions.users.create]),
  [routes.USER_EDIT]: true,
  [routes.USERS]: hasAnyPermission([permissions.users.list]),
  [routes.USER_GROUP_CREATE]: hasAnyPermission([
    permissions.user_groups.create,
  ]),
  [routes.USER_GROUP_EDIT]: true,
  [routes.USER_GROUPS]: hasAnyPermission([
    permissions.user_groups.list,
    permissions.user_groups.create, // Frontend allows to go on list page if user has create permission
  ]),
  [routes.ROLES_CREATE]: hasAnyPermission([permissions.roles.create]),
  [routes.ROLES_EDIT]: true,
  [routes.ROLES]: hasAnyPermission([permissions.roles.list]),
  [routes.COMPANY_DETAILS]: hasAnyPermission([
    permissions.company_details.view,
  ]),
  [routes.STANDARD_AUTHENTICATION]: hasAnyPermission([
    permissions.standard_authentication.edit,
  ]),
  [routes.SINGLE_SIGN_ON_CREATE]: hasAnyPermission([
    permissions.single_sign_on.create,
  ]),
  [routes.SINGLE_SIGN_ON_EDIT]: true,
  [routes.SINGLE_SIGN_ONS]: hasAnyPermission([permissions.single_sign_on.list]),
  [routes.CUSTOM_COMPONENTS]: hasAnyPermission([
    permissions.custom_components.list,
  ]),
  [routes.CUSTOM_COMPONENTS_CREATE]: hasAnyPermission([
    permissions.custom_components.create,
  ]),
  [routes.CUSTOM_COMPONENTS_EDIT]: hasAnyPermission([
    permissions.custom_components.edit,
  ]),
  [routes.OUTBOUND_MAIL_SETUP]: hasAnyPermission([
    permissions.outbound_mail_setup.view,
  ]),
  [routes.AUTHENTICATION_OBJECT_CREATE]: hasAnyPermission([
    permissions.authentication_objects.create,
  ]),
  [routes.AUTHENTICATION_OBJECT_EDIT]: true,
  [routes.AUTHENTICATION_OBJECTS]: hasAnyPermission([
    permissions.authentication_objects.list,
  ]),
  [routes.ADMINISTRATION]: hasAnyPermission([
    permissions.users.list,
    permissions.user_groups.list,
    permissions.user_groups.create,
    permissions.roles.list,
    permissions.company_details.view,
    permissions.standard_authentication.edit,
    permissions.single_sign_on.list,
    permissions.outbound_mail_setup.view,
    permissions.authentication_objects.list,
    permissions.custom_components.list,
  ]),
});
