import React from 'react';
import { FormDataValue } from 'components/FormPreview/types';
import { CustomFileItem } from 'components/lib/FileUpload/types';
import { TaskStatus } from 'utils/Enums/TaskStatus';

export enum CheckTaskPermission {
  View = 'view',
  Complete = 'complete',
}

export interface TaskFormProps {
  onCancelClick?: (
    shouldRefetchData?: boolean | undefined,
    validateModal?: boolean
  ) => Promise<void> | void;
  taskId: number | string;
  readOnly: boolean;
  updatePageHeader?: boolean;
  cancelButtonContent?: React.ReactElement | string;
  checkPermission?: CheckTaskPermission;
  shouldSetSelectedResource?: boolean;
}

export interface TaskHeaderProps {
  recordId: number;
  taskName: string;
  taskId: number | string;
  status: TaskStatus;
}

export interface UseCompleteOrSaveTaskParams {
  parsedFormData: MappedObject<FormDataValue>;
  parsedFilesData: MappedObject<CustomFileItem[]>;
  isTaskSaving: boolean;
}
