import { apiCall } from 'utils/api';
import { downloadFileFromBlob } from 'utils/functions/downloadFileFromBlob';
import showDefaultErrorToast from 'utils/functions/showDefaultErrorToast';

export const downloadFile = async (name: string, url: string) => {
  try {
    const path = new URL(url).pathname;

    const { data: blob } = await apiCall.get(path, { responseType: 'blob' });

    if (!blob) {
      return;
    }

    downloadFileFromBlob(blob, name);
  } catch {
    showDefaultErrorToast();
  }
};
