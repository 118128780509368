import React from 'react';
import { URLIcon } from 'components/Icon';
import useInputStyles from 'components/lib/Input/styles';
import clsx from 'clsx';
import StandardFieldWrapper from 'components/FormPreview2/components/StandardFieldWrapper';
import { FormLabelWithToolTip } from 'pages/TaskTemplates/components/FormLabel';
import { FormItem } from 'components/lib/Form';
import useCustomWidgetsStyles from '../../customWidgetsStyles';
import { Widget } from 'alx-dynamic-form';
import useURLWidgetStyles from './styles';
import { useURLField } from './hooks';

const CustomURLWidget: Widget = ({
  value,
  onChange,
  description,
  errors,
  required,
  label,
  reValidateField,
  disabled,
  fieldAlias,
}) => {
  const inputClasses = useInputStyles({});
  const widgetClasses = useCustomWidgetsStyles({});
  const urlWidgetClasses = useURLWidgetStyles({});
  const { protocolInput, urlInput } = useURLField({
    onChange,
    reValidateField,
    value,
    disabled,
    fieldAlias,
  });

  return (
    <>
      <FormLabelWithToolTip
        dynamicSpacing
        {...{ required }}
        className={clsx([
          widgetClasses.inputLabel,
          widgetClasses.inputLabelEllipsis,
        ])}
        label={label}
      />
      <FormItem
        dynamicSpacing
        validateStatus={errors?.length ? 'error' : undefined}
      >
        <StandardFieldWrapper {...{ description, errors }}>
          <div
            className={clsx(inputClasses.customInput, {
              [inputClasses.error]: !!errors?.length,
            })}
          >
            <div className={urlWidgetClasses.inputsContainer}>
              <div className={urlWidgetClasses.protocol}>{protocolInput}</div>
              <div className={urlWidgetClasses.url}>
                {urlInput}
                <div className={inputClasses.inputIconWrapper}>
                  <URLIcon className={inputClasses.icon} size={16} />
                </div>
              </div>
            </div>
          </div>
        </StandardFieldWrapper>
      </FormItem>
    </>
  );
};

export default CustomURLWidget;
