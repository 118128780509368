import { RootState } from 'store/reducers';

export const globalPreferencesSelector = (state: RootState) =>
  state.globalPreferences.data;

export const getMappedAppRoutesToHelpPanelSelector = (state: RootState) =>
  state.globalPreferences.data.mappedAppRoutesToHelpPanel;

export const getGlobalPreferencesSavingSelector = (state: RootState) =>
  state.globalPreferences.saving;

export const getLandingPageDataSelector = (state: RootState) =>
  state.globalPreferences.data.landingPageData;
