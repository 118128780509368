const useStepChange = () => (
  stepValue: number,
  value: number | string | undefined,
  onChange: ((value: number | string | undefined) => void) | undefined
) => {
  if (!onChange) return;

  if (value === undefined) {
    onChange(stepValue);

    return;
  }

  const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
  const roundedValue = Math.round((parsedValue + stepValue) * 1e12) / 1e12;

  onChange(roundedValue);

  return roundedValue;
};

export default useStepChange;
