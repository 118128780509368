import React, { useEffect, useMemo, useState } from 'react';
import FormField from 'pages/TaskTemplates/components/FormField';
import { Col } from 'components/lib/Grid';
import { FormikSwitch } from 'components/lib/Switch';
import { FormattedMessage } from 'react-intl';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import clsx from 'clsx';
import {
  OBJECT_CLASS_FIELD_IDENTIFIER,
  OBJECT_CLASS_FIELD_UNIQUE,
  OBJECT_CLASS_FIELD_UNIQUE_ERROR,
} from 'utils/testIds';
import { getObjectClassFieldsDetails } from 'store/selectors/objectClassesFieldsSelectors';
import { useClassFieldFormContext } from '../../../context/ClassFieldFormContext';
import { FormMode } from 'utils/Enums/FormMode';
import {
  ClassFieldForm,
  ClassFieldFormFields,
  FieldFormCategories,
  IdentifierAllowedTypes,
} from '../types';
import useClassFieldPropertiesStyles from '../styles';
import { MAX_IDENTIFIER_LENGTH } from '../../../consts';

export const useAdditionalControls = () => {
  const classes = useClassFieldPropertiesStyles({});
  const details = useSelector(getObjectClassFieldsDetails);
  const {
    values: {
      alias,
      type,
      has_duplicates: hasDuplicates,
      max_length = MAX_IDENTIFIER_LENGTH,
    },
    errors: { is_unique: uniqueErrors },
    setFieldValue,
    initialValues,
  } = useFormikContext<ClassFieldForm>();
  const { mode, setInitialValues } = useClassFieldFormContext();
  const [uniqueSchemaTypes, setUniqueSchemaTypes] = useState<string[]>([]);

  // if field had recieved error from api that it cannot be unique
  const [hadUniqueError, setHadUniqueError] = useState(false);

  const { uniquenessNotAllowed, schemaType } = useMemo(() => {
    const schemaType = details?.schema.find(
      ({ alias }) => alias === ClassFieldFormFields.Type
    );
    const uniquenessNotAllowed = !uniqueSchemaTypes.includes(type);

    return { uniquenessNotAllowed, schemaType };
  }, [details, type, uniqueSchemaTypes]);

  const handleChangeIdentifier = (isIdentifier: boolean) => {
    const maxLength =
      isIdentifier && max_length > MAX_IDENTIFIER_LENGTH
        ? MAX_IDENTIFIER_LENGTH
        : max_length;

    setFieldValue(ClassFieldFormFields.MaxLength, maxLength);
  };

  useEffect(() => {
    if (schemaType) {
      setUniqueSchemaTypes(
        schemaType?.values
          ?.filter(({ is_unique: isUnique }) => !!isUnique)
          .map(({ value }) => value) || []
      );
    }
  }, [schemaType]);

  useEffect(() => {
    if (mode === FormMode.Edit) return;

    setFieldValue(ClassFieldFormFields.Unique, undefined);
    setFieldValue(ClassFieldFormFields.Identifier, undefined);
    setFieldValue(ClassFieldFormFields.Required, undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  // if field had uniqueError save it in separate state to preserve this error
  // then force uniqueValue to false and update initialValues remove touched state from is_unique
  useEffect(() => {
    if (mode !== FormMode.Edit || !uniqueErrors) return;

    setFieldValue(ClassFieldFormFields.Unique, false);
    setInitialValues({ ...initialValues, is_unique: false });
    setHadUniqueError(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueErrors, mode, setFieldValue, setInitialValues]);

  // if different field is chosen reset the error
  useEffect(() => {
    setHadUniqueError(false);
  }, [alias]);

  const fieldProperties: JSX.Element[] = [
    ...(IdentifierAllowedTypes.includes(type)
      ? [
          <FormField key={ClassFieldFormFields.Identifier} gutter={0}>
            <Col span={24}>
              <FormikSwitch
                name={ClassFieldFormFields.Identifier}
                data-testid={OBJECT_CLASS_FIELD_IDENTIFIER}
                onChange={handleChangeIdentifier}
              />
              <FormLabel inline className={classes.defaultBoolLabel}>
                <FormattedMessage
                  id='objectClasses.form.useAsIdentifier'
                  defaultMessage='Use as identifier'
                />
              </FormLabel>
            </Col>
          </FormField>,
        ]
      : []),
  ];

  return {
    [FieldFormCategories.FieldProperties]: fieldProperties,
    [FieldFormCategories.SelectionOptions]: [],
    [FieldFormCategories.Validation]: uniquenessNotAllowed
      ? []
      : [
          <FormField key={ClassFieldFormFields.Unique} gutter={0}>
            <Col span={24}>
              <FormikSwitch
                disabled={hasDuplicates || hadUniqueError}
                name={ClassFieldFormFields.Unique}
                data-testid={OBJECT_CLASS_FIELD_UNIQUE}
              />
              <FormLabel inline className={classes.defaultBoolLabel}>
                <FormattedMessage
                  id='objectClasses.form.uniqueValue'
                  defaultMessage='Unique value'
                />
              </FormLabel>
            </Col>
            {(hasDuplicates || hadUniqueError) && (
              <Col
                span={24}
                className={clsx(classes.identifierLimitMessage, {
                  [classes.error]: hadUniqueError,
                })}
              >
                <FormattedMessage
                  data-testid={OBJECT_CLASS_FIELD_UNIQUE_ERROR}
                  id='objectClasses.form.theFieldCannotBeSetAsUniqueBecauseItContainsDuplicateValues'
                  defaultMessage='The field cannot be set as unique because it contains duplicate values.'
                />
              </Col>
            )}
          </FormField>,
        ],
  };
};
