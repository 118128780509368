import { createContext, useContext } from 'react';

interface LayoutContext {
  isSidebarCollapsed: boolean;
}

const layoutContext = createContext<LayoutContext | undefined>(undefined);

const LayoutContextProvider = layoutContext.Provider;

const useLayoutContext = () => {
  const context = useContext<LayoutContext | undefined>(layoutContext);

  if (context === undefined) {
    throw new Error(
      'useLayoutContext: Context undefined. Did you forget to wrap the component in LayoutContextProvider?'
    );
  }

  return context;
};

export { LayoutContextProvider, useLayoutContext };
