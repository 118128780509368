import { StatusCodes } from 'http-status-codes';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootAction, RootState } from 'store/reducers';
import { apiCall } from 'utils/api';
import {
  OBJECT_CLASS_RECORD_PERMISSION_SETS,
  OBJECT_CLASS_RECORD_PERMISSION_SET_ASSIGNEES,
  OBJECT_RECORD_OWNERS,
} from 'utils/endpoints';
import { GetResponse } from 'utils/types';
import { RecordOwner } from 'utils/types/api/objectRecords.types';
import { generatePath } from 'react-router-dom';
import {
  SET_OBJECT_RECORD_USERS,
  SET_OBJECT_RECORD_USERS_ERROR,
  SET_OBJECT_RECORD_USERS_FETCHING,
  SET_CLASS_PERMISSION_SETS,
} from 'store/constants/objectRecord.consts';
import { RecordPeopleWithCount } from 'pages/Records/types';
import { ResponseError } from 'utils/types/errorResponse';
import { PermissionSetSelectOptions } from 'pages/Records/RecordsListing/RecordAccessPanel/components/RecordOwners/types';
import { PermissionSetFromAPI } from 'pages/ObjectClasses/components/ObjectClassForm/ObjectClassPermissions/components/PermissionsTable/types';
import { PeopleListElementProps } from 'pages/Records/RecordsListing/RecordAccessPanel/components/PeopleListElement/types';
import { getObjectRecordUsersLoading } from 'store/selectors/objectRecordSelectors';

export interface SetObjectRecordUsersError
  extends Action<typeof SET_OBJECT_RECORD_USERS_ERROR> {
  payload: ResponseError | undefined;
}
export interface SetObjectRecordUsersFetching
  extends Action<typeof SET_OBJECT_RECORD_USERS_FETCHING> {
  payload: boolean;
}
export interface SetObjectRecordUsers
  extends Action<typeof SET_OBJECT_RECORD_USERS> {
  payload: { recordId: string; data: RecordPeopleWithCount };
}

export interface SetClassPermissionSets
  extends Action<typeof SET_CLASS_PERMISSION_SETS> {
  payload: PermissionSetFromAPI[];
}

const transformRecordUsers = (
  responseData: GetResponse<RecordOwner>,
  permissionSetId: number
): RecordPeopleWithCount => {
  const getRequiredFields = (data: RecordOwner[]) =>
    data.map(
      ({
        id: ownershipId,
        owner_id: id,
        category,
        user: {
          first_name: firstName,
          last_name: lastName,
          company_name: company,
          username: email,
          id: userId,
        } = {},
      }) => {
        const isOwner = permissionSetId === PermissionSetSelectOptions.Owners;

        return {
          ownershipId: isOwner ? ownershipId : undefined,
          firstName,
          lastName,
          id,
          userId,
          category,
          company,
          email,
          permissionSetIds: [permissionSetId],
          isOwner,
        };
      }
    );

  return {
    id: permissionSetId,
    filteredCount: responseData.filtered_count,
    total: responseData.total_count,
    results: getRequiredFields(responseData.results),
  };
};

export type ObjectRecordAction =
  | SetObjectRecordUsersError
  | SetObjectRecordUsersFetching
  | SetObjectRecordUsers
  | SetClassPermissionSets;

export const setObjectRecordUsersError = (
  payload: ResponseError | undefined
): SetObjectRecordUsersError => ({
  type: SET_OBJECT_RECORD_USERS_ERROR,
  payload,
});

export const setObjectRecordUsersFetching = (
  payload: boolean
): SetObjectRecordUsersFetching => ({
  type: SET_OBJECT_RECORD_USERS_FETCHING,
  payload,
});

export const setObjectRecordUsers = (
  recordId: string,
  data: RecordPeopleWithCount
): SetObjectRecordUsers => ({
  type: SET_OBJECT_RECORD_USERS,
  payload: { recordId, data },
});

export const setClassPermissionSets = (
  payload: PermissionSetFromAPI[]
): SetClassPermissionSets => ({
  type: SET_CLASS_PERMISSION_SETS,
  payload,
});

const reduceResults = (
  results: RecordPeopleWithCount[],
  permissionSetId = PermissionSetSelectOptions.Owners
) =>
  results.reduce(
    (total, curr) => {
      const newResults = {
        ...total.results,
        ...Object.fromEntries(
          curr.results.map(result => {
            const permissionSetIds = [
              ...(total.results?.[result.userId || '']?.permissionSetIds || []),
              ...(result.permissionSetIds || []),
            ];

            const ownershipId =
              total.results?.[result.userId || '']?.ownershipId ||
              result.ownershipId;

            const isOwner =
              result.isOwner || total.results?.[result.userId || '']?.isOwner;

            return [
              result.userId,
              { ...result, permissionSetIds, isOwner, ownershipId },
            ];
          })
        ),
      };
      return {
        id: curr.id,
        filteredCount: 0,
        total: 0,
        results: newResults as MappedObject<PeopleListElementProps>,
      };
    },
    {
      id: permissionSetId,
      filteredCount: 0,
      total: 0,
      results: {} as MappedObject<PeopleListElementProps>,
    }
  );

const getAllAssignees = async <t>(
  path: string,
  resultsAcc: t[]
): Promise<{
  results: t[];
  total_count: number;
  filtered_count: number;
  status: StatusCodes;
}> => {
  const {
    status,
    data: { results, total_count, filtered_count, next },
  } = await apiCall.get<GetResponse<any>>(path);
  if (status !== StatusCodes.OK) {
    return {
      status,
      results,
      total_count: results?.length ?? 0,
      filtered_count,
    };
  }
  if (!next) {
    return {
      status,
      results: [...resultsAcc, ...results],
      total_count: total_count,
      filtered_count,
    };
  }
  const { pathname, searchParams } = new URL(next);
  return await getAllAssignees(`${pathname}?${searchParams}`, [
    ...resultsAcc,
    ...results,
  ]);
};

export const getObjectRecordUsers = (
  recordId: string | undefined,
  permissionSetId = PermissionSetSelectOptions.Owners,
  objectClassId?: string | number
): ThunkAction<void, RootState, undefined, RootAction> => async (
  dispatch,
  getState
) => {
  const isFetching = getObjectRecordUsersLoading(getState());

  if (recordId === undefined || isFetching) return;

  dispatch(setObjectRecordUsersFetching(true));

  try {
    const dataSources = [
      {
        url: generatePath(OBJECT_RECORD_OWNERS, { id: recordId }),
        permissionSetId: PermissionSetSelectOptions.Owners,
      },
    ];

    if (objectClassId) {
      const { status, data } = await apiCall.get<
        GetResponse<PermissionSetFromAPI>
      >(
        generatePath(OBJECT_CLASS_RECORD_PERMISSION_SETS, { id: objectClassId })
      );

      if (status === StatusCodes.OK) {
        dispatch(setClassPermissionSets(data.results));

        data.results.forEach(({ id: permissionSetId }) => {
          dataSources.push({
            url: generatePath(OBJECT_CLASS_RECORD_PERMISSION_SET_ASSIGNEES, {
              recordId,
              permissionSetId,
            }),
            permissionSetId,
          });
        });
      }
    }

    const results = dataSources.length
      ? await Promise.all(
          dataSources.map(async ({ url, permissionSetId }) => {
            const {
              status,
              results,
              filtered_count,
              total_count,
            } = await getAllAssignees(url, []);

            if (status === StatusCodes.OK) {
              return transformRecordUsers(
                {
                  results,
                  filtered_count,
                  total_count,
                  limit: 0,
                  offset: 0,
                  next: null,
                  previous: null,
                } as GetResponse<RecordOwner>,
                permissionSetId
              );
            }

            return {
              id: permissionSetId,
              filteredCount: 0,
              total: 0,
              results: [],
            };
          })
        )
      : [];

    const tmpResult = reduceResults(results, permissionSetId);

    const filteredTmpResults = Object.values(
      tmpResult.results
    ).filter(({ permissionSetIds }) =>
      permissionSetId === PermissionSetSelectOptions.All
        ? true
        : permissionSetIds?.includes(permissionSetId)
    );

    const result: RecordPeopleWithCount = {
      ...tmpResult,
      filteredCount: filteredTmpResults.length,
      total: filteredTmpResults.length,
      results: filteredTmpResults,
    };

    dispatch(setObjectRecordUsers(recordId, result));
    dispatch(setObjectRecordUsersError(undefined));
  } catch (error) {
    dispatch(setObjectRecordUsersError(error?.response));
  } finally {
    dispatch(setObjectRecordUsersFetching(false));
  }
};
