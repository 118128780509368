import { useMemo } from 'react';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { SingleSelectOption } from 'utils/types/selectInput.types';

const useGenerateSelectOptions = (
  options: SingleSelectOption[],
  intlPrefix: string
) =>
  useMemo(() => {
    if (!options) return [];

    return options.map(({ value, text }) => ({
      label:
        GlobalIntlSingleton.dynamicFormatMessage({
          id: `${intlPrefix}.${value}`,
          defaultMessage: text,
        }) || text,
      value: value.toString(),
    }));
  }, [options, intlPrefix]);

export default useGenerateSelectOptions;
