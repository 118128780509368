import { RootState } from 'store/reducers';
import TablesType from 'utils/Enums/TablesType';
import { PreferencesTypes } from 'utils/types/api/preferences.types';

export const getObjectRecordsLoading = (state: RootState) =>
  state.objectRecords.fetching;

export const getObjectRecordsTotal = (state: RootState) =>
  state.objectRecords.total;

export const getObjectRecordsLimitItems = (state: RootState) =>
  state.objectRecords.restrictions.limit_items_in_object_class;

export const getObjectRecordsFiltered = (state: RootState) =>
  state.objectRecords.filtered;

export const getObjectRecordsError = (state: RootState) =>
  state.objectRecords?.error;

export const getObjectRecordsSelectedColumns = (state: RootState) => {
  const selectedClassId =
    state.preferences.data[PreferencesTypes.GeneralPreferences].selectedClassId;
  const currentTableName = state.filters.currentTable;

  if (selectedClassId && currentTableName === TablesType.ObjectRecords) {
    return state.objectRecords.selectedColumns?.[selectedClassId];
  }
  return undefined;
};

export const getObjectRecordsInitialColumns = (state: RootState) => {
  const selectedClassId =
    state.preferences.data[PreferencesTypes.GeneralPreferences].selectedClassId;
  const currentTableName = state.filters.currentTable;

  if (selectedClassId && currentTableName === TablesType.ObjectRecords) {
    return state.objectRecords.initialColumns?.[selectedClassId];
  }
  return undefined;
};
