import React from 'react';
import DOMPurify from 'dompurify';
import { SanitizedHTMLProps } from './types';

const SanitizedHTML: React.FC<SanitizedHTMLProps> = ({ html, ...props }) => (
  <div
    {...props}
    dangerouslySetInnerHTML={{
      __html: DOMPurify.sanitize(html, { ADD_ATTR: ['target'] }),
    }}
  />
);

export default SanitizedHTML;
