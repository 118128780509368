import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { TOP_MENU_HEIGHT } from 'utils/consts';
import get from 'lodash/get';

const layoutDefaultStyles = {
  transition: 'all 0.2s',
};

const defaultHeaderStyles = {
  padding: '0px',
  position: 'fixed',
  zIndex: 1,
  transition: 'all 0.2s',
  display: 'flex',
  backgroundColor: 'white',
  boxShadow: '0px 4px 30px rgba(0, 23, 49, 0.15)',
  height: TOP_MENU_HEIGHT,
  lineHeight: `${TOP_MENU_HEIGHT}px`,
};

type UsePageLayoutStylesProps = {
  currentSidebarWidth?: number;
};

const usePageLayoutStyles = createUseStyles((theme: ThemeState) => {
  return {
    layout: ({ currentSidebarWidth }: UsePageLayoutStylesProps) =>
      currentSidebarWidth
        ? {
            ...layoutDefaultStyles,
            marginLeft: currentSidebarWidth,
            '--navigationMenuWidth': `${currentSidebarWidth}px`,
          }
        : {
            ...layoutDefaultStyles,
            '--navigationMenuWidth': `${currentSidebarWidth}px`,
          },
    sider: {
      overflow: 'hidden',
      height: '100vh',
      position: 'fixed',
      left: 0,
      zIndex: 99,
      '& *': {
        scrollbarWidth: 'thin',
        scrollbarColor: '#dde3e8 transparent !important;',
      },
      '& *::-webkit-scrollbar-thumb': {
        border: `8px solid ${theme['sider-bg']} !important`,
      },
      '& .ant-layout-sider-children': {
        backgroundColor: `${theme['sider-bg']} !important`,
        display: 'flex',
        flexDirection: 'column',
        '& .ant-menu-root': {
          overflow: 'auto',
        },
      },
      '& .ant-layout-sider-trigger': {
        position: 'initial',
        marginTop: 'auto',
        borderTop: '2px solid rgba(158,171,203,0.4)',
        textAlign: 'right',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'inherit',
        '& svg': {
          height: 40,
          width: 40,
          padding: 12,
          borderRadius: 9,
        },
      },
      '&.ant-layout-sider-collapsed': {
        '& .ant-layout-sider-trigger': {
          justifyContent: 'center',
        },
      },
    },
    layoutHeader: ({ currentSidebarWidth }: UsePageLayoutStylesProps) =>
      currentSidebarWidth
        ? {
            ...defaultHeaderStyles,
            width: `calc(100% - ${currentSidebarWidth}px)`,
            zIndex: 10,
          }
        : defaultHeaderStyles,
    flexLayoutWrapper: {
      width: '100%',
      height: `calc(100vh - ${TOP_MENU_HEIGHT}px)`,
      marginTop: TOP_MENU_HEIGHT,
      position: 'relative',
      backgroundColor: '#fff',
    },
    menuItemsSeparator: {
      margin: '16px 0',
      backgroundColor: get(
        theme,
        ['sider-bg-separater'],
        theme['dark-blue-separator-color']
      ),
      '.sea-blue &': {
        backgroundColor: get(
          theme,
          ['sider-bg-separater'],
          theme['sea-blue-light-color']
        ),
      },
      '&:first-child, &:last-child': {
        display: 'none',
      },
    },
  };
});

export default usePageLayoutStyles;
