import React from 'react';
import SelectWidget from './select';
import useCustomSelectWidget from './styles';
import useSelectStyles from 'components/lib/Select/styles';
import clsx from 'clsx';
import { FormLabelWithToolTip } from 'pages/TaskTemplates/components/FormLabel';
import StandardFieldWrapper from 'components/FormPreview2/components/StandardFieldWrapper';
import { FormItem } from 'components/lib/Form';
import useCustomWidgetsStyles from '../../customWidgetsStyles';
import useFormPreview2Container from 'components/FormPreview2/hooks/useFormPrevievContainer';
import { Widget } from 'alx-dynamic-form';

const CustomSelectWidget: Widget = props => {
  const classes = useCustomSelectWidget();
  const selectClasses = useSelectStyles({});
  const widgetClasses = useCustomWidgetsStyles({});
  const getPopupContainer = useFormPreview2Container();

  const { required, label, errors, description, reValidateField } = props;

  return (
    <>
      <FormLabelWithToolTip
        dynamicSpacing
        className={clsx([
          widgetClasses.inputLabel,
          widgetClasses.inputLabelEllipsis,
        ])}
        {...{ required }}
        label={label}
      />
      <FormItem
        dynamicSpacing
        validateStatus={errors?.length ? 'error' : undefined}
      >
        <StandardFieldWrapper {...{ description, errors }}>
          <div
            className={clsx([
              selectClasses.multiselect,
              classes.selectStyles,
              classes.limitedMaxSizeMid,
            ])}
          >
            <SelectWidget
              {...{ getPopupContainer, ...props }}
              onBlur={reValidateField}
            />
          </div>
        </StandardFieldWrapper>
      </FormItem>
    </>
  );
};

export default CustomSelectWidget;
