import React, { useContext } from 'react';
import { useActiveMenuKey } from '../hooks';
import { SideMenuContextType } from '../types';

export const SideMenuContext = React.createContext<SideMenuContextType>({
  activeMenuKey: [],
});

export const SideMenuContextProvider: React.FC = ({ children }) => {
  const activeMenuKey = useActiveMenuKey();

  return (
    <SideMenuContext.Provider value={{ activeMenuKey }}>
      {children}
    </SideMenuContext.Provider>
  );
};

export const useSideMenuContext = () => useContext(SideMenuContext);
