import { createUseStyles } from 'react-jss';

type UseLogoIconStylesProps = {
  height?: number;
};

const useLogoIconStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    height: ({ height }: UseLogoIconStylesProps) => `${height}px`,
    alignItems: 'center',
  },
  icon: {
    height: 'fit-content',
  },
});

export default useLogoIconStyles;
