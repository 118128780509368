import {
  ObjectClassCountObjectRecords,
  ObjectClassFieldObjectClasses,
} from 'utils/types/api/objectClassesFields.types';
import { TreeOfStrings } from './TreeOfStringsDisplay/types';

export enum ViewUsageDisplayType {
  Name = 'name',
  NameWithNumber = 'nameWithNumber',
  Number = 'number',
  CountOfRecords = 'countOfRecords',
  TreeOfStrings = 'treeOfStrings',
}

export interface ViewUsageProps {
  usage: (
    | ObjectClassFieldObjectClasses
    | ObjectClassCountObjectRecords
    | TreeOfStrings
  )[];
  type?: ViewUsageDisplayType;
}

export interface ViewUsageConfig {
  usage:
    | ObjectClassFieldObjectClasses[]
    | ObjectClassCountObjectRecords[]
    | TreeOfStrings[];
  text: string;
  type?: ViewUsageDisplayType;
}
