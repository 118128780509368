import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { TaskStatus } from 'utils/Enums/TaskStatus';

const getTagColorsByStatus = (status: TaskStatus, theme: ThemeState) => {
  switch (status) {
    case TaskStatus.Completed:
      return {
        color: theme['chips-color'],
        backgroundColor: theme['chips-success-background-opacity'],
        borderColor: theme['chips-success-background-opacity'],
      };
    case TaskStatus.Overdue:
      return {
        color: theme['error-color'],
        backgroundColor: theme['error-chip-bg-light'],
        borderColor: theme['error-chip-bg-light'],
      };
    default:
      return {
        color: theme['chips-warning-color'],
        backgroundColor: theme['chips-warning-bg-color'],
        borderColor: theme['chips-warning-bg-color'],
      };
  }
};

type UseStatusTagStylesProps = {
  status: TaskStatus;
  size?: number;
};

const useStatusTagStyles = createUseStyles((theme: ThemeState) => {
  return {
    statusTag: ({ status, size }: UseStatusTagStylesProps) => ({
      ...getTagColorsByStatus(status, theme),
      fontSize: size || 14,
      lineHeight: '19px',
      fontWeight: 600,
      padding: '3px 6px',
      marginLeft: 'auto',
      marginRight: 'auto',
    }),
  };
});

export default useStatusTagStyles;
