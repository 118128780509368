import noop from 'lodash/noop';
import { createContext, useContext } from 'react';
import { TableRowContextType } from './types';

const TableRowContext = createContext<TableRowContextType>({
  setSelectedRow: noop,
  getSelectedRow: () => undefined,
  currentColumns: {},
  density: undefined,
  customCells: [],
  onActionCellClick: undefined,
});

export const TableRowContextProvider = TableRowContext.Provider;
export const useTableRowContext = () => useContext(TableRowContext);
