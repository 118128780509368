import {
  CommonTableState,
  SetTableAction,
  ActionObject,
  ColumnsMetadata,
} from 'utils/types/api/table.types';

type PickedActionObject<T> = Pick<
  ActionObject<T>,
  'list' | 'total' | 'filtered'
>;

export const setTableContent = <T extends { uuid: string }, U, K = {}>(
  state: CommonTableState<T, K>,
  action: SetTableAction<PickedActionObject<T>, U>
) => {
  const { total, filtered, list } = action;

  return {
    ...state,
    fetching: false,
    total,
    filtered,
    byId: list.reduce((byId: { [uuid: string]: T }, item: T) => {
      byId[item.uuid] = item;

      return byId;
    }, state.byId),
    allIds: list.map((item: T) => item.uuid),
  };
};

export const appendTableContent = <T extends { uuid: string }, U, K = {}>(
  state: CommonTableState<T, K>,
  action: SetTableAction<PickedActionObject<T>, U>,
  onTopOfTable?: boolean
) => {
  const { list } = action;

  return {
    ...setTableContent(state, action),
    allIds: onTopOfTable
      ? [...list.map(item => item.uuid), ...state.allIds]
      : [...state.allIds, ...list.map(item => item.uuid)],
  };
};

export const setTableLoading = <T, U, K = {}>(
  state: CommonTableState<T> & K,
  action: SetTableAction<Pick<ActionObject<T>, 'fetching'>, U>
) => ({
  ...state,
  fetching: action.fetching,
});

export const resetTable = <T, K = {}>(
  state: CommonTableState<T, K>,
  initialState: CommonTableState<T, K>
) => ({
  ...initialState,
  restrictions: state.restrictions,
  columns: state.columns,
  fetching: state.fetching,
  selectedRow: state?.selectedRow,
  details: state.details,
});

export const resetTableColumns = <T, K = {}>(
  state: CommonTableState<T, K>,
  initialState: CommonTableState<T, K>
) => ({
  ...state,
  columns: initialState.columns,
});

export const setTableColumns = <T, U, K = {}>(
  state: CommonTableState<T, K>,
  action: SetTableAction<Pick<ActionObject<T>, 'payload'>, U>
) => {
  const { payload = [] } = action;

  return {
    ...state,
    columns: payload.reduce((byName: MappedObject<ColumnsMetadata>, item) => {
      byName[item.alias] = item;

      return byName;
    }, {}),
  };
};

export const setSelectedRow = <T, U, K = {}>(
  state: CommonTableState<T, K>,
  action: SetTableAction<Pick<ActionObject<T>, 'selectedRow'>, U>
) => {
  const { selectedRow } = action;

  return {
    ...state,
    selectedRow,
  };
};

export const removeTableContent = <T extends { uuid: string }, U, K = {}>(
  state: CommonTableState<T, K>,
  action: SetTableAction<PickedActionObject<T>, U>
) => {
  const { list, total, filtered } = action;

  const idsToRemove = list.map(item => item.uuid);

  return {
    ...state,
    fetching: false,
    total,
    filtered,
    byId: Object.fromEntries(
      Object.entries(state.byId).filter(([key]) => !idsToRemove.includes(key))
    ),
    allIds: [...state.allIds.filter(item => !idsToRemove.includes(item))],
  };
};
