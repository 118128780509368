import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  getUserDateFormat,
  languageSelector,
} from 'store/selectors/preferencesSelectors';
import moment, { LongDateFormatKey } from 'moment';
import { range, startCase } from 'lodash';
import { useCallback } from 'react';

const useDateFormat = () => {
  const intl = useIntl();
  const language = useSelector(languageSelector);
  const dateFormat = useSelector(getUserDateFormat);

  const getMomentDateFormat = useCallback(
    (format: LongDateFormatKey) =>
      moment.localeData(language).longDateFormat(format),
    [language]
  );

  const getTimeFormat = useCallback(() => getMomentDateFormat('LT'), [
    getMomentDateFormat,
  ]);

  const getTimeWithSecondsFormat = useCallback(
    () => getMomentDateFormat('LTS'),
    [getMomentDateFormat]
  );

  const formatMonth = (date: Date) =>
    intl
      .formatDateToParts(date, {
        dateStyle: 'long',
      })
      .find(({ type }) => type === 'month')?.value;

  const getMonths = () =>
    range(1, 13).map(month => ({
      id: month, //  months from 1-12
      label: startCase(
        formatMonth(new Date(Date.UTC(moment().year(), month - 1)))
      ),
    }));

  return {
    getMonths,
    dateFormat,
    getTimeFormat,
    getTimeWithSecondsFormat,
  };
};

export default useDateFormat;
