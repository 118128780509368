export enum FileType {
  Document = 'document',
  Template = 'template',
}

export type FileListElementProps = {
  documentTemplateID?: string;
  label?: string;
  downloadedFileName?: string;
  testId?: string;
} & (
  | { type?: FileType.Document; url: string }
  | { type?: FileType.Template; url?: string }
);

export interface UseClickLinkProps {
  label?: string;
  downloadedFileName?: string;
  path: string;
  type?: FileType;
}
