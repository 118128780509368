import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useFormStyles = createUseStyles((theme: ThemeState) => {
  return {
    formItem: {
      '& .ant-form-item-explain-error': {
        fontSize: 12,
        color: theme['error-color'],
        marginTop: 3,
      },
      marginBottom: 15,
      '@global  [data-small-column] [data-small-column] > div:last-child > .ant-form-item': {
        marginBottom: '0px !important',
      },
      'form.rjsf &': {
        marginBottom: 0,
      },
      '&.ant-form-item-has-error .ant-input-affix-wrapper': {
        borderColor: theme['error-color'],
        '&:focus, &:focus-within': {
          boxShadow: theme['input-focus-shadow-error'],
          borderColor: `${theme['error-color']} !important`,
        },
      },
      '&.ant-form-item-has-error .ant-input, &.ant-form-item-has-error .ant-input-number, &.ant-form-item-has-error .ant-picker, &.ant-form-item-has-error .ant-select-focused .ant-select-selector, &.ant-form-item-has-error .ant-select-focused .ant-select-selector': {
        borderColor: `${theme['error-color']} !important`,
        boxShadow: `${theme['input-focus-shadow-error']} !important`,
        '&:focus, &:focus-within': {
          boxShadow: `${theme['input-focus-shadow-error']} !important`,
          borderColor: `${theme['error-color']} !important`,
        },
      },
    },
  };
});

export default useFormStyles;
