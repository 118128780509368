import { IntlShape } from 'react-intl';

export const commonPredicates = (intl: IntlShape) => ({
  containsall: {
    label: intl.formatMessage({
      id: 'predicates.containsall',
      defaultMessage: 'Contains all of',
    }),
    value: 'containsall',
    query: '__containsall={value}',
    args: ['value'],
    selectMultiple: true,
  },
  containssome: {
    label: intl.formatMessage({
      id: 'predicates.containssome',
      defaultMessage: 'Contains some of',
    }),
    value: 'containssome',
    query: '__containssome={value}',
    args: ['value'],
    selectMultiple: true,
  },
  not_containssome: {
    label: intl.formatMessage({
      id: 'predicates.doesNotContainAnyOf',
      defaultMessage: 'does not contains any of',
    }),
    value: 'not_containssome',
    query: '__containssome!={value}',
    args: ['value'],
    selectMultiple: true,
  },
  isnull: {
    label: intl.formatMessage({
      id: 'predicates.unset',
      defaultMessage: 'is unset',
    }),
    value: 'isnull',
    query: '__isnull=true',
    args: [],
  },
  not_isnull: {
    label: intl.formatMessage({
      id: 'predicates.isAny',
      defaultMessage: 'is any',
    }),
    value: 'not_isnull',
    query: '__isnull=false',
    args: [],
  },
  isempty: {
    label: intl.formatMessage({
      id: 'predicates.unset',
      defaultMessage: 'is unset',
    }),
    value: 'isempty',
    query: '__isempty=true',
    args: [],
  },
  not_isempty: {
    label: intl.formatMessage({
      id: 'predicates.isAny',
      defaultMessage: 'is any',
    }),
    value: 'not_isempty',
    query: '__isempty=false',
    args: [],
  },
});
