import { LICENSE_DATA_STORAGE_KEY } from '../getPspdfkitLicense.consts';

export const saveLicenseData = (licenseKey: string): void => {
  localStorage.setItem(
    LICENSE_DATA_STORAGE_KEY,
    JSON.stringify({
      licenseKey,
      storedDateEpoch: Date.now(),
    })
  );
};
