import { PasswordRulesFields, PasswordRulesOptions } from './types';

export const buildPasswordRulesObject = (
  options: PasswordRulesOptions | undefined
): Partial<PasswordRulesFields> => {
  if (options) {
    return Object.entries(options).reduce((acc, [key, val]) => {
      if (!val) return { ...acc };

      return { ...acc, [key]: false };
    }, {});
  }

  return {};
};

export const isDuplicatesCountLessOrSameThanMax = (
  value: string,
  maximumDuplicates: number
) => {
  const { max } = value.split('').reduce(
    (prev, letter) => {
      if (prev.letter === letter) {
        prev.count++;

        if (prev.count > prev.max) prev.max = prev.count;
      } else {
        prev = { letter, count: 1, max: prev.max };
      }

      return prev;
    },
    { letter: '', count: 0, max: 0 }
  );

  return max <= maximumDuplicates;
};
