export const SET_AUTHENTICATION_OBJECTS = 'SET_AUTHENTICATION_OBJECTS';
export const APPEND_AUTHENTICATION_OBJECTS = 'APPEND_AUTHENTICATION_OBJECTS';
export const SET_AUTHENTICATION_OBJECTS_FETCHING =
  'SET_AUTHENTICATION_OBJECTS_FETCHING';
export const SET_TOTAL_AUTHENTICATION_OBJECTS =
  'SET_TOTAL_AUTHENTICATION_OBJECTS';
export const RESET_AUTHENTICATION_OBJECTS = 'RESET_AUTHENTICATION_OBJECTS';
export const SET_AUTHENTICATION_OBJECTS_COLUMNS =
  'SET_AUTHENTICATION_OBJECTS_COLUMNS';
export const RESET_AUTHENTICATION_OBJECTS_COLUMNS =
  'RESET_AUTHENTICATION_OBJECTS_COLUMNS';
export const SET_AUTHENTICATION_OBJECTS_SELECTED_ROW =
  'SET_AUTHENTICATION_OBJECTS_SELECTED_ROW';
export const SET_AUTHENTICATION_OBJECTS_RESTRICTIONS =
  'SET_AUTHENTICATION_OBJECTS_RESTRICTIONS';
