import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { convertHexToRGBA } from 'utils/functions/convertHextoRGBA';

export const buttonStyles = {
  borderRadius: 3,
  fontWeight: 'bold',
  fontSize: 14,
  padding: '0px 10px',
  display: 'flex',
  alignItems: 'center',
  '&:not(.ant-btn-icon-only)': {
    '& svg': {
      marginRight: 10,
    },
  },
};

const useButtonStyles = createUseStyles((theme: ThemeState) => {
  return {
    button: buttonStyles,
    buttonPrimary: {
      background: theme['primary-color'],
      borderColor: theme['primary-color'],
      textShadow: 'none',
      '&:hover': {
        background: theme['primary-color'],
        borderColor: theme['primary-color'],
        boxShadow: '0px 0px 20px -3px rgba(2,142,223,0.35)',
      },
      '&:active, &:focus': {
        background: theme['primary-color-dark'],
      },
      '&:disabled, &:disabled:hover, &:disabled:focus': {
        background: theme['primary-color-opacity'],
        border: `1px solid ${theme['primary-color-opacity']}`,
        backgroundClip: 'padding-box',
        color: 'white',
      },
    },
    buttonOutlined: {
      background: 'white',
      borderColor: theme['primary-color'],
      borderWidth: 1,
      color: theme['primary-color'],
      '&:hover': {
        background: theme['primary-color'],
        borderColor: theme['primary-color'],
        boxShadow: '0px 0px 20px -3px rgba(2,142,223,0.35)',
        color: 'white',
      },
      '&:active, &:focus': {
        background: theme['primary-color-dark'],
        borderColor: theme['primary-color-dark'],
        color: 'white',
      },
      '&:disabled, &:disabled:hover, &:disabled:focus': {
        border: `1px solid ${theme['primary-color-opacity']}`,
        backgroundClip: 'padding-box',
        color: theme['primary-color-opacity'],
        backgroundColor: 'white',
      },
    },
    buttonSecondaryOutlined: {
      background: 'white',
      borderColor: theme['placeholder-border-color'],
      color: theme['secondary-color'],
      borderWidth: 1,
      '&:hover, &:active, &:focus': {
        borderColor: theme['primary-color'],
        color: theme['primary-color'],
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
      '&:disabled, &:disabled:hover, &:disabled:focus': {
        background: 'white',
        borderColor: theme['placeholder-border-color'],
        color: theme['secondary-color'],
        borderWidth: 1,
        opacity: 0.6,
      },
    },
    buttonSecondary: {
      color: `${theme['secondary-color']} !important`,
      borderColor: `${theme['placeholder-border-color']} !important`,
      '&:hover, &:focus': {
        color: theme['primary-color'],
        borderColor: theme['primary-color'],
      },
      '&:active': {
        color: theme['primary-color-dark'],
        borderColor: theme['primary-color-dark'],
      },
      '&:disabled, &:hover:disabled, &:disabled:focus': {
        backgroundColor: theme['disabled-input-gray'],
        borderColor: theme['placeholder-border-color'],
        color: `${convertHexToRGBA(theme['secondary-color'], 60)} !important`,
      },
    },
    buttonTertiary: {
      background: theme['tertiary-color'],
      borderColor: theme['tertiary-color'],
      textShadow: 'none',
      color: 'white',
      fontWeight: 700,
      '&:hover': {
        background: theme['tertiary-color'],
        borderColor: theme['tertiary-color'],
        boxShadow: '0px 0px 20px -3px rgba(22, 154, 22,0.35)',
        color: 'white',
      },
      '&:active, &:focus': {
        background: theme['tertiary-color'],
        color: 'white',
      },
      '&:disabled, &:disabled:hover, &:disabled:focus': {
        background: theme['tertiary-disabled-color'],
        border: `1px solid ${theme['tertiary-disabled-color']}`,
        backgroundClip: 'padding-box',
        color: 'white',
      },
      '& .ant-btn-loading-icon': {
        display: 'flex',
      },
    },
    buttonWarning: {
      background: theme['warning-color'],
      borderColor: theme['warning-color'],
      '&:hover, &:focus': {
        background: theme['warning-color-light'],
        borderColor: theme['warning-color-light'],
      },
      '&:active': {
        background: theme['warning-color-dark'],
        borderColor: theme['warning-color-dark'],
      },
    },
    buttonDanger: {
      background: theme['error-color'],
      borderColor: theme['error-color'],
      '&:hover, &:focus': {
        background: theme['error-color'],
        borderColor: theme['error-color'],
        boxShadow: '0px 4px 10px rgba(238, 41, 121, 0.33)',
      },
      '&:active': {
        background: theme['error-color-dark-pink'],
        borderColor: theme['error-color-dark-pink'],
      },
      '&:disabled, &:hover:disabled, &:disabled:focus': {
        background: convertHexToRGBA(theme['error-color'], 60),
        borderColor: 'transparent',
        color: 'white',
      },
    },
    buttonLink: {
      color: theme['primary-color'],
      textDecoration: 'underline',
      fontWeight: 'bold',
      padding: 0,
      '&:hover, &:focus': {
        color: theme['primary-color-dark'],
        textDecoration: 'underline!important',
      },
      '&:active': {
        color: theme['primary-color-dark'],
        textDecoration: 'underline!important',
      },
      '&.no-underline': {
        textDecoration: 'none',
      },
    },
    buttonTransparent: {
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
      padding: 0,
      '&:focus': {
        outline: 0,
      },
      '&:disabled, &:hover:disabled, &:disabled:focus': {
        color: theme['disabled-color'],
        cursor: 'default',
      },
    },
    buttonDefault: {
      color: theme['secondary-color'],
      borderColor: theme['border-color-base'],
      '&:hover, &:focus': {
        color: theme['link-color'],
        borderColor: theme['link-color'],
      },
      '&:disabled, &:hover:disabled, &:disabled:focus': {
        backgroundColor: theme['disabled-input-gray'],
        borderColor: theme['placeholder-border-color'],
        color: convertHexToRGBA(theme['secondary-color'], 60),
      },
    },
  };
});

export default useButtonStyles;
