import { RootState } from 'store/reducers';
import { AssigneesType } from 'store/reducers/types/objectClassPermissions';

export const getObjectClassPermissionsIsRemovingHimself = (state: RootState) =>
  state.objectClassPermissions.isRemovingHimself;

export const getObjectClassPermissionsClassOwnersCount = (state: RootState) =>
  state.objectClassPermissions.classPermissions.owners.totalCount;

export const getObjectClassPermissionsClassOwnersAvatars = (state: RootState) =>
  state.objectClassPermissions.classPermissions.owners.avatars;

export const getObjectClassPermissionSetAssigneesData = (
  permissionSetId: string
) => (state: RootState) =>
  state.objectClassPermissions.classPermissions.assignees[permissionSetId] ||
  ({} as AssigneesType);

export const getObjectClassPermissionSetAssigneesAvatars = (
  permissionSetId: string
) => (state: RootState) =>
  state.objectClassPermissions.classPermissions.assignees[permissionSetId]
    ?.avatars || [];

export const getObjectClassPermissionSetAssigneesFetching = (
  permissionSetId?: string
) => (state: RootState) =>
  !permissionSetId
    ? false
    : state.objectClassPermissions.classPermissions.assignees[permissionSetId]
        ?.fetching;

export const getObjectClassPermissionsClassOwnersItemsLimit = (
  state: RootState
) =>
  state.objectClassPermissions.classPermissions.owners.options.restrictions
    .limit_items;

export const getObjectClassPermissionSetAssigneesItemsLimit = (
  permissionSetId: string
) => (state: RootState) =>
  state.objectClassPermissions.classPermissions.assignees[permissionSetId]
    ?.options?.restrictions?.limit_items;

export const getObjectClassPermissionSetAssigneesCount = (
  permissionSetId: string
) => (state: RootState) =>
  state.objectClassPermissions.classPermissions.assignees[permissionSetId]
    ?.totalCount;

export const getObjectClassPermissionsClassOwnersAutocompleteUrl = (
  state: RootState
) =>
  state.objectClassPermissions.classPermissions.owners.options.batch
    .autocomplete;

export const getObjectClassSelectedPermissionSet = (state: RootState) =>
  state.objectClassPermissions.selectedPermissionSet;

export const getObjectClassOwnersFetching = (state: RootState) =>
  state.objectClassPermissions.classPermissions.owners.fetching;
