import { createUseStyles } from 'react-jss';

const useCreateRecordFormStyles = createUseStyles({
  wrapper: {
    paddingTop: 15,
    '&, & > div, & > div #formBody': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    '& #formBody': {
      maxHeight: 'initial',
      '& .ant-select': {
        '& *': {
          fontSize: 14,
        },
        '& .ant-select-arrow svg': {
          fontSize: 10,
        },
        '& .ant-select-selector': {
          height: 28,
        },
      },
      '& .ant-input-number-input': {
        height: 26,
      },
    },
  },
  formWrapper: {
    '& #formBody': {
      padding: '0px 5px',
    },
  },
  loaderWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default useCreateRecordFormStyles;
