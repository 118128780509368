import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useLimitExceededNotificationStyles = createUseStyles(
  (theme: ThemeState) => {
    return {
      wrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
      alert: {
        display: 'flex',
        alignItems: 'center',
        padding: '9px 7px 7px 9px',
        backgroundColor: theme['error-bg-light'],
        marginBottom: 10,
        width: '100%',
        '& svg.ant-alert-icon': {
          position: 'initial',
          marginRight: 7,
          color: theme['error-color'],
        },
        '& span.ant-alert-message': {
          color: theme['error-color'],
          fontSize: 11,
          fontWeight: 400,
          lineHeight: 1.36,
        },
      },
    };
  }
);
