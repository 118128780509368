import { useState } from 'react';
import { useField } from 'formik';

export const useObfuscateInput = (
  name: string,
  isObfuscate: boolean | undefined,
  obfuscateValueLength: number
) => {
  const [isObfuscateFieldVisible, setObfuscateFieldVisible] = useState(true);
  const [, { value }, { setTouched, setError, setValue }] = useField(name);
  const [isDirty, setIsDirty] = useState(false);
  const obfuscatedValue = ' '.repeat(obfuscateValueLength);

  const handleFocus = () => {
    setObfuscateFieldVisible(false);
    setError('');
    setValue('');
  };

  const handleBlur = () => {
    if (isObfuscate) {
      if (!isDirty && value === '') {
        setObfuscateFieldVisible(true);
        setTouched(false);
        setValue(obfuscatedValue);
      } else {
        setIsDirty(true);
        setObfuscateFieldVisible(false);
      }
    }
  };

  return {
    obfuscatedValue,
    isObfuscateFieldVisible,
    handleBlur,
    handleFocus,
  };
};
