import { CheckboxWithAdditionalData } from 'components/lib/Checkbox';
import React from 'react';
import { COLUMN_TESTID } from 'utils/testIds';
import { ClassFieldCheckboxProps } from './types';
import useClassFieldCheckboxStyles from './styles';

const ClassFieldCheckbox = ({
  id,
  field,
  onSelectClassFields,
  isColumnLimitReached,
  selectedClassFields,
  label,
  className,
}: ClassFieldCheckboxProps) => {
  const styles = useClassFieldCheckboxStyles({});

  return (
    <CheckboxWithAdditionalData
      data-testid={`${COLUMN_TESTID}${id}`}
      key={id}
      additionalData={field}
      onChange={onSelectClassFields as VoidFunction}
      className={styles.checkbox}
      value={id.toString()}
      disabled={
        isColumnLimitReached &&
        !selectedClassFields?.find(a => a === id.toString())
      }
    >
      <div className={className}>{label}</div>
    </CheckboxWithAdditionalData>
  );
};

export default React.memo(
  ClassFieldCheckbox,
  (prev, next) =>
    prev.id === next.id &&
    prev.isColumnLimitReached === next.isColumnLimitReached
);
