import PSPDFKit, { Configuration, Instance } from 'pspdfkit';

/**
 * Loads a file buffer into the PSPDF instance.
 * @param fileBuffer ArrayBuffer of the file to display.
 * @param container host container for the PSPDF instance.
 * @param locale locale for PSPDF for internationalization.
 * @param licenseKey license key for PSPDFKit (retrieve it with (@link getPspdfkitLicense)).
 * @returns Response with PSPDF instance as data or error response.
 */
export const loadPspdfFile = async (
  fileBuffer: ArrayBuffer,
  container: HTMLDivElement,
  locale: string,
  licenseKey: string | undefined
): Promise<Instance> => {
  const baseUrl = `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`;
  const libUrl = new URL('/pspdfkit/', baseUrl);
  const styleUrl = new URL('pspdfkit-style.css', baseUrl);

  const configuration: Configuration = {
    container,
    document: fileBuffer,
    locale,
    // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
    baseUrl: libUrl.href,
    styleSheets: [styleUrl.href],
    licenseKey,
  };

  return await PSPDFKit.load(configuration);
};
