import { Dispatch, SetStateAction } from 'react';
import { Location } from 'history';

export interface CustomPromptProps {
  when: boolean;
  onCancel: (location?: Location) => void;
  onConfirm?: () => Promise<unknown>;
  onClose?: () => void;
  confirmDisabled?: boolean;
  setIsNavigateAway?: Dispatch<SetStateAction<boolean>>;
  type?: CustomPromptType;
}

export enum CustomPromptType {
  CANCEL = 'CANCEL',
  SAVE_CHANGES = 'SAVE_CHANGES',
}
