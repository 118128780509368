import React from 'react';
import TaskStatusTag from 'components/TaskStatusTag';
import TooltipString from 'components/TooltipString';
import { FormattedMessage } from 'react-intl';
import useTaskDisplayStyles from './styles';
import { TaskHeaderProps } from './types';

const TaskHeader = ({
  recordId,
  status,
  taskName,
  taskId,
}: TaskHeaderProps) => {
  const classes = useTaskDisplayStyles({});

  return (
    <div>
      <div className={classes.recordId}>
        <FormattedMessage id='misc.record' defaultMessage='Record' /> {recordId}
      </div>
      <div className={classes.taskInfo}>
        <div className={classes.taskTitle}>{taskName}</div>
        <div className={classes.taskIdWrapper}>
          <FormattedMessage id='task.taskId' defaultMessage='Task ID' />:{' '}
          <span className={classes.taskIdValueWrapper}>
            <b>
              <TooltipString noStyle text={taskId.toString()} />
            </b>
          </span>
        </div>
        <div className={classes.statusWrapper}>
          <TaskStatusTag status={status} />
        </div>
      </div>
    </div>
  );
};

export default TaskHeader;
