import React, { useMemo } from 'react';

import { Drawer } from '../Drawer';
import { List, ListItem } from '../List';
import { ButtonOutlined } from '../Button';
import { useFlexLayoutContext } from './FlexLayoutContext';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import useFlexLayoutStyles from './styles';
import { getLayoutWindowsToDisplay } from './utils';
import { ADD_NEW_TAB_BUTTON_TESTID } from 'utils/testIds';

const ComponentsDrawer = () => {
  const {
    availableComponents,
    flexLayoutMethods: { onAddTab, onDrawerClose, selectedTabSetId },
  } = useFlexLayoutContext();

  const classes = useFlexLayoutStyles({});

  const componentsToDisplay = useMemo(() => getLayoutWindowsToDisplay(), []);

  const components = useMemo(
    () =>
      Object.entries(availableComponents).filter(([componentKey]) =>
        componentsToDisplay.includes(componentKey as FlexLayoutWindows)
      ),
    [availableComponents, componentsToDisplay]
  );

  return (
    <Drawer
      title='Select component'
      placement='right'
      visible={!!selectedTabSetId}
      onClose={onDrawerClose}
    >
      <List
        dataSource={components}
        renderItem={([componentKey, { name }]) => (
          <ListItem>
            <ButtonOutlined
              className={classes.drawerButton}
              onClick={() => onAddTab(componentKey)}
              data-testid={`${ADD_NEW_TAB_BUTTON_TESTID}${componentKey}`}
            >
              {name}
            </ButtonOutlined>
          </ListItem>
        )}
      />
    </Drawer>
  );
};

export default ComponentsDrawer;
