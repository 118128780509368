export enum UsersMode {
  Manage = 'manage',
  Adding = 'adding',
}

export interface UserType {
  first_name: string;
  last_name: string;
  id: number;
  company_name: string;
  username: string;
}
