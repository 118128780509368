import { createUseStyles } from 'react-jss';

type UseEventFiltersStylesProps = {
  offset?: number;
};

const useEventFiltersStyles = createUseStyles({
  eventFiltersWrapper: {
    display: 'grid',
    gap: '14px',
    gridTemplateColumns: ({ offset = 0 }: UseEventFiltersStylesProps = {}) =>
      offset < 310 ? '1fr' : '1fr 1fr',
    marginTop: 14,
  },
  eventUsersFilterWrapper: {
    position: 'relative',
  },
});

export default useEventFiltersStyles;
