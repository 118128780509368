import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ExclamationMark, LogoIcon, SuccessMark } from 'components/Icon';
import { FallbackLogoOptions } from 'components/Icon/types';
import { TASK_1TC_SUMMARY } from 'utils/testIds';
import { useTaskSummaryStyles } from './styles';
import { TaskSummaryProps } from './types';

const TaskSummary = ({ name, isSuccess }: TaskSummaryProps) => {
  const classes = useTaskSummaryStyles({});
  return (
    <div className={classes.taskSummaryLayout}>
      <div className={classes.taskSummaryContainer}>
        <div className={classes.taskSummaryData}>
          {isSuccess ? (
            <SuccessMark size={86} />
          ) : (
            <ExclamationMark size={86} />
          )}
          <div
            className={classes.taskSummaryDescription}
            data-testid={TASK_1TC_SUMMARY}
          >
            <LogoIcon
              className={classes.taskSummaryLogo}
              fallbackLogo={FallbackLogoOptions.NORMAL}
              width={150}
              height={54}
            />
            <h1>
              {isSuccess ? (
                <FormattedMessage
                  id='tasks.taskComplete'
                  defaultMessage='Thank you. {name} is now complete.'
                  values={{ name }}
                />
              ) : (
                <FormattedMessage
                  id='tasks.notAvailable'
                  defaultMessage='Task no longer available'
                />
              )}
            </h1>
            {isSuccess ? (
              <p>
                <FormattedMessage
                  id='misc.closePage'
                  defaultMessage='You can close this page.'
                />
              </p>
            ) : (
              <>
                <p>
                  <FormattedMessage
                    id='tasks.deletedMessage'
                    defaultMessage='The task has either been deleted, reassigned, or completed by someone else.'
                  />
                </p>

                <p>
                  <FormattedMessage
                    id='misc.contactAdministrator'
                    defaultMessage='If you think this is an error, contact your administrator.'
                  />
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskSummary;
