import React from 'react';
import usePredicates from '../usePredicates';
import PredicateInput from './PredicateInput';
import { Space } from 'components/lib/Space';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterPredicateValue } from 'store/actions/filtersActions';
import { getCurrentTable } from 'store/selectors/filtersSelectors';
import { PredicatesProps, PredicatesTestIdType } from './types';
import { TPredicateValue } from 'utils/types/predicates.types';

const Predicate = ({
  value,
  type,
  filterKey,
  selectOptions,
  autocompleteUrl,
  predicateSet,
}: PredicatesProps) => {
  const PREDICATES = usePredicates();
  const dispatch = useDispatch();
  const currentTableName = useSelector(getCurrentTable);

  const onChangePredicateValue = (
    predicateValue: TPredicateValue,
    arg: string,
    arg2?: string
  ) => {
    const tableName = currentTableName || '';

    //when we use dateRange input we fill both args at one time
    if (arg2) {
      dispatch(
        setFilterPredicateValue(tableName, filterKey, {
          ...value.predicateValues,
          [arg]: (predicateValue as string)[0],
          [arg2]: (predicateValue as string)[1],
        })
      );

      return;
    }

    dispatch(
      setFilterPredicateValue(tableName, filterKey, {
        ...value.predicateValues,
        [arg]: predicateValue,
      })
    );
  };

  const selectedPredicate = PREDICATES?.[type]?.[value.predicateKey];

  //when we have date type with 2 args we show single dateRange picker
  if (
    selectedPredicate &&
    ['datetime', 'date'].includes(type) &&
    selectedPredicate.args.length === 2
  ) {
    return (
      // extrenal window don't copy class styles if elem not rendered before
      <Space direction='vertical' style={{ width: '100%' }}>
        <PredicateInput
          type={type === 'datetime' ? 'dateTimeRange' : 'dateRange'}
          key={selectedPredicate.args[0]}
          onChange={e =>
            onChangePredicateValue(
              e,
              selectedPredicate.args[0],
              selectedPredicate.args[1]
            )
          }
          value={[
            value.predicateValues[selectedPredicate.args[0]] as string,
            value.predicateValues[selectedPredicate.args[1]] as string,
          ]}
          {...{ selectOptions, filterKey, predicateSet }}
          selectMultiple={selectedPredicate?.selectMultiple}
        />
      </Space>
    );
  }

  return (
    // extrenal window don't copy class styles if elem not rendered before
    <Space direction='vertical' style={{ width: '100%' }}>
      {selectedPredicate && selectedPredicate.args.length > 0 && (
        <>
          {selectedPredicate.args.length > 1 && (
            <label>
              <FormattedMessage id='misc.from' defaultMessage='From' />
            </label>
          )}
          <PredicateInput
            type={type}
            key={selectedPredicate.args[0]}
            onChange={e => onChangePredicateValue(e, selectedPredicate.args[0])}
            value={value.predicateValues[selectedPredicate.args[0]]}
            {...{ selectOptions, filterKey, autocompleteUrl, predicateSet }}
            selectMultiple={selectedPredicate?.selectMultiple}
            testIdType={
              selectedPredicate.args.length > 1
                ? PredicatesTestIdType.From
                : PredicatesTestIdType.Default
            }
          />
        </>
      )}
      {selectedPredicate && selectedPredicate.args.length > 1 && (
        <>
          <label>
            <FormattedMessage id='misc.to' defaultMessage='To' />
          </label>
          <PredicateInput
            type={type}
            key={selectedPredicate.args[1]}
            onChange={e => onChangePredicateValue(e, selectedPredicate.args[1])}
            value={value.predicateValues[selectedPredicate.args[1]]}
            {...{ selectOptions, filterKey, autocompleteUrl, predicateSet }}
            selectMultiple={selectedPredicate?.selectMultiple}
            testIdType={PredicatesTestIdType.To}
          />
        </>
      )}
    </Space>
  );
};

export default Predicate;
