import React from 'react';
import RevertIllustration from '../../img/illustrations/revert-illustration.svg';
import { RevertIcon } from '../Icon';
import ConfirmationModal from '../ConfirmationModal';
import { useIntl } from 'react-intl';
import { ConfirmationModalProps } from '../ConfirmationModal/types';

export const RevertModal: React.FC<ConfirmationModalProps> = ({
  visible,
  onConfirm,
  onCancel,
  onClose,
  subtitle,
}) => {
  const intl = useIntl();

  return (
    <ConfirmationModal
      {...{ visible, onConfirm, onCancel, onClose }}
      image={RevertIllustration}
      title={intl.formatMessage({
        id: 'formBuilder.revertChanges',
        defaultMessage: 'Revert changes?',
      })}
      subtitle={
        subtitle ||
        intl.formatMessage({
          id: 'formBuilder.thisWillRevertForm',
          defaultMessage:
            'This will revert the form to its last saved version.',
        })
      }
      confirmationButtonIcon={<RevertIcon size={16} />}
      confirmationButtonLabel={intl.formatMessage({
        id: 'misc.revert',
        defaultMessage: 'Revert',
      })}
      withCancel
    />
  );
};
