import { RootState } from 'store/reducers';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';

export const getSidebarWidth = (state: RootState) => {
  return state.flexLayout.sidebarWidth;
};

export const getIsSidebarOpened = (state: RootState) => {
  return state.flexLayout.isSidebarOpened;
};

export const getSidebarData = (state: RootState) => {
  return state.flexLayout.data;
};

export const getViewerPanelData = (state: RootState) => {
  return state.flexLayout.data[FlexLayoutWindows.Viewer];
};
