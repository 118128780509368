import { FormBuilderChildObjectClass } from 'components/formBuilder/formBuilder/FormBuilderContext/types';
import { Action } from 'redux';
import {
  CLEAR_CHILD_CLASSES,
  SET_CHILD_CLASSES,
} from 'store/constants/childClasses.consts';

export type SetChildClassesPayload = FormBuilderChildObjectClass[];

export type ClearChildClassesPayload = {
  id: string;
};

export type SetChildClasses = Action<typeof SET_CHILD_CLASSES> & {
  payload: SetChildClassesPayload;
};

export type ClearChildClasses = Action<typeof CLEAR_CHILD_CLASSES> & {
  payload: ClearChildClassesPayload;
};

export type ChildClassesActions = SetChildClasses | ClearChildClasses;

export const setChildClasses = (
  payload: SetChildClassesPayload
): SetChildClasses => ({
  type: SET_CHILD_CLASSES,
  payload,
});

export const clearChildClasses = (
  payload: ClearChildClassesPayload
): ClearChildClasses => ({
  type: CLEAR_CHILD_CLASSES,
  payload,
});
