import React from 'react';
import { TreeOfStringsDisplayProps } from './types';
import useTreeOfStringsDisplayStyles from './styles';
import clsx from 'clsx';
const TreeOfStringsDisplay = ({
  data,
  depth = 0,
}: TreeOfStringsDisplayProps) => {
  const styles = useTreeOfStringsDisplayStyles({ depth });
  return (
    <div>
      <ul className={styles.list}>
        {data?.map(item => {
          return (
            <li
              className={clsx([styles.listItem, styles.listItemStyle])}
              key={item.name}
            >
              <div>
                {item.name ?? ''}
                <TreeOfStringsDisplay
                  data={item.children ?? []}
                  depth={depth + 1}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TreeOfStringsDisplay;
