import React, { Suspense } from 'react';
import SimpleLayout from 'components/SimpleLayout';
import { Route, Switch } from 'react-router-dom';
import { classFormRoutes } from 'utils/routing';
import LoaderOverlay from 'components/LoaderOverlay';

const ClassFormsRouting = () => (
  <SimpleLayout>
    <Suspense fallback={<LoaderOverlay />}>
      <Switch>
        {classFormRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} component={Component} />
        ))}
      </Switch>
    </Suspense>
  </SimpleLayout>
);

export default ClassFormsRouting;
