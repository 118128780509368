import { useDispatch } from 'react-redux';
import { matchPath, useHistory } from 'react-router-dom';
import { setPermissions } from 'store/actions/permissionsActions';
import { apiCall } from 'utils/api';
import { PERMISSIONS } from 'utils/endpoints';
import { buildUrlPermissionDict } from 'utils/permissions';
import routes from 'utils/routingPaths';
import { PermissionsState } from 'utils/types/api/permissions.types';

const usePermissionRedirect = (redirectUrls: string[]) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const redirect = async () => {
    try {
      const { data } = await apiCall.get<PermissionsState>(PERMISSIONS);
      const urlPermissionDict = buildUrlPermissionDict(data);

      // dispatching new permissions will refresh nav elements on side menu
      dispatch(setPermissions(data));

      redirectUrls.some(url => {
        const permissionKey = Object.keys(urlPermissionDict).find(
          key => !!matchPath(url, key)
        );

        // if we don't specify permissions for this url then allow user to enter
        if (!permissionKey) {
          history.push(url);

          return true;
        }

        if (permissionKey && urlPermissionDict[permissionKey]) {
          history.push(url);

          return true;
        }
        history.push(routes.WORKSPACE);

        return false;
      });
    } catch {
      history.push(routes.WORKSPACE);
    }
  };

  return { redirect };
};

export default usePermissionRedirect;
