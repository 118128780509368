import { FormikProps } from 'formik';
import { memo, PropsWithChildren } from 'react';
import { FormMode } from 'utils/Enums/FormMode';
import OrderType from 'utils/Enums/OrderType';
import { DefaultPermissions } from './api/permissions.types';
import { ColumnsMetadata } from './api/table.types';

export type ValueOf<T> = T[keyof T];

export interface SimpleObjectValue {
  id: number;
  name: string;
}

export type FormModes = FormMode.Edit | FormMode.View;

export interface FormWithMode {
  mode: FormModes;
}

export type Form<T> = FormikProps<T>;

export const typedMemo: <T>(
  c: T,
  propsAreEqual?: (
    prevProps: Readonly<PropsWithChildren<T>>,
    nextProps: Readonly<PropsWithChildren<T>>
  ) => boolean
) => T = memo;

export type Order = OrderType;

export type RequestType = 'get' | 'options' | 'delete';

export interface FlatValidators {
  alias?: string;
  required?: boolean;
  min_length?: number;
  max_length?: number;
  min_value?: number;
  max_value?: number;
  max_items?: number;
  max_size?: number;
  allowed_extensions?: string[];
  type?: string;
  autocomplete?: string;
}

export interface FlatValidatorsObject {
  [key: string]: FlatValidators;
}

export interface OptionsDetailsSchema {
  alias: string;
  autocomplete?: string;
  values?: { value: string; text: string; is_unique?: boolean }[];
  type: string;
  required?: string;
}

export interface OptionsDetailsSystemFields {
  alias: string;
  label: string;
  is_required: boolean;
  is_unique: boolean;
  is_identifier: boolean;
}

export interface OptionsResponseDetails {
  schema: OptionsDetailsSchema[];
  system_fields?: OptionsDetailsSystemFields[];
}

export interface OptionsResponse {
  list: {
    columns: ColumnsMetadata[];
  };
  details: OptionsResponseDetails;
  restrictions?: {
    limit_items: number;
    limit_identifiers?: number;
    limit_items_in_object_class?: number;
  };
  batch?: BatchOptions;
}

export interface ClassFormsOptionsResponse
  extends Omit<OptionsResponse, 'restrictions'> {
  restrictions?: {
    limit_items_in_object_class: number;
  };
}

export interface MenuInfo {
  key: React.Key;
  keyPath: React.Key[];
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement>;
}

export interface GetResponse<ResultsType> {
  filtered_count: number;
  limit: number;
  next: string | null;
  offset: number;
  previous: string | null;
  results: ResultsType[];
  total_count: number;
}

export interface MetaData {
  permissions: DefaultPermissions;
}

export interface BatchOptions {
  autocomplete: string;
  required: boolean;
  type: 'set';
}
