import { ExtendedUiSchema } from 'components/FormPreview/customFields/ObjectField/types';
import { ExtendedJsonSchema } from 'components/FormPreview/types';
import { FORM_BUILDER_SUPPORTED_SCHEMA_VERSION } from 'utils/consts';
import { EMPTY_SCHEMA, EMPTY_UI_SCHEMA } from './consts';
import migrations from './migrations';
import {
  MigrateFormDefinitionToSupportedVersionType,
  MigrationError,
} from './types';

const migrateFormDefinitionToSupportedVersion: MigrateFormDefinitionToSupportedVersionType = (
  schema,
  uiSchema
) => {
  if (!schema || !uiSchema) return {};

  const { parsedSchema, parsedUiSchema, parseError } = parseFormDefinition(
    schema,
    uiSchema
  );

  if (parseError || !parsedSchema || !parsedUiSchema)
    return {
      schema: JSON.stringify(EMPTY_SCHEMA),
      uiSchema: JSON.stringify(EMPTY_UI_SCHEMA),
      migrationError: MigrationError.CORRUPTED_SCHEMA,
    };

  const { schemaVersion } = parsedSchema;

  if (schemaVersion === FORM_BUILDER_SUPPORTED_SCHEMA_VERSION)
    return {
      schema: schema,
      uiSchema: uiSchema,
    };

  if (
    schemaVersion === undefined ||
    schemaVersion < 3 ||
    schemaVersion > FORM_BUILDER_SUPPORTED_SCHEMA_VERSION
  )
    return {
      schema: JSON.stringify(EMPTY_SCHEMA),
      uiSchema: JSON.stringify(EMPTY_UI_SCHEMA),
      migrationError: MigrationError.UNSUPPORTED_SCHEMA_VERSION,
    };

  let currentVersion = schemaVersion;
  let migrationObject = { schema: parsedSchema, uiSchema: parsedUiSchema };

  try {
    while (currentVersion < FORM_BUILDER_SUPPORTED_SCHEMA_VERSION) {
      currentVersion++;
      migrationObject = migrations[currentVersion](migrationObject);
    }
  } catch {
    return {
      schema: JSON.stringify(EMPTY_SCHEMA),
      uiSchema: JSON.stringify(EMPTY_UI_SCHEMA),
      migrationError: MigrationError.CORRUPTED_SCHEMA,
    };
  }

  return {
    schema: JSON.stringify(migrationObject.schema),
    uiSchema: JSON.stringify(migrationObject.uiSchema),
  };
};

const parseFormDefinition = (schema: string, uiSchema: string) => {
  try {
    const parsedSchema = JSON.parse(schema) as ExtendedJsonSchema;
    const parsedUiSchema = JSON.parse(uiSchema) as ExtendedUiSchema;

    return { parsedSchema, parsedUiSchema };
  } catch {
    return { parseError: true };
  }
};

export default migrateFormDefinitionToSupportedVersion;
