import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useDocumentTemplateStatusStyles = createUseStyles(
  (theme: ThemeState) => {
    return {
      icons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        gap: '8px',
      },
      grayIcon: {
        display: 'flex',
        color: theme['placeholder-text-color'],
        '&:hover': {
          color: theme['primary-color'],
        },
      },
      greenIcon: {
        display: 'flex',
        color: theme['success-color'],
      },
    };
  }
);
