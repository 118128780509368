export const SET_ALL_FILTERS = 'SET_ALL_FILTERS';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_CURRENT_TABLE = 'SET_CURRENT_TABLE';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const SET_FILTERS_APPLIED_FOR_TABLE = 'SET_FILTERS_APPLIED_FOR_TABLE';
export const SET_FILTER_PREDICATE_VALUE = 'SET_FILTER_PREDICATE_VALUE';
export const SET_FILTER_PREDICATE = 'SET_FILTER_PREDICATE';
export const REMOVE_FILTER_COLUMN = 'REMOVE_FILTER_COLUMN';
export const SET_FILTER_COLUMN = 'SET_FILTER_COLUMN';
export const SET_FILTER_PREDICATE_SET = 'SET_FILTER_PREDICATE_SET';
export const SET_FILTER_SOURCE_ID = 'SET_FILTER_SOURCE_ID';
export const RESET_FILTERS = 'RESET_FILTERS';
