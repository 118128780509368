import { createUseStyles } from 'react-jss';
import { labelStyles } from 'pages/TaskTemplates/components/FormLabel/styles';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseFormBuilderModalStylesProps = {
  isRecordSummary?: boolean;
  withStaticWidth?: boolean;
};

const useFormBuilderModalStyles = createUseStyles((theme: ThemeState) => {
  return {
    previewWrapper: ({
      isRecordSummary,
      withStaticWidth,
    }: UseFormBuilderModalStylesProps = {}) => ({
      borderLeft: isRecordSummary
        ? `1px solid ${theme['border-color-base']}`
        : 0,
      borderRight: isRecordSummary
        ? `1px solid ${theme['border-color-base']}`
        : 0,
      width: withStaticWidth ? 450 : '100%',
      '& .ant-collapse-header': {
        backgroundColor: isRecordSummary ? 'none' : theme['section-header-bg'],
        color: isRecordSummary ? 'initial' : '#fff !important',
      },
      '& .ant-picker-suffix': {
        color: `${theme['primary-color']} !important`,
      },
    }),
    modal: {
      '& .ant-modal-content': {
        borderRadius: 5,
        '& .ant-modal-body': {
          padding: 0,
        },
      },
    },
    formHeader: {
      padding: '0px 15px',
      borderBottom: 'initial',
      marginBottom: 15,
    },
    previewHeaderWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    resetButtonWrapper: {
      marginRight: 7,
    },
    submitHeaderWrapper: {
      display: 'flex',
      justifyContent: 'initial',
      '& > span': {
        marginRight: 'auto',
      },
    },
    submitWrapper: {
      display: 'flex',
    },
    formWrapper: ({
      isRecordSummary,
    }: UseFormBuilderModalStylesProps = {}) => ({
      overflow: 'auto',
      maxHeight: '75vh',
      padding: 0,
      position: 'relative',
      margin: isRecordSummary ? 0 : '0 5px',
      '& fieldset:not(#root) .ant-collapse': {
        backgroundColor: isRecordSummary ? 'none' : theme['section-content-bg'],
      },
      '& .ant-form-item-label > label': labelStyles,
      '& button[type="submit"]': {
        marginLeft: 15,
      },
      '& .btn.btn-info': {
        display: 'none',
      },
      '& .ant-form-item-children-icon': {
        display: 'none',
      },
      '& .rjsf #root > .ant-collapse > .ant-collapse-item': {
        '& > .ant-collapse-header': {
          display: 'none',
        },
        '& > .ant-collapse-content': {
          padding: 0,
          '& > .ant-collapse-content-box > div': {
            paddingBottom: 0,
            '& > div > div': {
              maxWidth: '100%',
            },
          },
        },
      },
    }),
    modalWrapper: {
      overflow: 'hidden',
    },
    title: {
      fontSize: 20,
      fontWeight: 700,
      color: theme['text-color'],
    },
  };
});

export default useFormBuilderModalStyles;
