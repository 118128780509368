import clsx from 'clsx';
import { ArrowSmallUp, ArrowSmallDown } from 'components/Icon';
import React from 'react';
import { SORT_ASC_TESTID, SORT_DESC_TESTID } from 'utils/testIds';
import { useDisabledSorterStyles } from './styles';

export const DisabledSorter = () => {
  const classes = useDisabledSorterStyles({});

  return (
    <div className={classes.sorterWrapper}>
      <ArrowSmallUp
        size={6}
        className={clsx([classes.upperArrow])}
        data-testid={SORT_ASC_TESTID}
      />
      <ArrowSmallDown
        size={6}
        className={clsx({})}
        data-testid={SORT_DESC_TESTID}
      />
    </div>
  );
};
