import useTableWrapperStyles, { useTableStyles } from 'components/Table/styles';
import { useTableContext } from 'contexts/TableContext';
import { useTableRowContext } from 'contexts/TableRowContext';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TABLE_BULK_SELECTION_COUNT_MESSAGE_TESTID } from 'utils/testIds';

const BulkSelectionCountMessage = () => {
  const { bulkSelectionList } = useTableContext();
  const { density } = useTableRowContext();
  const tableClasses = useTableWrapperStyles({});
  const classes = useTableStyles({ density });

  return (
    <div
      className={classes.tableHeadCell}
      data-testid={TABLE_BULK_SELECTION_COUNT_MESSAGE_TESTID}
    >
      <div className={tableClasses.flexAlignCenter}>
        <FormattedMessage
          id={'misc.countRowsSelected'}
          defaultMessage={
            '{count, plural, one {# row selected} other {# rows selected}}'
          }
          values={{ count: bulkSelectionList.length }}
        />
      </div>
    </div>
  );
};

export default BulkSelectionCountMessage;
