import React from 'react';
import ToastType from 'utils/Enums/ToastType';
import useToastStyles from './styles';
import {
  ExclamationMark,
  SuccessMark,
  WarningExclamationMark,
  InfoMark,
} from 'components/Icon';
import { MessageProps } from './types';
import { TOAST_TESTID } from 'utils/testIds';

const Message = ({ text, subtitle, type }: MessageProps) => {
  const classes = useToastStyles({});

  return (
    <div className={classes.messageWrapper} data-testid={TOAST_TESTID[type]}>
      {type === ToastType.Info && <InfoMark size={35} />}
      {type === ToastType.Success && <SuccessMark size={35} />}
      {type === ToastType.Warning && <WarningExclamationMark size={35} />}
      {type === ToastType.Error && <ExclamationMark size={35} />}
      <div className={classes.messageText}>
        <p className={classes.toastTitle}>{text}</p>
        <p className={classes.toastSubtitle}>{subtitle}</p>
      </div>
    </div>
  );
};

export default Message;
