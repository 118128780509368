import { useMemo } from 'react';
import { HeaderGroup } from 'react-table';
import { ACTIONS_COLUMN_WIDTH, DEFAULT_COLUMNS_WIDTHS } from '../consts';
import { getColumnVarName } from '../utils';

export const useColumnStyles = (
  headerGroups: HeaderGroup<object>[],
  additionalWidth: number
) => {
  return useMemo<MappedObject<string>>(() => {
    const columns = headerGroups.map(({ headers }) => headers).flat();

    const columnWidths = Object.fromEntries(
      columns.map(({ id, width }) => [
        getColumnVarName(id),
        `${width ?? DEFAULT_COLUMNS_WIDTHS.minWidth}px`,
      ])
    );

    const totalWidth = `calc(${
      ACTIONS_COLUMN_WIDTH + additionalWidth
    }px + ${columns
      .map(({ id }) => `var(${getColumnVarName(id)})`)
      .join(' + ')})`;

    return { ...columnWidths, '--table-row-width': totalWidth };
  }, [headerGroups, additionalWidth]);
};
