import { useCallback, useRef, useState } from 'react';
import {
  DraggableProvidedDraggableProps,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import { ColumnInstance } from 'react-table';
import { UseDragAndDropParams } from './types';

export const useDragAndDrop = ({
  setColumnOrder,
  visibleColumns,
  editMode,
}: UseDragAndDropParams) => {
  const [isDraggingColumn, setIsDraggingColumn] = useState(false);
  const currentColOrder = useRef<string[]>([]);

  const onColumnDragStart = useCallback(() => {
    currentColOrder.current = visibleColumns.map((o: ColumnInstance) => o.id);
    setIsDraggingColumn(true);
  }, [currentColOrder, visibleColumns]);

  const onDragUpdate = useCallback(
    ({ source, destination, draggableId }) => {
      if (currentColOrder.current) {
        const colOrder = [...currentColOrder.current];
        const { index: sourceIndex } = source;
        const destinationIndex = destination && destination.index;

        if (
          typeof sourceIndex === 'number' &&
          typeof destinationIndex === 'number'
        ) {
          colOrder.splice(sourceIndex, 1);
          colOrder.splice(destinationIndex, 0, draggableId as never);
          setColumnOrder(colOrder);
        }
      }
    },
    [currentColOrder, setColumnOrder]
  );

  const onDragEnd = useCallback(() => {
    setIsDraggingColumn(false);
  }, []);

  const getItemStyle = (
    { isDragging, isDropAnimating }: DraggableStateSnapshot,
    draggableStyle: DraggableProvidedDraggableProps
  ) => ({
    ...draggableStyle.style,
    // some basic styles to make the items look a bit nicer
    display: 'flex',
    alignItems: 'center',
    ...(editMode && {
      justifyContent: 'space-between',
    }),
    ...(!isDragging && {
      transform: 'translate(0,0)',
    }),
    ...(isDragging && {
      boxShadow: '10px 10px 20px -10px rgba(0,0,0,0.75)',
    }),
    ...(isDropAnimating && { transitionDuration: '0.01s' }),
  });

  return {
    onColumnDragStart,
    onDragUpdate,
    onDragEnd,
    getItemStyle,
    isDraggingColumn,
  };
};
