import React from 'react';
import { Tabs as AntTabs } from 'antd';
import { TabsProps, TabPaneProps } from 'antd/lib/tabs';

import useTabsStyles from './styles';
import { TABS_TESTID } from 'utils/testIds';

const { TabPane: AntTabPane } = AntTabs;

export const Tabs = ({ className, ...rest }: TabsProps) => {
  const classes = useTabsStyles({});

  return (
    <AntTabs
      {...rest}
      className={`${classes.tabBar} ${className}`}
      data-testid={TABS_TESTID}
    />
  );
};

export const TabPane = ({ ...rest }: TabPaneProps) => <AntTabPane {...rest} />;
