import { ThemeState } from 'store/reducers/types/theme.types';

export const headerWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  marginBottom: 7,
};

export const panelWrapper = {
  padding: 15,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
};

export const getResultsInfo = (theme: ThemeState) => ({
  fontSize: 12,
  lineHeight: 1.3,
  color: theme['heading-color'],
  opacity: 0.6,
  marginBottom: 16,
});

export const getIdentifier = (theme: ThemeState) => ({
  fontSize: 18,
  fontWeight: 'bold',
  lineHeight: '25px',
  color: theme['heading-color'],
  overflowWrap: 'break-word',
  wordBreak: 'break-all',
  display: 'block',
});

export const rightActionButton = {
  height: 35,
};

export const getPanelTitle = (theme: ThemeState) => ({
  fontWeight: 700,
  fontSize: 16,
  lineHeight: 1.37,
  color: theme['heading-color'],
  marginBottom: 25,
});

export const buttonsWrapper = {
  '& button:first-child': {
    marginRight: 10,
  },
  display: 'flex',
  justifyContent: 'flex-end',
};
