import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import { useViewMenuContext } from 'components/PageLayout/context/ViewMenuContext';
import { SelectedItem } from 'contexts/types';
import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  getActivePanels,
  getOpenedPanels,
} from 'store/selectors/preferencesSelectors';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';

const useResourcePanels = (
  selectedItem?: SelectedItem,
  additionalData?: SelectedItem
) => {
  const { addPanelData } = useViewMenuContext();
  const {
    model,
    flexLayoutMethods: { onAddTab },
    globalMethods: { focusComponent },
    isComponentOpen,
    closePanelIfOpen,
  } = useFlexLayoutContext();
  const openedPanels = useSelector(getOpenedPanels) || [];
  const activePanels = useSelector(getActivePanels);

  const togglePanel = useCallback(
    (key: FlexLayoutWindows, isClosed: boolean, hasPermission: boolean) => {
      if (isClosed) {
        addPanelData(key);
        closePanelIfOpen(key);
      }
      if (!isComponentOpen(key)) {
        const panel = openedPanels.find(item => item[key] !== undefined);
        const isOpened = panel === undefined ? true : panel[key];

        addPanelData(key, {
          disabled: !hasPermission,
        });

        if (!hasPermission) {
          closePanelIfOpen(key);
        } else if (isOpened) {
          onAddTab(key);
        }
      } else if (!hasPermission) {
        closePanelIfOpen(key);
      }
    },
    [isComponentOpen, addPanelData, openedPanels, onAddTab, closePanelIfOpen]
  );
  const { record: { isAccessedFromTask = false } = {} } = selectedItem ?? {};

  useEffect(() => {
    // this order helps us to set the initial order of the tabs: Summary, Tasks, RecordAccess
    togglePanel(
      FlexLayoutWindows.RecordAccess,
      !selectedItem?.record?.recordId,
      !!selectedItem?.record?.permissions?.view_owners
    );
    togglePanel(
      FlexLayoutWindows.ActivityLog,
      !selectedItem?.record?.recordId,
      !!selectedItem?.record?.permissions?.view
    );
    togglePanel(
      FlexLayoutWindows.Documents,
      !selectedItem?.record?.recordId,
      !!selectedItem?.record?.permissions?.view
    );

    togglePanel(
      FlexLayoutWindows.Tasks,
      !selectedItem?.record?.recordId,
      !!selectedItem?.record?.permissions?.view && !isAccessedFromTask
    );
    const isSummaryOpened = !!openedPanels?.find(
      item => item[FlexLayoutWindows.Summary]
    );
    togglePanel(
      FlexLayoutWindows.Summary,
      !(selectedItem?.record?.recordId && isSummaryOpened),
      (!!selectedItem?.record?.permissions?.view || isAccessedFromTask) &&
        !!selectedItem?.record?.isSummaryPanelEnabled
    );

    const isChildSummaryOpened = !!openedPanels?.find(
      item => item[FlexLayoutWindows.ChildRecordSummary]
    );
    togglePanel(
      FlexLayoutWindows.ChildRecordSummary,
      !(additionalData?.record?.recordId && isChildSummaryOpened),
      !!additionalData?.record?.permissions?.view
    );

    const recordsActivePanel = activePanels?.records;

    if (!!recordsActivePanel) {
      focusComponent(FlexLayoutWindows.MainWindow); // to prevent double clicking issue on table
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, model, additionalData]);
};

export default useResourcePanels;
