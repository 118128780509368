import React from 'react';
import { Col, Row } from '../../components/lib/Grid';
import { LogoIcon } from '../../components/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import { Text } from '../../components/lib/Text';
import AutologyxInformation from 'components/AutologyxInformation';
import useResetPasswordStyles from './styles';
import LoaderOverlay from '../../components/LoaderOverlay';
import { useResetPasswordData, useResetPasswordForm } from './hooks';
import { Checkbox } from '../../components/lib/Checkbox';
import PasswordRules from '../../components/PasswordRules';
import { Formik } from 'formik';
import FormField from '../TaskTemplates/components/FormField';
import FormLabel from '../TaskTemplates/components/FormLabel';
import PasswordInput from '../../components/Inputs/PasswordInput';
import { ButtonPrimary } from '../../components/lib/Button';
import { ResetPasswordFields } from './enums';
import { useToggle } from '../../hooks/useToggle';
import ErrorComponent from '../../components/ErrorComponent';
import { StatusCodes } from 'http-status-codes';
import { useParams } from 'react-router';
import { ResetPasswordFormValues, ResetPasswordParams } from './types';
import { RESET_PASSWORD_TITLE_TESTID } from 'utils/testIds';
import { usePasswordRulesData } from 'hooks/usePasswordRulesData';
import { ActivationTokenError } from 'pages/ActivateAccount/enums';
import ForgotPasswordForm from 'pages/ForgotPassword/components/ForgotPasswordForm';
import { FallbackLogoOptions } from 'components/Icon/types';

const ResetPassword = () => {
  const intl = useIntl();
  const classes = useResetPasswordStyles({});
  const {
    activateAccountData,
    loadingActivateAccountData,
    error,
  } = useResetPasswordData();
  const {
    loadingPasswordRulesData,
    passwordComplexityData,
  } = usePasswordRulesData({
    fetchOnLoad: false,
    shouldFetch: !!activateAccountData,
  });
  const {
    setIsPasswordFulfilled,
    onSubmit,
    buildValidationSchema,
    initialValues,
    isPasswordFulfilled,
  } = useResetPasswordForm();
  const { token } = useParams<ResetPasswordParams>();
  const [toggled, { toggleOff, toggleOn }] = useToggle(false);

  const isTokenExpired =
    error?.messages?.error_code === ActivationTokenError.TokenExpired;

  if (
    (loadingActivateAccountData && loadingPasswordRulesData) ||
    (!passwordComplexityData &&
      !isTokenExpired &&
      (!activateAccountData || !error))
  )
    return <LoaderOverlay />;

  if (!token)
    return (
      <div className={classes.notFoundWrapper}>
        <ErrorComponent error={StatusCodes.NOT_FOUND} />
      </div>
    );

  return (
    <div className={classes.wrapper}>
      <Row justify='center' className='w-100'>
        <Col span={6} xxl={15} xl={16} lg={18} md={14} sm={18} xs={22}>
          {isTokenExpired ? (
            <ForgotPasswordForm
              title={intl.formatMessage({
                defaultMessage: 'Reset password link has expired',
                id: 'resetPassword.passwordLinkExpired',
              })}
              subtitle={intl.formatMessage({
                defaultMessage:
                  'Please enter your username below and request a new link to reset your password.',
                id: 'resetPassword.enterUsernameBelowToReset',
              })}
              buttonLabel={intl.formatMessage({
                defaultMessage: 'Reset password',
                id: 'resetPassword.resetPassword',
              })}
              showErrorIcon
            />
          ) : (
            <div className={classes.card}>
              <LogoIcon
                width={150}
                size={150}
                fallbackLogo={FallbackLogoOptions.NORMAL}
                className={classes.logo}
              />
              <p
                className={classes.title}
                data-testid={RESET_PASSWORD_TITLE_TESTID}
              >
                <FormattedMessage
                  id='reset.title'
                  defaultMessage='Reset password'
                />
              </p>
              <Formik
                {...{ initialValues, onSubmit }}
                validationSchema={buildValidationSchema()}
                enableReinitialize
              >
                {({ handleSubmit, errors, dirty, isSubmitting }) => (
                  <>
                    <FormField>
                      <Col span={12} className={classes.input}>
                        <FormLabel required>
                          <FormattedMessage
                            id='reset.password'
                            defaultMessage='New password'
                          />
                        </FormLabel>
                        <PasswordInput
                          visibilityToggle={false}
                          isPasswordVisible={toggled}
                          name={ResetPasswordFields.Password}
                        />
                      </Col>
                      <Col span={12} className={classes.input}>
                        <FormLabel required>
                          <FormattedMessage
                            id='reset.confirmPassword'
                            defaultMessage='Confirm new password'
                          />
                        </FormLabel>
                        <PasswordInput
                          visibilityToggle={false}
                          isPasswordVisible={toggled}
                          name={ResetPasswordFields.ConfirmPassword}
                        />
                      </Col>
                    </FormField>
                    <Checkbox
                      checked={toggled}
                      onChange={() => (toggled ? toggleOff() : toggleOn())}
                    >
                      <FormattedMessage
                        id='reset.showPassword'
                        defaultMessage='Show password'
                      />
                    </Checkbox>
                    <PasswordRules<ResetPasswordFormValues>
                      setIsFulfilled={setIsPasswordFulfilled}
                      options={passwordComplexityData}
                      passwordKey={ResetPasswordFields.Password}
                    />
                    <div className={classes.buttonWrapper}>
                      <ButtonPrimary
                        disabled={
                          Object.keys(errors).length > 0 ||
                          isSubmitting ||
                          !dirty ||
                          !isPasswordFulfilled
                        }
                        onClick={() => handleSubmit()}
                      >
                        <FormattedMessage
                          id='reset.reset'
                          defaultMessage='Reset password'
                        />
                      </ButtonPrimary>
                    </div>
                  </>
                )}
              </Formik>
            </div>
          )}
        </Col>
        <div className={classes.footer}>
          <Text className={classes.footerText}>
            <FormattedMessage
              id='login.footer'
              defaultMessage='{copy} {year} autologyx.com All rights reserved.'
              values={{
                copy: <span>&copy;</span>,
                year: new Date().getFullYear(),
                privacy: (
                  <u>
                    <FormattedMessage
                      id='login.privacy'
                      defaultMessage='Privacy'
                    />
                  </u>
                ),
              }}
            />
          </Text>
        </div>
      </Row>
      <div className={classes.informationWrapper}>
        <AutologyxInformation />
      </div>
    </div>
  );
};

export default ResetPassword;
