import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseCollapseWithHeaderStylesProps = {
  isOpen?: boolean;
};

const useCollapseWithHeaderStyles = createUseStyles((theme: ThemeState) => {
  return {
    wrapper: {
      display: 'flex',
      marginTop: -30,
      flexDirection: 'column',
    },
    collapseButton: {
      alignSelf: 'flex-end',
      border: `1px solid ${theme['border-color-base']}`,
      borderRadius: ({ isOpen }: UseCollapseWithHeaderStylesProps) =>
        isOpen ? '3px 3px 0px 0px' : '3px 3px 3px 3px',
      borderBottomColor: ({ isOpen }: UseCollapseWithHeaderStylesProps) =>
        isOpen ? 'white' : theme['border-color-base'],
      marginBottom: -1,
      zIndex: 1,
      height: 35,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px 15px',
      gap: '11px',
      userSelect: 'none',
      cursor: 'pointer',
    },
    collapseContent: {
      padding: '11px 14px',
    },
    collapseContentWrapper: ({ isOpen }: UseCollapseWithHeaderStylesProps) => ({
      border: `1px solid ${theme['border-color-base']}`,
      opacity: isOpen ? 1 : 0,
      overflow: 'hidden',
      maxHeight: isOpen ? 100 : 0,
      transition: 'all .2s ease-in-out',
    }),
    arrow: {
      color: theme['layout-trigger-color'],
    },
  };
});

export default useCollapseWithHeaderStyles;
