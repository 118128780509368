import { createUseStyles } from 'react-jss';

const useObjectClassesFieldsStyles = createUseStyles({
  autoSaveTextWrapper: {
    margin: '0 0 -32px 15px',
  },
  crossedOutEyeWhite: {
    color: 'white',
  },
});

export default useObjectClassesFieldsStyles;
