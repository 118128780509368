import useFilterEditor from 'components/FilterEditor/hooks';
import usePredicates from 'components/Filters/usePredicates';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetCurrentFilters,
  setAppliedFiltersForTable,
} from 'store/actions/filtersActions';
import { getCurrentTable } from 'store/selectors/filtersSelectors';
import { getCurrentTableCustomOffset } from 'store/selectors/generalSelectors';
import { getCurrentTablePreferenceFilter } from 'store/selectors/preferencesSelectors';
import CustomCells from 'utils/Enums/CustomCells';
import TablesType from 'utils/Enums/TablesType';
import { TPredicateValue } from 'utils/types/predicates.types';
import { AutocompleteSelectValue } from 'utils/types/selectInput.types';
import { predicateValuesMapFunction } from './utils';
import { FIELD_PREFIX } from 'utils/consts';

interface UseGeneratedParamsStringPrams {
  pagination: {
    currentPage: number;
    pageSize: number;
  };
  sort: string;
  withFilters: boolean;
  classID?: string;
  customTableName?: TablesType; //make the table independent from current table property set in redux store. This let to display many tables in the same view
}

export const useGeneratedParamsString = ({
  pagination,
  sort,
  withFilters,
  classID,
  customTableName,
}: UseGeneratedParamsStringPrams) => {
  const PREDICATES = usePredicates();
  const currentTableFilters = useSelector(getCurrentTablePreferenceFilter);
  const { areFiltersValid } = useFilterEditor(classID);
  const currentTableName = useSelector(getCurrentTable);
  const customOffset = useSelector(
    getCurrentTableCustomOffset(classID, customTableName)
  );
  const dispatch = useDispatch();

  const parsePredicateValues = useCallback(
    /*
  predicateValue can be an array in case when multiselect is used,
  also it can be an AutocompleteSelectValue or array of this type
  we parse the array to string separated with commas
  */
    (predicateValue: TPredicateValue) => {
      if (Array.isArray(predicateValue)) {
        if (
          typeof predicateValue[0] === 'string' ||
          typeof predicateValue[0] === 'number'
        ) {
          //predicate value is array of strings or numbers
          return (predicateValue as Array<string>).join(',');
        } else {
          //predicate value is array of AutocompleteSelectValue
          return (predicateValue as Array<AutocompleteSelectValue>)
            .map(autocompleteValue => autocompleteValue.value)
            .join(',');
        }
      } else {
        if (
          typeof predicateValue === 'string' ||
          typeof predicateValue === 'number'
        ) {
          return predicateValue.toString();
        } else {
          //predicate value is AutocompleteSelectValue
          return predicateValue.value;
        }
      }
    },
    []
  );

  const getFilterParams = useCallback(() => {
    return currentTableFilters
      .reduce((queryItems: string[], item) => {
        const {
          value: { predicateKey, predicateValues },
        } = item;
        if (areFiltersValid()) {
          const { args } = PREDICATES[item.type][predicateKey];
          let { query } = PREDICATES[item.type][predicateKey];

          if (item.type === 'user' && item.column.includes(FIELD_PREFIX)) {
            query = `__users${query}`;
          }

          if (item.column === CustomCells.Assignees) query = `__users${query}`;
          const queryString = args.reduce((queryToFill, arg) => {
            let escapedValues;
            if (Array.isArray(predicateValues[arg])) {
              escapedValues = (predicateValues[arg] as []).map(
                predicateValuesMapFunction
              );
            } else {
              escapedValues = predicateValues[arg];
            }
            const predicateValue = parsePredicateValues(escapedValues);

            return queryToFill.replace(
              `{${arg}}`,
              encodeURIComponent(predicateValue as string)
            );
          }, query);

          queryItems.push(`${item.column}${queryString}`);
          dispatch(
            setAppliedFiltersForTable({ id: currentTableName, value: true })
          );
        }
        return queryItems;
      }, [])
      .join('&');
  }, [
    PREDICATES,
    areFiltersValid,
    currentTableFilters,
    currentTableName,
    dispatch,
    parsePredicateValues,
  ]);

  return useCallback(() => {
    const params: { [key: string]: string | number | undefined } = {
      limit: pagination.pageSize,
      offset:
        pagination.pageSize * pagination.currentPage -
        pagination.pageSize +
        customOffset,
      ordering: sort ? sort : undefined,
    };

    const queryParams = Object.keys(params)
      .filter(key => !!params[key])
      .map(key => key + '=' + params[key])
      .join('&');

    if (!withFilters) return queryParams;

    try {
      const filterParams = getFilterParams();

      if (!!filterParams) {
        return `${queryParams}&${filterParams}`;
      }
    } catch {
      //if filters are corrupted we reset them
      dispatch(resetCurrentFilters());
    }

    return queryParams;
  }, [
    pagination.pageSize,
    pagination.currentPage,
    customOffset,
    sort,
    withFilters,
    getFilterParams,
    dispatch,
  ]);
};
