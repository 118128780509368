import moment from 'moment';
import { GetResponse } from 'utils/types';
import {
  AssigneesData,
  EventData,
  FieldValueData,
  OwnersData,
  EventFieldType,
  FieldValueType,
  EventHistory,
  EventType,
  UserFieldValueUpdate,
  DocumentData,
} from './types';

export const isDocumentData = (data: EventData): data is DocumentData[] => {
  return (
    Array.isArray(data) &&
    data.every(
      e => 'document_template_id' in e && 'file_name' in e && 'file_id' in e
    )
  );
};

export const isOwnersData = (data: EventData): data is OwnersData => {
  return (
    Array.isArray(data) && data.every(e => 'type' in e && e.type === 'user')
  );
};

export const isAssigneesData = (data: EventData): data is AssigneesData => {
  return (
    !Array.isArray(data) &&
    'permission_set' in data &&
    isOwnersData(data.assignees)
  );
};

export const isUserFieldValueData = (
  eventData: EventData,
  eventType: EventType //event type is important, because there is also Owner changed event and it contains the same data structure
): eventData is UserFieldValueUpdate[] => {
  return (
    eventType === EventType.FieldValuesChanged &&
    Array.isArray(eventData) &&
    'type' in eventData[0] &&
    eventData[0].type === EventFieldType.user &&
    'value_labels' in eventData[0] &&
    'users' in (eventData[0].value_labels ?? {})
  );
};

export const isFieldValueData = (data: EventData): data is FieldValueData => {
  return (
    Array.isArray(data) &&
    !isOwnersData(data) &&
    data.every(
      item =>
        'id' in item &&
        typeof item.id === 'number' &&
        'name' in item &&
        (typeof item.name === 'string' || item.name === null) &&
        'type' in item &&
        item.type !== 'user' &&
        !!EventFieldType[item.type] &&
        'value' in item
    )
  );
};

export const getFieldValueDate = (
  value: FieldValueType,
  dateFormat: string,
  timeFormat = ''
) => {
  if (typeof value === 'string')
    return moment(value).format(`${dateFormat} ${timeFormat}`);
  return value;
};

export const separateUpdateFieldEvents = (
  data: GetResponse<EventHistory>,
  fieldId?: number
): GetResponse<EventHistory> => {
  const separated: EventHistory[] = [];
  const { results } = data;

  results.forEach(event => {
    if (
      event.event_type === EventType.FieldValuesChanged &&
      event.event_data.length > 1
    ) {
      const { event_data } = event;
      event_data.forEach(eventData => {
        if (!fieldId || eventData.id === fieldId) {
          data.filtered_count++;
          data.total_count++;
          separated.push({
            ...event,
            key: event.event_id + eventData.id,
            event_data: [eventData],
          });
        }
      });

      return;
    }
    separated.push(event);
  });

  data.results = separated;

  return data;
};
