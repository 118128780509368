import { ConditionalRelationPair } from 'components/formBuilder/formBuilder/types';
import { Condition } from 'components/FormPreview/types';
import {
  columnsSizes,
  columnPercentageValue,
  defaultColumnRatiosValue,
  defaultColumnRatiosOptions,
} from './consts';

export const getPopupContainerFabric = (id: string) => () =>
  document.getElementById(id) || document.body;

export const getColumnRatiosDefaultValue = (
  columnsAmount: number | undefined
) => defaultColumnRatiosValue[columnsAmount || 1];

export const getColumnRatiosOptions = (columnsAmount: number | undefined) =>
  defaultColumnRatiosOptions[columnsAmount || 1];

export const mapColumnRatiosToColumnSize = (columnRatios: string) => {
  const tmpColumnSize = columnsSizes[columnRatios];

  if (!tmpColumnSize) {
    const columnsAmount = (columnRatios.match(/:/g) || []).length + 1;
    const columnRatioDefaultValue = getColumnRatiosDefaultValue(columnsAmount);

    return columnsSizes[columnRatioDefaultValue];
  }

  return tmpColumnSize;
};

export const mapColumnsSizeToColumnRatios = (columnsSize: number[]) => {
  const tmpResult = columnsSize.map(
    columnSize => columnPercentageValue[columnSize]
  );

  if (!tmpResult.some(value => value === undefined)) return tmpResult.join(':');

  let columnsAmount = columnsSize.length;

  if (columnsAmount < 1) columnsAmount = 1;
  if (columnsAmount > 3) columnsAmount = 3;

  const tmpColumnSize = Math.floor(24 / columnsAmount);
  const tmpColumnPercentage = columnPercentageValue[tmpColumnSize];

  return new Array(columnsAmount).fill(tmpColumnPercentage).join(':');
};

export const filterRemovedSchemaDeps = (
  deps: MappedObject<Condition[], string> | undefined,
  depsPairsToRemove: ConditionalRelationPair[]
) => {
  const schemaDeps = Object.entries(deps || {})
    .map(([sectionName, condition]) => {
      const filteredConditon = condition.filter(
        ({ path: [, inputName] = [] }) => {
          const isConditionToRemove = depsPairsToRemove.some(
            ({ input, section }) =>
              section.key === sectionName && input.key === inputName
          );

          return !isConditionToRemove;
        }
      );

      return filteredConditon.length > 0 ? [sectionName, filteredConditon] : [];
    })
    .filter(dep => dep.length !== 0);

  return {
    ...Object.fromEntries(schemaDeps),
  };
};
