import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useRadioStyles = createUseStyles((theme: ThemeState) => {
  return {
    radio: {
      borderColor: theme['border-color-base'],
      '& .ant-radio-inner': {
        width: '1.0625rem',
        height: '1.0625rem',
        borderColor: theme['checkbox-border-color'],
        borderWidth: '.0625rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        '&::after': {
          width: '100%',
          height: '100%',
          top: 'auto',
          left: 'auto',
          position: 'static',
          border: '.15625rem solid white',
          borderRadius: '100%',
        },
      },
      '&.ant-radio-wrapper-checked, &.ant-radio-wrapper': {
        '& .ant-radio-checked .ant-radio-inner': {
          borderColor: theme['primary-color-medium'],
          '&::after': {
            backgroundColor: theme['primary-color-medium'],
          },
        },
        '& .ant-radio:hover .ant-radio-inner': {
          borderColor: theme['primary-color-medium'],
        },
      },
      '& .ant-radio.ant-radio-disabled': {
        cursor: 'not-allowed',
      },
      '&.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner': {
        borderColor: theme['primary-color'],
        boxShadow: 'none',
      },
      '& .ant-radio-checked::after': {
        border: `1px solid ${theme['primary-color']}`,
      },
      '&.ant-radio-wrapper-checked .ant-radio-checked.ant-radio-disabled .ant-radio-inner::after, &.ant-radio-wrapper .ant-radio-checked.ant-radio-disabled .ant-radio-inner::after': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
      '&.ant-radio-wrapper-disabled': {
        cursor: 'not-allowed !important',
      },
    },
    radioButton: {
      borderColor: theme['border-color-base'],
      '&:hover:not(.ant-radio-button-wrapper-disabled)': {
        color: theme['primary-color-medium'],
      },
      '&:focus, &:focus-within': {
        borderColor: theme['primary-color-medium'],
        boxShadow: 'none',
      },
      '&:active:not(.ant-radio-button-wrapper-disabled)': {
        borderColor: theme['primary-color-medium'],
      },
      '&.ant-radio-button-wrapper-checked': {
        borderColor: theme['primary-color-medium'],
        color: theme['primary-color-medium'],
        "&:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child": {
          borderColor: theme['primary-color-medium'],
          color: theme['primary-color-medium'],
        },
        '&:not(.ant-radio-button-wrapper-disabled)': {
          borderColor: theme['primary-color-medium'],
          color: theme['primary-color-medium'],
          '&:first-child': {
            borderColor: theme['primary-color-medium'],
            color: theme['primary-color-medium'],
          },
          '&:hover': {
            color: theme['primary-color-medium'],
          },
          '&::before': {
            backgroundColor: theme['primary-color-medium'],
          },
        },
        '&.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within': {
          boxShadow: 'none',
        },
      },
      '.ant-radio-input:focus + .ant-radio-inner': {
        boxShadow: 'none',
      },
    },
    radioButtonSolid: {
      borderColor: theme['border-color-base'],
      '&:hover:not(.ant-radio-button-wrapper-disabled)': {
        color: theme['primary-color-medium'],
      },
      '&:focus, &:focus-within': {
        borderColor: theme['primary-color-medium'],
        boxShadow: 'none',
      },
      '&:active:not(.ant-radio-button-wrapper-disabled)': {
        borderColor: theme['primary-color-medium'],
        backgroundColor: theme['primary-color-medium'],
      },
      '&.ant-radio-button-wrapper-checked': {
        borderColor: theme['primary-color-medium'],
        color: '#ffffff',
        backgroundColor: theme['primary-color-medium'],
        "&:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child": {
          borderColor: theme['primary-color-medium'],
          color: '#ffffff',
          backgroundColor: theme['primary-color-medium'],
        },
        '&:not(.ant-radio-button-wrapper-disabled)': {
          borderColor: theme['primary-color-medium'],
          color: '#ffffff',
          backgroundColor: theme['primary-color-medium'],
          '&:first-child': {
            borderColor: theme['primary-color-medium'],
            color: '#ffffff',
            backgroundColor: theme['primary-color-medium'],
          },
          '&:hover': {
            color: '#ffffff',
            backgroundColor: theme['primary-color-medium'],
          },
          '&::before': {
            backgroundColor: theme['primary-color-medium'],
          },
        },
      },
    },
    readonly: {
      '&:not(.ant-radio-wrapper-checked)': {
        '&, &:hover, &:active, &:focus': {
          '&, &:focus, &:active, & .ant-radio:focus, & .ant-radio:active, .ant-radio-inner, .ant-radio-inner:focus, .ant-radio-inner:active, & span, & input, & input:focus, &': {
            borderColor: `${theme['placeholder-border-color']} !important`,
            cursor: 'default !important',
          },
        },
      },
    },
  };
});

export default useRadioStyles;
