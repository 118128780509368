import React, { useMemo } from 'react';
import useDocumentsStyles from './styles';
import { DocumentsProps } from './types';
import { OBJECT_RECORD_DETAILS } from 'utils/endpoints';
import { ObjectRecordDetails } from 'utils/types/api/objectRecords.types';
import useData from 'hooks/useData';
import { generatePath } from 'react-router-dom';
import ErrorComponent from 'components/ErrorComponent';
import { Loader } from 'components/lib/Loader';
import kebabCase from 'lodash/kebabCase';
import { LISTED_UPLOADED_FILE } from 'utils/testIds';
import FileClickLink from 'components/FileLink';
import { FileType } from 'components/FileLink/types';

const DocumentList = ({ value, recordId }: DocumentsProps) => {
  const classes = useDocumentsStyles({});
  const [data, { loading, error }] = useData<ObjectRecordDetails>(
    generatePath(OBJECT_RECORD_DETAILS, { id: recordId })
  );

  const list = useMemo(
    () =>
      value
        .map(
          fileId =>
            ({ ...data?._meta?.labels?.files[fileId], fileId } as {
              name: string;
              url: string;
              fileId: number;
            })
        )
        .sort((a, b) => {
          const aName = a?.name?.toLocaleLowerCase();
          const bName = b?.name?.toLocaleLowerCase();

          if (aName > bName) return 1;
          if (aName < bName) return -1;
          return 0;
        }),
    [data, value]
  );

  if (loading)
    return (
      <div className={classes.loadingWrapper}>
        <Loader spinning />
      </div>
    );

  if (error?.status)
    return (
      <div className={classes.loadingWrapper}>
        <ErrorComponent error={error.status} />
      </div>
    );

  return (
    <div
      className={classes.dropdownWrapper}
      onClick={event => event.stopPropagation()}
    >
      <div className={classes.scrollWrapper}>
        {list.map(({ fileId, name, url }) => (
          <div
            className={classes.listItem}
            key={fileId}
            data-testid={`${LISTED_UPLOADED_FILE}-${kebabCase(name)}`}
          >
            <FileClickLink label={name} type={FileType.Document} url={url} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DocumentList;
