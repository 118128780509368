import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  APPEND_SINGLE_SIGN_ON,
  RESET_SINGLE_SIGN_ON,
  RESET_SINGLE_SIGN_ON_COLUMNS,
  SET_SINGLE_SIGN_ON,
  SET_SINGLE_SIGN_ON_COLUMNS,
  SET_SINGLE_SIGN_ON_FETCHING,
  SET_SINGLE_SIGN_ON_RESTRICTIONS,
  SET_SINGLE_SIGN_ON_SELECTED_ROW,
} from 'store/constants/singleSignOn.consts';
import {
  appendTableContent,
  resetTable,
  resetTableColumns,
  setSelectedRow,
  setTableColumns,
  setTableContent,
  setTableLoading,
} from './tablesCommonStateModificators';
import { SingleSignOn } from 'utils/types/api/singleSignOn.types';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import { CommonTableState } from 'utils/types/api/table.types';
import cloneDeep from 'lodash/cloneDeep';

const initialState = cloneDeep(INITIAL_TABLE_STATE);
const reducerName: Reducer<CommonTableState<SingleSignOn>, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_SINGLE_SIGN_ON: {
      return setTableContent<SingleSignOn, typeof SET_SINGLE_SIGN_ON>(
        state,
        action
      );
    }
    case APPEND_SINGLE_SIGN_ON:
      return appendTableContent<SingleSignOn, typeof APPEND_SINGLE_SIGN_ON>(
        state,
        action
      );

    case SET_SINGLE_SIGN_ON_FETCHING:
      return setTableLoading<SingleSignOn, typeof SET_SINGLE_SIGN_ON_FETCHING>(
        state,
        action
      );

    case RESET_SINGLE_SIGN_ON:
      return resetTable<SingleSignOn>(state, initialState);

    case RESET_SINGLE_SIGN_ON_COLUMNS:
      return resetTableColumns<SingleSignOn>(state, initialState);

    case SET_SINGLE_SIGN_ON_RESTRICTIONS:
      return { ...state, restrictions: action.restrictions };
    case SET_SINGLE_SIGN_ON_COLUMNS:
      return setTableColumns<SingleSignOn, typeof SET_SINGLE_SIGN_ON_COLUMNS>(
        state,
        action
      );
    case SET_SINGLE_SIGN_ON_SELECTED_ROW:
      return setSelectedRow<
        SingleSignOn,
        typeof SET_SINGLE_SIGN_ON_SELECTED_ROW
      >(state, action);
    default:
      return state;
  }
};

export default reducerName;
