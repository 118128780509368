import clsx from 'clsx';
import { useTableRowContext } from 'contexts/TableRowContext';
import React from 'react';
import { TABLE_ROW } from 'utils/elementsIds';
import { TABLE_ROW_ITEM_ID_TESTID } from 'utils/testIds';
import { useEmptyTableRowStyles } from './styles';

const EmptyTableRow = ({ index }: { index: number }) => {
  const { density } = useTableRowContext();
  const classes = useEmptyTableRowStyles({ density, index });
  return (
    <div
      key={`empty-${index}`}
      className={clsx(classes.tr)}
      data-testid={`${TABLE_ROW_ITEM_ID_TESTID}${index}`}
      id={TABLE_ROW.replace('{0}', `empty-${index.toString()}`)}
    ></div>
  );
};

export default EmptyTableRow;
