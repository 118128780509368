import { useCallback, useState } from 'react';
import debounce from 'lodash/debounce';
import {
  AutocompleteSelectValue,
  AutocompleteUsersResponse,
  SelectUserOption,
} from 'utils/types/selectInput.types';
import { useToggle } from 'hooks/useToggle';
import useData from 'hooks/useData';

const useAutocompleteUsersSelect = (
  autocompleteUrl: string,
  onChange: (value: SelectUserOption | undefined) => void,
  handleOnBlur?: () => void,
  selectMultiple?: boolean
) => {
  const [
    isOpenSelect,
    { toggleOn: openSelect, toggleOff: closeSelect },
  ] = useToggle(false);
  const [searchValue, setSearchValue] = useState('');

  const [{ results = [] } = {}, { loading, fetchData }] = useData<
    AutocompleteUsersResponse | undefined
  >('', {
    fetchOnLoad: false,
  });

  const debouncedFetchData = useCallback(debounce(fetchData, 300), []);

  const handleChange = useCallback(
    (
      { value: selectedUserId }: AutocompleteSelectValue | undefined = {
        value: '',
        label: '',
      }
    ) => {
      if (selectedUserId) {
        const selectedUser = results.find(({ id }) => id === +selectedUserId);

        onChange(selectedUser);
        if (!selectMultiple) closeSelect();
      }
    },
    [onChange, results, selectMultiple, closeSelect]
  );

  const onFocus = useCallback(() => {
    openSelect();
    fetchData(autocompleteUrl);
  }, [autocompleteUrl, fetchData, openSelect]);

  const onSearch = useCallback(
    (val: string) => {
      setSearchValue(val);
      debouncedFetchData(`${autocompleteUrl}${encodeURIComponent(val)}`);
    },
    [autocompleteUrl, debouncedFetchData]
  );

  const onBlur = useCallback(() => {
    closeSelect();

    if (handleOnBlur) handleOnBlur();
  }, [closeSelect, handleOnBlur]);

  return {
    data: results,
    isOpenSelect,
    loading,
    handleChange,
    onBlur,
    onFocus,
    onSearch,
    searchValue,
  };
};

export default useAutocompleteUsersSelect;
