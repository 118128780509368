import { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'antd/lib/form/interface';

import { loginUser } from 'store/actions/authActions';
import { useHistory, useLocation } from 'react-router-dom';
import { getAccessToken } from 'store/selectors/authSelectors';
import routes, { nonProtectedRoutes } from 'utils/routingPaths';
import { generatePath } from 'react-router';
import { LoginErrors } from './enums';
import useData from '../../hooks/useData';
import { SINGLE_SIGN_ON_PROVIDERS_LIST } from '../../utils/endpoints';
import { dataProvidersTransform } from './utils';
import { AutocompleteSelectValue } from '../../utils/types/selectInput.types';
import { apiCall } from 'utils/api';
import { useIntl } from 'react-intl';
import { LoginErrorType, LoginPageLocationState } from './types';
import * as Yup from 'yup';

export const useLoginForm = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const token = useSelector(getAccessToken);
  const { pathname, state } = useLocation<LoginPageLocationState>();

  const [error, setError] = useState<LoginErrorType | undefined>(state?.error);

  const errorMessage = useMemo(() => {
    switch (error) {
      case LoginErrorType.Credentials:
        return intl.formatMessage({
          id: 'user.wrongCredentials',
          defaultMessage:
            'Sorry the details you have provided are incorrect, please try again.',
        });

      case LoginErrorType.SSO:
        return intl.formatMessage({
          id: 'user.ssoError',
          defaultMessage:
            'Sorry we are unable to log you in using Single Sign on. There may be a number of reasons for this, please contact your system administrator for help.',
        });
    }
  }, [error, intl]);

  const [isLoading, setIsLoading] = useState(false);

  const onFinish = useCallback(
    async (values: Store) => {
      const { username, password } = values;

      setIsLoading(true);
      try {
        const result = await dispatch(loginUser(username, password));
        setIsLoading(false);

        if (!!result) {
          return;
        }
      } catch (error) {
        setIsLoading(false);
        if (error.error_code === LoginErrors.PasswordExpired) {
          history.push(
            generatePath(nonProtectedRoutes.SET_EXPIRED_PASSWORD, {
              token: error.token,
            })
          );
        } else {
          setError(LoginErrorType.Credentials);
        }
      }
    },
    [dispatch, history]
  );

  const navigateToReset = () => {
    history.push(nonProtectedRoutes.FORGOTTEN_PASSWORD);
  };

  const onChangeProvider = async (id: number) => {
    if (error) setError(undefined);

    try {
      const {
        data: { redirect_uri },
      } = await apiCall.post(SINGLE_SIGN_ON_PROVIDERS_LIST, {
        id,
      });

      const redirectPath = new URLSearchParams(search).get('path');

      if (redirectPath) localStorage.setItem('redirectPath', redirectPath);
      window.location.href = redirect_uri;
    } catch (error) {
      setError(LoginErrorType.SSO);
    }
  };

  useEffect(() => {
    if (!token) {
      return;
    }

    const path = new URLSearchParams(search).get('path');
    history.replace(path ?? routes.WORKSPACE);
  }, [history, token, search]);

  useEffect(() => {
    if (state?.error) history.replace(pathname, {});
  }, [history, pathname, state]);

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .email(
        intl.formatMessage({
          id: 'errors.enterValidEmailAddress',
          defaultMessage: 'Enter a valid email address',
        })
      )
      .required(
        intl.formatMessage({
          id: 'user.pleaseEnterEmail',
          defaultMessage: 'Please enter your username',
        })
      ),
    password: Yup.string().required(
      intl.formatMessage({
        id: 'user.pleaseEnterPassword',
        defaultMessage: 'Please enter your password',
      })
    ),
  });

  return {
    onFinish,
    isLoading,
    navigateToReset,
    onChangeProvider,
    errorMessage,
    error,
    validationSchema,
  };
};

export const useProviders = () => {
  const [providers, { loading, error }] = useData<AutocompleteSelectValue[]>(
    generatePath(SINGLE_SIGN_ON_PROVIDERS_LIST),
    {
      dataTransformFn: dataProvidersTransform,
    }
  );

  return {
    providers,
    loadingAuthenticationData: loading,
    errorRole: error,
  };
};
