import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { LINK_TESTID } from 'utils/testIds';
import { ButtonLink } from '../Button';

interface LinkComponentProps extends LinkProps {
  element?: 'Link' | 'a';
}

const LinkComponent: React.FC<LinkComponentProps> = ({
  children,
  to,
  className,
  element = 'Link',
}) => {
  if (element === 'Link') {
    return (
      <Link {...{ to, className }} data-testid={LINK_TESTID}>
        <ButtonLink underlined={false}>{children}</ButtonLink>
      </Link>
    );
  } else {
    return (
      <a href={to as string} {...{ className }} data-testid={LINK_TESTID}>
        {children}
      </a>
    );
  }
};

export default React.memo(LinkComponent);
