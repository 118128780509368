import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useWindowContext } from 'components/lib/FlexLayout/WindowContext';

interface UseOutsideClickOptions {
  event?: 'click' | 'mousedown' | 'mouseup';
  disabled?: boolean;
}

const useOutsideClick = (
  elementRef: React.RefObject<HTMLElement>,
  callback: (event: MouseEvent) => void,
  { event = 'mousedown', disabled = false }: UseOutsideClickOptions = {},
  includeSelectors?: string,
  preventClickElement?: Element | null //block callback if click event started from this element #38091
) => {
  const { windowRef } = useWindowContext();
  // if we are in external window we want to attach eventListener to its document
  const availableDocument = windowRef?.document || document;
  const didStartInsideElement = useRef<boolean>();

  const excludedElements = useMemo(
    () =>
      includeSelectors
        ? availableDocument.querySelectorAll(includeSelectors)
        : [],
    [includeSelectors, availableDocument]
  );

  const someElementsIncludesTarget = useCallback(
    (target: EventTarget | null) => {
      if (!includeSelectors) return false;

      //   @ts-ignore
      return [...excludedElements].some(element =>
        element?.contains(target as Node)
      );
    },
    [includeSelectors, excludedElements]
  );

  useEffect(() => {
    if (disabled) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (
        elementRef.current &&
        !elementRef.current.contains(event.target as Node) &&
        !someElementsIncludesTarget(event.target)
      ) {
        if (preventClickElement && didStartInsideElement.current) {
          didStartInsideElement.current = false;
          return;
        }
        callback(event);
      }
      didStartInsideElement.current = false;
    };

    const handleStartInsideElement = () =>
      (didStartInsideElement.current = true);

    if (preventClickElement)
      preventClickElement.addEventListener(
        'mousedown',
        handleStartInsideElement
      );

    availableDocument.addEventListener(event, handleClickOutside);

    return () => {
      availableDocument.removeEventListener(event, handleClickOutside);
      if (preventClickElement)
        preventClickElement.removeEventListener(
          'mousedown',
          handleStartInsideElement
        );
    };
  }, [
    elementRef,
    callback,
    event,
    disabled,
    availableDocument,
    someElementsIncludesTarget,
    preventClickElement,
  ]);
};

export default useOutsideClick;
