import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useErrorLabelStyles = createUseStyles((theme: ThemeState) => {
  return {
    requiredAsterisk: {
      color: theme['error-color-dark-pink'],
      marginLeft: 2,
      fontSize: 18,
      paddingTop: '1em',
    },
    requiredLabel: {
      textTransform: 'lowercase',
      fontSize: 12,
      color: theme['disabled-color'],
      fontWeight: 'normal',
    },
    '@global [data-small-column="true"] [data-small-column-hide="true"]': {
      display: 'none',
    },
    wrapperSpan: {
      display: 'inline-block',
    },
    innerWrapperSpan: {
      display: 'flex',
      width: '100%',
      marginRight: 3,
    },
  };
});

export default useErrorLabelStyles;
