import { Tag } from 'components/lib/Tag';
import React from 'react';
import { TASK_STATUS } from 'utils/testIds';
import { useStatus } from './hooks';
import useStatusTagStyles from './styles';
import { TaskStatusTagProps } from './types';

const TaskStatusTag = ({ status, size }: TaskStatusTagProps) => {
  const classes = useStatusTagStyles({ status, size });
  const statusText = useStatus(status);

  return (
    <Tag className={classes.statusTag} data-testid={TASK_STATUS}>
      {statusText}
    </Tag>
  );
};

export default TaskStatusTag;
