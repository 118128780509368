import { Action } from 'redux';
import {
  SET_TASK_ASSIGNEES,
  SET_TASK_STAGE_ASSIGNEES,
} from 'store/constants/tasksAssignees.consts';
import {
  SetTaskAssigneesPayload,
  SetTaskStageAssigneesPayload,
} from 'store/reducers/types/tasksAssignees.types';

export interface SetTaskAssigneesAction
  extends Action<typeof SET_TASK_ASSIGNEES> {
  payload: SetTaskAssigneesPayload;
}

export interface SetTaskStageAssigneesAction
  extends Action<typeof SET_TASK_STAGE_ASSIGNEES> {
  payload: SetTaskStageAssigneesPayload;
}

export type TasksAssigneesAction =
  | SetTaskAssigneesAction
  | SetTaskStageAssigneesAction;

export const setTaskAssignees = (
  payload: SetTaskAssigneesPayload
): SetTaskAssigneesAction => {
  return {
    type: SET_TASK_ASSIGNEES,
    payload,
  };
};

export const setTaskStageAssignees = (
  payload: SetTaskStageAssigneesPayload
): SetTaskStageAssigneesAction => {
  return {
    type: SET_TASK_STAGE_ASSIGNEES,
    payload,
  };
};
