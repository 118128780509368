import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useColumnSelectStyles = createUseStyles((theme: ThemeState) => {
  return {
    wrapper: {
      width: '100%',
      '& > .ant-select-selector': {
        minHeight: '32px !important',
      },
    },
    selectSetWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, calc(50% - 4px))',
      gridGap: 8,
    },
    divider: {
      position: 'absolute',
      width: '100%',
      bottom: 0,
      left: 0,
      borderBottom: `1px solid ${theme['border-color-base']}`,
    },
  };
});

export default useColumnSelectStyles;
