import { RootState } from 'store/reducers';

export const selectDocumentTemplatesPanel = (state: RootState) => {
  return state.documentTemplatesPanel.allIds.map(id => {
    const documentTemplate = state.documentTemplatesPanel.byId[id];
    return {
      ...documentTemplate,
      key: documentTemplate.id,
    };
  });
};

export const getDocumentTemplatesPanelColumns = (state: RootState) => {
  return state.documentTemplatesPanel.columns;
};

export const getDocumentTemplatesPanelSelectedRow = (state: RootState) => {
  return state.documentTemplatesPanel?.selectedRow;
};

export const getDocumentTemplatesPanelTotal = (state: RootState) => {
  return state.documentTemplatesPanel?.total;
};

export const getDocumentTemplatesPanelError = (state: RootState) => {
  return state.documentTemplatesPanel?.error;
};
