import React from 'react';
import { FormikDatePickerProps } from '../types';
import { FormikFormItem } from 'components/lib/Form';
import {
  DatePicker as AntDatePicker,
  DateRangePicker as AntDateRangePicker,
  FormikDatePicker,
} from 'components/lib/DatePicker';
import useDatePickerStyles from './styles';
import ReadonlyDatepicker from './ReadonlyDatepicker';
import { useFormikContext } from 'formik';
import { Moment } from 'moment';
import useDateFormat from 'hooks/useDateFormat';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { CustomRangeDatePicker } from 'components/lib/DatePicker/types';
import { DatePickerInputProps } from './types';

const DatePicker: React.FC<FormikDatePickerProps> = ({
  name,
  disabled,
  ...rest
}) => {
  const classes = useDatePickerStyles({});
  const { dateFormat } = useDateFormat();
  const { values } = useFormikContext<
    MappedObject<Moment | null | undefined>
  >();

  if (disabled)
    return <ReadonlyDatepicker value={values[name]} format={dateFormat} />;

  return (
    <FormikFormItem {...{ name }}>
      <FormikDatePicker
        {...{ name, ...rest }}
        className={classes.datePicker}
        format={dateFormat}
      />
    </FormikFormItem>
  );
};

export const DatePickerInput: React.FC<DatePickerInputProps> = props => {
  const { dateFormat, getTimeFormat } = useDateFormat();
  const { showTime } = props;
  const format = showTime ? `${dateFormat}, ${getTimeFormat()}` : dateFormat;

  return <AntDatePicker {...{ format, ...props }} />;
};

export const DateRangePickerInput: React.FC<
  RangePickerProps<Moment> & CustomRangeDatePicker
> = props => {
  const { dateFormat, getTimeFormat } = useDateFormat();
  const { showTime } = props;
  const format = showTime ? `${dateFormat}, ${getTimeFormat()}` : dateFormat;

  return <AntDateRangePicker {...{ format, ...props }} />;
};

export default DatePicker;
