import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ViewFieldUsageWindow from 'components/ViewFieldUsageWindow';
import useFlexLayoutWindow from 'hooks/useFlexLayoutWindow';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import useCustomComponentsViewUsage from './hooks';
import ErrorComponent from 'components/ErrorComponent';
import {
  getCustomComponentsRow,
  getCustomComponentsSelectedRow,
} from 'store/selectors/customComponentSelectors';

const CustomComponentsViewUsagePanel = () => {
  const intl = useIntl();
  const { closeComponent, isExternal } = useFlexLayoutWindow(
    FlexLayoutWindows.CustomComponentsViewUsage
  );
  const selectedRowId = useSelector(getCustomComponentsSelectedRow);
  const { name = '', id } = useSelector(getCustomComponentsRow(selectedRowId));

  const { displayConfig, loading, error } = useCustomComponentsViewUsage(id);

  useEffect(() => {
    if (!selectedRowId)
      closeComponent(FlexLayoutWindows.CustomComponentsViewUsage);
  }, [closeComponent, selectedRowId]);

  if (error) return <ErrorComponent error={error?.status} />;

  return (
    <ViewFieldUsageWindow
      {...{ displayConfig, isExternal }}
      usageLabel={intl.formatMessage({
        id: 'customComponents.componentUsageAreas',
        defaultMessage: 'This element is used in the following system areas:',
      })}
      noUsageLabel={intl.formatMessage({
        id: 'customComponents.noUsageComponentScope',
        defaultMessage: 'This element is not used anywhere in the system.',
      })}
      id={selectedRowId}
      label={name}
      isLoading={loading}
      isLight
    />
  );
};

export default CustomComponentsViewUsagePanel;
