import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useAddColumnStyles = createUseStyles((theme: ThemeState) => {
  return {
    columnIndicator: {
      color: theme['error-color-pink'],
      transition: 'color 0.2s ease-in-out',
      '&:hover': {
        color: theme['error-color-dark-pink'],
      },
    },
    disabledColumnIndicator: {
      color: theme['error-color-pink'],
      cursor: 'default',
    },
    wrapper: {
      width: 330,
    },
    heading: { fontWeight: 'bold', marginBottom: 25 },
    checkbox: {
      marginBottom: 20,
      display: 'flex !important',
      height: 20,
      'ant-checkbox + span': {
        overflow: 'hidden',
      },
    },
    buttonsWrapper: {
      marginTop: 10,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    popoverWrapper: {
      position: 'absolute',
      top: -10,
      right: -11,
      zIndex: 30,
    },
    pin: { padding: 0 },
    scroll: {
      overflow: 'auto',
      maxHeight: 400,
    },
    classNameLabel: {
      overflow: 'hidden',
      height: '30px',
      whiteSpace: 'nowrap',
      width: '267px',
      textOverflow: 'ellipsis',
      fontWeight: 600,
    },
  };
});

export default useAddColumnStyles;
