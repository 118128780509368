import useInputStyles from '../styles';
import useGlobalStyle from '../../../hooks/useGlobalStyle';
import React, { useCallback } from 'react';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';
import { DISABLED_TEXT_INPUT_TESTID } from '../../../utils/testIds';
import { DisabledInputProps } from '../types';
import TooltipString from 'components/TooltipString';

export const DisabledInput = ({
  name,
  value,
  placeholder,
  className,
}: DisabledInputProps) => {
  const classes = useInputStyles({});
  const globalClasses = useGlobalStyle();

  const formattedValue = useCallback(value => {
    return typeof value === 'number' ? (
      <NumberFormat {...{ value }} displayType='text' thousandSeparator=' ' />
    ) : (
      value
    );
  }, []);

  return (
    <div
      className={clsx([
        'ant-input',
        'ant-input-disabled',
        globalClasses.disabledInput,
      ])}
      data-testid={`${DISABLED_TEXT_INPUT_TESTID}${name}`}
    >
      {value !== null && value !== undefined ? (
        <TooltipString text={formattedValue(value)}>
          {formattedValue(value)}
        </TooltipString>
      ) : (
        placeholder && (
          <span className={clsx([classes.placeholder, className])}>
            {placeholder}
          </span>
        )
      )}
    </div>
  );
};
