export enum UserFormFields {
  AccountType = 'account_type',
  Username = 'username',
  Password = 'password',
  Roles = 'roles',
  FirstName = 'first_name',
  LastName = 'last_name',
  JobTitle = 'job_title',
  CompanyName = 'company_name',
  Phone = 'phone',
  Mobile = 'mobile',
}
