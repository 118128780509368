import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  appendTableContent,
  resetTable,
  setTableContent,
  setTableLoading,
  resetTableColumns,
  setTableColumns,
  setSelectedRow,
} from './nestedCommonTableStateModificators';
import { INITIAL_NESTED_TABLE_STATE } from 'store/constants/misc.consts';
import { ObjectRecordDetails } from 'utils/types/api/objectRecords.types';
import cloneDeep from 'lodash/cloneDeep';
import { produce } from 'immer';
import {
  SET_NESTED_OBJECT_RECORDS,
  APPEND_NESTED_OBJECT_RECORDS,
  SET_NESTED_OBJECT_RECORDS_FETCHING,
  RESET_NESTED_OBJECT_RECORDS,
  RESET_NESTED_OBJECT_RECORDS_COLUMNS,
  SET_NESTED_OBJECT_RECORDS_COLUMNS,
  SET_NESTED_OBJECT_RECORDS_RESTRICTIONS,
  SET_NESTED_OBJECT_RECORDS_ERROR,
  SET_NESTED_OBJECT_RECORDS_SELECTED_ROW,
  SET_NESTED_OBJECT_RECORDS_SELECTED_COLUMNS,
  CLEAR_NESTED_OBJECT_RECORDS,
  PUSH_STACK,
  POP_STACK,
  CLEAR_STACK,
  POP_MANY_STACK,
  ADD_TREE_CHILD,
  REMOVE_TREE_CHILD,
  REMOVE_TREE_NODE,
  REPLACE_TREE_NODE,
  CLEAR_TREE,
  UPDATE_STACK_ELEMENT,
  UPDATE_NESTED_RECORDS_STACK_MODAL,
} from 'store/constants/nestedObjectRecords.consts';
import {
  addNode,
  removeChild,
  removeNode,
  replaceNode,
} from 'hooks/useNestedRecordsTree/utils';
import { NestedObjectRecordsState } from './types/nestedObjectRecords.types';
import noop from 'lodash/noop';

const initialState = {
  data: { ...cloneDeep(INITIAL_NESTED_TABLE_STATE) },
  selectedRow: undefined,
  nestedRecordsStackModals: undefined,
  tree: {},
  stack: [],
};

const nestedObjectRecordsReducer: Reducer<
  NestedObjectRecordsState,
  RootAction
> = (state = initialState, action) => {
  switch (action.type) {
    case SET_NESTED_OBJECT_RECORDS: {
      return setTableContent<
        ObjectRecordDetails,
        typeof SET_NESTED_OBJECT_RECORDS
      >(state, action);
    }
    case APPEND_NESTED_OBJECT_RECORDS:
      return appendTableContent<
        ObjectRecordDetails,
        typeof APPEND_NESTED_OBJECT_RECORDS
      >(state, action);
    case SET_NESTED_OBJECT_RECORDS_FETCHING:
      return setTableLoading<
        ObjectRecordDetails,
        typeof SET_NESTED_OBJECT_RECORDS_FETCHING
      >(state, action);

    case SET_NESTED_OBJECT_RECORDS_SELECTED_COLUMNS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.classID]: {
            ...state.data[action.classID],
            selectedColumns: action.columns,
          },
        },
      };

    case RESET_NESTED_OBJECT_RECORDS:
      return resetTable<ObjectRecordDetails>(
        state,
        initialState,
        action.classID
      );

    case CLEAR_NESTED_OBJECT_RECORDS:
      const newState = { ...state };
      delete newState.data[action.classID];
      return newState;
    case RESET_NESTED_OBJECT_RECORDS_COLUMNS:
      return resetTableColumns<ObjectRecordDetails>(
        state,
        initialState,
        action.classID
      );

    case SET_NESTED_OBJECT_RECORDS_COLUMNS:
      return setTableColumns<
        ObjectRecordDetails,
        typeof SET_NESTED_OBJECT_RECORDS_COLUMNS
      >(state, action);
    case SET_NESTED_OBJECT_RECORDS_RESTRICTIONS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.classID]: {
            ...state.data[action.classID],
            restrictions: action.payload,
          },
        },
      };
    case SET_NESTED_OBJECT_RECORDS_SELECTED_ROW:
      return setSelectedRow<
        ObjectRecordDetails,
        typeof SET_NESTED_OBJECT_RECORDS_SELECTED_ROW
      >(state, action);
    case SET_NESTED_OBJECT_RECORDS_ERROR:
      return {
        ...state,
        data: {
          ...state.data,
          [action.classID]: {
            ...state.data[action.classID],
            error: action.payload,
          },
        },
      };

    case PUSH_STACK: {
      if (state.stack.find(el => el.classId === action.element.classId))
        return state;
      return {
        ...state,
        stack: [...state.stack, action.element],
      };
    }
    case POP_STACK: {
      return {
        ...state,
        stack: state.stack.slice(0, state.stack.length - 1),
      };
    }
    case POP_MANY_STACK: {
      return {
        ...state,
        stack: state.stack.slice(0, action.index),
      };
    }
    case CLEAR_STACK: {
      return {
        ...state,
        stack: [],
      };
    }
    case UPDATE_STACK_ELEMENT: {
      const newStack = produce(state.stack, draft => {
        const index = draft.findIndex(el => el.classId === action.classId);
        draft[index] = {
          ...draft[index],
          ...action.newData,
        };

        return draft;
      });
      return {
        ...state,
        stack: newStack,
      };
    }
    case ADD_TREE_CHILD: {
      return {
        ...state,
        tree: addNode(action),
      };
    }
    case REMOVE_TREE_NODE: {
      return {
        ...state,
        tree: removeNode(action),
      };
    }
    case REPLACE_TREE_NODE:
      return {
        ...state,
        tree: replaceNode(action),
      };
    case REMOVE_TREE_CHILD: {
      return {
        ...state,
        tree: removeChild(action),
      };
    }
    case CLEAR_TREE: {
      return {
        ...state,
        tree: {},
      };
    }
    case UPDATE_NESTED_RECORDS_STACK_MODAL: {
      return {
        ...state,
        nestedRecordsStackModals: {
          ...state.nestedRecordsStackModals,
          [action.modalId]: {
            data: {
              isVisible: false,
              onCancel: noop,
              onConfirm: noop,
              ...(state.nestedRecordsStackModals?.[action.modalId]?.data ?? {}),
              ...action.data,
            },
          },
        },
      };
    }
    default:
      return state;
  }
};

export default nestedObjectRecordsReducer;
