import { ExclamationMarkSquare } from 'components/Icon';
import { useLimitMessageStyles } from './styles';
import React from 'react';
import { ALERT_TYPE_TESTID } from 'utils/testIds';
import { Alert } from 'components/lib/Alert';
import { useIntl } from 'react-intl';
import { LimitMessageProps } from './types';

const LimitMessage = ({
  optionsCount,
  maxOptionsCount = 100,
}: LimitMessageProps) => {
  const intl = useIntl();
  const classes = useLimitMessageStyles({});

  if (optionsCount < maxOptionsCount) return null;

  return (
    <div className={classes.wrapper} data-testid={ALERT_TYPE_TESTID}>
      <Alert
        banner
        message={intl.formatMessage(
          {
            id: 'misc.optionsLimitReached',
            defaultMessage:
              'The maximum number of options ({ itemsLimit }) has been reached.',
          },
          { itemsLimit: maxOptionsCount }
        )}
        icon={<ExclamationMarkSquare size={18} />}
        className={classes.alert}
      />
    </div>
  );
};

export default LimitMessage;
