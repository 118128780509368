export enum Os {
  Macos,
}

const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'Mac'];

export default (os: Os) => {
  if (os === Os.Macos) {
    return macosPlatforms.some(
      platform => !!navigator?.userAgent?.includes(platform)
    );
  } else {
    throw new Error('unknown Os');
  }
};
