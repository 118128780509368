import { RootAction } from 'store/reducers';
import { Reducer } from 'redux';
import {
  SET_TOKENS,
  CLEAR_TOKENS,
  SET_USER_DETAILS,
} from '../constants/auth.consts';
import { AuthState } from 'utils/types/api/auth.types';

const initialState: AuthState = {
  refresh: undefined,
  access: undefined,
  userDetails: undefined,
};

const authReducer: Reducer<AuthState, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_TOKENS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CLEAR_TOKENS: {
      return initialState;
    }
    case SET_USER_DETAILS: {
      return {
        ...state,
        userDetails: action.payload,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
