import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import React from 'react';
import { useIntl } from 'react-intl';
import ChildClassFormBuilderHeader from '../ChildClassFormBuilderHeader';
import ChildClassFormBuilderNoPermission from '../ChildClassFormBuilderNoPremissionChildClass';
import { useChildClassWidgetNoPermissionStyles } from './styles';
import { ChildClassWidgetNoPermissionProps } from './types';

const ChildclassWidgetNoPermission = ({
  childClassLabel,
}: ChildClassWidgetNoPermissionProps) => {
  const widgetClasses = useChildClassWidgetNoPermissionStyles({});
  const intl = useIntl();

  return (
    <div className={widgetClasses.container}>
      <FormLabel dynamicSpacing className={widgetClasses.inputLabel}>
        <ChildClassFormBuilderHeader withIcon={false} label={childClassLabel} />
      </FormLabel>
      <ChildClassFormBuilderNoPermission
        overrideMessage={intl.formatMessage({
          id: 'misc.noViewPermission',
          defaultMessage:
            'You do not have permission to view this information.',
        })}
      />
    </div>
  );
};

export default ChildclassWidgetNoPermission;
