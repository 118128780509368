import {
  CHILD_FORM_PREVIEW_WRAPPER,
  FORM_PREVIEW_WRAPPER,
  TASK_FORM_WRAPPER,
} from 'utils/elementsIds';

const THRESHOLD_SPACE = 183;

const useFormPreview2Container = () => {
  return () => {
    // currently tasks will always be at the top of the stack so we can always check this first
    const taskFormElement = document.getElementById(TASK_FORM_WRAPPER);
    if (taskFormElement) {
      return taskFormElement;
    }
    const childFormElement = document.getElementById(
      CHILD_FORM_PREVIEW_WRAPPER
    );
    if (childFormElement) {
      return childFormElement;
    }

    const formElement = document.getElementById(FORM_PREVIEW_WRAPPER);
    const formBoundingRect = formElement?.getBoundingClientRect();
    const bodyBoundingRect = document.body?.getBoundingClientRect();

    if (!formElement || !formBoundingRect || !bodyBoundingRect)
      return document.body;

    const { height: formHeight } = formBoundingRect;
    const { height: bodyHeight } = bodyBoundingRect;

    if (formHeight > bodyHeight * 0.75 - THRESHOLD_SPACE) return formElement;

    return document.body;
  };
};

export default useFormPreview2Container;
