import { RootState } from 'store/reducers';
import { DocumentTemplate } from 'utils/types/api/documentTemplate.types';

export const selectDocumentTemplates = (state: RootState) => {
  return state.documentTemplates.allIds.map(id => {
    const documentTemplate = state.documentTemplates.byId[id];
    return {
      ...documentTemplate,
      key: documentTemplate.id,
    };
  });
};

export const totalDocumentTemplates = (state: RootState) => {
  return state.documentTemplates.total;
};

export const filteredDocumentTemplatesCount = (state: RootState) => {
  return state.documentTemplates.filtered;
};

export const getDocumentTemplatesColumns = (state: RootState) => {
  return state.documentTemplates.columns;
};

export const getDocumentTemplatesSelectedRow = (state: RootState) => {
  return state.documentTemplates?.selectedRow;
};

export const getDocumnetTemplateRow = (id: string | undefined) => (
  state: RootState
): DocumentTemplate | undefined => {
  if (!id) return;

  return state.documentTemplates.byId[id];
};
