export const SET_OBJECT_CLASS_PERMISSIONS_CLASS_OWNERS =
  'SET_OBJECT_CLASS_PERMISSIONS_CLASS_OWNERS';
export const SET_OBJECT_CLASS_PERMISSION_OWNERS_FETCHING =
  'SET_OBJECT_CLASS_PERMISSION_OWNERS_FETCHING';
export const SET_OBJECT_CLASS_PERMISSIONS_CLASS_OWNERS_OPTIONS =
  'SET_OBJECT_CLASS_PERMISSIONS_CLASS_OWNERS_OPTIONS';
export const SET_OBJECT_CLASS_PERMISSIONS_IS_REMOVING_HIMSELF =
  'SET_OBJECT_CLASS_PERMISSIONS_IS_REMOVING_HIMSELF';
export const SET_OBJECT_CLASS_SELECTED_PERMISSION_SET =
  'SET_OBJECT_CLASS_SELECTED_PERMISSION_SET';
export const CLEAR_OBJECT_CLASS_PERMISSION_SETS_ASSIGNEES =
  'CLEAR_OBJECT_CLASS_PERMISSION_SETS_ASSIGNEES';
export const SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES =
  'SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES';
export const SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES_FETCHING =
  'SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES_FETCHING';
export const SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES_OPTIONS =
  'SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES_OPTIONS';
