import React from 'react';
import { ActionMenuListProps } from './types';
import useActionMenuListStyles from './styles';
import { Menu, MenuItem } from 'components/lib/Menu';
import {
  ACTIONS_MENU_ITEM_TESTID,
  TABLE_ROW_ACTION_BUTTON_TESTID,
} from 'utils/testIds';
import { TABLE_ROW, TABLE_WRAPPER } from 'utils/elementsIds';
import clsx from 'clsx';

const MENU_ITEM_HEIGHT = 48;

const ActionMenuList: React.FC<ActionMenuListProps> = ({
  items,
  id,
  listClassName,
  containerId,
  tableId,
}) => {
  const containerHeight = containerId
    ? document.getElementById(containerId)?.getBoundingClientRect().height
    : undefined;

  const getRowPosition = () => {
    if (id === undefined) return { y: 0, x: 0 };

    const { left: contentX = 0, top: contentY = 0 } =
      document
        .getElementById(tableId ?? TABLE_WRAPPER)
        ?.offsetParent?.getBoundingClientRect() || {};

    const actionsButton = document.querySelector(
      `#${TABLE_ROW.replace(
        '{0}',
        id.toString()
      )} [data-testid=${TABLE_ROW_ACTION_BUTTON_TESTID}]`
    );

    const {
      left: buttonX = 0,
      top: buttonY = 0,
      width: buttonWidth = 0,
      height: buttonHeight = 0,
    } = actionsButton?.getBoundingClientRect() ?? {};

    const actionsHeight =
      (items.filter(({ hasPermission }) => hasPermission).length + 1) *
      MENU_ITEM_HEIGHT;

    const y = buttonY - contentY + buttonHeight / 2;

    return {
      x: buttonX - contentX + buttonWidth / 2,
      y,
      displayToBottom: containerHeight
        ? containerHeight - y > actionsHeight
        : document.body.clientHeight - y > actionsHeight,
    };
  };

  const classes = useActionMenuListStyles(getRowPosition());

  const onlyAllowedItems = items.filter(({ hasPermission }) => hasPermission);

  return onlyAllowedItems.length ? (
    <Menu
      selectable={false}
      className={clsx(classes.list, listClassName, {
        [classes.actionsWrapper]: id !== undefined,
      })}
    >
      {onlyAllowedItems.map(({ icon, title, action }) => (
        <MenuItem
          className={classes.listItem}
          key={title}
          onClick={action}
          data-testid={ACTIONS_MENU_ITEM_TESTID}
          data-actions-item={true}
        >
          {icon}
          <span
            className={classes.title}
            data-actions-item={true}
            data-text={title}
          >
            {title}
          </span>
        </MenuItem>
      ))}
    </Menu>
  ) : null;
};

export default ActionMenuList;
