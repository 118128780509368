import { ThemeState } from 'store/reducers/types/theme.types';

export const getRadioStyles = (theme: ThemeState) => ({
  width: '1.0625rem',
  height: '1.0625rem',
  borderColor: theme['checkbox-border-color'],
  borderWidth: '.0625rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  '&::after': {
    width: '100%',
    height: '100%',
    top: 'auto',
    left: 'auto',
    position: 'static',
    border: '.15625rem solid white',
    borderRadius: '100%',
  },
});
