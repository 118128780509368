import { TableDensity } from 'components/Table/enums';
import { getHeaderCellHeight } from 'components/Table/styles';
import { createUseStyles } from 'react-jss';

type UseResizerStylesProps = {
  density: TableDensity;
};

export const useResizerStyles = createUseStyles({
  resizer: ({ density }: UseResizerStylesProps) => ({
    cursor: 'col-resize',
    paddingLeft: '0px',
    marginLeft: 'auto',
    zIndex: 2,
    '&:after': {
      display: 'block',
      content: '""',
      width: 2,
      height: getHeaderCellHeight(density),
      background: '#ced5da',
    },
    '&.disabled': {
      cursor: 'default!important',
    },
  }),
});
