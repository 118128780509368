import React from 'react';
import { Formik } from 'formik';
import FormLabel from '../../../TaskTemplates/components/FormLabel';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonPrimary } from '../../../../components/lib/Button';
import useForgotPasswordForm from '../../hooks';
import TextInput from '../../../../components/Inputs/TextInput';
import useForgotPasswordFormStyles from './styles';
import RequestSentPage from '../../../../components/RequestSentPage';
import { LogoIcon } from '../../../../components/Icon';
import {
  BUTTON_SUBMIT_TESTID,
  FORGOT_PASSWORD_SUBTITLE_TESTID,
  FORGOT_PASSWORD_TITLE_TESTID,
} from 'utils/testIds';
import ErrorIcon from 'img/icons/exclamation-mark.svg';
import { ForgotPasswordFormProps } from './types';
import { FallbackLogoOptions } from 'components/Icon/types';

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  title,
  subtitle,
  buttonLabel,
  showErrorIcon,
}) => {
  const {
    initialValues,
    onSubmit,
    isSuccessResetPassword,
    validationSchema,
    rawValidationSchema,
  } = useForgotPasswordForm();
  const classes = useForgotPasswordFormStyles({});
  const intl = useIntl();

  if (isSuccessResetPassword)
    return (
      <RequestSentPage
        title={intl.formatMessage({
          id: 'resetPassword.requestSent',
          defaultMessage: 'Your request has been sent',
        })}
        subtitle={intl.formatMessage(
          {
            id: 'resetPassword.accountExists',
            defaultMessage:
              'If an account exists for {email}, we’ll send an email with a link to reset your password.',
          },
          { email: <b>{initialValues.email}</b> }
        )}
      />
    );

  return (
    <div className={classes.card}>
      <div className={classes.iconsWrapper}>
        <LogoIcon
          width={150}
          size={150}
          fallbackLogo={FallbackLogoOptions.NORMAL}
          className={classes.logo}
        />
        {showErrorIcon && (
          <img src={ErrorIcon} alt='Error icon' className={classes.errorIcon} />
        )}
      </div>
      <p className={classes.title} data-testid={FORGOT_PASSWORD_TITLE_TESTID}>
        {title ? (
          title
        ) : (
          <FormattedMessage
            id='resetPassword.title'
            defaultMessage='Forgotten your password?'
          />
        )}
      </p>
      <p
        className={classes.subtitle}
        data-testid={FORGOT_PASSWORD_SUBTITLE_TESTID}
      >
        {subtitle ? (
          subtitle
        ) : (
          <FormattedMessage
            id='resetPassword.subtitle'
            defaultMessage='Enter your email address to reset your password. We will send a link to the e-mail address provided, from which you will be able to reset your password.'
          />
        )}
      </p>
      <Formik {...{ initialValues, onSubmit, validationSchema }}>
        {({ handleSubmit, values, errors }) => (
          <>
            <FormLabel className={classes.input}>
              <FormattedMessage
                id='activateAccount.username'
                defaultMessage='Username'
              />
            </FormLabel>
            <TextInput
              name='email'
              type='email'
              maxLength={rawValidationSchema.email.max_length}
            />
            <span className={classes.description}>
              <FormattedMessage
                id='users.usernameHint'
                defaultMessage='This must be valid email'
              />
            </span>
            <div className={classes.buttonWrapper}>
              <ButtonPrimary
                onClick={() => handleSubmit()}
                disabled={Object.keys(errors).length > 0 || !values.email}
                data-testid={BUTTON_SUBMIT_TESTID}
              >
                {buttonLabel ? (
                  buttonLabel
                ) : (
                  <FormattedMessage id='misc.reset' defaultMessage='Reset' />
                )}
              </ButtonPrimary>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPasswordForm;
