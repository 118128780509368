import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import ActionMenuList from 'components/ActionMenu/ActionMenuList';
import { DownloadIcon, EditIcon, MenuIcon } from 'components/Icon';

import { ActionsMenuProps, RowActions } from '../types';
import get from 'lodash/get';

const ActionMenuItems = ({
  id,
  tableId,
  onActionMenuOptionClick,
  permissions,
  recordPermissions,
  hasDocumentFields,
  ...rest
}: ActionsMenuProps) => {
  const intl = useIntl();
  const hasEditPermission =
    (recordPermissions?.edit ?? permissions.edit) && hasDocumentFields;
  const hasViewPermission = recordPermissions?.view ?? permissions.view;
  const hasHistory = !!get(rest, 'generated_document', null);

  const menu = useMemo(
    () => [
      {
        title: intl.formatMessage({
          id: 'misc.generateAndDownload',
          defaultMessage: 'Generate and download document',
        }),
        action: () => onActionMenuOptionClick(RowActions.Download),
        icon: <DownloadIcon size={18} />,
        hasPermission: hasEditPermission || hasViewPermission,
      },
      {
        title: intl.formatMessage({
          id: 'misc.generateAndSave',
          defaultMessage: 'Generate and save to field',
        }),
        action: () => onActionMenuOptionClick(RowActions.Save),
        icon: <EditIcon size={18} />,
        hasPermission: hasEditPermission,
      },
      {
        title: intl.formatMessage({
          id: 'misc.viewDocumentHistory',
          defaultMessage: 'View document history',
        }),
        action: () => onActionMenuOptionClick(RowActions.History),
        icon: <MenuIcon size={18} />,
        hasPermission: hasHistory,
      },
    ],
    [
      hasHistory,
      hasEditPermission,
      hasViewPermission,
      intl,
      onActionMenuOptionClick,
    ]
  );

  return <ActionMenuList items={menu} {...{ id, tableId }} />;
};

export default ActionMenuItems;
