import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Location } from 'history';
import useBackToList from 'hooks/useBackToList';
import { ERROR } from './consts';

const useFormHeader = (defaultBackPath?: string, withParams?: boolean) => {
  const history = useHistory();
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const redirectUrl = useRef<Location | undefined | string>(undefined);
  const { generateBackPath } = useBackToList(withParams);
  const [isNavigateAway, setIsNavigateAway] = useState(false);

  const { pathname } = history.location;

  const toggleCancelModal = useCallback(
    (withoutRedirect?: boolean, disableNavigateAway?: boolean) => {
      if (!withoutRedirect) {
        redirectUrl.current = undefined;
      }

      if (!disableNavigateAway) setIsNavigateAway(!withoutRedirect);

      setIsCancelModalVisible(prevState => !prevState);
    },
    []
  );

  const handleBlockedNavigation = useCallback(
    (nextLocation: Location): boolean => {
      const { pathname: nextPathname } = nextLocation;

      if (pathname === nextPathname) return true;

      toggleCancelModal();

      redirectUrl.current = nextLocation;

      return false;
    },
    [pathname, toggleCancelModal]
  );

  const onDontSaveClick = useCallback(() => {
    if (redirectUrl.current) {
      history.push(redirectUrl.current);
    } else {
      // this will redirect you to list page even if you come from a link
      history.push(
        generateBackPath(
          defaultBackPath || pathname.slice(0, pathname.lastIndexOf('/'))
        )
      );
    }
  }, [defaultBackPath, generateBackPath, history, pathname]);

  return {
    onDontSaveClick,
    handleBlockedNavigation,
    toggleCancelModal,
    isCancelModalVisible,
    history,
    isNavigateAway,
    setIsNavigateAway,
  };
};

export default useFormHeader;

export const useSaveChangesModal = () => {
  const history = useHistory();

  const [navigateTo, setNavigateTo] = useState<string | undefined>();

  useEffect(() => {
    if (navigateTo && navigateTo !== ERROR) {
      history.replace(navigateTo);
    } else if (navigateTo === ERROR) {
      setNavigateTo(undefined);
    }
  }, [history, navigateTo]);

  const handleLocationChange = (location?: string) => setNavigateTo(location);

  return {
    navigateTo,
    handleLocationChange,
  };
};
