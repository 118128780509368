import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { TaskStatus } from 'utils/Enums/TaskStatus';

export const useStatus = (status: TaskStatus) => {
  const intl = useIntl();

  return useMemo(() => {
    switch (status) {
      case TaskStatus.Completed:
        return intl.formatMessage({
          id: 'tasksPanel.completed',
          defaultMessage: 'Completed',
        });
      case TaskStatus.Overdue:
        return intl.formatMessage({
          id: 'tasksPanel.overdue',
          defaultMessage: 'Overdue',
        });

      default:
        return intl.formatMessage({
          id: 'tasksPanel.outstanding',
          defaultMessage: 'Outstanding',
        });
    }
  }, [intl, status]);
};
