import React, { useCallback, useMemo } from 'react';
import { Modal } from 'components/lib/Modal';
import {
  ButtonPrimary,
  ButtonDanger,
  ButtonSecondaryOutlined,
} from 'components/lib/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { Space } from 'components/lib/Space';
import { CheckIcon, CloseIcon } from 'components/Icon';
import { useSelector } from 'react-redux';
import { isSavingPreferences } from 'store/selectors/preferencesSelectors';
import useModalStyles from './styles';
import { Text } from 'components/lib/Text';
import { ConfirmationModalProps } from './types';
import { convertHexToRGBA } from 'utils/functions/convertHextoRGBA';
import {
  MODAL_CANCEL_BUTTON_TESTID,
  MODAL_CONFIRM_BUTTON_TESTID,
  MODAL_DISCARD_BUTTON_TESTID,
  MODAL_HEADING_TESTID,
  MODAL_SUBTITLE_TESTID,
  MODAL_TESTID,
  MODAL_TITLE_TESTID,
} from 'utils/testIds';
import clsx from 'clsx';

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  visible,
  onCancel,
  onConfirm,
  onClose,
  image,
  customImage,
  title,
  subtitle,
  children,
  heading,
  cancelButtonLabel,
  confirmationButtonLabel,
  isDangerAction = false,
  cancelButtonIcon,
  confirmationButtonIcon,
  withCancel,
  additionalBodyContent,
  wider,
  confirmDisabled,
  className,
  isLoading,
}) => {
  const isSaving = useSelector(isSavingPreferences);
  const styles = useModalStyles({});
  const intl = useIntl();

  const confirmationButton = useMemo(() => {
    const ButtonComponent = isDangerAction ? ButtonDanger : ButtonPrimary;

    return (
      <ButtonComponent
        icon={
          confirmationButtonIcon ? (
            confirmationButtonIcon
          ) : (
            <CheckIcon size={12} />
          )
        }
        onClick={e => {
          e.stopPropagation();

          if (onConfirm) onConfirm();
        }}
        loading={isSaving || isLoading}
        data-testid={MODAL_CONFIRM_BUTTON_TESTID}
        disabled={confirmDisabled}
      >
        {confirmationButtonLabel
          ? confirmationButtonLabel
          : intl.formatMessage({
              id: 'misc.save',
              defaultMessage: 'Save',
            })}
      </ButtonComponent>
    );
  }, [
    isLoading,
    confirmationButtonIcon,
    confirmationButtonLabel,
    intl,
    isDangerAction,
    isSaving,
    onConfirm,
    confirmDisabled,
  ]);

  const onCloseClick = useCallback(
    e => {
      e.stopPropagation();

      if (!!onClose) onClose();
      if (!!onCancel) onCancel();
    },
    [onClose, onCancel]
  );

  return (
    <Modal
      {...{ visible, className }}
      footer={null}
      wrapClassName={clsx(styles.wrapper, {
        [styles.wider]: wider,
      })}
      maskStyle={{
        zIndex: 1100,
        backgroundColor: convertHexToRGBA('#202134', 16),
      }}
      destroyOnClose
      closable={false}
    >
      <div data-testid={MODAL_TESTID}>
        {!!heading && (
          <div className={styles['modal-header']}>
            <div
              className={styles['modal-header-content']}
              data-testid={MODAL_HEADING_TESTID}
            >
              <Text strong>{heading}</Text>
            </div>
          </div>
        )}
        <div className={styles['modal-content']}>
          <div className={styles['modal-body']}>
            <div className={styles['modal-body-container']}>
              {customImage ??
                (image && (
                  <img
                    src={image}
                    alt='Modal Illustration'
                    className={styles.modalImage}
                  />
                ))}
              <div className={styles['modal-body-section']}>
                {title && (
                  <Text
                    fontSize={18}
                    fontWeight={700}
                    data-testid={MODAL_TITLE_TESTID}
                  >
                    {title}
                  </Text>
                )}
                {subtitle && (
                  <Text
                    className={styles.overflowWrap}
                    fontSize={14}
                    data-testid={MODAL_SUBTITLE_TESTID}
                  >
                    {subtitle}
                  </Text>
                )}
                {additionalBodyContent}
              </div>
            </div>
            {children}
          </div>
          <div className={styles['modal-footer']}>
            <Space>
              {withCancel && (
                <ButtonSecondaryOutlined
                  onClick={onCloseClick}
                  disabled={isSaving}
                  data-testid={MODAL_CANCEL_BUTTON_TESTID}
                >
                  <FormattedMessage id='misc.cancel' defaultMessage='Cancel' />
                </ButtonSecondaryOutlined>
              )}
              {onCancel !== undefined && (
                <ButtonSecondaryOutlined
                  icon={cancelButtonIcon || <CloseIcon size={18} />}
                  onClick={onCancel}
                  disabled={isSaving}
                  data-testid={MODAL_DISCARD_BUTTON_TESTID}
                >
                  {cancelButtonLabel || (
                    <FormattedMessage
                      id='misc.discard'
                      defaultMessage='Discard'
                    />
                  )}
                </ButtonSecondaryOutlined>
              )}
              {confirmationButton}
            </Space>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
