import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

import { getIdentifier, panelWrapper } from 'styles/panelsStyles';

export const useViewerPanelStyles = createUseStyles((theme: ThemeState) => {
  return {
    panelWrapper,
    recordIdentifier: {
      ...getIdentifier(theme),
      marginBottom: '0.5rem',
    },
    fieldLabel: {
      color: theme['secondary-color'],
    },
    pdfViewContainer: {
      padding: '0.938rem 0 0 0',
      height: '100%',
      width: '100%',
    },
  };
});
