import clsx from 'clsx';
import { TableDensity } from 'components/Table/enums';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTableDensity } from 'store/selectors/preferencesSelectors';
import { useDisabledResizerStyles } from './styles';

export const DisabledResizer = () => {
  const density = useSelector(getTableDensity) ?? TableDensity.Default;
  const classes = useDisabledResizerStyles({ density });

  return (
    <div className={clsx([classes.resizer, { disabled: true }, 'resizer'])} />
  );
};
