import React from 'react';
import { TabPane, Tabs } from 'components/lib/Tabs';
import { useAssigneesPanelTabs } from './hooks';
import { useSelector } from 'react-redux';
import { getUserGroupPermissionsGetSelectedPermissionSet } from 'store/selectors/userGroupPermissionsSelectors';
import { useManageAssigneesTabsStyles } from './styles';
import { ManagementTabsProps, TabsKeys } from './types';
import TooltipString from 'components/TooltipString';
const ManagementTabs = ({ currentTab, setCurrentTab }: ManagementTabsProps) => {
  const classes = useManageAssigneesTabsStyles();
  const tabs = useAssigneesPanelTabs();
  const selectedPermissionSet = useSelector(
    getUserGroupPermissionsGetSelectedPermissionSet
  );
  return (
    <div className={classes.contentWrappper}>
      <div className={classes.name}>
        <TooltipString text={selectedPermissionSet?.name ?? ''} />
      </div>
      <Tabs
        className={classes.tabs}
        onTabClick={tab => {
          setCurrentTab(tab as TabsKeys);
        }}
        activeKey={currentTab}
      >
        {tabs.map(({ key, tabName, TabContent }) => (
          <TabPane key={key} tab={tabName}>
            <TabContent />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default ManagementTabs;
