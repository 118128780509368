import React from 'react';
import ConfirmationModal from 'components/ConfirmationModal';
import { CheckIcon } from 'components/Icon';
import { useIntl } from 'react-intl';
import WarningIllustration from 'img/illustrations/warning-triangle.svg';
import { CancelConfigurationModeProps } from './types';

const CancelConfigurationModal: React.FC<CancelConfigurationModeProps> = ({
  onConfirm,
  onCancel,
  isDangerAction,
  visible,
  customSubtitle,
  customConfirmationLabel,
  customTitle,
  customCancelLabel,
}) => {
  const intl = useIntl();

  return (
    <ConfirmationModal
      title={
        customTitle ??
        intl.formatMessage({
          id: 'misc.unsavedChangesLost',
          defaultMessage: 'Unsaved changes will be lost',
        })
      }
      subtitle={
        customSubtitle ??
        intl.formatMessage({
          id: 'misc.ifYouConfirmCancel',
          defaultMessage:
            'If you confirm you will lose your unsaved changes.\n Do you want to do this?',
        })
      }
      image={WarningIllustration}
      confirmationButtonIcon={<CheckIcon size={12} />}
      confirmationButtonLabel={
        customConfirmationLabel ??
        intl.formatMessage({
          id: 'misc.yes',
          defaultMessage: 'Yes',
        })
      }
      cancelButtonLabel={
        customCancelLabel ??
        intl.formatMessage({
          id: 'misc.goBack',
          defaultMessage: 'No, go back',
        })
      }
      {...{ onConfirm, onCancel, visible, isDangerAction }}
    />
  );
};

export default CancelConfigurationModal;
