/*
This is a global intl singleton that is used outside react components
It creates intl instance when first used, allowing redux to rehydrate store
*/

import { createIntl, createIntlCache, MessageDescriptor } from 'react-intl';
import AppLocale from './AppLocale';
import SupportedLanguages from 'utils/Enums/SupportedLanguages';
import { store } from 'store/store';
import moment from 'moment';
import { PreferencesTypes } from 'utils/types/api/preferences.types';

class GlobalIntlSingleton {
  private static instance: GlobalIntlSingleton;
  private _intl: ReturnType<typeof createIntl>;

  private constructor() {
    const selectedLang = store.getState().preferences.data[
      PreferencesTypes.GeneralPreferences
    ].language;
    const cache = createIntlCache();

    this._intl = createIntl(
      {
        locale: selectedLang || SupportedLanguages.enGB,
        messages: AppLocale[selectedLang || SupportedLanguages.enGB],
      },
      cache
    );
  }

  private static getInstance(): ReturnType<typeof createIntl> {
    if (!GlobalIntlSingleton.instance) {
      GlobalIntlSingleton.instance = new GlobalIntlSingleton();
    }
    return GlobalIntlSingleton.instance._intl;
  }

  static get intl(): ReturnType<typeof createIntl> {
    return this.getInstance();
  }

  static recreateInstance(): void {
    GlobalIntlSingleton.instance = new GlobalIntlSingleton();

    const selectedLang = store.getState().preferences.data[
      PreferencesTypes.GeneralPreferences
    ].language;
    moment.locale(selectedLang || SupportedLanguages.enGB);
  }

  /*
  this method is used to allow creating translated messages with dynamic keys
  such messages need to be created statically somewhere to allow extracting them
  using `extract-messages` see file src/lang/ApiTranslations.tsx
  */
  static dynamicFormatMessage(
    descriptor: MessageDescriptor,
    values?: Record<string, number | string>
  ): string | undefined {
    if (!GlobalIntlSingleton.instance) {
      return undefined;
    }

    return GlobalIntlSingleton.instance._intl.formatMessage(descriptor, values);
  }
}

export default GlobalIntlSingleton;
