import { createUseStyles } from 'react-jss';

const useInformationSectionStyle = createUseStyles({
  wrapper: {
    background: 'linear-gradient(270deg, #01042B 6.67%, #000001 87.6%)',
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  informationWrapper: {
    width: '75%',
    margin: 'auto',
  },
  title: {
    fontSize: 50,
    letterSpacing: '2px',
    color: '#FFFFFF',
    margin: 0,
    lineHeight: '50px',
  },
  subtitle: {
    color: '#FFFFFF',
    fontSize: 20,
    lineHeight: '27px',
    marginTop: 30,
  },
});

export default useInformationSectionStyle;
