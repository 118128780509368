import { createUseStyles } from 'react-jss';

const useCouldNotOpenFormStyles = createUseStyles({
  unsupportedFormWrapper: {
    padding: 25,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gridGap: 12,
  },
});

export default useCouldNotOpenFormStyles;
