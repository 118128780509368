import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  APPEND_USERS,
  RESET_USERS,
  RESET_USERS_COLUMNS,
  SET_USERS,
  SET_USERS_COLUMNS,
  SET_USERS_FETCHING,
  SET_USERS_RESTRICTIONS,
  SET_USERS_SELECTED_ROW,
} from 'store/constants/users.consts';
import {
  appendTableContent,
  resetTable,
  resetTableColumns,
  setSelectedRow,
  setTableColumns,
  setTableContent,
  setTableLoading,
} from './tablesCommonStateModificators';
import { CommonTableState } from 'utils/types/api/table.types';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import { User } from 'utils/types/api/users.types';

import cloneDeep from 'lodash/cloneDeep';

const initialState = cloneDeep(INITIAL_TABLE_STATE);

const usersReducer: Reducer<CommonTableState<User>, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_USERS: {
      return setTableContent<User, typeof SET_USERS>(state, action);
    }
    case APPEND_USERS:
      return appendTableContent<User, typeof APPEND_USERS>(state, action);
    case SET_USERS_FETCHING:
      return setTableLoading<User, typeof SET_USERS_FETCHING>(state, action);

    case RESET_USERS:
      return resetTable<User>(state, initialState);

    case RESET_USERS_COLUMNS:
      return resetTableColumns<User>(state, initialState);

    case SET_USERS_COLUMNS:
      return setTableColumns<User, typeof SET_USERS_COLUMNS>(state, action);
    case SET_USERS_SELECTED_ROW:
      return setSelectedRow<User, typeof SET_USERS_SELECTED_ROW>(state, action);
    case SET_USERS_RESTRICTIONS:
      return { ...state, restrictions: action.restrictions };
    default:
      return state;
  }
};

export default usersReducer;
