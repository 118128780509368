import { createStore, applyMiddleware } from 'redux';
import rootReducer, { RootState } from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { RESET_STORE } from './constants/store.consts';
import { getPersistConfig } from 'redux-deep-persist';

export const persistConfig = getPersistConfig({
  key: 'root',
  storage: storageSession,
  whitelist: [
    'auth.access',
    'auth.refresh',
    'auth.userDetails',
    'preferences.data.timezone',
    'preferences.data.dateFormat',
    'preferences.data.decimalFormat',
    'preferences.data.thousandSeparator',
    'preferences.data.language',
    'preferences.data.tableDensity',
  ],
  rootReducer,
});

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk as ThunkMiddleware<RootState>))
);

export const storeWithInitialState = (
  initialState: RecursivePartial<RootState>
) =>
  createStore(
    persistedReducer,
    //@ts-ignore
    { ...rootReducer(undefined, { type: RESET_STORE }), ...initialState },
    composeEnhancers(applyMiddleware(thunk as ThunkMiddleware<RootState>))
  );

export const persist = persistStore(store);
export type AppDispatch = typeof store.dispatch;
