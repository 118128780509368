import React from 'react';
import { Input } from 'reactstrap';
import { FieldProps } from '../defaultInputs/types';
import FBCheckbox from '../../FBCheckbox';
import Tooltip from '../../Tooltip';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNumberInputs } from './hooks';

// specify the inputs required for a number type object
export const CardNumberParameterInputs: React.FC<FieldProps> = ({
  parameters,
  onChange,
}) => {
  const intl = useIntl();
  const {
    onMultipleOfChange,
    onMinimumChange,
    onExlusiveMinimumCheckboxChange,
    onMaximumChange,
    onExclusiveMaximumCheckboxChange,
  } = useNumberInputs(parameters, onChange);

  return (
    <div>
      <h4>
        <FormattedMessage
          id='formBuilder.multipleOf'
          defaultMessage='Multiple of'
        />
        <Tooltip
          id={`${parameters.path}_multiple`}
          type='help'
          text={intl.formatMessage({
            id: 'formBuilder.requireMultipleHelp',
            defaultMessage: 'Require number to be a multiple of this number',
          })}
        />
      </h4>
      <Input
        value={parameters.multipleOf || ''}
        placeholder='ex: 2'
        key='multipleOf'
        type='number'
        onChange={onMultipleOfChange}
        className='card-modal-number'
      />
      <h4>
        <FormattedMessage id='formBuilder.minimum' defaultMessage='Minimum' />
      </h4>
      <Input
        value={parameters.minimum || parameters.exclusiveMinimum || ''}
        key='minimum'
        type='number'
        onChange={onMinimumChange}
        className='card-modal-number'
      />
      <div className='card-modal-boolean'>
        <FBCheckbox
          key='exclusiveMinimum'
          onChangeValue={onExlusiveMinimumCheckboxChange}
          isChecked={!!parameters.exclusiveMinimum}
          disabled={!parameters.minimum && !parameters.exclusiveMinimum}
          label={intl.formatMessage({
            id: 'formBuilder.exclusiveMinimum',
            defaultMessage: 'Exclusive minimum',
          })}
        />
      </div>
      <h4>
        <FormattedMessage id='formBuilder.maximum' defaultMessage='Maximum' />
      </h4>
      <Input
        value={parameters.maximum || parameters.exclusiveMaximum || ''}
        key='maximum'
        type='number'
        onChange={onMaximumChange}
        className='card-modal-number'
      />
      <div className='card-modal-boolean'>
        <FBCheckbox
          key='exclusiveMaximum'
          onChangeValue={onExclusiveMaximumCheckboxChange}
          isChecked={!!parameters.exclusiveMaximum}
          disabled={!parameters.maximum && !parameters.exclusiveMaximum}
          label={intl.formatMessage({
            id: 'formBuilder.exclusiveMaximum',
            defaultMessage: 'Exclusive maximum',
          })}
        />
      </div>
    </div>
  );
};

const numberInputs = {
  integer: {
    displayName: 'Integer',
    matchIf: [
      {
        types: ['integer'],
      },
      {
        types: ['integer'],
        widget: 'number',
      },
    ],
    defaultDataSchema: {},
    defaultUiSchema: {},
    type: 'integer',
    modalBody: CardNumberParameterInputs,
  },
  number: {
    displayName: 'Number',
    matchIf: [
      {
        types: ['number'],
      },
    ],
    defaultDataSchema: {},
    defaultUiSchema: {},
    type: 'number',
    modalBody: CardNumberParameterInputs,
  },
};

export default numberInputs;
