export enum MigrationError {
  UNSUPPORTED_SCHEMA_VERSION = 'UNSUPPORTED_SCHEMA_VERSION',
  CORRUPTED_SCHEMA = 'COULD_NOT_MIGRATE_SCHEMA',
}

export type MigrateFormDefinitionToSupportedVersionType = (
  schema: string | undefined,
  uiSchema: string | undefined
) => {
  schema?: string;
  uiSchema?: string;
  migrationError?: MigrationError;
};
