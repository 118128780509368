import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const getInputNumberStyles = (theme: ThemeState) => ({
  borderColor: theme['border-color-base'],
  width: '100%',
  '& .ant-input-number-handler-wrap': {
    display: 'none',
  },
  '& .ant-input-number-input': {
    paddingRight: '30px',
  },
  '& [class^=inputIconWrapper]': {
    display: 'none',
  },
});

export const getInputNumberWrapperStyles = (
  theme: ThemeState 
) => ({
  position: 'relative',
  userSelect: 'none',
  '& .ant-input-number-focused': {
    borderColor: `${theme['primary-color']} !important`,
    boxShadow: `${theme['input-focus-shadow']} !important`,
  },
  '& .ant-input-number:hover': {
    borderColor: `${theme['primary-color']} !important`,
  },
  '& .ant-input-number': {
    width: '100%',
  },
});

export const getArrowsWrapperStyles = (theme: ThemeState ) => ({
  width: 30,
  position: 'absolute',
  right: '0px',
  top: '0px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-evenly',
  cursor: 'pointer',
});

export const getArrowWrapperStyles = (theme: ThemeState ) => ({
  height: '50%',
  width: '25px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme['secondary-color'],
  padding: '1px 0px',
});

const useInputNumberStyles = createUseStyles(
  (theme: ThemeState ) => {
    return {
      inputNumber: getInputNumberStyles(theme),
      inputNumberWrapper: getInputNumberWrapperStyles(theme),
      arrowsWrapper: getArrowsWrapperStyles(theme),
      arrowWrapper: getArrowWrapperStyles(theme),
      topWrapper: {
        alignItems: 'flex-end',
      },
      bottomWrapper: {
        alignItems: 'flex-start',
      },
      previewStyle: {
        maxWidth: 200,
      },
      disabledInputNumberWrapper: {
        '& #arrows-wrapper': {
          pointerEvents: 'none',
        },
      },
    };
  }
);

export default useInputNumberStyles;
