import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTableColumnMetadata } from 'store/selectors/generalSelectors';
import TagContainer from 'components/TagContainer';
import { SelectOption } from 'utils/types/selectInput.types';
import { StateProps } from './types';
import { states } from './consts';

const State: React.FC<StateProps> = ({ stateId, column }) => {
  const { values = [] } = useSelector(getCurrentTableColumnMetadata)(column);

  const { text = '' } =
    values.find((item: SelectOption) => item.value === stateId) || {};

  return <TagContainer type={states[stateId]} name={stateId || text} />;
};

export default State;
