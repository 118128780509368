import { createUseStyles } from 'react-jss';

type UseSearchInputStylesProps = {
  icon?: string;
};

const useSearchInputStyles = createUseStyles({
  searchInput: {
    backgroundImage: ({ icon }: UseSearchInputStylesProps = {}) =>
      icon ? `url(${icon})` : 'none',
    paddingLeft: 34,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '11px 12px',
    backgroundPositionY: 'center',
    backgroundSize: 13,
    fontSize: 14,
    height: 26,
    minHeight: `26px !important`,
  },
});

export default useSearchInputStyles;
