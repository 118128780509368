const formatStringToKey = (text: string, lowercase = false) => {
  const generatedKey = text.trim().replace(/\W/g, '_');
  if (lowercase) return generatedKey.toLowerCase();

  return generatedKey;
};

export const onChangeFormatStringToKey = <
  T extends HTMLTextAreaElement | HTMLInputElement
>(
  onChange: ((e: React.ChangeEvent<T>) => void) | undefined
) => (newEvent: React.ChangeEvent<T>) => {
  if (!onChange) return;

  const valueBeforeChange = newEvent.target.value;

  newEvent.target.value = formatStringToKey(valueBeforeChange, true);
  onChange(newEvent);
};

export default formatStringToKey;
