import { DefaultPermissions } from './permissions.types';
import {
  ReminderNoticeTypes,
  TimeUnits,
  TaskResponseDataTypes,
  TaskTypes,
} from 'pages/TaskTemplates/enums';
import { OptionSelect, OptionAutocomplete } from '../selectInput.types';
import { TaskStatus } from 'utils/Enums/TaskStatus';
import { ConvertedTaskTemplateDetails } from 'pages/TaskTemplates/components/TaskForm/hooks/useTaskTemplateDetailsData/types';
import { CompletedBy } from 'pages/TaskTemplates/components/TaskForm/Lifecycle/sections/CompletionDate/enums';
import { ApiUserAvatar } from './users.types';
import { BaseUserInfo } from './BaseUserInfo.types';
import { FileDataFromApi } from 'components/lib/FileUpload/types';
import { ReturnedPluginConfig } from 'pages/TaskTemplates/components/TaskForm/types';
import { BaseFieldUsage } from './objectClassesFields.types';

export enum TaskCreationType {
  Manual = 'manual',
  Automatic = 'automatic',
}

export interface TaskTemplate {
  id: string;
  name: string;
  task_type: string;
  description: string;
  created_at: string;
  modified_at: string;
  resource_url: string;
  permissions: DefaultPermissions;
  action_with: number;
  created_by: ApiUserAvatar;
  last_task_created: Date | null;
  num_of_assigned: number;
  num_of_tasks_created: number;
  status: number;
  tags: string;
  task_group_templates: string[];
  task_creation: TaskCreationType;
}

export interface TaskTemplateUsage {
  num_of_sequences: number;
  sequences: BaseFieldUsage[];
  num_of_task_group_templates: number;
  task_group_templates: BaseFieldUsage[];
}

export interface Renewal {
  id: number;
  renewal_time_unit: TimeUnits;
  renewal_time_value: string | number;
}

export interface Reminder {
  id?: number;
  notice_type: ReminderNoticeTypes;
  time_unit: TimeUnits;
  time_value: number;
}

export interface FormSchema {
  data_schema: string;
  ui_schema: string;
}

export interface TaskTemplatesCommonFields
  extends Omit<TaskTemplate, 'resource_url'> {
  label: string;
  instructions: string;
  complete_by: CompletedBy;
  reminders: Reminder[];
}

export type TaskTemplateFormValues = ConvertedTaskTemplateDetails;

interface ApprovalConditions extends Omit<OptionSelect, 'values'> {
  name: Omit<OptionSelect, 'values'>;
}
export interface TaskTemplatesOptions {
  task_type: OptionSelect<TaskTypes>;
  data_type?: MappedObject<OptionSelect<TaskResponseDataTypes>>;
  tags: OptionAutocomplete;
  complete_by: OptionSelect;
  complete_by_time_unit: OptionSelect;
  expiry_time_rel_to: OptionSelect;
  expiry_time_unit: OptionSelect;
  expiry_time_value: OptionSelect;
  expiry: OptionSelect;
  approval_conditions: ApprovalConditions;
  renewals: {
    renewal_time_unit: OptionSelect;
    renewal_time_value: OptionSelect;
  };
  reminders: {
    notice_type: OptionSelect;
  };
  [TaskTypes.RecordUpdate]: {
    object_class: OptionSelect & OptionAutocomplete;
  };
}

export enum ResponseState {
  Accepted = 'accepted',
}

export enum NextTaskAction {
  View = 'view',
  Save = 'save',
  Complete = 'complete',
  TakeOwnership = 'take_ownership',
  ChangeOwnership = 'change_ownership',
  GiveUpOwnership = 'give_up_ownership',
}

export interface Task {
  id: string;
  name: string;
  task_type: string;
  object_record: number;
  object_name: string | null;
  status: TaskStatus;
  completed_by: ApiUserAvatar;
  current_owner: ApiUserAvatar | null;
  completed_at: Date | null;
  complete_by: Date | null;
  due_date: Date | null;
  created_at: Date;
  modified_at: Date;
  task_template: number;
  _meta: {
    permissions: {
      list: boolean;
      view: boolean;
      edit: boolean;
      complete: boolean;
      assign: boolean;
    };
  };
}

export enum StageNames {
  Stage1 = 'stage_1',
  Stage2 = 'stage_2',
}

export interface Stage {
  assignees: {
    users: ApiUserAvatar[];
  };
  owner: ApiUserAvatar | null;
  name: StageNames;
  status: TaskStatus;
}

export interface TaskDetails {
  id: string;
  configuration: {
    plugin_config: ReturnedPluginConfig;
  };
  completed_at?: string;
  completed_by?: BaseUserInfo;
  created_by: unknown;
  due_date: string;
  instructions: string;
  modified_by: unknown;
  name: string;
  object_record: number;
  status: TaskStatus;
  task_template: number;
  next_actions: NextTaskAction[];
  expiry_date: string | null;
  _meta: {
    permissions: {
      list: boolean;
      view: boolean;
      edit: boolean;
      complete: boolean;
    };
    labels: {
      // this looks exactly like TaskStatus but it has uppercase letters in statuses
      status: unknown;
    };
  };
  response: Nullable<{
    data: MappedObject<any>;
    id: number;
    modified_at: Date;
    modified_by: ApiUserAvatar;
    state: ResponseState;
    files: MappedObject<number[]>;
    _meta: { labels: { state: string; files: MappedObject<FileDataFromApi> } };
  }>;
  stages: Stage[];
}
