import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { ObjectClassField } from 'utils/types/api/objectClassesFields.types';

export const useCodeNameTextBox = (name: string, isDisabled?: boolean) => {
  const [textBoxEdited, setTextBoxEdited] = useState(isDisabled || false);
  const { setFieldValue } = useFormikContext<ObjectClassField>();

  const onTextBoxManualChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (!textBoxEdited) setTextBoxEdited(true);

    setFieldValue(name, value);
  };

  const resetIsTextBoxEdited = () => setTextBoxEdited(false);

  return {
    onTextBoxManualChange,
    textBoxEdited,
    resetIsTextBoxEdited,
  };
};
