import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useListStyles = createUseStyles((theme: ThemeState) => {
  return {
    listElementWrapper: {
      display: 'flex',
      backgroundColor: '#fff',
      marginBottom: 5,
      color: theme['text-color'],
      border: `1px solid ${theme['border-color-base']}`,
      padding: '8px 12px',
      borderRadius: 5,
      position: 'relative',
    },
    listWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      maxWidth: '100%',
    },
  };
});

export default useListStyles;
