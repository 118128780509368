import React from 'react';
import { Loader } from 'components/lib/Loader';
import { FileViewerDisplayProps } from './FileViewerDisplay.types';
import { useFileViewerDisplayStyles } from './FileViewerDisplay.styles';
import { useFileViewerDisplay } from './hooks';

/**
 * Utilizes PSPDFKit to load and display provided documents.
 */
export const FileViewerDisplay = ({
  fileUrl,
  fileName,
}: FileViewerDisplayProps) => {
  const styles = useFileViewerDisplayStyles();
  const { isLoading, containerRef } = useFileViewerDisplay(fileUrl, fileName);

  return (
    <>
      {isLoading && (
        <div key='spinner' className={styles.loadingSpinnerContainer}>
          <Loader size='large' />
        </div>
      )}
      {/* containerRef has to be present in virtual DOM the entire time pspdfkit loads the 
      document so we just render both the spinner and document host on each other */}
      <div className={styles.fileViewerHost} ref={containerRef} />
    </>
  );
};
