import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useCheckboxesWidgetStyles = createUseStyles((theme: ThemeState) => {
  return {
    checkboxWrapper: {
      '& .ant-checkbox-group': {
        '& > span:not(:last-child) .ant-checkbox-wrapper': {
          marginBottom: 10,
        },
        '& .ant-checkbox': {
          marginRight: 5,
        },
        '& .ant-checkbox-inner': {
          height: 20,
          width: 20,
          borderColor: theme['checkbox-border-color'],
          borderWidth: 1.5,
          '&:after': {
            left: 4,
          },
          '&.ant-checkbox-checked': {
            backgroundColor: theme['primary-color'],
            borderColor: theme['primary-color'],
          },
        },
      },
    },
    checkboxGroup: {
      '& .ant-checkbox-group-item': {
        display: 'block',
        marginBottom: 10,
      },
    },
  };
});

export default useCheckboxesWidgetStyles;
