export const predicateValuesMapFunction = (
  value: string | { value: string }
) => {
  if (typeof value === 'string') {
    return value.replaceAll(',', '\\,');
  }
  return value.value;
};

type SortFunctionElement = { order: number | undefined };
export const sortFunction = (a: SortFunctionElement, b: SortFunctionElement) =>
  (a.order ?? 0) < (b.order ?? 0) ? -1 : 1;
