import { createUseStyles } from 'react-jss';
import defaultTheme from 'utils/defaultTheme';

const theme = defaultTheme

const useExpiredLinkPageStyles = createUseStyles({
  icon: {
    display: 'block',
    margin: 'auto',
    padding: 0,
    height: 150,
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    margin: '20px 0',
    lineHeight: '24.5px',
    fontWeight: 700,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '18px',
    opacity: 0.6,
    textAlign: 'center',
  },
  link: {
    color: theme['tertiary-tag-font-color'],
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

export default useExpiredLinkPageStyles;
