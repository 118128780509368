import { createUseStyles } from 'react-jss';

type UseInPlaceEditTextInputStylesProps = {
  actionButtonOnTop?: boolean;
  maxWidth?: string | number;
  isEditMode?: boolean;
};

const useInPlaceEditTextInputStyles = createUseStyles({
  row: ({
    actionButtonOnTop,
    maxWidth,
  }: UseInPlaceEditTextInputStylesProps) => ({
    display: 'flex',
    alignItems: actionButtonOnTop ? 'flex-start' : 'center',
    marginTop: 1,
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    maxWidth: maxWidth || 750,
    '& .ant-spin-spinning': {
      alignSelf: actionButtonOnTop ? 'flex-start' : 'center',
    },
  }),
  fieldWrapper: {
    position: 'relative',
  },
  previewWrapper: ({
    maxWidth,
    isEditMode,
  }: UseInPlaceEditTextInputStylesProps) => ({
    maxWidth: maxWidth || 750,
    visibility: isEditMode ? 'hidden' : 'initial',
    display: 'flex',
    flexDirection: 'column',
  }),
});

export default useInPlaceEditTextInputStyles;
