import { useMemo } from 'react';
import { useBoolPredicates } from './hooks/useBoolPredicates';
import { useDateTimePredicates } from './hooks/useDateTimePredicates';
import { useEnumPredicates } from './hooks/useEnumPredicates';
import { useNumberPredicates } from './hooks/useNumberPredicates';
import { useSetPredicates } from './hooks/useSetPredicates';
import { useStringPredicates } from './hooks/useStringPredicates';
import { useUserPredicates } from './hooks/useUserPredicates';
import { Predicate } from './Predicate/types';

//predicates object is a hook to allow dynamic language changes
const usePredicates = (): MappedObject<MappedObject<Predicate>> => {
  const textPredicates = useStringPredicates();
  const numberPredicates = useNumberPredicates();
  const dateTimePredicates = useDateTimePredicates();
  const enumPredicates = useEnumPredicates();
  const setPredicates = useSetPredicates();
  const boolPredicates = useBoolPredicates();
  const userPredicates = useUserPredicates();

  return useMemo(
    () => ({
      string: textPredicates,
      int: numberPredicates,
      datetime: dateTimePredicates,
      date: dateTimePredicates,
      enum: enumPredicates,
      set: setPredicates,
      bool: boolPredicates,
      user: userPredicates,
      url: textPredicates,
      phone: textPredicates,
      email: textPredicates,
      float: numberPredicates,
      json: {},
      owners: {},
      uuid: {},
    }),
    [
      textPredicates,
      numberPredicates,
      dateTimePredicates,
      enumPredicates,
      setPredicates,
      boolPredicates,
      userPredicates,
    ]
  );
};

export default usePredicates;
