import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useTaskDisplayStyles = createUseStyles((theme: ThemeState) => {
  return {
    loader: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loaderWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      color: theme['text-color'],
      overflow: 'auto',
      '& > .ant-spin-container': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minWidth: 800,
      },
    },
    formContent: {
      flex: 1,
      padding: '35px 28px',
    },
    formFooter: {
      padding: '30px 12px 30px 28px',
      display: 'flex',
      justifyContent: 'flex-end',
      borderTop: `1px solid ${theme['border-color-base']}`,
    },
    recordId: {
      fontWeight: 'bold',
      marginBottom: 22,
    },
    taskTitle: {
      fontWeight: 600,
      maxWidth: 330,
      fontSize: 16,
      flex: 1,
    },
    taskInfo: {
      display: 'flex',
    },
    taskIdWrapper: {
      marginLeft: 20,
      display: 'flex',
      paddingTop: 1,
    },
    taskIdValueWrapper: {
      width: 70,
      marginLeft: 15,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-block',
    },
    statusWrapper: {
      marginTop: -1,
    },
    instructions: {
      marginTop: 27,
      lineHeight: 1.5,
      textAlign: 'justify',
    },
    taskFormWrapper: {
      marginTop: 25,
      '& #formBody': {
        margin: 0,
        maxHeight: '100%',
      },
    },
    formFooterSection: {
      display: 'flex',
      padding: '5px 16px 5px 16px',
      '& button:first-child': {
        marginRight: 16,
      },
    },
    sectionDivider: {
      borderRight: `1px solid ${theme['border-color-base']}`,
    },
  };
});

export default useTaskDisplayStyles;
