import { createUseStyles } from 'react-jss';

const useClassFieldCheckboxStyles = createUseStyles(() => ({
  checkbox: {
    marginBottom: 20,
    display: 'flex !important',
    height: 20,
    'ant-checkbox + span': {
      overflow: 'hidden',
    },
  },
}));

export default useClassFieldCheckboxStyles;
