import { StatusCodes } from 'http-status-codes';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { getSelectedObjectClassId } from 'store/selectors/preferencesSelectors';
import { apiCall } from 'utils/api';
import { OBJECT_CLASS_DETAILS } from 'utils/endpoints';

const useCheckAccessToSelectedObjectClass = () => {
  const selectedObjectClassId = useSelector(getSelectedObjectClassId);

  const checkAccessToSelectedObjectClassId = useCallback(async () => {
    try {
      const { status } = await apiCall.get(
        generatePath(OBJECT_CLASS_DETAILS, {
          id: selectedObjectClassId,
        })
      );

      return status === StatusCodes.OK;
    } catch {
      return false;
    }
  }, [selectedObjectClassId]);

  return {
    checkAccessToSelectedObjectClassId,
  };
};

export default useCheckAccessToSelectedObjectClass;
