import React from 'react';
import clsx from 'clsx';
import useInputStyles from 'components/lib/Input/styles';
import useInputNumberStyles from 'components/lib/InputNumber/styles';
import MinMaxInfo from 'components/MinMaxInfo';
import StandardFieldWrapper from 'components/FormPreview2/components/StandardFieldWrapper';
import { InputNumber } from 'components/lib/InputNumber';
import { FormLabelWithToolTip } from 'pages/TaskTemplates/components/FormLabel';
import { FormItem } from 'components/lib/Form';
import useCustomWidgetsStyles from '../../customWidgetsStyles';
import fixValue from 'utils/functions/fixValue';
import { Widget } from 'alx-dynamic-form';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import PropertyWidget from '../../PropertyWidget';

const CustomNumberWidget: Widget = ({
  value,
  onChange,
  errors,
  description,
  min,
  max,
  isProperty,
  required,
  label,
  multipleOf,
  reValidateField,
  disabled,
  fieldAlias,
}) => {
  const inputNumberClasses = useInputNumberStyles({});
  const classes = useInputStyles({});
  const widgetClasses = useCustomWidgetsStyles({});

  if (isProperty) {
    return (
      <Provider store={store}>
        <PropertyWidget value={value as string} {...{ label }} />
      </Provider>
    );
  }

  // number input requires validating on change because it automatically corrects value
  const handleOnChange = (value: number | string | undefined) => {
    if (value === undefined || isNaN(value as number)) {
      onChange(null);
      reValidateField();

      return;
    }

    onChange(
      multipleOf !== null
        ? fixValue(parseFloat(value as string), multipleOf)
        : value
    );
    reValidateField();
  };

  return (
    <>
      <FormLabelWithToolTip
        dynamicSpacing
        {...{ required }}
        className={clsx([
          widgetClasses.inputLabel,
          widgetClasses.inputLabelEllipsis,
        ])}
        label={label}
      />
      <FormItem
        dynamicSpacing
        validateStatus={errors?.length ? 'error' : undefined}
      >
        <StandardFieldWrapper {...{ description, errors }}>
          <div
            className={clsx([
              classes.input,
              inputNumberClasses.inputNumber,
              inputNumberClasses.previewStyle,
              'numericInputContainer',
            ])}
          >
            <InputNumber
              type='number'
              data-testid={`number-input-${fieldAlias}`}
              value={value as number}
              onChange={handleOnChange}
              max={max ?? undefined}
              min={min ?? undefined}
              onBlur={reValidateField}
              {...{ disabled }}
            />
          </div>
          <MinMaxInfo minimum={min ?? undefined} maximum={max ?? undefined} />
        </StandardFieldWrapper>
      </FormItem>
    </>
  );
};

export default CustomNumberWidget;
