import { Reducer } from 'redux';
import {
  SET_TASK_ASSIGNEES,
  SET_TASK_STAGE_ASSIGNEES,
} from 'store/constants/tasksAssignees.consts';
import { RootAction } from '.';
import { TasksAssigneesState } from './types/tasksAssignees.types';

const initialState: TasksAssigneesState = {};

const taskAssigneesReducer: Reducer<TasksAssigneesState, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_TASK_ASSIGNEES:
      const {
        payload: { taskId, stages },
      } = action;
      return {
        ...state,
        [taskId]: stages,
      };

    case SET_TASK_STAGE_ASSIGNEES:
      const {
        payload: { taskId: taskID, stageId, assignees, name, status, owner },
      } = action;
      const newStage = { name, assignees, status, owner };
      const taskStages = [...state[taskID]];
      const changeIndex = taskStages.findIndex(({ name }) => name === stageId);
      if (changeIndex >= 0) {
        taskStages[changeIndex] = newStage;
        return {
          ...state,
          [taskID]: taskStages,
        };
      } else {
        return {
          ...state,
          [taskID]: [...taskStages, newStage],
        };
      }
    default:
      return state;
  }
};

export default taskAssigneesReducer;
