import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useFileViewerLauncherStyles = createUseStyles(
  (theme: ThemeState) => {
    return {
      fileViewerLauncher: {
        display: 'block',

        color: theme['primary-color'],

        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',

        cursor: 'pointer',

        '&:hover': {
          textDecoration: 'underline',
        },
      },
    };
  }
);
