import GlobalIntlSingleton from '../../providers/IntlProviderWrapper/globalIntlSingleton';

export const getFieldNameTranslation = (
  formPrefix: string,
  fieldName: string
) => {
  return (
    GlobalIntlSingleton.dynamicFormatMessage({
      id: `${formPrefix}.form.${fieldName}`,
    }) || fieldName
  ).toLowerCase();
};
