import React from 'react';
import { ComponentsDictionary, ExternalComponent } from './types';
import WindowRefWrapper from './WindowRefWrapper';
import { useLocation } from 'react-router';
import { checkIsVisibleComponent } from './utils';

interface ExternalWindowsProps {
  components: ExternalComponent[];
  componentsDictionary: ComponentsDictionary;
  onCloseWindow: (id: string) => void;
  onReatachWindow: (id: string) => void;
}

const ExternalWindows = ({
  components,
  componentsDictionary,
  onCloseWindow,
  onReatachWindow,
}: ExternalWindowsProps) => {
  const { pathname } = useLocation();

  return (
    <>
      {components.map(({ componentName, id }) => {
        const { Component, availablePaths } = componentsDictionary[
          componentName
        ] || {
          Component: undefined,
          availablePaths: [],
        };

        if (!Component) return null;

        const isVisible = checkIsVisibleComponent(availablePaths, pathname);

        return (
          <WindowRefWrapper
            key={id}
            {...{ id, isVisible, onCloseWindow, onReatachWindow, Component }}
          />
        );
      })}
    </>
  );
};

export default ExternalWindows;
