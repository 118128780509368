import React from 'react';
import { Switch as AntSwitch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';
import useSwitchStyles from './styles';
import { Switch as FSwitch, SwitchProps as FSwitchProps } from 'formik-antd';
import clsx from 'clsx';
import { SWITCH_BUTTON_TESTID } from 'utils/testIds';

export enum LabelPosition {
  left,
  right,
  top,
}
interface LabeledSwitchProps extends SwitchProps {
  label: string;
  position?: LabelPosition;
  wrapperClassName?: string;
  boldLabel?: boolean;
  dataTestId?: string;
}

type FormikLabeledSwitchProps = FSwitchProps & LabeledSwitchProps;

export const Switch: React.FC<SwitchProps> = ({ className, ...rest }) => {
  const classes = useSwitchStyles({});

  return <AntSwitch {...rest} className={`${classes.switch} ${className}`} />;
};

export const FormikSwitch: React.FC<FSwitchProps> = ({
  className,
  name,
  ...rest
}) => {
  const classes = useSwitchStyles({});

  return (
    <FSwitch
      {...{ ...rest, name }}
      className={`${classes.switch} ${className}`}
      data-testid={`${SWITCH_BUTTON_TESTID}${name}`}
    />
  );
};

const SwitchLabel: React.FC<React.HTMLAttributes<HTMLSpanElement>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <span {...rest} className={`${className || ''}`}>
      {children}
    </span>
  );
};

export const LabeledSwitch: React.FC<LabeledSwitchProps> = ({
  label,
  position = LabelPosition.right,
  wrapperClassName,
  ...rest
}) => {
  const classes = useSwitchStyles({ position });

  return (
    <div className={`${classes.switchWrapper} ${wrapperClassName || ''}`}>
      <SwitchLabel className={classes.label}>{label}</SwitchLabel>
      <Switch {...rest} />
    </div>
  );
};

export const FormikLabeledSwitch: React.FC<FormikLabeledSwitchProps> = ({
  label,
  position = LabelPosition.left,
  wrapperClassName,
  boldLabel,
  dataTestId,
  ...rest
}) => {
  const classes = useSwitchStyles({ position });

  return (
    <div
      className={clsx([classes['switchWrapper'], wrapperClassName])}
      data-testid={dataTestId}
    >
      <SwitchLabel
        className={clsx([classes.label, { [classes['boldLabel']]: boldLabel }])}
      >
        {label}
      </SwitchLabel>
      <FormikSwitch {...rest} />
    </div>
  );
};
