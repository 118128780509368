import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseInPlaceEditUrlStylesProps = {
  errorMessage?: boolean;
};

const useInPlaceEditUrlStyles = createUseStyles((theme: ThemeState) => {
  return {
    singleLinePreview: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      textOverflow: 'ellipsis',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    urlInputWrapper: {
      padding: [0, 2],
      '& .ant-spin': {
        right: -20,
        top: 4,
        position: 'absolute',
      },
    },
    urlIcon: {
      position: 'absolute',
      top: 2.5,
      zIndex: 1,
      right: 5,
      color: theme['primary-color'],
    },
    closeIcon: {
      marginTop: '8px',
    },
    dropdown: {
      width: 90,
    },
    inPlaceEdit: ({ errorMessage }: UseInPlaceEditUrlStylesProps = {}) => ({
      '& .ant-input-wrapper': {
        border: `${!!errorMessage ? '1px solid red' : ''}`,
      },
      '& .ant-input': {
        height: '25px',
        paddingRight: 30,
        textOverflow: 'ellipsis',
        borderLeftColor: `${
          !!errorMessage ? theme['error-color'] : theme['primary-color']
        }`,
        '&:hover, &:focus': {
          borderColor: `${
            !!errorMessage ? theme['error-color'] : theme['primary-color']
          }`,
          boxShadow: `0px 0px 0px 1px ${
            !!errorMessage ? theme['error-color'] : theme['primary-color']
          }`,
        },
      },
      '& .ant-select:not(.ant-select-disabled):hover .ant-select-selector': {
        borderColor: `${
          !!errorMessage ? theme['error-color'] : theme['primary-color']
        } !important`,
        borderWidth: `${!!errorMessage ? '0px' : '1px'} !important`,
      },
      '& .ant-select': {
        border: 'none !important',
        '&:hover, &:focus': {
          borderColor: `${
            !!errorMessage ? theme['error-color'] : theme['primary-color']
          }`,
        },
      },
      '& .ant-select-selector': {
        top: '2px',
        height: '25px !important',
        border: 'none !important',
        boxShadow: 'none !important',
      },
      '& .ant-select-selection-item': {
        lineHeight: '25px !important',
        border: 'none !important',
      },
      '& .ant-select-selection-search': {
        lineHeight: '25px',
      },
    }),
    dropdownWrapper: ({ errorMessage }: UseInPlaceEditUrlStylesProps = {}) => ({
      borderRadius: 3,
      width: 350,
      height: 25,
      '&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector': {
        height: 25,
        border: 'none',
      },
      '&.ant-select-open': {
        borderColor: `${
          !!errorMessage ? theme['error-color'] : theme['primary-color']
        }`,
        boxShadow: `0px 0px 0px 1px ${
          !!errorMessage ? theme['error-color'] : theme['primary-color']
        }`,
        borderRadius: 3,
      },
      '& .ant-input-group-addon': {
        backgroundColor: '#FFF',
      },
      '&.ant-select-single .ant-select-selection-item': {
        fontSize: 14,
        lineHeight: 2,
        color: theme['heading-color'],
      },
    }),
    dropdownOptionsWrapper: {
      '& .ant-select-item-option-selected:not(.ant-select-item-option-disabled)': {
        backgroundColor: `${theme['disabled-ant-bg-color']}`,
        color: `${theme['primary-color']}`,
      },
      '& .ant-select-item': {
        fontSize: 14,
        lineHeight: 1.3,
        minHeight: 30,
        alignItems: 'center',
      },
    },
    dropdownOptionsWithError: {
      '& .rc-virtual-list': {
        boxShadow: 'rgb(46 63 87 / 20%) 0px 2px 4px',
        paddingBottom: 4,
      },
    },
    dropdownError: {
      boxShadow: 'none',
      fontSize: 12,
      lineHeight: 1.3,
      padding: '10px 8px 4px',
    },
    dropdownOverlay: {
      zIndex: 1060,
    },
  };
});
export default useInPlaceEditUrlStyles;
