import React, { createContext, useContext, useState } from 'react';
import noop from 'lodash/noop';
import { HelmetContextType } from './types';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

const HelmetContext = createContext<HelmetContextType>({
  pageTitle: '',
  setPageTitle: noop,
});

export const HelmetContextProvider = HelmetContext.Provider;
export const useHelmetContext = () => useContext(HelmetContext);

export const HelmentContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const intl = useIntl();
  const [pageTitle, setPageTitle] = useState(
    intl.formatMessage({
      id: 'misc.autologyx',
      defaultMessage: 'Autologyx',
    })
  );

  return (
    <HelmetContextProvider
      value={{
        pageTitle,
        setPageTitle,
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {children}
    </HelmetContextProvider>
  );
};
