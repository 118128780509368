import { DraggingStyle } from 'react-beautiful-dnd';
import { calcTransformTranslate } from './calcTransformTranslate';

export const generateDraggableTransform = (
  isDragging: boolean,
  style?: DraggingStyle,
  topOffset?: number,
  leftOffset?: number,
  bottomOffset?: number
) => {
  const { transform } = style || {};

  let targetTransform = {
    transform,
  };

  if (isDragging) {
    const limitedTransformTranslate = calcTransformTranslate(
      style,
      topOffset,
      leftOffset,
      bottomOffset
    );

    if (limitedTransformTranslate !== undefined) {
      targetTransform = limitedTransformTranslate;
    }
  }

  return targetTransform;
};
