import { createContext, useContext } from 'react';
import {
  ActivateAccountContextFields,
  ActivateAccountData,
} from '../../types';
import { PasswordRulesOptions } from 'components/PasswordRules/types';

const ActivateAccountContext = createContext<ActivateAccountContextFields>({
  data: {} as ActivateAccountData,
  options: {} as PasswordRulesOptions,
});

export const ActivateAccountProvider = ActivateAccountContext.Provider;
export const useActivateAccountContext = () =>
  useContext(ActivateAccountContext);
