import { CustomTableGroupKeys } from 'contexts/types';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { ColumnsMetadata } from 'utils/types/api/table.types';
import { idField, nameFields } from './consts';
import { FieldWithAliasAndLabel } from './types';

export const getFiledsWithLabels = (
  fields: string[],
  columns: MappedObject<ColumnsMetadata>,
  tableName?: string
) =>
  fields.map(key => {
    const column = columns[key];

    if (column.groupKey && column.groupKey !== CustomTableGroupKeys.Default) {
      return {
        ...column,
        label: column?.label ?? '',
      };
    }

    return {
      ...column,
      label:
        GlobalIntlSingleton.dynamicFormatMessage({
          id: `${tableName}.${column.alias}`,
        }) ?? column.alias,
    };
  });

export const sortColumnFields = <T extends FieldWithAliasAndLabel>(
  a: T,
  b: T
) => {
  if (a.alias === idField) return -1;
  if (b.alias === idField) return 1;
  if (nameFields.includes(a.alias)) return -1;
  if (nameFields.includes(b.alias)) return 1;
  if (a.label < b.label) return -1;
  if (a.label > b.label) return 1;
  return 0;
};
