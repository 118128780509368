import CancelConfigurationModal from 'components/CancelConfigurationModal';
import CustomPrompt from 'components/CustomPrompt';
import { CustomPromptType } from 'components/CustomPrompt/types';
import { useSaveChangesModal } from 'components/FormHeader/hooks';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { preventDefault } from 'utils/functions/preventDefault';
import { TaskFormType } from './hooks/types';
import { Location } from 'history';
import { CancelCreationModalsProps } from './types';
import { useTasksPanelContext } from '../TasksPanelModeContext';
import { TasksPanelMode } from '../types';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import { useDispatch } from 'react-redux';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { useConfirmationModalContext } from 'contexts/ConfirmationModalContext';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import usePanels from 'hooks/usePanels';

const CancelCreationModals: React.FC<CancelCreationModalsProps> = ({
  isCancelModalVisible,
  toggleCancelModal,
  isNavigateAway,
}) => {
  const dispatch = useDispatch();
  const { dirty } = useFormikContext<TaskFormType>();
  const { navigateTo, handleLocationChange } = useSaveChangesModal();
  const { setMode } = useTasksPanelContext();
  const {
    setShouldBeDisplayed,
    setStoredModalFunctions,
  } = useConfirmationModalContext();
  const {
    globalMethods: { closeComponent },
  } = useFlexLayoutContext();
  const { togglePanelsPreferences } = usePanels();

  const onCancelCustomPrompt = (navigateTo?: Location) => {
    if (navigateTo)
      handleLocationChange(`${navigateTo.pathname}${navigateTo.search}`);
  };

  const handleConfirm = () => {
    if (isNavigateAway) {
      closeComponent(FlexLayoutWindows.Tasks);
      togglePanelsPreferences(FlexLayoutWindows.Tasks, true);
    } else setMode(TasksPanelMode.List);
    toggleCancelModal();
  };

  useEffect(() => {
    // prevent "Create record" clicking or "select class" changing
    dispatch(setSidebarData(FlexLayoutWindows.Tasks, { dirty }));

    // prevent "X" clicking on panel tab
    setShouldBeDisplayed(FlexLayoutWindows.Tasks, dirty);
    setStoredModalFunctions(FlexLayoutWindows.Tasks, {
      callback: toggleCancelModal,
      preventCloseTab: dirty ? undefined : () => setMode(TasksPanelMode.List),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty]);

  useEffect(() => {
    // reset all on unmount
    return () => {
      setShouldBeDisplayed(FlexLayoutWindows.Tasks, false);
      dispatch(setSidebarData(FlexLayoutWindows.Tasks, { dirty: false }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useBeforeunload(e => dirty && preventDefault(e));

  return (
    <>
      <CancelConfigurationModal
        visible={isCancelModalVisible && dirty}
        onCancel={toggleCancelModal}
        onConfirm={handleConfirm}
      />
      <CustomPrompt
        when={!navigateTo && dirty}
        onCancel={onCancelCustomPrompt}
        type={CustomPromptType.CANCEL}
      />
    </>
  );
};

export default CancelCreationModals;
