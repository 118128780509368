import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import { SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_EXPANDED_WIDTH } from 'utils/consts';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { useSelector } from 'react-redux';
import { getOpenedPanels } from 'store/selectors/preferencesSelectors';
import { useViewMenuContext } from './context/ViewMenuContext';
import { ViewMenuValues } from './types';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import usePanels from 'hooks/usePanels';
import { checkIsVisibleComponent } from 'components/lib/FlexLayout/utils';
import { getSidebarData } from 'store/selectors/flexLayoutSelectors';
import noop from 'lodash/noop';
import routes from 'utils/routingPaths';
import useNestedRecordsDrawer from 'hooks/useNestedRecordsDrawer';

export const useSideMenu = () => {
  const history = useHistory();

  const onSubmenuClick = (redirectRoute?: string) => () => {
    if (redirectRoute) {
      history.push(redirectRoute);
    }
  };

  return { onSubmenuClick };
};

export const useActiveMenuKey = () => {
  const { pathname } = useLocation();

  const routeParts = pathname.split('/').filter(Boolean);

  return useMemo(() => {
    if (routeParts.length === 0) {
      return ['/'];
    }

    return routeParts.reduce(
      (activeKeys, routePart) => [
        ...activeKeys,
        `${activeKeys[activeKeys.length - 1]}/${routePart}`,
      ],
      ['']
    );
  }, [routeParts]);
};

export const useMenuSidebarWidth = () => {
  const collapsed = false;

  return useMemo(
    () => (collapsed ? SIDEBAR_COLLAPSED_WIDTH : SIDEBAR_EXPANDED_WIDTH),
    [collapsed]
  );
};

export const useViewOptions = () => {
  const intl = useIntl();
  const openedPanels = useSelector(getOpenedPanels) || [];
  const isDrawerForCreatingOpened = useNestedRecordsDrawer();

  const {
    panels = {} as MappedObject<ViewMenuValues, FlexLayoutWindows>,
  } = useViewMenuContext();

  const {
    flexLayoutMethods: { onAddTab },
    globalMethods: { closeComponent },
    availableComponents,
  } = useFlexLayoutContext();
  const { togglePanelsPreferences } = usePanels();
  const { pathname } = useLocation();
  const {
    [FlexLayoutWindows.RecordAccess]: { selectedUsers = [] } = {},
  } = useSelector(getSidebarData);
  const hasRecordAccessUnsavedChanges = selectedUsers.length > 0;

  const getDisabled = useCallback(
    (key: FlexLayoutWindows, customCondition?: boolean) =>
      panels[key]?.disabled === undefined || customCondition
        ? true
        : panels[key].disabled,
    [panels]
  );

  const getIsOpened = useCallback(
    (key: FlexLayoutWindows) => {
      const panel = openedPanels.find(item => item[key] !== undefined);

      return panel === undefined ? true : panel[key];
    },
    [openedPanels]
  );

  const handleClick = useCallback(
    (key: FlexLayoutWindows) => {
      const isOpened = getIsOpened(key);

      if (!isOpened) {
        const disabled = getDisabled(key);
        //on opening
        if (!disabled) {
          onAddTab(key);
        } else {
          togglePanelsPreferences(key, false);
        }
      } else {
        //on closing
        togglePanelsPreferences(key, true);
        closeComponent(key);
      }
    },
    [
      getIsOpened,
      getDisabled,
      onAddTab,
      togglePanelsPreferences,
      closeComponent,
    ]
  );

  const viewOptions = useMemo(
    () => [
      [
        {
          key: FlexLayoutWindows.Summary,
          label: intl.formatMessage({
            id: 'misc.summary',
            defaultMessage: 'Summary',
          }),
          disabled: getDisabled(
            FlexLayoutWindows.Summary,
            isDrawerForCreatingOpened
          ),
          opened: getIsOpened(FlexLayoutWindows.Summary),
          onClick: () => handleClick(FlexLayoutWindows.Summary),
        },
        {
          key: FlexLayoutWindows.ChildRecordSummary,
          label: intl.formatMessage({
            id: 'misc.childRecordSummary',
            defaultMessage: 'Child record summary',
          }),
          disabled: getDisabled(FlexLayoutWindows.ChildRecordSummary),
          opened: getIsOpened(FlexLayoutWindows.ChildRecordSummary),
          onClick: () => handleClick(FlexLayoutWindows.ChildRecordSummary),
        },
        {
          key: FlexLayoutWindows.Tasks,
          label: intl.formatMessage({
            id: 'misc.tasks',
            defaultMessage: 'Tasks',
          }),
          disabled: getDisabled(
            FlexLayoutWindows.Tasks,
            isDrawerForCreatingOpened
          ),
          opened: getIsOpened(FlexLayoutWindows.Tasks),
          onClick: () => handleClick(FlexLayoutWindows.Tasks),
        },
        {
          key: FlexLayoutWindows.RecordAccess,
          label: intl.formatMessage({
            id: 'misc.recordAccess',
            defaultMessage: 'Record access',
          }),
          disabled: getDisabled(
            FlexLayoutWindows.RecordAccess,
            hasRecordAccessUnsavedChanges || isDrawerForCreatingOpened
          ),
          opened: getIsOpened(FlexLayoutWindows.RecordAccess),
          onClick: () =>
            !hasRecordAccessUnsavedChanges
              ? handleClick(FlexLayoutWindows.RecordAccess)
              : noop,
        },
        {
          key: FlexLayoutWindows.ActivityLog,
          label: intl.formatMessage({
            id: 'misc.activityLog',
            defaultMessage: 'Activity log',
          }),
          disabled: getDisabled(
            FlexLayoutWindows.ActivityLog,
            isDrawerForCreatingOpened
          ),
          opened: getIsOpened(FlexLayoutWindows.ActivityLog),
          onClick: () => handleClick(FlexLayoutWindows.ActivityLog),
        },
        {
          key: FlexLayoutWindows.Documents,
          label: intl.formatMessage({
            id: 'misc.templates',
            defaultMessage: 'Templates',
          }),
          disabled: getDisabled(
            FlexLayoutWindows.Documents,
            isDrawerForCreatingOpened
          ),
          opened: getIsOpened(FlexLayoutWindows.Documents),
          onClick: () => handleClick(FlexLayoutWindows.Documents),
        },
        /* {
        other items
        },*/
      ],
      /* [
        other sections
       ],*/
    ],
    [
      intl,
      getDisabled,
      getIsOpened,
      hasRecordAccessUnsavedChanges,
      handleClick,
      isDrawerForCreatingOpened,
    ]
  );

  return viewOptions
    .map(sectionItems =>
      sectionItems.filter(({ key }) =>
        checkIsVisibleComponent(
          availableComponents[key].availablePaths,
          pathname
        )
      )
    )
    .filter(sectionItems => sectionItems.length !== 0);
};

export const useIsViewVisible = (): boolean => {
  const { pathname } = useLocation();
  const visiblePaths = useMemo(() => [routes.RECORDS, routes.TASKS], []);
  return useMemo(
    () => visiblePaths.some(path => !!matchPath(pathname, { path })),
    [visiblePaths, pathname]
  );
};
