import { useFormikContext } from 'formik';
import FormCategory from 'pages/TaskTemplates/components/FormCategory';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ClassFieldForm,
  ClassFieldFormFields,
  FieldFormCategories,
} from '../../types';
import FieldProperties from '../FieldProperties';
import FieldSelectionOptions from '../FieldSelectionOptions';
import FieldValidation from '../FieldValidation';
import { ClassFieldFormContentProps } from './types';
import { typesWithConstraints } from '../consts';
import { useUniquenessValidation } from './hooks';
import { ObjectClassFieldTypes } from 'utils/types/api/objectClassesFields.types';
import FieldUserOptions from '../FieldUserOptions';

const ClassFieldFormContent: React.FC<ClassFieldFormContentProps> = ({
  shouldBeDisabled = false,
  additionalControls,
  parsedTypes,
}) => {
  const { values } = useFormikContext<ClassFieldForm>();

  const { uniquenessAllowed } = useUniquenessValidation(
    values[ClassFieldFormFields.Type]
  );

  return (
    <>
      <FormCategory
        collapsible={[
          ObjectClassFieldTypes.Enum,
          ObjectClassFieldTypes.Set,
          ObjectClassFieldTypes.String,
          ObjectClassFieldTypes.Int,
          ObjectClassFieldTypes.Float,
          ObjectClassFieldTypes.Document,
        ].includes(values[ClassFieldFormFields.Type])}
        noMarginTop
        collapsibleContent={
          <FieldProperties {...{ shouldBeDisabled, parsedTypes }}>
            {!!additionalControls &&
              additionalControls[FieldFormCategories.FieldProperties]}
          </FieldProperties>
        }
      >
        <FormattedMessage
          id='objectClasses.fields.fieldProperties'
          defaultMessage='Field properties'
        />
      </FormCategory>
      {[ObjectClassFieldTypes.Enum, ObjectClassFieldTypes.Set].includes(
        values[ClassFieldFormFields.Type]
      ) && (
        <FormCategory
          collapsible
          collapsibleContent={
            <FieldSelectionOptions {...{ shouldBeDisabled, parsedTypes }} />
          }
        >
          <FormattedMessage
            id='objectClasses.fields.selectionOptions'
            defaultMessage='Selection options'
          />
        </FormCategory>
      )}
      {values[ClassFieldFormFields.Type] === ObjectClassFieldTypes.User && (
        <FormCategory collapsible collapsibleContent={<FieldUserOptions />}>
          <FormattedMessage
            id='objectClasses.fields.selectionOptions'
            defaultMessage='Selection options'
          />
        </FormCategory>
      )}
      {(typesWithConstraints.includes(values[ClassFieldFormFields.Type]) ||
        (additionalControls && uniquenessAllowed)) && (
        <FormCategory
          collapsible
          collapsibleContent={
            <FieldValidation {...{ shouldBeDisabled, parsedTypes }}>
              {!!additionalControls &&
                additionalControls[FieldFormCategories.Validation]}
            </FieldValidation>
          }
        >
          <FormattedMessage
            id='objectClasses.fields.validation'
            defaultMessage='Validation'
          />
        </FormCategory>
      )}
    </>
  );
};

export default ClassFieldFormContent;
