import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { ObjectClassDetailsFromApi } from 'pages/ObjectClasses/components/ObjectClassForm/hooks/useObjectClassDetailsData/types';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearStack,
  popStackWithTreeUpdate,
  pushStackWithTreeUpdate,
  clearTree,
  updateStackElement,
} from 'store/actions/nestedObjectRecordsActions';
import { NestedObjectRecordsStackElement } from 'store/reducers/types/nestedObjectRecords.types';
import { selectStack } from 'store/selectors/nestedObjectRecordsSelctors';
import { ObjectRecordDetails } from 'utils/types/api/objectRecords.types';

const useNestedRecordsStack = (
  isDrawer: boolean,
  objectClass?: ObjectClassDetailsFromApi,
  record?: ObjectRecordDetails
) => {
  const dispatch = useDispatch();
  const nestedRecordStack = useSelector(selectStack);
  const { setAdditionalSelectedResource } = useSelectedResourceContext();

  const updateSelectedElement = useCallback(
    (newData: Partial<NestedObjectRecordsStackElement>) => {
      if (objectClass?.id) {
        dispatch(updateStackElement(newData, objectClass.id.toString()));
      }
    },
    [dispatch, objectClass]
  );

  const pop = useCallback(() => {
    dispatch(popStackWithTreeUpdate());
  }, [dispatch]);

  useEffect(() => {
    if (!objectClass?.name || !objectClass.id) {
      return;
    }

    if (nestedRecordStack.length === 0) {
      dispatch(
        pushStackWithTreeUpdate({
          recordId: record?.id,
          recordIdentifier: record?.object_name,
          classId: objectClass.id.toString(),
          className: objectClass?.name,
          formData: undefined,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, objectClass, dispatch]);

  useEffect(() => {
    return () => {
      if (!isDrawer) {
        dispatch(clearStack());
        dispatch(clearTree());
        setAdditionalSelectedResource(undefined); //clear and hide ChildRecordSummaryPanel data
      }
    };
  }, [dispatch, isDrawer, setAdditionalSelectedResource]);

  return {
    updateSelectedElement,
    pop,
    stackElement: nestedRecordStack.find(
      el => el.classId === objectClass?.id.toString()
    ),
    isMoreThanOne: nestedRecordStack.length > 1,
  };
};

export default useNestedRecordsStack;
