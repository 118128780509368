import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  APPEND_CUSTOM_COMPONENT,
  RESET_CUSTOM_COMPONENT,
  RESET_CUSTOM_COMPONENT_COLUMNS,
  SET_CUSTOM_COMPONENT,
  SET_CUSTOM_COMPONENT_COLUMNS,
  SET_CUSTOM_COMPONENT_FETCHING,
  SET_CUSTOM_COMPONENT_RESTRICTIONS,
  SET_CUSTOM_COMPONENT_SELECTED_ROW,
} from 'store/constants/customComponents.consts';
import {
  appendTableContent,
  resetTable,
  resetTableColumns,
  setSelectedRow,
  setTableColumns,
  setTableContent,
  setTableLoading,
} from './tablesCommonStateModificators';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import { CommonTableState } from 'utils/types/api/table.types';
import cloneDeep from 'lodash/cloneDeep';
import { CustomComponent } from 'utils/types/api/customComponents.types';

const initialState = cloneDeep(INITIAL_TABLE_STATE);
const reducerName: Reducer<CommonTableState<CustomComponent>, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_CUSTOM_COMPONENT: {
      return setTableContent<CustomComponent, typeof SET_CUSTOM_COMPONENT>(
        state,
        action
      );
    }
    case APPEND_CUSTOM_COMPONENT:
      return appendTableContent<
        CustomComponent,
        typeof APPEND_CUSTOM_COMPONENT
      >(state, action);

    case SET_CUSTOM_COMPONENT_FETCHING:
      return setTableLoading<
        CustomComponent,
        typeof SET_CUSTOM_COMPONENT_FETCHING
      >(state, action);

    case RESET_CUSTOM_COMPONENT:
      return resetTable<CustomComponent>(state, initialState);

    case RESET_CUSTOM_COMPONENT_COLUMNS:
      return resetTableColumns<CustomComponent>(state, initialState);

    case SET_CUSTOM_COMPONENT_RESTRICTIONS:
      return { ...state, restrictions: action.restrictions };
    case SET_CUSTOM_COMPONENT_COLUMNS:
      return setTableColumns<
        CustomComponent,
        typeof SET_CUSTOM_COMPONENT_COLUMNS
      >(state, action);
    case SET_CUSTOM_COMPONENT_SELECTED_ROW:
      return setSelectedRow<
        CustomComponent,
        typeof SET_CUSTOM_COMPONENT_SELECTED_ROW
      >(state, action);
    default:
      return state;
  }
};

export default reducerName;
