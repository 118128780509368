import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useMenuStyles = createUseStyles((theme: ThemeState) => {
  return {
    menu: {
      //do not remove these, may be useful for Menu component styling
      // '&:not(.ant-menu-inline) .ant-menu-item, .ant-menu-submenu': {
      //   '&:hover, &.ant-menu-item-selected': {
      //     color: theme['primary-color'],
      //     borderBottomColor: theme['primary-color'],
      //     '& .ant-menu-submenu-title': {
      //       color: theme['primary-color'],
      //     },
      //   },
      //   '&:not(.ant-menu-item-active):not(.ant-menu-submenu):active, &:not(.ant-menu-submenu-active) .ant-menu-submenu-title:active': {
      //     backgroundColor: theme['primary-color-opacity'],
      //   },
      //   '&.ant-menu-dark .ant-menu-item-selected': {
      //     backgroundColor: theme['primary-color'],
      //     color: 'rgba(255, 255, 255, 0.65)',
      //   },
      // },
      // '&:not(.ant-menu-horizontal) .ant-menu-item, &:not(.ant-menu-horizontal) .ant-menu-submenu': {
      //   '&:hover, &.ant-menu-item-selected': {
      //     color: theme['primary-color'],
      //     '& .ant-menu-submenu-title': {
      //       color: theme['primary-color'],
      //     },
      //   },
      //   '& .ant-menu-submenu-title:hover': {
      //     color: theme['primary-color'],
      //     '& .ant-menu-submenu-arrow': {
      //       background: theme['primary-color'],
      //       backgroundColor: theme['primary-color'],
      //       '&::before, &::after': {
      //         background: theme['primary-color'],
      //         backgroundColor: theme['primary-color'],
      //       },
      //     },
      //   },
      //   '&.ant-menu-item-selected': {
      //     backgroundColor: theme['primary-color-opacity'],
      //     '&::after': {
      //       borderRightColor: theme['primary-color'],
      //     },
      //   },
      //   '&:not(.ant-menu-submenu-open):active, & .ant-menu-submenu-title:active': {
      //     backgroundColor: theme['primary-color-opacity'],
      //   },
      //   '& .ant-menu-item-selected': {
      //     color: theme['primary-color'],
      //     backgroundColor: theme['primary-color-opacity'],
      //     '&::after': {
      //       borderRightColor: theme['primary-color'],
      //     },
      //   },
      // },
      // '&:not(.ant-menu-inline) .ant-menu-submenu': {
      //   '&.ant-menu-submenu-open, &.ant-menu-submenu-active, &.ant-menu-open .ant-menu-submenu-title': {
      //     color: theme['primary-color'],
      //     borderBottom: `2px solid ${theme['primary-color']}`,
      //   },
      // },
      // '& .ant-menu-item-only-child': {
      //   '&:hover a, &.ant-menu-item-active, &.ant-menu-item-selected, &.ant-menu-item-selected a': {
      //     color: theme['primary-color'],
      //   },
      // },
    },
    submenuPopup: {
      '&.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-submenu-placement-rightTop .ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-submenu-placement-rightTop': {
        // top: '0!important',
      },
      '& .ant-menu-submenu .ant-menu-submenu-title': {
        padding: '0px 16px !important',
        justifyContent: 'left !important',
        '& span.anticon': {
          display: 'none',
        },
      },
      //do not remove these, may be useful for Menu component styling
      // '& .ant-menu-item': {
      //   '&:hover': {
      //     color: theme['primary-color'],
      //   },
      //   '&:active': {
      //     backgroundColor: theme['primary-color-opacity'],
      //   },
      //   '&.ant-menu-item-selected': {
      //     color: theme['primary-color'],
      //     backgroundColor: theme['primary-color-opacity'],
      //   },
      // },
    },
    subMenuActive: {
      //do not remove these, may be useful for Menu component styling

      // backgroundColor: theme['primary-color'],
      color: 'white',
      '&.ant-menu-submenu-open': {
        '& svg': {
          color: 'white!important',
        },
      },
      '& .ant-menu-submenu-title': {
        padding: '0px!important',
        display: 'flex',
        justifyContent: 'center',
        margin: 0,
        '&:hover': {
          '& svg:hover': {
            color: 'white',
          },
        },
        '& .anticon': {
          height: 40,
          width: 40,
          borderRadius: 9,
          '&:hover': {
            '& svg': {
              color: 'white',
            },
          },
        },
      },
    },
    subMenuInlineActive: {
      //do not remove these, may be useful for Menu component styling
      // color: theme['primary-color'],
      '& > .ant-menu-submenu-title': {
        color: 'white',
        fontWeight: 'bold',
      },
    },
    subMenu: {
      color: theme['layout-trigger-color'],
      '.sea-blue & > .ant-menu-submenu-title, .dark-blue & > .ant-menu-submenu-title': {
        '&:hover': {
          color: 'white',
        },
      },
      '.purple & > .ant-menu-submenu-title': {
        '&:hover': {
          color: 'white',
        },
      },
    },
    menuItem: {
      '.purple &:hover a, .sea-blue &:hover a, .dark-blue &:hover a': {
        color: 'white',
      },
      '& .ant-menu-item-selected': {
        fontWeight: 'bold',
        '& .anticon': {
          color: theme['default-red'],
        },
      },
    },
  };
});

export default useMenuStyles;
