import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useFilterEditorStyles = createUseStyles((theme: ThemeState) => {
  return {
    singleFilter: {
      padding: 12,
      border: '1px solid',
      borderRadius: 3,
      borderColor: theme['placeholder-border-color'],
      marginBottom: 15,
    },
    bottomButtonsContainer: {
      backgroundColor: 'white',
      width: '100%',
      padding: '30px 15px',
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
    },
    buttonWrapper: {
      marginTop: 4,
      marginBottom: 12,
    },
    leftButton: { marginBottom: 8 },
    rightButton: { marginLeft: 8 },
    wrapper: {
      width: '100%',
    },
    container: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    filterEditorMainWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    filterEditorConditionsWrapper: {
      overflow: 'auto',
      flex: 1,
      position: 'relative',
      padding: 15,
    },
    addConditionButton: {
      padding: '0 8px',
    },
  };
});

export default useFilterEditorStyles;
