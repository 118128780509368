import { FormTypes } from 'components/formBuilder/formBuilder/FormBuilder/types';

export interface DisplayTableProps {
  activeViews: {
    [FormTypes.RecordListing]: boolean;
    [FormTypes.RecordView]: boolean;
    [FormTypes.RecordSummary]: boolean;
  };
}

export enum TagNames {
  Enabled = 'enabled',
  Disabled = 'disabled',
}
