import { ExtendedUiSchema } from 'components/FormPreview/customFields/ObjectField/types';
import noop from 'lodash/noop';
import { createContext, useContext } from 'react';
import { FormTypes } from '../FormBuilder/types';
import { FormBuilderContextType } from './types';

export const FormBuilderContext = createContext<FormBuilderContextType>({
  schema: '',
  uiSchema: '',
  setSchema: noop,
  setUiSchema: noop,
  onChange: noop,
  confirmationModalData: {},
  setConfirmationModalData: noop,
  selectedItem: undefined,
  setSelectedItem: noop,
  manuallyModifiedFieldName: {},
  setManuallyModifiedFieldName: noop,
  allFieldNames: [],
  collapsedSections: [],
  setCollapsedSections: noop,
  schemaData: {},
  uiSchemaData: {} as ExtendedUiSchema,
  conditionalComponentNames: [],
  formBuilderFields: [],
  setFormBuilderFields: noop,
  formType: FormTypes.TaskTemplates,
  removingSectionColumnsModalData: {},
  setRemovingSectionColumnsModalData: noop,
  isFormBuilderTouched: false,
  resetFormBuilderTouchedState: noop,
  resetFormToInitialState: noop,
  conditionalVisibilityErrors: [],
  setConditionalVisibilityErrors: noop,
  disabledSections: [],
  conditionalVisibilityFieldsThatShouldBeUpdated: {},
  setConditionalVisibilityFieldsThatShouldBeUpdated: noop,
  lockedFieldNames: [],
  setLockedFieldNames: noop,
  isAliasInvalid: false,
  setIsAliasInvalid: noop,
  formName: '',
  setFormName: noop,
  redirectURL: undefined,
  setRedirectURL: noop,
  isDragging: false,
  componentsAmmount: { components: 0, instructions: 0, sections: 0 },
  isDragDisabled: false,
  setIsDragDisabled: noop,
  redirectEnabled: false,
  setRedirectEnabled: noop,
  hideSubmit: false,
  setHideSubmit: noop,
});

export const FormBuilderContextProvider = FormBuilderContext.Provider;
export const useFormBuilderContext = () => useContext(FormBuilderContext);
