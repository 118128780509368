import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useCompleteTaskBy1TCStyles = createUseStyles((theme: ThemeState) => ({
  taskLayout: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr 92px',
    gridColumnGap: '0px',
    gridRowGap: '0px',
    height: '100vh',
  },
  taskLoader: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },
  taskContainer: {
    padding: 50,
    overflow: 'auto',
  },
  taskTitleContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: 28,
  },
  taskTitle: { fontSize: 16, lineHeight: '22px', fontWeight: 600, margin: 0 },
  taskDue: { fontSize: 14, lineHeight: '19px', fontWeight: 700, margin: 0 },
  taskDueLabel: { fontWeight: 400, marginLeft: 30 },
  taskOverdue: {
    fontSize: 12,
    fontWeight: 400,
    marginLeft: 8,
    lineHeight: '16px',
    padding: [3, 6],
    color: theme['error-color'],
    backgroundColor: theme['error-bg-light'],
  },
  taskInstructions: { marginBottom: 28, maxWidth: 750 },
  taskFormButtons: {
    position: 'sticky',
    bottom: 0,
    borderTop: `1px solid ${theme['border-color-base']}`,
    backgroundColor: theme['white-color'],
    width: '100%',
    padding: [0, 50],
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '16px',
  },
  taskFormButtonsDivider: {
    width: 0,
    height: 44,
    borderLeft: `1px solid ${theme['border-color-base']}`,
  },
}));

export const useSaveProgressModalStyles = createUseStyles({
  modalContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '20px',
  },
  modalDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    '&>h3': {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '25px',
    },
    '&>p': {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '19px',
    },
  },
  modalButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24,
  },
});

export const useTaskSummaryStyles = createUseStyles((theme: ThemeState) => ({
  taskSummaryLayout: {
    width: '100vw',
    height: '100vh',
    backgroundColor: theme['disabled-input-gray'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  taskSummaryContainer: {
    width: 711,
    height: 515,
    backgroundColor: theme['white-color'],
    padding: [71, 107],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    boxShadow: '0px 4px 50px rgba(46, 63, 87, 0.19)',
  },
  taskSummaryLogo: {
    position: 'absolute',
    top: 70,
  },
  taskSummaryData: {
    display: 'flex',
    alignItems: 'center',
    gap: '40px',
    width: '100%',
  },
  taskSummaryDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
    '&>h1': {
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '25px',
    },
    '&>p': {
      fontSize: 13,
      fontWeight: 400,
      lineHeight: '18px',
    },
  },
}));

export default useCompleteTaskBy1TCStyles;
