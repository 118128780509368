import React, { useCallback, useMemo, useState } from 'react';
import { useTableContext } from 'contexts/TableContext';
import debounce from 'lodash/debounce';
import InfiniteScroll from 'react-infinite-scroll-component';
import Table from '.';
import { TABLE_BODY_ID } from 'utils/elementsIds';
import { DraggableTable } from './DraggableTable';

const InfiniteTable: React.FC = () => {
  const [isDragging, setIsDragging] = useState(false);
  const {
    filteredCount,
    total: totalCount,
    data,
    onTableScroll,
    handleOrderChange,
    searchValue,
    filterSelectOptions,
  } = useTableContext();

  const onReachBottom = useCallback(() => {
    if (onTableScroll) {
      onTableScroll();
    }
  }, [onTableScroll]);

  const hasMoreItemsToLoad = useMemo(
    () =>
      filteredCount < totalCount
        ? data.length < filteredCount
        : data.length < totalCount,
    [data.length, filteredCount, totalCount]
  );

  const onScroll = debounce(e => {
    const {
      target: { scrollTop, scrollHeight, offsetHeight },
    } = e;

    // we add -25 to compensate when something appears on bottom eg. horizontal scroll
    if (hasMoreItemsToLoad && scrollTop > scrollHeight - offsetHeight - 25) {
      onReachBottom();
    }
  }, 200);

  const handleDragging = (value: boolean) => setIsDragging(value);

  return (
    <InfiniteScroll
      key={`infiniteTable${!!filterSelectOptions ? searchValue?.value : ''}`}
      onScroll={onScroll}
      dataLength={filteredCount < totalCount ? filteredCount : totalCount}
      next={() => null}
      hasMore={hasMoreItemsToLoad}
      loader={null}
      scrollableTarget={TABLE_BODY_ID}
      style={{ overflow: 'visible' }}
    >
      {!!handleOrderChange ? (
        <DraggableTable {...{ handleDragging }} isDraggingActive={isDragging} />
      ) : (
        <Table />
      )}
    </InfiniteScroll>
  );
};

export default InfiniteTable;
