import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectStack } from 'store/selectors/nestedObjectRecordsSelctors';

const useNestedRecordsDrawer = () => {
  const nestedRecordsStack = useSelector(selectStack);
  return useMemo(
    () =>
      nestedRecordsStack.length > 1 &&
      !nestedRecordsStack[nestedRecordsStack.length - 1].recordId,
    [nestedRecordsStack]
  );
};

export default useNestedRecordsDrawer;
