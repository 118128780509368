import React from 'react';
import useFormPreviewStyles from 'components/FormPreview2/styles';
import { StandardFieldWrapperProps } from './types';
import ErrorsComponent from 'components/ErrorsComponent';
import { Provider } from 'react-redux';
import { store } from 'store/store';

const StandardFieldWrapper: React.FC<StandardFieldWrapperProps> = ({
  children,
  description,
  errors,
  valueLength,
  maxLength,
}) => {
  const classes = useFormPreviewStyles({});

  return (
    <Provider {...{ store }}>
      <div>
        {children}

        <div className={classes.counterAndErrorContainer}>
          <ErrorsComponent rawErrors={errors} />

          {maxLength && (
            <div>
              <span
                className={classes.counter}
              >{`${valueLength} / ${maxLength}`}</span>
            </div>
          )}
        </div>

        {description && (
          <span className={classes.description}>{description}</span>
        )}
      </div>
    </Provider>
  );
};

export default StandardFieldWrapper;
