import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { Location } from 'history';

const useCustomPrompt = (
  onCancel: (location?: Location) => void,
  onConfirm?: () => Promise<unknown>,
  setIsNavigateAway?: Dispatch<SetStateAction<boolean>>
) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const history = useHistory();

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    const { pathname } = history.location;
    const { pathname: nextPathname } = nextLocation;

    if (!confirmedNavigation && nextPathname !== pathname) {
      if (setIsNavigateAway) setIsNavigateAway(true);

      setModalVisible(true);
      setLastLocation(nextLocation);

      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = async () => {
    try {
      const result = await onConfirm?.();

      if (!result) return;

      setConfirmedNavigation(true);
    } catch (e) {
      setConfirmedNavigation(false);
    } finally {
      setModalVisible(false);

      if (setIsNavigateAway) setIsNavigateAway(false);
    }
  };

  const closeModal = () => {
    if (lastLocation) {
      onCancel(lastLocation);
    }
  };

  const handleClosePress = () => {
    setModalVisible(false);
    onCancel();
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, history, lastLocation]);

  return {
    closeModal,
    handleClosePress,
    handleConfirmNavigationClick,
    handleBlockedNavigation,
    modalVisible,
  };
};

export default useCustomPrompt;
