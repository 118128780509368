import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useToolButtonStyles = createUseStyles((theme: ThemeState) => {
  return {
    toolButton: {
      width: 35,
      height: 35,
      '&:hover': {
        borderColor: theme['primary-color-light'],
        backgroundColor: theme['primary-color-light'],
        '& svg': { color: theme['primary-color'] },
      },
    },
    disabledButtonWithTooltipWrapper: {
      position: 'relative',
    },
    disabledButtonFakeArea: {
      position: 'absolute',
      width: '100%',
      minWidth: 32,
      opacity: 0,
      height: 32,
      zIndex: 10,
    },
    tooltipWrapper: {
      display: 'inline-block',
    },
  };
});

export default useToolButtonStyles;
