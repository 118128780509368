import React, { useEffect } from 'react';
import { useTasksPanelContext } from '../../TasksPanelModeContext';
import { TasksPanelMode } from '../../types';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import usePreviousState from 'hooks/usePreviousState';

const BackToTaskListWrapper: React.FC = ({ children }) => {
  const { setMode } = useTasksPanelContext();
  const {
    selectedResource: { record: { recordId = undefined } = {} } = {},
  } = useSelectedResourceContext();
  const prevRecord = usePreviousState(recordId);

  useEffect(() => {
    if (prevRecord && prevRecord !== recordId) setMode(TasksPanelMode.List);
  }, [prevRecord, recordId, setMode]);

  return <>{children}</>;
};

export default BackToTaskListWrapper;
