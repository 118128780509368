import React, { useEffect } from 'react';
import { InPlaceEditMultiselectProps } from './types';
import useInPlaceEdit from '../useInPlaceEdit';
import InPlaceEditWrapper from '../components/InPlaceEditWrapper';
import useInPlaceEditMultiselectStyles from './styles';
import More from 'components/More';
import EditContent from './EditContent';
import { InPlaceEditError } from '../utils';
import { useErrorMessages } from 'utils/useErrorMessages';
import { Dropdown } from 'components/lib/Dropdown';
import useCommonInPlaceEditStyles from '../components/commonStyles';
import TooltipString from 'components/TooltipString';

const InPlaceEditMultiselect = <R extends Object>({
  label,
  required,
  value,
  disabled,
  propertyName,
  patchUrl,
  onSaveSuccess,
  options,
  maxSelected,
  minSelected,
  getPopupContainer,
  remountChangeTarget,
  readOnly,
  size,
  withUnderline,
}: InPlaceEditMultiselectProps<R>) => {
  const validateBeforeSubmit = (value: string[] | null | undefined) => {
    if (!value) return undefined;

    if (
      minSelected !== undefined &&
      minSelected !== null &&
      value.length < minSelected
    )
      return errorMessages[InPlaceEditError.SELECTED_LESS_THAN_MINIMUM](
        minSelected
      );

    return undefined;
  };

  const {
    isEditMode,
    setTempValue,
    tempValue,
    isSaving,
    setIsEditModeOn,
    errorMessage,
    inputWrapperRef,
    setIsEditModeOff,
    setErrorMessage,
    editWrapperRef,
  } = useInPlaceEdit({
    initialValue: !!value ? value : null,
    patchUrl,
    propertyName,
    onSaveSuccess,
    validateBeforeSubmit,
    getPopupContainer,
  });
  const styles = useInPlaceEditMultiselectStyles({});
  const commonInPlaceEditClasses = useCommonInPlaceEditStyles({
    isSaving,
    errorMessage,
  });

  const errorMessages = useErrorMessages();

  useEffect(() => {
    if (!isEditMode) return;

    const onKeyPress = (event: KeyboardEvent) => {
      if (event.key !== 'Escape') return;

      setIsEditModeOff();
    };

    document.addEventListener('keydown', onKeyPress);

    return () => {
      document.removeEventListener('keypress', onKeyPress);
    };
  }, [isEditMode, setIsEditModeOff]);

  const onViewClick = () => {
    if (disabled) return;

    setTempValue(!!value ? value : null); // preevents blinking checkboxes with old value
    setAccordingErrorMessage(value);
    setIsEditModeOn();
  };

  const setAccordingErrorMessage = (newValue: string[] | null | undefined) => {
    if (newValue === null && required) {
      setErrorMessage(errorMessages[InPlaceEditError.FIELD_IS_REQUIRED_ERROR]);
    } else if (!!maxSelected && !!newValue && newValue?.length > maxSelected) {
      setErrorMessage(
        errorMessages[InPlaceEditError.SELECTED_MORE_THAN_MAXIMUM](maxSelected)
      );
    } else if (!!minSelected && !!newValue && newValue?.length < minSelected) {
      setErrorMessage(
        errorMessages[InPlaceEditError.SELECTED_LESS_THAN_MINIMUM](minSelected)
      );
    } else {
      setErrorMessage(undefined);
    }
  };

  const onChange = (newValue: string[] | null) => {
    setAccordingErrorMessage(newValue);

    setTempValue(newValue);
  };

  const sortedValue = value || [];
  const visibleElementsCount = 3;
  const visibleElements = sortedValue.slice(0, visibleElementsCount);

  return (
    <InPlaceEditWrapper
      {...{
        isEditMode,
        isSaving,
        label,
        required,
        disabled,
        onViewClick,
        inputWrapperRef,
        readOnly,
        size,
        withUnderline,
      }}
      noAfter
      editContent={
        <Dropdown
          {...{ getPopupContainer, remountChangeTarget }}
          visible
          overlayClassName={styles.dropdownOverlay}
          overlay={
            <EditContent
              {...{
                isSaving,
                tempValue,
                errorMessage,
                options,
                onChange,
                maxSelected,
                minSelected,
                getPopupContainer,
              }}
              onClose={setIsEditModeOff}
              ref={editWrapperRef}
            />
          }
        >
          <div className={commonInPlaceEditClasses.dropdownAnchor}></div>
        </Dropdown>
      }
      viewContent={
        !!visibleElements?.length ? (
          <div className={styles.vievWrapper}>
            {visibleElements.map(option => (
              <TooltipString
                text={option}
                key={option}
                className={styles.viewOption}
              />
            ))}
          </div>
        ) : (
          '-'
        )
      }
      viewContentAfter={
        sortedValue.length - visibleElementsCount > 0 ? (
          <More
            moreElements={sortedValue}
            {...{ visibleElementsCount, getPopupContainer }}
          />
        ) : undefined
      }
    />
  );
};

export default InPlaceEditMultiselect;
