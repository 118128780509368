import { useRefetchResourceContext } from 'contexts/RefetchResourceContext';
import { RefetchResources } from 'contexts/types';
import { useEffect } from 'react';

/*

"fetchFunction" will be called every time "refetchData" from RefetchResourceContext is called with the same "resourceName"
"componentName" is used to identify component that listens to resource updates (when we want to omit this component when refreshing a resource)
*/

const useRefetchResource = (
  resourceName: RefetchResources,
  fetchFunction: (fetchFnProps?: MappedObject<unknown>) => void | undefined,
  componentName: string
) => {
  const { refetchResourcesRef } = useRefetchResourceContext();

  useEffect(() => {
    if (!fetchFunction || !componentName) return;

    refetchResourcesRef.current = {
      ...refetchResourcesRef.current,
      [resourceName]: [
        ...refetchResourcesRef.current[resourceName],
        { componentName, refreshFunction: fetchFunction },
      ],
    };

    return () => {
      refetchResourcesRef.current = {
        ...refetchResourcesRef.current,
        [resourceName]: refetchResourcesRef.current[resourceName].filter(
          refetchResourceEntry =>
            refetchResourceEntry.componentName !== componentName
        ),
      };
    };
  }, [fetchFunction, refetchResourcesRef, resourceName, componentName]);
};

export default useRefetchResource;
