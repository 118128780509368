export enum childClassPageSizes {
  ZERO_OR_MORE = 5,
  ZERO_OR_ONE = 1,
}

export enum childClassUICOmponentStates {
  HIDDEN = 'HIDDEN',
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}
