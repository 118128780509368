import { createUseStyles } from 'react-jss';

const useFormFieldStyles = createUseStyles({
  formField: {
    marginBottom: 15,
    '& .ant-form-item': {
      marginBottom: 0,
    },
    '& .ant-form-item-explain-error': {
      marginTop: 3,
      fontSize: '12px',
    },
  },
});

export default useFormFieldStyles;
