import TablesType from './Enums/TablesType';
import routes from './routingPaths';

export const tablePaths = {
  [TablesType.TaskTemplates]: routes.TASK_TEMPLATES,
  [TablesType.ObjectRecords]: routes.RECORDS,
  [TablesType.TaskGroups]: routes.TASK_GROUPS,
  [TablesType.Sequences]: routes.SEQUENCES,
  [TablesType.ObjectClasses]: routes.OBJECT_CLASSES,
  [TablesType.ClassForms]: routes.CLASS_FORMS,
  [TablesType.ObjectClassesFields]: routes.TASK_TEMPLATE_EDIT,
  [TablesType.UserGroups]: routes.USER_GROUPS,
  [TablesType.SingleSignOn]: routes.SINGLE_SIGN_ONS,
  [TablesType.Users]: routes.USERS,
  [TablesType.Roles]: routes.ROLES,
  [TablesType.Tasks]: routes.TASKS,
  [TablesType.Forms]: routes.CLASS_FORMS,
  [TablesType.AuthenticationObjects]: routes.AUTHENTICATION_OBJECTS,
  [TablesType.DocumentTemplates]: routes.OBJECT_CLASSES_EDIT,
  [TablesType.CustomComponents]: routes.CUSTOM_COMPONENTS,
} as Record<string, string>;
