import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { ColumnsMetadata } from 'utils/types/api/table.types';
import { UserMe } from 'utils/types/api/users.types';
import { TPredicateValue } from 'utils/types/predicates.types';
import { TasksQuickFilterOwnership } from '../../types';

const { intl } = GlobalIntlSingleton;
const { Assignees, Owners } = TasksQuickFilterOwnership;

export const getAssigneePredicate = (
  columns: MappedObject<ColumnsMetadata, string>,
  user: UserMe
) => {
  return [
    {
      column: columns[Assignees].alias,
      type: columns[Assignees].type,
      predicates: columns[Assignees].predicates || [],
      label: ownershipPredicateLabels[Assignees],
      value: {
        predicateKey: 'containsall',
        predicateValues: {
          value: [
            {
              ...user,
              value: user.id,
              label: `${user.first_name} ${user.last_name} (${user.username})`,
            },
          ],
        },
        predicateArgs: ['value'],
        isMultipleSelection: true,
      },
    },
    ...getOwnerPredicate(columns, user, 'not_exact'),
  ];
};

export const getOwnerPredicate = (
  columns: MappedObject<ColumnsMetadata, string>,
  user: UserMe,
  predicate?: 'exact' | 'not_exact'
) => {
  return [
    {
      column: columns[Owners].alias,
      type: columns[Owners].type,
      predicates: columns[Owners].predicates || [],
      label: ownershipPredicateLabels[Owners],
      value: {
        predicateKey: predicate || 'exact',
        predicateValues: {
          value: ({
            ...user,
            value: user.id,
            text: `${user.first_name} ${user.last_name} (${user.username})`,
          } as unknown) as TPredicateValue,
        },
        predicateArgs: ['value'],
        isMultipleSelection: true,
      },
    },
  ];
};

const ownershipPredicateLabels = {
  [TasksQuickFilterOwnership.Default]: '------',
  [TasksQuickFilterOwnership.Assignees]: intl.formatMessage({
    id: 'tasks.current_assignees',
  }),
  [TasksQuickFilterOwnership.Owners]: intl.formatMessage({
    id: 'tasks.current_owner',
  }),
};
