import { toast } from 'components/lib/toast';
import globalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { TOAST_AUTO_CLOSE_TIME } from 'utils/consts';
import ToastType from 'utils/Enums/ToastType';

const errorToast = () => {
  toast(
    {
      title: globalIntlSingleton.intl.formatMessage({
        id: 'misc.error',
        defaultMessage: 'Error!',
      }),
      subtitle: globalIntlSingleton.intl.formatMessage({
        id: 'misc.errorEncountered',
        defaultMessage: 'We encountered an error, please try again later.',
      }),
    },
    ToastType.Error,
    {
      autoClose: TOAST_AUTO_CLOSE_TIME,
    }
  );
};

export default errorToast;
