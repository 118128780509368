import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useMultiselectStyles = createUseStyles((theme: ThemeState) => {
  return {
    multiselect: {
      width: '100%',
      borderColor: theme['border-color-base'],
      '& .ant-select-selector': {
        borderColor: theme['border-color-base'],
      },
      '&:hover': {
        borderColor: theme['primary-color'],
      },
      '&.ant-select:not(.ant-select-disabled):hover .ant-select-selector': {
        borderColor: theme['primary-color'],
      },
      '&.ant-select.ant-select-disabled .ant-select-selection-search': {
        display: 'none',
      },
      '&:focus, &:focus-within': {
        borderColor: `${theme['primary-color']} !important`,
        boxShadow: `${theme['input-focus-shadow']} !important`,
      },
      '&:active': {
        borderColor: theme['primary-color-dark'],
      },
      '&.ant-select-focused.ant-select-multiple .ant-select-selector': {
        borderColor: theme['primary-color'],
        boxShadow: theme['input-focus-shadow'],
      },
      '& .ant-select-selection-placeholder': {
        color: theme['placeholder-text-color'],
        borderColor: theme['placeholder-border-color'],
      },
      '& .ant-select-selection-item': {
        border: 'none',
        color: theme['primary-color'],
        background: theme['primary-color-light'],
        display: 'flex',
        alignItems: 'center',

        '& .anticon': {
          color: theme['primary-color'],
        },
      },
      '&.ant-select-multiple .ant-select-selection-item-remove > .anticon': {
        verticalAlign: 0,
        display: 'flex',
        alignItems: 'center',
      },
      '&.ant-select-multiple .ant-select-selection-item-remove > *': {
        lineHeight: 'unset',
      },
      '& .ant-select-clear': {
        top: '45%',
      },
      '&.ant-select-disabled .ant-select-selection-item': {
        background: theme['primary-color-light'],
        '& > span': {
          color: '#028FDF',
          opacity: 0.6,
        },
      },
    },
    selectTag: {
      border: 'none',
      color: theme['primary-color'],
      background: theme['primary-color-light'],
      display: 'flex',
      alignItems: 'center',

      '& .anticon': {
        color: theme['primary-color'],
      },
    },
    tagPadding: {
      padding: '2px 7px!important',
    },
    dropdown: {
      '& .ant-select-item-option-selected:not(.ant-select-item-option-disabled)': {
        color: theme['primary-color'],
        fontWeight: 700,
        backgroundColor: theme['disabled-color-light'],
      },
    },
    arrowDown: {
      color: theme['secondary-color'],
    },
    withIcon: {
      '& .ant-select-selector': {
        paddingLeft: '34px !important',
      },
      '& .ant-select-selection-search-input': {
        marginLeft: '0px !important',
      },
      '& .ant-select-selection-placeholder': {
        left: 34,
      },
      // this block needs style: {position: relative} in parent element
      '& + svg': {
        position: 'absolute',
        zIndex: 1,
        left: '12px',
        top: '11px',
      },
    },
  };
});

export default useMultiselectStyles;
