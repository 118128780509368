import clsx from 'clsx';
import { CustomAvatarGroup } from 'components/lib/Avatar';
import useDateFormat from 'hooks/useDateFormat';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { mapBaseUserInfoToAvatarItem } from 'utils/functions/mapUserToAvatarItem';
import {
  INCOMPLETE_TASK_DUE_DATE,
  TASK_PROPERTY_ASSIGNEES,
} from 'utils/testIds';
import useTaskPropertiesStyles from '../../styles';
import TaskOwnership from '../TaskOwnership';
import { IncompleteTaskPropertiesContentProps } from './types';

const IncompleteTaskPropertiesContent: React.FC<IncompleteTaskPropertiesContentProps> = ({
  dueDate,
  stage: { assignees: { users = [] } = {}, owner, name: stageName } = {},
  nextActions,
  id,
}) => {
  const styles = useTaskPropertiesStyles({});
  const { dateFormat } = useDateFormat();

  const avatarItems = useMemo(
    () =>
      orderBy(users.map(mapBaseUserInfoToAvatarItem), [
        ({ firstName: name }) => name?.toLowerCase(),
        ({ lastName: surname }) => surname?.toLowerCase(),
      ]),
    [users]
  );

  const itemsLimit = 4;

  return (
    <>
      <div
        className={clsx(styles.gridItem, {
          [styles.assigneItem]: !!owner,
        })}
      >
        <FormattedMessage id='task.taskOwner' defaultMessage='Task owner: ' />
        <TaskOwnership
          owner={owner}
          nextActions={nextActions}
          taskId={id}
          stageId={stageName}
        />
      </div>
      <div className={styles.gridItem}>
        <FormattedMessage id='task.dueDate' defaultMessage='Due: ' />
        <b data-testid={INCOMPLETE_TASK_DUE_DATE}>
          {dueDate ? (
            moment(dueDate).format(dateFormat)
          ) : (
            <FormattedMessage id='misc.none' defaultMessage='None' />
          )}
        </b>
      </div>
      <div className={styles.gridItem}></div>
      <div
        className={clsx(styles.gridItem, {
          [styles.assigneItem]: users.length,
        })}
        data-testid={TASK_PROPERTY_ASSIGNEES}
      >
        <FormattedMessage id='task.assignedTo' defaultMessage='Assigned to: ' />
        {users.length ? (
          <CustomAvatarGroup items={avatarItems} limitItems={itemsLimit} />
        ) : (
          <b>
            <FormattedMessage id='misc.none' defaultMessage='None' />
          </b>
        )}
      </div>
      <div className={styles.gridItem}></div>
      <div className={styles.gridItem}></div>
    </>
  );
};

export default IncompleteTaskPropertiesContent;
