import React, { Suspense } from 'react';
import { Field } from 'formik-antd';
import { FormikFormItem } from 'components/lib/Form';
import { FieldProps } from 'formik';
import { FormikEditorProps } from 'components/lib/HTMLEditor/types';
import LoaderOverlay from 'components/LoaderOverlay';

const HTMLEditorWrapper = React.lazy(
  () => import('components/HTMLEditorWrapper')
);

const Editor: React.FC<FormikEditorProps> = ({ name, ...props }) => (
  <FormikFormItem {...{ name }}>
    <Field {...{ name }}>
      {({ field }: FieldProps) => (
        <Suspense fallback={<LoaderOverlay />}>
          <HTMLEditorWrapper {...{ ...props, field }} />
        </Suspense>
      )}
    </Field>
  </FormikFormItem>
);

export default Editor;
