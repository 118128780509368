import React from 'react';
import { FilterIcon } from 'components/Icon';
import ToolButton from 'components/ToolButton';
import Tooltip from 'components/lib/Tooltip';
import { FormattedMessage } from 'react-intl';
import useFilterEditor from 'components/FilterEditor/hooks';
import { OPEN_FILTER_EDITOR_TESTID } from 'utils/testIds';
import GTag from 'providers/IntlProviderWrapper/gTag';
import { GTagEventButtonName } from 'providers/IntlProviderWrapper/gTag/types';
import { FilterEditorButtonProps } from './types';

const FilterEditorButton = ({ alwaysEnabled }: FilterEditorButtonProps) => {
  const { showFilters, hasNoFilters } = useFilterEditor();

  const onClick = () => {
    GTag.pushGtagButtonEvent(GTagEventButtonName.OpenFilterEditor);
    showFilters();
  };

  return (
    <Tooltip
      title={
        <FormattedMessage id='misc.openFilter' defaultMessage='Open filter' />
      }
    >
      <ToolButton
        {...{ onClick }}
        disabled={hasNoFilters && !alwaysEnabled}
        icon={<FilterIcon size={15} />}
        data-testid={OPEN_FILTER_EDITOR_TESTID}
      />
    </Tooltip>
  );
};

export default FilterEditorButton;
