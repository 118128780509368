import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const usePropertyFieldStyles = createUseStyles((theme: ThemeState) => {
  return {
    disabledText: {
      color: theme['disabled-color'],
    },
  };
});

export default usePropertyFieldStyles;
