import React from 'react';
import useResetPasswordPageStyles from './styles';
import { Col, Row } from '../../components/lib/Grid';
import AutologyxInformation from '../../components/AutologyxInformation';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import { Text } from '../../components/lib/Text';
import { FormattedMessage } from 'react-intl';

const ForgotPassword = () => {
  const classes = useResetPasswordPageStyles({});

  return (
    <div className={classes.wrapper}>
      <Row justify='center' className='w-100'>
        <Col span={6} xxl={12} xl={14} lg={16} md={12} sm={16} xs={22}>
          <ForgotPasswordForm />
        </Col>
        <div className={classes.footer}>
          <Text className={classes.footerText}>
            <FormattedMessage
              id='login.footer'
              defaultMessage='{copy} {year} autologyx.com All rights reserved.'
              values={{
                copy: <span>&copy;</span>,
                year: new Date().getFullYear(),
                privacy: (
                  <u>
                    <FormattedMessage
                      id='login.privacy'
                      defaultMessage='Privacy'
                    />
                  </u>
                ),
              }}
            />
          </Text>
        </div>
      </Row>
      <div className={classes.informationWrapper}>
        <AutologyxInformation />
      </div>
    </div>
  );
};

export default ForgotPassword;
