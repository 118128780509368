import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_INFINITE_SCROLL,
} from 'components/Table/consts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SortingRule } from 'react-table';
import { getCurrentTable } from 'store/selectors/filtersSelectors';
import routes from 'utils/routingPaths';
import { useTableParams } from './useTableParams';
import {
  getSelectedObjectClassId,
  getTablesState,
} from 'store/selectors/preferencesSelectors';
import { setTablesState } from 'store/actions/preferencesActions';
import { Pagination, UsePaginationAndSortingProps } from './types';
import TablesType from 'utils/Enums/TablesType';

export const usePaginationAndSorting = ({
  isFetching,
  isUsingURL,
  withPagination,
  withoutSort,
  defaultSortColumn,
  pageSizes: tablePageSizes,
  resetPaginationRef,
  customTableName,
}: UsePaginationAndSortingProps) => {
  const [isStoredTableState, setIsStoredTableState] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();
  const currentTableStore = useSelector(getCurrentTable);
  const currentTable = customTableName ?? currentTableStore;
  const objectClassId = useSelector(getSelectedObjectClassId);
  const isObjectRecordsTable = currentTable === TablesType.ObjectRecords;
  const paramsTableState = useTableParams(
    currentTable,
    withoutSort,
    tablePageSizes,
    defaultSortColumn,
    withPagination
  );
  const [paginationState, setPaginationState] = useState<Pagination>({
    currentPage: 1,
    pageSize: 5,
  });

  const resetPagination = () => {
    if (paginationState.pageSize === 5 && paginationState.currentPage === 1) {
      //if paginationState is equal default values then there is no change - return false
      return false;
    }

    setPaginationState({ currentPage: 1, pageSize: 5 });
    return true;
  };

  if (resetPaginationRef) {
    resetPaginationRef.current = resetPagination;
  }

  const storedTablesState = useSelector(getTablesState);
  const storedCurrentTableState =
    (currentTable && storedTablesState?.[currentTable]) || paramsTableState;

  const dispatch = useDispatch();

  useEffect(() => {
    setIsStoredTableState(true);

    dispatch(
      setTablesState({
        tableName: currentTable,
        ...paramsTableState,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTable]);

  const onPaginationChange = useCallback(
    (page, pageSize) => {
      const isInfinite = isNaN(pageSize);

      if (isUsingURL) {
        dispatch(
          setTablesState({
            tableName: currentTable,
            ...storedCurrentTableState,
            pageSize: isInfinite ? DEFAULT_PAGE_SIZE_INFINITE_SCROLL : pageSize,
            currentPage: page,
            isInfinite,
          })
        );
        return;
      }

      setPaginationState({ pageSize, currentPage: page });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isUsingURL,
      currentTable,
      dispatch,
      storedCurrentTableState.sort,
      storedCurrentTableState.pageSize,
      storedCurrentTableState.currentPage,
    ]
  );

  /* for inifinite scroll */
  const onTableScroll = useCallback(() => {
    if (isFetching || !isUsingURL) return;

    dispatch(
      setTablesState({
        tableName: currentTable,
        ...storedCurrentTableState,
        currentPage: storedCurrentTableState.currentPage + 1,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentTable,
    dispatch,
    isFetching,
    storedCurrentTableState.isInfinite,
    storedCurrentTableState.pageSize,
    storedCurrentTableState.sort,
    storedCurrentTableState.currentPage,
  ]);

  const onChangeSort = useCallback(
    (sort: SortingRule<string>) => {
      if (!isUsingURL) {
        //to be implemented later
        return;
      }

      if (!!sort) {
        const sortValue = `${sort.desc ? '-' : ''}${sort.id}`;

        dispatch(
          setTablesState({
            tableName: currentTable,
            ...storedCurrentTableState,
            currentPage: 1,
            pageSize: storedCurrentTableState.isInfinite
              ? DEFAULT_PAGE_SIZE_INFINITE_SCROLL
              : storedCurrentTableState.pageSize,
            sort: sortValue,
            sortById: isObjectRecordsTable
              ? {
                  ...(storedCurrentTableState?.sortById || {}),
                  [objectClassId as string]: sortValue,
                }
              : undefined,
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isUsingURL,
      currentTable,
      dispatch,
      storedCurrentTableState.pageSize,
      storedCurrentTableState.isInfinite,
      isObjectRecordsTable,
      objectClassId,
    ]
  );

  const onPageChange = useCallback(
    (page: number) => {
      if (isUsingURL) {
        dispatch(
          setTablesState({
            tableName: currentTable,
            ...storedCurrentTableState,
            currentPage: page,
          })
        );
        return;
      }

      setPaginationState(prev => ({ ...prev, currentPage: page }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isUsingURL,
      currentTable,
      dispatch,
      storedCurrentTableState.sort,
      storedCurrentTableState.pageSize,
      storedCurrentTableState.isInfinite,
    ]
  );

  const onPageSizeChange = useCallback(
    size => {
      const isInfinite = isNaN(size);

      if (isUsingURL) {
        dispatch(
          setTablesState({
            tableName: currentTable,
            ...storedCurrentTableState,
            currentPage: 1,
            pageSize: isInfinite ? DEFAULT_PAGE_SIZE_INFINITE_SCROLL : +size,
            isInfinite,
          })
        );
        return;
      }

      setPaginationState(prev => ({ ...prev, pageSize: size }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTable, dispatch, storedCurrentTableState.sort, isUsingURL]
  );

  useEffect(() => {
    if (pathname === routes.OBJECT_CLASSES_CREATE) {
      return;
    }

    if (!isUsingURL) {
      return;
    }

    const savedSorting =
      objectClassId &&
      storedCurrentTableState?.sortById &&
      storedCurrentTableState?.sortById[objectClassId]
        ? storedCurrentTableState?.sortById[objectClassId]
        : '';

    const sort = isObjectRecordsTable
      ? savedSorting
      : storedCurrentTableState.sort;

    if (storedCurrentTableState.isInfinite) {
      history.replace({
        pathname,
        search: sort
          ? `?isInfinite=true&sort=${savedSorting}`
          : '?isInfinite=true',
      });
    } else {
      const {
        currentPage = 1,
        pageSize = DEFAULT_PAGE_SIZE,
      } = storedCurrentTableState;
      const paramsWithoutSort = {
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
      };
      const paginationQueryParams = sort
        ? {
            ...paramsWithoutSort,
            sort,
          }
        : paramsWithoutSort;

      history.replace({
        pathname,
        search: `?${new URLSearchParams(paginationQueryParams)}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    pathname,
    storedCurrentTableState.sort,
    storedCurrentTableState.sortById,
    storedCurrentTableState.pageSize,
    storedCurrentTableState.currentPage,
    storedCurrentTableState.isInfinite,
    history,
    isUsingURL,
    isObjectRecordsTable,
    objectClassId,
  ]);

  const setPagination = (currentPage: number, pageSize: number) => {
    if (isUsingURL) {
      dispatch(
        setTablesState({
          tableName: currentTable,
          ...storedCurrentTableState,
          currentPage,
          pageSize,
        })
      );
    }
  };

  const pagination = useMemo(
    () => {
      const {
        sort: _,
        isInfinite: __,
        currentPage,
        ...paginationState
      } = storedCurrentTableState;

      return {
        ...paginationState,
        currentPage: isStoredTableState
          ? currentPage
          : paramsTableState.currentPage,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storedCurrentTableState.pageSize, storedCurrentTableState.currentPage]
  );

  const sort = useMemo(() => {
    const savedSorting =
      objectClassId &&
      storedCurrentTableState?.sortById &&
      storedCurrentTableState?.sortById[objectClassId]
        ? storedCurrentTableState?.sortById[objectClassId]
        : defaultSortColumn ?? '';

    if (isUsingURL) {
      return isObjectRecordsTable ? savedSorting : storedCurrentTableState.sort;
    }
    return defaultSortColumn ?? '';
  }, [
    isObjectRecordsTable,
    objectClassId,
    storedCurrentTableState,
    isUsingURL,
    defaultSortColumn,
  ]);

  const isInfinite = useMemo(
    () => !!storedCurrentTableState.isInfinite && isUsingURL,
    [storedCurrentTableState.isInfinite, isUsingURL]
  );

  return {
    onPageSizeChange,
    onPageChange,
    onChangeSort,
    onTableScroll,
    onPaginationChange,
    sort,
    isInfinite,
    pagination: isUsingURL ? pagination : paginationState,
    setPagination,
    tablePageSizes,
  };
};
