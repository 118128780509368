import React from 'react';
import TablesType from 'utils/Enums/TablesType';
import CustomCells from 'utils/Enums/CustomCells';
import Status from './Status';
import CommaSeparatedString from './CommaSeparatedString';
import State from './State';
import { Assignee, CustomCellProps } from './types';
import BoldedPartOfText from './BoldedPartOfText';
import { useTableContext } from 'contexts/TableContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCustomCellStyles } from './styles';
import { CheckIcon } from '../Icon';
import CustomTag from '../CustomTag';
import clsx from 'clsx';
import { ApiUserAvatar } from 'utils/types/api/users.types';
import UserCell from './UserCell';
import SingleUserAvatarWithCounter from './UserCell/SingleUserAvatarWithCounter';
import TaskStatusTag from 'components/TaskStatusTag';
import moment from 'moment';
import useDateFormat from 'hooks/useDateFormat';
import TooltipString from 'components/TooltipString';
import Tooltip from 'components/lib/Tooltip';
import MembershipTag from 'components/MembershipTag';
import TagContainer from 'components/TagContainer';
import { TagTypes } from 'components/TagContainer/enums';
import { TagNames } from 'pages/ObjectClasses/components/ObjectClassForm/ObjectClassDisplay/DisplayTable/types';
import { generateFormUrl } from 'pages/ObjectClasses/components/ObjectClassForm/ObjectClassForms/List/ActionMenuItems/utils';
import CopyText from './CopyText';
import FileLink from 'components/FileLink';
import { CustomAvatarGroup } from 'components/lib/Avatar';
import { AvatarItem } from 'components/lib/Avatar/types';
import { sortAvatarsAlphabeticallyComparison } from 'utils/sortAvatars';
import DocumentTemplateStatus from './DocumentTemplateStatus';
import UrlCell from './UrlCell';

const CustomCell = ({ table, column, row }: CustomCellProps) => {
  const intl = useIntl();
  const { searchValue } = useTableContext();
  const classes = useCustomCellStyles({});
  const { dateFormat } = useDateFormat();

  if (column === CustomCells.Membership && table === TablesType.Members) {
    const { membership } = row;

    return <MembershipTag {...{ membership }} />;
  }
  if (column === CustomCells.Status) {
    const { status } = row;

    if (table === TablesType.TaskTemplates) {
      return <Status {...{ status }} column={CustomCells.Status} />;
    }

    if (table === TablesType.Tasks) {
      return <TaskStatusTag {...{ status }} size={13} />;
    }
  }

  if (column === CustomCells.Assignees && table === TablesType.Tasks) {
    const users = row.current_assignees?.users || [];
    const assignees: AvatarItem[] = users.reduce(
      (prev: Assignee[], curr: Assignee) => [
        ...prev,
        {
          id: curr.id,
          firstName: curr.first_name,
          lastName: curr.last_name,
          accountType: curr.account_type,
          companyName: curr.company_name,
        },
      ],
      []
    );
    assignees.sort(sortAvatarsAlphabeticallyComparison);

    return (
      <CustomAvatarGroup items={assignees} limitItems={2} stopPropagation />
    );
  }

  if (table === TablesType.Tasks && column === CustomCells.DueDate) {
    const { due_date: dueDate } = row;
    const formattedDate = dueDate ? moment(dueDate).format(dateFormat) : null;

    return <>{formattedDate}</>;
  }

  if (
    column === CustomCells.Label &&
    [TablesType.ObjectClassesFields].includes(table)
  ) {
    const { label = '', is_identifier: isIdentifier = false } = row;

    return (
      <>
        <BoldedPartOfText value={label} boldPhrase={searchValue?.value} />
        {isIdentifier && (
          <CustomTag className={clsx([classes.tag, classes.identifier])}>
            <FormattedMessage
              id='enums.identifier'
              defaultMessage='Identifier'
            />
          </CustomTag>
        )}
      </>
    );
  }

  if (
    [
      CustomCells.Required,
      CustomCells.Unique,
      CustomCells.AutoCreateUse,
      CustomCells.Enabled,
      CustomCells.AddInEnabled,
      CustomCells.ManualDocumentGeneration,
    ].includes(column as CustomCells) &&
    [
      TablesType.ObjectClassesFields,
      TablesType.SingleSignOn,
      TablesType.CustomComponents,
      TablesType.DocumentTemplates,
    ].includes(table)
  ) {
    return (
      <>
        {row[column] && (
          <CheckIcon className={classes.booleanCheck} size={12} />
        )}
      </>
    );
  }

  if (table === TablesType.TaskTemplates || table === TablesType.Sequences) {
    if (column === CustomCells.TaskGroups) {
      const { task_group_templates = [] } = row;

      return (
        <CommaSeparatedString
          values={task_group_templates}
          highlightedColumnKey={CustomCells.TaskGroups}
        />
      );
    }
  }

  if (
    [
      TablesType.TaskGroups,
      TablesType.Roles,
      TablesType.EmailCollectors,
    ].includes(table)
  ) {
    if (column === CustomCells.ObjectRecordGroups) {
      const { object_classes } = row;

      return (
        <CommaSeparatedString
          values={object_classes}
          highlightedColumnKey={CustomCells.ObjectRecordGroups}
        />
      );
    }
  }

  if (table === TablesType.Sequences) {
    if (column === CustomCells.State) {
      const { state } = row;

      return <State stateId={state} {...{ column }} />;
    }

    if (column === CustomCells.APIActorsNames) {
      const { api_actor_names } = row;

      return <CommaSeparatedString values={api_actor_names} />;
    }
  }

  if (table === TablesType.ObjectClasses) {
    if (column === CustomCells.Relations) {
      const { relations } = row;

      return <CommaSeparatedString values={relations} />;
    }

    if (column === CustomCells.Owners) {
      const { owners: { total_number: total = 0, first = {} } = {} } = row;

      if (!first) return null;

      const {
        first_name: firstName,
        last_name: lastName,
        id,
      } = first as ApiUserAvatar;

      return (
        <SingleUserAvatarWithCounter {...{ total, firstName, lastName, id }} />
      );
    }
  }

  if (table === TablesType.Users) {
    if (column === CustomCells.Roles) {
      const { roles } = row;

      return <CommaSeparatedString values={roles} />;
    }
  }

  if (table === TablesType.Members && column === CustomCells.Name) {
    const { first_name, last_name, id } = row;

    return (
      <UserCell
        user={{
          id,
          first_name,
          last_name,
        }}
      />
    );
  }

  if (column === CustomCells.ObjectClass) {
    const objectClassName = row?._meta.labels?.object_class;

    return (
      <TooltipString text={objectClassName}>{objectClassName}</TooltipString>
    );
  }

  if (column === CustomCells.ObjectRecord) {
    return row.object_record;
  }

  if (column === CustomCells.ObjectName) {
    const objectName = row.object_name;

    return objectName === null ? (
      <Tooltip
        title={intl.formatMessage({
          id: 'misc.detailsRestricted',
          defaultMessage: 'Details restricted due to permissions',
        })}
      >
        ***
      </Tooltip>
    ) : (
      <TooltipString text={objectName}>{objectName}</TooltipString>
    );
  }

  if (column === CustomCells.Uuid) {
    const formUrl = generateFormUrl(row.uuid);

    return (
      <CopyText
        textToCopy={formUrl}
        message={intl.formatMessage({
          id: 'misc.copyUrl',
          defaultMessage: 'Copy URL',
        })}
        successMessage={intl.formatMessage({
          id: 'misc.urlCopied',
          defaultMessage: 'URL copied!',
        })}
      />
    );
  }
  if (column === CustomCells.TemplateFileName) {
    const { template_file_name, id } = row ?? {};
    return (
      <FileLink
        label={template_file_name}
        downloadedFileName={`${template_file_name}.docx`}
        documentTemplateID={id}
      />
    );
  }

  if (column === CustomCells.GeneratedDocument) {
    return <DocumentTemplateStatus {...row} />;
  }

  if (column === CustomCells.Enabled && table === TablesType.ClassForms) {
    const { is_enabled: isEnabled } = row;

    return (
      <TagContainer
        type={isEnabled ? TagTypes.Primary : TagTypes.Error}
        name={isEnabled ? TagNames.Enabled : TagNames.Disabled}
      />
    );
  }

  if (column === CustomCells.Publisher) {
    const { publisher, publisher_url } = row ?? {};
    if (publisher_url) {
      return <UrlCell value={publisher_url} label={publisher} />;
    }
    return publisher;
  }

  return null;
};

export default CustomCell;
