import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  SET_DOCUMENT_TEMPLATES_PANEL,
  APPEND_DOCUMENT_TEMPLATES_PANEL,
  RESET_DOCUMENT_TEMPLATES_PANEL,
  SET_DOCUMENT_TEMPLATES_PANEL_FETCHING,
  SET_DOCUMENT_TEMPLATES_PANEL_COLUMNS,
  SET_DOCUMENT_TEMPLATES_PANEL_RESTRICTIONS,
  SET_DOCUMENT_TEMPLATES_PANEL_ERROR,
  SET_DOCUMENT_TEMPLATES_PANEL_STATUS,
} from 'store/constants/documentTemplatePanel.consts';
import {
  appendTableContent,
  resetTable,
  setTableContent,
  setTableLoading,
  setTableColumns,
} from './tablesCommonStateModificators';
import {
  DocumentTemplatePanel,
  GeneratedDocument,
} from 'utils/types/api/documentTemplate.types';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import { CommonTableState } from 'utils/types/api/table.types';
import cloneDeep from 'lodash/cloneDeep';

const initialState = cloneDeep(INITIAL_TABLE_STATE);
const documentTemplatePanelReducer: Reducer<
  CommonTableState<DocumentTemplatePanel>,
  RootAction
> = (state = initialState, action) => {
  switch (action.type) {
    case SET_DOCUMENT_TEMPLATES_PANEL: {
      return setTableContent<
        DocumentTemplatePanel,
        typeof SET_DOCUMENT_TEMPLATES_PANEL
      >(state, action);
    }
    case APPEND_DOCUMENT_TEMPLATES_PANEL:
      return appendTableContent<
        DocumentTemplatePanel,
        typeof APPEND_DOCUMENT_TEMPLATES_PANEL
      >(state, action);

    case SET_DOCUMENT_TEMPLATES_PANEL_FETCHING:
      return setTableLoading<
        DocumentTemplatePanel,
        typeof SET_DOCUMENT_TEMPLATES_PANEL_FETCHING
      >(state, action);

    case RESET_DOCUMENT_TEMPLATES_PANEL:
      return resetTable<DocumentTemplatePanel>(state, initialState);

    case SET_DOCUMENT_TEMPLATES_PANEL_COLUMNS:
      return setTableColumns<
        DocumentTemplatePanel,
        typeof SET_DOCUMENT_TEMPLATES_PANEL_COLUMNS
      >(state, action);

    case SET_DOCUMENT_TEMPLATES_PANEL_RESTRICTIONS:
      return {
        ...state,
        restrictions: {
          limit_items: action.restrictions.limit_items_in_object_class,
        },
      };

    case SET_DOCUMENT_TEMPLATES_PANEL_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case SET_DOCUMENT_TEMPLATES_PANEL_STATUS: {
      const { id, status } = action;
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            generated_document: {
              ...(state.byId[id]?.generated_document ??
                ({} as GeneratedDocument)),
              status,
            },
          },
        },
      };
    }

    default:
      return state;
  }
};

export default documentTemplatePanelReducer;
