import React from 'react';
import { FormattedMessage } from 'react-intl';
import useChildClassFormBuilderNoPermissionStyles from './styles';
import { ChildClassFormBuilderNoPermissionProps } from './types';

const ChildClassFormBuilderNoPermission = ({
  overrideMessage,
}: ChildClassFormBuilderNoPermissionProps) => {
  const styles = useChildClassFormBuilderNoPermissionStyles({});
  return (
    <span className={styles.content}>
      {overrideMessage ?? (
        <FormattedMessage
          id='formBuilder.noViewPermission'
          defaultMessage={
            "You can't see this component as you do not have permissions to view the object class."
          }
        />
      )}
    </span>
  );
};

export default ChildClassFormBuilderNoPermission;
