import React from 'react';
import InPlaceEditSingleSelect from 'components/InPlaceEditControls/InPlaceEditSingleSelect';
import { mapCustomWidgetPropsToBaseInPlaceEditProps } from './utils';
import { Widget } from 'alx-dynamic-form';
import { Provider } from 'react-redux';
import { store } from 'store/store';

const AdaptedInPlaceEditSelectRadio: Widget = widgetProps => {
  const baseInPlaceEditProps = mapCustomWidgetPropsToBaseInPlaceEditProps<
    string,
    any
  >(widgetProps);

  const { options } = widgetProps;

  return (
    <Provider store={store}>
      <InPlaceEditSingleSelect
        {...{ ...baseInPlaceEditProps, options }}
        areRadios
        withUnderline
      />
    </Provider>
  );
};

export default AdaptedInPlaceEditSelectRadio;
