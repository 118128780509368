import React, { useEffect, useRef } from 'react';
import { Loader } from 'components/lib/Loader';
import { SelectOption as Option, Select } from 'components/lib/Select';
import { useIntl } from 'react-intl';
import useAutocompleteSelectStyles from './styles';
import clsx from 'clsx';
import { AutocompleteUsersSelectProps } from './types';
import useAutocompleteSelect from './hooks';
import NoMatchesFound from 'components/NoMatchesFound';
import { AUTOCOMPLETE_USERS_SELECT } from 'utils/testIds';
import { SearchBoldIcon } from 'components/Icon';
import useScrollableParent from 'hooks/useScrollableParent';
import { AutocompleteSelectValue } from 'utils/types/selectInput.types';
import { useTheme } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const AutocompleteUsersSelect = ({
  autocompleteUrl = '',
  checkOptionIsDisabled,
  className,
  getPopupContainer,
  onChange,
  placeholder,
  renderOption,
  value = [],
  handleOnBlur,
  disabled,
  selectMultiple = false,
  onClear,
  allowClear,
}: AutocompleteUsersSelectProps) => {
  const intl = useIntl();
  const theme = useTheme<ThemeState>();
  const classes = useAutocompleteSelectStyles({ disabled, theme });
  const {
    data,
    isOpenSelect,
    loading,
    handleChange,
    onBlur,
    onFocus,
    onSearch,
    searchValue,
  } = useAutocompleteSelect(
    autocompleteUrl,
    onChange,
    handleOnBlur,
    selectMultiple
  );

  const elementRef = useRef<HTMLDivElement>(null);

  const handleOnChange = (value: AutocompleteSelectValue) => {
    handleChange(value);
    // #36005 - The input needs to be un-focused to prevent unwanted behaviour.
    if (elementRef.current && !selectMultiple) {
      const el = elementRef.current.querySelector('input');
      if (el) el.blur();
    }
  };

  // blur select when it is disabled so it wont open immediately after enabling it again
  useEffect(() => {
    if (!disabled) return;

    onBlur();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const getScrollableParent = useScrollableParent(elementRef);

  return (
    // we add key from disabled value as Select component can't be safely blurred
    // which makes placeholder always visible even when user enters value
    <div ref={elementRef} key={disabled?.toString() || ''}>
      <Select
        getPopupContainer={getPopupContainer || getScrollableParent}
        showSearch
        open={isOpenSelect}
        className={clsx(classes.autocompleteSelect, className)}
        placeholder={
          placeholder ??
          intl.formatMessage({
            id: 'placeholders.searchForUsers',
            defaultMessage: 'Search for users',
          })
        }
        notFoundContent={loading ? <Loader size='small' /> : <NoMatchesFound />}
        filterOption={false}
        labelInValue
        onChange={handleOnChange}
        {...{
          value,
          onSearch,
          searchValue,
          onFocus,
          onBlur,
          disabled,
          onClear,
          allowClear,
        }}
        icon={<SearchBoldIcon size={12} className={classes.searchIcon} />}
        data-testid={AUTOCOMPLETE_USERS_SELECT}
      >
        {data.map(({ id, text, account_type, ...restOptionData }) => (
          <Option
            key={id}
            value={id}
            title={text}
            className={classes.selectItem}
            disabled={checkOptionIsDisabled({ id, account_type })}
          >
            {renderOption(
              { id, text, account_type, ...restOptionData },
              searchValue
            )}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default AutocompleteUsersSelect;
