import React from 'react';
import { FBCheckboxProps } from './types';
import { Checkbox } from 'components/lib/Checkbox';

const FBCheckbox: React.FC<FBCheckboxProps> = ({
  onChangeValue,
  isChecked = false,
  label = '',
  disabled = false,
  dataTestId,
}) => (
  <Checkbox
    data-testid={dataTestId}
    onChange={onChangeValue}
    checked={isChecked}
    {...{ disabled }}
  >
    {label}
  </Checkbox>
);

export default FBCheckbox;
