import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { getInPlaceEditFontSize } from '../components/commonStyles';

type UseInPlaceEditDateTimeStylesProps = {
  size?: 'small' | 'medium';
  isError?: boolean;
};

const useInPlaceEditDateTimeStyles = createUseStyles((theme: ThemeState) => {
  return {
    picker: ({ isError }: UseInPlaceEditDateTimeStylesProps = {}) => ({
      minHeight: 21,
      height: 21,
      padding: '0px 2px',
      position: 'relative',
      border: `1px solid ${
        isError ? theme['error-color'] : theme['primary-color']
      }`,
      boxShadow: isError
        ? theme['input-focus-shadow-error']
        : theme['input-focus-shadow'],
      borderRadius: 3,
      backgroundColor: 'white',
      '& input': {
        fontSize: ({ size }: UseInPlaceEditDateTimeStylesProps = {}) =>
          getInPlaceEditFontSize(size),
        lineHeight: 1,
      },
      '& .ant-picker-input': {
        width: 200,
      },
      '&.ant-picker-focused': {
        boxShadow: theme['input-focus-shadow'],
      },
    }),
    pickerDropdown: {
      zIndex: 1060,
    },
  };
});

export default useInPlaceEditDateTimeStyles;
