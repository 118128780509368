import React from 'react';
import { TagProps, CheckableTagProps } from 'antd/lib/tag';
import { Tag as AntTag } from 'antd';
import useTagStyles from './styles';
import clsx from 'clsx';

export const Tag: React.FC<TagProps> = ({ ...rest }) => {
  return <AntTag {...rest} />;
};

export const CheckableTag: React.FC<CheckableTagProps> = ({ ...rest }) => {
  return <AntTag.CheckableTag {...rest} />;
};

export const TagRounded: React.FC<TagProps> = ({ className, ...rest }) => {
  const classes = useTagStyles({});

  return <AntTag className={clsx([classes.roundedTag, className])} {...rest} />;
};
