import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useTabsStyles = createUseStyles((theme: ThemeState) => {
  return {
    tabBar: {
      '& .ant-tabs-nav': {
        margin: '0px 0px 15px 0px',
        '& .ant-tabs-nav-wrap .ant-tabs-nav-list': {
          '& button': {
            '&:hover:not(.ant-tabs-tab-disabled), &:active, &:focus': {
              color: theme['primary-color-medium'],
            },
            '&.ant-tabs-tab-active': {
              color: theme['primary-color'],
            },
          },
          '& .ant-tabs-ink-bar': {
            background: theme['primary-color'],
          },
        },
      },
      '& .ant-tabs-tab:hover': {
        color: theme['primary-color'],
      },
      '& .ant-tabs-tab': {
        paddingLeft: 20,
        paddingRight: 20,
        '& .ant-tabs-tab-btn': {
          color: theme['text-color'],
        },
        '&.ant-tabs-tab-active .ant-tabs-tab-btn': {
          fontWeight: 'bold',
        },
      },
    },
  };
});

export default useTabsStyles;
