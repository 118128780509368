import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useManageOwnersStyles = createUseStyles((theme: ThemeState) => {
  return {
    contentWrapper: {
      height: '100%',
      overflow: 'auto',
      padding: 15,
      display: 'flex',
      flexDirection: 'column',
    },
    headerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 10,
      gap: '5px',
    },
    text: {
      color: theme['text-color'],
      opacity: '.6',
    },
    emptyBox: {
      margin: [35, 0, 10],
    },
    scrollableContainer: {
      display: 'flex',
      position: 'relative',
    },
    buttonsWrapper: {
      marginTop: 'auto',
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 15,
    },
    buttonDisabled: {
      borderColor: `${theme['border-color-base']} !important`,
      background: `${theme['border-color-base']} !important`,
      '&:hover': {
        borderColor: `${theme['border-color-base']} !important`,
        background: `${theme['border-color-base']} !important`,
      },
    },
  };
});
