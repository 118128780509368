import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import Membership from 'utils/Enums/Membership';

const getTagColorsByMembership = (
  membership: Membership,
  theme: ThemeState
) => {
  switch (membership) {
    case Membership.Member:
      return {
        color: theme['chips-color'],
        backgroundColor: theme['chips-success-background-dark-opacity'],
      };
    case Membership.Owner:
      return {
        color: theme['primary-color'],
        backgroundColor: theme['info-bg'],
      };
    default:
      return {
        color: 'transparent',
        backgroundColor: 'transparent',
      };
  }
};

type UseMembershipTagStylesProps = {
  membership: Membership;
};

const useMembershipTagStyles = createUseStyles((theme: ThemeState) => {
  return {
    membershipTag: ({ membership }: UseMembershipTagStylesProps) => ({
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 600,
      padding: '3px 6px',
      marginLeft: 'auto',
      marginRight: 'auto',
      borderRadius: 3,
      border: 'none',
      ...getTagColorsByMembership(membership, theme),
    }),
  };
});

export default useMembershipTagStyles;
