import { RootAction } from 'store/reducers';
import { Reducer } from 'redux';
import {
  SET_IS_ABOUT_MODAL_VISIBLE,
  SET_API_VERSION,
} from 'store/constants/misc.consts';
import { ApiHistoryType, MiscState } from './types/misc.types';

const initialState: MiscState = {
  apiVersion: undefined,
  historyVersion: ApiHistoryType.NA,
  isAboutModalVisible: false,
};

const miscReducer: Reducer<MiscState, RootAction> = (
  state = initialState,
  action
) => {
  if (action.type === SET_API_VERSION) {
    return {
      ...state,
      apiVersion: action.payload.version,
      historyVersion: action.payload.historyVersion,
    };
  }
  if (action.type === SET_IS_ABOUT_MODAL_VISIBLE) {
    return {
      ...state,
      isAboutModalVisible: action.payload,
    };
  }
  return state;
};

export default miscReducer;
