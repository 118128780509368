import { Action } from 'redux';
import { RESET_STORE } from 'store/constants/store.consts';

export type resetStoreAction = Action<typeof RESET_STORE>;

export type storeActions = resetStoreAction;

export const resetStore = (): resetStoreAction => {
  return {
    type: RESET_STORE,
  };
};
