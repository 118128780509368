import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UsePickerStylesProps = {
  filtersSidebarWidth?: number;
};

const usePickerStyles = createUseStyles((theme: ThemeState) => {
  return {
    datepicker: {
      minHeight: 28,
      borderColor: theme['border-color-base'],
      '&.ant-picker-focused': {
        boxShadow: 'none',
      },
      '&:hover': {
        borderColor: theme['primary-color-medium'],
      },
      '&:focus, &:focus-within': {
        borderColor: theme['primary-color-medium'],
        boxShadow: theme['input-focus-shadow'],
      },
      '&:active': {
        borderColor: theme['primary-color-dark'],
      },
      '& .ant-picker-today-btn': {
        color: theme['primary-color'],
      },
      '& + div div .ant-picker-dropdown': {
        backgroundColor: 'black',
      },
      '&.ant-picker-range .ant-picker-active-bar': {
        background: theme['primary-color-dark'],
      },
      '& .ant-picker-range-separator svg': {
        width: 18,
      },
      '& .ant-picker-suffix': {
        color: theme['primary-color'],
      },
    },
    popupWrapper: ({ filtersSidebarWidth }: UsePickerStylesProps) => ({
      '& .ant-picker-panels': {
        flexDirection:
          filtersSidebarWidth && filtersSidebarWidth < 560 ? 'column' : 'row',
      },
    }),
    pickerDropdown: {
      '& .ant-picker-time-panel-column > li.ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner': {
        color: theme['primary-color'],
        background: theme['form-builder-background'],
        fontWeight: 700,
        '&:hover': {
          color: theme['primary-color'],
          background: theme['form-builder-background'],
        },
      },
      '& .ant-picker-ranges .ant-picker-preset > .ant-tag-blue': {
        background: 'none',
        fontWeight: 700,
      },
      '& .ant-picker-cell-in-view.ant-picker-cell-in-range': {
        color: theme['white-color'],
      },
    },
  };
});

export default usePickerStyles;
