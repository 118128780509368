import React from 'react';
import { DragAndDrop } from 'components/lib/FileUpload';
import { Dropdown } from 'components/lib/Dropdown';
import { InPlaceEditFileContentProps } from '../types';
import useInPlaceEditFileUploadStyles from '../styles';
import { FormattedMessage } from 'react-intl';
import { Loader } from 'components/lib/Loader';

const InPlaceEditFileContent = ({
  getPopupContainer,
  remountChangeTarget,
  maxItems = 100,
  value,
  readOnly,
  editWrapperRef,
  onChange,
  onUploadCompleted,
  onUpload,
  customOnRemove,
  loading,
  fieldLabel,
  shouldDownloadFileOnClick,
  recordIdentifier,
}: InPlaceEditFileContentProps) => {
  const styles = useInPlaceEditFileUploadStyles({});

  return (
    <Dropdown
      {...{ getPopupContainer, remountChangeTarget }}
      visible
      overlayClassName={styles.dropdownOverlay}
      overlay={
        <div ref={editWrapperRef} className={styles.dropdownContentWrapper}>
          {value?.length === maxItems && (
            <span className={styles.limitMessage}>
              <FormattedMessage
                id='misc.maxNumberOfFilesReached'
                defaultMessage='A maximum number of {maxFiles, plural, one {# file} other {# files}} has been reached.'
                values={{ maxFiles: maxItems }}
              />
            </span>
          )}
          <Loader wrapperClassName={styles.loader} spinning={loading}>
            <DragAndDrop
              recordIdentifier={recordIdentifier}
              fieldLabel={fieldLabel}
              small
              fileList={value}
              {...{
                maxItems,
                onChange,
                onUploadCompleted,
                onUpload,
                customOnRemove,
              }}
              readonly={readOnly}
              shouldDownloadFileOnClick={shouldDownloadFileOnClick}
            />
          </Loader>
        </div>
      }
    >
      <div className={styles.dropdownAnchor}></div>
    </Dropdown>
  );
};

export default InPlaceEditFileContent;
