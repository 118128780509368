import React from 'react';
import { useIntl } from 'react-intl';
import RequestSentPage from '../../../../components/RequestSentPage';

const PendingRequestPage: React.FC = () => {
  const intl = useIntl();

  return (
    <RequestSentPage
      title={intl.formatMessage({
        id: 'activateAccount.requestPending',
        defaultMessage: 'Your request is pending',
      })}
      subtitle={intl.formatMessage({
        id: 'activateAccount.receivedRequest',
        defaultMessage:
          'Our system administrators received your request. Once accepted, you will receive a new activation link.',
      })}
    />
  );
};

export default PendingRequestPage;
