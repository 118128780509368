import {
  ColumnDefinition,
  ExtendedUiSchema,
} from 'components/FormPreview/customFields/ObjectField/types';
import { FormDataValue } from 'components/FormPreview/types';
import partition from 'lodash/partition';
import { ObjectRecordDetails } from 'utils/types/api/objectRecords.types';

const createSectionComponentsDict = (
  uiSchema: ExtendedUiSchema
): MappedObject<string[]> =>
  Object.entries(uiSchema)
    .filter(([property]) => !property.includes('ui:'))
    .reduce(
      (dictionary, [key, sectionProperties]) => ({
        ...dictionary,
        [key]: sectionProperties?.['ui:columns'].flatMap(
          (column: ColumnDefinition) => column.components
        ),
      }),
      {}
    );

export const mapFlatFormDataToFormData = (
  flatFormData: MappedObject<FormDataValue>,
  uiSchema: ExtendedUiSchema
) => {
  const sectionComponentsDict = createSectionComponentsDict(uiSchema);

  return Object.entries(sectionComponentsDict).reduce(
    (prevValue, [sectionName, components]) => {
      const componentValues = components.reduce(
        (prevComponents, componentName) => {
          const componentValue = flatFormData[componentName];

          if (componentValue !== undefined && componentValue !== null)
            return {
              ...prevComponents,
              [componentName]: componentValue,
            };

          return prevComponents;
        },
        {}
      );

      return {
        ...prevValue,
        [sectionName]: componentValues,
      };
    },
    {}
  );
};

// any here because api has resposne type which essentially cant be typed :/
export const extractFormDataFromRecord = (data: any) => {
  const [formFields, recordFields] = partition(Object.entries(data), ([key]) =>
    key.includes('field')
  );

  const recordProperties = {
    id: data.id,
    modified_at: data.modified_at,
    created_at: data.created_at,
  };

  const formFieldsData = Object.fromEntries(formFields);

  const formDataWithRecordProperties = {
    ...formFieldsData,
    ...recordProperties,
  } as MappedObject<FormDataValue>;

  return {
    ...Object.fromEntries(recordFields),
    formData: formDataWithRecordProperties,
  } as ObjectRecordDetails;
};
