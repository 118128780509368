import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useUnsupportedFileFormatStyles = createUseStyles(
  (theme: ThemeState) => {
    return {
      container: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: '2rem',
        gap: '1rem',
      },
      noticeText: {
        textAlign: 'center',
        fontSize: '0.75rem',
        color: theme['disabled-color'],
      },
    };
  }
);
