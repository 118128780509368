import React from 'react';
import PageNotFound from 'components/PageNotFound';
import PermissionDenied from 'components/PermissionDenied';
import { StatusCodes } from 'http-status-codes';
import { ErrorComponentProps } from './types';
import { FormattedMessage } from 'react-intl';

const ErrorComponent = ({ error }: ErrorComponentProps) => {
  switch (error) {
    case StatusCodes.FORBIDDEN:
      return <PermissionDenied />;
    case StatusCodes.NOT_FOUND:
      return <PageNotFound />;
    default:
      return <FormattedMessage id='misc.error' defaultMessage='Error' />;
  }
};

export default ErrorComponent;
