import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import moment, { Moment } from 'moment';
import range from 'lodash/range';
import { toast } from 'components/lib/toast';
import ToastType from 'utils/Enums/ToastType';

export const useDatePicker = (
  showTime: boolean | undefined,
  withDateInTheFuture: boolean,
  onOk: ((v: Moment) => void) | undefined
) => {
  const intl = useIntl();

  const getDisabledTime = useCallback(
    (value: Moment | null, type: moment.unitOfTime.All) => {
      if (value && value.isSame(moment(), 'D')) {
        return range(0, moment().get(type));
      }

      return [];
    },
    []
  );

  const handleOnOk = useCallback(
    (savingDate: Moment) => {
      if (
        withDateInTheFuture &&
        !!showTime &&
        savingDate < moment().startOf('minutes')
      ) {
        toast(
          {
            title: intl.formatMessage({
              id: 'errors.the_date_must_be_in_the_future',
              defaultMessage: 'The date must be in the future.',
            }),
          },
          ToastType.Warning
        );
      }

      if (onOk) onOk(savingDate);
    },
    [intl, onOk, showTime, withDateInTheFuture]
  );

  const disabledTime = useMemo(
    () =>
      withDateInTheFuture && showTime
        ? (value: Moment | null) => ({
            disabledHours: () => getDisabledTime(value, 'hours'),
            disabledMinutes: (hour: number) =>
              hour <= moment().get('hour')
                ? getDisabledTime(value, 'minutes')
                : [],
          })
        : undefined,
    [getDisabledTime, withDateInTheFuture, showTime]
  );

  return {
    handleOnOk,
    disabledTime,
  };
};
