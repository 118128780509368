import React, { useRef } from 'react';
import { Avatar as AntAvatar } from 'antd';
import { GroupProps } from 'antd/lib/avatar';
import clsx from 'clsx';
import useAvatarStyles from './styles';
import { AvatarExtendedProps, AvatarsGroupProps } from './types';
import UserAvatar from 'components/UserAvatar';
import Popover from 'components/lib/Popover';
import Tooltip from 'components/lib/Tooltip';
import {
  AVATARS_GROUP,
  AVATARS_GROUP_MORE_AVATAR,
  AVATARS_GROUP_REST_ITEMS,
} from 'utils/testIds';
import useScrollableParent from 'hooks/useScrollableParent';

const LIMIT = 6;

export const Avatar: React.FC<AvatarExtendedProps> = ({
  isCountAvatar,
  className,
  size,
  ...rest
}) => {
  const classes = useAvatarStyles({ size });

  return (
    <AntAvatar
      {...{ ...rest, size }}
      className={clsx(className, {
        [classes.countAvatar]: isCountAvatar,
      })}
    />
  );
};

export const AvatarGroup: React.FC<GroupProps> = ({ ...rest }) => {
  return <AntAvatar.Group {...rest} />;
};

export const CustomAvatarGroup = ({
  items,
  disablePopover,
  moreItemsTooltipMessage,
  getPopupContainer,
  limitItems = LIMIT,
  stopPropagation = false,
  onClickShowMore,
  hiddenItems = 0,
  customMoreText,
  showAllInMore,
  hasFullUserDetails,
}: AvatarsGroupProps) => {
  const styles = useAvatarStyles({});
  const elementRef = useRef<HTMLDivElement>(null);

  const calculatedLimit =
    items.length >= limitItems ? limitItems - 1 : limitItems;
  const reducedItems = items.slice(0, calculatedLimit);
  const moreItems = showAllInMore ? items : items.slice(calculatedLimit);

  const getScrollableParent = useScrollableParent(elementRef);

  const onContainerClick = (e: React.MouseEvent<HTMLDivElement>) =>
    stopPropagation ? e.stopPropagation() : null;

  return (
    <div
      onClick={onContainerClick}
      ref={elementRef}
      className={styles.avatarsGroupWrapper}
      data-testid={AVATARS_GROUP}
    >
      {reducedItems.map(
        ({
          firstName,
          lastName,
          userGroup,
          id,
          accountType,
          isDeleted,
          email,
          company,
        }) =>
          id ? (
            <UserAvatar
              key={id}
              {...{
                id,
                userGroup,
                firstName,
                lastName,
                disablePopover,
                getPopupContainer,
                accountType,
                isDeleted,
                email,
                company,
                hasFullUserDetails,
              }}
              size='small'
            />
          ) : null
      )}
      {(items.length >= limitItems || hiddenItems > 0) && (
        <Popover
          getPopupContainer={getPopupContainer ?? getScrollableParent}
          placement='top'
          destroyTooltipOnHide
          overlayStyle={{ maxWidth: 'min(100%, 250px)' }}
          trigger='click'
          content={
            <div
              className={styles.moreItems}
              id={AVATARS_GROUP_REST_ITEMS}
              data-testid={AVATARS_GROUP_REST_ITEMS}
            >
              {moreItems.map(
                ({
                  firstName,
                  lastName,
                  userGroup,
                  id,
                  accountType,
                  isDeleted,
                }) =>
                  id ? (
                    <div key={id} className={styles.listItem}>
                      <UserAvatar
                        {...{
                          id,
                          userGroup,
                          firstName,
                          lastName,
                          disablePopover,
                          accountType,
                          isDeleted,
                        }}
                        size='small'
                        closeOnElementScroll={AVATARS_GROUP_REST_ITEMS}
                      />
                      <p>
                        {userGroup ? userGroup : `${firstName} ${lastName}`}
                      </p>
                    </div>
                  ) : null
              )}
            </div>
          }
          overlayClassName={styles.popover}
        >
          <Tooltip placement='top' title={moreItemsTooltipMessage}>
            <Avatar
              isCountAvatar
              size='small'
              className={styles.moreAvatar}
              data-testid={AVATARS_GROUP_MORE_AVATAR}
              onClick={onClickShowMore}
            >
              {customMoreText ?? `+${moreItems.length + hiddenItems}`}
            </Avatar>
          </Tooltip>
        </Popover>
      )}
    </div>
  );
};
