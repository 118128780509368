import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useInPlaceEditMultiselectStyles = createUseStyles((theme: ThemeState) => {
  return {
    vievWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      marginRight: -5,
      marginBottom: -3,
      marginTop: 2,
      maxWidth: 'calc(100% - 16px)',
    },
    viewOption: {
      backgroundColor: theme['info-bg'],
      fontWeight: 600,
      marginRight: 5,
      marginBottom: 5,
      borderRadius: 2,
      color: theme['primary-color'],
      padding: '0px 5px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
    },
    moreTextWrapper: {
      color: theme['primary-color'],
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    dropdownOverlay: {
      zIndex: 1060,
    },
  };
});

export default useInPlaceEditMultiselectStyles;
