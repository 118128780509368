import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import Membership from 'utils/Enums/Membership';

export const useMembership = (membership: Membership) => {
  const intl = useIntl();

  return useMemo(() => {
    switch (membership) {
      case Membership.Member:
        return intl.formatMessage({
          id: 'members.member',
          defaultMessage: 'Member',
        });
      case Membership.Owner:
        return intl.formatMessage({
          id: 'members.owner',
          defaultMessage: 'Owner',
        });

      default:
        return '';
    }
  }, [intl, membership]);
};
