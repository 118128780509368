import React from 'react';
import { Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';
import useTypographyStyles from './styles';

type HeadingProps = TitleProps;

const { Title } = Typography;

export const Heading: React.FC<HeadingProps> = ({ className, ...rest }) => {
  const classes = useTypographyStyles({});

  return <Title {...rest} className={`${classes.typography} ${className}`} />;
};
