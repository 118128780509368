import { RootAction } from 'store/reducers';
import { Reducer } from 'redux';
import {
  RESET_PERFERENCES,
  SET_ALL_PREFERENCES,
  SET_PREFERENCES,
  SET_PREFERENCES_LOADING,
  SET_SAVING_PREFERENCES,
} from 'store/constants/preferences.consts';
import flexLayoutDefaultJsonModel from 'components/lib/FlexLayout/default.json';
import { TableDensity } from 'components/Table/enums';
import moment from 'moment-timezone';
import { getDefaultSupportedLanguage } from 'utils/functions/getDefaultSupportedLanguage';
import { PreferencesState } from './types/preferences.types';
import { FORM_BUILDER_SIDEPANEL_MIN_WIDTH } from 'pages/TaskTemplates/components/TaskForm/consts';
import TablesType from 'utils/Enums/TablesType';
import { ViewMode } from 'contexts/types';
import {
  PreferencesTypes,
  TableUrlParams,
} from 'utils/types/api/preferences.types';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { ActiveTabGroups } from 'components/lib/FlexLayout/types';
import { NumbersSeparator } from 'utils/Enums/NumbersSeparator';
import { SupportedDateFormats } from 'utils/Enums/SupportedDateFormats';
import {
  TasksQuickFilterDates,
  TasksQuickFilterOwnership,
} from 'components/Table/QuickFilters/TasksQuickFilters/types';

export const preferencesInitialState: PreferencesState = {
  loading: true,
  saving: false,
  data: {
    [PreferencesTypes.GeneralPreferences]: {
      language: getDefaultSupportedLanguage(),
      isSidebarCollapsed: false,
      timezone: moment.tz.guess(),
      dateFormat: SupportedDateFormats.DDMMYYYY_WITH_SLASH,
      decimalFormat: NumbersSeparator.Dot,
      thousandSeparator: NumbersSeparator.Comma,
      formBuilderToolboxWidth: FORM_BUILDER_SIDEPANEL_MIN_WIDTH,
      isNavigationMenuPinned: true,
      selectedClassId: undefined,
    },
    [PreferencesTypes.TablePreferences]: {
      tables: {
        tasks: {},
        objects: {},
      },
    },
    [PreferencesTypes.FilterPreferences]: {
      filters: {
        objectRecords: [],
      },
      quickFilters: {
        tasks: {
          ownership: TasksQuickFilterOwnership.Default,
          dates: TasksQuickFilterDates.Default,
        },
      },
    },
    [PreferencesTypes.TableLayoutPreferences]: {
      tableDensity: TableDensity.Default,
      tablesViewMode: {} as MappedObject<ViewMode, TablesType>,
      tablesState: {} as MappedObject<TableUrlParams, TablesType>,
    },
    [PreferencesTypes.LayoutPreferences]: {
      flexLayoutJSONModel: flexLayoutDefaultJsonModel,
      panels: {
        openedPanels: [],
        activePanels: {} as MappedObject<FlexLayoutWindows, ActiveTabGroups>,
      },
      tasks: {
        taskPropertiesOpen: true,
      },
    },
  },
};

const preferencesReducer: Reducer<PreferencesState, RootAction> = (
  state = preferencesInitialState,
  action
) => {
  switch (action.type) {
    case SET_PREFERENCES: {
      return {
        ...state,
        loading: false,
        saving: false,
        data: {
          ...state.data,
          [action.payload.key]: action.payload.payload,
        },
      };
    }
    case SET_ALL_PREFERENCES: {
      return {
        ...state,
        data: {
          ...preferencesInitialState.data,
          ...action.payload,
        },
      };
    }
    case SET_PREFERENCES_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case SET_SAVING_PREFERENCES: {
      return {
        ...state,
        saving: action.saving,
      };
    }
    case RESET_PERFERENCES: {
      return preferencesInitialState;
    }
    default:
      return state;
  }
};

export default preferencesReducer;
