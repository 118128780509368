import moment from 'moment-timezone';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUserTimezone } from 'store/selectors/preferencesSelectors';
import { getSupportedTimezone } from 'utils/functions/getSupportedTimezone';

const useTimezone = () => {
  const timezone = getSupportedTimezone(
    useSelector(getUserTimezone) || moment.tz.guess()
  );

  const userTimezoneName = useMemo(() => {
    const offset = moment().tz(timezone).format('Z');

    return GlobalIntlSingleton.dynamicFormatMessage(
      {
        id: `enums.timezone.${timezone}`,
      },
      {
        offset,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone, GlobalIntlSingleton.intl.locale]);

  return {
    timezone,
    userTimezoneName,
  };
};

export default useTimezone;
