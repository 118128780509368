import clsx from 'clsx';
import { useContentWrapperContext } from 'contexts/ContentWrapperContext';
import { useTableContext } from 'contexts/TableContext';
import React, { HTMLAttributes, useCallback } from 'react';
import { TableResizerProps } from 'react-table';
import useTableWrapperStyles from './styles';

const Resizer = ({
  onMouseDown: onMouseDownCallback,
  className,
  ...rest
}: TableResizerProps & HTMLAttributes<HTMLDivElement>) => {
  const { disabledEdition } = useContentWrapperContext();
  const { preventInteractionWithColumns } = useTableContext();
  const isDisabled = disabledEdition || preventInteractionWithColumns;

  const onMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (isDisabled) return;

      if (onMouseDownCallback) onMouseDownCallback(event);

      document.body.setAttribute('style', 'cursor: col-resize !important');
    },
    [onMouseDownCallback, isDisabled]
  );

  const classes = useTableWrapperStyles({});

  return (
    <div
      {...{ ...rest, onMouseDown }}
      className={clsx([classes.resizer, { disabled: isDisabled }, className])}
    />
  );
};

export default Resizer;
