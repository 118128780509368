import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useSelectStyles = createUseStyles((theme: ThemeState) => {
  return {
    multiselect: {
      width: '100%',
      borderColor: theme['border-color-base'],
      '& .ant-select-selector': {
        borderColor: `${theme['border-color-base']}`,
      },
      '&:hover': {
        borderColor: theme['primary-color'],
      },
      '&.ant-select:not(.ant-select-disabled):hover .ant-select-selector': {
        borderColor: theme['primary-color'],
      },
      '&.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector': {
        borderColor: theme['primary-color'],
        boxShadow: theme['input-focus-shadow'],
      },
      '& .ant-select-selection-placeholder': {
        color: theme['placeholder-text-color'],
        borderColor: theme['border-color-base'],
      },
      '& .ant-select-clear': {
        top: '45%',
      },
    },
    arrowDown: {
      color: theme['secondary-color'],
    },
    dropdown: {
      '& .ant-select-item-option-selected:not(.ant-select-item-option-disabled)': {
        color: theme['primary-color'],
        fontWeight: 700,
        backgroundColor: theme['disabled-color-light'],
      },
    },
    readonly: {
      '& .ant-select-selector': {
        pointerEvents: 'none',
      },
      '&:hover, &:active': {
        '&, & .ant-select-selector': {
          borderColor: `${theme['border-color-base']} !important`,
          cursor: 'default',
        },
      },
    },
    withIcon: {
      '& .ant-select-selector': {
        paddingLeft: '34px !important',
      },
      '& .ant-select-selection-search-input': {
        paddingLeft: '23px !important',
      },
      // this block needs style: {position: relative} in parent element
      '& + svg': {
        position: 'absolute',
        zIndex: 1,
        left: '12px',
        top: '11px',
      },
    },
  };
});

export default useSelectStyles;
