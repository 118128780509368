import noop from 'lodash/noop';
import React, { useContext, createContext, useState } from 'react';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { ViewMenuContextType, ViewMenuValues, PanelsData } from '../types';

export const ViewMenuContext = createContext<ViewMenuContextType>({
  addPanelData: noop,
});

// handling if option in menu should be disabled etc.
export const ViewMenuContextProvider: React.FC = ({ children }) => {
  const [panels, setPanel] = useState<PanelsData | undefined>();

  const addPanelData = (key: FlexLayoutWindows, data?: ViewMenuValues) => {
    setPanel(prevPanels => {
      const current = prevPanels ? prevPanels[key] : {};

      return {
        ...(prevPanels || ({} as PanelsData)),
        [key]: data ? { ...current, ...data } : {},
      };
    });
  };

  return (
    <ViewMenuContext.Provider
      value={{
        addPanelData,
        panels,
      }}
    >
      {children}
    </ViewMenuContext.Provider>
  );
};

export const useViewMenuContext = () => useContext(ViewMenuContext);
