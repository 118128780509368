import React from 'react';
import { FormattedMessage } from 'react-intl';
import ActivateAccountForm from '../ActivateAccountForm';
import useActivateAccountPageStyles from './styles';
import { Heading } from '../../../../components/lib/Heading';
import CardWrapper from '../../../../components/CardWrapper';
import {
  ACTIVATION_ACCOUNT_SUBTITLE_TESTID,
  ACTIVATION_ACCOUNT_TITLE_TESTID,
} from 'utils/testIds';

const ActivateAccountPage = () => {
  const classes = useActivateAccountPageStyles({});

  return (
    <CardWrapper>
      <Heading
        level={4}
        className={classes.title}
        data-testid={ACTIVATION_ACCOUNT_TITLE_TESTID}
      >
        <FormattedMessage
          id='activateAccount.activateYourAccount'
          defaultMessage='Activate your user account'
        />
      </Heading>
      <p
        className={classes.subtitle}
        data-testid={ACTIVATION_ACCOUNT_SUBTITLE_TESTID}
      >
        <FormattedMessage
          id='activateAccount.welcome'
          defaultMessage='Welcome to Autologyx. Please complete a couple of details to get started'
        />
      </p>
      <ActivateAccountForm />
    </CardWrapper>
  );
};

export default ActivateAccountPage;
