import { createContext, useContext } from 'react';
import noop from 'lodash/noop';
import { TasksPanelContextProps } from './types';

const TasksPanelContext = createContext<TasksPanelContextProps>({
  setMode: noop,
  setTask: noop,
  setStage: noop,
});

export const TasksPanelContextProvider = TasksPanelContext.Provider;
export const useTasksPanelContext = () => useContext(TasksPanelContext);
