import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { getAlertColors } from './utils';
import { AlertProps } from 'reactstrap';

type UseAlertStylesProps = {
  type?: AlertProps['type'];
};

const useAlertStyles = createUseStyles((theme: ThemeState) => {
  return {
    alert: ({ type }: UseAlertStylesProps) => {
      const { backgroundColor, color } = getAlertColors(type, theme);

      return {
        paddingTop: 7,
        paddingBottom: 6,
        paddingLeft: 6,
        width: 'fit-content',
        backgroundColor,
        '& img': {
          position: 'initial',
          marginRight: 9,
        },
        '& span': {
          fontSize: 12,
          fontWeight: 600,
          color,
        },
      };
    },
  };
});

export default useAlertStyles;
