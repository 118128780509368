export const getTouchedData = <T extends {}>(
  initialData: T | undefined,
  data: T
) => {
  const changedFields = Object.entries<valueof<T>>(data).reduce<T | {}>(
    (result, [key, value]) => {
      if (
        initialData &&
        JSON.stringify(initialData[key as keyof T]) ===
          JSON.stringify(data[key as keyof T])
      ) {
        return result;
      }

      return {
        ...result,
        [key]: value,
      };
    },
    {}
  );

  let changedFieldsWithEmpties = { ...changedFields };

  if (initialData)
    Object.keys(initialData).forEach(key => {
      const value = data[key as keyof T];

      if (!value && initialData[key as keyof T])
        changedFieldsWithEmpties = {
          ...changedFieldsWithEmpties,
          [key]: value !== undefined ? value : null,
        };
    });

  return changedFieldsWithEmpties;
};
