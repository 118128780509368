import { FormBuilderField } from 'components/formBuilder/formBuilder/FormBuilderContext/types';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { TableState } from 'react-table';

export const useTableHasChanges = (
  tableState: TableState<object>,
  initialTableState?: TableState<object>,
  initialRecordsCols?: FormBuilderField[] | undefined,
  currentRecordsCols?: FormBuilderField[] | undefined
) => {
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    let isSame = true;
    if (initialTableState) {
      isSame = isEqual(tableState, initialTableState);
    }
    // don't bother checking if already has changes or initialRecordsCols is undefined
    if (isSame && initialRecordsCols) {
      const initialColumnsSet = new Set(initialRecordsCols?.map(col => col.id));
      const currentRecordsColsSet = new Set(
        currentRecordsCols?.map(col => col.id)
      );
      isSame =
        initialColumnsSet.size === currentRecordsColsSet.size &&
        Array.from(initialColumnsSet).every(x => currentRecordsColsSet.has(x));
    }
    setHasChanges(!isSame);
  }, [initialTableState, tableState, initialRecordsCols, currentRecordsCols]);

  return hasChanges;
};
