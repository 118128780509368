import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useChildClassPaginationStyles = createUseStyles((theme: ThemeState) => ({
  paginationWrapper: {
    color: theme['secondary-color'],
    marginLeft: 17,
    alignItems: 'flex-end',
  },
  buttonsWrapper: {
    '& > .ant-space-item:not(:last-child)': {
      marginRight: '5px !important',
    },
  },
}));

export default useChildClassPaginationStyles;
