import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useChildRecordSummaryPanelHeaderStyles = createUseStyles(
  (theme: ThemeState) => ({
    objectName: {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      marginBottom: 20,
    },
    eyeIcon: {
      color: theme['default-red'],
      marginRight: 10,
    },
    headerContainer: {
      padding: 10,
    },
    button: {
      padding: 10,
    },
    disabled: {
      pointerEvents: 'none',
    },
  })
);

export default useChildRecordSummaryPanelHeaderStyles;
