export const BASIC_TOOLBAR = [
  'bold',
  'italic',
  '|',
  'alignment',
  '|',
  'numberedList',
  'bulletedList',
];

export const TOOLBAR = [
  'heading',
  '|',
  'fontSize',
  'fontFamily',
  '|',
  'bold',
  'italic',
  'underline',
  'subscript',
  'superscript',
  'strikethrough',
  'highlight',
  '|',
  'alignment',
  '|',
  'numberedList',
  'bulletedList',
  '|',
  'indent',
  'outdent',
  '|',
  'todoList',
  'link',
  'blockQuote',
  'imageUpload',
  'insertTable',
  'mediaEmbed',
  '|',
  'undo',
  'redo',
];

export const INSTRUCTIONS_TOOLBAR = [
  'fontSize',
  '|',
  'bold',
  'italic',
  'fontColor',
  '|',
  'alignment',
  '|',
  'numberedList',
  'bulletedList',
  '|',
  'link',
  'imageUpload',
];
