import LoaderOverlay from 'components/LoaderOverlay';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getPermissions } from 'store/actions/permissionsActions';
import { getPreferences } from 'store/actions/preferencesActions';
import { fetchApiVersion } from 'store/actions/miscActions';
import {
  getHelpMappingPreferences,
  getLandingPagePreferences,
} from 'store/actions/globalPreferencesActions';
import { fetchUserDetails } from 'store/actions/authActions';
import { AppDispatch } from 'store/store';

const LoggedInGlobalActionsWrapper: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      await dispatch(getPermissions());
      await dispatch(getPreferences());
      await dispatch(fetchUserDetails());
      await dispatch(fetchApiVersion());
      await dispatch(getHelpMappingPreferences());
      await dispatch(getLandingPagePreferences());
      setIsLoading(false);
    };

    loadData();
  }, [dispatch]);

  return <>{isLoading ? <LoaderOverlay /> : children}</>;
};

export default LoggedInGlobalActionsWrapper;
