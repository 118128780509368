import { Action } from 'redux';
import {
  TOGGLE_SIDEBAR,
  SET_SIDEBAR_WIDTH,
  SET_SIDEBAR_DATA,
} from 'store/constants/flexLayout.const';
import { SidebarData } from 'store/reducers/types/flexLayout.types';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';

interface ToggleSidebarAction extends Action<typeof TOGGLE_SIDEBAR> {
  payload: boolean;
}

interface SetSidebarWidthAction extends Action<typeof SET_SIDEBAR_WIDTH> {
  payload: number;
}

export interface SetSidebarDataAction extends Action<typeof SET_SIDEBAR_DATA> {
  sidebarData: SidebarData;
}

export type SidebarActions =
  | SetSidebarDataAction
  | SetSidebarWidthAction
  | ToggleSidebarAction;

export const toggleSidebar = (value: boolean): ToggleSidebarAction => {
  return {
    type: TOGGLE_SIDEBAR,
    payload: value,
  };
};

export const setSidebarWidth = (value: number): SetSidebarWidthAction => {
  return {
    type: SET_SIDEBAR_WIDTH,
    payload: value,
  };
};

export const setSidebarData = (
  key: FlexLayoutWindows,
  data: MappedObject<unknown>
): SetSidebarDataAction => {
  return {
    type: SET_SIDEBAR_DATA,
    sidebarData: {
      id: key,
      data,
    },
  };
};
