import { RootState } from 'store/reducers';

export const permissionsSelector = (state: RootState) => state.permissions;
export const arePermissionsLoadingSelector = (state: RootState) =>
  state.permissions.loading;

export const userPermissionViewSelector = (state: RootState) =>
  state.permissions.users.view;

export const objectClassesPermissionListSelector = (state: RootState) =>
  state.permissions.object_classes.list;

export const userGroupsPermissionListSelector = (state: RootState) =>
  state.permissions.user_groups.list;

export const userGroupsPermissionEditSelector = (state: RootState) =>
  state.permissions.user_groups.edit;

export const objectRecordsPermissionListSelector = (state: RootState) =>
  state.permissions.object_records.list;

export const companyDetailsPermissionEditSelector = (state: RootState) =>
  state.permissions.company_details.edit;
