export enum GTagEventButtonName {
  OpenFilterEditor = 'open_filter_editor',
  FilteredResults = 'filtered_results',
  ShowThisSectionHeader = 'show_this_section_header',
  Columns = 'columns',
  ColumnRation = 'column_ratios',
  AddConditionalVisibility = 'add_conditional_visibility',
  RemoveConditionalVisibility = 'remove_conditional_visibility',
  OpenRecord = 'open_record',
  Logo = 'logo',
  Help = 'help',
  Create = 'create',
  ColumnConfiguration = 'column_configuration',
  SwitchView = 'switch_view',
  Remove = 'remove',
  TestAuthenticationObjects = 'test_authentication_object',
  AddField = 'add_field',
  ViewUsage = 'view_usage',
  FieldEditDbClick = 'field_edit_db_click',
  EditField = 'edit_field',
  ViewFieldUsage = 'view_field_usage',
  RemoveField = 'remove_field',
  AddPermissionSet = 'add_permission_set',
  RemovePermissionSet = 'remove_permission_set',
  TestMailSettings = 'test_mail_settings',
  CreateRecord = 'create_record',
  AutomaticallyCreateUser = 'automatically_create_user',
  Preview = 'preview',
  AddApprovalCondition = 'add_approval_condition',
  RemoveApprovalCondition = 'remove_approval_condition',
  PreviewLandingPage = 'preview_landing_page',
}

export enum GtagEvent {
  ButtonClick = 'button_click',
}
