import { useSelector } from 'react-redux';
import { getCurrentTable } from 'store/selectors/filtersSelectors';
import defaultColumns from 'utils/defaultColumns';

export default (customTableName?: string): string[] | undefined => {
  const currentTableNameStore = useSelector(getCurrentTable);
  const currentTableName = customTableName ?? currentTableNameStore;

  if (!currentTableName) {
    return undefined;
  }

  return (defaultColumns as MappedObject<string[]>)[currentTableName];
};
