import React, { useCallback, useEffect, useRef, useState } from 'react';
import { apiCall } from 'utils/api';
import { parseError } from 'utils/parseError';
import { ResponseError } from 'utils/types/errorResponse';
import { RequestType } from 'utils/types';

type UseDataReturnType<T> = [
  T | undefined,
  {
    loading: boolean;
    error: ResponseError | undefined;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    fetchData: (
      customUrl?: string
    ) => Promise<{
      data: T | undefined;
      status?: number;
      error?: ResponseError;
    }>;
  }
];

const useData = <T>(
  url: string,
  options?: {
    initialData?: T;
    fetchOnLoad?: boolean;
    requestType?: RequestType;
    dataTransformFn?: (data: any) => T;
    params?: MappedObject<string | number>;
    initialLoadingState?: boolean;
  }
): UseDataReturnType<T> => {
  const {
    initialData = undefined,
    fetchOnLoad = true,
    requestType = 'get',
    dataTransformFn = undefined,
    params = undefined,
    initialLoadingState = false,
  } = options || {};

  const [data, setData] = useState<T | undefined>(initialData);
  const [loading, setLoading] = useState(fetchOnLoad || initialLoadingState);
  const [error, setError] = useState<ResponseError | undefined>(undefined);

  const isUnmounted = useRef(false);

  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const fetchData = useCallback(
    async (customUrl?: string) => {
      try {
        if (!isUnmounted.current) {
          setLoading(true);
          setError(undefined);
        }

        const { data, status } = await apiCall[requestType]<T>(
          customUrl || url,
          {
            params,
          }
        );

        if (!isUnmounted.current) {
          if (dataTransformFn) setData(dataTransformFn(data));
          else setData(data);

          setLoading(false);
        }

        return { data: dataTransformFn ? dataTransformFn(data) : data, status };
      } catch (err) {
        if (!isUnmounted.current) {
          setError(parseError(err));
        }

        return { error: parseError(err), data: undefined, status: undefined };
      } finally {
        if (!isUnmounted.current) {
          setLoading(false);
        }
      }
    },
    [requestType, url, params, dataTransformFn]
  );

  useEffect(() => {
    if (fetchOnLoad) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [data, { loading, error, fetchData, setLoading }];
};

export default useData;
