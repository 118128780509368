export const SET_SEQUENCES = 'SET_SEQUENCES';
export const APPEND_SEQUENCES = 'APPEND_SEQUENCES';
export const SET_SEQUENCES_FETCHING = 'SET_SEQUENCES_FETCHING';
export const SET_TOTAL_SEQUENCES = 'SET_TOTAL_SEQUENCES';
export const RESET_SEQUENCES = 'RESET_SEQUENCES';
export const SET_SEQUENCES_COLUMNS = 'SET_SEQUENCES_COLUMNS';
export const RESET_SEQUENCES_COLUMNS = 'RESET_SEQUENCES_COLUMNS';
export const SET_SEQUENCES_SELECTED_ROW = 'SET_SEQUENCES_SELECTED_ROW';
export const SET_SEQUENCES_RESTRICTIONS = 'SET_SEQUENCES_RESTRICTIONS';
export const SET_SEQUENCE_DEFAULT_TASK_ASSIGNEES =
  'SET_SEQUENCE_DEFAULT_TASK_ASSIGNEES';
export const SET_SEQUENCE_DEFAULT_TASK_ASSIGNEES_LOADING =
  'SET_SEQUENCE_DEFAULT_TASK_ASSIGNEES_LOADING';
