import noop from 'lodash/noop';
import React, {
  useCallback,
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react';
import { TaskOwnershipContextType } from './types';

const TaskOwnershipContext = createContext<TaskOwnershipContextType>({
  shouldBeConfirmationDisplayed: false,
  shouldBeAssignOwnerDisplayed: false,
  onConfirm: undefined,
  title: '',
  subtitle: '',
  confirmLabel: '',
  cancelLabel: '',
  setOnConfirm: noop,
  setShouldBeConfirmationDisplayed: noop,
  setShouldBeAssignOwnerDisplayed: noop,
  refetchData: noop,
  setTitle: noop,
  setSubtitle: noop,
  setConfirmLabel: noop,
  onConfirmCallback: noop,
  setCancelLabel: noop,
});

export const useTaskOwnershipContext = () => useContext(TaskOwnershipContext);

export const TaskOwnershipContextConsumer = TaskOwnershipContext.Consumer;

export const TaskOwnershipContextProvider: React.FC<{
  refetchData: Function;
}> = ({ children, refetchData }) => {
  const [
    shouldBeConfirmationDisplayed,
    setShouldBeConfirmationDisplayed,
  ] = useState(false);
  const [
    shouldBeAssignOwnerDisplayed,
    setShouldBeAssignOwnerDisplayed,
  ] = useState(false);
  const [onConfirm, setOnConfirm] = useState<Function>();
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [confirmLabel, setConfirmLabel] = useState('');
  const [cancelLabel, setCancelLabel] = useState('');

  const onConfirmCallback = useCallback(async () => {
    if (onConfirm) {
      await onConfirm();
    }
    setShouldBeConfirmationDisplayed(false);
  }, [onConfirm]);

  const value = useMemo(
    () => ({
      shouldBeConfirmationDisplayed,
      shouldBeAssignOwnerDisplayed,
      refetchData,
      title,
      subtitle,
      confirmLabel,
      setConfirmLabel,
      onConfirm,
      setShouldBeConfirmationDisplayed,
      setShouldBeAssignOwnerDisplayed,
      setOnConfirm,
      setTitle,
      setSubtitle,
      onConfirmCallback,
      cancelLabel,
      setCancelLabel,
    }),
    [
      cancelLabel,
      confirmLabel,
      onConfirm,
      onConfirmCallback,
      refetchData,
      shouldBeAssignOwnerDisplayed,
      shouldBeConfirmationDisplayed,
      subtitle,
      title,
    ]
  );

  return (
    <TaskOwnershipContext.Provider value={value}>
      {children}
    </TaskOwnershipContext.Provider>
  );
};
