import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  SET_FORMS,
  SET_FORMS_FETCHING,
  APPEND_FORMS,
  RESET_FORMS,
  SET_FORMS_COLUMNS,
  RESET_FORMS_COLUMNS,
  SET_FORMS_SELECTED_ROW,
} from 'store/constants/forms.consts';
import {
  appendTableContent,
  resetTable,
  setTableContent,
  setTableLoading,
  resetTableColumns,
  setTableColumns,
  setSelectedRow,
} from './tablesCommonStateModificators';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import { Form } from 'utils/types/api/forms.types';
import { CommonTableState } from 'utils/types/api/table.types';

import cloneDeep from 'lodash/cloneDeep';
const initialState = cloneDeep(INITIAL_TABLE_STATE);

const forms: Reducer<CommonTableState<Form>, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_FORMS: {
      return setTableContent<Form, typeof SET_FORMS>(state, action);
    }
    case APPEND_FORMS:
      return appendTableContent<Form, typeof APPEND_FORMS>(state, action);
    case SET_FORMS_FETCHING:
      return setTableLoading<Form, typeof SET_FORMS_FETCHING>(state, action);

    case RESET_FORMS:
      return resetTable<Form>(state, initialState);

    case RESET_FORMS_COLUMNS:
      return resetTableColumns<Form>(state, initialState);

    case SET_FORMS_COLUMNS:
      return setTableColumns<Form, typeof SET_FORMS_COLUMNS>(state, action);
    case SET_FORMS_SELECTED_ROW:
      return setSelectedRow<Form, typeof SET_FORMS_SELECTED_ROW>(state, action);
    default:
      return state;
  }
};

export default forms;
