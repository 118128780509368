import React from 'react';
import Empty from 'components/lib/Empty';
import { ReactComponent as NoData } from 'img/illustrations/empty-box.svg';
import useEmptyTableStyles from './styles';
import { EmptyDataProps } from './types';

const EmptyData: React.FC<EmptyDataProps> = ({
  image,
  title,
  description,
  hasListPermissions,
  className,
}) => {
  const classes = useEmptyTableStyles({});

  return (
    <Empty
      image={image || <NoData />}
      description={
        <>
          {hasListPermissions !== false && (
            <span className={classes.title}>{title}</span>
          )}
          <span>{description}</span>
        </>
      }
      {...{ className }}
    />
  );
};

export default EmptyData;
