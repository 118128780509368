import { useCallback, useState } from 'react';

type UseToggleReturnedType = [
  boolean,
  {
    toggle: () => void;
    toggleOff: () => void;
    toggleOn: () => void;
  }
];
export const useToggle = (
  initialValue: boolean | undefined = false
): UseToggleReturnedType => {
  const [value, setValue] = useState(initialValue);

  const toggleOff = useCallback(() => setValue(false), []);
  const toggleOn = useCallback(() => setValue(true), []);

  const toggle = useCallback(() => setValue(prevValue => !prevValue), []);

  return [value, { toggle, toggleOff, toggleOn }];
};
