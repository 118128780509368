export const nonProtectedRoutes = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  ACTIVATE_ACCOUNT: '/accounts/activate/:token?',
  FORGOTTEN_PASSWORD: '/accounts/forgot-password',
  RESET_PASSWORD: '/accounts/reset-password/:token?',
  SET_EXPIRED_PASSWORD: '/accounts/password-expired/:token?',
  SINGLE_SIGN_ON_CALLBACK: '/single-sign-on/callback/:id/',
  COMPLETE_TASK_BY_1TC: '/tasks/1tc/:id/complete/',
};

const routes = {
  HOMEPAGE: '/',
  MY_ACCOUNT: '/my-account',

  WORKSPACE: '/workspace',
  RECORDS: '/workspace/records',
  CREATE_RECORD: '/workspace/records/create',
  RECORD: '/workspace/records/:id',

  TASKS: '/workspace/tasks',
  TASK: '/workspace/tasks/:id',
  TASK_VIEW: '/workspace/tasks/:id/view',
  TASK_COMPLETE: '/workspace/tasks/:id/complete',

  SYSTEM_CONFIGURATION: '/system-configuration',

  OBJECT_CLASSES: '/system-configuration/object-classes',
  OBJECT_CLASS: '/system-configuration/object-classes/:id',
  OBJECT_CLASSES_CREATE: '/system-configuration/object-classes/create',
  OBJECT_CLASSES_EDIT: '/system-configuration/object-classes/:id/edit',

  RELATIONAL_MODELLER: '/system-configuration/object-model',

  TASK_TEMPLATES: '/system-configuration/task-templates',
  TASK_TEMPLATE: '/system-configuration/task-templates/:id',
  TASK_TEMPLATE_EDIT: '/system-configuration/task-templates/:id/edit',
  TASK_TEMPLATE_CREATE: '/system-configuration/task-templates/create',
  TASK_GROUP_TEMPLATES_CREATE:
    '/system-configuration/task-group-templates/create',
  TASK_GROUP_TEMPLATES: '/system-configuration/task-group-templates/:id',
  TASK_GROUP_TEMPLATES_EDIT:
    '/system-configuration/task-group-templates/:id/edit',
  TASK_GROUPS: '/system-configuration/task-group-templates',
  SEQUENCES: '/system-configuration/sequences',
  SEQUENCE_CREATE: '/system-configuration/sequences/create',
  SEQUENCE: '/system-configuration/sequences/:id',
  SEQUENCE_EDIT: '/system-configuration/sequences/:id/edit',
  SEQUENCE_DESIGN: '/system-configuration/sequences/:id/design',
  LANDING_PAGE: '/system-configuration/landing-page',
  THEME: '/system-configuration/theme',

  CLASS_FORMS: '/forms',
  CLASS_FORM_DETAILS: '/forms/:id',
  CLASS_FORM_COMPLETE: '/forms/:id/complete',

  ADMINISTRATION: '/administration',
  USERS: '/administration/users',
  USER: '/administration/users/:id',
  USER_CREATE: '/administration/users/create',
  USER_EDIT: '/administration/users/:id/edit',

  USER_GROUPS: '/administration/user-groups',
  USER_GROUP_CREATE: '/administration/user-groups/create',
  USER_GROUP: '/administration/user-groups/:id',
  USER_GROUP_EDIT: '/administration/user-groups/:id',

  ROLES: '/administration/roles',
  ROLE: '/administration/roles/:id',
  ROLES_CREATE: '/administration/roles/create',
  ROLES_EDIT: '/administration/roles/:id/edit',

  COMPANY_DETAILS: '/administration/company-details',

  STANDARD_AUTHENTICATION: '/administration/standard-authentication',

  SINGLE_SIGN_ONS: '/administration/single-sign-on',
  SINGLE_SIGN_ON: '/administration/single-sign-on/:id',
  SINGLE_SIGN_ON_EDIT: '/administration/single-sign-on/:id/edit',
  SINGLE_SIGN_ON_CREATE: '/administration/single-sign-on/create',

  AUTHENTICATION_OBJECTS: '/administration/authentication-objects',
  AUTHENTICATION_OBJECT_CREATE: '/administration/authentication-objects/create',
  AUTHENTICATION_OBJECT: '/administration/authentication-objects/:id',
  AUTHENTICATION_OBJECT_EDIT: '/administration/authentication-objects/:id/edit',

  CUSTOM_COMPONENTS: '/administration/custom-components',
  CUSTOM_COMPONENT: '/administration/custom-components/:id',
  CUSTOM_COMPONENTS_EDIT: '/administration/custom-components/:id/edit',
  CUSTOM_COMPONENTS_CREATE: '/administration/custom-components/create',

  OUTBOUND_MAIL_SETUP: '/administration/outbound-mail-setup',
  HELP_MAPPING: '/help-mapping',
  ERROR_404: '*',
};

export default routes;
