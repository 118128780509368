import { StatusCodes } from 'http-status-codes';
import { DefaultPermissions } from 'utils/types/api/permissions.types';
import { ErrorStatus } from 'utils/types/errorResponse';

export const getErrorStatus = (
  apiErrorStatus: ErrorStatus | undefined,
  permissions: DefaultPermissions | undefined,
  perm: keyof DefaultPermissions
) => {
  if (apiErrorStatus)
    return apiErrorStatus === true ? StatusCodes.FORBIDDEN : apiErrorStatus;

  if (permissions && !permissions[perm]) return StatusCodes.FORBIDDEN;
};

export const getErrorStatusWithPermsList = (
  apiErrorStatus: ErrorStatus | undefined,
  permissions: DefaultPermissions | undefined,
  permislist: Array<keyof DefaultPermissions>
) => {
  if (apiErrorStatus)
    return apiErrorStatus === true ? StatusCodes.FORBIDDEN : apiErrorStatus;

  if (permissions && !permislist.some(perm => permissions[perm]))
    return StatusCodes.FORBIDDEN;
};
