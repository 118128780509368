export interface ComponentsDictionary {
  [key: string]: {
    Component: () => JSX.Element;
    name: string;
    onClose?: () => void;
    availablePaths: string[];
    disableUndockButton?: boolean;
  };
}

export interface ExternalComponent {
  id: string;
  componentName: string;
}

export enum ActiveTabGroups {
  records = 'records',
}
