import useData from 'hooks/useData';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ObjectClassFieldTypes } from 'utils/types/api/objectClassesFields.types';
import { useFormBuilderContext } from '../FormBuilderContext';
import { FormBuilderField } from '../FormBuilderContext/types';
import { FormTypes } from '../FormBuilder/types';
import { HIDDEN_FIELDS_ON_RECORD_LISTING } from './consts';
import { SearchDataParams } from 'components/SearchInput/types';
import { DraggableComponents } from './enums';
import { addFieldPrefixToFormBuilderFields } from 'components/formBuilder/utils/dynamicSchemaUtils';
import { useSelector } from 'react-redux';
import { selectChildClassesByParentId } from 'store/selectors/childClassesSelectors';

const REQUEST_PARAMS = 'limit=50';

const useInputsContainer = (fieldsUrl?: string, classId?: number) => {
  const {
    formBuilderFields,
    setFormBuilderFields,
    formType,
    allFieldNames,
  } = useFormBuilderContext();
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState<string | undefined>();
  const properties = usePropertyFields();

  const formBuilderObjectClasses =
    useSelector(selectChildClassesByParentId(classId)) ?? [];

  const hiddenProperties = useMemo(
    () =>
      formType === FormTypes.RecordListing
        ? HIDDEN_FIELDS_ON_RECORD_LISTING
        : [],
    [formType]
  );

  const [, { loading, fetchData }] = useData(
    `${fieldsUrl}?${REQUEST_PARAMS}&offset=0` || '',
    {
      dataTransformFn: (data: any) => ({
        fields: data.results as FormBuilderField[],
        hasMore: !!data.next,
      }),
      fetchOnLoad: false,
    }
  );

  useEffect(() => {
    if (formBuilderFields.length === 0) fetchMore(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMore = async (offset: number, searchValue?: string) => {
    if (searchValue !== undefined) setSearch(searchValue);

    const value = searchValue !== undefined ? searchValue : search;

    const url = !!value
      ? `${fieldsUrl}?${REQUEST_PARAMS}&offset=0&label__icontains=${value}`
      : `${fieldsUrl}?${REQUEST_PARAMS}&offset=${offset}`;

    const { data: { fields = [], hasMore = false } = {} } = fieldsUrl
      ? (await fetchData(url)) || {}
      : {};

    setHasMore(!!hasMore);

    const fieldsWithPrefix = addFieldPrefixToFormBuilderFields(fields);

    if (!!fieldsUrl && !url.includes('offset=0'))
      setFormBuilderFields(prevState => [...prevState, ...fieldsWithPrefix]);
    else
      setFormBuilderFields(
        !!fieldsUrl ? [...properties, ...fieldsWithPrefix] : fieldsWithPrefix
      );
  };

  // this is valid only when in record listing where we always have only one section
  // as section names are also in allFieldNames - we dont remove section bc of identifier - it is not counted
  const componentsCount =
    formType === FormTypes.RecordListing ? allFieldNames.length : undefined;

  const handleSearchChange = ({ value }: SearchDataParams) =>
    fetchMore(0, value);

  return {
    formBuilderFields,
    hasMore,
    fetchMore,
    loading,
    search,
    properties,
    componentsCount,
    handleSearchChange,
    hiddenProperties,
    formBuilderObjectClasses,
  };
};

export default useInputsContainer;

export const usePropertyFields = (): FormBuilderField[] => {
  const intl = useIntl();

  return [
    {
      id: -2,
      label: intl.formatMessage({
        id: 'formBuilder.alxId',
        defaultMessage: 'ALX ID',
      }),
      type: ObjectClassFieldTypes.Int,
      alias: DraggableComponents.PropertyId,
      is_identifier: false,
      order: 0,
      extras: {},
    },
    {
      id: -3,
      label: intl.formatMessage({
        id: 'formBuilder.createdDateTime',
        defaultMessage: 'Created date/time',
      }),
      type: ObjectClassFieldTypes.Datetime,
      alias: DraggableComponents.PropertyCreatedDateTime,
      is_identifier: false,
      order: 0,
      extras: {},
    },
    {
      id: -4,
      label: intl.formatMessage({
        id: 'formBuilder.updatedDateTime',
        defaultMessage: 'Updated date/time',
      }),
      type: ObjectClassFieldTypes.Datetime,
      alias: DraggableComponents.PropertyUpdatedDateTime,
      is_identifier: false,
      order: 0,
      extras: {},
    },
    {
      id: -1,
      label: intl.formatMessage({
        id: 'formBuilder.owners',
        defaultMessage: 'Owners',
      }),
      type: ObjectClassFieldTypes.Array,
      alias: DraggableComponents.Owners,
      is_identifier: false,
      order: 0,
      extras: {},
    },
  ];
};

export const useComponentLimits = () => {
  const { componentsLimits, componentsAmmount } = useFormBuilderContext();

  const isSectionLimitExceeded =
    componentsLimits?.sections !== undefined &&
    componentsAmmount.sections >= componentsLimits.sections;

  const isInstructionLimitExceeded =
    componentsLimits?.instructions !== undefined &&
    componentsAmmount.instructions >= componentsLimits.instructions;

  const isComponentLimitExceeded =
    componentsLimits?.components !== undefined &&
    componentsAmmount.components >= componentsLimits.components;

  return {
    isComponentLimitExceeded,
    isInstructionLimitExceeded,
    isSectionLimitExceeded,
  };
};
