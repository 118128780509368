import React, { useMemo } from 'react';
import { Menu } from 'antd';
import {
  SystemConfigurationIcon,
  WorkManagementIcon,
  AdministrationIcon,
  RecordsIcon,
  CubeIcon,
  NotesIcon,
  RelationalModeller,
  SequencesIcon,
  AuthObjectIcon,
  CompanyDetailsIcon,
  StandardAuthIcon,
  UserManagementIcon,
  UserManagementSettingsIcon,
  UsersIcon,
  KeyIcon,
  OutboundMailIcon,
  ThemeIcon,
  LandingPageIcon,
  CustomComponentsIcon,
} from 'components/Icon';
import useLayoutStyles from 'components/lib/Layout/styles';
import { useSideMenuContext } from './context/SideMenuContext';
import routes from 'utils/routingPaths';
import CollapsedMenuItem from './CollapsedMenuItem';

const CollapsedSideMenu = () => {
  const classes = useLayoutStyles({});
  const { activeMenuKey } = useSideMenuContext();

  const isWorkspaceExpanded = useMemo(
    () => activeMenuKey.includes(routes.WORKSPACE),
    [activeMenuKey]
  );
  const isSystemConfigExpanded = useMemo(
    () => activeMenuKey.includes(routes.SYSTEM_CONFIGURATION),
    [activeMenuKey]
  );
  const isAdministrationExpanded = useMemo(
    () => activeMenuKey.includes(routes.ADMINISTRATION),
    [activeMenuKey]
  );

  return (
    <Menu
      className={classes.collapsedSidebarMenu}
      openKeys={activeMenuKey}
      mode='inline'
    >
      <CollapsedMenuItem
        route={routes.WORKSPACE}
        Icon={WorkManagementIcon}
        isSubmenu
      />
      {isWorkspaceExpanded && (
        <>
          <CollapsedMenuItem route={routes.RECORDS} Icon={RecordsIcon} />
          <CollapsedMenuItem route={routes.TASKS} Icon={NotesIcon} />
        </>
      )}
      <CollapsedMenuItem
        route={routes.SYSTEM_CONFIGURATION}
        Icon={SystemConfigurationIcon}
        isSubmenu
      />
      {isSystemConfigExpanded && (
        <>
          <CollapsedMenuItem route={routes.OBJECT_CLASSES} Icon={CubeIcon} />
          <CollapsedMenuItem
            route={routes.RELATIONAL_MODELLER}
            Icon={RelationalModeller}
          />
          <CollapsedMenuItem route={routes.TASK_TEMPLATES} Icon={NotesIcon} />
          <CollapsedMenuItem
            route={routes.TASK_GROUP_TEMPLATES}
            Icon={NotesIcon}
          />
          <CollapsedMenuItem route={routes.SEQUENCES} Icon={SequencesIcon} />
          <CollapsedMenuItem
            route={routes.LANDING_PAGE}
            Icon={LandingPageIcon}
          />
          <CollapsedMenuItem route={routes.THEME} Icon={ThemeIcon} />
        </>
      )}
      <CollapsedMenuItem
        route={routes.ADMINISTRATION}
        Icon={AdministrationIcon}
        isSubmenu
      />
      {isAdministrationExpanded && (
        <>
          <CollapsedMenuItem route={routes.USERS} Icon={UserManagementIcon} />
          <CollapsedMenuItem
            route={routes.ROLES}
            Icon={UserManagementSettingsIcon}
          />
          <CollapsedMenuItem route={routes.USER_GROUPS} Icon={UsersIcon} />
          <CollapsedMenuItem
            route={routes.COMPANY_DETAILS}
            Icon={CompanyDetailsIcon}
          />
          <CollapsedMenuItem
            route={routes.STANDARD_AUTHENTICATION}
            Icon={StandardAuthIcon}
          />
          <CollapsedMenuItem route={routes.SINGLE_SIGN_ONS} Icon={KeyIcon} />
          <CollapsedMenuItem
            route={routes.AUTHENTICATION_OBJECTS}
            Icon={AuthObjectIcon}
          />
          <CollapsedMenuItem
            route={routes.CUSTOM_COMPONENTS}
            Icon={CustomComponentsIcon}
          />
          <CollapsedMenuItem
            route={routes.OUTBOUND_MAIL_SETUP}
            Icon={OutboundMailIcon}
          />
        </>
      )}
    </Menu>
  );
};

export default CollapsedSideMenu;
