import React from 'react';
import ConfirmationModal from 'components/ConfirmationModal';
import { NoPermissionModalProps } from './types';
import { useIntl } from 'react-intl';
import ErrorIcon from 'img/icons/exclamation-mark.svg';

const NoPermissionModal = ({
  visible,
  onConfirm,
  subtitle,
  title,
}: NoPermissionModalProps) => {
  const intl = useIntl();

  return (
    <ConfirmationModal
      image={ErrorIcon}
      {...{ visible, onConfirm }}
      subtitle={subtitle}
      confirmationButtonLabel={intl.formatMessage({
        id: 'misc.uppercaseOk',
        defaultMessage: 'OK',
      })}
      title={
        title ??
        intl.formatMessage({
          id: 'misc.taskNoPermissionsTitle',
          defaultMessage: 'The task is no longer available',
        })
      }
    />
  );
};

export default NoPermissionModal;
