import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAppliedFilters from 'hooks/useAppliedFilters';
import useBackToList from 'hooks/useBackToList';
import useFilterEditor from 'components/FilterEditor/hooks';
import { BreadcrumbsListProps } from './types';
import { useSelector } from 'react-redux';
import { selectStack } from 'store/selectors/nestedObjectRecordsSelctors';
import BreadcrumbsSkeleton from './components/BreadcrumbsSkeleton';

export const BreadcrumbsList: React.FC<BreadcrumbsListProps> = ({
  routes = [],
  excludedPaths = [],
}) => {
  const nestedRecordsStack = useSelector(selectStack);
  const appliedFilters = useAppliedFilters();
  const { showFilters, resetFilters } = useFilterEditor();
  const params = useParams<MappedObject<string>>();
  const { generateBackPath, url } = useBackToList();
  const filteredRoutes = routes.filter(
    ({ path }) => !excludedPaths.includes(path)
  );
  const [isTooltipVisible, setIsTooltipVisible] = React.useState(false);

  useEffect(() => {
    setIsTooltipVisible(nestedRecordsStack.length > 1);
  }, [nestedRecordsStack]);

  return (
    <>
      <BreadcrumbsSkeleton
        {...{
          filteredRoutes,
          appliedFilters,
          nestedRecordsStack,
          showFilters,
          resetFilters,
          generateBackPath,
          isTooltipVisible,
          params,
          url,
        }}
      />
    </>
  );
};
