import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const getDisabledInputStyle = (theme: ThemeState) => ({
  backgroundColor: `${theme['disabled-input-gray']}!important`,
  color: `${theme['placeholder-text-color']}!important`,
  borderColor: `${theme['border-color-base']}!important`,
  minHeight: 35,
});

const useGlobalStyle = createUseStyles((theme: ThemeState) => {
  return {
    form: {
      '& .ant-input': {
        minHeight: 35,
        paddingTop: 4,
        paddingBottom: 4,
        borderColor: `${theme['border-color-base']}`,
        '&:hover': {
          borderColor: theme['primary-color-medium'],
        },
        '&:focus, &:focus-within': {
          borderColor: theme['primary-color-medium'],
          boxShadow: theme['input-focus-shadow'],
        },
      },
      '& .ant-input-number': {
        minHeight: 35,
        borderColor: `${theme['border-color-base']}`,
        '& .ant-input-number-input': {
          height: 32,
        },
      },
      '& .ant-picker': {
        height: 35,
        borderColor: `${theme['border-color-base']}`,
      },
      '& .ant-select-selector': {
        paddingTop: `2.5px!important`,
        paddingBottom: `2.5px!important`,
        minHeight: 35,
        alignItems: 'center',
      },
    },
    resizeHandler: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 3,
      height: '100%',
      backgroundColor: theme['border-color-base'],
      cursor: 'ew-resize',
      '&:hover': {
        backgroundColor: theme['primary-color'],
      },
    },
    normalFont: {
      fontWeight: 'normal',
    },
    withoutTextTransform: {
      textTransform: 'none',
    },
    disabledTextHighlight: {
      '-webkit-touch-callout': 'none',
      '-webkit-user-select': 'none',
      '-khtml-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
      'user-select': 'none',
    },
    noPaddingRight: {
      paddingRight: '0 !important',
    },
    inputWrapper: {
      '& .ant-form-item-control-input-content': {
        display: 'grid',
      },
    },
    disabledInput: {
      '&.ant-input': getDisabledInputStyle(theme),
      '&.ant-input-number-disabled': getDisabledInputStyle(theme),
      '&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector': getDisabledInputStyle(
        theme
      ),
      '&.ant-input[disabled]': getDisabledInputStyle(theme),
      '&.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector': {
        boxShadow: 'none',
      },
      '& .ant-select-selector': {
        ...getDisabledInputStyle(theme),
        '& .ant-tag': {
          color: theme['primary-color'],
          border: 'none',
        },
      },
      '& .ck-content': {
        ...getDisabledInputStyle(theme),
        paddingTop: 1.5,
        paddingBottom: 1.5,
        '& i': {
          '&::selection': {
            background: '#4f8fd9!important',
          },
        },
      },
      '& .ant-tag:hover': {
        opacity: 1,
      },
      '& .ant-radio-disabled': {
        '& .ant-radio-inner': {
          backgroundColor: 'transparent',
        },
        '&.ant-radio-checked .ant-radio-inner': {
          '&:after': {
            backgroundColor: `${theme['primary-color-opacity']}!important`,
          },
        },
      },
    },
    app: {
      '& .ant-form-item-explain': {
        minHeight: 0,
      },
      '& mark': {
        padding: 0,
        backgroundColor: 'inherit',
      },
    },
    autoSaveWrapper: {
      flexDirection: 'column',
      display: 'flex',
      width: '100%',
      maxWidth: '500px',
    },
    autoSaveWrapperArea: {
      display: 'flex',
      width: '100%',
    },
  };
});

export default useGlobalStyle;
