import { createUseStyles } from 'react-jss';

const useModalStyles = createUseStyles({
  modalWrapper: {
    '& .ant-modal-close': {
      outline: 0,
    },
    '& .ant-modal-title': {
      fontWeight: 'bold',
    },
  },
});

export default useModalStyles;
