import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseInputStylesProps = {
  spacingWithin?: string | number;
  icon?: string;
};

const useInputStyles = createUseStyles((theme: ThemeState) => {
  return {
    input: {
      borderColor: `${theme['border-color-base']}`,
      '&:hover': {
        borderColor: theme['primary-color-medium'],
      },
      '&:focus, &:focus-within': {
        borderColor: theme['primary-color-medium'],
        boxShadow: theme['input-focus-shadow'],
      },
      '&.numericInputContainer:focus-within': {
        boxShadow: 'none',
      },
      '&:active': {
        borderColor: theme['primary-color-dark'],
      },
      '& .ant-input:hover': {
        borderColor: theme['primary-color-medium'],
      },
      '& .ant-input:focus, .ant-input-focused': {
        borderColor: theme['primary-color-medium'],
        boxShadow: theme['input-focus-shadow'],
      },
      '& .ant-btn-primary': {
        background: theme['primary-color'],
        borderColor: theme['primary-color'],
      },
      '& .ant-btn-primary:hover': {
        background: theme['primary-color-medium'],
        borderColor: theme['primary-color-medium'],
      },
      '&.ant-input::placeholder': {
        color: theme['placeholder-text-color'],
        borderColor: `${theme['border-color-base']} !important`,
      },
    },
    readonly: {
      '&:hover, &:active, &:focus': {
        borderColor: `${theme['border-color-base']} !important`,
        boxShadow: 'none',
      },
    },
    cursorAuto: {
      cursor: 'auto',
    },
    noResize: {
      resize: 'none',
    },
    counter: {
      display: 'flex',
      justifyContent: 'flex-end',
      fontWeight: 400,
      color: theme['disabled-color'],
      fontSize: 12,
      marginTop: ({ spacingWithin }: UseInputStylesProps = {}) =>
        spacingWithin ?? 4,
      minWidth: 50,
    },
    customInput: {
      position: 'relative',
      width: '100%',
      maxWidth: '700px',
      '& .ant-input': {
        paddingRight: '30px',
        borderColor: `${theme['border-color-base']} !important`,
        '&:hover': { borderColor: `${theme['primary-color']} !important` },
        '&:focus': {
          borderColor: `${theme['primary-color']} !important`,
          boxShadow: `${theme['input-focus-shadow']} !important`,
        },
      },
    },
    inputIconWrapper: {
      position: 'absolute',
      display: 'flex',
      top: '0',
      right: 11,
      height: '100%',
    },
    textareaIconWrapper: {
      position: 'absolute',
      display: 'flex',
      top: 19,
      right: 11,
    },
    icon: {
      width: '16px',
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
      color: theme['primary-color'],
    },
    textareaIcon: {
      backgroundImage: ({ icon }: UseInputStylesProps = {}) =>
        icon ? `url(${icon})` : 'none',
      paddingRight: 26,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'calc(100% - 5px) 5px',
      backgroundSize: 16,
    },
    inputIcon: {
      backgroundImage: ({ icon }: UseInputStylesProps = {}) =>
        icon ? `url(${icon})` : 'none',
      paddingRight: 26,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'calc(100% - 5px) center',
      backgroundSize: 16,
    },
    error: {
      '& .ant-input': {
        borderColor: `${theme['error-color']} !important`,
        '&:hover': { borderColor: `${theme['error-color']} !important` },
        '&:focus': {
          borderColor: `${theme['error-color']} !important`,
          boxShadow: `${theme['input-focus-shadow-error']} !important`,
        },
      },
    },
    urlInputsWrapper: {
      display: 'flex',
    },
    urlInputsProtocol: {
      width: '100px',
      marginRight: '2px',
    },
    withLineCounter: {
      paddingLeft: '2rem',
      boxSizing: 'border-box',
    },
    withIcon: {
      paddingRight: '2.25rem',
    },
    lineCounter: {
      position: 'absolute',
      top: 1,
      left: 1,
      overflowY: 'hidden',
      color: theme['disabled-switch-gray'],
      backgroundColor: theme['white-color'],
      border: 'none',
      width: '1.5rem',
      alignText: 'center',
      padding: '4px 0 !important;',
      textAlign: 'center',
      height: '100% !important',
      maxHeight: 'calc(100% - 3px)',
      outline: 'none',
    },
  };
});

export default useInputStyles;
