import { TextArea } from 'components/lib/Input';
import React, { useEffect } from 'react';
import { TLN } from './TLNscript';
import './tln.css';
import useLineCountTextareaStyles from './styles';
import { FormikLineCountTextareaProps, LineCountTextareaProps } from './types';
import { useField, useFormikContext, Field } from 'formik';
import { FormikFormItem } from 'components/lib/Form';
import { fixMultipleWhiteSpaces } from 'components/Inputs/hooks';
import uniq from 'lodash/uniq';
import { useWindowContext } from 'components/lib/FlexLayout/WindowContext';
import { LINE_TEXT_AREA_ID } from 'utils/elementsIds';
import clsx from 'clsx';

const LineCountTextarea = ({
  onChange,
  value,
  id,
  onBlur,
  options,
  disabled,
  maxOptionsCount = 50,
  optionMaxLength,
  ...rest
}: LineCountTextareaProps) => {
  const classes = useLineCountTextareaStyles({});
  const { windowRef } = useWindowContext();

  const elem = windowRef?.document?.getElementById(id);
  const widowDocument = windowRef?.document;

  useEffect(() => {
    if (!widowDocument || (widowDocument && elem))
      TLN.append_line_numbers(id, widowDocument);
  }, [elem, id, widowDocument, windowRef]);

  useEffect(() => {
    if (!widowDocument || (widowDocument && elem))
      TLN.update_line_number_on_external_change(id, widowDocument);
  }, [value, id, windowRef, elem, widowDocument]);

  const onOptionsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const newOptions = value
      .split('\n')
      .slice(0, maxOptionsCount)
      .map(option => {
        const fixedOption = fixMultipleWhiteSpaces(option);

        return optionMaxLength
          ? fixedOption.substring(0, optionMaxLength)
          : fixedOption;
      });

    onChange(newOptions);
  };

  const onOptionsBlur = () => {
    if (onBlur) {
      const uniqueOptions = uniq(options.filter(Boolean) || []).map(option =>
        option.trim()
      );

      onBlur(uniqueOptions);
    }
  };

  return (
    <div
      className={clsx(classes.LineCountTextarea, {
        [classes.disabledTextarea]: disabled,
      })}
      id={LINE_TEXT_AREA_ID}
    >
      <TextArea
        {...{ ...rest, value, id, disabled }}
        onChange={onOptionsChange}
        onBlur={onOptionsBlur}
      />
    </div>
  );
};

export const FormikLineCountTextarea = <T extends object>({
  name,
  onBlur,
  ...props
}: FormikLineCountTextareaProps) => {
  const [{ value }, { touched, error }] = useField(name);
  const { setFieldValue } = useFormikContext<T>();

  const handleChange = (value: string[]) => setFieldValue(name, value);

  const handleBlur = (values: string[]) => {
    if (onBlur) onBlur(values);
    handleChange(values.filter(Boolean));
  };

  return (
    <FormikFormItem
      {...{ name }}
      validateStatus={touched && error ? 'error' : undefined}
    >
      <Field {...{ name }}>
        {() => (
          <LineCountTextarea
            value={value ? value.join('\n') : value}
            onChange={handleChange}
            onBlur={handleBlur}
            id={name}
            {...props}
          />
        )}
      </Field>
    </FormikFormItem>
  );
};

export default LineCountTextarea;
