import { ExclamationMarkSquare } from 'components/Icon';
import { Alert } from 'components/lib/Alert';
import React from 'react';
import { ALERT_TYPE_TESTID } from 'utils/testIds';
import useLimitMessage from './hooks';
import { useLimitMessageStyles } from './styles';
import { LimitMessageProps } from './types';

const LimitMessage = (props: LimitMessageProps) => {
  const { message, notificationType } = useLimitMessage(props);
  const classes = useLimitMessageStyles(notificationType);

  if (!notificationType) return null;

  return (
    <div
      className={classes.wrapper}
      data-testid={`${ALERT_TYPE_TESTID}${notificationType}`}
    >
      <Alert
        banner
        {...{ message }}
        icon={<ExclamationMarkSquare size={18} />}
        className={classes.alert}
      />
    </div>
  );
};

export default LimitMessage;
