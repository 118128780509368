import React from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import Tooltip from 'components/lib/Tooltip';
import { useToggle } from 'hooks/useToggle';
import { EVENT_DATA_CHIP } from 'utils/testIds';
import {
  useEventData,
  useEventDateTime,
  useEventSourceName,
  useGeneratedDocumentDownload,
} from './hooks';
import useEventsListStyles from './styles';
import EventData from './EventData';
import EventDataExpand from './EventDataExpand';
import { EventItemProps } from './types';

const EventItem = ({ event, refreshData }: EventItemProps) => {
  const styles = useEventsListStyles({});
  const [isExpanded, { toggle }] = useToggle();

  const {
    event_id,
    event_type,
    event_datetime,
    source_id,
    source_name,
    event_data,
  } = event;
  const eventDateTime = useEventDateTime(event_datetime);
  const eventSourceName = useEventSourceName(source_name, source_id);
  const {
    items,
    label,
    EventIcon,
    fieldType,
    isMultiple,
    title,
  } = useEventData(event_data, event_type);
  const handleDocumentDownload = useGeneratedDocumentDownload(
    event_id,
    refreshData
  );

  return (
    <div className={styles.eventItemWrapper}>
      <div
        className={clsx(styles.eventItemDataWrapper, {
          [styles.eventItemExpand]: isMultiple,
        })}
        onClick={isMultiple ? toggle : undefined}
      >
        <Tooltip title={title} addOverlay className={styles.eventItemCircle}>
          <EventIcon data-testid={EVENT_DATA_CHIP} />
        </Tooltip>
        <EventData {...{ label, isExpanded, isMultiple }} />
      </div>

      <EventDataExpand
        {...{ isExpanded, items, fieldType, handleDocumentDownload }}
      />

      <div className={styles.eventItemDetails}>
        <FormattedMessage
          id='activityLog.eventDetails'
          defaultMessage='{eventDateTime} by {eventSourceName}'
          values={{ eventDateTime, eventSourceName }}
        />
      </div>
    </div>
  );
};

export default EventItem;
