import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  APPEND_USER_GROUPS,
  RESET_USER_GROUPS,
  RESET_USER_GROUPS_COLUMNS,
  SET_USER_GROUPS,
  SET_USER_GROUPS_COLUMNS,
  SET_USER_GROUPS_FETCHING,
  SET_USER_GROUPS_RESTRICTIONS,
  SET_USER_GROUPS_SELECTED_ROW,
} from 'store/constants/userGroups.consts';
import {
  appendTableContent,
  resetTable,
  resetTableColumns,
  setSelectedRow,
  setTableColumns,
  setTableContent,
  setTableLoading,
} from './tablesCommonStateModificators';
import { CommonTableState } from 'utils/types/api/table.types';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import cloneDeep from 'lodash/cloneDeep';
import { UserGroup } from '../../utils/types/api/userGroups.types';

const initialState = cloneDeep(INITIAL_TABLE_STATE);

const userGroupsReducer: Reducer<CommonTableState<UserGroup>, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_USER_GROUPS: {
      return setTableContent<UserGroup, typeof SET_USER_GROUPS>(state, action);
    }
    case APPEND_USER_GROUPS:
      return appendTableContent<UserGroup, typeof APPEND_USER_GROUPS>(
        state,
        action
      );
    case SET_USER_GROUPS_FETCHING:
      return setTableLoading<UserGroup, typeof SET_USER_GROUPS_FETCHING>(
        state,
        action
      );

    case RESET_USER_GROUPS:
      return resetTable<UserGroup>(state, initialState);

    case RESET_USER_GROUPS_COLUMNS:
      return resetTableColumns<UserGroup>(state, initialState);

    case SET_USER_GROUPS_COLUMNS:
      return setTableColumns<UserGroup, typeof SET_USER_GROUPS_COLUMNS>(
        state,
        action
      );

    case SET_USER_GROUPS_SELECTED_ROW:
      return setSelectedRow<UserGroup, typeof SET_USER_GROUPS_SELECTED_ROW>(
        state,
        action
      );

    case SET_USER_GROUPS_RESTRICTIONS:
      return { ...state, restrictions: action.restrictions };

    default:
      return state;
  }
};

export default userGroupsReducer;
