import React from 'react';
import { USER_EMAIL_IN_BRACES_REGEX } from 'utils/consts';
import useAutocompleteSelectStyles from './styles';
import { BoldNameTextProps } from './types';

const BoldNameText = ({ text }: BoldNameTextProps) => {
  const classes = useAutocompleteSelectStyles({});
  const emailBeginningIndex = text.search(USER_EMAIL_IN_BRACES_REGEX);

  if (emailBeginningIndex === -1)
    return (
      <span className={classes.wrapper}>
        <b>{text}</b>
      </span>
    );

  const [firstPart, secondPart] = [
    text.slice(0, emailBeginningIndex),
    text.slice(emailBeginningIndex),
  ];

  return (
    <span className={classes.wrapper}>
      <b>{firstPart}</b>
      {secondPart}
    </span>
  );
};

export default BoldNameText;
