import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useModalStyles = createUseStyles((theme: ThemeState) => {
  return {
    'modal-footer': {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 15,
    },
    'modal-header': {
      borderBottom: '2px solid #EBEDEE',
    },
    'modal-header-content': {
      padding: '17px 23px 15px',
    },
    wrapper: {
      zIndex: 1200,
      '& .ant-modal-body': {
        padding: 0,
      },
      '& .ant-modal': {
        width: '550px !important',
      },
      '& .ant-modal-content': {
        borderRadius: '5px',
      },
    },
    wider: {
      '& .ant-modal': {
        width: '600px !important',
      },
    },
    'modal-content': {
      padding: '25px 15px 15px 25px',
    },
    'modal-body': {
      paddingBottom: 27,
      '.removingSectionColumnsModal &': {
        paddingBottom: 0,
      },
    },
    'modal-body-section': {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 1.75,
      whiteSpace: 'pre-line',
      overflow: 'hidden',
      '.removingSectionColumnsModal &': {
        width: '100%',
        lineHeight: 1,
      },
    },
    'modal-body-container': {
      display: 'flex',
      alignItems: 'center',
      '& > img': {
        alignSelf: 'flex-start',
      },
    },
    'close-wrapper': {
      borderRadius: 2,
      paddingLeft: 3,
      paddingRight: 3,
      color: theme['text-color'],
      '&:hover': {
        backgroundColor: '#DAEBFF',
        color: theme['primary-color'],
      },
    },
    'tooltip-overlay': {
      zIndex: 1300,
    },
    modalImage: {
      width: 86,
      height: 86,
      marginRight: 19,
    },
    submitButton: {
      marginLeft: 7,
    },
    overflowWrap: {
      overflowWrap: 'anywhere',
    },
  };
});

export default useModalStyles;
