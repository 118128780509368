import moment from 'moment-timezone';
import { SupportedTimezones, TIMEZONES_SET } from 'utils/timezones';
import { DEFAULT_TIMEZONE } from 'utils/consts';

export const getSupportedTimezone = (currentTimezone: string | undefined) => {
  if (!currentTimezone) return DEFAULT_TIMEZONE;

  if (SupportedTimezones.includes(currentTimezone)) return currentTimezone;

  const offsetBaseOnCurrentTimezone = moment().tz(currentTimezone).format('Z');
  const { timezone } =
    TIMEZONES_SET.find(tz => tz.offset === offsetBaseOnCurrentTimezone) || {};

  return timezone ?? DEFAULT_TIMEZONE;
};
