import { TPredicateTypes } from 'utils/types/predicates.types';

export interface PredicateSelectProps {
  filterKey: string;
  value: string;
  allowedPredicates: string[];
  type: TPredicateTypes;
  additionalClassName?: string;
}

export type AdditionalFilterMap = MappedObject<
  MappedObject<Array<string>, string>,
  string
>;

export enum DateAdditionalFilters {
  TODAY = 'today',
  THIS_WEEK = 'this_week',
  WITHIN_WEEK = 'within_week',
  WITHIN_30_DAYS = 'within_30_days',
  YESTERDAY = 'yesterday',
  WITHIN_LAST_WEEK = 'within_last_week',
  WITHIN_LAST_30_DAYS = 'within_last_30_days',
}
