import React, { useCallback, useEffect, useRef } from 'react';
import { FormikPassword } from 'components/lib/Input';
import { FormikPasswordProps } from '../types';
import useInputStyles from '../styles';
import useGlobalStyle from 'hooks/useGlobalStyle';
import clsx from 'clsx';
import { Field } from 'formik-antd';
import { useField } from 'formik';
import { FormikFormItem } from 'components/lib/Form';
import { useSetRequiredField } from '../hooks';
import { Input } from 'antd';
import ErrorLabel from 'components/ErrorLabel';
import {
  DISABLED_PASSWORD_INPUT_TESTID,
  PASSWORD_INPUT_TESTID,
} from 'utils/testIds';
import { useObfuscateInput } from '../TextInput/hooks';
import { autoCompleteOffProps } from '../consts';
import { onFocusNonAutoCompleteInput } from 'utils/functions/onFocusNonAutoCompleteInput';

const PasswordInput: React.FC<FormikPasswordProps> = ({
  name,
  onChange,
  onFocus,
  isPasswordVisible,
  visibilityToggle,
  placeholder,
  className,
  wrapperClassName,
  disabled,
  required,
  maxLength,
  obfuscateWhenEmpty,
  obfuscateValueLength = 23,
  obfuscatePlaceholder,
  autoCompleteOff,
  ...rest
}) => {
  const classes = useInputStyles({});
  const globalClasses = useGlobalStyle();
  const [{ value }, { touched, error }] = useField(name);
  const passwordRef = useRef<Input>(null);

  const {
    handleBlur,
    handleFocus,
    isObfuscateFieldVisible,
    obfuscatedValue,
  } = useObfuscateInput(name, obfuscateWhenEmpty, obfuscateValueLength);

  const isObfuscateInput = obfuscateWhenEmpty && isObfuscateFieldVisible;

  const setInputType = useCallback(visible => {
    if (passwordRef.current) {
      if (visible) {
        passwordRef.current.input.type = 'text';
      } else {
        passwordRef.current.input.type = 'password';
      }
    }
  }, []);

  useEffect(() => {
    if (visibilityToggle === false && visibilityToggle !== undefined) {
      setInputType(isPasswordVisible);
    }
  }, [visibilityToggle, isPasswordVisible, setInputType]);

  useSetRequiredField(name, required);

  const DisabledField = useCallback(
    () => (
      <div
        className={clsx([
          'ant-input',
          'ant-input-disabled',
          globalClasses.disabledInput,
          className,
        ])}
        data-testid={
          //@ts-ignore
          rest['data-testid'] || `${DISABLED_PASSWORD_INPUT_TESTID}${name}`
        }
      >
        {placeholder && (
          <span className={clsx([classes.placeholder, className])}>
            {placeholder}
          </span>
        )}
      </div>
    ),
    [
      className,
      classes.placeholder,
      globalClasses.disabledInput,
      name,
      placeholder,
      rest,
    ]
  );

  return (
    <FormikFormItem
      {...{ name }}
      className={wrapperClassName}
      validateStatus={touched && error ? 'error' : undefined}
    >
      <Field {...{ name }}>
        {() => {
          if (disabled) return <DisabledField />;

          return (
            <div className={classes.inputWrapper}>
              <FormikPassword
                ref={passwordRef}
                {...{
                  value: isObfuscateInput ? obfuscatedValue : value,
                  name,
                  visibilityToggle,
                  className: clsx(className, {
                    [classes.obfuscatePlaceholder]: obfuscatePlaceholder,
                  }),
                  onChange,
                  placeholder: obfuscatePlaceholder
                    ? '•'.repeat(obfuscateValueLength)
                    : placeholder,
                  required,
                  maxLength,
                  onBlur: handleBlur,
                  ...(autoCompleteOff ? autoCompleteOffProps : {}),
                }}
                onFocus={element => {
                  if (autoCompleteOff) onFocusNonAutoCompleteInput(element);

                  if (isObfuscateInput) handleFocus();
                  else if (onFocus) onFocus(element);
                }}
                data-testid={
                  //@ts-ignore
                  rest['data-testid'] || `${PASSWORD_INPUT_TESTID}${name}`
                }
              />
              {required && <ErrorLabel />}
            </div>
          );
        }}
      </Field>
    </FormikFormItem>
  );
};

export default PasswordInput;
