import { useCallback, useMemo, useState } from 'react';
import { DisabledOptionParams } from 'components/AutocompleteUsersSelect/types';
import { AccountTypeOptions } from 'pages/Users/enums';
import { useIntl } from 'react-intl';
import { SelectUserOption } from 'utils/types/selectInput.types';
import { MergedTaskSchema, TaskFormFields, TaskFormType } from './types';
import { useFormikContext } from 'formik';

export default (options: MergedTaskSchema) => {
  const intl = useIntl();
  const { setFieldValue } = useFormikContext<TaskFormType>();

  const limitForStandarsUsers =
    options[TaskFormFields.Stage1]?.limit_assignees_standard;
  const limitForOneTimeCompletionUsers =
    options[TaskFormFields.Stage1]?.limit_assignees_one_time_completion;
  const [selectedUsers, setSelectedUsers] = useState<SelectUserOption[]>([]);
  const isStandardUserSelectedAsFirst = useMemo(
    () =>
      selectedUsers.length &&
      selectedUsers[0]?.account_type !== AccountTypeOptions.OneTimeCompletion,
    [selectedUsers]
  );

  const getIsUserSelected = useCallback(
    (userId: number) => selectedUsers.some(({ id }) => userId === id),
    [selectedUsers]
  );

  const shouldOptionBeDisabled = useCallback(
    ({ account_type: accountType, id }: DisabledOptionParams) => {
      // block 1TC users when standard user selected as first
      if (
        isStandardUserSelectedAsFirst &&
        accountType === AccountTypeOptions.OneTimeCompletion
      )
        return true;

      // block all users when 1TC user has been already selected as first
      if (
        selectedUsers.length &&
        selectedUsers[0]?.account_type === AccountTypeOptions.OneTimeCompletion
      )
        return true;

      return getIsUserSelected(id);
    },
    [isStandardUserSelectedAsFirst, selectedUsers, getIsUserSelected]
  );

  const getDisabledText = useCallback(
    ({ id }: SelectUserOption) => {
      if (getIsUserSelected(id))
        return intl.formatMessage({
          id: 'misc.alreadyAdded',
          defaultMessage: 'Already added',
        });

      return '';
    },
    [getIsUserSelected, intl]
  );

  const onSelectChange = async (user: SelectUserOption | undefined) => {
    if (!user) return;

    if (!selectedUsers.length) {
      setSelectedUsers([user]);

      return;
    }

    if (
      // allow to select more only if first is a standard and in limit
      (isStandardUserSelectedAsFirst &&
        selectedUsers.length < limitForStandarsUsers) ||
      selectedUsers.length < limitForOneTimeCompletionUsers // allow to select more only if first is a 1TM and in limit
    )
      setSelectedUsers(prevUsers => [...prevUsers, user]);
  };

  const onDelete = useCallback(
    (id: number, toggleOff: () => void) => {
      const updatedList = selectedUsers.filter(user => user.id !== id);

      setSelectedUsers(updatedList);
      setFieldValue(
        TaskFormFields.Stage1,
        updatedList.map(({ id }) => id).join(', ')
      );

      // close popover after removing last elem
      if (updatedList.length === 0) toggleOff();

      return updatedList;
    },
    [selectedUsers, setFieldValue]
  );

  const areSelectedUsersInLimit = useMemo(() => {
    if (selectedUsers.length === 0) return true;

    if (isStandardUserSelectedAsFirst)
      return selectedUsers.length < limitForStandarsUsers;

    return selectedUsers.length < limitForOneTimeCompletionUsers;
  }, [
    isStandardUserSelectedAsFirst,
    limitForOneTimeCompletionUsers,
    limitForStandarsUsers,
    selectedUsers.length,
  ]);

  const autocompleteTooltipTitle = useMemo(() => {
    if (!isStandardUserSelectedAsFirst && !areSelectedUsersInLimit)
      return intl.formatMessage({
        id: 'tasksPanel.form.oneTimeCompletionUserLimitInfo',
        defaultMessage:
          'A one-time completion user has been assigned to this task, therefore no further users can be added.',
      });

    // if title undefined tooltip will not be visible
    return undefined;
  }, [areSelectedUsersInLimit, intl, isStandardUserSelectedAsFirst]);

  return {
    onSelectChange,
    getDisabledText,
    shouldOptionBeDisabled,
    selectedUsers,
    onDelete,
    areSelectedUsersInLimit,
    autocompleteTooltipTitle,
  };
};
