import React from 'react';
import { mapCustomWidgetPropsToBaseInPlaceEditProps } from './utils';
import { Widget } from 'alx-dynamic-form';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import InPlaceEditFileUpload from 'components/InPlaceEditControls/InPlaceEditFileUpload';
import { CustomFileItem } from 'components/lib/FileUpload/types';

const AdaptedInPlaceEditFileUpload: Widget = widgetProps => {
  const baseInPlaceEditProps = mapCustomWidgetPropsToBaseInPlaceEditProps<
    CustomFileItem[],
    any
  >(widgetProps);
  const {
    maxItems,
    additionalFieldProps: {
      identifier,
      recordId,
      skipUploading,
      shouldDownloadFileOnClick = false,
    },
    onChange,
  } = widgetProps;

  return (
    <Provider store={store}>
      <InPlaceEditFileUpload
        {...{
          ...baseInPlaceEditProps,
          onChange,
          maxItems,
          identifier,
          recordId,
          skipUploading,
        }}
        shouldDownloadFileOnClick={shouldDownloadFileOnClick}
        withUnderline
      />
    </Provider>
  );
};

export default AdaptedInPlaceEditFileUpload;
