import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useEventsListStyles = createUseStyles((theme: ThemeState) => {
  return {
    eventErrorText: {
      color: theme['error-color'],
      fontWeight: 700,
    },
    eventsListWrapper: {
      paddingTop: 35,
      overflow: 'hidden',
    },
    eventItemWrapper: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 20,
      position: 'relative',
      '&:last-child [class*="eventItemCircle"]::before': {
        backgroundColor: theme['white-color'],
      },
    },
    eventItemDataWrapper: {
      alignItems: 'center',
      display: 'flex',
      gap: '12px',
    },
    eventItemCircle: {
      display: 'flex',
      color: theme['primary-color'],
      height: '25px',
      position: 'relative',
      zIndex: 1,
      '&::before': {
        backgroundColor: theme['border-color-base'],
        content: '""',
        height: '1000px',
        left: '11px',
        position: 'absolute',
        top: 0,
        width: '2px',
        zIndex: -1,
      },
    },
    eventItemExpand: {
      cursor: 'pointer',
    },
    eventItemDetails: {
      color: theme['secondary-color'],
      fontSize: 12,
      marginLeft: 37,
      wordBreak: 'break-word',
    },
  };
});

export const useEventDataLabelStyles = createUseStyles((theme: ThemeState) => {
  return {
    eventDataLabelGreen: {
      color: theme['chips-color'],
    },
    eventDataLabelRed: {
      color: theme['error-color'],
    },
    eventDataLabelGray: {
      color: theme['disabled-switch-gray'],
    },
    eventDataLabelAnchor: {
      color: theme['primary-color'],
      fontWeight: 700,
      textDecoration: 'underline',
    },
  };
});

export default useEventsListStyles;
