import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useInPlaceEditFileUploadStyles = createUseStyles((theme: ThemeState) => {
  return {
    dropdownContentWrapper: {
      flex: 1,
      padding: '9px 7px 14px 7px',
      backgroundColor: 'white',
      borderRadius: 3,
      boxShadow: '0px 4px 4px rgba(46, 63, 87, 0.3)',
      maxWidth: 500,
      maxHeight: 280,
      overflow: 'auto',
    },
    fileUpload: {
      width: '100%',
      '& > span': {
        width: '100%',
        maxWidth: 750,
      },
    },
    viewPlaceholder: {
      height: 75,
    },
    dropdownAnchor: {
      width: '100%',
      maxWidth: 500,
    },
    fileLink: {
      color: theme['primary-color'],
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
    previewClass: {
      cursor: 'pointer',
    },
    limitMessage: {
      fontSize: 12,
    },
    dropdownOverlay: {
      zIndex: 1060,
    },
    readoOnlyEmptyContent: {
      display: 'inline-block',
      width: 250,
    },
    loader: {
      position: 'static',

      '& .ant-spin-container': {
        position: 'static',
      },
    },
  };
});

export default useInPlaceEditFileUploadStyles;
