import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseCustomUserWidgetStyles = {
  disabled?: boolean;
  withUnderline?: boolean;
  hasErrors?: boolean;
};

const useCustomUserWidgetStyles = createUseStyles((theme: ThemeState) => {
  return {
    selectStyles: {
      maxWidth: 350,
      width: 350,
      position: 'relative',
      '& .ant-select-selector': {
        height: '32px !important',
        borderColor: theme['border-color-base'],
      },
    },
    checkmarkIcon: {
      color: theme['primary-color'],
    },
    selectItem: {
      minHeight: 47,
      lineHeight: '19px',
      '& .ant-select-item-option-content': {
        display: 'flex',
        alignItems: 'center',
      },
      '& mark': {
        padding: 0,
        backgroundColor: 'inherit',
      },
      '&.ant-select-item-option-disabled ': {
        '& .ant-avatar': {
          background: theme['border-color-base'],
        },
        '& .ant-select-item-option-content > div:not(:first-child)': {
          color: theme['disabled-switch-gray'],
          '& mark': {
            color: theme['disabled-switch-gray'],
          },
        },
      },
    },
    dropdownStyles: (props: UseCustomUserWidgetStyles) => ({
      minWidth: '350px !important',
      boxShadow: 'none',
      border: props?.hasErrors
        ? `1px solid ${theme['error-color']}`
        : `1px solid ${theme['border-color-base']}`,
      paddingBottom: 0,
    }),
    avatars: {
      cursor: ({ disabled }: UseCustomUserWidgetStyles) =>
        disabled ? 'inherit' : 'pointer',
      width: 350,
      padding: 2,
      borderBottom: ({ withUnderline }: UseCustomUserWidgetStyles) =>
        withUnderline ? `1px solid ${theme['border-color-base']}` : 'none',
      '& span.ant-avatar': {
        cursor: ({ disabled }: UseCustomUserWidgetStyles) =>
          disabled ? 'inherit' : 'pointer',
      },
    },
    optionToolTip: {
      width: '100%',
    },
    optionContent: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      '& > div:first-child': {
        marginRight: 6,
      },
    },
    minMaxInfo: {
      marginTop: 0,
    },
    editUserWrapper: {
      position: 'relative',
      display: 'flex',
    },
    messageBox: {
      bottom: '0px',
      left: 1,
      width: 348,
      borderTop: `1px solid ${theme['border-color-base']}`,
      position: 'relative',
    },
    messageBoxOnTop: {
      borderBottom: `1px solid ${theme['border-color-base']}`,
      borderTop: 'none !important',
      zIndex: -1,
    },
    emptyContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 18,
    },
  };
});

export default useCustomUserWidgetStyles;
