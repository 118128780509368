import AdaptedInPlaceEditCheckbox from './inPlaceEdit/AdaptedInPlaceEditCheckbox';
import AdaptedInPlaceEditDatepicker from './inPlaceEdit/AdaptedInPlaceEditDatepicker';
import AdaptedInPlaceEditDateTimePicker from './inPlaceEdit/AdaptedInPlaceEditDateTimePicker';
import AdaptedInPlaceEditEmail from './inPlaceEdit/AdaptedInPlaceEditEmail';
import AdaptedInPlaceEditMultiSelect from './inPlaceEdit/AdaptedInPlaceEditMultiSelect';
import AdaptedInPlaceEditNumberInput from './inPlaceEdit/AdaptedInPlaceEditNumberinput';
import AdaptedInPlaceEditOwners from './inPlaceEdit/AdaptedInPlaceEditOwners';
import AdaptedInPlaceEditPhoneInput from './inPlaceEdit/AdaptedInPlaceEditPhoneInput';
import AdaptedInPlaceEditSelecDropdown from './inPlaceEdit/AdaptedInPlaceEditSelectDropdown';
import AdaptedInPlaceEditTextarea from './inPlaceEdit/AdaptedInPlaceEditTextarea';
import AdaptedInPlaceEditTextInput from './inPlaceEdit/AdaptedInPlaceEditTextInput';
import CustomCheckboxesWidget from './standard/CustomCheckboxesWidget';
import CustomCountrySelectWidget from './standard/CustomCountrySelectWidget';
import CustomCheckboxWidget from './standard/CustomCheckboxWidget';
import CustomDatepickerWidget from './standard/CustomDatepickerWidget';
import CustomDateTimepickerWidget from './standard/CustomDateTimepickerWidget';
import CustomEmailWidget from './standard/CustomEmailWidget';
import CustomFileUploadWidget from './standard/CustomFileUploadWidget';
import CustomInstructionWidget from './standard/CustomInstructionWidget';
import CustomNumberWidget from './standard/CustomNumberWidget';
import CustomOwnersWidget from './standard/CustomOwnersWidget';
import CustomRadioWidget from './standard/CustomRadioWidget';
import CustomSelectWidget from './standard/CustomSelectWidget';
import CustomTextareaWidget from './standard/CustomTextareaWidget';
import CustomTextboxWidget from './standard/CustomTextboxWidget';
import CustomPhoneNumberWidget from './standard/CustomPhoneNumberWidget';
import CustomURLWidget from './standard/CustomURLWidget';
import { WidgetMap } from 'alx-dynamic-form';
import AdaptedInPlaceEditSelectRadio from './inPlaceEdit/AdaptedInPlaceEditSelectRadio';
import CustomTimePickerWidget from './standard/CustomTimePickerWidget';
import AdaptedInPlaceEditFileUpload from './inPlaceEdit/AdaptedInPlaceEditFileUpload';
import AdaptedInPlaceEditTimePicker from './inPlaceEdit/AdaptedInPlaceEditTimePicker';
import AdaptedInPlaceEditUrl from './inPlaceEdit/AdaptedInPlaceEditUrl';
import {
  ChildClassStandardWidget,
  InPlaceChildClassWidget,
} from 'components/lib/ChildClassComponent';
import AdaptedInPlaceEditJson from './inPlaceEdit/AdaptedInPlaceEditJson';
import CustomJsonWidget from './standard/CustomJsonWidget';
import CustomUserWidget from './standard/CustomUserWidget';
import AdaptedInPlaceEditUser from './inPlaceEdit/AdaptedInPlaceEditUser';
import CustomExternalComponentWidget from './standard/CustomExternalComponentWidget';

export enum WidgetSet {
  standard,
  inPlaceEdit,
}

const standardWidgetSet: WidgetMap = {
  default: CustomTextboxWidget,
  string: CustomTextboxWidget,
  textarea: CustomTextareaWidget,
  date: CustomDatepickerWidget,
  'date-time': CustomDateTimepickerWidget,
  'country-select': CustomCountrySelectWidget,
  enum: CustomSelectWidget,
  file: CustomFileUploadWidget,
  instruction: CustomInstructionWidget,
  owners: CustomOwnersWidget,
  array: CustomCheckboxesWidget,
  phoneNumber: CustomPhoneNumberWidget,
  email: CustomEmailWidget,
  number: CustomNumberWidget,
  boolean: CustomCheckboxWidget,
  int: CustomNumberWidget,
  float: CustomNumberWidget,
  bool: CustomCheckboxWidget,
  time: CustomTimePickerWidget,
  url: CustomURLWidget,
  datetime: CustomDateTimepickerWidget,
  checkboxes: CustomCheckboxesWidget,
  radio: CustomRadioWidget,
  child_class: ChildClassStandardWidget,
  json: CustomJsonWidget,
  user: CustomUserWidget,
  external_content: CustomExternalComponentWidget,
};

const inPlaceEditWidgetSet: WidgetMap = {
  default: AdaptedInPlaceEditTextInput,
  string: AdaptedInPlaceEditTextInput,
  textarea: AdaptedInPlaceEditTextarea,
  date: AdaptedInPlaceEditDatepicker,
  'date-time': AdaptedInPlaceEditDateTimePicker,
  'country-select': AdaptedInPlaceEditTextInput,
  enum: AdaptedInPlaceEditSelecDropdown,
  file: AdaptedInPlaceEditFileUpload,
  instruction: CustomInstructionWidget,
  owners: AdaptedInPlaceEditOwners,
  array: AdaptedInPlaceEditSelecDropdown,
  phoneNumber: AdaptedInPlaceEditPhoneInput,
  email: AdaptedInPlaceEditEmail,
  number: AdaptedInPlaceEditNumberInput,
  boolean: AdaptedInPlaceEditCheckbox,
  int: AdaptedInPlaceEditNumberInput,
  float: AdaptedInPlaceEditNumberInput,
  bool: AdaptedInPlaceEditCheckbox,
  time: AdaptedInPlaceEditTimePicker,
  url: AdaptedInPlaceEditUrl,
  datetime: AdaptedInPlaceEditDateTimePicker,
  checkboxes: AdaptedInPlaceEditMultiSelect,
  radio: AdaptedInPlaceEditSelectRadio,
  child_class: InPlaceChildClassWidget,
  json: AdaptedInPlaceEditJson,
  user: AdaptedInPlaceEditUser,
  external_content: CustomExternalComponentWidget,
};

const widgetSets = {
  [WidgetSet.standard]: standardWidgetSet,
  [WidgetSet.inPlaceEdit]: inPlaceEditWidgetSet,
};

export default widgetSets;
