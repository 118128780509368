import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useAddOwnersStyles = createUseStyles((theme: ThemeState) => {
  return {
    contentWrapper: {
      height: '100%',
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: 15,
    },
    headerTitle: {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 7,
      lineHeight: 1.3,
      color: theme['heading-color'],
    },
    panelTitle: {
      fontSize: 16,
      marginTop: 10,
    },
    bottomGap: {
      marginBottom: 10,
      position: 'relative',
    },
    emptyBox: {
      margin: [35, 0, 10],
    },
    text: {
      color: theme['text-color'],
      opacity: '.6',
      fontSize: 12,
    },
    scrollableContainer: {
      overflow: 'auto',
      height: '100%',
      position: 'relative',
    },
    autocompleteSelect: {
      '& .ant-select-selector': {
        height: '34px !important',
      },
      '& .ant-select-selection-search-input': {
        height: '32px !important',
      },
      '& .ant-select-selection-placeholder': {
        lineHeight: '32px !important',
      },
    },
    buttonsWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& .ant-btn:first-child': {
        marginRight: 8,
      },
      paddingTop: 30,
    },
  };
});
