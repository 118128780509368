import { StatusCodes } from 'http-status-codes';
import { ViewParams } from 'pages/Role/types';
import { useCallback, useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import {
  getObjectClassPermissionSetAssignees,
  getObjectClassPermissionSetAssigneesConfiguration,
  setObjectClassPermissionsClassOwnersIsRemovingHimself,
  setObjectClassPermissionSetAssigneesFetching,
  setObjectClassSelectedPermissionSet,
} from 'store/actions/objectClassPermissionsActions';
import {
  getObjectClassPermissionSetAssigneesAvatars,
  getObjectClassPermissionSetAssigneesCount,
  getObjectClassPermissionSetAssigneesItemsLimit,
  getObjectClassSelectedPermissionSet,
} from 'store/selectors/objectClassPermissionsSelectors';
import { apiCall } from 'utils/api';
import { OBJECT_CLASS_PERMISSION_SET_ASSIGNEES } from 'utils/endpoints';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import errorToast from 'utils/functions/errorToast';
import { OwnersMode } from '../AddUsersPanel/types';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import { AssigneeToDeleteType } from './types';
import { AvatarItem } from 'components/lib/Avatar/types';
import { getUserDetails } from 'store/selectors/authSelectors';
import { useObjectClassContext } from '../../../context/ObjectClassFormContext';

const useManageAssigneesPanel = () => {
  const dispatch = useDispatch();
  const { id: objectClassId } = useParams<ViewParams>();
  const selectedPermissionSet = useSelector(
    getObjectClassSelectedPermissionSet
  );
  const { activePermissionSetTab } = useObjectClassContext();

  const userId = useSelector(getUserDetails)?.id;

  const [assigneeToDelete, setAssigneeToDelete] = useState<
    AssigneeToDeleteType | undefined
  >(undefined);

  const permissionSetId = selectedPermissionSet?.id?.toString() || '';

  const assigneesAvatars =
    useSelector(getObjectClassPermissionSetAssigneesAvatars(permissionSetId)) ||
    [];

  useEffect(() => {
    if (objectClassId !== undefined && permissionSetId.length > 0) {
      dispatch(
        getObjectClassPermissionSetAssigneesConfiguration(
          objectClassId,
          permissionSetId
        )
      );
    }
  }, [dispatch, objectClassId, permissionSetId]);

  const { closePanelIfOpen } = useFlexLayoutContext();

  const handleCancel = () => {
    closePanelIfOpen(FlexLayoutWindows.ClassPermissionsOwners);
    dispatch(setObjectClassSelectedPermissionSet(undefined));
  };

  const limitAssigneesItems = useSelector(
    getObjectClassPermissionSetAssigneesItemsLimit(permissionSetId)
  );

  const assigneesCount =
    useSelector(getObjectClassPermissionSetAssigneesCount(permissionSetId)) ||
    0;

  const isLimitExceeded =
    limitAssigneesItems !== undefined && assigneesCount >= limitAssigneesItems;

  const openAddOwnersPanel = useCallback(
    () =>
      dispatch(
        setSidebarData(FlexLayoutWindows.ClassPermissionsOwners, {
          mode: OwnersMode.Adding,
        })
      ),
    [dispatch]
  );

  const removePermissionSetAssignee = useCallback(async () => {
    dispatch(
      setObjectClassPermissionSetAssigneesFetching(permissionSetId, true)
    );

    try {
      const { status } = await apiCall.request({
        method: 'delete',
        url: generatePath(OBJECT_CLASS_PERMISSION_SET_ASSIGNEES, {
          objectClassId,
          permissionSetId,
        }),
        data: [assigneeToDelete?.id],
      });

      if (status === StatusCodes.NO_CONTENT) {
        await dispatch(
          getObjectClassPermissionSetAssignees(
            objectClassId,
            permissionSetId,
            activePermissionSetTab
          )
        );
      }
    } catch {
      errorToast();
    } finally {
      dispatch(
        setObjectClassPermissionSetAssigneesFetching(permissionSetId, false)
      );
    }
  }, [
    activePermissionSetTab,
    assigneeToDelete,
    dispatch,
    objectClassId,
    permissionSetId,
  ]);

  const onDeleteFabric = (avatar: AvatarItem) => async () => {
    const { id, userGroup, firstName, lastName } = avatar;

    dispatch(
      setObjectClassPermissionsClassOwnersIsRemovingHimself(userId === id)
    );

    setAssigneeToDelete({
      permissionSetId,
      id,
      displayName: userGroup ? userGroup : `${firstName} ${lastName}`,
    });
  };

  return {
    handleCancel,
    assigneesCount,
    limitAssigneesItems,
    isLimitExceeded,
    assigneesAvatars,
    selectedPermissionSet,
    openAddOwnersPanel,
    onDeleteFabric,
    objectClassId,
    assigneeToDelete,
    setAssigneeToDelete,
    removePermissionSetAssignee,
  };
};

export default useManageAssigneesPanel;
