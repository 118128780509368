import React from 'react';
import clsx from 'clsx';
import first from 'lodash/first';
import { ErrorsComponentProps } from './types';
import useFormPreviewStyles from 'components/FormPreview/styles';
import { ERROR_COMPONENT_TESTID } from 'utils/testIds';

const ErrorsComponent = ({ rawErrors }: ErrorsComponentProps) => {
  const classes = useFormPreviewStyles({});

  if (!rawErrors?.length) return null;

  return (
    <span
      data-testid={ERROR_COMPONENT_TESTID}
      className={clsx([classes.errorMessage])}
    >
      {first(rawErrors)}
    </span>
  );
};

export default ErrorsComponent;
