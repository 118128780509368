import { lazy } from 'react';

const lazyWithRetry = (
  componentImport: () => Promise<{
    default: () => JSX.Element;
  }>
) =>
  lazy(async () => {
    const hasBeenRefreshed = JSON.parse(
      window.localStorage.getItem('hasBeenRefreshed') || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem('hasBeenRefreshed', 'false');

      return component;
    } catch (error) {
      if (!hasBeenRefreshed) {
        window.localStorage.setItem('hasBeenRefreshed', 'true');
        window.location.reload();
      }

      throw error;
    }
  });

export default lazyWithRetry;
