import React from 'react';
import LoaderOverlay from '../../components/LoaderOverlay';
import useSignSignOnCallback from './hooks';

const SingleSignOnCallback = () => {
  useSignSignOnCallback();

  return <LoaderOverlay />;
};

export default SingleSignOnCallback;
