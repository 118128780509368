import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const collapseContentBoxStyles = {
  paddingTop: '0px !important',
  paddingBottom: '0px !important',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
};

type UseFormCategoryStylesProps = {
  noMarginTop?: boolean;
  noMarginBottom?: boolean;
  noHorizontalMargin?: boolean;
  collapsible?: boolean;
  hideHeader?: boolean;
};

const useFormCategoryStyles = createUseStyles((theme: ThemeState) => {
  return {
    collapse: ({
      noMarginTop,
      noMarginBottom,
      noHorizontalMargin,
      collapsible,
      hideHeader,
    }: UseFormCategoryStylesProps) => ({
      '& .ant-collapse-item:not(.ant-collapse-item-active) .ant-collapse-header': {
        marginBottom: noMarginBottom ? 0 : 15,
      },
      '& .ant-collapse-header': {
        backgroundColor: theme['background-color-light'],
        marginLeft: noHorizontalMargin ? 0 : -30,
        marginRight: noHorizontalMargin ? 0 : -30,
        marginBottom: noMarginBottom ? 0 : 15,
        paddingLeft: '15px !important',
        paddingRight: '15px !important',
        paddingTop: '7px !important',
        paddingBottom: '7px !important',
        fontWeight: 'bold',
        color: `${theme['secondary-color']} !important`,
        marginTop: noMarginTop ? 0 : 15,
        textTransform: 'uppercase',
        fontSize: 12,
        cursor: collapsible ? 'pointer' : 'default !important',
        display: hideHeader ? 'none' : 'block',
      },
      '& .ant-collapse-content-box': collapseContentBoxStyles,
    }),
    collapseLight: ({
      noMarginTop,
      noMarginBottom,
      noHorizontalMargin,
      collapsible,
      hideHeader,
    }: UseFormCategoryStylesProps) => ({
      '& .ant-collapse-item:not(.ant-collapse-item-active) .ant-collapse-header': {
        marginBottom: noMarginBottom ? 0 : 15,
      },
      '& .ant-collapse-header': {
        '&:hover': {
          backgroundColor: theme['background-color-light'],
        },
        marginLeft: noHorizontalMargin ? 0 : -10,
        marginRight: noHorizontalMargin ? 0 : -10,
        marginBottom: '13px !important',
        paddingLeft: '15px !important',
        paddingRight: '15px !important',
        paddingTop: '7px !important',
        paddingBottom: '7px !important',
        fontWeight: 'bold',
        marginTop: noMarginTop ? 0 : 15,
        fontSize: 12,
        cursor: collapsible ? 'pointer' : 'default !important',
        display: hideHeader ? 'none' : 'block',
      },
      '& .ant-collapse-content-box': collapseContentBoxStyles,
    }),
    suffixIcon: {
      right: '13px !important',
      'form.rjsf &': {
        right: '19px !important',
      },
    },
    suffixIconActive: {
      transform: 'rotate(0deg) translateY(25%) !important',
      marginTop: -5,
    },
  };
});

export default useFormCategoryStyles;
