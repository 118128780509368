import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { panelWrapper, getIdentifier } from 'styles/panelsStyles';

const useDocumentTemplatesPanelStyles = createUseStyles((theme: ThemeState) => {
  return {
    panelWrapper: {
      ...panelWrapper,
      '& div[role=table]': {
        margin: 0,
      },
      '& .ant-modal-mask': {
        position: 'absolute',
      },
      '& .ant-modal-wrap': {
        position: 'absolute',
      },
    },
    identifier: getIdentifier(theme),
    errorMessage: {
      fontSize: 12,
      color: theme['error-color-light'],
      paddingTop: 16,
    },
    emptyData: {
      marginTop: 67,
    },
    submitButton: {
      display: 'inline-block',
    },
    modal: {
      '& .ant-modal-body': {
        padding: [8, 4],
      },
    },
    menu: {
      border: 'none',
      minHeight: 200,
      maxHeight: 200,
      overflowY: 'auto',
      '& .ant-menu-item:active': {
        backgroundColor: '#f8f9fb !important',
      },
      '& .ant-menu-item-selected': {
        backgroundColor: '#f8f9fb !important',
        fontWeight: 700,
      },
    },
  };
});

export default useDocumentTemplatesPanelStyles;
