import React from 'react';
import { Field, FieldProps, useField, useFormikContext } from 'formik';
import AutocompleteSelect from '.';
import { FAutocompleteSelectProps } from './types';
import { FormikFormItem } from 'components/lib/Form';
import { useSetRequiredField } from 'components/Inputs/hooks';
import { AUTOCOMPLETE_SELECT_TESTID } from 'utils/testIds';

const FAutocompleteSelect: React.FC<FAutocompleteSelectProps> = ({
  name,
  value,
  disabled,
  selectMultiple,
  autocompleteUrl,
  placeholder,
  parseValue,
  labelInValue,
  required,
  onChange,
  limit,
  setTotalObjectTypeFields,
  ...rest
}) => {
  const [, { touched, error }] = useField(name);
  const { setFieldTouched } = useFormikContext();

  useSetRequiredField(name, required);

  return (
    <FormikFormItem
      {...{ name }}
      validateStatus={touched && error ? 'error' : undefined}
      data-testid={`${AUTOCOMPLETE_SELECT_TESTID}${name}`}
    >
      <Field {...{ name }}>
        {({ field }: FieldProps) => (
          <AutocompleteSelect
            {...{
              selectMultiple,
              autocompleteUrl,
              value,
              disabled,
              labelInValue,
              placeholder,
              setTotalObjectTypeFields,
              ...rest,
            }}
            isLimitExceeded={!!limit && value && value.length >= limit}
            handleOnBlur={() => setFieldTouched(name, true)}
            onChange={options => {
              if (onChange) {
                onChange(options);
              }

              if (parseValue) {
                field.onChange(field.name)(parseValue(options));

                return;
              }

              field.onChange(field.name)(
                Array.isArray(options)
                  ? options.map(({ value }) => value).join(', ')
                  : options.value
              );
            }}
          />
        )}
      </Field>
    </FormikFormItem>
  );
};

export default FAutocompleteSelect;
