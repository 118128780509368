import { createUseStyles } from 'react-jss';
import { getInPlaceEditFontSize } from '../commonStyles';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseInPlaceEditLabelStylesProps = {
  size?: 'small' | 'medium';
};

const useInPlaceEditLabel = createUseStyles((theme: ThemeState) => {
  return {
    label: {
      fontSize: ({ size }: UseInPlaceEditLabelStylesProps = {}) =>
        getInPlaceEditFontSize(size),
      lineHeight: '16.5px',
      display: 'flex',
      paddingRight: 5,
      marginBottom: 'var(--spacingWithin)',
      color: theme['secondary-color'],
      '& >span': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  };
});

export default useInPlaceEditLabel;
