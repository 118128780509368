export const SET_DOCUMENT_TEMPLATES_PANEL = 'SET_DOCUMENT_TEMPLATES_PANEL';
export const APPEND_DOCUMENT_TEMPLATES_PANEL =
  'APPEND_DOCUMENT_TEMPLATES_PANEL';
export const RESET_DOCUMENT_TEMPLATES_PANEL = 'RESET_DOCUMENT_TEMPLATES_PANEL';
export const SET_DOCUMENT_TEMPLATES_PANEL_FETCHING =
  'SET_DOCUMENT_TEMPLATES_PANEL_FETCHING';
export const SET_TOTAL_DOCUMENT_TEMPLATES_PANEL =
  'SET_TOTAL_DOCUMENT_TEMPLATES_PANEL';
export const SET_DOCUMENT_TEMPLATES_PANEL_SELECTED_ROW =
  'SET_DOCUMENT_TEMPLATES_PANEL_SELECTED_ROW';
export const SET_DOCUMENT_TEMPLATES_PANEL_COLUMNS =
  'SET_DOCUMENT_TEMPLATES_PANEL_COLUMNS';
export const SET_DOCUMENT_TEMPLATES_PANEL_RESTRICTIONS =
  'SET_DOCUMENT_TEMPLATES_PANEL_RESTRICTIONS';
export const SET_DOCUMENT_TEMPLATES_PANEL_ERROR =
  'SET_DOCUMENT_TEMPLATES_PANEL_ERROR';
export const SET_DOCUMENT_TEMPLATES_PANEL_STATUS =
  'SET_DOCUMENT_TEMPLATES_PANEL_STATUS';

export const FETCH_DOCUMENTS_PANEL_PERISTANT_QUERY_PARAMS =
  'allow_on_demand_generation=true';
