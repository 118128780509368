import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import EventItem from './EventItem';
import useEventsListStyles from './styles';
import { EventListProps } from './types';
import EventItemErrorFallback from './EventItemErrorFallback';

const EventsList = ({ events, refreshData }: EventListProps) => {
  const styles = useEventsListStyles({});
  return (
    <div className={styles.eventsListWrapper}>
      {events.map(e => (
        <ErrorBoundary FallbackComponent={EventItemErrorFallback}>
          <EventItem
            key={e?.key || e.event_id}
            event={e}
            refreshData={refreshData}
          />
        </ErrorBoundary>
      ))}
    </div>
  );
};

export default EventsList;
