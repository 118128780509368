import React from 'react';
import { Prompt } from 'react-router-dom';
import { CustomPromptProps, CustomPromptType } from './types';
import useCustomPrompt from './hooks';
import SaveChangesModal from './components/SaveChangesModal';
import CancelConfigurationModal from '../CancelConfigurationModal';
import { useToggle } from '../../hooks/useToggle';

const CustomPrompt: React.FC<CustomPromptProps> = ({
  when,
  onCancel,
  onConfirm,
  onClose,
  confirmDisabled,
  setIsNavigateAway,
  type = CustomPromptType.SAVE_CHANGES,
}) => {
  const [navigationLoading, { toggleOn: toggleNavLoading }] = useToggle(false);
  const {
    closeModal,
    handleClosePress,
    handleConfirmNavigationClick,
    handleBlockedNavigation,
    modalVisible,
  } = useCustomPrompt(onCancel, onConfirm, setIsNavigateAway);

  const handleCancel = () => {
    closeModal();

    if (!!onClose) onClose();
  };

  const customConfirmNavigation = () => {
    toggleNavLoading();
    handleConfirmNavigationClick();
  };

  return (
    <>
      <Prompt {...{ when }} message={handleBlockedNavigation} />
      <SaveChangesModal
        visible={
          (type === CustomPromptType.SAVE_CHANGES || navigationLoading) &&
          !!onConfirm &&
          when &&
          modalVisible
        }
        onClose={handleClosePress}
        onCancel={handleCancel}
        onConfirm={customConfirmNavigation}
        {...{ confirmDisabled }}
      />
      <CancelConfigurationModal
        visible={
          type === CustomPromptType.CANCEL &&
          !navigationLoading &&
          when &&
          modalVisible
        }
        onConfirm={handleCancel}
        onCancel={handleClosePress}
      />
    </>
  );
};
export default CustomPrompt;
