import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootAction, RootState } from 'store/reducers';
import { toast } from 'components/lib/toast';
import ToastType from 'utils/Enums/ToastType';
import errorToast from 'utils/functions/errorToast';
import { TOAST_AUTO_CLOSE_TIME } from 'utils/consts';
import {
  RESET_PERFERENCES,
  SET_ALL_PREFERENCES,
  SET_PREFERENCES,
  SET_PREFERENCES_LOADING,
  SET_SAVING_PREFERENCES,
} from 'store/constants/preferences.consts';
import globalIntl from 'providers/IntlProviderWrapper/globalIntlSingleton';
import {
  getAllPreferences,
  preferencesSelector,
} from 'store/selectors/preferencesSelectors';
import { apiCall } from 'utils/api';
import { PREFERENCES } from 'utils/endpoints';
import { isSuccess } from 'utils/apiUtils';
import { resetFilters, setAllFilters } from './filtersActions';
import { StatusCodes } from 'http-status-codes';
import {
  FilterPreferences,
  GeneralPreferences,
  LayoutPreferences,
  PreferencesDataState,
  PreferencesTypes,
  TableLayoutPreferences,
  TablePreferences,
  TableUrlParams,
} from 'utils/types/api/preferences.types';
import { AxiosError } from 'axios';
import debounce from 'lodash/debounce';
import { preferencesInitialState } from 'store/reducers/preferencesReducer';

const DEBOUNCE_INTERVAL = 1000;

export interface SetPreferencesAction extends Action<typeof SET_PREFERENCES> {
  payload: {
    key: keyof PreferencesDataState;
    payload: Partial<PreferencesDataState[keyof PreferencesDataState]>;
  };
}

export interface SetAllPreferencesAction
  extends Action<typeof SET_ALL_PREFERENCES> {
  payload: PreferencesDataState;
}

export interface SetPreferencesLoadingAction
  extends Action<typeof SET_PREFERENCES_LOADING> {
  loading: boolean;
}

export interface SetSavingPreferences
  extends Action<typeof SET_SAVING_PREFERENCES> {
  saving: boolean;
}

export type ResetPreferencesAction = Action<typeof RESET_PERFERENCES>;

export type PreferencesAction =
  | SetPreferencesAction
  | SetAllPreferencesAction
  | SetPreferencesLoadingAction
  | ResetPreferencesAction
  | SetSavingPreferences;

export const setPreferences = (
  key: keyof PreferencesDataState,
  payload: Partial<PreferencesDataState[typeof key]>
): SetPreferencesAction => {
  return {
    type: SET_PREFERENCES,
    payload: {
      key,
      payload,
    },
  };
};

export const setAllPreferences = (
  payload: PreferencesDataState
): SetAllPreferencesAction => {
  return {
    type: SET_ALL_PREFERENCES,
    payload,
  };
};

export const setPreferencesLoading = (
  loading: boolean
): SetPreferencesLoadingAction => {
  return {
    type: SET_PREFERENCES_LOADING,
    loading,
  };
};

export const resetPreferences = (): ResetPreferencesAction => {
  return {
    type: RESET_PERFERENCES,
  };
};

export const setSavingPreferences = (saving: boolean): SetSavingPreferences => {
  return {
    type: SET_SAVING_PREFERENCES,
    saving,
  };
};

export const getPreferences = (): ThunkAction<
  Promise<boolean>,
  RootState,
  undefined,
  RootAction
> => async (dispatch, getState) => {
  const currentPreferences = getAllPreferences(getState());
  const preferencesKeys = Object.keys(currentPreferences) as PreferencesTypes[];

  try {
    const requests = await Promise.allSettled(
      preferencesKeys.map(key => apiCall.get(`${PREFERENCES}${key}/`))
    );

    const parsedPreferences = requests.reduce((acc, current, index) => {
      if (current.status === 'fulfilled') {
        const parsed = JSON.parse(current.value.data.preferences);
        return {
          ...acc,
          [preferencesKeys[index]]: parsed,
        };
      }
      return acc;
    }, {} as PreferencesDataState);
    const { filters } =
      parsedPreferences[PreferencesTypes.FilterPreferences] ?? {};

    dispatch(setAllPreferences(parsedPreferences));

    if (filters) {
      dispatch(setAllFilters(filters));
    }

    return true;
  } catch (error) {
    const { code, response: { status } = {} } = error as AxiosError;

    //preferences not found or Request aborted - fallback to defaults
    if (status === StatusCodes.NOT_FOUND || code === 'ECONNABORTED')
      return false;

    return false;
  } finally {
    // this call replaces intl singleton with new instance using preferences language
    globalIntl.recreateInstance();
  }
};

export const updateSelectedColumns = (
  classID: string,
  newColumns?: string[]
): ThunkAction<Promise<boolean>, RootState, undefined, PreferencesAction> => (
  dispatch,
  getState
) => {
  const selectedAlready = preferencesSelector(
    getState(),
    PreferencesTypes.TableLayoutPreferences
  );

  return dispatch(
    updatePreferences(PreferencesTypes.TableLayoutPreferences, {
      ...selectedAlready,
      selectedColumns: {
        ...selectedAlready.selectedColumns,
        [classID]: newColumns ?? undefined,
      },
    })
  );
};

export const updatePreferences = <
  K extends PreferencesTypes,
  T extends Partial<PreferencesDataState[K]>
>(
  preferenceKey: K,
  newPreferences: T,
  options = { showMessage: false }
): ThunkAction<Promise<boolean>, RootState, undefined, PreferencesAction> => (
  dispatch,
  getState
) => {
  dispatch(setSavingPreferences(true));

  try {
    const oldPreferences = preferencesSelector(getState(), preferenceKey); //change that
    const mergedPreferences = { ...oldPreferences, ...newPreferences };

    dispatch(setPreferences(preferenceKey, mergedPreferences));

    switch (preferenceKey) {
      case PreferencesTypes.GeneralPreferences:
        debouncedSaveGeneralPreferences(
          (mergedPreferences as unknown) as GeneralPreferences, //weird TS error - mergedPreferences is PreferencesDataState[K] and K equals GeneralPreferences
          !!(newPreferences as Partial<GeneralPreferences>)?.language,
          options.showMessage
        );
        break;
      case PreferencesTypes.LayoutPreferences:
        debouncedSaveLayoutPreferences(
          (mergedPreferences as unknown) as LayoutPreferences,
          options.showMessage
        );
        break;
      case PreferencesTypes.TableLayoutPreferences:
        debouncedSaveTableLayoutPreferences(
          (mergedPreferences as unknown) as TableLayoutPreferences,
          options.showMessage
        );
        break;
      case PreferencesTypes.TablePreferences:
        debouncedSaveTablePreferences(
          (mergedPreferences as unknown) as TablePreferences,
          options.showMessage
        );
        break;
      case PreferencesTypes.FilterPreferences:
        debouncedSaveFilterPreferences(
          (mergedPreferences as unknown) as FilterPreferences,
          options.showMessage
        );
        break;
    }

    dispatch(setSavingPreferences(false));
    return Promise.resolve(true);
  } catch {
    dispatch(setSavingPreferences(false));
    return Promise.reject();
  }
};

//change to reset each part of preferences
export const resetPreferencesAction = (): ThunkAction<
  void,
  RootState,
  undefined,
  RootAction
> => async (dispatch, getState) => {
  const preferencesKeys = Object.keys(
    getAllPreferences(getState())
  ) as PreferencesTypes[];
  dispatch(resetPreferences());
  dispatch(resetFilters());

  // this call replaces intl singleton with new instance using preferences language
  globalIntl.recreateInstance();

  try {
    await Promise.allSettled(
      preferencesKeys.map(key =>
        apiCall.post(`${PREFERENCES}${key}/`, {
          preferences: JSON.stringify(preferencesInitialState.data[key]),
        })
      )
    );

    toast(
      {
        title: globalIntl.intl.formatMessage({
          id: 'misc.preferencesUpdated',
          defaultMessage: 'Preferences updated',
        }),
      },
      ToastType.Success,
      { autoClose: TOAST_AUTO_CLOSE_TIME }
    );
  } catch {}
};

export const setTablesState = ({
  tableName,
  ...tableState
}: { tableName: string | undefined } & TableUrlParams): ThunkAction<
  void,
  RootState,
  undefined,
  PreferencesAction
> => async (dispatch, getState) => {
  if (!tableName) return;

  const tableLayoutPreferences = preferencesSelector(
    getState(),
    PreferencesTypes.TableLayoutPreferences
  );

  await dispatch(
    updatePreferences(PreferencesTypes.TableLayoutPreferences, {
      ...tableLayoutPreferences,
      tablesState: {
        ...tableLayoutPreferences.tablesState,
        [tableName]: tableState,
      },
    })
  );
};

const debouncedSaveGeneralPreferences = debounce(
  async (
    mergedPreferences: PreferencesDataState[PreferencesTypes.GeneralPreferences],
    recreateInstance?: boolean,
    showMessage?: boolean
  ) =>
    debouncedSavePreferences(
      PreferencesTypes.GeneralPreferences,
      mergedPreferences,
      recreateInstance,
      showMessage
    ),
  DEBOUNCE_INTERVAL
);
const debouncedSaveLayoutPreferences = debounce(
  async (
    mergedPreferences: PreferencesDataState[PreferencesTypes.LayoutPreferences],
    showMessage?: boolean
  ) =>
    debouncedSavePreferences(
      PreferencesTypes.LayoutPreferences,
      mergedPreferences,
      false,
      showMessage
    ),
  DEBOUNCE_INTERVAL
);
const debouncedSaveTableLayoutPreferences = debounce(
  async (
    mergedPreferences: PreferencesDataState[PreferencesTypes.TableLayoutPreferences],
    showMessage?: boolean
  ) =>
    debouncedSavePreferences(
      PreferencesTypes.TableLayoutPreferences,
      mergedPreferences,
      false,
      showMessage
    ),
  DEBOUNCE_INTERVAL
);
const debouncedSaveTablePreferences = debounce(
  async (
    mergedPreferences: PreferencesDataState[PreferencesTypes.TablePreferences],
    showMessage?: boolean
  ) =>
    debouncedSavePreferences(
      PreferencesTypes.TablePreferences,
      mergedPreferences,
      false,
      showMessage
    ),
  DEBOUNCE_INTERVAL
);
const debouncedSaveFilterPreferences = debounce(
  async (
    mergedPreferences: PreferencesDataState[PreferencesTypes.FilterPreferences],
    showMessage?: boolean
  ) =>
    debouncedSavePreferences(
      PreferencesTypes.FilterPreferences,
      mergedPreferences,
      false,
      showMessage
    ),
  DEBOUNCE_INTERVAL
);

async function debouncedSavePreferences(
  key: PreferencesTypes,
  mergedPreferences: PreferencesDataState[typeof key],
  recreateInstance?: boolean,
  showMessage?: boolean
) {
  try {
    const { status } = await apiCall.post(`${PREFERENCES}${key}/`, {
      preferences: JSON.stringify(mergedPreferences),
    });

    if (isSuccess(status)) {
      if (recreateInstance) {
        globalIntl.recreateInstance();
      }

      if (showMessage) {
        toast(
          {
            title: globalIntl.intl.formatMessage({
              id: 'misc.preferencesUpdated',
              defaultMessage: 'Preferences updated',
            }),
          },
          ToastType.Success,
          { autoClose: TOAST_AUTO_CLOSE_TIME }
        );
      }
      return Promise.resolve(true);
    } else if (status === StatusCodes.UNAUTHORIZED) {
      errorToast();
    }
    return Promise.resolve(false);
  } catch {
    return Promise.reject();
  }
}
