import { Instance } from 'pspdfkit';

/**
 * Enables or disables the toolbar items when highlighting text in the PSPDF document.
 * @param instance Instance of loaded PSPDF file.
 * @param enableInlineTextToolbarItems Enable or disable.
 */
export const setAllowedInlineTextSelectionToolbarItems = (
  instance: Instance,
  enableInlineTextToolbarItems: boolean
) => {
  instance.setInlineTextSelectionToolbarItems(({ defaultItems }) => {
    return enableInlineTextToolbarItems ? defaultItems : [];
  });
};
