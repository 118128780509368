import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useManageAssigneesStyles } from './styles';
import { ButtonPrimary, ButtonSecondaryOutlined } from 'components/lib/Button';
import { PlusIcon } from 'components/Icon';
import PeopleListElement from 'pages/Records/RecordsListing/RecordAccessPanel/components/PeopleListElement';
import List from 'components/List';
import { AvatarItem } from 'components/lib/Avatar/types';
import { scrollableContainer } from 'utils/elementsIds';
import {
  BUTTON_CANCEL_TESTID,
  MANAGE_ASSIGNEES_PANEL_BUTTON_ADD_ASSIGNEES,
  OBJECT_CLASS_PERMISSIONS_MANAGE_ASSIGNEES,
} from 'utils/testIds';
import useManageAssigneesPanel from './hooks';
import DisabledButtonTooltip from 'components/DisabledButtonTooltip';
import clsx from 'clsx';
import LimitMessage from '../LimitMessage';
import RemoveAssigneeModal from './RemoveAssigneeModal';

const ManageAssigneesPanel = () => {
  const intl = useIntl();
  const classes = useManageAssigneesStyles({});

  const {
    handleCancel,
    assigneesCount,
    limitAssigneesItems,
    assigneesAvatars,
    isLimitExceeded,
    openAddOwnersPanel,
    onDeleteFabric,
    selectedPermissionSet,
    assigneeToDelete,
    setAssigneeToDelete,
    removePermissionSetAssignee,
  } = useManageAssigneesPanel();

  return (
    <div
      className={classes.contentWrapper}
      data-testid={OBJECT_CLASS_PERMISSIONS_MANAGE_ASSIGNEES}
    >
      <p className={classes.name}>{selectedPermissionSet?.name}</p>
      <div className={classes.headerWrapper}>
        <span className={classes.text}>
          <FormattedMessage
            id='objectClass.manageAssignees.counter'
            defaultMessage='{count, plural, one {# assignee} other {# assignees}}'
            values={{ count: assigneesCount }}
          />
        </span>
        <DisabledButtonTooltip
          title={intl.formatMessage({
            id: 'assignees.disabledButtonMessage',
            defaultMessage:
              'The maximum number of assignees has been reached. Remove assignees to add new ones.',
          })}
          showTooltip={isLimitExceeded}
        >
          <ButtonPrimary
            onClick={openAddOwnersPanel}
            icon={<PlusIcon size={9} />}
            data-testid={MANAGE_ASSIGNEES_PANEL_BUTTON_ADD_ASSIGNEES}
            disabled={isLimitExceeded}
            className={clsx({ [classes.buttonDisabled]: isLimitExceeded })}
          >
            <FormattedMessage
              id='misc.addAssignees'
              defaultMessage='Add assignees'
            />
          </ButtonPrimary>
        </DisabledButtonTooltip>
      </div>
      <LimitMessage
        count={assigneesCount}
        itemsLimit={limitAssigneesItems}
        customErrorMessage={intl.formatMessage(
          {
            id: 'owners.limitErrorMessagePermissionSetAssignees',
            defaultMessage:
              'Maximum of {itemsLimit} {itemsLimit, plural, one {assignee} other {assignees}} has been reached.',
          },
          { itemsLimit: limitAssigneesItems }
        )}
        customWarningMessage={intl.formatMessage(
          {
            id: 'owners.limitMessagePermissionSetAssignees',
            defaultMessage:
              'A permission set can have up to {itemsLimit} {itemsLimit, plural, one {assignee} other {assignees}}. This currently has {count}',
          },
          { itemsLimit: limitAssigneesItems, count: assigneesCount }
        )}
      />
      <div className={classes.scrollableContainer} id={scrollableContainer}>
        <List<AvatarItem>
          emptyDataDescription={intl.formatMessage({
            id: 'objectClass.manageAssignees.noUsersHasBeenAssigneed',
            defaultMessage: 'No users have been assigned',
          })}
          items={assigneesAvatars}
          renderItem={item => (
            <PeopleListElement {...item} onDelete={onDeleteFabric(item)} />
          )}
        />
      </div>
      <div className={classes.buttonsWrapper}>
        <ButtonSecondaryOutlined
          onClick={handleCancel}
          data-testid={BUTTON_CANCEL_TESTID}
        >
          <FormattedMessage id='misc.close' defaultMessage='Close' />
        </ButtonSecondaryOutlined>
      </div>
      <RemoveAssigneeModal
        {...{
          assigneeToDelete,
          setAssigneeToDelete,
          removePermissionSetAssignee,
        }}
      />
    </div>
  );
};

export default ManageAssigneesPanel;
