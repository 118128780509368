import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as AccessDeniedIllustration } from 'img/illustrations/access-denied.svg';
import { Heading } from 'components/lib/Heading';
import { Text } from 'components/lib/Text';
import usePermissionDeniedStyles from './styles';
import { PermissionDeniedProps } from './types';

const PermissionDenied = ({ title, description }: PermissionDeniedProps) => {
  const classes = usePermissionDeniedStyles({});

  return (
    <div className={classes.container}>
      <AccessDeniedIllustration className={classes.illustration} />
      <div className={classes.textContainer}>
        <Heading level={3} className={classes.title}>
          {title ?? (
            <FormattedMessage
              id='misc.accessDenied'
              defaultMessage='Access denied'
            />
          )}
        </Heading>
        <div className={classes.paragraph}>
          <Text fontSize={14}>
            {description ?? (
              <FormattedMessage
                id='misc.accessDeniedDescription'
                defaultMessage='You do not have access to view this page. Contact the administrator for details.'
              />
            )}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default PermissionDenied;
