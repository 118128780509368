import { createUseStyles } from 'react-jss';

const useURLWidgetStyles = createUseStyles({
  inputsContainer: {
    display: 'flex',
    flexDirection: 'row',
    aligntems: 'stretch',
    width: '100%',
  },
  protocol: {
    marginRight: '2px',
    width: '100px',
    '& .ant-select-selector': {
      height: '32px !important',
    },
  },
  url: {
    flex: 1,
  },
});

export default useURLWidgetStyles;
