import React from 'react';
import TooltipString from 'components/TooltipString';
import useBoldedPartOfTextStyles from './styles';
import { BoldedPartOfTextProps } from './types';
import Highlighter from 'react-highlight-words';

const BoldedPartOfText: React.FC<BoldedPartOfTextProps> = ({
  value,
  noBoldedValue = '',
  boldPhrase = '',
  hideArrow,
}) => {
  const classes = useBoldedPartOfTextStyles({});

  return boldPhrase ? (
    <TooltipString
      text={`${value}${noBoldedValue}`}
      modifiedText={
        <>
          <Highlighter
            highlightClassName={classes.bolded}
            searchWords={[boldPhrase.trim()]}
            textToHighlight={value}
            autoEscape
          />
          {noBoldedValue && <span>{noBoldedValue}</span>}
        </>
      }
      {...{ hideArrow }}
    />
  ) : (
    <TooltipString text={`${value}${noBoldedValue}`} {...{ hideArrow }} />
  );
};

export default BoldedPartOfText;
