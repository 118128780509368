import React from 'react';
import { useIntl } from 'react-intl';
import ConfirmationModal from 'components/ConfirmationModal';
import ErrorIcon from 'img/illustrations/error.svg';
import { ErrorModalProps } from './types';

const ResourceNotFoundOnSave: React.FC<ErrorModalProps> = ({
  isOpen,
  closeModal,
  onCloseErrorModal,
  title,
  subtitle,
}) => {
  const intl = useIntl();

  const handleConfirmAction = async () => {
    if (onCloseErrorModal) await onCloseErrorModal();

    closeModal();
  };

  return (
    <ConfirmationModal
      visible={isOpen}
      onConfirm={handleConfirmAction}
      {...{ title }}
      subtitle={
        subtitle ||
        intl.formatMessage({
          id: 'errors.yourChangesCanNoLongerBeSaved',
          defaultMessage: 'Your changes can no longer be saved.',
        })
      }
      confirmationButtonLabel={intl.formatMessage({
        id: 'misc.ok',
        defaultMessage: 'Ok',
      })}
      image={ErrorIcon}
    />
  );
};

export default ResourceNotFoundOnSave;
