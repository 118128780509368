import React from 'react';
import { InPlaceEditDateTimeProps } from './types';
import useInPlaceEdit from '../useInPlaceEdit';
import InPlaceEditWrapper from '../components/InPlaceEditWrapper';
import { useErrorMessages } from '../../../utils/useErrorMessages';
import { InPlaceEditError } from '../utils';
import FieldValidationMessage from '../components/FieldValidationMessage';
import { DatePicker } from 'components/lib/DatePicker';
import moment from 'moment';
import useInPlaceEditDateTimeStyles from './styles';
import { Dropdown } from 'components/lib/Dropdown';
import { IN_PLACE_EDIT_DATEPICKER } from 'utils/testIds';
import useDateFormat from 'hooks/useDateFormat';
import { CUSTOM_DATE_FORMAT } from 'pages/TaskTemplates/components/TaskForm/consts';
import useTimezone from 'hooks/useTimezone';

const InPlaceEditDateTime = <R extends Object>({
  label,
  required,
  value,
  disabled,
  propertyName,
  patchUrl,
  onSaveSuccess,
  showTime,
  getPopupContainer,
  remountChangeTarget,
  readOnly,
  size,
  withUnderline,
}: InPlaceEditDateTimeProps<R>) => {
  const {
    isEditMode,
    setTempValue,
    tempValue,
    isSaving,
    setIsEditModeOn,
    errorMessage,
    setErrorMessage,
    setIsSavingOn,
    inputWrapperRef,
  } = useInPlaceEdit({
    initialValue: !!value ? value : null,
    patchUrl,
    propertyName,
    onSaveSuccess,
    withOutsideClick: false,
  });
  const styles = useInPlaceEditDateTimeStyles({
    isError: !!errorMessage,
    size,
  });
  const errorMessages = useErrorMessages();
  const { dateFormat, getTimeFormat } = useDateFormat();
  const { timezone } = useTimezone();

  const format = showTime ? `${dateFormat} ${getTimeFormat()}` : dateFormat;

  const onViewClick = () => {
    if (disabled) return;

    setIsEditModeOn();
  };

  const onInputChange = (value: moment.Moment | null) => {
    if (required && !value)
      setErrorMessage(errorMessages[InPlaceEditError.FIELD_IS_REQUIRED_ERROR]);
    else setErrorMessage(undefined);

    if (!showTime && value) setTempValue(value.format(CUSTOM_DATE_FORMAT));
    else if (value)
      setTempValue(value.seconds(0).milliseconds(0).toISOString());
    else setTempValue(null);

    if (required && !value) return;

    setIsSavingOn();
  };

  const handleOnOpenChange = (isOpen: boolean) => {
    if (!isOpen)
      setTimeout(() => {
        // we need setTimeout to wait for correct value from input below (after clicking "Clear" icon)
        const val = inputWrapperRef?.current?.querySelector('input')?.value;

        if (!val && required) {
          setTempValue(null);
          setErrorMessage(
            errorMessages[InPlaceEditError.FIELD_IS_REQUIRED_ERROR]
          );

          return;
        }

        setIsSavingOn();
      });
  };

  return (
    <InPlaceEditWrapper
      {...{
        isEditMode,
        isSaving,
        label,
        required,
        disabled,
        onViewClick,
        inputWrapperRef,
        readOnly,
        size,
        withUnderline,
      }}
      maxWidth={200}
      editContent={
        <Dropdown
          {...{ getPopupContainer, remountChangeTarget }}
          visible={!isSaving && !!errorMessage}
          overlayClassName={styles.pickerDropdown}
          overlay={
            <FieldValidationMessage error={errorMessage} displayAfterParent />
          }
        >
          <DatePicker
            allowClear
            defaultOpen
            onChange={onInputChange}
            value={!!tempValue ? moment(tempValue) : undefined}
            className={styles.picker}
            {...{ showTime, getPopupContainer, format }}
            showSecond={false}
            onOpenChange={handleOnOpenChange}
            onFocus={() => setErrorMessage(undefined)}
            dropdownClassName={styles.pickerDropdown}
            data-testid={IN_PLACE_EDIT_DATEPICKER}
          />
        </Dropdown>
      }
      viewContent={
        !!value?.length ? (
          <span>{moment(value).tz(timezone).format(format)}</span>
        ) : (
          '-'
        )
      }
    />
  );
};

export default InPlaceEditDateTime;
