import { ObjectClassDetailsFromApi } from 'pages/ObjectClasses/components/ObjectClassForm/hooks/useObjectClassDetailsData/types';
import { ObjectRecordDetails } from 'utils/types/api/objectRecords.types';

export enum SummaryPanelSource {
  ADDITIONAL_DATA = 'ADDITIONAL_DATA',
  DATA = 'DATA',
}

export type SummaryPanelProps = {
  dataSource?: SummaryPanelSource;
  RenderHeader?: React.FC<{
    objectClass: ObjectClassDetailsFromApi;
    objectRecord: ObjectRecordDetails;
  }>;
  RenderPermissionDenied?: React.FC;
};
