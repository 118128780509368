import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import ConfirmationModal from 'components/ConfirmationModal';
import { ErrorModalProps } from './types';
import ErrorIcon from 'img/illustrations/error.svg';

const ErrorModal: React.FC<ErrorModalProps> = ({ isOpen, closeModal }) => {
  const intl = useIntl();

  const errorsConfirmationProps = useMemo(() => {
    return {
      title: intl.formatMessage({
        id: 'objectRecords.form.duplicateValues',
        defaultMessage: 'Duplicate values.',
      }),
      subtitle: intl.formatMessage({
        id: 'objectRecords.form.duplicateValuesWereFoundInUniqueFields',
        defaultMessage: 'Duplicate values were found in unique fields.',
      }),
      confirmationButtonLabel: intl.formatMessage({
        id: 'misc.ok',
        defaultMessage: 'Ok',
      }),
      image: ErrorIcon,
    };
  }, [intl]);

  return (
    <ConfirmationModal
      visible={isOpen}
      onConfirm={closeModal}
      {...errorsConfirmationProps}
    />
  );
};

export default ErrorModal;
