import { createUseStyles } from 'react-jss';

const useActionMenuStyles = createUseStyles({
  dropdownOverlay: {
    boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.05)',
  },
  actionButton: {
    background: 'rgba(0, 0, 0, 0.028)',
    borderColor: 'transparent',
  },
});

export default useActionMenuStyles;
