import React, { useMemo } from 'react';
import { RECORD_FORM_DRAWER_PATHS, TASK_DISPLAY_DRAWER_PATHS } from './consts';
import useCheckIfOnAvailablePath from './hooks';
import RecordDisplayDrawer from './RecordDisplayDrawer';
import TaskDisplayDrawer from './TaskDisplayDrawer';

const MultiPageComponents = () => {
  const checkIfOnAvailablePath = useCheckIfOnAvailablePath();

  const activePaths = useMemo(
    () => ({
      TASK_DISPLAY_DRAWER: checkIfOnAvailablePath(TASK_DISPLAY_DRAWER_PATHS),
      RECORD_FORM_DRAWER: checkIfOnAvailablePath(RECORD_FORM_DRAWER_PATHS),
    }),
    [checkIfOnAvailablePath]
  );

  return (
    <>
      {activePaths.TASK_DISPLAY_DRAWER && <TaskDisplayDrawer />}
      {activePaths.RECORD_FORM_DRAWER && <RecordDisplayDrawer />}
    </>
  );
};

export default MultiPageComponents;
