import axios, { CancelToken } from 'axios';
import { apiCall } from 'utils/api';
import { LogLevel, Logger } from 'utils/logger';
import {
  checkifCachedLicenseExpired,
  loadLicenseData,
  saveLicenseData,
} from './utils';
import { PSPDFKIT_LICENSE } from 'utils/endpoints';
import { generatePath } from 'react-router-dom';

export const getPspdfkitLicense = async (
  cancelToken: CancelToken
): Promise<string | undefined> => {
  const licenseData = loadLicenseData();

  if (licenseData && !checkifCachedLicenseExpired(licenseData)) {
    Logger.log(
      `FileViewer: Returning cached license key: ${licenseData.licenseKey}`,
      LogLevel.Verbose
    );
    return licenseData.licenseKey;
  }

  const clientHost = window.location.hostname;
  Logger.log(
    `FileViewer: No license in cache. Fetching for host: ${clientHost}.`,
    LogLevel.Verbose
  );

  try {
    const endpoint = generatePath(PSPDFKIT_LICENSE, { clientHost });

    const result = await apiCall.get(endpoint, {
      cancelToken,
    });

    if (!result.data[clientHost]) {
      Logger.log(
        `FileViewer: Retrieved license data is in invalid format.`,
        LogLevel.Warning
      );
      return undefined;
    }

    const licenseKey = result.data[clientHost];
    Logger.log(
      `FileViewer: Retrieved license. Saving in cache: ${licenseKey}}`,
      LogLevel.Verbose
    );

    saveLicenseData(licenseKey);
    return licenseKey;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      Logger.log(
        `FileViewer: Unable to fetch license. Returning no license. ${error.message}`,
        LogLevel.Warning
      );
      return undefined;
    }

    throw error;
  }
};
