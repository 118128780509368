import { ColumnsMetadata } from 'utils/types/api/table.types';
import { TasksQuickFilterDates, TasksQuickFilterDatesTypes } from '../../types';
import { TODAY } from 'components/Filters/hooks/useDateTimePredicates';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';

const { intl } = GlobalIntlSingleton;
const { DueDate, ExpiryDate, Status } = TasksQuickFilterDatesTypes;

export const addStatusOverduePredicate = (
  columns: MappedObject<ColumnsMetadata, string>
) => {
  return [
    {
      column: columns[Status].alias,
      type: columns[Status].type,
      predicates: columns[Status].predicates || [],
      label: intl.formatMessage({
        id: 'tasksPanel.overdue',
      }),
      value: {
        predicateKey: 'exact',
        predicateValues: {
          value: 'overdue',
        },
        predicateArgs: ['value'],
        isMultipleSelection: true,
      },
    },
  ];
};

export const getDatePredicate = (
  columns: MappedObject<ColumnsMetadata, string>,
  value: TasksQuickFilterDates
) => {
  return [
    {
      column: columns[DueDate].alias,
      type: columns[DueDate].type,
      predicates: columns[DueDate].predicates || [],
      label: datePredicateLabels[value],
      value: {
        predicateKey: value,
        predicateValues: {
          value: value,
        },
        predicateArgs: ['value'],
        isMultipleSelection: true,
      },
    },
  ];
};

export const getDatePredicateExpiry = (
  columns: MappedObject<ColumnsMetadata, string>
) => {
  return [
    {
      column: columns[ExpiryDate].alias,
      type: columns[ExpiryDate].type,
      predicates: columns[ExpiryDate].predicates || [],
      label: datePredicateLabels[TasksQuickFilterDates.ExpiredTasks],
      value: {
        predicateKey: 'lt',
        predicateValues: {
          value: TODAY,
        },
        predicateArgs: ['value'],
        isMultipleSelection: true,
      },
    },
  ];
};

const datePredicateLabels = {
  [TasksQuickFilterDates.Default]: '------',
  [TasksQuickFilterDates.NoDueDate]: intl.formatMessage({
    id: 'predicates.isNull',
  }),
  [TasksQuickFilterDates.Today]: intl.formatMessage({ id: 'predicates.today' }),
  [TasksQuickFilterDates.ThisWeek]: intl.formatMessage({
    id: 'predicates.thisWeek',
  }),
  [TasksQuickFilterDates.WithinAWeek]: intl.formatMessage({
    id: 'predicates.withinWeek',
  }),
  [TasksQuickFilterDates.Within30Days]: intl.formatMessage({
    id: 'predicates.within30Days',
  }),
  [TasksQuickFilterDates.Yesterday]: intl.formatMessage({
    id: 'predicates.yesterday',
  }),
  [TasksQuickFilterDates.WithinTheLastWeek]: intl.formatMessage({
    id: 'predicates.withinLastWeek',
  }),
  [TasksQuickFilterDates.WithinTheLast30Days]: intl.formatMessage({
    id: 'predicates.withinLast30Days',
  }),
  [TasksQuickFilterDates.ExpiredTasks]: intl.formatMessage({
    id: `predicates.expiredTasks`,
  }),
};

export const TaskQuickFilterOverdueDates = [
  TasksQuickFilterDates.Yesterday,
  TasksQuickFilterDates.WithinTheLastWeek,
  TasksQuickFilterDates.WithinTheLast30Days,
];
