export const isInMinMaxRange = (
  value: number,
  isMinChange: boolean,
  minValue?: number,
  maxValue?: number
) => {
  return isMinChange
    ? maxValue !== undefined && value > maxValue
    : minValue !== undefined && value < minValue;
};
