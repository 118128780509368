import { createUseStyles } from 'react-jss';

const useFilterSelectStyles = createUseStyles({
  select: {
    width: '195px !important',
    '& .ant-select-selector': {
      height: '35px !important',
    },
    '& .ant-select-selection-item': {
      lineHeight: '33px !important',
    },
  },
  selectWrapper: {
    '& > .ant-form-item': {
      marginBottom: 0,
    },
  },
});

export default useFilterSelectStyles;
