import React from 'react';
import { Radio as AntRadio } from 'antd';
import { RadioGroupProps as AntRadioGruopProps } from 'antd/lib/radio';
import { RadioButtonProps as AntRadioButtonProps } from 'antd/lib/radio/radioButton';
import {
  Radio as FRadio,
  RadioGroupProps as FRadioGroupProps,
} from 'formik-antd';

import useRadioStyles from './styles';
import clsx from 'clsx';
import useGlobalStyle from 'hooks/useGlobalStyle';

export interface RadioGroupProps extends AntRadioGruopProps {
  optionType?: 'button';
}

export interface RadioButtonProps extends AntRadioButtonProps {
  buttonStyle?: 'solid' | 'outline';
}

export interface FRadioGroupPropsWithReadonly extends FRadioGroupProps {
  readOnly?: boolean;
}

const { Group: AntGroup, Button: AntRadioButton } = AntRadio;
const { Group: FGroup, Button: FRadioButton } = FRadio;

export const Radio: React.FC<RadioButtonProps> = ({ className, ...rest }) => {
  const classes = useRadioStyles({});

  return <AntRadio {...rest} className={clsx([classes.radio, className])} />;
};

export const FormikRadio: React.FC<FRadioGroupPropsWithReadonly> = ({
  className,
  disabled,
  ...rest
}) => {
  const classes = useRadioStyles({});
  const globalClasses = useGlobalStyle();

  return (
    <FRadio
      {...{ ...rest, disabled }}
      className={clsx([
        classes['radio'],
        className,
        { [globalClasses.disabledInput]: disabled },
      ])}
    />
  );
};

export const RadioButton: React.FC<RadioButtonProps> = ({
  buttonStyle,
  ...rest
}) => {
  const classes = useRadioStyles({});

  return (
    <AntRadioButton
      {...rest}
      className={
        buttonStyle === 'solid' ? classes.radioButtonSolid : classes.radioButton
      }
    />
  );
};

export const FormikRadioButton: React.FC<RadioButtonProps> = ({
  buttonStyle,
  ...rest
}) => {
  const classes = useRadioStyles({});

  return (
    <FRadioButton
      {...rest}
      className={
        buttonStyle === 'solid' ? classes.radioButtonSolid : classes.radioButton
      }
    />
  );
};

export const RadioGroup: React.FC<RadioGroupProps> = ({
  options,
  optionType,
  buttonStyle,
  ...rest
}) => {
  const Element = optionType === 'button' ? RadioButton : Radio;

  return (
    <AntGroup {...rest}>
      {options?.map(option =>
        typeof option === 'string' ? (
          <Element key={option} value={option} buttonStyle={buttonStyle}>
            {option}
          </Element>
        ) : (
          <Element
            key={option.value.toString()}
            value={option.value}
            buttonStyle={buttonStyle}
            disabled={option.disabled}
          >
            {option.label}
          </Element>
        )
      )}
    </AntGroup>
  );
};

export const FormikRadioGroup: React.FC<FRadioGroupProps> = ({ ...rest }) => (
  <FGroup {...rest} />
);
