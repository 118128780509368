import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { ActionCellProps } from 'components/Table/types';
import { toast } from 'components/lib/toast';
import {
  getDocumentTemplatePanelColumnConfiguration,
  getDocumentTemplatesPanel,
  generateAndDownloadDocumentTemplatePanel,
  refetchDocumentTemplatePanelByIds,
  setDocumentTemplatePanelError,
} from 'store/actions/documentTemplatesPanelActions';
import {
  getDocumentTemplatesPanelError,
  getDocumentTemplatesPanelTotal,
  selectDocumentTemplatesPanel,
} from 'store/selectors/documentTemplatesPanelSelectors';
import DataFetchType from 'utils/Enums/DataFetchType';
import { DocumentStatus } from 'utils/types/api/documentTemplate.types';
import ToastType from 'utils/Enums/ToastType';
import useFlexLayoutWindow from 'hooks/useFlexLayoutWindow';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';

const useDocumentTemplatesPanel = (id: string) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const documentTemplates = useSelector(selectDocumentTemplatesPanel);
  const total = useSelector(getDocumentTemplatesPanelTotal);
  const error = useSelector(getDocumentTemplatesPanelError);
  const intervalRef = useRef<NodeJS.Timeout>();
  const wasToastDisplayed = useRef(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState('');
  const { onOpenTab } = useFlexLayoutWindow(FlexLayoutWindows.ActivityLog);
  const {
    selectedResource,
    setSelectedResource,
  } = useSelectedResourceContext();

  useEffect(() => {
    if (id) dispatch(setDocumentTemplatePanelError(undefined));
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      if (intervalRef.current && !wasToastDisplayed.current) {
        wasToastDisplayed.current = true; //component is unmounting twice so it prevent for double display
        toast(
          {
            title: intl.formatMessage({
              id: 'misc.info',
              defaultMessage: 'Info!',
            }),
            subtitle: intl.formatMessage({
              id: 'documentTemplatesPanel.documentStillInProgress',
              defaultMessage:
                'The document generation process is still in progress. Please check back later.',
            }),
          },
          ToastType.Info
        );
      }
    };
  }, [intl]);

  useEffect(() => {
    const processingIds = documentTemplates
      .filter(
        ({ generated_document }) =>
          generated_document &&
          generated_document.status === DocumentStatus.Processing
      )
      .map(({ id }) => id);

    //If no processing templates and interval is set => clear interval
    if (processingIds.length === 0 && intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
      return;
    }

    //Check if new processing templates are set
    if (processingIds.length) {
      //Clear existing interval if needed
      if (intervalRef.current) clearInterval(intervalRef.current);

      const interval = setInterval(() => {
        dispatch(refetchDocumentTemplatePanelByIds(id, processingIds));
      }, 2000);

      intervalRef.current = interval;

      return () => clearInterval(interval);
    }
  }, [documentTemplates, id, dispatch]);

  const fetchDocumentTemplatesPanel = useCallback(
    (
      queryParams?: string,
      fetchType:
        | DataFetchType.Append
        | DataFetchType.Overwrite = DataFetchType.Overwrite
    ) => getDocumentTemplatesPanel(id, queryParams, fetchType),
    [id]
  );

  const getColumnsConfiguration = useCallback(() => {
    return getDocumentTemplatePanelColumnConfiguration(id);
  }, [id]);

  const downloadDocumentTemplate = useCallback(
    ({ id: documentId }: ActionCellProps) => {
      dispatch(generateAndDownloadDocumentTemplatePanel(id, documentId));
    },
    [dispatch, id]
  );

  const saveDocumentTemplate = useCallback(
    ({ id: documentId }: ActionCellProps) => {
      setSelectedDocumentId(documentId);
    },
    []
  );

  const viewDocumentHistory = useCallback(() => {
    setSelectedResource({ ...selectedResource });
    onOpenTab();
  }, [selectedResource, onOpenTab, setSelectedResource]);

  const closeModal = useCallback(() => {
    setSelectedDocumentId('');
  }, []);

  return {
    total,
    error,
    fetchDocumentTemplatesPanel,
    getColumnsConfiguration,
    downloadDocumentTemplate,
    saveDocumentTemplate,
    viewDocumentHistory,
    closeModal,
    selectedDocumentId,
  };
};

export default useDocumentTemplatesPanel;
