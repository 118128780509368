import React from 'react';
import { Input } from 'components/lib/Input';
import useInPlaceEmailInputStyles from './styles';
import { InPlaceEditEmailProps } from './types';
import useInPlaceEdit from '../useInPlaceEdit';
import InPlaceEditWrapper from '../components/InPlaceEditWrapper';
import { useErrorMessages } from '../../../utils/useErrorMessages';
import { InPlaceEditError } from '../utils';
import FieldValidationMessage from '../components/FieldValidationMessage';
import { EmailIcon } from 'components/Icon';
import Tooltip from 'components/lib/Tooltip';
import checkOsIs, { Os } from 'utils/functions/checkOsIs';
import { useIntl } from 'react-intl';
import useCommonInPlaceEditStyles from '../components/commonStyles';
import { EMAIL_REGEX } from 'utils/consts';
import { Dropdown } from 'components/lib/Dropdown';
import { IN_PLACE_EDIT_EMAIL } from 'utils/testIds';

const InPlaceEditEmail = <R extends Object>({
  label,
  required,
  value,
  disabled,
  propertyName,
  patchUrl,
  onSaveSuccess,
  getPopupContainer,
  remountChangeTarget,
  readOnly,
  size,
  withUnderline,
  maxLength = 254,
}: InPlaceEditEmailProps<R>) => {
  const intl = useIntl();
  const errorMessages = useErrorMessages(undefined, maxLength);

  const validateBeforeSubmit = (value: string | undefined | null) => {
    const emailRegex = new RegExp(EMAIL_REGEX);

    if (!value) return undefined;

    if (!emailRegex.test(value.trim()))
      return errorMessages[InPlaceEditError.INVALID_EMAIL_FORMAT];

    return undefined;
  };

  const {
    inputWrapperRef,
    isEditMode,
    setTempValue,
    tempValue,
    isSaving,
    setIsEditModeOff,
    setIsEditModeOn,
    errorMessage,
    setErrorMessage,
  } = useInPlaceEdit({
    initialValue: value,
    patchUrl,
    propertyName,
    onSaveSuccess,
    validateBeforeSubmit,
  });

  const classes = useCommonInPlaceEditStyles({
    isSaving,
    errorMessage,
    size,
  });
  const emailClasses = useInPlaceEmailInputStyles({});

  const onViewClick = () => {
    if (disabled) return;

    setIsEditModeOn();
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    if (required && value.trim().length === 0)
      setErrorMessage(errorMessages[InPlaceEditError.FIELD_IS_REQUIRED_ERROR]);
    else if (maxLength && value.length > maxLength)
      setErrorMessage(errorMessages[InPlaceEditError.LIMIT_OF_CHARS_EXCEEDED]);
    else setErrorMessage(undefined);

    setTempValue(value);
  };

  const onEmailClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (!event.metaKey && !event.ctrlKey) return;

    event.stopPropagation();
    window.location.href = `mailto:${value}`;
  };

  const tooltipMessage = checkOsIs(Os.Macos)
    ? intl.formatMessage({
        id: 'misc.cmdClickToEmail',
        defaultMessage: 'Cmd + click to email',
      })
    : intl.formatMessage({
        id: 'misc.ctrlClickToEmail',
        defaultMessage: 'Ctrl + click to email',
      });

  const getViewContent = () => {
    if (!!value?.length) {
      return (
        <Tooltip
          title={tooltipMessage}
          placement='topRight'
          {...{ getPopupContainer }}
          className={emailClasses.tooltip}
        >
          <span className={emailClasses.emailWrapper} onClick={onEmailClick}>
            {value}
          </span>
        </Tooltip>
      );
    } else {
      return '-';
    }
  };

  return (
    <InPlaceEditWrapper
      {...{
        isEditMode,
        isSaving,
        label,
        required,
        disabled,
        onViewClick,
        inputWrapperRef,
        readOnly,
        size,
        withUnderline,
      }}
      onCloseClick={setIsEditModeOff}
      editContent={
        <Dropdown
          {...{ getPopupContainer, remountChangeTarget }}
          visible={!isSaving && !!errorMessage}
          overlay={
            <FieldValidationMessage error={errorMessage} displayAfterParent />
          }
        >
          <div className={classes.basicInPlaceInputWrapper}>
            <Input
              withCounter={false}
              value={tempValue ?? ''}
              onChange={onInputChange}
              className={classes.basicInPlaceInput}
              autoFocus
              onKeyDown={event => event.key === 'Escape' && setIsEditModeOff()}
              data-testid={IN_PLACE_EDIT_EMAIL}
              maxLength={maxLength}
            />
            <EmailIcon size={16} />
          </div>
        </Dropdown>
      }
      viewContent={getViewContent()}
    />
  );
};

export default InPlaceEditEmail;
