export const getDynamicPathParts = (
  routingTemplate: string,
  pathname: string
): MappedObject<string> => {
  const tempSplit = routingTemplate.split('/');
  const pathSplit = pathname.split('/');

  if (tempSplit.length !== pathSplit.length) return {};

  const pathParams: MappedObject<string> = {};

  tempSplit.forEach((ele, idx) => {
    if (!ele.includes(':')) return;
    else pathParams[ele.slice(1)] = pathSplit[idx];
  });

  return pathParams;
};
