import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useMoreStyles = createUseStyles((theme: ThemeState) => {
  return {
    wrapper: {
      position: 'relative',
    },
    textWrapper: {
      color: theme['primary-color'],
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    box: {
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
      borderRadius: 5,
      minWidth: 200,
      maxWidth: 300,
      overflow: 'hidden',
      maxHeight: 400,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    viewOption: {
      backgroundColor: theme['info-bg'],
      fontWeight: 600,
      width: 'fit-content',
      marginBottom: 5,
      borderRadius: 2,
      color: theme['primary-color'],
      padding: '0px 5px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
    },
  };
});

export default useMoreStyles;
