import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSidebarData } from 'store/selectors/flexLayoutSelectors';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import RecordOwners from './components/RecordOwners';
import AddUsersAs from './components/AddUsersAs';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import { PanelMode } from 'utils/Enums/PanelMode';

const RecordAccessPanel = () => {
  const dispatch = useDispatch();
  const {
    [FlexLayoutWindows.RecordAccess]: { mode = PanelMode.Manage } = {},
  } = useSelector(getSidebarData);
  const {
    selectedResource: { record: { recordId = undefined } = {} } = {},
  } = useSelectedResourceContext();

  const Panel = useCallback(() => {
    if (mode === PanelMode.Adding) return <AddUsersAs />;
    return <RecordOwners />;
  }, [mode]);

  useEffect(() => {
    // resetting mode to initial after select new selected record
    dispatch(
      setSidebarData(FlexLayoutWindows.RecordAccess, {
        mode: PanelMode.Manage,
      })
    );
  }, [dispatch, recordId]);

  return <Panel />;
};

export default RecordAccessPanel;
