import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import {
  headerWrapper,
  getIdentifier,
  getResultsInfo,
  panelWrapper,
  rightActionButton,
} from 'styles/panelsStyles';

type UseTaskTaskListItemStylesProps = {
  isSelected?: boolean;
  canCreateTask?: boolean;
};

const useTaskTaskListItemStyles = createUseStyles((theme: ThemeState) => {
  return {
    taskName: {
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: 1.3,
      color: theme['heading-color'],
      margin: 0,
    },
    statusIndicator: {
      position: 'absolute',
      width: '3px',
      backgroundColor: theme['primary-color'],
      borderRadius: '5px 0 0 5px',
      top: 0,
      left: '-1px',
      height: '100%',
    },
    dateWrapper: {
      fontSize: 14,
      lineHeight: '19px',
      marginTop: 5,
      marginBottom: 3,
      display: 'block',
    },
    wrapper: ({ isSelected }: UseTaskTaskListItemStylesProps) => ({
      display: 'flex',
      flex: 1,
      padding: '8px 12px',
      backgroundColor: isSelected ? theme['primary-color-light'] : 'unset',
      width: '100%',
    }),
    panelWrapper,
    identifier: {
      ...getIdentifier(theme),
      width: ({ canCreateTask }: UseTaskTaskListItemStylesProps = {}) =>
        canCreateTask ? 'calc(100% - 124px)' : '100%', // panel width - CREATE button
    },
    resultsInfo: getResultsInfo(theme),
    infiniteListWrapper: {
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    headerWrapper,
    leftCol: {
      flex: 1,
    },
    listElementWrapper: {
      padding: 0,
    },
    rightCol: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    completeSection: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 12,
    },
    label: {
      paddingRight: 8,
      color: theme['secondary-color'],
    },
    button: rightActionButton,
    taskNameWrapper: {
      display: 'flex',
      alignItems: 'baseline',
      '& .ant-dropdown-trigger': {
        padding: 0,
        height: 22,
        marginLeft: 4,
      },
      '& .ant-btn-circle': {
        minWidth: 22,
      },
    },
  };
});

export default useTaskTaskListItemStyles;
