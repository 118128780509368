import React, { useCallback, useEffect, useRef } from 'react';
import { SelectOutlined } from '@ant-design/icons';
import NewWindow from 'react-new-window';
import useFlexLayoutStyles from './styles';
import { WindowContextProvider } from './WindowContext';
import ExternalWindowStylesheetSubscriber from './components/ExternalWindowStylesheetSubscriber';

interface WindowRefWrapperProps {
  onCloseWindow: (id: string) => void;
  onReatachWindow: (id: string) => void;
  id: string;
  Component: () => JSX.Element;
  isVisible: boolean;
}

const WindowRefWrapper = ({
  onCloseWindow,
  onReatachWindow,
  id,
  Component,
  isVisible,
}: WindowRefWrapperProps) => {
  const classes = useFlexLayoutStyles({});
  const windowRef = useRef<NewWindow>(null);
  const shouldExecuteOnClose = useRef(true);

  /*
   window is always closed event when reataching to main window so we use this
   to differ when user closes window and when the window is reatached
   */
  const beforeReatachWindow = useCallback(
    (id: string) => {
      shouldExecuteOnClose.current = false;
      onReatachWindow(id);
    },
    [onReatachWindow]
  );

  const onUnload = useCallback(
    () => shouldExecuteOnClose.current && onCloseWindow(id),
    [onCloseWindow, id]
  );

  useEffect(() => {
    if (!isVisible) onCloseWindow(id);
  }, [onCloseWindow, id, isVisible]);

  return (
    <NewWindow ref={windowRef} {...{ onUnload }}>
      <WindowContextProvider {...{ windowRef }}>
        <ExternalWindowStylesheetSubscriber>
          <Component />
          <SelectOutlined
            className={classes.reattachButton}
            onClick={() => beforeReatachWindow(id)}
          />
        </ExternalWindowStylesheetSubscriber>
      </WindowContextProvider>
    </NewWindow>
  );
};

export default WindowRefWrapper;
