import { InPlaceEditError } from 'components/InPlaceEditControls/utils';
import { useIntl } from 'react-intl';

export const useErrorMessages = (label?: string, maxLength = 5000) => {
  const intl = useIntl();

  const fieldRequiredMessage = intl.formatMessage({
    id: 'errors.fieldRequired',
    defaultMessage: 'This field is required',
  });

  const fieldUniqueMessage = intl.formatMessage({
    id: 'errors.fieldMustBeUnique',
    defaultMessage: 'This field must be unique',
  });

  const unknownErrorMessage = intl.formatMessage({
    id: 'errors.unknownError',
    defaultMessage: 'Unknown error, please try again later',
  });

  const invalidJsonFormat = intl.formatMessage(
    {
      id: 'errors.invalid_json_format',
      defaultMessage: '{label} format is invalid.',
    },
    { label: label ?? 'JSON' }
  );

  const invalidEmailFormat = intl.formatMessage({
    id: 'errors.invalid_email_format',
    defaultMessage: 'Email format is invalid.',
  });

  const limitOfCharsExceeded = intl.formatMessage(
    {
      id: 'errors.limit_of_chars_exceeded',
      defaultMessage:
        'Ensure this field has no more than {maxLength} characters.',
    },
    { maxLength }
  );

  const selectedLessThanMinimum = (minimum: string | number) =>
    intl.formatMessage(
      {
        id: 'errors.selected_less_than_minimum',
        defaultMessage:
          'At least {minimum, plural, one {# item} other {# items}} must be selected',
      },
      { minimum }
    );

  const selectedMoreThanMaximum = (maximum: string | number) =>
    intl.formatMessage(
      {
        id: 'errors.selected_more_than_maximum',
        defaultMessage:
          'Maximum {maximum, plural, one {# item} other {# items}} must be selected',
      },
      { maximum }
    );

  return {
    [InPlaceEditError.FIELD_IS_REQUIRED_ERROR]: fieldRequiredMessage,
    [InPlaceEditError.FIELD_UNIQUE_ERROR]: fieldUniqueMessage,
    [InPlaceEditError.UNKNOWN_ERROR]: unknownErrorMessage,
    [InPlaceEditError.INVALID_EMAIL_FORMAT]: invalidEmailFormat,
    [InPlaceEditError.INVALID_JSON_FORMAT]: invalidJsonFormat,
    [InPlaceEditError.LIMIT_OF_CHARS_EXCEEDED]: limitOfCharsExceeded,
    [InPlaceEditError.SELECTED_LESS_THAN_MINIMUM]: selectedLessThanMinimum,
    [InPlaceEditError.SELECTED_MORE_THAN_MAXIMUM]: selectedMoreThanMaximum,
  };
};
