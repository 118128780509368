import React from 'react';
import { ReactComponent as LimitExceededIllustration } from 'img/illustrations/limit-exceeded.svg';

import usePageLimitExceededStyles from './styles';

const ExceededMaximumNumberOfResources: React.FC<{ message: string }> = ({
  message,
}) => {
  const classes = usePageLimitExceededStyles({});

  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <LimitExceededIllustration className={classes.illustration} />
        <p className={classes.title}>{message}</p>
      </div>
    </div>
  );
};

export default ExceededMaximumNumberOfResources;
