export const PREVIEW_FIELD_VALUES = {
  string: ['Sample text 1', 'Sample text 2', 'Sample text 3'],
  multilineString: [
    'Sample text 1 - line 1 \n Sample text 1 - line 2',
    'Sample text 2 - line 1 \n Sample text 2 - line 2',
    'Sample text 3 - line 1 \n Sample text 3 - line 2',
  ],
  json: { eBooks: [{ language: 'Pascal', edition: 'third' }] },
  number: [10, 20, 30],
  float: [1.11, 2.22, 3.33],
  boolean: [true, false, true],
  email: [
    'someone1@example.com',
    'someone2@example.com',
    'someone3@example.com',
  ],
  phone: ['+44 20 7946 0001', '+44 20 7946 0002', '+44 20 7946 0003'],
  dateTime: [
    '2020-01-01T01:10:00.120607+00:00',
    '2020-02-01T02:20:00.120607+00:00',
    '2020-03-01T03:30:00.120607+00:00',
  ],
  url: 'https://www.autologyx.com',
  date: ['2020-01-01', '2020-02-01', '2020-03-01'],
  // negative user ids prevent UserAvatar component from downloading user details on click
  owners: [
    { userGroup: 'Horsesica', id: -1 },
    { firstName: 'Sherley', lastName: 'Devin', id: -10 },
    { firstName: 'Krysten', lastName: 'Barrett', id: -8 },
    { userGroup: 'Appleleaf Keys', id: -4 },
    { firstName: 'April', lastName: 'Dennis', id: -5 },
    { firstName: 'Shell', lastName: 'Hailey', id: -45 },
    { firstName: 'Kaycee', lastName: 'Horsfall', id: -2 },
    { firstName: 'Nannie', lastName: 'Stevenson', id: -3 },
    { firstName: 'Ryder', lastName: 'Boothman', id: -7 },
    { firstName: 'Merle', lastName: 'Rennoll', id: -118 },
  ],
  documents: Array(12).fill({
    name: 'image.png',
    size: '1',
    type: 'image/png',
    status: 'success',
    id: '-1',
    url: '',
  }),

  users: {
    users: [-1, -2, -3],
  },
};
