import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseCheckboxStylesProps = {
  color?: string;
};

const useCheckboxStyles = createUseStyles((theme: ThemeState) => {
  return {
    checkbox: {
      '& .ant-checkbox-checked .ant-checkbox-inner': {
        backgroundColor: theme['primary-color'],
        borderColor: theme['primary-color'],
      },
      '&:hover .ant-checkbox-inner, &:active .ant-checkbox-inner, &:focus .ant-checkbox-inner': {
        borderColor: theme['primary-color'],
      },
      '& .ant-checkbox-checked::after': {
        borderColor: theme['primary-color'],
      },
      '& .ant-checkbox-input:focus + .ant-checkbox-inner': {
        borderColor: theme['primary-color'],
      },
      '& .ant-checkbox-wrapper': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .ant-checkbox-inner': {
        width: 24,
        height: 24,
        top: 1, // fixes missing top border on checkbox
        borderColor: theme['checkbox-border-color'],
        borderWidth: 1.5,
        '&::after': {
          left: '30%',
        },
      },
      '& .ant-typography': {
        fontSize: 14,
      },
    },
    customCheckbox: ({ color }: UseCheckboxStylesProps) => ({
      '& .ant-checkbox-checked .ant-checkbox-inner': {
        backgroundColor: color,
        borderColor: color,
      },
      '&:hover .ant-checkbox-inner, &:active .ant-checkbox-inner, &:focus .ant-checkbox-inner': {
        borderColor: color,
      },
      '& .ant-checkbox-checked::after': {
        borderColor: color,
      },
      '& .ant-checkbox-input:focus + .ant-checkbox-inner': {
        borderColor: color,
      },
    }),
  };
});

export default useCheckboxStyles;
