import React from 'react';
import useEmailStyles from './styles';
import { EmailProps } from './types';

const Email = ({ value }: EmailProps) => {
  const classes = useEmailStyles({});

  return (
    <a
      href={`mailto:${value}`}
      target='_blank'
      rel='noopener noreferrer'
      className={classes.link}
    >
      {value}
    </a>
  );
};

export default Email;
