import React, { useState, Fragment } from 'react';
import moment, { Moment } from 'moment';
import { DatePickerInput } from 'components/Inputs/DatePicker';
import useDateWidgetStyles from './styles';
import { FormattedMessage } from 'react-intl';
import useFormPreviewStyles from 'components/FormPreview2/styles';
import { WidgetProps } from 'alx-dynamic-form';
import useFormPreview2Container from 'components/FormPreview2/hooks/useFormPrevievContainer';

const DateWidget: React.FC<Partial<WidgetProps>> = ({
  disabled,
  onChange,
  value,
  dateGreaterThanToday,
  reValidateField,
  fieldAlias,
}) => {
  const [componentKey, setComponentKey] = useState(new Date().toString());
  const getPopupContainer = useFormPreview2Container();
  const classes = useDateWidgetStyles({});
  const formClasses = useFormPreviewStyles({});

  const disabledDate = dateGreaterThanToday
    ? (currentDate: Moment) => currentDate < moment().startOf('minutes')
    : undefined;

  const handleChange = (nextValue: Moment | null) => {
    if (!onChange) return;

    onChange(nextValue?.seconds(0).milliseconds(0).toISOString() || null);

    if (reValidateField) reValidateField();

    setComponentKey(new Date().toString());
  };

  return (
    // adding key to Fragment it's temporary solution to force mount DatePickerInput, it helps to update method getPopupContainer inside DatePicker
    <Fragment key={componentKey}>
      <DatePickerInput
        data-testid={`date-time-picker-${fieldAlias}`}
        onChange={handleChange}
        showTime
        className={classes.widget}
        value={!!value ? moment(value as string) : undefined}
        showNow={!dateGreaterThanToday}
        {...{ getPopupContainer, disabledDate, dateGreaterThanToday, disabled }}
        onBlur={reValidateField}
      />
      {!!dateGreaterThanToday && (
        <span className={formClasses.description}>
          <FormattedMessage
            id='formBuilder.onlyDatesInTheFuture'
            defaultMessage='Only dates in the future'
          />
        </span>
      )}
    </Fragment>
  );
};

export default DateWidget;
