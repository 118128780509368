import clsx from 'clsx';
import UserAvatar from 'components/UserAvatar';
import useDateFormat from 'hooks/useDateFormat';
import useTimezone from 'hooks/useTimezone';
import moment from 'moment';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { DRAWER_CONTENT_WRAPPER } from 'utils/elementsIds';
import { mapBaseUserInfoToAvatarItem } from 'utils/functions/mapUserToAvatarItem';
import {
  TASK_PROPERTY_COMPLETED,
  TASK_PROPERTY_COMPLETED_BY,
  TASK_PROPERTY_EXPIRY,
} from 'utils/testIds';
import useTaskPropertiesStyles from '../../styles';
import { CompleteTaskPropertiesContentProps } from './types';

const CompleteTaskPropertiesContent = ({
  completionDate,
  completedBy,
  expiryDate,
}: CompleteTaskPropertiesContentProps) => {
  const taskExpiry = expiryDate ? moment(expiryDate).endOf('day') : null;
  const isTaskExpired = taskExpiry?.isBefore(moment());

  const styles = useTaskPropertiesStyles({ expired: isTaskExpired });
  const { dateFormat, getTimeWithSecondsFormat } = useDateFormat();
  const { timezone } = useTimezone();

  const getPopupContainer = () =>
    document.getElementById(DRAWER_CONTENT_WRAPPER) || document.body;

  const userInfo = useMemo(
    () => (completedBy ? mapBaseUserInfoToAvatarItem(completedBy) : undefined),
    [completedBy]
  );

  return (
    <>
      <div className={styles.gridItem}>
        <FormattedMessage
          id='task.stage1Completed'
          defaultMessage='Stage 1 completed: '
        />
        <b data-testid={TASK_PROPERTY_COMPLETED}>
          {completionDate ? (
            moment(completionDate)
              .tz(timezone)
              .format(`${dateFormat} ${getTimeWithSecondsFormat()}`)
          ) : (
            <FormattedMessage id='misc.none' defaultMessage='None' />
          )}
        </b>
      </div>
      <div className={styles.gridItem}></div>
      <div className={clsx([styles.gridItem, styles.expiryItem])}>
        {taskExpiry && (
          <>
            {taskExpiry.isBefore(moment()) ? (
              <FormattedMessage id='task.expired' defaultMessage='Expired: ' />
            ) : (
              <FormattedMessage id='task.expires' defaultMessage='Expires: ' />
            )}
            <b data-testid={TASK_PROPERTY_EXPIRY}>
              {taskExpiry.format(dateFormat)}
            </b>
          </>
        )}
      </div>
      <div className={clsx(styles.gridItem, styles.assigneItem)}>
        <FormattedMessage
          id='task.stage1CompletedBy'
          defaultMessage='Stage 1 completed by: '
        />
        {userInfo ? (
          <>
            <UserAvatar {...{ ...userInfo, getPopupContainer }} size='small' />
            <b data-testid={TASK_PROPERTY_COMPLETED_BY}>{`${
              userInfo.firstName || ''
            } ${userInfo.lastName || ''}`}</b>
          </>
        ) : (
          <b data-testid={TASK_PROPERTY_COMPLETED_BY}>
            <FormattedMessage id='misc.none' defaultMessage='None' />
          </b>
        )}
      </div>
      <div className={styles.gridItem}></div>
      <div className={styles.gridItem}></div>
    </>
  );
};

export default CompleteTaskPropertiesContent;
