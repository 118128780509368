import { createUseStyles } from 'react-jss';

const useModalStyles = createUseStyles({
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 15,
  },
  modalContainer: {
    position: 'relative',
    minHeight: 300,
  },
  modalSelect: {
    marginBottom: 20,
  },
});

export default useModalStyles;
