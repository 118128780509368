export const getParamValue = (
  defaultValue: number,
  paramValue: string,
  additionalConditions?: boolean[]
) => {
  if (
    paramValue &&
    typeof paramValue === 'string' &&
    !isNaN(parseInt(paramValue))
  ) {
    if (additionalConditions && additionalConditions.includes(false)) {
      return defaultValue;
    }

    return parseInt(paramValue);
  }

  return defaultValue;
};
