import React, { useCallback, useEffect, useRef } from 'react';
import { Loader } from 'components/lib/Loader';
import { Select } from 'components/lib/Select';
import { useIntl } from 'react-intl';
import useAutocompleteChildRecordsSelectStyles from './styles';
import clsx from 'clsx';
import {
  AutocompleteChildRecordsSelectProps,
  OptionData,
  SelectOption,
} from './types';
import useAutocompleteSelect from './hooks';
import NoMatchesFound from 'components/NoMatchesFound';
import { CHILD_CLASS_AUTOCOMPLETE_SELECT } from 'utils/testIds';
import { SearchBoldIcon } from 'components/Icon';
import useScrollableParent from 'hooks/useScrollableParent';

const AutocompleteChildClassSelect = ({
  autocompleteUrl = '',
  className,
  getPopupContainer,
  onChange,
  placeholder,
  renderOption,
  value = [],
  handleOnBlur,
  disabled,
  selectMultiple = false,
  onClear,
  allowClear,
}: AutocompleteChildRecordsSelectProps) => {
  const intl = useIntl();
  const classes = useAutocompleteChildRecordsSelectStyles({ disabled });
  const {
    data,
    isOpenSelect,
    loading,
    handleChange,
    onBlur,
    onFocus,
    onSearch,
    searchValue,
  } = useAutocompleteSelect({
    autocompleteUrl,
    onChange,
    handleOnBlur,
    selectMultiple,
  });

  const elementRef = useRef<HTMLDivElement>(null);

  const handleOnChange = useCallback(
    (_: SelectOption, option: unknown) => {
      //option is the whole Option component rendered under the select, but typing at the moment was too complex in order to fix it

      handleChange(option as OptionData);
      // #36005 - The input needs to be un-focused to prevent unwanted behaviour.
      if (elementRef.current && !selectMultiple) {
        const el = elementRef.current.querySelector('input');
        if (el) el.blur();
      }
    },
    [handleChange, selectMultiple]
  );

  // blur select when it is disabled so it wont open immediately after enabling it again
  useEffect(() => {
    if (!disabled) return;

    onBlur();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const getScrollableParent = useScrollableParent(elementRef);

  return (
    // we add key from disabled value as Select component can't be safely blurred
    // which makes placeholder always visible even when user enters value
    <div
      style={{ position: 'relative' }}
      ref={elementRef}
      key={disabled?.toString() || ''}
    >
      <Select
        getPopupContainer={getPopupContainer || getScrollableParent}
        showSearch
        open={isOpenSelect}
        className={clsx(classes.autocompleteSelect, className)}
        placeholder={
          placeholder ??
          intl.formatMessage({
            id: 'placeholders.searchForRecords',
            defaultMessage: 'Search for a record',
          })
        }
        notFoundContent={loading ? <Loader size='small' /> : <NoMatchesFound />}
        filterOption={false}
        labelInValue
        onChange={handleOnChange}
        {...{
          value,
          onSearch,
          searchValue,
          onFocus,
          onBlur,
          disabled,
          onClear,
          allowClear,
        }}
        icon={<SearchBoldIcon size={12} className={classes.searchIcon} />}
        data-testid={CHILD_CLASS_AUTOCOMPLETE_SELECT}
      >
        {!loading &&
          data.map(({ text, value, is_child }: SelectOption) =>
            renderOption({ text, value, is_child }, searchValue)
          )}
      </Select>
    </div>
  );
};

export default AutocompleteChildClassSelect;
