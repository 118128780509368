import { ToolbarItem } from 'pspdfkit';
import exportButtonIcon from 'img/icons/pspdfkit-export-button.svg';
import useClickLink from 'components/FileLink/hooks';
import { useCallback } from 'react';
import { FileType } from 'components/FileLink/types';
import { useIntl } from 'react-intl';

export const useFileDownloadToolbarItem = () => {
  const { onClick } = useClickLink();
  const intl = useIntl();

  const addFileDownloadToolbarItem = useCallback(
    (toolbarItems: ToolbarItem[], fileUrl: string, fileName: string) => {
      const downloadButton: ToolbarItem = {
        type: 'custom',
        id: 'download-file',
        icon: exportButtonIcon,
        title: intl.formatMessage({
          id: 'misc.download',
          defaultMessage: 'Download',
        }),
        onPress: () => {
          onClick({
            path: new URL(fileUrl).pathname,
            type: FileType.Document,
            label: fileName,
          });
        },
      };

      return [...toolbarItems, downloadButton];
    },
    [onClick, intl]
  );

  return {
    addFileDownloadToolbarItem,
  };
};
