import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { TabsKeys } from './types';
import ManageAssigneesPanel from '../ManageAssigneesPanel';
import ManageAssigneesGroupsPanel from '../ManageAssigneesGroupsPanel';
import {
  getUserGroupPermissionSetAssigneesCount,
  getUserGroupPermissionSetAssigneesGroupsCount,
  getUserGroupPermissionsGetSelectedPermissionSet,
} from 'store/selectors/userGroupPermissionsSelectors';
import { useSelector } from 'react-redux';
export const useAssigneesPanelTabs = () => {
  const intl = useIntl();
  const selectedPermissionSet = useSelector(
    getUserGroupPermissionsGetSelectedPermissionSet
  );
  const permissionSetId = selectedPermissionSet?.id?.toString() || '';
  const assigneesCount =
    useSelector(getUserGroupPermissionSetAssigneesCount(permissionSetId)) || 0;
  const assigneeGroupsCount =
    useSelector(
      getUserGroupPermissionSetAssigneesGroupsCount(permissionSetId)
    ) || 0;
  return useMemo(
    () => [
      {
        key: TabsKeys.Users,
        tabName: intl.formatMessage(
          {
            id: 'userGroupPermissions.manageAssigneesTabsUsers.counter',
            defaultMessage: 'Users ({count})',
          },
          { count: assigneesCount }
        ),
        TabContent: ManageAssigneesPanel,
      },
      {
        key: TabsKeys.Groups,
        tabName: intl.formatMessage(
          {
            id: 'userGroupPermissions.manageAssigneesTabsGroups.counter',
            defaultMessage: 'User groups ({count})',
          },
          { count: assigneeGroupsCount }
        ),
        TabContent: ManageAssigneesGroupsPanel,
      },
    ],
    [intl, assigneesCount, assigneeGroupsCount]
  );
};
