import { FIELD_PREFIX } from 'utils/consts';
import { ObjectClassPropertyFieldMap } from './types';

export const isFieldSortable = (
  fieldId: string,
  objectRecrdPropFields: ObjectClassPropertyFieldMap
) => {
  if (fieldId.startsWith(FIELD_PREFIX)) {
    return false;
  }
  return !!objectRecrdPropFields[fieldId]?.sort_ok;
};
