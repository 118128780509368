import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { DOCUMENT_TEMPLATE_DOWNLOAD } from 'utils/endpoints';
import { CLICKABLE_LINK } from 'utils/testIds';
import useClickLink from './hooks';
import useFilesLinkStyles from './styles';
import { FileListElementProps, FileType } from './types';
import TooltipString from 'components/TooltipString';

const FileClickLink = ({
  label,
  type = FileType.Template,
  documentTemplateID,
  downloadedFileName,
  url = '',
  testId,
}: FileListElementProps) => {
  const { id } = useParams<{ id: string }>();
  const styles = useFilesLinkStyles({});
  const { onClick } = useClickLink();

  const getFilePath = () => {
    if (type === FileType.Template) {
      return generatePath(DOCUMENT_TEMPLATE_DOWNLOAD, {
        objectClassID: id,
        documentTemplateID: documentTemplateID,
      });
    }

    try {
      return new URL(url).pathname;
    } catch {
      return undefined;
    }
  };

  const handleClick = () => {
    const path = getFilePath();

    if (!path) {
      return;
    }

    onClick({ path, label, downloadedFileName, type });
  };

  return (
    <TooltipString
      text={label ?? ''}
      testId={testId ?? CLICKABLE_LINK}
      withShortTooltipText={false}
      className={styles.anchor}
      onClick={handleClick}
    />
  );
};

export default FileClickLink;
