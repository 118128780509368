import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useTypographyStyles = createUseStyles((theme: ThemeState) => {
  return {
    typography: {
      '& .ant-typography-expand, .ant-typography-edit, .ant-typography-copy': {
        color: theme['primary-color'],
      },
    },
  };
});

export default useTypographyStyles;
