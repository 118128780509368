import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const textAreaWrapper = (hide?: boolean) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '& [class*="errorMessage-"] ': {
    marginTop: 6,
  },
  transition: hide
    ? 'height 0ms 400ms, opacity 400ms 0ms'
    : 'height 0ms 600ms, opacity 600ms 600ms',
  height: hide ? 0 : 'auto',
  opacity: hide ? 0 : 1,
});

type UseCodeNameStylesProps = {
  hide?: boolean;
};

const useCodeNameStyles = createUseStyles((theme: ThemeState) => {
  return {
    counter: {
      display: 'flex',
      justifyContent: 'flex-end',
      fontWeight: 400,
      color: theme['disabled-color'],
      fontSize: 12,
      marginTop: 4,
      marginLeft: 9,
    },
    textAreaWrapper: ({ hide }: UseCodeNameStylesProps) =>
      textAreaWrapper(hide),
    onlyCounter: {
      justifyContent: 'flex-end',
    },
    descriptionGrow: {
      flexGrow: 1,
    },
    counterShrink: {
      flexShrink: 0,
    },
    description: {
      fontSize: 12,
      marginTop: 6,
      textAlign: 'justify',
      fontWeight: 400,
      color: theme['disabled-color'],
      display: 'block',
      wordBreak: 'break-word',
    },
  };
});

export default useCodeNameStyles;
