import React from 'react';
import NoActionIllustration from 'img/illustrations/no-action.svg';
import useNoTasksFoundMessage from './styles';
import { Heading } from 'components/lib/Heading';
import { NoDataFoundMessageProps } from './types';
import { NO_DATA_SUBTITLE_TESTID, NO_DATA_TITLE_TESTID } from 'utils/testIds';

const NoDataFoundMessage: React.FC<NoDataFoundMessageProps> = ({
  title,
  subtitle,
}) => {
  const classes = useNoTasksFoundMessage();

  return (
    <div className={classes.container}>
      <img
        src={NoActionIllustration}
        className={classes.illustration}
        alt='No Data'
      />
      <div className={classes.textContainer}>
        <Heading className={classes.title} data-testid={NO_DATA_TITLE_TESTID}>
          {title}
        </Heading>
        <p data-testid={NO_DATA_SUBTITLE_TESTID}>{subtitle}</p>
      </div>
    </div>
  );
};

export default NoDataFoundMessage;
