import { WidgetProps } from 'alx-dynamic-form';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useCustomWidgetsStyles from '../customWidgetsStyles';
import usePropertyFieldStyles from './styles';

const PropertyWidget: React.FC<Partial<WidgetProps>> = ({
  value,
  label,
  required,
}) => {
  const classes = usePropertyFieldStyles({});
  const widgetClasses = useCustomWidgetsStyles({});

  return (
    <div>
      <FormLabel
        dynamicSpacing
        {...{ required }}
        className={widgetClasses.inputLabel}
      >
        {label}
      </FormLabel>
      <span className={classes.disabledText}>
        {value ?? (
          <FormattedMessage
            id='formBuilder.visibleOnlyWhenEditing'
            defaultMessage='(visible only when editing)'
          />
        )}
      </span>
    </div>
  );
};

export default PropertyWidget;
