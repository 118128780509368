import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { Layout as AntLayout } from 'antd';
import { BreadcrumbsList } from 'components/BreadcrumbsList';
import FlexLayout from 'components/lib/FlexLayout';
import PageLayout from 'components/PageLayout';
import TopMenu from 'components/PageLayout/TopMenu';
import ContentWrapper from 'components/wrappers/ContentWrapper';
import useBreadcrumbStyles from 'components/lib/Breadcrumbs/styles';
import usePageLayoutStyles from 'components/PageLayout/styles';
import { getNavigationMenuPinned } from 'store/selectors/preferencesSelectors';
import { updatePreferences } from 'store/actions/preferencesActions';
import { BREADCRUMBS_WRAPPER_ID } from 'utils/elementsIds';
import { protectedRoutes } from 'utils/routing';
import { SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_EXPANDED_WIDTH } from 'utils/consts';
import MultiPageComponents from 'components/MultiPageComponents';
import PageNotFound from 'components/PageNotFound';
import LoaderOverlay from 'components/LoaderOverlay';
import { PreferencesTypes } from 'utils/types/api/preferences.types';
import ConfirmationModal from 'components/ConfirmationModal';
import useAboutModal from 'hooks/useAboutModal';

const { Header, Content } = AntLayout;

const LoggedInRouting = () => {
  const dispatch = useDispatch();
  const modalData = useAboutModal();
  const classes = useBreadcrumbStyles({});
  const isPinned = useSelector(getNavigationMenuPinned);
  const [isCollapsed, setIsCollapsed] = useState(!isPinned);
  const currentSidebarWidth =
    isCollapsed || !isPinned ? SIDEBAR_COLLAPSED_WIDTH : SIDEBAR_EXPANDED_WIDTH;
  const pageLayoutClasses = usePageLayoutStyles({ currentSidebarWidth });

  const togglePinned = () =>
    dispatch(
      updatePreferences(PreferencesTypes.GeneralPreferences, {
        isNavigationMenuPinned: !isPinned,
      })
    );

  useEffect(() => {
    setIsCollapsed(!isPinned);
  }, [isPinned]);

  return (
    <PageLayout
      sidebarWidth={SIDEBAR_EXPANDED_WIDTH}
      {...{ isCollapsed, setIsCollapsed, isPinned, togglePinned }}
    >
      <Suspense fallback={<LoaderOverlay />}>
        <Switch>
          {protectedRoutes.map(
            ({ path, Component, excludedPaths }, key, routes) => (
              <Route
                exact
                path={path}
                key={key}
                render={props => {
                  const breadcrumbs = routes
                    .filter(({ path }) => props.match.path.includes(path))
                    .map(({ path, ...rest }) => ({
                      path: Object.keys(props.match.params).length
                        ? Object.keys(props.match.params).reduce(
                            (path, param) =>
                              path.replace(
                                `:${param}`,
                                props.match.params[param]
                              ),
                            path
                          )
                        : path,
                      ...rest,
                    }));

                  return (
                    <>
                      <Header className={pageLayoutClasses.layoutHeader}>
                        <div
                          className={classes.breadcrumbs}
                          id={BREADCRUMBS_WRAPPER_ID}
                        >
                          <BreadcrumbsList
                            routes={
                              Component
                                ? breadcrumbs.slice(1, breadcrumbs.length)
                                : []
                            }
                            {...{ excludedPaths }}
                          />
                        </div>
                        <TopMenu theme='light' />
                      </Header>
                      <Content className={pageLayoutClasses.flexLayoutWrapper}>
                        <FlexLayout>
                          <ContentWrapper>
                            {!!Component ? (
                              <Component {...props} />
                            ) : (
                              <PageNotFound />
                            )}
                            <MultiPageComponents />
                          </ContentWrapper>
                        </FlexLayout>
                        <ConfirmationModal {...modalData} />
                      </Content>
                    </>
                  );
                }}
              />
            )
          )}
        </Switch>
      </Suspense>
    </PageLayout>
  );
};

export default LoggedInRouting;
