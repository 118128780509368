import axios, { CancelTokenSource } from 'axios';
import PSPDFKit from 'pspdfkit';
import { useRef, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { extractErrorMessage } from 'utils/extractErrorMessage';
import { Logger, LogLevel } from 'utils/logger';
import { useFileViewerToolbar } from './useFileViewerToolbar';
import {
  downloadDocumentAsArrayBuffer,
  getPspdfkitLicense,
  loadPspdfFile,
  setAllowedInlineTextSelectionToolbarItems,
} from '../utils';

export const useFileViewerDisplay = (fileUrl?: string, fileName?: string) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const cancelToken = useRef<CancelTokenSource | null>(null);
  const abortController = useRef<AbortController | null>(null);
  const intl = useIntl();

  const { configureToolbarItems } = useFileViewerToolbar();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const container = containerRef.current;

    if (container === null || !fileUrl || !fileName) {
      return;
    }

    setIsLoading(true);

    // TODO: after upgrading axios to >0.23 - remove cancel token and reuse the abort controller below.
    if (cancelToken.current !== null) {
      cancelToken.current.cancel('Starting new file load. Aborting.');
    }

    cancelToken.current = axios.CancelToken.source();
    const token = cancelToken.current.token;

    if (abortController.current !== null) {
      abortController.current.abort();
    }

    abortController.current = new AbortController();
    const abortSignal = abortController.current.signal;

    (async () => {
      try {
        const documentBuffer = await downloadDocumentAsArrayBuffer(
          fileUrl,
          token
        );

        const licenseKey = await getPspdfkitLicense(token);

        PSPDFKit.unload(container);
        const loadedInstance = await loadPspdfFile(
          documentBuffer,
          container,
          intl.locale,
          licenseKey
        );

        if (abortSignal.aborted) {
          throw new Error('Aborted');
        }

        setAllowedInlineTextSelectionToolbarItems(loadedInstance, false);
        configureToolbarItems(loadedInstance, fileUrl, fileName);
        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error) || abortSignal.aborted) {
          return;
        }

        const message = extractErrorMessage(error, 'FileViewer.hooks.ts');
        Logger.log(`Error while loading document: ${message}`, LogLevel.Error);
        setIsLoading(false);
      }
    })();

    return () => {
      PSPDFKit.unload(container);
    };
  }, [fileUrl, fileName, intl, configureToolbarItems]);

  useEffect(() => {
    return () => {
      if (cancelToken.current !== null) {
        cancelToken.current.cancel();
      }

      if (abortController.current !== null) {
        abortController.current.abort();
      }
    };
  }, []);

  return { isLoading, containerRef };
};
