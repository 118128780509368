import { useLayoutEffect, useRef, useState } from 'react';

const DEFAULT_MIN_OFFSET_FROM_BOTTOM = 15;

/*
checks if element provided in "elementRef" fits in users viewPort below its parent element
it can also check if element fits in specified container element when optional getContainerRef is provided
additionally it sets customHeight if there is not enough space on bottom and top
*/
const useShouldRenderOnTop = <T extends HTMLElement>(
  getContainerRef?: () => HTMLElement
) => {
  const [shouldBeOnTop, setShouldBeOnTop] = useState(false);
  const [customHeight, setCustomHeight] = useState<number | undefined>();

  const elementRef = useRef<T>(null);

  useLayoutEffect(() => {
    const containerBotom =
      getContainerRef?.().getBoundingClientRect().bottom || window.innerHeight;

    const containerTop = getContainerRef?.().getBoundingClientRect().top || 0;

    const { y: boxYOffset, height: boxHeight, top: boxTop } =
      elementRef.current?.getBoundingClientRect() || {};

    if (
      boxYOffset === undefined ||
      boxHeight === undefined ||
      boxTop === undefined
    )
      return;

    const spaceOnBottom =
      containerBotom - (boxYOffset + DEFAULT_MIN_OFFSET_FROM_BOTTOM);
    const spaceOnTop =
      boxYOffset - DEFAULT_MIN_OFFSET_FROM_BOTTOM - containerTop;

    if (spaceOnBottom > boxHeight || spaceOnBottom > spaceOnTop) {
      setShouldBeOnTop(false);

      if (spaceOnBottom < boxHeight) {
        setCustomHeight(spaceOnBottom - DEFAULT_MIN_OFFSET_FROM_BOTTOM);
      } else {
        setCustomHeight(undefined);
      }
    } else {
      setShouldBeOnTop(true);

      if (spaceOnTop < boxHeight) {
        setCustomHeight(spaceOnTop - DEFAULT_MIN_OFFSET_FROM_BOTTOM);
      } else {
        setCustomHeight(undefined);
      }
    }
  }, [getContainerRef]);

  return { elementRef, shouldBeOnTop, customHeight };
};

export default useShouldRenderOnTop;
