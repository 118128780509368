import More from 'components/More';
import TooltipString from 'components/TooltipString';
import React from 'react';
import { visibleElementsCount } from './consts';
import useMultiselectValuesStyles from './styles';
import { MultiselectValuesProps } from './types';

const MultiselectValues = ({ values }: MultiselectValuesProps) => {
  const classes = useMultiselectValuesStyles({});

  return (
    <div className={classes.wrapper}>
      {!!values.length && (
        <span className={classes.option}>
          <TooltipString noStyle text={values[0]}>
            {values[0]}
          </TooltipString>
        </span>
      )}
      {values.length > visibleElementsCount && (
        <More
          moreElements={values}
          {...{ visibleElementsCount }}
          dropdownClassName={classes.dropdown}
        />
      )}
    </div>
  );
};

export default MultiselectValues;
