import React, { useContext } from 'react';
import NewWindow from 'react-new-window';

interface WindowContextProps {
  windowRef?: Window;
}

export const WindowContext = React.createContext<WindowContextProps>({
  windowRef: {} as Window,
});

interface WindowContextProviderProps {
  windowRef: React.RefObject<NewWindow>;
}

export const WindowContextProvider: React.FC<WindowContextProviderProps> = ({
  children,
  windowRef,
}) => {
  //@ts-ignore
  const ref = windowRef.current?.window || undefined;

  return (
    <WindowContext.Provider
      value={{
        windowRef: ref,
      }}
    >
      {children}
    </WindowContext.Provider>
  );
};

export const useWindowContext = () => useContext(WindowContext);
