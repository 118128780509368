import { AccountTypeOptions } from 'pages/Users/enums';

export const MOCKED_USERS = [
  {
    id: -1,
    first_name: 'Ashly',
    last_name: 'Hardwood',
    username: 'ashly.hardwood@email.com',
    account_type: AccountTypeOptions.Standard,
    company_name: 'Initech',
    company: '1',
    text: '-1',
    value: '-1',
    is_deleted: false,
  },
  {
    id: -2,
    first_name: 'Carlton',
    last_name: 'Shaw',
    username: 'c.shaw@email.com',
    account_type: AccountTypeOptions.Standard,
    company_name: 'Globex Corporation',
    company: '2',
    text: '-2',
    value: '-2',
    is_deleted: false,
  },
  {
    id: -3,
    first_name: 'Darla',
    last_name: 'Mane',
    username: 'd.mane@email.com',
    account_type: AccountTypeOptions.Standard,
    company_name: 'Globex Corporation',
    company: '2',
    text: '-3',
    value: '-3',
    is_deleted: false,
  },
];
