import React from 'react';
import { Menu as AntMenu } from 'antd';
import { MenuProps, MenuItemGroupProps } from 'antd/lib/menu';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { SubMenuProps as AntSubMenuProps } from 'antd/lib/menu/SubMenu';
import useMenuStyles from './styles';
import clsx from 'clsx';

const {
  Item: AntMenuItem,
  SubMenu: AntSubMenu,
  ItemGroup: AntMmenuItemGroup,
} = AntMenu;

interface SubMenuProps extends AntSubMenuProps {
  subMenuActive?: boolean;
  subMenuInlineActive?: boolean;
}

export const Menu: React.FC<MenuProps> = ({ className, ...rest }) => {
  const classes = useMenuStyles({});
  return <AntMenu {...rest} className={`${classes.menu} ${className || ''}`} />;
};

export const MenuItem: React.FC<MenuItemProps> = ({
  style,
  className,
  ...rest
}) => {
  const classes = useMenuStyles({});

  return (
    <AntMenuItem
      {...{ style, ...rest }}
      className={`${classes.menuItem} ${className || ''}`}
    />
  );
};

export const SubMenu: React.FC<SubMenuProps> = ({
  subMenuActive,
  subMenuInlineActive,
  className,
  ...rest
}) => {
  const classes = useMenuStyles({});

  return (
    <AntSubMenu
      {...rest}
      popupClassName={classes.submenuPopup}
      className={`${className ?? ''} ${classes.subMenu} ${clsx({
        [classes.subMenuActive]: subMenuActive,
        [classes.subMenuInlineActive]: subMenuInlineActive,
      })}`}
    />
  );
};

export const MenuItemGroup: React.FC<MenuItemGroupProps> = ({ ...rest }) => {
  return <AntMmenuItemGroup {...rest} />;
};
