import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import { useCallback } from 'react';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import usePanels from './usePanels';

const useCloseRecordPanels = () => {
  const { updateClosedPanelsPreferences } = usePanels();
  const { closePanelIfOpen } = useFlexLayoutContext();

  const closeRecordPanels = useCallback(
    (shouldUpdatePreferences?: boolean) => {
      closePanelIfOpen(FlexLayoutWindows.RecordAccess);
      closePanelIfOpen(FlexLayoutWindows.Tasks);
      closePanelIfOpen(FlexLayoutWindows.Summary);
      closePanelIfOpen(FlexLayoutWindows.ActivityLog);
      closePanelIfOpen(FlexLayoutWindows.Documents);

      if (shouldUpdatePreferences) {
        updateClosedPanelsPreferences([
          FlexLayoutWindows.RecordAccess,
          FlexLayoutWindows.Tasks,
          FlexLayoutWindows.Summary,
          FlexLayoutWindows.ActivityLog,
          FlexLayoutWindows.Documents,
        ]);
      }
    },
    [closePanelIfOpen, updateClosedPanelsPreferences]
  );
  return { closeRecordPanels };
};

export default useCloseRecordPanels;
