import React from 'react';
import clsx from 'clsx';
import { Select, SelectOption } from 'components/lib/Select';
import { useIntl } from 'react-intl';
import { FILTER_COLUMN_SELECT_TESTID } from 'utils/testIds';
import useColumnSelectStyles from './styles';
import { SelectColumnProps } from './types';

const SelectColumn = ({
  additionalClassName,
  defaultValue,
  onChange,
  isObjectRecords,
  predicateSet,
  getScrollableParent,
  fields,
  nameFields,
}: SelectColumnProps) => {
  const intl = useIntl();
  const classes = useColumnSelectStyles({});

  return (
    <Select
      className={clsx([classes.wrapper, additionalClassName])}
      {...{ defaultValue, onChange }}
      placeholder={intl.formatMessage({
        id: 'misc.select',
        defaultMessage: 'Select',
      })}
      data-testid={FILTER_COLUMN_SELECT_TESTID}
      disabled={isObjectRecords && !predicateSet}
      getPopupContainer={getScrollableParent}
    >
      {fields.map(({ alias, label }) => (
        <SelectOption value={alias} key={alias}>
          {label}
          {nameFields.includes(alias) && (
            <div className={classes.divider}></div>
          )}
        </SelectOption>
      ))}
    </Select>
  );
};

export default SelectColumn;
