import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import NotificationType from 'utils/Enums/NotificationType';
import { LimitMessageProps } from './types';

const useLimitMessage = ({
  itemsLimit,
  count,
  customErrorMessage,
  customWarningMessage,
}: LimitMessageProps) => {
  const intl = useIntl();

  const notificationType: NotificationType | undefined = useMemo(() => {
    if (itemsLimit === undefined) return;

    if (count >= itemsLimit) return NotificationType.Error;
    else if (count >= itemsLimit * 0.9) return NotificationType.Warning;
    else if (count >= itemsLimit * 0.8) return NotificationType.Info;
  }, [itemsLimit, count]);

  const message = useMemo(() => {
    if (notificationType === undefined || itemsLimit === undefined) return;

    if (notificationType === NotificationType.Error)
      return customErrorMessage
        ? customErrorMessage
        : intl.formatMessage(
            {
              id: 'owners.ownersLimitReached',
              defaultMessage:
                'Maximum of {itemsLimit} {itemsLimit, plural, one {owner} other {owners}} has been reached.',
            },
            { itemsLimit }
          );
    else if (
      notificationType === NotificationType.Warning ||
      notificationType === NotificationType.Info
    ) {
      return customWarningMessage
        ? customWarningMessage
        : intl.formatMessage(
            {
              id: 'owners.limitMessage',
              defaultMessage:
                'An object class can have up to {itemsLimit} {itemsLimit, plural, one {owner} other {owners}}. This currently has {count}.',
            },
            { itemsLimit, count }
          );
    }
  }, [
    notificationType,
    itemsLimit,
    intl,
    customErrorMessage,
    customWarningMessage,
    count,
  ]);

  return {
    message,
    notificationType,
  };
};

export default useLimitMessage;
