import { createUseStyles } from 'react-jss';

const useLoaderOverlayStyles = createUseStyles({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default useLoaderOverlayStyles;
