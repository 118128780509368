import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { userPermissionViewSelector } from 'store/selectors/permissionsSelectors';
import { useToggle } from 'hooks/useToggle';
import useData from 'hooks/useData';
import { User } from 'pages/Users/types';
import { UserGroup } from 'utils/types/api/userGroups.types';
import { generatePath } from 'react-router-dom';
import { USER, USER_GROUP } from 'utils/endpoints';
import showDefaultErrorToast from 'utils/functions/showDefaultErrorToast';

interface UseAvatarProps {
  firstName: string | undefined;
  lastName: string | undefined;
  groupName: string | undefined;
  disablePopover: boolean;
  id: number | string | undefined;
  closeOnElementScroll?: string;
  isDeleted?: boolean;
  email?: string;
  company?: string;
  hasFullUserDetails?: boolean;
}

const useAvatar = ({
  firstName,
  lastName,
  groupName,
  disablePopover,
  id,
  closeOnElementScroll,
  isDeleted,
  email,
  company,
  hasFullUserDetails,
}: UseAvatarProps) => {
  const hasUserViewPermission = useSelector(userPermissionViewSelector);
  const [noItemViewPerm, setNoItemViewPerm] = useState(false);
  const [details, setDetails] = useState<User | UserGroup | undefined>();
  const [
    isVisiblePopover,
    { toggleOff: closePopover, toggleOn: openPopover },
  ] = useToggle(false);

  const [result, { loading, error, fetchData }] = useData<User | UserGroup>(
    '',
    {
      fetchOnLoad: false,
    }
  );

  useEffect(() => {
    setDetails(result);
  }, [result]);

  useEffect(() => {
    if (!details) return;

    openPopover();
  }, [openPopover, details]);

  useEffect(() => {
    if (!error) return;
    if (error.status === 403 && !!groupName) {
      setDetails({
        id: id?.toString() ?? '',
        name: groupName,
        description: '',
        num_of_members: 0,
      } as UserGroup);
      setNoItemViewPerm(true);
      return;
    }

    showDefaultErrorToast();
    // eslint-disable-next-line
  }, [error]);

  const initials = useMemo(() => {
    if (isDeleted) return 'D';

    if (groupName) {
      const userGroupWords = groupName.split(' ');
      const groupInitials =
        userGroupWords.length > 1
          ? `${userGroupWords[0].charAt(0)}${userGroupWords[1].charAt(0)}`
          : `${userGroupWords[0].charAt(0)}${userGroupWords[0].charAt(1)}`;

      return groupInitials.toUpperCase();
    }

    return `${firstName?.charAt(0)}${lastName?.charAt(0)}`.toUpperCase();
  }, [isDeleted, groupName, firstName, lastName]);

  const handleAvatarClick = (e: MouseEvent<HTMLElement>) => {
    if (
      disablePopover ||
      (id !== undefined && id < 0) || // negative ids are used in mock users and we don't want to downlaod them
      (!groupName && !hasFullUserDetails && !hasUserViewPermission)
    )
      return;

    e.stopPropagation();

    if ((!details || details.id !== id) && id !== undefined) {
      if (hasFullUserDetails && !groupName && !hasUserViewPermission) {
        setDetails(({
          id,
          email: email as string,
          first_name: firstName,
          last_name: lastName,
          company_name: company,
          username: email,
        } as unknown) as User);
      } else {
        fetchData(generatePath(!!groupName ? USER_GROUP : USER, { id }));
      }
    } else if (!isVisiblePopover) openPopover();
  };

  useEffect(() => {
    if (!(isVisiblePopover && closeOnElementScroll)) return;

    const element = window.document.getElementById(closeOnElementScroll);

    if (element) element.addEventListener('scroll', closePopover);

    return () => {
      if (element) element.removeEventListener('scroll', closePopover);
    };
  }, [closePopover, isVisiblePopover, closeOnElementScroll]);

  return {
    initials,
    closePopover,
    handleAvatarClick,
    isVisiblePopover,
    loading,
    details,
    noItemViewPerm,
  };
};

export default useAvatar;
