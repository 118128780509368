import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import useExternalComponentWidgetStyles from './styles';
import { ExternalComponentWidgetProps } from './types';
import { getAccessToken, getUserDetails } from 'store/selectors/authSelectors';
import { ExternalComponentMessage } from 'store/reducers/types/externalComponents.types';

const ExternalComponentWidget = ({
  index_url,
  name,
  height = 0,
  dynamicParams,
  classId,
  recordId,
}: ExternalComponentWidgetProps) => {
  const ref = useRef<HTMLIFrameElement>(null);
  const userDetails = useSelector(getUserDetails);
  const token = useSelector(getAccessToken);
  const classes = useExternalComponentWidgetStyles();

  const prepareMessage = useCallback(() => {
    if (!userDetails || !token) return;
    const message: ExternalComponentMessage = {
      user_details: {
        user_id: userDetails.id,
        user_name: userDetails.username,
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        access_token: token,
      },
      object_record_meta: {
        class_id: classId,
        record_id: recordId,
      },
      endpoint: process.env.REACT_APP_PROXY || window.location.origin,
      parameters: dynamicParams.reduce(
        (acc, param) => ({
          ...acc,
          [param.parameter_name]: param.parameter_value,
        }),
        {}
      ),
    };
    if (ref.current?.contentWindow) {
      ref.current.contentWindow.postMessage(
        message,
        index_url?.replace('index.html', '') ?? ''
      );
    }
  }, [userDetails, token, classId, recordId, dynamicParams, index_url]);

  useEffect(prepareMessage);

  return (
    <div>
      <iframe
        title={name}
        ref={ref}
        src={index_url}
        frameBorder='0'
        className={classes.ExternalComponent}
        onLoad={prepareMessage}
        height={height > 0 ? height : 250}
      />
    </div>
  );
};

export default React.memo(
  ExternalComponentWidget,
  (prev, next) =>
    prev.index_url === next.index_url &&
    prev.recordId === next.recordId &&
    prev.name === next.name
);
