import { useCallback, useState } from 'react';
import debounce from 'lodash/debounce';
import { useToggle } from 'hooks/useToggle';
import useData from 'hooks/useData';
import {
  AutocompleteDataResult,
  OnChange,
  UseAutocompleteChildRecords,
} from './types';

const useAutocompleteChildRecordsSelect = ({
  autocompleteUrl,
  onChange,
  handleOnBlur,
}: UseAutocompleteChildRecords) => {
  const [
    isOpenSelect,
    { toggleOn: openSelect, toggleOff: closeSelect },
  ] = useToggle(false);
  const [searchValue, setSearchValue] = useState('');

  const [{ results = [] } = {}, { loading, fetchData }] = useData<
    AutocompleteDataResult
  >('', {
    fetchOnLoad: false,
  });

  const debouncedFetchData = useCallback(debounce(fetchData, 300), []);

  const handleChange: OnChange = useCallback(
    item => {
      onChange(item);
      closeSelect();
    },
    [onChange, closeSelect]
  );

  const onFocus = useCallback(() => {
    openSelect();
    fetchData(autocompleteUrl);
  }, [autocompleteUrl, fetchData, openSelect]);

  const onSearch = useCallback(
    (val: string) => {
      setSearchValue(val);
      debouncedFetchData(`${autocompleteUrl}${encodeURIComponent(val)}`);
    },
    [autocompleteUrl, debouncedFetchData]
  );

  const onBlur = useCallback(() => {
    closeSelect();

    if (handleOnBlur) handleOnBlur();
  }, [closeSelect, handleOnBlur]);

  return {
    data: results,
    isOpenSelect,
    loading,
    handleChange,
    onBlur,
    onFocus,
    onSearch,
    searchValue,
  };
};

export default useAutocompleteChildRecordsSelect;
