import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  APPEND_AUTHENTICATION_OBJECTS,
  RESET_AUTHENTICATION_OBJECTS,
  RESET_AUTHENTICATION_OBJECTS_COLUMNS,
  SET_AUTHENTICATION_OBJECTS,
  SET_AUTHENTICATION_OBJECTS_COLUMNS,
  SET_AUTHENTICATION_OBJECTS_FETCHING,
  SET_AUTHENTICATION_OBJECTS_RESTRICTIONS,
  SET_AUTHENTICATION_OBJECTS_SELECTED_ROW,
} from 'store/constants/authenticationObjects.consts';
import {
  appendTableContent,
  resetTable,
  resetTableColumns,
  setSelectedRow,
  setTableColumns,
  setTableContent,
  setTableLoading,
} from './tablesCommonStateModificators';
import { AuthenticationObjects } from 'utils/types/api/authenticationObjects.types';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import { CommonTableState } from 'utils/types/api/table.types';
import cloneDeep from 'lodash/cloneDeep';

const initialState = cloneDeep(INITIAL_TABLE_STATE);
const reducerName: Reducer<
  CommonTableState<AuthenticationObjects>,
  RootAction
> = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTHENTICATION_OBJECTS: {
      return setTableContent<
        AuthenticationObjects,
        typeof SET_AUTHENTICATION_OBJECTS
      >(state, action);
    }

    case APPEND_AUTHENTICATION_OBJECTS:
      return appendTableContent<
        AuthenticationObjects,
        typeof APPEND_AUTHENTICATION_OBJECTS
      >(state, action);

    case SET_AUTHENTICATION_OBJECTS_FETCHING:
      return setTableLoading<
        AuthenticationObjects,
        typeof SET_AUTHENTICATION_OBJECTS_FETCHING
      >(state, action);

    case RESET_AUTHENTICATION_OBJECTS:
      return resetTable<AuthenticationObjects>(state, initialState);

    case RESET_AUTHENTICATION_OBJECTS_COLUMNS:
      return resetTableColumns<AuthenticationObjects>(state, initialState);

    case SET_AUTHENTICATION_OBJECTS_RESTRICTIONS:
      return { ...state, restrictions: action.restrictions };

    case SET_AUTHENTICATION_OBJECTS_COLUMNS:
      return setTableColumns<
        AuthenticationObjects,
        typeof SET_AUTHENTICATION_OBJECTS_COLUMNS
      >(state, action);

    case SET_AUTHENTICATION_OBJECTS_SELECTED_ROW:
      return setSelectedRow<
        AuthenticationObjects,
        typeof SET_AUTHENTICATION_OBJECTS_SELECTED_ROW
      >(state, action);

    default:
      return state;
  }
};

export default reducerName;
