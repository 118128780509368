import {
  SetTableAction,
  ActionObject,
  ColumnsMetadata,
  ClassID,
} from 'utils/types/api/table.types';
import { NestedCommonTableState } from './types/nestedObjectRecords.types';

type PickedActionObject<T> = Pick<
  ActionObject<T>,
  'list' | 'total' | 'filtered' | 'classID'
>;

export const setTableContent = <T extends { id: string }, U, K = {}>(
  state: NestedCommonTableState<T> & K,
  action: SetTableAction<PickedActionObject<T>, U>
) => {
  const { total, filtered, list, classID } = action;

  return {
    ...state,
    data: {
      ...state.data,
      [classID]: {
        ...state.data[classID],
        fetching: false,
        total,
        filtered,
        byId: list.reduce((byId: { [id: string]: T }, item: T) => {
          byId[item.id] = item;

          return byId;
        }, {}),
        allIds: list.map((item: T) => item.id),
      },
    },
  };
};

export const appendTableContent = <T extends { id: string }, U, K = {}>(
  state: NestedCommonTableState<T> & K,
  action: SetTableAction<PickedActionObject<T>, U>,
  onTopOfTable?: boolean
) => {
  const { list, classID } = action;
  const result = setTableContent(state, action);

  return {
    ...state,
    data: {
      ...state.data,
      [classID]: {
        ...result.data[classID],
        allIds: onTopOfTable
          ? [...list.map(item => item.id), ...state.data[classID].allIds]
          : [...state.data[classID].allIds, ...list.map(item => item.id)],
      },
    },
  };
};

export const setTableLoading = <T, U, K = {}>(
  state: NestedCommonTableState<T> & K,
  action: SetTableAction<Pick<ActionObject<T>, 'fetching' | 'classID'>, U>
) => ({
  ...state,
  data: {
    ...state.data,
    [action.classID]: {
      ...state.data[action.classID],
      fetching: action.fetching,
    },
  },
});

export const resetTable = <T, K = {}>(
  state: NestedCommonTableState<T> & K,
  initialState: NestedCommonTableState<T> & K,
  classID: ClassID
) => ({
  ...state,
  data: {
    ...state.data,
    [classID]: {
      ...initialState.data['NO_ID'],
    },
  },
});

export const resetTableColumns = <T, K = {}>(
  state: NestedCommonTableState<T> & K,
  initialState: NestedCommonTableState<T> & K,
  classID: ClassID
) => ({
  ...state,
  data: {
    ...state.data,
    [classID]: {
      ...state.data[classID],
      columns: initialState.data['NO_ID'].columns,
    },
  },
});

export const setTableColumns = <T, U, K = {}>(
  state: NestedCommonTableState<T> & K,
  action: SetTableAction<Pick<ActionObject<T>, 'payload' | 'classID'>, U>
): NestedCommonTableState<T> & K => {
  const { payload = [], classID } = action;

  return {
    ...state,
    data: {
      ...state.data,
      [classID]: {
        ...state.data[classID],
        columns: payload.reduce(
          (byName: MappedObject<ColumnsMetadata>, item) => {
            byName[item.alias] = item;

            return byName;
          },
          {}
        ),
      },
    },
  };
};

export const setSelectedRow = <T, U, K = {}>(
  state: NestedCommonTableState<T> & K,
  action: SetTableAction<Pick<ActionObject<T>, 'selectedRow'>, U>
): NestedCommonTableState<T> & K => {
  const { selectedRow } = action;

  return {
    ...state,
    selectedRow,
  };
};

export const removeTableContent = <T extends { id: string }, U, K = {}>(
  state: NestedCommonTableState<T> & K,
  action: SetTableAction<PickedActionObject<T>, U>
) => {
  const { list, total, filtered, classID } = action;

  const idsToRemove = list.map(item => item.id);

  return {
    ...state,
    data: {
      ...state.data,
      [classID]: {
        ...state.data[classID],
        fetching: false,
        total,
        filtered,
        byId: Object.fromEntries(
          Object.entries(state.data[classID].byId).filter(
            ([key]) => !idsToRemove.includes(key)
          )
        ),
        allIds: [
          ...state.data[classID].allIds.filter(
            item => !idsToRemove.includes(item)
          ),
        ],
      },
    },
  };
};
