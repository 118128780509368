export const DEFAULT_COLUMNS_WIDTHS = {
  minWidth: 75,
  width: 300,
};

export const ACTIONS_COLUMN_WIDTH = 100;
export const BULK_SELECTION_COLUMN_WIDTH = 44;
export const EMPTY_TABLE_HEIGHT = 400;
export const ACTIONS_COLUMN_WITH_MARGIN_WIDTH = ACTIONS_COLUMN_WIDTH + 10;

export const DEFAULT_SORT = '-id';
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_SIZE_INFINITE_SCROLL = 50;
export const DEFAULT_TABLE_QUERY_STRING = `limit=${DEFAULT_PAGE_SIZE}&offset=0&ordering=-id`;

export const COMFORTABLE_DENSITY_HEADER = 43;
export const COMFORTABLE_DENSITY_ROW = 40;
export const DEFAULT_DENSITY_HEADER = 38;
export const DEFAULT_DENSITY_ROW = 35;
export const COMPACT_DENSITY_HEADER = 33;
export const COMPACT_DENSITY_ROW = 30;

export const DEFAULT_OBJECT_MODEL_ID = 1;
