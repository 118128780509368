import SupportedLanguages from 'utils/Enums/SupportedLanguages';
import enGBLang from 'lang/en-GB.json';
import enUSLang from 'lang/en-US.json';
import plLang from 'lang/pl.json';
import { flattenObject } from 'utils/functions/flattenObject';

const languages = {
  [SupportedLanguages.enGB]: enGBLang,
  [SupportedLanguages.enUS]: enUSLang,
  [SupportedLanguages.pl]: plLang,
};

const AppLocale: { [key: string]: { [key: string]: string } } = {
  ...Object.entries(SupportedLanguages).reduce((all, [, value]) => {
    return {
      ...all,
      [value]: flattenObject(languages[value]),
    };
  }, {}),
};

export default AppLocale;
