import { Protocol } from './enums';

export const ProtocolToLabelMap = {
  [Protocol.HTTPS]: Protocol.HTTPS,
  [Protocol.HTTP]: Protocol.HTTP,
};

export const PROTOCOL_SELECT_OPTIONS = [
  {
    label: ProtocolToLabelMap[Protocol.HTTPS],
    value: Protocol.HTTPS,
  },
  {
    label: ProtocolToLabelMap[Protocol.HTTP],
    value: Protocol.HTTP,
  },
];
