//@ts-nocheck
import React from 'react';
import { Input } from 'reactstrap';
import FBCheckbox from '../../FBCheckbox';
import shortAnswerInputs from '../shortAnswerInputs';
import longAnswerInputs from '../longAnswerInputs';
import numberInputs from '../numberInputs';
import defaultInputs from '../defaultInputs';
import { FormInput } from '../../types';
import { CardArrayParameterInputsProps, InnerCardProps } from './types';
import { FormattedMessage } from 'react-intl';

// specify the inputs required for a string type object
const CardArrayParameterInputs: React.FC<CardArrayParameterInputsProps> = ({
  parameters,
  onChange,
}) => {
  return (
    <div>
      <h4>
        <FormattedMessage
          id='formBuilder.minimumItems'
          defaultMessage='Minimum items'
        />
      </h4>
      <Input
        value={(parameters.minItems as string) || ''}
        placeholder='ex: 2'
        key='minimum'
        type='number'
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          onChange({
            ...parameters,
            minItems: parseInt(ev.target.value, 10),
          });
        }}
        className='card-modal-number'
      />
      <h4>
        <FormattedMessage
          id='formBuilder.maximumItems'
          defaultMessage='Maximum items'
        />
      </h4>
      <Input
        value={(parameters.maxItems as string) || ''}
        placeholder='ex: 2'
        key='maximum'
        type='number'
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          onChange({
            ...parameters,
            maxItems: parseInt(ev.target.value, 10),
          });
        }}
        className='card-modal-number'
      />
    </div>
  );
};

function getInnerCardComponent() {
  return function InnerCard({ parameters, onChange, mods }: InnerCardProps) {
    const newDataProps: MappedObject<any> = {};
    const newUiProps: MappedObject<any> = {};
    // parse components into data and ui relevant pieces
    Object.keys(parameters).forEach(propName => {
      if (propName.startsWith('ui:*')) {
        newUiProps[propName.substring(4)] = parameters[propName];
      } else if (propName.startsWith('ui:')) {
        newUiProps[propName] = parameters[propName];
      } else if (!['name', 'required'].includes(propName)) {
        newDataProps[propName] = parameters[propName];
      }
    });

    if (parameters.type !== 'array') {
      return (
        <h4>
          <FormattedMessage
            id='formBuilder.notAnArray'
            defaultMessage='Not an array'
          />
        </h4>
      );
    }
    return (
      <div className='card-array'>
        <FBCheckbox
          onChangeValue={() => {
            if (newDataProps.items.type === 'object') {
              onChange({
                ...parameters,
                items: {
                  ...newDataProps.items,
                  type: 'string',
                },
              });
            } else {
              onChange({
                ...parameters,
                items: {
                  ...newDataProps.items,
                  type: 'object',
                },
              });
            }
          }}
          isChecked={newDataProps.items.type === 'object'}
          label='Section'
        />
      </div>
    );
  };
}

const defaultFormInputs = {
  ...defaultInputs,
  ...shortAnswerInputs,
  ...longAnswerInputs,
  ...numberInputs,
} as {
  [key: string]: FormInput;
};
defaultFormInputs.array = {
  displayName: 'Array',
  matchIf: [
    {
      types: ['array'],
    },
  ],
  defaultDataSchema: {
    items: { type: 'string' },
  },
  defaultUiSchema: {},
  type: 'array',
  cardBody: getInnerCardComponent({ defaultFormInputs }),
  modalBody: CardArrayParameterInputs,
};

const ArrayInputs = {
  array: {
    displayName: 'Array',
    matchIf: [
      {
        types: ['array'],
      },
    ],
    defaultDataSchema: {
      items: { type: 'string' },
    },
    defaultUiSchema: {},
    type: 'array',
    cardBody: getInnerCardComponent({ defaultFormInputs }),
    modalBody: CardArrayParameterInputs,
  },
};

export default ArrayInputs;
