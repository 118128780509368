import React from 'react';
import { List as AntList } from 'antd';
import { ListProps, ListItemProps, ListItemMetaProps } from 'antd/lib/list';

const { Item: AntListItem } = AntList;
const { Meta: AntListItemMeta } = AntListItem;

export const List = <T extends object>({ ...rest }: ListProps<T>) => {
  return <AntList {...rest} />;
};

export const ListItem = ({ ...rest }: ListItemProps) => (
  <AntListItem {...rest} />
);

export const ListItemMeta = ({ ...rest }: ListItemMetaProps) => (
  <AntListItemMeta {...rest} />
);
