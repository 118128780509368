import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useParams } from 'react-router-dom';
import {
  getObjectClassSelectedPermissionSet,
  getObjectClassPermissionSetAssigneesData,
} from 'store/selectors/objectClassPermissionsSelectors';
import { OBJECT_CLASS_PERMISSION_SET_ASSIGNEES } from 'utils/endpoints';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import AddUsersPanel from '../AddUsersPanel';
import { ObjectClassOwner } from 'utils/types/api/objectClasses.types';
import {
  AddUsersPanelType,
  CustomErrorMessage,
  OwnersMode,
} from '../AddUsersPanel/types';
import { getObjectClassPermissionSetAssignees } from 'store/actions/objectClassPermissionsActions';
import { useIntl } from 'react-intl';
import { AppDispatch } from 'store/store';
import { ErrorCodes } from 'utils/types/errorResponse';
import { useObjectClassContext } from '../../../context/ObjectClassFormContext';

const AddPermissionSetAssignee = () => {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const { id: objectClassId = '0' } = useParams<{ id: string }>();
  const { activePermissionSetTab } = useObjectClassContext();

  const selectedPermissionSet = useSelector(
    getObjectClassSelectedPermissionSet
  );

  const permissionSetId = selectedPermissionSet?.id?.toString() || '';

  const { avatars, totalCount: usersCount, options } = useSelector(
    getObjectClassPermissionSetAssigneesData(permissionSetId)
  );

  const itemsLimit = options?.restrictions?.limit_items || 0;
  const autocompleteUrl = options?.batch?.autocomplete || '';

  const postApiEndpoint = generatePath(OBJECT_CLASS_PERMISSION_SET_ASSIGNEES, {
    objectClassId,
    permissionSetId,
  });

  const panelTitle = intl.formatMessage(
    {
      id: 'misc.addAssigneesTo',
      defaultMessage: 'Add assignees to {name}',
    },
    { name: selectedPermissionSet?.name }
  );

  const customErrorMessage = useCallback(
    ({ count, itemsLimit, errorCode }: CustomErrorMessage) =>
      errorCode === ErrorCodes.ERR_LIMIT_EXCEEDED && itemsLimit
        ? {
            title: intl.formatMessage(
              {
                id: 'owners.countNotAddedAssignees',
                defaultMessage: `{count, plural, one {# assignee} other {# assignees}} not added`,
              },
              { count }
            ),
            subtitle: intl.formatMessage(
              {
                id: 'owners.assigneesLimitReached',
                defaultMessage:
                  'Maximum of {itemsLimit} {itemsLimit, plural, one {assignee} other {assignees}} has been reached.',
              },
              { itemsLimit }
            ),
          }
        : undefined,
    [intl]
  );

  return (
    <AddUsersPanel<ObjectClassOwner>
      {...{
        avatars,
        usersCount,
        itemsLimit,
        autocompleteUrl,
        postApiEndpoint,
        panelTitle,
        customErrorMessage,
      }}
      panelKey={FlexLayoutWindows.ClassPermissionsOwners}
      panelType={AddUsersPanelType.PermissionSetAssignees}
      fetchOwners={() =>
        dispatch(
          getObjectClassPermissionSetAssignees(
            objectClassId,
            permissionSetId,
            activePermissionSetTab
          )
        )
      }
      intlLimitMessage={intl.formatMessage(
        {
          id: 'owners.selectedAssigneesLimitReached',
          defaultMessage:
            'Maximum of {limit} new {limit, plural, one {assignee} other {assignees}} can be selected ({usersCount} {usersCount, plural, one {assignee} other {assignees}} already added).',
        },
        { limit: itemsLimit - usersCount, usersCount }
      )}
      shouldFetchOnSuccess
      defaultMode={OwnersMode.Manage}
      alreadyAddedDisabledMessage={intl.formatMessage({
        id: 'misc.alreadyAnAssignee',
        defaultMessage: 'Already an assignee',
      })}
    />
  );
};

export default AddPermissionSetAssignee;
