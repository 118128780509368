import {
  COMFORTABLE_DENSITY_HEADER,
  COMFORTABLE_DENSITY_ROW,
  COMPACT_DENSITY_HEADER,
  COMPACT_DENSITY_ROW,
  DEFAULT_DENSITY_HEADER,
  DEFAULT_DENSITY_ROW,
} from 'components/Table/consts';
import { TableDensity } from 'components/Table/enums';
import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useRowDensityStyles = createUseStyles({
  radioWrapper: {
    padding: [24, 13],
    '& > .ant-radio-group': {
      display: 'flex',
      gap: '36px',
      '& > .ant-radio-wrapper': {
        display: 'flex',
        alignItems: 'center',
        marginRight: 0,
      },
    },
  },
});

const getHeadHeight = (density?: TableDensity) => {
  switch (density) {
    case TableDensity.Compact:
      return COMPACT_DENSITY_HEADER;
    case TableDensity.Comfortable:
      return COMFORTABLE_DENSITY_HEADER;
    default:
      return DEFAULT_DENSITY_HEADER;
  }
};

export const getBodyRowHeight = (density?: TableDensity) => {
  switch (density) {
    case TableDensity.Compact:
      return COMPACT_DENSITY_ROW;
    case TableDensity.Comfortable:
      return COMFORTABLE_DENSITY_ROW;
    default:
      return DEFAULT_DENSITY_ROW;
  }
};

type UseTableStylesProps = {
  density?: TableDensity;
};

export const usePreviewTableStyles = createUseStyles((theme: ThemeState) => {
  return {
    title: {
      fontSize: 14,
      lineHeight: '19px',
      fontWeight: 700,
    },
    tableWrapper: {
      marginTop: 23,
    },
    table: {
      width: '100%',
      marginTop: 15,
      '& td, th': {
        padding: [0, 25],
      },
    },
    tHead: {
      height: ({ density }: UseTableStylesProps = {}) =>
        `${getHeadHeight(density)}px`,
      lineHeight: ({ density }: UseTableStylesProps = {}) =>
        `${getHeadHeight(density)}px`,
      '& th': {
        backgroundColor: theme['dropdown-active-gray'],
        fontWeight: 'bold',
        color: theme['text-color'],
        fontSize: 13,
      },
    },
    tBody: {
      '& > tr': {
        height: ({ density }: UseTableStylesProps = {}) =>
          `${getBodyRowHeight(density)}px`,
        lineHeight: ({ density }: UseTableStylesProps = {}) =>
          `${getBodyRowHeight(density)}px`,
      },
      '& > tr:nth-child(2n - 1)': {
        backgroundColor: theme['form-builder-background'],
      },
    },
  };
});
