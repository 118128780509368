import React from 'react';
import CustomPrompt from 'components/CustomPrompt';
import { useSaveChangesModal } from 'components/FormHeader/hooks';
import { useBeforeunload } from 'react-beforeunload';
import { preventDefault } from 'utils/functions/preventDefault';
import { PreventRedirectsProps } from './types';
import { CustomPromptType } from 'components/CustomPrompt/types';
import CancelConfigurationModal from 'components/CancelConfigurationModal';
import { Location } from 'history';
import noop from 'lodash/noop';

/*
This is component to unify and handle all cases of preventing redirects or
actions that don't change the url

when -> when to show modal and prevent actions by changing url, refreshing page or closing browser
showModal -> shows the same modal as soon as is set to true, even if we don't try to change url
*/

const PreventRedirects = ({
  when,
  onDontSave,
  showModal,
  onCancel,
}: PreventRedirectsProps) => {
  const { navigateTo, handleLocationChange } = useSaveChangesModal();

  useBeforeunload(event => when && preventDefault(event));

  return (
    <>
      <CancelConfigurationModal
        visible={!!showModal}
        onConfirm={onDontSave || noop}
        onCancel={onCancel || noop}
      />
      <CustomPrompt
        when={!navigateTo && when}
        onCancel={(navigateTo?: Location) => {
          if (navigateTo) handleLocationChange(navigateTo.pathname);
        }}
        onClose={onDontSave}
        type={CustomPromptType.CANCEL}
      />
    </>
  );
};

export default PreventRedirects;
