import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  SET_MESSAGE_TEMPLATES,
  SET_MESSAGE_TEMPLATES_FETCHING,
  APPEND_MESSAGE_TEMPLATES,
  RESET_MESSAGE_TEMPLATES,
  SET_MESSAGE_TEMPLATES_COLUMNS,
  RESET_MESSAGE_TEMPLATES_COLUMNS,
  SET_MESSAGE_TEMPLATES_SELECTED_ROW,
} from 'store/constants/messageTemplates.consts';
import {
  appendTableContent,
  resetTable,
  setTableContent,
  setTableLoading,
  resetTableColumns,
  setTableColumns,
  setSelectedRow,
} from './tablesCommonStateModificators';
import { MessageTemplate } from 'utils/types/api/messageTemplates.types';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import { CommonTableState } from 'utils/types/api/table.types';

import cloneDeep from 'lodash/cloneDeep';
const initialState = cloneDeep(INITIAL_TABLE_STATE);

const messageTemplatesReducer: Reducer<
  CommonTableState<MessageTemplate>,
  RootAction
> = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGE_TEMPLATES: {
      return setTableContent<MessageTemplate, typeof SET_MESSAGE_TEMPLATES>(
        state,
        action
      );
    }
    case APPEND_MESSAGE_TEMPLATES:
      return appendTableContent<
        MessageTemplate,
        typeof APPEND_MESSAGE_TEMPLATES
      >(state, action);

    case SET_MESSAGE_TEMPLATES_FETCHING:
      return setTableLoading<
        MessageTemplate,
        typeof SET_MESSAGE_TEMPLATES_FETCHING
      >(state, action);

    case RESET_MESSAGE_TEMPLATES:
      return resetTable<MessageTemplate>(state, initialState);

    case RESET_MESSAGE_TEMPLATES_COLUMNS:
      return resetTableColumns<MessageTemplate>(state, initialState);

    case SET_MESSAGE_TEMPLATES_COLUMNS:
      return setTableColumns<
        MessageTemplate,
        typeof SET_MESSAGE_TEMPLATES_COLUMNS
      >(state, action);
    case SET_MESSAGE_TEMPLATES_SELECTED_ROW:
      return setSelectedRow<
        MessageTemplate,
        typeof SET_MESSAGE_TEMPLATES_SELECTED_ROW
      >(state, action);
    default:
      return state;
  }
};

export default messageTemplatesReducer;
