import { SelectProps } from 'antd/lib/select';
import { SelectOption } from 'utils/types/selectInput.types';
import {
  TPredicateValue,
  TPredicateTypes,
  TPredicateInputTypes,
} from 'utils/types/predicates.types';
import { PredicateSet } from '../ColumnSelect/types';

export interface PredicateValue {
  predicateKey: string;
  predicateValues: MappedObject<TPredicateValue>;
}

export interface PredicatesProps {
  type: TPredicateTypes;
  filterKey: string;
  value: PredicateValue;
  selectOptions?: SelectOption[];
  autocompleteUrl?: string;
  predicateSet?: PredicateSet;
}

export enum PredicatesTestIdType {
  Default = '',
  From = '-from',
  To = '-to',
}

export interface PredicateInputProps {
  type: TPredicateInputTypes;
  value: TPredicateValue;
  selectOptions?: SelectOption[];
  selectMultiple?: boolean;
  onChange: (value: TPredicateValue) => void;
  autocompleteUrl?: string;
  testIdType?: PredicatesTestIdType;
  filterKey: string;
  predicateSet?: PredicateSet;
}

export interface SelectedPredicate extends Pick<Predicate, 'args'> {
  key: string;
  selectMultiple?: boolean;
}

export interface Predicate {
  label: string;
  value: string;
  query: string;
  args: string[];
  selectMultiple?: boolean;
}

export type FilterOption = SelectProps<unknown>['filterOption'];
