import { createUseStyles } from 'react-jss';

const useUserCellStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  text: {
    marginLeft: 8,
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  counter: {
    marginLeft: 1,
  },
  singleUserCellWrapper: {
    display: 'flex',
    alignItems: 'center',

    '& .ant-spin-container': {
      display: 'flex',
      alignItems: 'center',
    },
  },
});

export default useUserCellStyles;
