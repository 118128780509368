import { useCallback, useMemo } from 'react';
import { ActionButtonType } from './types';
import { useTableContext } from 'contexts/TableContext';
import TablesType from 'utils/Enums/TablesType';
import { useSelector } from 'react-redux';
import { getCurrentTableRestrictions } from 'store/selectors/generalSelectors';
import Membership from 'utils/Enums/Membership';

const useBulkSelectionActionButton = () => {
  const {
    onBulkSelectionAddAction,
    onBulkSelectionRemoveAction,
    bulkSelectionList,
    fetchData,
    setBulkSelectionList,
    searchValue,
    currentTableName,
    preferencesId,
  } = useTableContext();

  const { limit_items_in_batch: limitItems } = useSelector(
    getCurrentTableRestrictions(preferencesId?.toString())
  );

  const actionProps = useMemo(
    () => ({
      ids: bulkSelectionList.slice(0, limitItems),
      fetchData,
      setBulkSelectionList,
    }),
    [bulkSelectionList, fetchData, limitItems, setBulkSelectionList]
  );

  const onClickRemove = useCallback(
    () =>
      onBulkSelectionRemoveAction && onBulkSelectionRemoveAction(actionProps),
    [actionProps, onBulkSelectionRemoveAction]
  );

  const onClickAdd = useCallback(
    () => onBulkSelectionAddAction && onBulkSelectionAddAction(actionProps),
    [actionProps, onBulkSelectionAddAction]
  );

  const typeOfAction = useMemo<ActionButtonType | undefined>(() => {
    if (currentTableName === TablesType.Members) {
      const { searchKey, value } = searchValue || {};
      return searchKey === 'membership' && value === Membership.Member
        ? ActionButtonType.Removing
        : ActionButtonType.Adding;
    }
  }, [currentTableName, searchValue]);

  return {
    onClickRemove,
    onClickAdd,
    typeOfAction,
  };
};

export default useBulkSelectionActionButton;
