import { getLastUrlSegment } from '../../utils/functions/getLastUrlSegment';

export const parseZendeskArticleUrl = (url: string) => {
  const lastSegement = getLastUrlSegment(url);
  const [articleId, ...articleTitle] = lastSegement.split('-');

  return {
    articleId,
    articleTitle: articleTitle.join(' '),
    lastSegement,
  };
};
