import { AvatarItem } from 'components/lib/Avatar/types';
import { StageNames, Task, TaskDetails } from 'utils/types/api/tasks.types';
import { ResponseError } from 'utils/types/errorResponse';

export enum TasksPanelMode {
  List = 'list',
  Create = 'create',
  Reassign = 'reassign',
  AddAssignees = 'addAssignees',
}

export interface StageDetails {
  name: StageNames.Stage1;
  assignees?: AvatarItem[];
  fetchTask: (
    customUrl?: string
  ) => Promise<{
    data: TaskDetails | undefined;
    status?: number;
    error?: ResponseError;
  }>;
}

export interface TasksPanelContextProps {
  setMode: React.Dispatch<React.SetStateAction<TasksPanelMode>>;
  task?: Task;
  setTask: React.Dispatch<React.SetStateAction<Task | undefined>>;
  setStage: React.Dispatch<React.SetStateAction<StageDetails | undefined>>;
  stage?: StageDetails;
}
