import { CopyIcon } from 'components/Icon';
import { ButtonTransparent } from 'components/lib/Button';
import TooltipString from 'components/TooltipString';
import React, { FC, useState } from 'react';
import { useCopyTextStyles } from './styles';
import { CopyTextProps } from './types';

const CopyText: FC<CopyTextProps> = ({
  textToCopy,
  message,
  successMessage,
  hideTextToCopy,
  buttonHeight,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const classes = useCopyTextStyles({ buttonHeight });

  const onCopyIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    navigator.clipboard.writeText(textToCopy);

    setIsCopied(true);
  };

  const label = message ?? textToCopy;

  return (
    <div className={classes.wrapper}>
      {!hideTextToCopy && (
        <div className={classes.textWrapper}>
          <TooltipString text={textToCopy}>{textToCopy}</TooltipString>
        </div>
      )}
      <div className={classes.buttonWrapper}>
        <TooltipString
          text={isCopied ? successMessage : label}
          noDelay
          modifiedText={
            <ButtonTransparent
              onClick={onCopyIconClick}
              onMouseEnter={() => setIsCopied(false)}
              className={classes.button}
            >
              <CopyIcon size={16} className={classes.copyIcon} />
            </ButtonTransparent>
          }
          noStyle
        >
          {textToCopy}
        </TooltipString>
      </div>
    </div>
  );
};

export default CopyText;
