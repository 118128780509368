import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { SelectUserOption } from 'utils/types/selectInput.types';

export const useAvatarItems = (
  options: SelectUserOption[],
  value?: { users: number[] }
) => {
  const intl = useIntl();

  const avatarsItems = useMemo(() => {
    const users = options
      .filter(({ id }) => !!value?.users.some(uid => id === uid))
      .sort((a, b) => +a.is_deleted - +b.is_deleted);

    return users.map(
      ({
        first_name: firstName,
        last_name: lastName,
        id,
        account_type: accountType,
        is_deleted: isDeleted,
      }) => ({
        firstName: isDeleted
          ? intl.formatMessage(
              {
                id: 'activityLog.deletedUser',
                defaultMessage: 'Deleted user {id}',
              },
              { id }
            )
          : firstName,
        lastName: isDeleted ? '' : lastName,
        id,
        accountType,
        isDeleted,
      })
    );
  }, [options, value, intl]);

  return avatarsItems;
};
