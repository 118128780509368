import React from 'react';
import InPlaceEditSingleSelect from 'components/InPlaceEditControls/InPlaceEditSingleSelect';
import { mapCustomWidgetPropsToBaseInPlaceEditProps } from './utils';
import { Widget } from 'alx-dynamic-form';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import useFormPreview2Container from 'components/FormPreview2/hooks/useFormPrevievContainer';

const AdaptedInPlaceEditSelecDropdown: Widget = widgetProps => {
  const baseInPlaceEditProps = mapCustomWidgetPropsToBaseInPlaceEditProps<
    string,
    any
  >(widgetProps);
  const getPopupContainer = useFormPreview2Container();
  const { options } = widgetProps;

  return (
    <Provider store={store}>
      <InPlaceEditSingleSelect
        {...{ ...baseInPlaceEditProps, options, getPopupContainer }}
        withUnderline
      />
    </Provider>
  );
};

export default AdaptedInPlaceEditSelecDropdown;
