import { useSelector } from 'react-redux';
import { getAccessToken } from 'store/selectors/authSelectors';
import useTokenDecode from './useTokenDecode';
import useAvatarColor from './useAvatarColor';

export const useUserData = () => {
  const tokenData = useTokenDecode();
  const userAvatarBgColor = useAvatarColor(tokenData?.user_id);
  const isLoggedIn = !!useSelector(getAccessToken);

  return {
    isLoggedIn,
    tokenData,
    userAvatarBgColor,
  };
};
