export enum TaskTypes {
  ValueEntry = 'tt_value_entry',
  Select = 'tt_select',
  FieldUpdater = 'tt_field_updater',
  DocumentGeneratorV1 = 'tt_document_generator_v1',
  DocumentGeneratorV2 = 'tt_document_generator_v2',
  DocumentReview = 'tt_document_review',
  DocumentSignature = 'tt_document_signature',
  AddressHistory = 'tt_address_history',
  DynamicForm = 'tt_dynamic_form',
  Webcheck = 'tt_webcheck',
  Instructions = 'tt_instructions',
  Form = 'tt_form',
  RecordUpdate = 'tt_object_record_update',
}

export enum TaskResponseDataTypes {
  Text = 't_text',
  Integer = 't_integer',
  Float = 't_float',
  Json = 't_json',
  Date = 't_date',
  DateTime = 't_datetime',
  Bool = 't_bool',
  SingleValue = 't_single',
  MultipleValues = 't_multiple',
  ObjectRecordFieldChanges = 't_field_change_log',
  DocumentGeneratorStatus = 't_document_generator_status',
  DocumentReviewStatus = 't_document_review_status',
  DocumentSignatureStatus = 't_document_signature_status',
  AddressHistory = 't_address_history',
  InstructionsStatus = 't_instructions_status',
  Unknown = 't_unknown',
}

export enum FillInAFormTaskResponse {
  Form = 't_form',
}

export enum TaskUiOptions {
  UiSingleLine = 'ui_singleline',
  UiMultiLine = 'ui_multiline',
  UiRadio = 'ui_radio',
}

export enum ReminderNoticeTypes {
  TaskCompleteBy = 'task_complete_by',
  TaskOverdue = 'task_overdue',
  TaskExpiry = 'task_expiry',
}

export enum TimeUnits {
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
}
