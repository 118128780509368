import React from 'react';
import { Popover as AntPopover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';
import { CONTENT_ID } from 'utils/elementsIds';

const Popover: React.FC<PopoverProps> = ({ ...rest }) => {
  return (
    <AntPopover
      transitionName=''
      getPopupContainer={() =>
        document.getElementById(CONTENT_ID) || document.body
      }
      {...rest}
    />
  );
};

export default Popover;
