import React from 'react';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { FormLabelWithToolTip } from 'pages/TaskTemplates/components/FormLabel';
import OwnersAvatarsList from './OwnersAvatarsList';
import { Widget } from 'alx-dynamic-form';
import useCustomWidgetsStyles from '../../customWidgetsStyles';
import clsx from 'clsx';

const CustomOwnersWidget: Widget = ({ required, label, fieldAlias }) => {
  const widgetClasses = useCustomWidgetsStyles({});

  return (
    <Provider store={store}>
      <FormLabelWithToolTip
        dynamicSpacing
        {...{ required }}
        className={clsx([
          widgetClasses.inputLabel,
          widgetClasses.inputLabelEllipsis,
        ])}
        label={label}
      />

      <div data-testid={`owners-${fieldAlias}`}>
        <OwnersAvatarsList value={[]} />
      </div>
    </Provider>
  );
};

export default CustomOwnersWidget;
