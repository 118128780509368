import migrationToV4 from './migrationToV4';
import migrationToV5 from './migrationToV5';
import migrationToV6 from './migrationToV6';
import { MigrationFunction } from './types';

const migrations: { [key: number]: MigrationFunction } = {
  4: migrationToV4,
  5: migrationToV5,
  6: migrationToV6,
};

export default migrations;
