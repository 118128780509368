import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import { CheckIcon } from 'components/Icon';
import EmptyData from 'components/EmptyData';
import useDynamicSchema from 'components/formBuilder/hooks/useDynamicSchema';
import { Loader } from 'components/lib/Loader';
import useBackToList from 'hooks/useBackToList';
import useData from 'hooks/useData';
import { useToggle } from 'hooks/useToggle';
import { ObjectClassDetailsFromApi } from 'pages/ObjectClasses/components/ObjectClassForm/hooks/useObjectClassDetailsData/types';
import { OBJECT_CLASS_DETAILS } from 'utils/endpoints';
import routes from 'utils/routingPaths';
import useCreateRecordFormStyles from './styles';
import { CreateRecordFormProps } from './types';
import useCreateRecordForm, {
  useCreateRecordMaximumLimitExceeded,
} from './hooks';
import ErrorModal from './ErrorModal';
import PageNotFound from 'components/PageNotFound';
import ExceededMaximumNumberOfResources from 'components/ExceededMaximumNumberOfResources';
import PermissionDenied from 'components/PermissionDenied';
import { useHelmetContext } from 'contexts/HelmetContext';
import FormPreview2 from 'components/FormPreview2';
import { FormPreview2RefProps } from 'components/FormPreview2/types';
import migrateFormDefinitionToSupportedVersion from 'components/formBuilder/migrateDefinition';
import { selectChildClasses } from 'store/selectors/childClassesSelectors';
import { useSelector } from 'react-redux';
import useChildClasses from 'pages/Records/useChildClasses';
import useNestedRecordsStack from 'hooks/useNestedRecordsStack';
import { FORM_DRAWER_TESTID } from 'utils/testIds';
import { FormValue } from 'alx-dynamic-form';

const CreateRecordForm = ({
  classId,
  isDrawer,
  onCancelClick,
  wrapperId,
}: CreateRecordFormProps) => {
  const intl = useIntl();
  const classes = useCreateRecordFormStyles({});
  const history = useHistory();
  const { generateBackPath } = useBackToList();
  const formRef = useRef<FormPreview2RefProps>(null);
  const [isOpen, { toggleOff: closeModal, toggleOn: openModal }] = useToggle(
    false
  );
  const { onSubmit, isSaving, errors } = useCreateRecordForm(
    classId,
    formRef,
    openModal
  );

  const [formData, setFormData] = useState<
    MappedObject<FormValue> | undefined
  >();

  const childClasses = useSelector(selectChildClasses);

  const {
    isLimitExceeded,
    loading: recordsLimitLoading,
    error: recordsLimitError,
  } = useCreateRecordMaximumLimitExceeded(classId);

  const [objectClass, { loading, error, fetchData }] = useData<
    ObjectClassDetailsFromApi
  >(generatePath(OBJECT_CLASS_DETAILS, { id: classId }), {
    fetchOnLoad: false,
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classId]);

  const { setObjectClassChildren, childrenClassLoading } = useChildClasses({
    objectClass,
  });

  useEffect(() => {
    setObjectClassChildren();
  }, [setObjectClassChildren]);

  const { loading: schemaLoading, schema, uiSchema } = useDynamicSchema(
    objectClass?.display_configuration?.recordView?.data_schema,
    objectClass?.display_configuration?.recordView?.ui_schema,
    classId
  );

  const { updateSelectedElement, stackElement } = useNestedRecordsStack(
    isDrawer,
    objectClass
  );

  useEffect(() => {
    if (formData) {
      updateSelectedElement({ formData: formData });
    }
  }, [formData, updateSelectedElement]);

  const { supportedSchema, supportedUiSchema } = useMemo(() => {
    const {
      schema: supportedSchema,
      uiSchema: supportedUiSchema,
    } = migrateFormDefinitionToSupportedVersion(
      JSON.stringify(schema),
      JSON.stringify(uiSchema)
    );

    return {
      supportedSchema,
      supportedUiSchema,
    };
  }, [schema, uiSchema]);

  const name = objectClass?.name;

  const title = intl.formatMessage(
    {
      id: 'objectRecords.create.title',
      defaultMessage: 'Create {className} record',
    },
    { className: name }
  );

  const { setPageTitle } = useHelmetContext();

  useEffect(() => {
    if (title && name) setPageTitle(title);
  }, [name, setPageTitle, title]);

  if (loading || schemaLoading || recordsLimitLoading || childrenClassLoading)
    return (
      <div className={classes.loaderWrapper}>
        <Loader size='large' />
      </div>
    );

  if (error?.status === StatusCodes.NOT_FOUND)
    return (
      <EmptyData
        title={intl.formatMessage(
          {
            id: 'objectRecords.noClassFound',
            defaultMessage: 'No object class with id {id} found',
          },
          { id: classId }
        )}
      />
    );

  if (
    error?.status === StatusCodes.FORBIDDEN ||
    !objectClass?._meta.permissions.object_records.create
  )
    return <PermissionDenied />;

  if (
    !objectClass?.display_configuration?.recordView?.data_schema?.enabled ||
    !!recordsLimitError?.status
  )
    return <PageNotFound />;

  if (isLimitExceeded)
    return (
      <ExceededMaximumNumberOfResources
        message={intl.formatMessage({
          id: 'records.exceededMaximum',
          defaultMessage: 'The maximum number of records has been reached.',
        })}
      />
    );

  const cancelForm = () => {
    if (onCancelClick) {
      onCancelClick();
      return;
    }
    history.push(generateBackPath(routes.RECORDS));
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.formWrapper}>
        <FormPreview2
          preventModalAppearing // RecordDisplayDrawer handles cancel configuration modal and prevent redirect modal
          additionalFieldProps={{ childClasses, classId }}
          testId={isDrawer ? FORM_DRAWER_TESTID : undefined}
          schema={supportedSchema}
          uischema={supportedUiSchema}
          onCancel={cancelForm}
          saving={isSaving}
          initialValues={stackElement?.formData}
          setFormData={setFormData}
          {...{ onSubmit, title, errors }}
          ref={formRef}
          submitButtonIcon={<CheckIcon size={10} />}
          submitButtonContent={
            <FormattedMessage id='misc.save' defaultMessage='Save' />
          }
          isCreateMode
          onSchemaErrorGoBackClick={cancelForm}
          wrapperId={wrapperId}
        />
      </div>
      <ErrorModal {...{ closeModal, isOpen }} />
    </div>
  );
};

export default CreateRecordForm;
