import { FORM_BUILDER_FIELD_KEY } from './consts';
import { FlatTokenDataProps } from '../FormBuilderModal/types';
import {
  ExtendedJsonSchema,
  FormDataValue,
  SchemaSectionProperty,
} from './types';
import { ExtendedUiSchema } from './customFields/ObjectField/types';
import { getExampleValue } from 'components/formBuilder/ListingView/utils';
import { FormValue } from 'alx-dynamic-form';

export const getFieldInfo = (id: string) => {
  const rawSectionName = id.substring(id.indexOf('+') + 1, id.lastIndexOf('+'));
  const rawFieldName = id
    .replace(FORM_BUILDER_FIELD_KEY, '')
    .replace(`+${rawSectionName}+_`, '');

  return {
    rawSectionName,
    rawFieldName,
  };
};

export const addFieldPrefixes = (
  data: MappedObject<FormDataValue> | FlatTokenDataProps
) =>
  Object.fromEntries(
    Object.entries(data).map(([key, value]) => [`field_${key}`, value])
  );

export const removeDefaultValuesFromSchema = (
  schema: ExtendedJsonSchema
): ExtendedJsonSchema => {
  const { properties: formProperties } = schema;

  if (!formProperties) return schema;

  const formPropertiesWithoutDefaults = Object.fromEntries(
    Object.entries(formProperties).map(([sectionName, section]) => {
      const { properties: sectionProperties } = section;

      if (!sectionProperties) return [sectionName, section];

      const sectionPropertiesWithoutDefaults = Object.fromEntries(
        Object.entries(sectionProperties).map(
          ([componentName, componentProperties]) => {
            const { default: removedDefault, ...rest } = componentProperties;

            return [componentName, rest];
          }
        )
      );

      return [
        sectionName,
        { ...section, properties: sectionPropertiesWithoutDefaults },
      ];
    })
  );

  return { ...schema, properties: formPropertiesWithoutDefaults };
};

export const generateSampleValues = (schema: string, uiSchema: string) => {
  const parsedSchema = JSON.parse(schema || '{}') as ExtendedJsonSchema;
  const parsedUiSchema = JSON.parse(uiSchema || '{}') as ExtendedUiSchema;

  const sections = Object.keys(parsedSchema?.properties || {});

  return sections?.reduce((tmpSampleValues, sectionKey) => {
    const { properties } = parsedSchema?.properties?.[
      sectionKey
    ] as SchemaSectionProperty;

    Object.keys(properties || {}).forEach(fieldKey => {
      const field = properties?.[fieldKey];

      const value = field
        ? getExampleValue(
            field,
            parsedUiSchema?.[sectionKey]?.[fieldKey] || {},
            Math.round(Math.random() * 3)
          )
        : undefined;

      tmpSampleValues[fieldKey] = value as FormValue;
    });

    return tmpSampleValues;
  }, {} as MappedObject<FormValue>);
};
