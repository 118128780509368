import React from 'react';
import InPlaceEditEmail from 'components/InPlaceEditControls/InPlaceEditEmail';
import { mapCustomWidgetPropsToBaseInPlaceEditProps } from './utils';
import { Widget } from 'alx-dynamic-form';
import { Provider } from 'react-redux';
import { store } from 'store/store';

const AdaptedInPlaceEditEmail: Widget = widgetProps => {
  const baseInPlaceEditProps = mapCustomWidgetPropsToBaseInPlaceEditProps<
    string,
    any
  >(widgetProps);

  return (
    <Provider store={store}>
      <InPlaceEditEmail {...{ ...baseInPlaceEditProps }} withUnderline />
    </Provider>
  );
};

export default AdaptedInPlaceEditEmail;
