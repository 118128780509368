import { DraggableComponents } from 'components/formBuilder/formBuilder/InputsContainer/enums';
import { DynamicExtendedSchema } from 'components/formBuilder/formBuilder/types';
import { SearchDataParams } from 'components/SearchInput/types';
import useData from 'hooks/useData';
import { useCallback, useEffect } from 'react';
import { generatePath } from 'react-router';
import { OBJECT_RECORD_LIST, OBJECT_RECORD_OWNERS } from 'utils/endpoints';
import { GetResponse } from 'utils/types';
import {
  ObjectRecordOptions,
  RecordOwner,
} from 'utils/types/api/objectRecords.types';
import { RecordsOptions } from './RecordsListing/components/RecordsCards/types';
import { RecordPeopleWithCount } from './types';
import schemaHasField from 'utils/functions/schemaHasField';

export const useRecordsOptions = () => {
  const [data, { loading, error }] = useData<RecordsOptions>(
    OBJECT_RECORD_LIST,
    {
      requestType: 'options',
    }
  );

  return { loading, data, error };
};

export const useRecordOptions = (recordId: string | undefined) => {
  const [options, { loading, error, fetchData }] = useData<ObjectRecordOptions>(
    '',
    {
      requestType: 'options',
      fetchOnLoad: false,
    }
  );

  useEffect(() => {
    if (recordId === undefined) return;

    fetchData(generatePath(OBJECT_RECORD_OWNERS, { id: recordId }));
  }, [recordId, fetchData]);

  return {
    loading: loading || (!options && !error), // to avoid unmounting loading should be true at the beginning
    options,
    errorStatus: error?.status,
  };
};

export const useRecordOwners = (
  schema: DynamicExtendedSchema | undefined,
  id: string | number | undefined,
  fetchOnLoad = false,
  hasViewOwnersPermission = true
) => {
  const getRequiredFields = useCallback(
    (data: RecordOwner[]) =>
      data.map(
        ({
          id: ownershipId,
          owner_id: id,
          category,
          user: {
            first_name: firstName,
            last_name: lastName,
            company_name: company,
            username: email,
          } = {},
        }) => ({
          ownershipId,
          firstName,
          lastName,
          id,
          category,
          company,
          email,
        })
      ),
    []
  );

  const [data, { loading, error, fetchData }] = useData<
    Omit<RecordPeopleWithCount, 'id'>
  >(id ? generatePath(OBJECT_RECORD_OWNERS, { id }) : '', {
    fetchOnLoad,
    dataTransformFn: (responseData: GetResponse<RecordOwner>) => {
      return {
        filteredCount: responseData.filtered_count,
        total: responseData.total_count,
        results: getRequiredFields(responseData.results),
      };
    },
  });

  useEffect(() => {
    if (!schema || !hasViewOwnersPermission) return;

    if (schemaHasField(schema, DraggableComponents.Owners)) fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema, hasViewOwnersPermission]);

  const searchData = async ({ value, searchKey }: SearchDataParams) => {
    await fetchData(
      id && value
        ? `${generatePath(OBJECT_RECORD_OWNERS, {
            id,
          })}?${searchKey}=${value}`
        : undefined
    );
  };

  const { filteredCount, total, results = [] } =
    data || ({} as RecordPeopleWithCount);

  return {
    loading,
    data: results,
    error,
    fetchData,
    searchData,
    filteredCount,
    total,
  };
};
