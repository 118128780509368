import React, { CSSProperties, useCallback, useMemo, useRef } from 'react';
import { ColumnInstance, Row } from 'react-table';
import { DEFAULT_COLUMNS_WIDTHS, ACTIONS_COLUMN_WIDTH } from './consts';
import { useRowStyles } from './styles';
import clsx from 'clsx';
import { useTableRowContext } from 'contexts/TableRowContext';
import CustomCell from 'components/CustomCell';
import ColumnDataTypes from 'utils/Enums/ColumnDataTypes';
import TablesType from 'utils/Enums/TablesType';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultPermissions } from 'utils/types/api/permissions.types';
import {
  TABLE_CELL_COLUMN_NAME_TESTID,
  TABLE_ROW_ACTION_BUTTON_TESTID,
  TABLE_ROW_ITEM_ID_TESTID,
} from 'utils/testIds';
import ActionMenu from 'components/ActionMenu';
import ActionMenuList from 'components/ActionMenu/ActionMenuList';
import { CONTENT_ID, TABLE_BODY_ID, TABLE_ROW } from 'utils/elementsIds';
import { useTableContext } from 'contexts/TableContext';
import BulkSelectionRowCheckbox from './BulkSelection/RowCheckbox';
import TooltipString from 'components/TooltipString';
import { ReactComponent as ThreeDotsSvg } from 'img/icons/three-dots-icon.svg';
import UserCell from 'components/CustomCell/UserCell';
import { getColumnVarName } from './utils';
import UrlCell from 'components/CustomCell/UrlCell';
import { CustomAvatarGroup } from 'components/lib/Avatar';
import { mapBaseUserInfoToAvatarItem } from 'utils/functions/mapUserToAvatarItem';
import { AvatarItem } from 'components/lib/Avatar/types';
import get from 'lodash/get';
import { BaseUserInfo } from 'utils/types/api/BaseUserInfo.types';
import { DocumentStatus } from 'utils/types/api/documentTemplate.types';

export interface TableRowProps {
  index: number;
  row: Row<object>;
  currentTableName: TablesType | undefined;
  style?: CSSProperties;
  showIndicator?: boolean;
}

const TableRow = ({
  index,
  row,
  currentTableName,
  style = {},
  showIndicator,
}: TableRowProps) => {
  const {
    density,
    customCells,
    currentColumns,
    setSelectedRow,
    getSelectedRow,
    onDoubleClick,
    onSingleClick,
    onActionCellClick,
  } = useTableRowContext();
  const {
    fetchData,
    isVisibleBulkSelectionColumn,
    bulkSelectionList,
    setBulkSelectionList,
    searchValue,
    isDraggingEnabled,
    fullWidth,
  } = useTableContext();
  const classes = useRowStyles({ density });
  const { width, ...virtualizationStyles } = style; //set all virtualization styles except width for single element
  const dispatch = useDispatch();
  const selectedRowId = useSelector(getSelectedRow);
  const originalRow = (row.original as MappedObject<any>) || {};
  const permissions: DefaultPermissions = originalRow?._meta?.permissions || {};
  const id =
    originalRow.id !== undefined ? originalRow.id : row.values.id || row.id;
  const name = row.values?.name || row.values?.full_name;
  const usage = row.values?.usage;
  const membership = row.values?.membership;
  const timer = useRef<NodeJS.Timeout | null>(null);
  const isNotProcessingStatus =
    row.values?.generated_document?.status !== DocumentStatus.Processing;

  const handleOnClick = () => {
    if (timer.current) {
      if (onDoubleClick) {
        onDoubleClick({ id, permissions, name, ...originalRow });
      }
      clearTimeout(timer.current);
      return;
    }

    timer.current = setTimeout(() => {
      if (!selectedRowId || id.toString() !== selectedRowId) {
        if (setSelectedRow) {
          dispatch(setSelectedRow(id.toString()));
        }
      }
      if (onSingleClick) {
        onSingleClick({ id, permissions, name, ...originalRow });
      }
    }, 200);
  };

  const handleActionsClick = () => {
    if (onActionCellClick)
      onActionCellClick({
        actionId: `action-${id}`,
        ...originalRow,
        permissions,
        name,
        id,
        usage,
        membership,
        fetchData,
        setBulkSelectionList,
      });
  };

  const renderCell = useCallback(
    (
      column: ColumnInstance<object>,
      render: (type: string, userProps?: object | undefined) => React.ReactNode
    ) => {
      const type = currentColumns[column.id]?.type;
      const value = row.values[column.id];
      const meta: { [key: number]: BaseUserInfo } = get(
        row.original,
        '_meta.users',
        undefined
      );
      if (
        type === ColumnDataTypes.User &&
        value?.users &&
        Array.isArray(value.users) &&
        meta
      ) {
        const users: AvatarItem[] = value.users.map((id: number) => {
          const user = meta[id] ?? {
            id: 0,
          };
          return mapBaseUserInfoToAvatarItem(user);
        });
        return (
          <CustomAvatarGroup
            stopPropagation={true}
            items={users}
            limitItems={2}
          />
        );
      }
      if (value && type === ColumnDataTypes.URL)
        return <UrlCell value={value} />;
      if (value && type === ColumnDataTypes.User) {
        return <UserCell user={value} />;
      }
      if (type === ColumnDataTypes.JSON) {
        return <div>{JSON.stringify(value)}</div>;
      }
      return (
        <TooltipString text={(render('Cell') as string) ?? ''}>
          {render('Cell')}
        </TooltipString>
      );
    },
    [currentColumns, row.original, row.values]
  );

  const moveableCell = useMemo(() => {
    if (showIndicator) {
      return isDraggingEnabled && !searchValue?.value.length ? (
        <div className={clsx(classes.tbodyTd, classes.moveableCell)}>
          <ThreeDotsSvg className={clsx(classes.moveableIcon)} />
          <ThreeDotsSvg />
        </div>
      ) : (
        <div className={clsx(classes.tbodyTd, classes.moveableCell)} />
      );
    }
  }, [
    showIndicator,
    isDraggingEnabled,
    classes.moveableCell,
    classes.moveableIcon,
    searchValue,
    classes.tbodyTd,
  ]);

  const getActionMenuPopupContainer = () => {
    const wrapper = document.getElementById(TABLE_BODY_ID) || document.body;
    const content = document.getElementById(CONTENT_ID) || document.body;
    const hasVerticalScrollbar = wrapper.scrollHeight > wrapper.clientHeight;

    return hasVerticalScrollbar ? wrapper : content;
  };

  return (
    <div
      {...row.getRowProps({
        style: {
          ...virtualizationStyles,
          width: 'initial',
        },
      })}
      onClick={handleOnClick}
      className={clsx(classes.tr, {
        [classes.fullRowWidth]: fullWidth,
        [classes.alternateRow]: index % 2 === 0,
        [classes.selected]:
          selectedRowId === id.toString() || bulkSelectionList.includes(id),
      })}
      data-testid={`${TABLE_ROW_ITEM_ID_TESTID}${id}`}
      id={TABLE_ROW.replace('{0}', id.toString())}
    >
      {moveableCell}
      {isVisibleBulkSelectionColumn && <BulkSelectionRowCheckbox rowId={id} />}
      {row.cells.map(({ getCellProps, column, row, render }) => (
        <div
          {...getCellProps({
            style: {
              minWidth: DEFAULT_COLUMNS_WIDTHS.minWidth,
              width: `var(${getColumnVarName(column.id)})`,
            },
          })}
          key={`${row.id}-${column.id}`}
          className={clsx(classes.tbodyTd, classes.tableCell, {
            'col-resizing': column.isResizing,
          })}
          data-testid={`${TABLE_CELL_COLUMN_NAME_TESTID}${column.id}`}
        >
          {customCells &&
          customCells.includes(column.id) &&
          currentTableName ? (
            <div className={classes.cellWrapper}>
              <CustomCell
                table={currentTableName}
                column={column.id}
                row={row.original}
              />
            </div>
          ) : (
            <div
              className={clsx([
                classes.cellWrapper,
                {
                  [classes.numericColumns]:
                    [ColumnDataTypes.Int, ColumnDataTypes.Float].includes(
                      currentColumns[column.id]?.type as ColumnDataTypes
                    ) && currentColumns[column.id]?.alias !== 'id',
                },
              ])}
            >
              {renderCell(column, render)}
            </div>
          )}
        </div>
      ))}
      {onActionCellClick && isNotProcessingStatus && (
        <div
          className={clsx([
            classes.tbodyTd,
            classes.tableCell,
            classes.tableCellCentered,
          ])}
          style={{
            minWidth: ACTIONS_COLUMN_WIDTH,
            marginLeft: 'auto',
          }}
          data-testid={TABLE_ROW_ACTION_BUTTON_TESTID}
        >
          <ActionMenu
            rowId={id}
            overlay={<ActionMenuList items={[]} />}
            onClick={handleActionsClick}
            getPopupContainer={getActionMenuPopupContainer}
          />
        </div>
      )}
    </div>
  );
};

export default TableRow;
