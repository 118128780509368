import React from 'react';
import useChildClassFormBuilderHeaderStyles from './styles';

import { ReactComponent as CubeIcon } from 'img/icons/object-class-icon.svg';
import { useIntl } from 'react-intl';
import { ChildClassFormBuilderHeaderProps } from './types';
import Tooltip from 'components/lib/Tooltip';

const ChildClassFormBuilderHeader = ({
  label,
  withIcon,
}: ChildClassFormBuilderHeaderProps) => {
  const styles = useChildClassFormBuilderHeaderStyles({});
  const intl = useIntl();

  const message = intl.formatMessage(
    {
      id: 'formBuilder.childClassRelatedRecords',
      defaultMessage: 'Related records: {childClassLabel}',
    },
    {
      childClassLabel: label,
    }
  );

  return (
    <>
      {withIcon && <CubeIcon className={styles.inputIllustration} />}
      <Tooltip placement='topLeft' overlay={message}>
        {message}
      </Tooltip>
    </>
  );
};

export default ChildClassFormBuilderHeader;
