import { Input } from 'components/lib/Input';
import React, { useCallback, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { Select, SelectOption as Option } from 'components/lib/Select';
import { Multiselect } from 'components/lib/Multiselect';
import { useIntl } from 'react-intl';
import AutocompleteSelect from 'components/AutocompleteSelect';
import useDynamicIntl from 'hooks/useDynamicIntl';
import {
  DatePickerInput,
  DateRangePickerInput,
} from 'components/Inputs/DatePicker';
import { parseToIntlKey } from 'utils/functions/parseToIntlKey';
import useFilterEditor from 'components/FilterEditor/hooks';
import {
  AutocompleteSelectValue,
  SelectUserOption,
} from 'utils/types/selectInput.types';
import {
  FilterOption,
  PredicateInputProps,
  PredicatesTestIdType,
} from './types';
import { InputNumber } from 'components/lib/InputNumber';
import { FILTER_INPUT_TESTID } from 'utils/testIds';
import { SearchBoldIcon } from 'components/Icon';
import usePredicatesStyles from './styles';
import { PredicateSet } from '../ColumnSelect/types';
import NoMatchesFound from 'components/NoMatchesFound';
import OptionAvatar from 'components/OptionAvatar';
import AutocompleteUsersSelect from 'components/AutocompleteUsersSelect';
import List from 'components/List';
import { AvatarItem } from 'components/lib/Avatar/types';
import PeopleListElement from 'pages/Records/RecordsListing/RecordAccessPanel/components/PeopleListElement';
import { SELECT_USERS_WRAPPER } from 'utils/elementsIds';
import usePredicateTimeDate from './hooks';
import { USERS_LIST_AUTOCOMPLETE } from 'utils/endpoints';
import AutocompleteUserFieldFilter from '../AutocompleteUserFieldFilter';

const PredicateInput = ({
  type,
  value,
  onChange,
  selectOptions = [],
  selectMultiple = false,
  autocompleteUrl,
  testIdType = PredicatesTestIdType.Default,
  filterKey,
  predicateSet,
}: PredicateInputProps) => {
  const { isExternal } = useFilterEditor();
  const intl = useIntl();
  const dynamicIntl = useDynamicIntl();
  const classes = usePredicatesStyles({});
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    onChange(value);
  };
  const isUserField = type === 'user' && !autocompleteUrl;

  const { dateFormat, isTimeVisible } = usePredicateTimeDate(filterKey, type);

  const onDatepickerChange = useCallback(
    (date: Moment | null) => {
      onChange(date?.format(dateFormat) ?? '');
    },
    [onChange, dateFormat]
  );

  const onNumberInputChange = (value: string | number | undefined) =>
    onChange(value ?? '');

  const onDateRangePickerChange = (
    dateRange: [Moment | null, Moment | null] | null
  ) =>
    onChange(
      [
        dateRange?.[0]?.format(dateFormat) ?? '',
        dateRange?.[1]?.format(dateFormat) ?? '',
      ] ?? ''
    );

  const onSelectChange = (
    value:
      | string
      | string[]
      | AutocompleteSelectValue
      | AutocompleteSelectValue[]
  ) => onChange(value);

  const removeSeletedUser = useCallback(
    (userId: number | string) => {
      if (!value) return;

      if (Array.isArray(value)) {
        const tmpUsers = ([...value] as SelectUserOption[]).filter(
          value => value?.id !== userId
        );

        onChange(tmpUsers);
      } else {
        onChange('');
      }
    },
    [onChange, value]
  );

  const onSelectUserChange = useCallback(
    (newValue: SelectUserOption | undefined) => {
      if (isUserField && Array.isArray(value)) {
        const [isAlreadyAdded] = (value as SelectUserOption[]).filter(
          ({ id }) => id === newValue?.id
        );

        if (isAlreadyAdded) {
          removeSeletedUser(isAlreadyAdded?.id);

          return;
        }
      }
      if (!selectMultiple) {
        onChange(newValue || '');

        return;
      }
      if (!value) {
        onChange([newValue] as SelectUserOption[]);
        return;
      }
      const tmpUsers = Array.isArray(value) ? value : [value];

      onChange([newValue, ...tmpUsers] as SelectUserOption[]);
    },
    [isUserField, onChange, removeSeletedUser, selectMultiple, value]
  );

  const dateTimeValue = useMemo(
    () => (value ? moment(value as string) : undefined),
    [value]
  );

  const mapSelectOptions = useMemo(
    () =>
      selectOptions.map(({ value, text }) => {
        if (value === undefined) {
          return null;
        }

        return (
          <Option key={value} {...{ value }}>
            {predicateSet === PredicateSet.ClassFields
              ? text
              : dynamicIntl({
                  id: `enums.${parseToIntlKey(
                    typeof value === 'string' ? value : text
                  )}`,
                  defaultMessage: text,
                })}
          </Option>
        );
      }),
    [selectOptions, dynamicIntl, predicateSet]
  );

  const filterOption: FilterOption = useCallback(
    (search, option) =>
      option?.children?.toLowerCase().includes(search.toLowerCase()),
    []
  );

  const testId = `${FILTER_INPUT_TESTID}-${type}${testIdType}`;

  const checkboxYesLabel =
    predicateSet === PredicateSet.ClassFields
      ? intl.formatMessage({
          id: 'bool.checked',
          defaultMessage: 'Checked',
        })
      : intl.formatMessage({
          id: 'bool.yes',
          defaultMessage: 'Yes',
        });
  const checkboxNoLabel =
    predicateSet === PredicateSet.ClassFields
      ? intl.formatMessage({
          id: 'bool.unchecked',
          defaultMessage: 'Unchecked',
        })
      : intl.formatMessage({
          id: 'bool.no',
          defaultMessage: 'No',
        });

  const getIsUserSelected = useCallback(
    (userId: number) =>
      !!value &&
      ((Array.isArray(value) ? value : [value]) as SelectUserOption[])?.some(
        ({ id }) => userId === id
      ),
    [value]
  );

  const getDisabledText = useCallback(
    ({ id }: SelectUserOption) => {
      if (getIsUserSelected(id))
        return intl.formatMessage({
          id: 'misc.alreadySelected',
          defaultMessage: 'Already selected',
        });

      return '';
    },
    [getIsUserSelected, intl]
  );

  const users = useMemo(() => {
    if (!value || (Array.isArray(value) && !value[0])) return undefined;
    return ((Array.isArray(value) ? value : [value]) as SelectUserOption[]).map(
      ({
        first_name: firstName,
        last_name: lastName,
        id,
        account_type: accountType,
        username: email,
        company_name: company,
      }) => ({
        firstName,
        lastName,
        id,
        accountType,
        email,
        company,
      })
    );
  }, [value]);

  switch (type) {
    case 'string':
    case 'phone':
    case 'email':
    case 'url':
      return (
        <Input
          value={value as string}
          onChange={onInputChange}
          placeholder={intl.formatMessage({
            id: 'placeholders.enterText',
            defaultMessage: 'Enter text',
          })}
          data-testid={testId}
        />
      );
    case 'int':
    case 'float':
      return (
        <InputNumber
          type='number'
          value={value as number}
          onChange={onNumberInputChange}
          placeholder={intl.formatMessage({
            id: 'placeholders.enterNumber',
            defaultMessage: 'Enter number',
          })}
          data-testid={testId}
        />
      );
    case 'datetime':
    case 'date':
      return (
        <DatePickerInput
          value={dateTimeValue}
          onChange={onDatepickerChange}
          className={classes.fullWidth}
          placeholder={intl.formatMessage({
            id: 'placeholders.enterDate',
            defaultMessage: 'Select date',
          })}
          data-testid={testId}
          showTime={isTimeVisible}
        />
      );
    case 'dateRange':
    case 'dateTimeRange':
      return (
        <DateRangePickerInput
          value={
            value && Array.isArray(value) && value[0] && value[1]
              ? [moment(value[0] as string), moment(value[1] as string)]
              : undefined
          }
          onChange={onDateRangePickerChange}
          className={classes.fullWidth}
          withCustomPopupStyles
          {...{ isExternal }}
          data-testid={testId}
          showTime={isTimeVisible}
        />
      );
    case 'user':
      if (isUserField) {
        const fixedAutocompleteUrl = USERS_LIST_AUTOCOMPLETE.replace(
          '?',
          '?search_in=first_name,last_name,company_name&'
        );
        return (
          <div id={SELECT_USERS_WRAPPER} className={classes.usersAutocomplete}>
            <AutocompleteUserFieldFilter
              selectMultiple={selectMultiple}
              fixedAutocompleteUrl={fixedAutocompleteUrl}
              onSelectUserChange={onSelectUserChange}
              users={users ?? []}
              getIsUserSelected={getIsUserSelected}
            />
          </div>
        );
      }

      const fixedAutocompleteUrl = autocompleteUrl?.replace(
        '?',
        '?search_in=first_name,last_name,company_name&'
      );
      return (
        <div id={SELECT_USERS_WRAPPER} className={classes.usersAutocomplete}>
          <AutocompleteUsersSelect
            onChange={onSelectUserChange}
            {...{
              selectMultiple,
            }}
            autocompleteUrl={fixedAutocompleteUrl}
            checkOptionIsDisabled={({ id }) => getIsUserSelected(id)}
            renderOption={(option, searchValue) => (
              <OptionAvatar
                {...{ option, searchValue }}
                disabledText={getDisabledText(option)}
              />
            )}
          />
          <List<AvatarItem>
            hideEmptyData
            items={users}
            renderItem={item => (
              <PeopleListElement
                {...item}
                onDelete={() => removeSeletedUser(item.id)}
              />
            )}
          />
        </div>
      );

    case 'enum':
    case 'set':
      if (!!autocompleteUrl) {
        return (
          <AutocompleteSelect
            onChange={onSelectChange}
            value={value as AutocompleteSelectValue[]}
            autocompleteUrl={autocompleteUrl}
            selectMultiple={selectMultiple}
            dataTestId={testId}
          />
        );
      }

      return selectMultiple ? (
        <div className={classes.multiSelectWrapper}>
          <Multiselect
            className={classes.multiSelect}
            onChange={onSelectChange}
            placeholder={intl.formatMessage({
              id: 'predicates.select',
              defaultMessage: 'Select',
            })}
            {...{ value, filterOption }}
            showArrow
            icon={<SearchBoldIcon size={12} className={classes.searchIcon} />}
            notFoundContent={<NoMatchesFound />}
          >
            {mapSelectOptions}
          </Multiselect>
        </div>
      ) : (
        <Select
          className={classes.fullWidth}
          onChange={onSelectChange}
          placeholder={intl.formatMessage({
            id: 'predicates.select',
            defaultMessage: 'Select',
          })}
          {...{ value }}
          data-testid={testId}
          notFoundContent={<NoMatchesFound />}
        >
          {mapSelectOptions}
        </Select>
      );

    case 'bool':
      return (
        <Select
          className={classes.fullWidth}
          onChange={onSelectChange}
          placeholder={intl.formatMessage({
            id: 'predicates.select',
            defaultMessage: 'Select',
          })}
          {...{ value }}
          data-testid={testId}
        >
          <Option key='true' value='true'>
            {checkboxYesLabel}
          </Option>
          <Option key='false' value='false'>
            {checkboxNoLabel}
          </Option>
        </Select>
      );

    default:
      return null;
  }
};

export default PredicateInput;
