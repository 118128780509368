import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  SET_OBJECT_CLASSES,
  SET_OBJECT_CLASSES_FETCHING,
  APPEND_OBJECT_CLASSES,
  RESET_OBJECT_CLASSES,
  SET_OBJECT_CLASSES_COLUMNS,
  RESET_OBJECT_CLASSES_COLUMNS,
  SET_OBJECT_CLASSES_SELECTED_ROW,
  SET_OBJECT_CLASSES_RESTRICTIONS,
  SET_OBJECT_CLASSES_RESPONSE,
} from 'store/constants/objectClasses.consts';
import {
  appendTableContent,
  resetTable,
  setTableContent,
  setTableLoading,
  resetTableColumns,
  setTableColumns,
  setSelectedRow,
} from './tablesCommonStateModificators';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import {
  ObjectClass,
  ObjectClassesResponse,
} from 'utils/types/api/objectClasses.types';
import { CommonTableState } from 'utils/types/api/table.types';
import cloneDeep from 'lodash/cloneDeep';

export type ObjectClasses = CommonTableState<ObjectClass> & {
  response?: ObjectClassesResponse;
};

const initialState = cloneDeep(INITIAL_TABLE_STATE);

const ObjectClassesReducer: Reducer<ObjectClasses, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_OBJECT_CLASSES: {
      return setTableContent<ObjectClass, typeof SET_OBJECT_CLASSES>(
        state,
        action
      );
    }
    case APPEND_OBJECT_CLASSES:
      return appendTableContent<ObjectClass, typeof APPEND_OBJECT_CLASSES>(
        state,
        action
      );
    case SET_OBJECT_CLASSES_FETCHING:
      return setTableLoading<ObjectClass, typeof SET_OBJECT_CLASSES_FETCHING>(
        state,
        action
      );

    case RESET_OBJECT_CLASSES:
      return resetTable<ObjectClass>(state, initialState);

    case RESET_OBJECT_CLASSES_COLUMNS:
      return resetTableColumns<ObjectClass>(state, initialState);

    case SET_OBJECT_CLASSES_COLUMNS:
      return setTableColumns<ObjectClass, typeof SET_OBJECT_CLASSES_COLUMNS>(
        state,
        action
      );
    case SET_OBJECT_CLASSES_SELECTED_ROW:
      return setSelectedRow<
        ObjectClass,
        typeof SET_OBJECT_CLASSES_SELECTED_ROW
      >(state, action);
    case SET_OBJECT_CLASSES_RESTRICTIONS:
      return { ...state, restrictions: action.restrictions };

    case SET_OBJECT_CLASSES_RESPONSE: {
      return { ...state, response: { ...state.response, ...action.payload } };
    }
    default:
      return state;
  }
};

export default ObjectClassesReducer;
