import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useOptionAvatarStyles = createUseStyles((theme: ThemeState) => {
  return {
    bottomText: {
      display: 'grid',
      gridTemplateColumns: 'minmax(10px, 1fr) max-content',
      justifyContent: 'space-between',
      fontSize: 12,
      maxWidth: '100%',
      color: theme['secondary-color'],
    },
    highlightText: {
      fontSize: 12,
      fontWeight: 700,
      color: theme['primary-color'],
    },
    textWrapper: {
      maxWidth: '100%',
      overflow: 'hidden',
      width: '100%',
    },
    disabledText: {
      maxWidth: '100%',
      marginLeft: 5,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: theme['disabled-switch-gray'],
    },
    disabledBottomText: { color: theme['disabled-switch-gray'] },
  };
});

export default useOptionAvatarStyles;
