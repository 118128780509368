import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import TablesType from 'utils/Enums/TablesType';

const useEmptyTableMessage = (currentTable: TablesType | undefined) => {
  const intl = useIntl();

  const messages: MappedObject<string, TablesType> = useMemo(
    () => ({
      [TablesType.TaskTemplates]: intl.formatMessage({
        id: 'misc.noTaskTemplatesFound',
        defaultMessage: 'No task templates found',
      }),
      [TablesType.ObjectClasses]: intl.formatMessage({
        id: 'misc.noObjectClassesFound',
        defaultMessage: 'No object classes found',
      }),
      [TablesType.ObjectClassesFields]: intl.formatMessage({
        id: 'misc.noObjectClassesFieldsFound',
        defaultMessage: 'No object classes fields found',
      }),
      [TablesType.ObjectRecords]: intl.formatMessage({
        id: 'misc.noObjectRecordsFound',
        defaultMessage: 'No object records found',
      }),
      [TablesType.Tasks]: intl.formatMessage({
        id: 'misc.noTasksFound',
        defaultMessage: 'No tasks found',
      }),
      [TablesType.Sequences]: intl.formatMessage({
        id: 'misc.noSequencesFound',
        defaultMessage: 'No sequences found',
      }),
      [TablesType.TaskGroups]: intl.formatMessage({
        id: 'misc.noTaskGroupTemplatesFound',
        defaultMessage: 'No task group templates found',
      }),
      [TablesType.UserGroups]: intl.formatMessage({
        id: 'misc.noUserGroupsFound',
        defaultMessage: 'No user groups found',
      }),
      [TablesType.Users]: intl.formatMessage({
        id: 'misc.noUsersFound',
        defaultMessage: 'No users found',
      }),
      [TablesType.Roles]: intl.formatMessage({
        id: 'misc.noRolesFound',
        defaultMessage: 'No roles found',
      }),
      [TablesType.Forms]: intl.formatMessage({
        id: 'misc.noFormsFound',
        defaultMessage: 'No forms found',
      }),
      [TablesType.MessageTemplates]: intl.formatMessage({
        id: 'misc.noMessageTemplatesFound',
        defaultMessage: 'No message templates found',
      }),
      [TablesType.EmailCollectors]: intl.formatMessage({
        id: 'misc.noEmailCollectorsFound',
        defaultMessage: 'No email collectors found',
      }),
      [TablesType.SingleSignOn]: intl.formatMessage({
        id: 'misc.noSingleSignOnFound',
        defaultMessage: 'No single sign-on found',
      }),
      [TablesType.AuthenticationObjects]: intl.formatMessage({
        id: 'misc.noAuthenticationObjectsFound',
        defaultMessage: 'No authentication objects found',
      }),
      [TablesType.Members]: intl.formatMessage({
        id: 'misc.noResults',
        defaultMessage: 'No results',
      }),
      [TablesType.ClassForms]: intl.formatMessage({
        id: 'misc.noFormsFound',
        defaultMessage: 'No forms found',
      }),
      [TablesType.DocumentTemplates]: intl.formatMessage({
        id: 'misc.noDocumentTemplatesFound',
        defaultMessage: 'No document templates found',
      }),
      [TablesType.DocumentTemplatesPanel]: intl.formatMessage({
        id: 'misc.noDocumentTemplatesFound',
        defaultMessage: 'No document templates found',
      }),
      [TablesType.NestedTable]: intl.formatMessage({
        id: 'misc.noObjectRecordsFound',
        defaultMessage: 'No object records found',
      }),
      [TablesType.CustomComponents]: intl.formatMessage({
        id: 'misc.noCustomComponentsFound',
        defaultMessage: 'No elements found',
      }),
    }),
    [intl]
  );

  const commonMessage = intl.formatMessage({
    id: 'misc.noItemsFound',
    defaultMessage: 'No items found',
  });

  if (currentTable) {
    return messages[currentTable] || commonMessage;
  }

  return commonMessage;
};

export default useEmptyTableMessage;
