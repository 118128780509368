import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useChildClassWidgetNoPermissionStyles = createUseStyles(
  (theme: ThemeState) => ({
    inputLabel: {
      textAlign: 'justify',
      paddingRight: 5,
      fontSize: 12,
    },
    container: {
      overflowX: 'auto',
      padding: 10,
      fontSize: 12,
      maxWidth: 700,
      borderColor: theme['border-color-base'],
      border: '1px solid',
      backgroundColor: theme['white-color'],
    },
  })
);
