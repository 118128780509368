import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { TagTypes } from './enums';

const useTagContainerStyles = createUseStyles((theme: ThemeState) => {
  return {
    tag: {
      fontWeight: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [TagTypes.Primary]: {
      borderColor: theme['primary-tag-bg-color'],
      backgroundColor: theme['primary-tag-bg-color'],
      color: theme['primary-tag-font-color'],
    },
    [TagTypes.Secondary]: {
      borderColor: theme['secondary-tag-bg-color'],
      backgroundColor: theme['secondary-tag-bg-color'],
      color: theme['secondary-tag-font-color'],
    },
    [TagTypes.Tertiary]: {
      borderColor: theme['tertiary-tag-bg-color'],
      backgroundColor: theme['tertiary-tag-bg-color'],
      color: theme['tertiary-tag-font-color'],
    },
    [TagTypes.Error]: {
      borderColor: theme['error-bg-light'],
      backgroundColor: theme['error-bg-light'],
      color: theme['error-color'],
    },
    tagContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
  };
});

export default useTagContainerStyles;
