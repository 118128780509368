import {
  ViewUsageConfig,
  ViewUsageDisplayType,
} from 'components/ViewUsage/types';
import useData from 'hooks/useData';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { OBJECT_CLASS_USAGE } from 'utils/endpoints';
import { ObjectClassUsage } from 'utils/types/api/objectClasses.types';
import { ObjectClassCountObjectRecords } from 'utils/types/api/objectClassesFields.types';

const useObjectClassViewUsage = (id?: string) => {
  const intl = useIntl();
  const {
    loading,
    error,
    data: {
      num_of_records: numOfRecords = 0,
      num_of_sequences: numOfSequences = 0,
      num_of_task_group_templates: numOfTaskGroupTemplates = 0,
      num_of_task_templates: numOfTaskTemplates = [],
      sequences = [],
      object_models: relations = [],
      task_group_templates: taskGroupTemplates = [],
      task_templates: taskTemplates = [],
    } = {},
  } = useObjectClassViewUsageData(id);

  const objectRecords =
    !!numOfRecords ||
    !!numOfSequences ||
    !!numOfTaskGroupTemplates ||
    !!numOfTaskTemplates
      ? [
          {
            num_of_records: numOfRecords,
          } as ObjectClassCountObjectRecords,
        ]
      : [];

  const displayConfig = useMemo<ViewUsageConfig[]>(
    () => [
      {
        usage: objectRecords,
        text: intl.formatMessage({
          id: 'misc.objectRecords',
          defaultMessage: 'Object records',
        }),
        type: ViewUsageDisplayType.CountOfRecords,
      },
      {
        usage: taskTemplates,
        text: intl.formatMessage({
          id: 'misc.taskTemplates',
          defaultMessage: 'Task templates',
        }),
      },
      {
        usage: taskGroupTemplates,
        text: intl.formatMessage({
          id: 'misc.taskGroups',
          defaultMessage: 'Task group templates',
        }),
      },
      {
        usage: sequences,
        text: intl.formatMessage({
          id: 'misc.sequences',
          defaultMessage: 'Sequences',
        }),
      },
      {
        usage: relations,
        text: intl.formatMessage({
          id: 'misc.relations',
          defaultMessage: 'Relations',
        }),
      },
    ],
    [
      intl,
      objectRecords,
      sequences,
      taskGroupTemplates,
      taskTemplates,
      relations,
    ]
  );

  return { loading, error, displayConfig };
};

const useObjectClassViewUsageData = (id?: string) => {
  const [data, { loading, error, fetchData }] = useData<ObjectClassUsage>(
    id !== undefined
      ? generatePath(OBJECT_CLASS_USAGE, {
          id,
        })
      : '',
    {
      fetchOnLoad: false,
    }
  );

  useEffect(() => {
    if (id !== undefined) fetchData();
  }, [fetchData, id]);

  return { error, loading, data };
};

export default useObjectClassViewUsage;
