import React, { useCallback, useRef } from 'react';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { Loader } from 'components/lib/Loader';
import useTaskListItemStyles from './styles';
import { useRecordTasks } from './hooks/useRecordTasks';
import ErrorComponent from 'components/ErrorComponent';
import { GetResponse } from 'utils/types';
import { Task } from 'utils/types/api/tasks.types';
import { FormattedMessage, useIntl } from 'react-intl';
import List from 'components/List';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SCROLLABLE_TASKS_LIST_ID } from 'utils/elementsIds';
import {
  CREATE_TASK_BUTTON,
  TASKS_LIST_COUNTER,
  TASKS_LIST_IDENTIFIER,
} from 'utils/testIds';
import TaskListItem from './TaskListItem';
import DisabledButtonTooltip from 'components/DisabledButtonTooltip';
import { ButtonPrimary } from 'components/lib/Button';
import { PlusIcon } from 'components/Icon';
import { useTasksPanelContext } from '../TasksPanelModeContext';
import { TasksPanelMode } from '../types';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedTask } from 'store/selectors/taskSelectors';
import { useConfirmationModalContext } from 'contexts/ConfirmationModalContext';
import { clearSelectedTask } from 'store/actions/taskActions';
import { TASK_COMPLETE_FORM_MODAL_ID } from 'components/MultiPageComponents/consts';
import TooltipString from 'components/TooltipString';

const TasksList = () => {
  const intl = useIntl();
  const {
    selectedResource: {
      record: {
        recordId = undefined,
        identifier = '',
        permissions: { tasks: { create: canCreateTask = false } = {} } = {},
      } = {},
    } = {},
  } = useSelectedResourceContext();
  const {
    setShouldBeDisplayed,
    setStoredModalFunctions,
    shouldBeDisplayed,
  } = useConfirmationModalContext();
  const classes = useTaskListItemStyles({ canCreateTask });
  const {
    loading,
    error,
    data: {
      filtered_count: filteredCount = 0,
      results,
      next: nextPageUrl,
    } = {} as GetResponse<Task>,
    fetchNextPage,
    fetchData,
  } = useRecordTasks(recordId);
  const { setMode } = useTasksPanelContext();
  const selectedTask = useSelector(getSelectedTask);
  const dispatch = useDispatch();
  const tasksListRef = useRef<HTMLDivElement>(null);

  const switchToCreateMode = useCallback(() => {
    if (shouldBeDisplayed?.[TASK_COMPLETE_FORM_MODAL_ID]) {
      setStoredModalFunctions(TASK_COMPLETE_FORM_MODAL_ID, {
        onSubmit: () => {
          setStoredModalFunctions(TASK_COMPLETE_FORM_MODAL_ID, {});
          dispatch(clearSelectedTask());
          setMode(TasksPanelMode.Create);
          setShouldBeDisplayed(TASK_COMPLETE_FORM_MODAL_ID, false);
        },
        callback: () =>
          setStoredModalFunctions(TASK_COMPLETE_FORM_MODAL_ID, {}),
      });
    } else {
      setMode(TasksPanelMode.Create);
    }
  }, [
    setMode,
    setShouldBeDisplayed,
    setStoredModalFunctions,
    shouldBeDisplayed,
    dispatch,
  ]);

  if (error) return <ErrorComponent error={error?.status} />;

  return (
    <Loader spinning={loading}>
      <div className={classes.panelWrapper}>
        <div className={classes.headerWrapper}>
          <TooltipString
            text={identifier}
            className={classes.identifier}
            testId={TASKS_LIST_IDENTIFIER}
          >
            {identifier}
          </TooltipString>

          {canCreateTask && (
            <DisabledButtonTooltip title={''} showTooltip={false}>
              <ButtonPrimary
                onClick={switchToCreateMode}
                icon={<PlusIcon size={9} />}
                data-testid={CREATE_TASK_BUTTON}
                className={classes.button}
              >
                <FormattedMessage
                  id='tasksPanel.createTask'
                  defaultMessage='Create task'
                />
              </ButtonPrimary>
            </DisabledButtonTooltip>
          )}
        </div>
        {!!results?.length && (
          <div className={classes.resultsInfo} data-testid={TASKS_LIST_COUNTER}>
            <FormattedMessage
              id='tasksPanel.tasksCount'
              defaultMessage='{count, plural, other {# tasks} one {# task}}'
              values={{ count: filteredCount }}
            />
          </div>
        )}
        <div
          id={SCROLLABLE_TASKS_LIST_ID}
          className={classes.infiniteListWrapper}
          ref={tasksListRef}
        >
          <InfiniteScroll
            dataLength={results?.length || 0}
            next={fetchNextPage}
            hasMore={!!nextPageUrl}
            loader={null}
            scrollableTarget={SCROLLABLE_TASKS_LIST_ID}
          >
            <List<Task>
              shouldShowEmptyDataTitle
              emptyDataDescription={intl.formatMessage({
                id: 'tasksPanel.noTasksAvailable',
                defaultMessage: 'No tasks available.',
              })}
              items={results}
              listItemElementWrapperClassName={classes.listElementWrapper}
              renderItem={task => (
                <TaskListItem
                  {...{ task, tasksListRef }}
                  isSelected={task.id.toString() === selectedTask?.id}
                  refetchTasksList={fetchData}
                />
              )}
            />
          </InfiniteScroll>
        </div>
      </div>
    </Loader>
  );
};

export default TasksList;
