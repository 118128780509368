import React, { useEffect } from 'react';
import { InPlaceEditCheckboxProps } from './types';
import useInPlaceEdit from '../useInPlaceEdit';
import InPlaceEditWrapper from '../components/InPlaceEditWrapper';
import { Checkbox } from 'components/lib/Checkbox';
import useInPlaceEditCheckboxStyles from './styles';
import { IN_PLACE_EDIT_CHECKBOX } from 'utils/testIds';
import Tooltip from 'components/lib/Tooltip';
import { useIntl } from 'react-intl';

const InPlaceEditCheckbox = <R extends Object>({
  label,
  required,
  value,
  disabled,
  propertyName,
  patchUrl,
  onSaveSuccess,
  readOnly,
  size,
}: InPlaceEditCheckboxProps<R>) => {
  const styles = useInPlaceEditCheckboxStyles({
    readOnly: readOnly || disabled,
  });
  const {
    inputWrapperRef,
    isSaving,
    setIsSavingOn,
    setTempValue,
    tempValue,
  } = useInPlaceEdit({
    initialValue: value,
    patchUrl,
    propertyName,
    onSaveSuccess,
  });

  const intl = useIntl();

  const onClick = () => {
    if (readOnly || disabled) return;

    setTempValue(prevValue => !prevValue);
    setIsSavingOn();
  };

  useEffect(() => {
    setTempValue(value);
  }, [setTempValue, value]);

  const readOnlyMessage = intl.formatMessage({
    id: 'forms.readOnlyField',
    defaultMessage: 'This field is read-only',
  });

  return (
    <InPlaceEditWrapper
      {...{
        isSaving,
        label,
        required,
        disabled,
        inputWrapperRef,
        readOnly,
        size,
      }}
      isEditMode
      editContent={
        <Tooltip title={disabled ? readOnlyMessage : ''}>
          <Checkbox
            className={styles.checkbox}
            checked={!!tempValue}
            disabled={disabled || isSaving || (value && required)}
            {...{ onClick }}
            data-testid={IN_PLACE_EDIT_CHECKBOX}
          />
        </Tooltip>
      }
      maxWidth={100}
      rowAdditionalClassName={styles.checkboxRow}
    />
  );
};

export default InPlaceEditCheckbox;
