import React from 'react';
import { Col } from '../lib/Grid';
import { LogoIcon } from '../../components/Icon';
import useCardWrapperStyles from './styles';
import { CARD_WRAPPER_TESTID } from 'utils/testIds';
import { FallbackLogoOptions } from 'components/Icon/types';

const CardWrapper: React.FC = ({ children }) => {
  const classes = useCardWrapperStyles({});

  return (
    <div className={classes.container}>
      <Col>
        <div className={classes.card} data-testid={CARD_WRAPPER_TESTID}>
          <LogoIcon
            width={150}
            size={150}
            fallbackLogo={FallbackLogoOptions.NORMAL}
            className={classes.logo}
          />
          {children}
        </div>
      </Col>
    </div>
  );
};

export default CardWrapper;
