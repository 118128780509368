import React, { useState, Fragment } from 'react';
import moment, { Moment } from 'moment';
import useTimeWidgetStyles from './styles';
import { TimePicker } from 'components/lib/TimePicker';
import { WidgetProps } from 'alx-dynamic-form';
import useFormPreview2Container from 'components/FormPreview2/hooks/useFormPrevievContainer';

const TimeWidget: React.FC<Partial<WidgetProps>> = ({
  disabled,
  onChange,
  value,
  reValidateField,
  fieldAlias,
}) => {
  const [componentKey, setComponentKey] = useState(new Date().toString());
  const getPopupContainer = useFormPreview2Container();
  const classes = useTimeWidgetStyles({});

  const handleChange = (nextValue: Moment | null) => {
    if (!onChange) return;

    onChange(nextValue?.format('HH:mm:ss') || null);

    if (reValidateField) reValidateField();

    setComponentKey(new Date().toString());
  };

  return (
    // adding key to Fragment it's temporary solution to force mount DatePickerInput, it helps to update method getPopupContainer inside DatePicker
    <Fragment key={componentKey}>
      <TimePicker
        data-testid={`time-picker-${fieldAlias}`}
        onChange={handleChange}
        className={classes.widget}
        value={!!value ? moment(value as string, 'HH:mm') : undefined}
        showSecond={false}
        onBlur={reValidateField}
        placeholder={''}
        {...{ getPopupContainer, disabled }}
      />
    </Fragment>
  );
};

export default TimeWidget;
