import { createUseStyles } from 'react-jss';

export const useDisabledSorterStyles = createUseStyles({
  upperArrow: {
    marginBottom: 3,
  },
  sorterWrapper: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
  },
});
