import React from 'react';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { Widget } from 'alx-dynamic-form';
import { mapCustomWidgetPropsToBaseInPlaceEditProps } from './utils';
import InPlaceEditUser from 'components/InPlaceEditControls/InPlaceEditUser';
import { EditUsers } from 'components/InPlaceEditControls/InPlaceEditUser/types';

const AdaptedInPlaceEditUser: Widget = widgetProps => {
  const baseInPlaceEditProps = mapCustomWidgetPropsToBaseInPlaceEditProps<
    EditUsers
  >(widgetProps);

  const { minUsers, maxUsers } = widgetProps;

  return (
    <Provider store={store}>
      <InPlaceEditUser
        {...baseInPlaceEditProps}
        minSelected={minUsers}
        maxSelected={maxUsers}
        meta={widgetProps.additionalFieldProps?.meta}
      />
    </Provider>
  );
};

export default AdaptedInPlaceEditUser;
