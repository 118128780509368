import { useState } from 'react';
import { Parameters } from '../../types';

export const useMultipleChoice = (
  parameters: Parameters,
  onChange: (newParams: Parameters) => void
) => {
  const enumArray = Array.isArray(parameters.enum) ? parameters.enum : [];
  const containsUnparsableString = enumArray.some((val: any) =>
    isNaN(val as number)
  );
  const containsString =
    containsUnparsableString ||
    enumArray.some((val: any) => typeof val === 'string');

  const [isNumber, setIsNumber] = useState(
    !!enumArray.length && !containsString
  );

  const onPossibleValuesChange = () => {
    if (Array.isArray(parameters.enumNames)) {
      // remove the enumNames
      onChange({
        ...parameters,
        enumNames: undefined,
      });
    } else {
      // create enumNames as a copy of enum values
      onChange({
        ...parameters,
        enumNames: enumArray.map(val => `${val}`),
      });
    }
  };

  const onForceNumberChange = () => {
    if (containsString || !isNumber) {
      // attempt converting enum values into numbers
      const newEnum = enumArray.map(val => parseFloat(val as string) || 0);
      setIsNumber(true);
      onChange({
        ...parameters,
        enum: newEnum,
      });
    } else {
      // convert enum values back into strings
      const newEnum = enumArray.map((val: any) => `${val || 0}`);
      setIsNumber(false);
      onChange({
        ...parameters,
        enum: newEnum,
      });
    }
  };

  return {
    onPossibleValuesChange,
    onForceNumberChange,
    containsUnparsableString,
    enumArray,
    containsString,
    isNumber,
    setIsNumber,
  };
};
