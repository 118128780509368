import { useCallback } from 'react';
import { generatePath } from 'react-router';
import useData from 'hooks/useData';
import useValidationSchemaBuilder from 'hooks/useValidationSchemaBuilder';
import { mapTaskOptionsToDictionary } from 'pages/TaskTemplates/utils';
import { TASKS_LIST, TASK_STAGES } from 'utils/endpoints';
import {
  MergedTaskSchema,
  TaskSchemaWithAutocomplete,
  TasksOptions,
  TaskStagesOptions,
  TaskStagesSchema,
} from './types';

export const useTaskOptions = () => {
  const dataTransformFn = useCallback(
    (data: TasksOptions) =>
      mapTaskOptionsToDictionary<TaskSchemaWithAutocomplete>(
        data.details.schema
      ),
    []
  );

  const dataTransformStagesOptionsFn = useCallback(
    (data: TaskStagesOptions) => {
      const stageOptions = {
        ...(data?.restrictions || {}),
        ...(data?.batch || {}),
      };

      return {
        stage1: stageOptions,
      };
    },
    []
  );

  const [options, { loading: optionsLoading, error: optionsError }] = useData<
    TaskSchemaWithAutocomplete
  >(TASKS_LIST, {
    requestType: 'options',
    dataTransformFn,
  });

  const [
    taskStagesOptions,
    { loading: taskStagesOptionsLoading, error: taskStagesOptionsError },
  ] = useData<TaskStagesSchema>(generatePath(TASK_STAGES, { id: 0 }), {
    requestType: 'options',
    dataTransformFn: dataTransformStagesOptionsFn,
  });

  const mergedTaskOptions = {
    ...(options || {}),
    ...(taskStagesOptions || {}),
  } as MergedTaskSchema;

  const {
    rawValidationSchema,
    buildValidationSchema,
  } = useValidationSchemaBuilder<MergedTaskSchema>(mergedTaskOptions);

  return {
    rawValidationSchema,
    buildValidationSchema,
    optionsLoading: optionsLoading || taskStagesOptionsLoading,
    optionsError: optionsError || taskStagesOptionsError,
    options: mergedTaskOptions,
  };
};
