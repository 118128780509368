/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import useTooltipStyles from './styles';
import { TooltipProps } from './types';

const typeMap = {
  alert: `fas fa-asterisk`,
  help: 'fa fa-question-circle',
};

const Tooltip: React.FC<TooltipProps> = ({ text, type, id }) => {
  const classes = useTooltipStyles({});

  return (
    <>
      <span className={classes.icon} {...{ id }}>
        <i className={typeMap[type]} />
      </span>
      <UncontrolledTooltip
        autohide={false}
        className={classes.toolTip}
        placement='top'
        target={id}
      >
        {text}
      </UncontrolledTooltip>
    </>
  );
};

export default Tooltip;
