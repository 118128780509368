import { RootState } from 'store/reducers';
import { StagesAssignees } from 'store/reducers/types/tasksAssignees.types';
import { StageNames } from 'utils/types/api/tasks.types';

export const getTaskStageAssignees = ({
  taskId,
  stageName,
}: {
  taskId: string;
  stageName: StageNames;
}) => (state: RootState) =>
  state.tasksAssignees[taskId].find(({ name }) => name === stageName);

export const getTaskAssignees = (taskId: string) => (state: RootState) =>
  state.tasksAssignees[taskId] as StagesAssignees | undefined;
