import { createUseStyles } from 'react-jss';

const useWrappersStyles = createUseStyles({
  contentWrapper: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'initial !important',
  },
  flexContentWrapper: {
    padding: '15px 15px 0 15px',
    height: '100%',
    '& .ant-empty': {
      marginTop: 30,
    },
  },
  spacingWrapper: { padding: '0 15px' },
});

export default useWrappersStyles;
