import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import { getViewerPanelData } from 'store/selectors/flexLayoutSelectors';
import { getSelectedTaskId } from 'store/selectors/taskSelectors';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';

export const useViewerPanelClosingBehaviour = () => {
  const dispatch = useDispatch();

  const panelData = useSelector(getViewerPanelData);
  const selectedTaskId = useSelector(getSelectedTaskId);

  const { pathname } = useLocation();
  const { closePanelIfOpen } = useFlexLayoutContext();
  const { selectedResource } = useSelectedResourceContext();

  const selectedResourceRecordId = useMemo(() => {
    return selectedResource?.record?.recordId;
  }, [selectedResource]);

  const initiallySelectedRecordId = useRef(selectedResourceRecordId);
  const initiallySelectedTaskId = useRef(selectedTaskId);

  const closeViewerPanel = useCallback(() => {
    closePanelIfOpen(FlexLayoutWindows.Viewer);
    dispatch(setSidebarData(FlexLayoutWindows.Viewer, {}));
  }, [closePanelIfOpen, dispatch]);

  /**
   * Closes the viewer panel when user refreshes a page.
   */
  useEffect(() => {
    if (panelData) {
      return;
    }

    closeViewerPanel();
  }, [panelData, closeViewerPanel]);

  /**
   * Closes the viewer panel when user selects or goes to a different record.
   */
  useEffect(() => {
    if (selectedResourceRecordId === initiallySelectedRecordId.current) {
      return;
    }

    closeViewerPanel();
  }, [selectedResourceRecordId, closeViewerPanel]);

  /**
   * Closes the viewer panel when user opens or closes a task completion flyout.
   */
  useEffect(() => {
    if (selectedTaskId === initiallySelectedTaskId.current) {
      return;
    }

    closeViewerPanel();
  }, [selectedTaskId, closeViewerPanel]);

  /**
   * Closes the viewer panel when user navigates to a different page.
   */
  useEffect(() => {
    const { originPathname } = panelData ?? {};

    if (pathname === originPathname) {
      return;
    }

    closeViewerPanel();
  }, [panelData, pathname, closeViewerPanel]);
};
