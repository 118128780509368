import { FB_FORM_MODAL_WRAPPER } from 'utils/elementsIds';

const useFormPreviewContainer = () => () => {
  const formElement = document.getElementById(FB_FORM_MODAL_WRAPPER);
  const formBoundingRect = formElement?.getBoundingClientRect();
  const bodyBoundingRect = document.body?.getBoundingClientRect();

  if (!formElement || !formBoundingRect || !bodyBoundingRect)
    return document.body;

  const { height: formHeight } = formBoundingRect;
  const { height: bodyHeight } = bodyBoundingRect;

  if (formHeight > bodyHeight * 0.75 - 183) return formElement;

  return document.body;
};

export default useFormPreviewContainer;
