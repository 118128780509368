import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import WarningIllustration from 'img/illustrations/warning-triangle.svg';
import ConfirmationModal from 'components/ConfirmationModal';
import { CloseIcon } from 'components/Icon';
import { RemoveOwnerModalProps } from './types';

const RemoveOwnerModal = ({
  objectRecordId,
  ownerToDelete,
  isLoading,
  isRemovingHimself,
  setOwnerToDelete,
  removeObjectRecordOwner,
}: RemoveOwnerModalProps) => {
  const intl = useIntl();

  const title = useMemo(
    () =>
      isRemovingHimself
        ? intl.formatMessage({
            id: 'misc.sureToRemoveYourself',
            defaultMessage: 'Are you sure you want to remove yourself?',
          })
        : intl.formatMessage(
            {
              id: 'misc.sureToRemoveAnotherUser',
              defaultMessage: 'Are you sure you want to remove {name}?',
            },
            {
              name: ownerToDelete?.displayName,
            }
          ),
    [intl, isRemovingHimself, ownerToDelete]
  );

  const subtitle = useMemo(
    () =>
      isRemovingHimself
        ? intl.formatMessage({
            id: 'misc.thisMayRemoveYourAccessToObjectRecord',
            defaultMessage: 'This may remove your access to this record.',
          })
        : intl.formatMessage({
            id: 'misc.theyMayLoseAccessToObjectRecord',
            defaultMessage: 'This may remove their access to this record.',
          }),
    [intl, isRemovingHimself]
  );

  const onClose = () => {
    setOwnerToDelete(undefined);
  };

  const onConfirm = async () => {
    if (ownerToDelete && objectRecordId) {
      await removeObjectRecordOwner(objectRecordId, ownerToDelete?.ownershipId);
    }

    setOwnerToDelete(undefined);
  };

  return (
    <ConfirmationModal
      {...{ isLoading, onClose, onConfirm, title, subtitle }}
      visible={!!ownerToDelete}
      confirmationButtonLabel={intl.formatMessage({
        id: 'misc.yesRemove',
        defaultMessage: 'Yes, remove',
      })}
      withCancel
      image={WarningIllustration}
      cancelButtonIcon={<CloseIcon size={18} />}
    />
  );
};

export default RemoveOwnerModal;
