import React, { forwardRef } from 'react';
import { Input as AntInput } from 'antd';
import useInputStyles from '../Input/styles';
import useGlobalStyle from '../../../hooks/useGlobalStyle';
import onChangeTrimSpaces from '../../../utils/functions/onChangeTrimSpaces';
import clsx from 'clsx';
import { onChangeFormatStringToKey } from 'utils/functions/formatStringToKey';
import useCodeNameStyles from './styles';
import { AdditionalElementsProps, CodeNameProps } from './types';
import { DEFAULT_MAX_LENGTH } from './consts';
import { Field, useField } from 'formik';
import { FormikFormItem } from '../Form';
import {
  CODE_NAME_BASIC_ID,
  CODE_NAME_DETAILED_ID,
} from '../../../utils/elementsIds';

const CodeName = forwardRef<AntInput, CodeNameProps>(
  (
    {
      className,
      disabled,
      maxLength = DEFAULT_MAX_LENGTH,
      value,
      onChange,
      onBlur,
      withCounter,
      description,
      hideDescription,
      ...rest
    },
    ref
  ) => {
    const classes = useInputStyles({});
    const globalClasses = useGlobalStyle();

    const slicedValue = value?.toString()?.slice(0, maxLength);

    return (
      <>
        <AntInput
          {...{ ...rest, disabled, ref, maxLength }}
          value={slicedValue?.toLowerCase()}
          onChange={onChangeTrimSpaces(onChangeFormatStringToKey(onChange))}
          onBlur={onBlur}
          className={clsx([
            classes.input,
            className,
            { [globalClasses.disabledInput]: disabled },
          ])}
        />
        <AdditionalElements
          {...{
            value: value?.toString() || '',
            maxLength,
            withCounter,
            description,
            hideDescription,
          }}
        />
      </>
    );
  }
);

const FormikCodeNameInput = ({
  name,
  hideDescription,
  ...rest
}: CodeNameProps) => {
  const [, { touched, error }] = useField(name);

  return (
    <FormikFormItem
      {...{ name }}
      validateStatus={touched && error ? 'error' : undefined}
    >
      <Field
        {...{
          hideDescription: hideDescription || (touched && error),
          name,
          ...rest,
        }}
        as={CodeName}
      />
    </FormikFormItem>
  );
};

const AdditionalElements = ({
  description,
  withCounter,
  value,
  maxLength,
  hideDescription,
}: AdditionalElementsProps) => {
  const classes = useInputStyles({});
  const styles = useCodeNameStyles({ hide: hideDescription });

  return (
    <div
      className={clsx(styles.textAreaWrapper, {
        [styles.onlyCounter]: !description,
      })}
      id={hideDescription ? CODE_NAME_BASIC_ID : CODE_NAME_DETAILED_ID}
    >
      {!!description && (
        <span className={clsx([styles.description, styles.descriptionGrow])}>
          {description}
        </span>
      )}
      {withCounter && !!maxLength ? (
        <span className={classes.counter}>{`${
          value?.length || 0
        } / ${maxLength}`}</span>
      ) : null}
    </div>
  );
};

export { CodeName, FormikCodeNameInput };
