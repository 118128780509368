import React, { createContext, useCallback, useContext, useState } from 'react';
import noop from 'lodash/noop';

export interface FileUploadContextProps {
  uploadingFiles: string[];
  updateUploadingFiles: (id: string) => void;
}

const FileUploadContext = createContext<FileUploadContextProps>({
  uploadingFiles: [],
  updateUploadingFiles: noop,
});

const FileUploadContextProvider = FileUploadContext.Provider;
export const useFileUploadContext = () => useContext(FileUploadContext);

export const FileUploadContextProviderWrapper: React.FC = ({ children }) => {
  const [uploadingFiles, setUploadingFiles] = useState<string[]>([]);

  const updateUploadingFiles = useCallback((id: string) => {
    setUploadingFiles(prevValue => {
      if (prevValue.includes(id))
        return prevValue.filter(fileId => fileId !== id);

      return [...prevValue, id];
    });
  }, []);

  return (
    <FileUploadContextProvider
      value={{
        updateUploadingFiles,
        uploadingFiles,
      }}
    >
      {children}
    </FileUploadContextProvider>
  );
};
