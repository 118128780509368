import { createUseStyles } from 'react-jss';
import defaultTheme from 'utils/defaultTheme';

const theme = defaultTheme

const useForgotPasswordFormStyles = createUseStyles({
  card: {
    borderRadius: 5,
    boxShadow: '0px 4px 50px rgba(46, 63, 87, 0.19)',
    padding: [20, 60, 30],
    maxWidth: 500,
    margin: 'auto',
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
    margin: [30, 0, 6],
    lineHeight: '34.5px',
    fontWeight: 700,
  },
  subtitle: {
    fontSize: 12,
    margin: 0,
    lineHeight: '18px',
    color: theme['text-color'],
    opacity: 0.6,
    textAlign: 'center',
  },
  input: {
    marginTop: 49,
  },
  buttonWrapper: {
    display: 'flex',
    marginTop: 64,
    justifyContent: 'flex-end',
  },
  description: {
    fontSize: 12,
    marginTop: -13,
    textAlign: 'justify',
    fontWeight: 400,
    color: theme['disabled-color'],
    display: 'block',
    wordBreak: 'break-word',
  },
  iconsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gridGap: 50,
  },
  errorIcon: {
    width: 86,
    height: 86,
  },
  logo: {
    height: 50,
  },
});

export default useForgotPasswordFormStyles;
