import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useManageAssigneesTabsStyles = createUseStyles(
  (theme: ThemeState) => {
    return {
      name: {
        fontWeight: '700',
        fontSize: 16,
        marginTop: 17,
        marginLeft: 17,
        margin: 17,
      },
      contentWrappper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& .ant-tabs': {
          flex: 1,
        },
        '& .ant-tabs-content': {
          height: '100%',
        },
      },
      tabs: {
        '& .ant-tabs-tab': {
          paddingLeft: 20,
          paddingRight: 20,
          '& .ant-tabs-tab-btn': {
            color: theme['disabled-color'],
          },
          '&.ant-tabs-tab-active .ant-tabs-tab-btn': {
            fontWeight: 'bold',
            color: theme['primary-color'],
          },
        },
      },
    };
  }
);
