import React from 'react';
import { FormattedMessage } from 'react-intl';
import useErrorLabelStyles from './styles';
import { ErrorLabelProps } from './types';
import clsx from 'clsx';

const ErrorLabel = ({
  asteriskIsSpan = false,
  asteriskClassName,
}: ErrorLabelProps) => {
  const classes = useErrorLabelStyles({});
  const AsteriskTag = asteriskIsSpan ? 'span' : 'sup';

  return (
    <span className={classes.wrapperSpan}>
      <span className={classes.innerWrapperSpan}>
        <AsteriskTag
          className={clsx([classes.requiredAsterisk, asteriskClassName])}
        >
          *
        </AsteriskTag>
        <span className={classes.requiredLabel} data-small-column-hide='true'>
          (
          <FormattedMessage id='misc.required' defaultMessage='required' />)
        </span>
      </span>
    </span>
  );
};

export default ErrorLabel;
