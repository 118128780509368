export const SET_TASK_TEMPLATES = 'SET_TASK_TEMPLATES';
export const APPEND_TASK_TEMPLATES = 'APPEND_TASK_TEMPLATES';
export const SET_TASK_TEMPLATES_FETCHING = 'SET_TASK_TEMPLATES_FETCHING';
export const SET_TOTAL_TASK_TEMPLATES = 'SET_TOTAL_TASK_TEMPLATES';
export const RESET_TASK_TEMPLATES = 'RESET_TASK_TEMPLATES';
export const SET_TASK_TEMPLATES_COLUMNS = 'SET_TASK_TEMPLATES_COLUMNS';
export const RESET_TASK_TEMPLATES_COLUMNS = 'RESET_TASK_TEMPLATES_COLUMNS';
export const SET_TASK_TEMPLATES_SELECTED_ROW =
  'SET_TASK_TEMPLATES_SELECTED_ROW';
export const SET_TASK_TEMPLATES_RESTRICTIONS =
  'SET_TASK_TEMPLATES_RESTRICTIONS';
