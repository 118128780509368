import { toast } from 'components/lib/toast';
import { useIntl } from 'react-intl';
import ToastType from 'utils/Enums/ToastType';

const useDefaultValueResetToast = () => {
  const intl = useIntl();

  return () => {
    toast(
      {
        title: intl.formatMessage({
          id: 'misc.warning',
          defaultMessage: 'Warning!',
        }),
        subtitle: intl.formatMessage({
          id: 'formBuilder.defaultValueReset',
          defaultMessage:
            'Default value has been unset because it does not match the min/max range.',
        }),
      },
      ToastType.Warning
    );
  };
};

export default useDefaultValueResetToast;
