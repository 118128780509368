import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AvatarItem } from 'components/lib/Avatar/types';
import {
  DefaultAssigneesListProps,
  SequenceTaskDefaultAssigneesPanelModes,
} from '../types';
import PeopleListElement from 'pages/Records/RecordsListing/RecordAccessPanel/components/PeopleListElement';
import List from 'components/List';
import { ButtonPrimary, ButtonSecondaryOutlined } from 'components/lib/Button';
import { DefaultSequenceTaskAssignees } from 'utils/types/api/sequences.types';
import { useDispatch, useSelector } from 'react-redux';
import { getSequenceDefaultTaskAssignees } from 'store/selectors/sequencesSelectors';
import useDefaultAssigneesStyles from './styles';
import {
  ADD_DEFAULT_TASK_ASSIGNEES,
  ASSIGNEES_COUNT_ID,
  BUTTON_CANCEL_TESTID,
} from 'utils/testIds';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import LimitMessage from 'pages/ObjectClasses/components/ObjectClassForm/ObjectClassPermissions/components/LimitMessage';
import { StageNames } from 'utils/types/api/tasks.types';
import { useSequenceDefaultTaskAssigneesOptions } from '../../SequenceForm/hooks';
import { selectLimitForUserGroup } from 'pages/Records/RecordsListing/TasksPanel/components/ReassignTask/utils';
import Tooltip from 'components/lib/Tooltip';
import { CheckIcon } from 'components/Icon';
import ConfirmationModal from 'components/ConfirmationModal';
import WarningIllustration from 'img/illustrations/warning-triangle.svg';
import { useParams } from 'react-router';
import { setDefaultTaskAssignees } from 'store/actions/sequencesActions';
import { apiCall } from 'utils/api';
import { SEQUENCE_STAGE_ASSIGNEES } from 'utils/endpoints';
import { generatePath } from 'react-router-dom';
import { toast } from 'components/lib/toast';
import showDefaultErrorToast from 'utils/functions/showDefaultErrorToast';
import { StatusCodes } from 'http-status-codes';
import { AxiosError } from 'axios';
import { useAutoSaveErrorModalContext } from 'contexts/AutoSaveErrorModalContext';
import { sortAvatarsAlphabetically } from 'utils/sortAvatars';

const DefaultAssigneesList: React.FC<DefaultAssigneesListProps> = ({
  setMode,
}) => {
  const classes = useDefaultAssigneesStyles({});
  const { showErrorModal } = useAutoSaveErrorModalContext();

  const intl = useIntl();
  const { results = [] } =
    useSelector(getSequenceDefaultTaskAssignees) ||
    ({} as DefaultSequenceTaskAssignees);
  const {
    globalMethods: { closeComponent },
  } = useFlexLayoutContext();
  const stageId = StageNames.Stage1; //when more stages come this should be fetched based on currently selected one
  const {
    defaultTaskAssigneesOptions: {
      [stageId]: {
        limit_assignees_one_time_completion: oneTCLimit = undefined,
        limit_assignees_standard: standardLimit = undefined,
      } = {},
    } = {},
  } = useSequenceDefaultTaskAssigneesOptions();

  const itemsLimit =
    selectLimitForUserGroup(results, oneTCLimit, standardLimit) ?? 100;
  const isLimitAchieved = results.length >= itemsLimit;
  const dispatch = useDispatch();
  const { id: sequenceId } = useParams<{ id: string }>();
  const [assigneeToRemove, setAssigneeToRemove] = useState<
    undefined | AvatarItem
  >();

  const handleDeleteAssignee = async (item: AvatarItem) => {
    // When in create mode everything is changed without api call
    if (sequenceId) {
      try {
        await apiCall.delete(
          generatePath(SEQUENCE_STAGE_ASSIGNEES, {
            stageId,
            id: sequenceId,
          }),
          { data: { users: [item.id] } }
        );

        toast({
          title: intl.formatMessage({
            id: 'misc.success',
            defaultMessage: 'Success!',
          }),
          subtitle: intl.formatMessage({
            id: 'sequences.defaultAssignees.removeAssignee',
            defaultMessage: '1 default assignee has been removed.',
          }),
        });
      } catch (error) {
        if (
          (error as AxiosError)?.response?.status === StatusCodes.FORBIDDEN &&
          showErrorModal
        ) {
          showErrorModal(error as AxiosError);
          return;
        }
        showDefaultErrorToast();
        return;
      }
    }

    setAssigneeToRemove(undefined);
    dispatch(
      setDefaultTaskAssignees(results.filter(({ id }) => item.id !== id))
    );
  };

  const sortedResults = useMemo(() => sortAvatarsAlphabetically(results), [
    results,
  ]);

  return (
    <div className={classes.panelWrapper}>
      <div>
        <div className={classes.headerWrapper}>
          <p className={classes.resultsInfo} data-testid={ASSIGNEES_COUNT_ID}>
            <FormattedMessage
              id='sequences.defaultAssignees.usersCount'
              defaultMessage='{count} default task {count, plural, one {assignee} other {assignees}}'
              values={{ count: results?.length }}
            />
          </p>
          <Tooltip
            placement='topRight'
            shouldBeRendered={isLimitAchieved}
            title={intl.formatMessage({
              id: 'sequences.defaultAssignees.maximumNumberReached',
              defaultMessage:
                'The maximum number of assignees has been reached. Remove assignees to add new ones.',
            })}
          >
            <div>
              <ButtonPrimary
                onClick={() =>
                  setMode(SequenceTaskDefaultAssigneesPanelModes.AddAssignees)
                }
                disabled={isLimitAchieved}
                data-testid={ADD_DEFAULT_TASK_ASSIGNEES}
              >
                <FormattedMessage
                  id='sequences.defaultAssignees.addAssignees'
                  defaultMessage='Add default assignees'
                />
              </ButtonPrimary>
            </div>
          </Tooltip>
        </div>
        <LimitMessage
          {...{
            count: results.length,
            itemsLimit,
            customWarningMessage: intl.formatMessage(
              {
                id: 'sequences.defaultAssignees.limitWarningMessage',
                defaultMessage:
                  'A sequence can have up to {limit} default task {limit, plural, one {assignee} other {assignees}}. This currently has {count}.',
              },
              { limit: itemsLimit, count: results.length }
            ),
            customErrorMessage: intl.formatMessage(
              {
                id: 'sequences.defaultAssignees.limitErrorMessage',
                defaultMessage:
                  'Maximum of {limit} default task {limit, plural, one {assignee} other {assignees}} has been reached.',
              },
              { limit: itemsLimit }
            ),
          }}
        />
        <List<AvatarItem>
          emptyDataDescription={intl.formatMessage({
            id: 'sequences.defaultAssignees.noDefaultTaskAssigneesAdded',
            defaultMessage: 'No default task assignees have been added.',
          })}
          items={sortedResults}
          renderItem={item => (
            <PeopleListElement
              {...item}
              onDelete={() => setAssigneeToRemove(item)}
            />
          )}
        />
      </div>
      <ButtonSecondaryOutlined
        className={classes.cancelButton}
        onClick={() =>
          closeComponent(FlexLayoutWindows.SequenceDefaultAssignees)
        }
        data-testid={BUTTON_CANCEL_TESTID}
      >
        <FormattedMessage id='misc.cancel' defaultMessage='Cancel' />
      </ButtonSecondaryOutlined>
      <ConfirmationModal
        title={intl.formatMessage(
          {
            id: 'sequences.defaultAssignees.removeAssigneeConfirmationTitle',
            defaultMessage: 'Are you sure you want to remove {name}?',
          },
          {
            name: `${assigneeToRemove?.firstName} ${assigneeToRemove?.lastName}`,
          }
        )}
        subtitle={intl.formatMessage({
          id: 'sequences.defaultAssignees.removeAssigneeConfirmationSubtitle',
          defaultMessage:
            'They will not be assigned to the tasks created in this sequence.',
        })}
        image={WarningIllustration}
        confirmationButtonIcon={<CheckIcon size={12} />}
        confirmationButtonLabel={intl.formatMessage({
          id: 'misc.yesRemove',
          defaultMessage: 'Yes, remove',
        })}
        withCancel
        onClose={() => setAssigneeToRemove(undefined)}
        onConfirm={() =>
          !!assigneeToRemove && handleDeleteAssignee(assigneeToRemove)
        }
        visible={!!assigneeToRemove}
      />
    </div>
  );
};

export default DefaultAssigneesList;
