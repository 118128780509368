import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  SET_SEQUENCES,
  SET_SEQUENCES_FETCHING,
  APPEND_SEQUENCES,
  RESET_SEQUENCES,
  SET_SEQUENCES_COLUMNS,
  RESET_SEQUENCES_COLUMNS,
  SET_SEQUENCES_SELECTED_ROW,
  SET_SEQUENCES_RESTRICTIONS,
  SET_SEQUENCE_DEFAULT_TASK_ASSIGNEES,
  SET_SEQUENCE_DEFAULT_TASK_ASSIGNEES_LOADING,
} from 'store/constants/sequences.consts';
import {
  appendTableContent,
  resetTable,
  setTableContent,
  setTableLoading,
  resetTableColumns,
  setTableColumns,
  setSelectedRow,
} from './tablesCommonStateModificators';
import {
  Sequence,
  SequencesReducerState,
} from 'utils/types/api/sequences.types';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import cloneDeep from 'lodash/cloneDeep';

const initialState = cloneDeep({
  ...INITIAL_TABLE_STATE,
  defaultTaskAssignees: undefined,
});

const sequencesReducer: Reducer<SequencesReducerState, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_SEQUENCES: {
      return setTableContent<Sequence, typeof SET_SEQUENCES>(state, action);
    }
    case APPEND_SEQUENCES:
      return appendTableContent<Sequence, typeof APPEND_SEQUENCES>(
        state,
        action
      );
    case SET_SEQUENCES_FETCHING:
      return setTableLoading<Sequence, typeof SET_SEQUENCES_FETCHING>(
        state,
        action
      );

    case RESET_SEQUENCES:
      return resetTable<Sequence>(state, initialState);

    case RESET_SEQUENCES_COLUMNS:
      return resetTableColumns<Sequence>(state, initialState);

    case SET_SEQUENCES_COLUMNS:
      return setTableColumns<Sequence, typeof SET_SEQUENCES_COLUMNS>(
        state,
        action
      );
    case SET_SEQUENCES_SELECTED_ROW:
      return setSelectedRow<Sequence, typeof SET_SEQUENCES_SELECTED_ROW>(
        state,
        action
      );
    case SET_SEQUENCES_RESTRICTIONS:
      return { ...state, restrictions: action.restrictions };
    case SET_SEQUENCE_DEFAULT_TASK_ASSIGNEES:
      return {
        ...state,
        defaultTaskAssignees: {
          ...state.defaultTaskAssignees,
          results: action.data,
        },
      };
    case SET_SEQUENCE_DEFAULT_TASK_ASSIGNEES_LOADING:
      return {
        ...state,
        defaultTaskAssignees: {
          ...state.defaultTaskAssignees,
          loading: action.data,
        },
      };
    default:
      return state;
  }
};

export default sequencesReducer;
