import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import useData from 'hooks/useData';
import { useCallback, useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import {
  OBJECT_CLASS_RECORD_PERMISSION_SET_ASSIGNEES,
  OBJECT_RECORD_OWNERS,
} from 'utils/endpoints';
import { RecordOwner } from 'utils/types/api/objectRecords.types';
import { PermissionSetSelectOptions } from '../RecordOwners/types';
import { UserType } from './types';

const usePermissionSetAssignees = (permissionSetId?: number) => {
  const {
    selectedResource: { record: { recordId = '0' } = {} } = {},
  } = useSelectedResourceContext();

  const dataTransformFn = useCallback(
    ({ results }: { results: RecordOwner[] }) =>
      results.map(({ user }) => user),
    []
  );

  const [permissionSetAssignees, { loading, error, fetchData }] = useData<
    UserType[]
  >('', {
    fetchOnLoad: false,
    dataTransformFn,
  });

  const fetchPersmissionSetAssignees = useCallback(() => {
    if (!permissionSetId) return;

    const url =
      permissionSetId === PermissionSetSelectOptions.Owners
        ? generatePath(OBJECT_RECORD_OWNERS, { id: recordId })
        : generatePath(OBJECT_CLASS_RECORD_PERMISSION_SET_ASSIGNEES, {
            recordId,
            permissionSetId,
          });

    fetchData(url);
  }, [permissionSetId, recordId, fetchData]);

  useEffect(() => {
    fetchPersmissionSetAssignees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionSetId, recordId]);

  return {
    permissionSetAssignees,
    loading,
    error,
    fetchPersmissionSetAssignees,
  };
};

export default usePermissionSetAssignees;
