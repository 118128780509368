import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown as AntDropdown } from 'antd';
import debounce from 'lodash/debounce';
import { ExtendedDropdownProps } from './types';

export const Dropdown: React.FC<ExtendedDropdownProps> = ({
  getPopupContainer,
  visible,
  remountChangeTarget,
  children,
  ...rest
}) => {
  const isMounted = useRef<boolean>();
  const [isContainerResizing, setIsContainerResizing] = useState(false);

  const debouncedFun = useCallback(
    debounce(() => {
      if (!isMounted.current) return;

      setIsContainerResizing(false);
    }, 100),
    []
  );

  useEffect(() => {
    isMounted.current = true;

    if (!remountChangeTarget || !visible) return;

    // @ts-ignore
    if (!window.ResizeObserver) return;

    // @ts-ignore
    const resizeObserver = new ResizeObserver(() => {
      setIsContainerResizing(true);
      debouncedFun();
    });

    resizeObserver.observe(remountChangeTarget());

    return () => {
      isMounted.current = false;

      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  if (isContainerResizing) return <>{children}</>;

  return (
    <AntDropdown
      {...{
        ...rest,
        children,
        getPopupContainer,
        ...(visible !== undefined ? { visible } : {}),
      }}
    />
  );
};
