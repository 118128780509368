import { CancelToken } from 'axios';
import { apiCall } from 'utils/api';

/**
 * Downloads document from the URL and returns it as ArrayBuffer.
 * @param fileUrl url of the file.
 * @param cancelToken optional axios cancel token to stop the download.
 * @returns ArrayBuffer of the downloaded file.
 */
export const downloadDocumentAsArrayBuffer = async (
  fileUrl: string,
  cancelToken: CancelToken
): Promise<ArrayBuffer> => {
  const path = new URL(fileUrl).pathname;
  const { data } = await apiCall.get(path, {
    responseType: 'arraybuffer',
    cancelToken: cancelToken,
  });

  return data;
};
