import { RootState } from 'store/reducers';
import { CustomComponent } from 'utils/types/api/customComponents.types';

export const selectCustomComponent = (state: RootState) =>
  state.customComponents.allIds.map(id => {
    const tableNameSingle = state.customComponents.byId[id];

    return {
      ...tableNameSingle,
      key: tableNameSingle.id,
    };
  });

export const totalCustomComponents = (state: RootState) => {
  return state.customComponents.total;
};

export const filteredCustomComponentsCount = (state: RootState) => {
  return state.customComponents.filtered;
};

export const getCustomComponentsColumns = (state: RootState) => {
  return state.customComponents.columns;
};

export const getCustomComponentsSelectedRow = (state: RootState) => {
  return state.customComponents?.selectedRow;
};

export const getCustomComponentsRow = (id: string | undefined) => (
  state: RootState
): CustomComponent => {
  if (!id) return {} as CustomComponent;

  return state.customComponents.byId[id];
};
