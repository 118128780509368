import React, { useContext } from 'react';

interface InternalWindowContextProps {
  internalWindowRef?: HTMLDivElement;
}

export const InternalWindowContext = React.createContext<
  InternalWindowContextProps
>({
  internalWindowRef: undefined,
});

interface InternalWindowContextProviderProps {
  windowRef: React.RefObject<HTMLDivElement>;
}

export const InternalWindowContextProvider: React.FC<InternalWindowContextProviderProps> = ({
  children,
  windowRef,
}) => {
  const ref = windowRef.current || undefined;

  return (
    <InternalWindowContext.Provider
      value={{
        internalWindowRef: ref,
      }}
    >
      {children}
    </InternalWindowContext.Provider>
  );
};

export const useInternalWindowContext = () => useContext(InternalWindowContext);
