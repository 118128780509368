import React from 'react';
import { ButtonPrimary } from 'components/lib/Button';
import { FormattedMessage } from 'react-intl';
import useCouldNotOpenFormStyles from './styles';
import { CouldNotOpenFormProps } from './types';
import {
  UNSUPPORTED_SCHEMA_BUTTON_TESTID,
  UNSUPPORTED_SCHEMA_CLEAR_BUTTON_TESTID,
  UNSUPPORTED_SCHEMA_TEXT_TESTID,
} from 'utils/testIds';
import { useFormBuilderContext } from 'components/formBuilder/formBuilder/FormBuilderContext';
import useInitialSchemas from 'pages/ObjectClasses/components/ObjectClassForm/ObjectClassDisplay/hooks/useInitialSchemas';

const CouldNotOpenForm = ({
  onClick,
  hideClearFormButton,
  message,
}: CouldNotOpenFormProps) => {
  const classes = useCouldNotOpenFormStyles({});

  const { onChange } = useFormBuilderContext();
  const { initialSchema, initialUiSchema } = useInitialSchemas({
    uiSchema: undefined,
    schema: undefined,
  });

  const handleClearForm = () => {
    onChange(initialSchema, initialUiSchema);
  };

  return (
    <div className={classes.unsupportedFormWrapper}>
      <h4 data-testid={UNSUPPORTED_SCHEMA_TEXT_TESTID}>
        {message || (
          <FormattedMessage
            id='formBuilder.couldNotOpenForm'
            defaultMessage='We could not open this form'
          />
        )}
      </h4>
      {!hideClearFormButton && (
        <ButtonPrimary
          onClick={handleClearForm}
          data-testid={UNSUPPORTED_SCHEMA_CLEAR_BUTTON_TESTID}
        >
          <FormattedMessage
            id='formBuilder.clearForm'
            defaultMessage='Clear form'
          />
        </ButtonPrimary>
      )}
      {!!onClick && (
        <ButtonPrimary
          {...{ onClick }}
          data-testid={UNSUPPORTED_SCHEMA_BUTTON_TESTID}
        >
          <FormattedMessage id='formBuilder.goBack' defaultMessage='Go back' />
        </ButtonPrimary>
      )}
    </div>
  );
};

export default CouldNotOpenForm;
