import AutocompleteUsersSelect from 'components/AutocompleteUsersSelect';
import { CheckMarkIcon } from 'components/Icon';
import OptionAvatar from 'components/OptionAvatar';
import { CustomAvatarGroup } from 'components/lib/Avatar';
import React from 'react';
import useAutocompleteUserFieldFilterStyles from './styles';
import { AutocompleteUserFieldFilterProps } from './types';

const AutocompleteUserFieldFilter = ({
  fixedAutocompleteUrl,
  users,
  selectMultiple,
  onSelectUserChange,
  getIsUserSelected,
}: AutocompleteUserFieldFilterProps) => {
  const classes = useAutocompleteUserFieldFilterStyles();

  return (
    <>
      <AutocompleteUsersSelect
        onChange={onSelectUserChange}
        {...{
          selectMultiple,
        }}
        autocompleteUrl={fixedAutocompleteUrl}
        checkOptionIsDisabled={() => false}
        renderOption={(option, searchValue) => (
          <div className={classes.optionContent}>
            <OptionAvatar
              disabledText={undefined}
              {...{ option, searchValue }}
            />
            {getIsUserSelected(option.id) && (
              <CheckMarkIcon className={classes.checkmarkIcon} size={14} />
            )}
          </div>
        )}
      />
      <div onClick={e => e.stopPropagation()}>
        <CustomAvatarGroup items={users ?? []} limitItems={6} />
      </div>
    </>
  );
};

export default AutocompleteUserFieldFilter;
