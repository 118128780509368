import { createUseStyles } from 'react-jss';

export const useDangerouslyRenderCkeditorContentStyles = createUseStyles({
  valueWrapper: {
    minHeight: 35,
    textAlign: 'start',
    padding: 8,
    wordBreak: 'break-word',
    '& .text-tiny': {
      fontSize: '.7em',
    },
    '& .text-small': {
      fontSize: '.85em',
    },
    '& .text-big': {
      fontSize: '1.4em',
    },
    '& .text-huge': {
      fontSize: '1.8em',
    },
    '& span > img': {
      width: '100%',
    },
  },
});
