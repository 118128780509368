import { useCallback, useMemo } from 'react';
import { generatePath } from 'react-router';
import { FormikErrors, FormikHelpers } from 'formik';
import moment from 'moment';
import { usePostWithToasts } from 'hooks/usePostWithToasts';
import { CUSTOM_DATE_FORMAT } from 'pages/TaskTemplates/components/TaskForm/consts';
import { TASKS_LIST, TASK_STAGE_ASSIGNES } from 'utils/endpoints';
import { TaskFormDataToAPI, TaskFormFields, TaskFormType } from './types';
import { StageNames, TaskDetails } from 'utils/types/api/tasks.types';
import { apiCall } from 'utils/api';
import { StatusCodes } from 'http-status-codes';
import { useAutoSaveErrorModalContext } from 'contexts/AutoSaveErrorModalContext';
import { AxiosError, AxiosResponse } from 'axios';
import { RECORD_NOT_EXIST_API_ERROR_REGEX } from 'utils/consts';
import { TaskTypes } from 'pages/TaskTemplates/enums';
import { useIntl } from 'react-intl';

const useTaskForm = (
  recordId: string | undefined,
  changePanelToList?: (dirty?: boolean) => void
) => {
  const intl = useIntl();
  const { showErrorModal } = useAutoSaveErrorModalContext();
  const { sendData } = usePostWithToasts<TaskFormDataToAPI, TaskDetails>();

  const initialValues: TaskFormType = useMemo(
    () => ({
      [TaskFormFields.Name]: '',
      [TaskFormFields.TaskType]: TaskTypes.Form,
    }),
    []
  );

  const onSubmit = useCallback(
    async (
      {
        task_template: taskTemplate,
        due_date: dueDate,
        stage1,
        ...values
      }: TaskFormType,
      { setErrors, setSubmitting, resetForm }: FormikHelpers<TaskFormType>
    ) => {
      const hideErrorToast = (errorResponse: AxiosResponse | undefined) =>
        RECORD_NOT_EXIST_API_ERROR_REGEX.test(
          errorResponse?.data?.object_record?.[0]
        );

      const transformErrors = (errors: FormikErrors<TaskFormType>) => {
        const newErrors = { ...errors };

        if (errors.task_template?.startsWith('errors.invalid_pk')) {
          newErrors.task_template = intl.formatMessage({
            id: 'errors.task_template_does_not_exist',
            defaultMessage: 'Task template does not exist',
          });
        }

        return newErrors;
      };

      const stages = {
        [TaskFormFields.Stage1]: stage1
          ? stage1.split(', ').map(id => Number(id))
          : null,
      };
      const formData = {
        object_record: recordId,
        task_template: Number(taskTemplate),
        due_date: dueDate ? moment(dueDate).format(CUSTOM_DATE_FORMAT) : null,
        ...values,
      };

      try {
        await sendData({
          url: generatePath(TASKS_LIST),
          data: formData,
          fields: TaskFormFields,
          callback: async (responseData: TaskDetails | undefined) => {
            if (responseData?.id) {
              await apiCall.post(
                generatePath(TASK_STAGE_ASSIGNES, {
                  id: responseData.id,
                  stageId: StageNames.Stage1,
                }),
                { users: stages[TaskFormFields.Stage1] }
              );
            }

            if (changePanelToList) changePanelToList();
          },
          successMessage: {
            title: intl.formatMessage({
              id: 'misc.success',
              defaultMessage: 'Success!',
            }),
            subtitle: intl.formatMessage({
              id: 'tasks.taskCreated',
              defaultMessage: 'Task has been created.',
            }),
          },
          setErrors: errors => setErrors(transformErrors(errors)),
          setSubmitting,
          hideErrorToast,
        });
      } catch (error) {
        const { response: { data, status } = {} } = error as AxiosError<
          MappedObject<string[]>
        >;

        if (
          showErrorModal &&
          status === StatusCodes.BAD_REQUEST &&
          data?.object_record?.[0] &&
          RECORD_NOT_EXIST_API_ERROR_REGEX.test(data?.object_record?.[0])
          // it is only one way to detect that an object record does not exist, based on API errors from ticket #31132
        ) {
          showErrorModal({
            ...error,
            response: { ...error.response, status: StatusCodes.NOT_FOUND },
          });

          resetForm({});
        }
      }
    },
    [recordId, sendData, changePanelToList, showErrorModal, intl]
  );

  return {
    initialValues,
    onSubmit,
  };
};

export default useTaskForm;
