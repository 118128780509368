import React, { useEffect, useMemo, useState } from 'react';
import { Menu } from 'antd';
import useLayoutStyles from 'components/lib/Layout/styles';

import PermissionMenuItem from './PermissionMenuItem';
import routes from 'utils/routingPaths';
import { FormattedMessage, useIntl } from 'react-intl';
import PermissionSubMenu from './PermissionSubMenu';
import { useSideMenu } from './hooks';
import {
  SystemConfigurationIcon,
  WorkManagementIcon,
  AdministrationIcon,
  RecordsIcon,
  CubeIcon,
  NotesIcon,
  RelationalModeller,
  SequencesIcon,
  AuthObjectIcon,
  CompanyDetailsIcon,
  StandardAuthIcon,
  UserManagementIcon,
  UserManagementSettingsIcon,
  UsersIcon,
  KeyIcon,
  OutboundMailIcon,
  ThemeIcon,
  LandingPageIcon,
  CustomComponentsIcon,
} from 'components/Icon';
import usePermissions from 'hooks/usePermissions';
import { SideMenuProps } from './types';
import { MENU_INLINE_INDENT } from 'utils/consts';
import PermissionMenuItemsSeparator from './PermissionMenuItemsSeparator';
import { SIDEBAR_MENU_TESTID } from 'utils/testIds';
import { SIDEBAR_MENU_ID } from 'utils/elementsIds';
import { useSideMenuContext } from './context/SideMenuContext';
import SubmenuItem from './SubmenuItem';

const SideMenu = ({ theme, parentCollapsed }: SideMenuProps) => {
  const classes = useLayoutStyles({});
  const { all: permissions } = usePermissions();
  const intl = useIntl();
  const { onSubmenuClick } = useSideMenu();
  const { activeMenuKey: openKeys } = useSideMenuContext();
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setOpacity(1);
  }, []);

  /*
  This is used to optionally pass openKeys prop to Menu
  setting this as undefined breaks Menu behaviour
  */
  const openKeysProp = useMemo(() => (parentCollapsed ? {} : { openKeys }), [
    parentCollapsed,
    openKeys,
  ]);

  return (
    <Menu
      theme={theme}
      selectedKeys={openKeys}
      mode='inline'
      className={classes.sidebarMenu}
      data-testid={SIDEBAR_MENU_TESTID}
      inlineIndent={MENU_INLINE_INDENT}
      id={SIDEBAR_MENU_ID}
      style={{ opacity }}
      {...openKeysProp}
    >
      <PermissionSubMenu
        data-testid={routes.WORKSPACE.replace('/', '')}
        key={routes.WORKSPACE}
        visible={permissions[routes.WORKSPACE]}
        icon={
          <span className='anticon'>
            <WorkManagementIcon size={25} />
          </span>
        }
        className='purple'
        title={intl.formatMessage({
          id: 'misc.workspace',
          defaultMessage: 'Workspace',
          description: 'Workspace menu item',
        })}
        onTitleClick={onSubmenuClick(routes.WORKSPACE)}
      >
        <PermissionMenuItem
          visible={permissions[routes.RECORDS]}
          key={routes.RECORDS}
          data-testid={routes.RECORDS.replace('/', '')}
          icon={<RecordsIcon />}
        >
          <SubmenuItem
            link={routes.RECORDS}
            message={
              <FormattedMessage
                id='misc.records'
                defaultMessage='Records'
                description='Records menu item'
              />
            }
          />
        </PermissionMenuItem>
        <PermissionMenuItem
          visible={permissions[routes.TASKS]}
          key={routes.TASKS}
          data-testid={routes.TASKS.replace('/', '')}
          icon={<NotesIcon />}
        >
          <SubmenuItem
            link={routes.TASKS}
            message={
              <FormattedMessage
                id='misc.tasks'
                defaultMessage='Tasks'
                description='Tasks menu item'
              />
            }
          />
        </PermissionMenuItem>
      </PermissionSubMenu>
      <PermissionSubMenu
        key={routes.SYSTEM_CONFIGURATION}
        data-testid={routes.SYSTEM_CONFIGURATION.replace('/', '')}
        visible={permissions[routes.SYSTEM_CONFIGURATION]}
        icon={
          <span className='anticon'>
            <SystemConfigurationIcon size={25} />
          </span>
        }
        className='dark-blue'
        title={intl.formatMessage({
          id: 'misc.systemConfiguration',
          defaultMessage: 'System configuration',
          description: 'System configuration menu item',
        })}
        onTitleClick={onSubmenuClick(routes.SYSTEM_CONFIGURATION)}
      >
        <PermissionMenuItem
          visible={permissions[routes.OBJECT_CLASSES]}
          key={routes.OBJECT_CLASSES}
          data-testid={routes.OBJECT_CLASSES.replace('/', '')}
          icon={<CubeIcon />}
        >
          <SubmenuItem
            link={routes.OBJECT_CLASSES}
            message={
              <FormattedMessage
                id='misc.objectClasses'
                defaultMessage='Object classes'
                description='Object classes menu item'
              />
            }
          />
        </PermissionMenuItem>
        <PermissionMenuItem
          visible={permissions[routes.RELATIONAL_MODELLER]}
          key={routes.RELATIONAL_MODELLER}
          data-testid={routes.RELATIONAL_MODELLER.replace('/', '')}
          icon={<RelationalModeller />}
        >
          <SubmenuItem
            link={routes.RELATIONAL_MODELLER}
            message={
              <FormattedMessage
                id='misc.relationalModeller'
                defaultMessage='Relational modeller'
                description='Relational modeller menu item'
              />
            }
          />
        </PermissionMenuItem>
        <PermissionMenuItemsSeparator
          visible={
            permissions[routes.TASK_GROUPS] ||
            permissions[routes.TASK_TEMPLATES]
          }
        />

        <PermissionMenuItem
          visible={permissions[routes.TASK_TEMPLATES]}
          key={routes.TASK_TEMPLATES}
          data-testid={routes.TASK_TEMPLATES.replace('/', '')}
          icon={<NotesIcon />}
        >
          <SubmenuItem
            link={routes.TASK_TEMPLATES}
            message={
              <FormattedMessage
                id='misc.taskTemplates'
                defaultMessage='Task templates'
                description='Tasks menu item'
              />
            }
          />
        </PermissionMenuItem>
        <PermissionMenuItem
          visible={permissions[routes.TASK_GROUPS]}
          key={routes.TASK_GROUPS}
          data-testid={routes.TASK_GROUPS.replace('/', '')}
          icon={<NotesIcon />}
        >
          <SubmenuItem
            link={routes.TASK_GROUPS}
            message={
              <FormattedMessage
                id='misc.taskGroups'
                defaultMessage='Task groups'
                description='Tasks groups menu item'
              />
            }
          />
        </PermissionMenuItem>
        <PermissionMenuItemsSeparator
          visible={
            permissions[routes.TASK_GROUPS] ||
            permissions[routes.TASK_TEMPLATES]
          }
        />
        <PermissionMenuItem
          visible={permissions[routes.SEQUENCES]}
          key={routes.SEQUENCES}
          data-testid={routes.SEQUENCES.replace('/', '')}
          icon={<SequencesIcon />}
        >
          <SubmenuItem
            link={routes.SEQUENCES}
            message={
              <FormattedMessage
                id='misc.sequences'
                defaultMessage='Sequences'
              />
            }
          />
        </PermissionMenuItem>
        <PermissionMenuItem
          visible={permissions[routes.LANDING_PAGE]}
          key={routes.LANDING_PAGE}
          data-testid={routes.LANDING_PAGE.replace('/', '')}
          icon={<LandingPageIcon />}
        >
          <SubmenuItem
            link={routes.LANDING_PAGE}
            message={
              <FormattedMessage
                id='misc.landingPage'
                defaultMessage='Landing page'
              />
            }
          />
        </PermissionMenuItem>
        <PermissionMenuItem
          visible={permissions[routes.THEME]}
          key={routes.THEME}
          data-testid={routes.THEME.replace('/', '')}
          icon={<ThemeIcon />}
        >
          <SubmenuItem
            link={routes.THEME}
            message={
              <FormattedMessage id='misc.theme' defaultMessage='Theme' />
            }
          />
        </PermissionMenuItem>
      </PermissionSubMenu>

      <PermissionSubMenu
        visible={permissions[routes.ADMINISTRATION]}
        key={routes.ADMINISTRATION}
        data-testid={routes.ADMINISTRATION.replace('/', '')}
        title={
          <>
            {intl.formatMessage({
              id: 'misc.administration',
              defaultMessage: 'Administration',
            })}
          </>
        }
        onTitleClick={onSubmenuClick(routes.ADMINISTRATION)}
        icon={
          <span className='anticon'>
            <AdministrationIcon size={25} />
          </span>
        }
        className='sea-blue'
      >
        <PermissionMenuItem
          visible={permissions[routes.USERS]}
          key={routes.USERS}
          data-testid={routes.USERS.replace('/', '')}
          icon={<UserManagementIcon />}
        >
          <SubmenuItem
            link={routes.USERS}
            message={
              <FormattedMessage
                id='misc.users'
                defaultMessage='Users'
                description='Users menu item'
              />
            }
          />
        </PermissionMenuItem>
        <PermissionMenuItem
          visible={permissions[routes.ROLES]}
          key={routes.ROLES}
          data-testid={routes.ROLES.replace('/', '')}
          icon={<UserManagementSettingsIcon />}
        >
          <SubmenuItem
            link={routes.ROLES}
            message={
              <FormattedMessage
                id='misc.roles'
                defaultMessage='Roles'
                description='Roles menu item'
              />
            }
          />
        </PermissionMenuItem>
        <PermissionMenuItem
          visible={permissions[routes.USER_GROUPS]}
          key={routes.USER_GROUPS}
          data-testid={routes.USER_GROUPS.replace('/', '')}
          icon={<UsersIcon />}
        >
          <SubmenuItem
            link={routes.USER_GROUPS}
            message={
              <FormattedMessage
                id='misc.userGroups'
                defaultMessage='User groups'
                description='User groups menu item'
              />
            }
          />
        </PermissionMenuItem>
        <PermissionMenuItemsSeparator
          visible={permissions[routes.COMPANY_DETAILS]}
        />
        <PermissionMenuItem
          visible={permissions[routes.COMPANY_DETAILS]}
          key={routes.COMPANY_DETAILS}
          data-testid={routes.COMPANY_DETAILS.replace('/', '')}
          icon={<CompanyDetailsIcon />}
        >
          <SubmenuItem
            link={routes.COMPANY_DETAILS}
            message={
              <FormattedMessage
                id='misc.companyDetails'
                defaultMessage='Company details'
              />
            }
          />
        </PermissionMenuItem>
        <PermissionMenuItemsSeparator
          visible={permissions[routes.COMPANY_DETAILS]}
        />
        <PermissionMenuItem
          visible={permissions[routes.STANDARD_AUTHENTICATION]}
          key={routes.STANDARD_AUTHENTICATION}
          data-testid={routes.STANDARD_AUTHENTICATION.replace('/', '')}
          icon={<StandardAuthIcon />}
        >
          <SubmenuItem
            link={routes.STANDARD_AUTHENTICATION}
            message={
              <FormattedMessage
                id='misc.standardAuthentication'
                defaultMessage='Standard authentication'
              />
            }
          />
        </PermissionMenuItem>
        <PermissionMenuItem
          visible={permissions[routes.SINGLE_SIGN_ONS]}
          key={routes.SINGLE_SIGN_ONS}
          data-testid={routes.SINGLE_SIGN_ONS.replace('/', '')}
          icon={<KeyIcon />}
        >
          <SubmenuItem
            link={routes.SINGLE_SIGN_ONS}
            message={
              <FormattedMessage
                id='misc.singleSignOnSettings'
                defaultMessage='Single sign-on settings'
              />
            }
          />
        </PermissionMenuItem>
        <PermissionMenuItem
          visible={permissions[routes.AUTHENTICATION_OBJECTS]}
          key={routes.AUTHENTICATION_OBJECTS}
          data-testid={routes.AUTHENTICATION_OBJECTS.replace('/', '')}
          icon={<AuthObjectIcon />}
        >
          <SubmenuItem
            link={routes.AUTHENTICATION_OBJECTS}
            message={
              <FormattedMessage
                id='misc.authenticationObjects'
                defaultMessage='Authentication objects'
              />
            }
          />
        </PermissionMenuItem>
        <PermissionMenuItem
          visible={permissions[routes.CUSTOM_COMPONENTS]}
          key={routes.CUSTOM_COMPONENTS}
          data-testid={routes.CUSTOM_COMPONENTS.replace('/', '')}
          icon={<CustomComponentsIcon />}
        >
          <SubmenuItem
            link={routes.CUSTOM_COMPONENTS}
            message={
              <FormattedMessage
                id='misc.customComponents'
                defaultMessage='Elements'
              />
            }
          />
        </PermissionMenuItem>
        <PermissionMenuItemsSeparator
          visible={permissions[routes.OUTBOUND_MAIL_SETUP]}
        />
        <PermissionMenuItem
          visible={permissions[routes.OUTBOUND_MAIL_SETUP]}
          key={routes.OUTBOUND_MAIL_SETUP}
          data-testid={routes.OUTBOUND_MAIL_SETUP.replace('/', '')}
          icon={<OutboundMailIcon />}
        >
          <SubmenuItem
            link={routes.OUTBOUND_MAIL_SETUP}
            message={
              <FormattedMessage
                id='misc.outboundMailSetup'
                defaultMessage='Outbound mail setup'
              />
            }
          />
        </PermissionMenuItem>
      </PermissionSubMenu>
    </Menu>
  );
};

export default SideMenu;
