import React, { createContext, useCallback, useContext, useState } from 'react';
import noop from 'lodash/noop';
import { InPlaceFilesSavingContextType } from './types';
import { useBeforeunload } from 'react-beforeunload';
import { preventDefault } from 'utils/functions/preventDefault';

const InPlaceFilesSavingContext = createContext<InPlaceFilesSavingContextType>({
  updateSavingFiles: noop,
  savingFilesInProgress: [],
});

export const InPlaceFilesSavingContextProvider =
  InPlaceFilesSavingContext.Provider;

export const useInPlaceFilesSavingContext = () =>
  useContext(InPlaceFilesSavingContext);

export const InPlaceFilesSavingContextWrapper: React.FC = ({ children }) => {
  const [savingFilesInProgress, setSavingFiles] = useState<string[]>([]);

  useBeforeunload(
    event => savingFilesInProgress.length && preventDefault(event)
  );

  const updateSavingFiles = useCallback((id: string) => {
    setSavingFiles(prevValue => {
      if (prevValue.includes(id))
        return prevValue.filter(fileId => fileId !== id);

      return [...prevValue, id];
    });
  }, []);

  return (
    <InPlaceFilesSavingContextProvider
      value={{
        updateSavingFiles,
        savingFilesInProgress,
      }}
    >
      {children}
    </InPlaceFilesSavingContextProvider>
  );
};
