import { createUseStyles } from 'react-jss';

const useUrlCellStyles = createUseStyles({
  url: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export default useUrlCellStyles;
