import { ViewUsageConfig } from 'components/ViewUsage/types';
import useData from 'hooks/useData';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { USER_GROUP_USAGE } from 'utils/endpoints';
import {
  UserGroupPermissionSetUsageItem,
  UserGroupUsage,
  UserGroupUsageItem,
} from 'utils/types/api/userGroups.types';

const transformUserGroups = (userGroups: UserGroupUsageItem[]) => {
  return userGroups.reduce((acc, userGroup) => {
    return [...acc, ...userGroup.permission_sets];
  }, [] as UserGroupPermissionSetUsageItem[]);
};

const useUserGroupsViewUsage = (id?: string) => {
  const intl = useIntl();
  const {
    loading,
    error,
    data: { sequences = [], user_groups: userGroups } = {},
  } = useUserGroupsViewUsageData(id);

  const displayConfig = useMemo<ViewUsageConfig[]>(() => {
    return [
      {
        usage: sequences,
        text: intl.formatMessage({
          id: 'misc.sequences',
          defaultMessage: 'Sequences',
        }),
      },
      {
        usage: transformUserGroups(userGroups ?? []),
        text: intl.formatMessage({
          id: 'misc.userGroupPermissionSets',
          defaultMessage: 'User group permission sets',
        }),
      },
    ];
  }, [intl, sequences, userGroups]);

  return { loading, error, displayConfig };
};

const useUserGroupsViewUsageData = (id?: string) => {
  const [data, { loading, error, fetchData }] = useData<UserGroupUsage>(
    id !== undefined
      ? generatePath(USER_GROUP_USAGE, {
          id,
        })
      : '',
    {
      fetchOnLoad: false,
    }
  );

  useEffect(() => {
    if (id !== undefined) fetchData();
  }, [fetchData, id]);

  return { error, loading, data };
};

export default useUserGroupsViewUsage;
