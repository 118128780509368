export const useHasVerticalScroll = () => {
  const tableBodyElement = window.document.querySelector('#table-body');

  if (tableBodyElement) {
    //@ts-ignore
    return tableBodyElement.clientWidth !== tableBodyElement.offsetWidth;
  }

  return false;
};
