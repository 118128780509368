import isNumber from 'lodash/isNumber';
import { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { ClassFieldForm, ClassFieldFormFields } from '../types';
import { Validators } from 'utils/types/selectInput.types';
import { ParsedTypes } from '../utils';
import useGenerateSelectOptions from 'hooks/useGenerateSelectOptions';
import { fieldTypes, maxUsersCount } from './consts';
import { ObjectClassFieldTypes } from 'utils/types/api/objectClassesFields.types';
import { isInMinMaxRange } from './utils';
import { SINGLE_SELECT_MAX_OPTIONS_COUNT } from 'components/formBuilder/formBuilder/FBGeneralInput/ControlPreferences/consts';
import { MAX_IDENTIFIER_LENGTH } from '../../../consts';
import { useIntl } from 'react-intl';
import { useClassFieldFormContext } from '../../../context/ClassFieldFormContext';

export const useObjectTypeOptions = () => {
  return useGenerateSelectOptions(fieldTypes, 'enums');
};

const useValidators = (validators: Validators[]) => {
  return validators.reduce<MappedObject<number | undefined>>(
    (result, { type: key, length }) => {
      if (Array.isArray(key)) {
        return result;
      } else {
        return {
          ...result,
          [key]: isNumber(length) ? length : undefined,
        };
      }
    },
    {}
  );
};

export const useEnumSetValidators = (parsedTypes: ParsedTypes) => {
  const {
    values: { type },
  } = useFormikContext<ClassFieldForm>();
  const { options: { validators = [] } = {} } = (parsedTypes || {})[type] || {};

  const {
    max_option_length: optionMaxLength,
    min_option_length: optionMinLength,
    max_options: maxOptions,
  } = useValidators(validators);

  const maxOptionsCount =
    type === ObjectClassFieldTypes.Enum
      ? SINGLE_SELECT_MAX_OPTIONS_COUNT
      : maxOptions;

  return {
    optionMaxLength,
    optionMinLength,
    maxOptionsCount,
  };
};

export const useSelectOptions = (
  values: (string | number)[] | { users: (string | number)[] }
) => {
  const items = Array.isArray(values) ? values : values.users;

  return items.map(item => ({
    value: item.toString(),
    text: item.toString(),
  }));
};

export const useTypeChange = () => {
  const { values, setFieldValue, validateForm } = useFormikContext<
    ClassFieldForm
  >();

  const setDefaultValue = useCallback(
    (value: ObjectClassFieldTypes) => {
      const defaultValue = values[ClassFieldFormFields.DefaultValue];

      if (ObjectClassFieldTypes.Bool === value && !defaultValue) {
        return setFieldValue(ClassFieldFormFields.DefaultValue, false);
      }

      setFieldValue(ClassFieldFormFields.DefaultValue, undefined);
    },
    [setFieldValue, values]
  );

  const setValidation = useCallback(
    (fieldType: ObjectClassFieldTypes) => {
      setFieldValue(ClassFieldFormFields.MaxValue, undefined);
      setFieldValue(ClassFieldFormFields.MinValue, undefined);

      setFieldValue(ClassFieldFormFields.MaxValues, undefined);
      setFieldValue(ClassFieldFormFields.MinValues, undefined);

      if (fieldType === ObjectClassFieldTypes.Document) {
        setFieldValue(ClassFieldFormFields.MaxFiles, 100);
      } else {
        setFieldValue(ClassFieldFormFields.MaxFiles, undefined);
      }

      // trigger validation to check updated fields
      // without this formik does not validate above values
      setTimeout(() => validateForm());
    },
    [setFieldValue, validateForm]
  );

  return useCallback(
    (value: ObjectClassFieldTypes) => {
      if (!!values[ClassFieldFormFields.MaxLength])
        setFieldValue(ClassFieldFormFields.MaxLength, undefined);

      if (
        ![ObjectClassFieldTypes.Enum, ObjectClassFieldTypes.Set].includes(value)
      ) {
        setFieldValue(ClassFieldFormFields.SelectionOptions, undefined);
      }

      setValidation(value);

      setDefaultValue(value);
    },
    [setDefaultValue, setFieldValue, setValidation, values]
  );
};

export const useMaxLengthValidators = (parsedTypes: ParsedTypes) => {
  const {
    values: { type, is_identifier },
  } = useFormikContext<ClassFieldForm>();
  const {
    max_length: { validators = [] } = {},
    max_num_of_files: { validators: fileValidators = [] } = {},
  } = (parsedTypes || {})[type] || {};
  const restrictions = useValidators(validators);
  const fileRestrictions = useValidators(fileValidators);

  return {
    ...restrictions,
    ...fileRestrictions,
    ...(is_identifier && { max_value: MAX_IDENTIFIER_LENGTH }),
  };
};

export const useHandleMaxMinValidation = () => {
  const {
    setFieldValue,
    values: {
      default_value: defaultVal,
      min_value: minValue,
      max_value: maxValue,
      type,
    },
  } = useFormikContext<ClassFieldForm>();

  return useCallback(
    (name: string, value?: number) => {
      const isMinValueChange = name === ClassFieldFormFields.MinValue;

      if (
        value !== undefined &&
        isInMinMaxRange(value, isMinValueChange, minValue, maxValue)
      ) {
        const valueKeyToChange = isMinValueChange
          ? ClassFieldFormFields.MaxValue
          : ClassFieldFormFields.MinValue;
        const valueToSet = type === 'int' ? Math.floor(value) : value;

        setFieldValue(valueKeyToChange, valueToSet);
      }

      if (isMinValueChange) {
        const isMinNotValid =
          value !== undefined && defaultVal !== undefined && defaultVal < value;

        if (isMinNotValid)
          return setFieldValue(ClassFieldFormFields.DefaultValue, undefined);
      } else if (name === ClassFieldFormFields.MaxValue) {
        const isMaxNotValid =
          value !== undefined && defaultVal !== undefined && defaultVal > value;

        if (isMaxNotValid)
          return setFieldValue(ClassFieldFormFields.DefaultValue, undefined);
      }
    },
    [defaultVal, maxValue, minValue, type, setFieldValue]
  );
};

export const useSelectMaxMinValidation = () => {
  const {
    setFieldValue,
    validateField,
    values: { min_values: minValues, max_values: maxValues },
  } = useFormikContext<ClassFieldForm>();

  return useCallback(
    (name: string, value?: number) => {
      const isMinValueChange = name === ClassFieldFormFields.MinValues;

      if (
        value !== undefined &&
        (isMinValueChange
          ? maxValues !== undefined && value > maxValues
          : minValues !== undefined && value < minValues)
      ) {
        const valueKeyToChange = isMinValueChange
          ? ClassFieldFormFields.MaxValues
          : ClassFieldFormFields.MinValues;
        const valueToSet = Math.floor(value);

        setFieldValue(valueKeyToChange, valueToSet);
        validateField(valueKeyToChange);
      }
    },
    [maxValues, minValues, validateField, setFieldValue]
  );
};

export const useSelectUsersMaxMinValidation = () => {
  const {
    setFieldValue,
    validateField,
    values: {
      min_users_values: minUsersValues,
      max_users_values: maxUsersValues,
    },
  } = useFormikContext<ClassFieldForm>();

  return useCallback(
    (name: string, value?: number) => {
      const isMinValueChange = name === ClassFieldFormFields.MinUsersValues;

      if (
        value !== undefined &&
        (isMinValueChange
          ? maxUsersValues !== undefined && value > maxUsersValues
          : minUsersValues !== undefined && value < minUsersValues)
      ) {
        const valueKeyToChange = isMinValueChange
          ? ClassFieldFormFields.MaxUsersValues
          : ClassFieldFormFields.MinUsersValues;
        const valueToSet = Math.floor(value);

        setFieldValue(valueKeyToChange, valueToSet);
        validateField(valueKeyToChange);
      }
    },
    [maxUsersValues, minUsersValues, validateField, setFieldValue]
  );
};

export const useUsersValidation = () => {
  const intl = useIntl();
  const { deletedUsers } = useClassFieldFormContext();
  const {
    values: {
      users = [],
      min_users_values: minValues = 1,
      max_users_values: maxValues,
    },
  } = useFormikContext<ClassFieldForm>();

  const [alerts, setAlerts] = useState({
    limitUsersError: '',
    deletedUsersInfo: '',
    deletedUsersError: '',
  });

  const clearInfoMessage = () => {
    setAlerts(prev => ({ ...prev, deletedUsersInfo: '' }));
  };

  const reachedMin = users.length >= minValues;
  const reachedMax = maxValues ? users.length >= maxValues : true;

  useEffect(() => {
    if (deletedUsers.length) {
      if (reachedMax && reachedMin) {
        setAlerts(prev => ({
          ...prev,
          deletedUsersInfo: intl.formatMessage(
            {
              id: 'misc.numberOfUsersHaveBeenDeleted',
              defaultMessage:
                '{deletedUsers} of the previously selected users have been deleted from the system.',
            },
            { deletedUsers: deletedUsers.length }
          ),
        }));
      } else {
        setAlerts(prev => ({
          ...prev,
          deletedUsersError: intl.formatMessage(
            {
              id: 'misc.usersHaveBeenDeleted',
              defaultMessage:
                '{users, plural, =0 {All} other {Some}} of the previously selected users have been deleted from the system. At least {value} {value, plural, =1 {user} other {users}} must be selected.',
            },
            { value: maxValues ?? minValues, users: users.length }
          ),
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (users.length >= maxUsersCount)
      setAlerts(prev => ({
        ...prev,
        limitUsersError: intl.formatMessage(
          {
            id: 'misc.usersLimitReached',
            defaultMessage:
              'The maximum number of users ({ itemsLimit }) have been added.',
          },
          { itemsLimit: maxUsersCount }
        ),
      }));
    if (reachedMax && reachedMin)
      setAlerts(prev => ({ ...prev, deletedUsersError: '' }));
  }, [intl, minValues, reachedMax, reachedMin, users]);

  return { alerts, clearInfoMessage };
};
