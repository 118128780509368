import Tooltip from 'components/lib/Tooltip';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const ChildClassLabel = ({
  label,
  overlayContainerClassName,
  inputLabelClassName,
}: {
  label?: string;
  inputLabelClassName?: string;
  overlayContainerClassName?: string;
}) => {
  return (
    <Tooltip
      placement='topLeft'
      {...(overlayContainerClassName
        ? { className: overlayContainerClassName }
        : {})}
      overlay={
        <FormattedMessage
          id={'formBuilder.childClassRelatedRecords'}
          defaultMessage='Related records: {childClassLabel}'
          values={{ childClassLabel: label }}
        />
      }
    >
      <FormLabel dynamicSpacing className={inputLabelClassName}>
        <FormattedMessage
          id={'formBuilder.childClassRelatedRecords'}
          defaultMessage='Related records: {childClassLabel}'
          values={{ childClassLabel: label }}
        />
      </FormLabel>
    </Tooltip>
  );
};

export default ChildClassLabel;
