import { createUseStyles } from 'react-jss';

const useActivateAccountPageStyles = createUseStyles({
  title: {
    fontSize: 22,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 14,
    opacity: 0.6,
    textAlign: 'center',
  },
});

export default useActivateAccountPageStyles;
