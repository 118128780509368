import { DEFAULT_COLUMNS_WIDTHS } from 'components/Table/consts';
import { composeAllHiddenColumns } from 'components/Table/utils';
import { CustomDataGroupsType, CustomTableGroupKeys } from 'contexts/types';
import useColumnsTranslations from 'hooks/useColumnsTranslations';
import usePreviousState from 'hooks/usePreviousState';
import { useInitialState } from 'hooks/useTableData/useInitialState';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableState } from 'react-table';
import { setCurrentTable } from 'store/actions/filtersActions';
import { selectStack } from 'store/selectors/nestedObjectRecordsSelctors';
import TablesType from 'utils/Enums/TablesType';
import { FIELD_PREFIX } from 'utils/consts';
import {
  ExtendedTableState,
  PreferencesDataState,
  PreferencesTypes,
} from 'utils/types/api/preferences.types';
import { ColumnsMetadata } from 'utils/types/api/table.types';
import {
  UseCurrentTableColumnsWithDataParams,
  UseCurrentTableHiddenColumnsAndQueryParams,
} from './types';

export const getTablePreferencesState = (
  preferences: PreferencesDataState,
  currentTableName: TablesType,
  preferencesId?: string | number
) =>
  preferencesId
    ? (preferences?.[PreferencesTypes.TablePreferences].tables?.[
        currentTableName
      ] as ExtendedTableState)?.[preferencesId]
    : (preferences?.[PreferencesTypes.TablePreferences].tables?.[
        currentTableName
      ] as Partial<TableState<object>>);

export const SPECIFIC_COLUMN_DEFAULT_WIDTHS: MappedObject<number> = {
  id: 100,
};

export const getColumnSpecificDefaultWidth = (
  columnAlias: string,
  defaultColumnWidthsOverrides?: MappedObject<number>
) => {
  return (
    SPECIFIC_COLUMN_DEFAULT_WIDTHS[columnAlias] ||
    defaultColumnWidthsOverrides?.[columnAlias] ||
    DEFAULT_COLUMNS_WIDTHS.width
  );
};

export const generateAdditionalQueryParams = (
  currentColumns: MappedObject<ColumnsMetadata, string>,
  hiddenColumns: string[],
  customDataGroups: CustomDataGroupsType | undefined
) => {
  if (!Object.keys(currentColumns).length) return undefined;

  if (!customDataGroups) return '';

  const groupedFields = Object.values(currentColumns).reduce(
    (groups, { alias, groupKey }) => {
      if (!groupKey) return groups;

      if (!hiddenColumns.includes(alias)) {
        if (groupKey in groups) {
          groups[groupKey].push(alias);
        } else {
          groups[groupKey] = [alias];
        }
      }

      return groups;
    },
    {} as MappedObject<string[]>
  );

  return (Object.keys(groupedFields) as CustomTableGroupKeys[]).reduce(
    (tmpQueryParams, groupKey) => {
      const addQueryParameters = customDataGroups[groupKey]?.addQueryParameters;

      if (!addQueryParameters) return tmpQueryParams;

      return `${tmpQueryParams}&${addQueryParameters(groupedFields[groupKey])}`;
    },
    ''
  );
};

export const useCurrentTableHiddenColumnsAndQuery = ({
  currentTableName,
  currentColumns,
  preferences,
  preferencesId,
  customDataGroups,
  customTableName,
}: UseCurrentTableHiddenColumnsAndQueryParams) => {
  const initialState = useInitialState(preferencesId, customTableName);
  const [
    columnsConfigurationLoading,
    setColumnsConfigurationLoading,
  ] = useState(false);
  const hiddenColumns = useMemo(() => {
    if (!currentTableName || !currentColumns) return [];
    if (currentTableName === TablesType.NestedTable) return [];

    const tablePreferences =
      preferences?.[PreferencesTypes.TablePreferences].tables[currentTableName];

    const {
      hiddenColumns: preferencesHiddenColumns,
      knownColumns: preferencesKnownColumns,
    } =
      (preferencesId
        ? (tablePreferences as ExtendedTableState)?.[preferencesId]
        : (tablePreferences as Partial<TableState<object>>)) || {};

    const hiddenColumns = composeAllHiddenColumns(
      preferencesHiddenColumns,
      preferencesKnownColumns,
      currentColumns
    );

    return (hiddenColumns ?? initialState?.hiddenColumns)?.filter(
      x => !x.startsWith(FIELD_PREFIX)
    ) as string[];
  }, [
    currentTableName,
    preferences,
    preferencesId,
    currentColumns,
    initialState,
  ]);

  const additionalQueryParams = useMemo(
    () =>
      generateAdditionalQueryParams(
        currentColumns,
        hiddenColumns,
        customDataGroups
      ),
    [currentColumns, customDataGroups, hiddenColumns]
  );

  return {
    additionalQueryParams,
    columnsConfigurationLoading,
    hiddenColumns,
    setColumnsConfigurationLoading,
  };
};

export const useCurrentTableColumnsWithData = <T>({
  data,
  currentColumns,
  preferencesId,
  sort,
  currentTableName,
  getColumnsConfiguration,
  setColumnsConfigurationLoading,
  resetData,
  editModeEnabled,
  setEditModeEnabled,
  preferences,
  defaultColumnWidthsOverrides,
  columnLabelKey,
}: UseCurrentTableColumnsWithDataParams<T>) => {
  const dispatch = useDispatch();
  const dataWithAdditionalColumns = useMemo(
    () =>
      (data ?? []).map(row => {
        const { _meta, ...additionalColumns } =
          // @ts-ignore
          row?.configuration?.plugin_config || {};

        return { ...row, ...additionalColumns };
      }),
    [data]
  );

  const [shouldReloadColumns, setShouldReloadColumns] = useState(false);
  const { getTranslation } = useColumnsTranslations(currentColumns);
  const prevSort = usePreviousState(sort);
  const nestedRecordStack = useSelector(selectStack);

  const toggleReload = useCallback(() => {
    setShouldReloadColumns(!shouldReloadColumns);
  }, [shouldReloadColumns, setShouldReloadColumns]);

  useEffect(() => {
    return () => {
      if (
        nestedRecordStack.length < 1 &&
        currentTableName === TablesType.NestedTable
      ) {
        dispatch(setCurrentTable(undefined));
      }
    };
  }, [dispatch, nestedRecordStack.length, currentTableName]);

  useEffect(() => {
    if (getColumnsConfiguration) {
      setColumnsConfigurationLoading(true);

      ((dispatch(getColumnsConfiguration(preferencesId)) as unknown) as Promise<
        void
      >).finally(() => {
        setColumnsConfigurationLoading(false);
      });
    }
  }, [
    dispatch,
    getColumnsConfiguration,
    preferencesId,
    setColumnsConfigurationLoading,
  ]);

  useEffect(() => {
    if (prevSort === undefined) {
      if (resetData) dispatch(resetData());
    }
  }, [dispatch, prevSort, resetData]);

  const toggleEditMode = useCallback(() => {
    setEditModeEnabled(!editModeEnabled);
  }, [editModeEnabled, setEditModeEnabled]);

  const columns = useMemo(
    () =>
      Object.keys(currentColumns).map((column: string) => {
        const {
          [column]: {
            alias,
            sort_ok: sortOk,
            search_key: searchKey,
            label,
            width,
          },
        } = currentColumns;
        const storedWidth =
          width ??
          (currentTableName &&
            getTablePreferencesState(
              preferences,
              currentTableName,
              preferencesId
            )?.columnResizing?.columnWidths[alias]);

        return {
          id: alias,
          Header: label
            ? label
            : GlobalIntlSingleton.dynamicFormatMessage({
                id: `${columnLabelKey}.${alias}`,
              }),
          Cell: ({ value, row }: MappedObject<unknown>) =>
            getTranslation(alias, value, row),
          accessor: alias,
          disableSortBy: !sortOk,
          minWidth: 75,
          searchKey,
          width:
            storedWidth ||
            getColumnSpecificDefaultWidth(alias, defaultColumnWidthsOverrides),
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnLabelKey, currentColumns, shouldReloadColumns]
  );

  return {
    columns,
    dataWithAdditionalColumns,
    toggleReload,
    toggleEditMode,
  };
};
