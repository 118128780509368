import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { getInPlaceEditFontSize } from '../components/commonStyles';

type UseInPlaceEditPhoneInputStylesProps = {
  size?: 'small' | 'medium';
  isSaving?: boolean;
  errorMessage?: string;
};

const useInPlaceEditPhoneInputStyles = createUseStyles((theme: ThemeState) => {
  return {
    inputWrapper: ({
      isSaving,
      errorMessage,
    }: UseInPlaceEditPhoneInputStylesProps) => ({
      position: 'relative',
      flex: 1,
      height: 21,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      borderRadius: 3,
      padding: '0px 2px',
      border: `1px solid ${
        !!errorMessage ? theme['error-color'] : theme['primary-color']
      }`,
      boxShadow: !!errorMessage
        ? theme['input-focus-shadow-error']
        : theme['input-focus-shadow'],
      '& > input:hover, & > input:focus, & > input:active': {
        border: 'none',
        boxShadow: 'none',
      },
      '& > svg': {
        color: theme['primary-color'],
      },
      opacity: isSaving ? 0.65 : 1,
    }),
    input: ({ isSaving, size }: UseInPlaceEditPhoneInputStylesProps = {}) => ({
      height: '100%',
      fontSize: getInPlaceEditFontSize(size),
      background: '#FFFFFF',
      border: 'none',
      padding: '0px',
      pointerEvents: isSaving ? 'none' : 'all',
    }),
    preview: {
      maxWidth: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    inPlaceDialInputStyles: {
      color: theme['primary-color'],
      cursor: 'pointer',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  };
});

export default useInPlaceEditPhoneInputStyles;
