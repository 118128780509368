import { Instance } from 'pspdfkit';
import { useFileDownloadToolbarItem } from './useFileDownloadToolbarItem';
import { useCallback } from 'react';

export const useFileViewerToolbar = () => {
  const { addFileDownloadToolbarItem } = useFileDownloadToolbarItem();

  const configureToolbarItems = useCallback(
    (instance: Instance, fileUrl: string, fileName: string) => {
      let toolbarItems = instance.toolbarItems;

      const allowedItems = [
        'sidebar-thumbnails',
        'pager',
        'pan',
        'zoom-out',
        'zoom-in',
        'zoom-mode',
        'spacer',
        'print',
        'search',
      ];

      toolbarItems = toolbarItems.filter(item =>
        allowedItems.find(allowed => item.type === allowed)
      );

      toolbarItems = addFileDownloadToolbarItem(
        toolbarItems,
        fileUrl,
        fileName
      );

      instance.setToolbarItems(toolbarItems);
    },
    [addFileDownloadToolbarItem]
  );

  return { configureToolbarItems };
};
