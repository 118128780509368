import React from 'react';
import UserAvatar from 'components/UserAvatar';
import useGroupListElementStyles from 'pages/Records/RecordsListing/RecordAccessPanel/components/PeopleListElement/styles';
import {
  USERS_LIST_ITEM_DELETE_BUTTON_TEST_ID,
  USERS_LIST_ITEM_NAME_TEST_ID,
  USERS_LIST_ITEM_TEST_ID,
} from 'utils/testIds';
import { CloseIcon } from 'components/Icon';
import { GroupListElementProps } from './types';

const GroupListElement = ({
  userGroup,
  id,
  actionElement,
  onDelete,
  disablePopover = false,
}: GroupListElementProps) => {
  const classes = useGroupListElementStyles();

  return (
    <div className={classes.wrapper} data-testid={USERS_LIST_ITEM_TEST_ID}>
      <div className={classes.box}>
        <UserAvatar {...{ userGroup, disablePopover }} id={id} size='large' />
        <div
          className={classes.userData}
          data-testid={USERS_LIST_ITEM_NAME_TEST_ID}
        >
          <p className={classes.name}>{userGroup}</p>
        </div>
      </div>
      {!!actionElement && actionElement}
      {!!onDelete && (
        <span data-testid={USERS_LIST_ITEM_DELETE_BUTTON_TEST_ID}>
          <CloseIcon onClick={onDelete} size={16} />
        </span>
      )}
    </div>
  );
};

export default GroupListElement;
