import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { customThemeWithDefaults } from 'store/selectors/customThemeSelectors';

export const DEFAULT_ID = 1;

const useAvatarColor = (id = DEFAULT_ID, isDeleted = false) => {
  const theme = useSelector(customThemeWithDefaults);
  const AVATAR_COLORS = [
    theme['avatar-color-0'],
    theme['avatar-color-1'],
    theme['avatar-color-2'],
    theme['avatar-color-3'],
    theme['avatar-color-4'],
    theme['avatar-color-5'],
    theme['avatar-color-6'],
    theme['avatar-color-7'],
    theme['avatar-color-8'],
    theme['avatar-color-9'],
    theme['avatar-color-10'],
    theme['avatar-color-11'],
  ];

  return useMemo(
    () =>
      isDeleted
        ? theme['disabled-color-light']
        : AVATAR_COLORS[id % AVATAR_COLORS.length],
    [AVATAR_COLORS, id, isDeleted, theme]
  );
};

export default useAvatarColor;
