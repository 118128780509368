import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const useBoolPredicates = () => {
  const intl = useIntl();

  return useMemo(
    () => ({
      exact: {
        label: intl.formatMessage({
          id: 'predicates.isEqual',
          defaultMessage: 'is equal to',
        }),
        value: 'exact',
        query: '={value}',
        args: ['value'],
      },
    }),
    [intl]
  );
};
