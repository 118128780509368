import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const labelStyles = {
  display: 'block',
  fontWeight: 'bold',
  fontSize: 12,
  height: 'auto',
  wordBreak: 'break-word',
  textAlign: 'left',
};

type UseFormLabelStylesProps = {
  inline?: boolean;
  bolded?: boolean;
  spacingWithin?: string | number;
  asteriskSize?: number;
  withPaddingBottom?: boolean;
};

const useFormLabelStyles = createUseStyles((theme: ThemeState) => {
  return {
    label: {
      display: ({ inline }: UseFormLabelStylesProps) =>
        inline ? 'inline' : 'block',
      fontWeight: ({ bolded = true }: UseFormLabelStylesProps = {}) =>
        bolded ? 'bold' : 'initial',
      fontSize: 12,
      height: 'auto',
      width: 'auto',
      textAlign: 'left',
      color: theme['heading-color'],
      paddingBottom: ({ spacingWithin }: UseFormLabelStylesProps = {}) =>
        spacingWithin ?? 6,
      lineHeight: 1.3,
    },
    required: {
      fontSize: ({ asteriskSize }: UseFormLabelStylesProps) =>
        asteriskSize || 15,
      lineHeight: 1,
      color: theme['error-color-dark-pink'],
      display: ({ withPaddingBottom }: UseFormLabelStylesProps) =>
        withPaddingBottom ? 'inline-block' : 'inline',
      overflow: 'hidden',
      width: 12,
      paddingTop: 0,
      marginLeft: 0,
    },
    flex: {
      display: 'flex !important',
    },
  };
});

export default useFormLabelStyles;
