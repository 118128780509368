import { createUseStyles } from 'react-jss';
import defaultTheme from 'utils/defaultTheme';

const theme = defaultTheme

const useLoginFormStyles = createUseStyles({
  wrapper: {
    padding: '0 42px',
  },
  input: {
    width: '100%',
    display: 'block',
    '&.ant-form-item': {
      marginBottom: 21,
    },
    '& > .ant-col': {
      maxWidth: '100%',
    },
    '&.ant-input:hover': { borderColor: `${theme['primary-color']}` },
    '&:-webkit-autofill,:-webkit-autofill:hover,:-webkit-autofill:focus,:-webkit-autofill:active': {
      borderColor: theme['primary-color'],
    },
  },
  selectWrapper: {
    marginBottom: 20,
  },
  buttonWrapper: {
    marginTop: 34,
  },
  link: {
    alignSelf: 'center',
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 700,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme['primary-color'],
  },
  dividerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  divider: {
    width: 'calc(37.5% - 5px)',
    display: 'block',
    height: 1,
    background: theme['border-color-base'],
  },
  dividerText: {
    fontSize: 12,
    width: '25%',
    textAlign: 'center',
    alignSelf: 'center',
    marginBottom: 0,
    padding: '0 5px',
    color: theme['border-color-base'],
  },
  error: {
    fontSize: 12,
    color: theme['error-color'],
  },
  errorInput: {
    border: `1px solid ${theme['error-color']} !important`,
  },
  errorSelect: {
    '& .ant-select-selector': {
      border: `1px solid ${theme['error-color']} !important`,
    },
  },
  description: {
    fontSize: 12,
    textAlign: 'justify',
    fontWeight: 400,
    marginTop: -13,
    marginBottom: 19,
    color: theme['disabled-color'],
    display: 'block',
    wordBreak: 'break-word',
  },
});

export default useLoginFormStyles;
