import React from 'react';
import { EventExpandIcon } from 'components/Icon';
import { EVENT_DATA_LABEL } from 'utils/testIds';
import useEventDataStyles from './styles';
import { EventDataTypeProps } from './types';

const EventData = ({ label, isExpanded, isMultiple }: EventDataTypeProps) => {
  const styles = useEventDataStyles({ isExpanded });

  return (
    <div className={styles.eventDataWrapper}>
      <div className={styles.eventDataLabel} data-testid={EVENT_DATA_LABEL}>
        {label}
      </div>
      {isMultiple && (
        <EventExpandIcon size={12} className={styles.eventDataExpand} />
      )}
    </div>
  );
};

export default EventData;
