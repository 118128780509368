import { useCallback } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

const useCheckIfOnAvailablePath = () => {
  const location = useLocation();

  return useCallback(
    (paths: string[]) =>
      matchPath(location.pathname, {
        path: paths,
        exact: true,
      })?.isExact,
    [location.pathname]
  );
};

export default useCheckIfOnAvailablePath;
