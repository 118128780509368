import { createUseStyles } from 'react-jss';

const useCardWrapperStyles = createUseStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100vh',
    padding: 30,
    width: '100%',
  },
  card: {
    borderRadius: 5,
    boxShadow: '0px 4px 50px rgba(46, 63, 87, 0.19)',
    maxWidth: 550,
    padding: 42,
  },
  logo: {
    display: 'block',
    margin: 'auto',
    paddingTop: 18,
    paddingBottom: 26,
    width: 150,
  },
});

export default useCardWrapperStyles;
