import { createUseStyles } from 'react-jss';

export const useFileViewerDisplayStyles = createUseStyles({
  loadingSpinnerContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fileViewerHost: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
});
