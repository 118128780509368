import noop from 'lodash/noop';
import { createContext, useContext } from 'react';
import { TableViewTypeProviderContextType } from './types';

const TableViewTypeContext = createContext<TableViewTypeProviderContextType>({
  isCardsView: undefined,
  toggle: noop,
});

export const TableViewTypeProvider = TableViewTypeContext.Provider;
export const useTableViewTypeContext = () => useContext(TableViewTypeContext);
