export const SET_NESTED_OBJECT_RECORDS = 'SET_NESTED_OBJECT_RECORDS';
export const APPEND_NESTED_OBJECT_RECORDS = 'APPEND_NESTED_OBJECT_RECORDS';
export const SET_NESTED_OBJECT_RECORDS_FETCHING =
  'SET_NESTED_OBJECT_RECORDS_FETCHING';
export const SET_TOTAL_NESTED_OBJECTS = 'SET_TOTAL_NESTED_OBJECTS';
export const RESET_NESTED_OBJECT_RECORDS = 'RESET_NESTED_OBJECT_RECORDS';
export const SET_NESTED_OBJECT_RECORDS_COLUMNS =
  'SET_NESTED_OBJECT_RECORDS_COLUMNS';
export const RESET_NESTED_OBJECT_RECORDS_COLUMNS =
  'RESET_NESTED_OBJECT_RECORDS_COLUMNS';
export const SET_INCOMPLETE_TASKS_OBJECT_RECORD_ID =
  'SET_INCOMPLETE_TASKS_OBJECT_RECORD_ID';
export const SET_INCOMPLETE_TASKS_FETCHING = 'SET_INCOMPLETE_TASKS_FETCHING';
export const SET_NESTED_OBJECT_RECORDS_RESTRICTIONS =
  'SET_NESTED_OBJECT_RECORDS_RESTRICTIONS';
export const SET_NESTED_OBJECT_RECORDS_ERROR =
  'SET_NESTED_OBJECT_RECORDS_ERROR';
export const SET_NESTED_OBJECT_RECORDS_SELECTED_COLUMNS =
  'SET_NESTED_OBJECT_RECORDS_SELECTED_COLUMNS';
export const CLEAR_NESTED_OBJECT_RECORDS = 'CLEAR_NESTED_OBJECT_RECORDS';
export const SET_NESTED_OBJECT_RECORDS_SELECTED_ROW =
  'SET_NESTED_OBJECT_RECORDS_SELECTED_ROW';

//stack
export const PUSH_STACK = 'PUSH_STACK';
export const POP_STACK = 'POP_STACK';
export const POP_MANY_STACK = 'POP_MANY_STACK';
export const CLEAR_STACK = 'CLEAR_STACK';
export const UPDATE_STACK_ELEMENT = 'UPDATE_STACK_ELEMENT';
export const UPDATE_NESTED_RECORDS_STACK_MODAL =
  'UPDATE_NESTED_RECORDS_STACK_MODAL';

//tree
export const ADD_TREE_CHILD = 'ADD_TREE_CHILD';
export const REMOVE_TREE_CHILD = 'REMOVE_TREE_CHILD';
export const REMOVE_TREE_NODE = 'REMOVE_TREE_NODE';
export const SET_TREE_NODE = 'SET_TREE_NODE';
export const REPLACE_TREE_NODE = 'REPLACE_TREE_NODE';
export const REMOVE_CHILDREN = 'REMOVE_CHILDREN';
export const CLEAR_TREE = 'CLEAR_TREE';
