import React from 'react';
import { AvatarItem } from 'components/lib/Avatar/types';
import OwnersAvatarsList from '../standard/CustomOwnersWidget/OwnersAvatarsList';
import { mapCustomWidgetPropsToBaseInPlaceEditProps } from './utils';
import { Widget } from 'alx-dynamic-form';
import { Provider } from 'react-redux';
import { store } from 'store/store';

const AdaptedInPlaceEditOwners: Widget = widgetProps => {
  const {
    value = [],
    ...baseInPlaceEditProps
  } = mapCustomWidgetPropsToBaseInPlaceEditProps<AvatarItem[], any>(
    widgetProps
  );

  const { hideOwners } = widgetProps.additionalFieldProps;

  return (
    <Provider store={store}>
      <OwnersAvatarsList {...{ value, ...baseInPlaceEditProps, hideOwners }} />
    </Provider>
  );
};

export default AdaptedInPlaceEditOwners;
