import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useMemo,
} from 'react';
import noop from 'lodash/noop';
import useResourcePanels from 'hooks/useResourcePanels';
import { SelectedItem, SelectedResourceContextType } from './types';

export const SelectedResourceContext = createContext<
  SelectedResourceContextType
>({
  setSelectedResource: noop,
  setAdditionalSelectedResource: noop,
});

export const SelectedResourceContextProvider: React.FC = ({ children }) => {
  const [selectedResource, setSelectedResource] = useState<
    SelectedItem | undefined
  >();
  const [additionalSelectedResource, setAdditionalSelectedResource] = useState<
    SelectedItem | undefined
  >(); //for data which isn't related to primary selectedItem - records page with nested records

  const handleSetData = useCallback((data?: SelectedItem) => {
    setSelectedResource(data);
  }, []);

  const handleSetAdditionalData = useCallback((data?: SelectedItem) => {
    setAdditionalSelectedResource(data);
  }, []);

  useResourcePanels(selectedResource, additionalSelectedResource);

  const value: SelectedResourceContextType = useMemo(
    () => ({
      additionalSelectedResource,
      selectedResource,
      setSelectedResource: handleSetData,
      setAdditionalSelectedResource: handleSetAdditionalData,
    }),
    [
      additionalSelectedResource,
      selectedResource,
      handleSetData,
      handleSetAdditionalData,
    ]
  );

  return (
    <SelectedResourceContext.Provider value={value}>
      {children}
    </SelectedResourceContext.Provider>
  );
};

/**
 * You can use this context to provide what is selected by the users. Those selected resources control
 * the right side resource panels.
 * TODO: Merge this into Redux store of usePanels() hook and related data as this seems to be strictly coupled with this logic.
 */
export const useSelectedResourceContext = () =>
  useContext(SelectedResourceContext);
