import { AxiosError } from 'axios';
import AutocompleteUsersSelect from 'components/AutocompleteUsersSelect';
import { CheckIcon } from 'components/Icon';
import { AvatarItem } from 'components/lib/Avatar/types';
import { ButtonSecondaryOutlined, ButtonTertiary } from 'components/lib/Button';
import { Modal } from 'components/lib/Modal';
import { toast } from 'components/lib/toast';
import List from 'components/List';
import OptionAvatar from 'components/OptionAvatar';
import { StatusCodes } from 'http-status-codes';
import PeopleListElement from 'pages/Records/RecordsListing/RecordAccessPanel/components/PeopleListElement';
import React, { useCallback, useMemo, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { getSelectedTask } from 'store/selectors/taskSelectors';
import { apiCall } from 'utils/api';
import {
  ASSIGN_TASK_OWNERSHIP,
  TASK_OWNERS_AUTOCOMPLETE,
} from 'utils/endpoints';
import ToastType from 'utils/Enums/ToastType';
import { preventDefault } from 'utils/functions/preventDefault';
import showDefaultErrorToast from 'utils/functions/showDefaultErrorToast';
import {
  MODAL_CANCEL_BUTTON_TESTID,
  MODAL_CONFIRM_BUTTON_TESTID,
  TASK_OWNER_MODAL_TITLE,
} from 'utils/testIds';
import { ErrorCodes } from 'utils/types/errorResponse';
import { useTaskOwnershipContext } from '../TaskOwnershipContext';
import useModalStyles from './styles';
import { AssignOwnerModalProps } from './types';

const AssignOwnerModal = ({
  owner,
  taskId,
  stageId,
}: AssignOwnerModalProps) => {
  const intl = useIntl();
  const styles = useModalStyles({});
  const selectedTask = useSelector(getSelectedTask);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<AvatarItem | undefined>(
    owner
      ? {
          firstName: owner.first_name,
          lastName: owner.last_name,
          company: owner.company_name,
          email: owner.username,
          ...owner,
        }
      : undefined
  );

  const {
    shouldBeAssignOwnerDisplayed,
    setShouldBeAssignOwnerDisplayed,
    setShouldBeConfirmationDisplayed,
    setOnConfirm,
    refetchData,
    setConfirmLabel,
    setCancelLabel,
    setTitle,
    setSubtitle,
  } = useTaskOwnershipContext();

  const hideModal = useCallback(() => {
    setShouldBeAssignOwnerDisplayed(false);
  }, [setShouldBeAssignOwnerDisplayed]);

  useBeforeunload(
    event => selectedUser?.id !== owner?.id && preventDefault(event)
  );

  const onCancel = useCallback(() => {
    if (selectedUser?.id !== owner?.id) {
      //change has been made
      setOnConfirm(() => () => setShouldBeAssignOwnerDisplayed(false));
      setConfirmLabel(
        intl.formatMessage({
          id: 'misc.Yes',
          defaultMessage: 'Yes',
        })
      );
      setCancelLabel(
        intl.formatMessage({
          id: 'misc.goBack',
          defaultMessage: 'No, go back',
        })
      );
      setTitle(
        intl.formatMessage({
          id: 'misc.unsavedChangesLost',
          defaultMessage: 'Unsaved changes will be lost',
        })
      );
      setSubtitle(
        intl.formatMessage({
          id: 'misc.ifYouConfirmCancel',
          defaultMessage:
            'If you confirm you will lose your unsaved changes.\n Do you want to do this?',
        })
      );
      setShouldBeConfirmationDisplayed(true);
      return;
    }
    setShouldBeAssignOwnerDisplayed(false);
  }, [
    selectedUser,
    owner,
    setShouldBeAssignOwnerDisplayed,
    setOnConfirm,
    setConfirmLabel,
    intl,
    setCancelLabel,
    setTitle,
    setSubtitle,
    setShouldBeConfirmationDisplayed,
  ]);

  const onSave = useCallback(async () => {
    setIsLoading(true);
    try {
      await apiCall.patch(
        generatePath(ASSIGN_TASK_OWNERSHIP, { taskId, stageId }),
        { owner: selectedUser?.id ?? null }
      );

      if (owner?.id && selectedUser?.id && selectedUser?.id !== owner?.id) {
        toast({
          title: intl.formatMessage({
            id: 'misc.Success',
            defaultMessage: 'Success!',
          }),
          subtitle: intl.formatMessage(
            {
              id: 'tasks.OwnershipReassigned',
              defaultMessage:
                '{firstName} {lastName} was removed and {assignedFirstName} {assignedLastName} was added as owner of the task.',
            },
            {
              firstName: owner.first_name,
              lastName: owner.last_name,
              assignedFirstName: selectedUser?.firstName,
              assignedLastName: selectedUser?.lastName,
            }
          ),
        });
      } else if (selectedUser?.id) {
        toast({
          title: intl.formatMessage({
            id: 'misc.Success',
            defaultMessage: 'Success!',
          }),
          subtitle: intl.formatMessage(
            {
              id: 'tasks.OwnershipAssigned',
              defaultMessage:
                '{firstName} {lastName} was assigned ownership of the task.',
            },
            { ...selectedUser }
          ),
        });
      } else {
        toast({
          title: intl.formatMessage({
            id: 'misc.Success',
            defaultMessage: 'Success!',
          }),
          subtitle: intl.formatMessage(
            {
              id: 'tasks.OwnershipRemoved',
              defaultMessage:
                '{firstName} {lastName} was removed as the task owner.',
            },
            { firstName: owner?.first_name, lastName: owner?.last_name }
          ),
        });
      }
      hideModal();

      if (selectedTask?.refetchTasksList) {
        selectedTask.refetchTasksList();
      }
    } catch (e) {
      const error = e as AxiosError | undefined;
      if (
        error?.response?.status === StatusCodes.BAD_REQUEST &&
        error?.response?.data.error_code === ErrorCodes.ERR_LIMIT_EXCEEDED
      ) {
        toast(
          {
            title: intl.formatMessage({
              id: 'errors.OwnerNotAdded',
              defaultMessage: 'Owner not added',
            }),
            subtitle: intl.formatMessage(
              {
                id: 'misc.OnwerNotAddedMaximumAssignees',
                defaultMessage:
                  'Adding {firstName} {lastName} as owner would cause this task to exceed the maximum allowed number of assignees.',
              },
              { ...selectedUser }
            ),
          },
          ToastType.Error
        );
      } else {
        showDefaultErrorToast();
      }
    } finally {
      setIsLoading(false);
      refetchData();
    }
  }, [
    taskId,
    stageId,
    selectedUser,
    owner,
    hideModal,
    selectedTask,
    intl,
    refetchData,
  ]);

  const onChange = useCallback(value => {
    const {
      id,
      first_name: firstName,
      last_name: lastName,
      company_name: company,
      username: email,
      account_type: accountType,
    } = value || {};

    if (!id) {
      return;
    }

    setSelectedUser({
      id,
      firstName,
      lastName,
      company,
      email,
      accountType,
    });
  }, []);

  const footer = useMemo(
    () => (
      <div className={styles.modalFooter}>
        <ButtonSecondaryOutlined
          onClick={onCancel}
          data-testid={MODAL_CANCEL_BUTTON_TESTID}
        >
          <FormattedMessage id='misc.cancel' defaultMessage='Cancel' />
        </ButtonSecondaryOutlined>
        <ButtonTertiary
          disabled={owner?.id === selectedUser?.id || isLoading}
          icon={<CheckIcon size={12} />}
          onClick={onSave}
          data-testid={MODAL_CONFIRM_BUTTON_TESTID}
        >
          {intl.formatMessage({
            id: 'misc.save',
            defaultMessage: 'Save',
          })}
        </ButtonTertiary>
      </div>
    ),
    [styles.modalFooter, onCancel, owner, selectedUser, isLoading, onSave, intl]
  );

  const checkOptionIsDisabled = () => false;

  return (
    <Modal
      visible={shouldBeAssignOwnerDisplayed}
      onCancel={onCancel}
      closable={false}
      maskClosable={false}
      destroyOnClose
      footer={footer}
      title={
        <span data-testid={TASK_OWNER_MODAL_TITLE}>
          {owner ? (
            <FormattedMessage
              id='tasks.ReassignOwnership'
              defaultMessage='Reassign owner'
            />
          ) : (
            <FormattedMessage
              id='tasks.AssignOwner'
              defaultMessage='Assign owner'
            />
          )}
        </span>
      }
    >
      <div className={styles.modalContainer}>
        <AutocompleteUsersSelect
          selectMultiple
          {...{
            onChange,
            checkOptionIsDisabled,
            autocompleteUrl: TASK_OWNERS_AUTOCOMPLETE,
          }}
          className={styles.modalSelect}
          placeholder={intl.formatMessage({
            id: 'tasks.searchForUsers',
            defaultMessage: 'Search for users',
          })}
          disabled={!!selectedUser}
          renderOption={(option, searchValue) => (
            <OptionAvatar
              {...{ option, searchValue }}
              disabledText=''
              disabled={!!selectedUser}
            />
          )}
        />
        <List<AvatarItem>
          emptyDataDescription={intl.formatMessage({
            id: 'tasks.noUsersSelected',
            defaultMessage: 'No users selected.',
          })}
          items={selectedUser ? [selectedUser] : undefined}
          renderItem={item => (
            <PeopleListElement
              {...item}
              onDelete={() => setSelectedUser(undefined)}
            />
          )}
        />
      </div>
    </Modal>
  );
};

export default AssignOwnerModal;
