import momentTz from 'moment-timezone';

export const TIMEZONES_SET = [
  {
    offset: momentTz().tz('US/Alaska').format('Z'),
    timezone: 'US/Alaska',
  },
  {
    offset: momentTz().tz('America/Los_Angeles').format('Z'),
    timezone: 'America/Los_Angeles',
  },
  {
    offset: momentTz().tz('America/Denver').format('Z'),
    timezone: 'America/Denver',
  },
  {
    offset: momentTz().tz('America/Chicago').format('Z'),
    timezone: 'America/Chicago',
  },
  {
    offset: momentTz().tz('America/New_York').format('Z'),
    timezone: 'America/New_York',
  },
  {
    offset: momentTz().tz('America/Argentina/Buenos_Aires').format('Z'),
    timezone: 'America/Argentina/Buenos_Aires',
  },
  {
    offset: momentTz().tz('Atlantic/Reykjavik').format('Z'),
    timezone: 'Atlantic/Reykjavik',
  },
  {
    offset: momentTz().tz('Europe/London').format('Z'),
    timezone: 'Europe/London',
  },
  {
    offset: momentTz().tz('Europe/Warsaw').format('Z'),
    timezone: 'Europe/Warsaw',
  },
  {
    offset: momentTz().tz('Europe/Moscow').format('Z'),
    timezone: 'Europe/Moscow',
  },
  { offset: momentTz().tz('Asia/Dubai').format('Z'), timezone: 'Asia/Dubai' },
  {
    offset: momentTz().tz('Indian/Maldives').format('Z'),
    timezone: 'Indian/Maldives',
  },
  { offset: momentTz().tz('Asia/Dhaka').format('Z'), timezone: 'Asia/Dhaka' },
  {
    offset: momentTz().tz('Asia/Bangkok').format('Z'),
    timezone: 'Asia/Bangkok',
  },
  {
    offset: momentTz().tz('Asia/Hong_Kong').format('Z'),
    timezone: 'Asia/Hong_Kong',
  },
  { offset: momentTz().tz('Asia/Tokyo').format('Z'), timezone: 'Asia/Tokyo' },
  {
    offset: momentTz().tz('Australia/Sydney').format('Z'),
    timezone: 'Australia/Sydney',
  },
];

export const SupportedTimezones = TIMEZONES_SET.map(zone => zone.timezone);
