import { Moment } from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { EventQueryPredicates, EventType } from '../../types';
import { AutocompleteSelectValue } from 'utils/types/selectInput.types';

export enum EventFiltersType {
  All = '',
  Update = 'record_update',
}

export type EventFiltersOptionsType =
  | EventFiltersType.All
  | EventType.RecordCreated
  | EventType.FieldValuesChanged
  | EventType.OwnerInitialized
  | EventType.DocumentGenerated
  | EventFiltersType.Update;

export interface EventFiltersProps {
  queryParams: EventQueryPredicates;
  setQueryParams: React.Dispatch<React.SetStateAction<EventQueryPredicates>>;
}

export type EventDateFilterRanges = RangePickerProps<Moment>['ranges'];
export type EventDateFilterDisabledTime = RangePickerProps<
  Moment
>['disabledTime'];
export type EventDateFilterChange = RangePickerProps<Moment>['onChange'];
export type FieldFilterAutocomplete = AutocompleteSelectValue & { id?: number };
