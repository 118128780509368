import { FIELD_PREFIX } from 'utils/consts';
import { composeAllHiddenColumns } from 'components/Table/utils';
import { getTablePreferencesState } from 'hooks/useCurrentTable/utils';
import useCurrentTableSupportedColumns from 'hooks/useCurrentTableSupportedColumns';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TableState } from 'react-table';
import { getCurrentTable } from 'store/selectors/filtersSelectors';
import { getAllPreferences } from 'store/selectors/preferencesSelectors';
import defaultColumns from 'utils/defaultColumns';
import TablesType from 'utils/Enums/TablesType';
import { sortFunction } from './utils';

export const useInitialState = (
  preferencesId?: string | number,
  customTableName?: TablesType
) => {
  const currentTableNameStore = useSelector(getCurrentTable);
  const currentTableName = customTableName ?? currentTableNameStore;
  const tableDefaultColumns = currentTableName
    ? (defaultColumns as MappedObject<string[]>)[currentTableName]
    : [];
  const currentColumns = useCurrentTableSupportedColumns(
    preferencesId?.toString(),
    currentTableName
  );
  const preferences = useSelector(getAllPreferences);

  const initialState: Partial<TableState<object>> | undefined = useMemo(() => {
    if (!currentTableName) {
      return undefined;
    }

    const tablePreferences = getTablePreferencesState(
      preferences,
      currentTableName,
      preferencesId
    );

    const {
      columnOrder = null,
      columnResizing = null,
      hiddenColumns = null,
      knownColumns = null,
    } = tablePreferences || {};

    const allHiddenColumns = composeAllHiddenColumns(
      hiddenColumns,
      knownColumns,
      currentColumns
    );

    const state: Partial<TableState<object>> = {};

    if (currentTableName === TablesType.NestedTable) {
      //override the behaviour specificly for nested tables.
      //There is no re-ordering, hiding or resizing when using these tables, thus we only propagate the order already stored in columns
      state.columnOrder = Object.entries(currentColumns)
        .map(([id, col]) => ({ id, alias: col.alias, order: col.order }))
        .sort(sortFunction)
        .map(col => col.alias);
      state.hiddenColumns = [];
      return state;
    }

    if (columnOrder) {
      state.columnOrder = columnOrder;
    } else {
      state.columnOrder = Object.keys(currentColumns);
    }

    if (columnResizing) state.columnResizing = columnResizing;
    if (allHiddenColumns) {
      state.hiddenColumns = allHiddenColumns.filter(
        col => !col.startsWith(FIELD_PREFIX)
      );
    } else {
      state.hiddenColumns = Object.keys(currentColumns).filter(
        (column: string) => !tableDefaultColumns.includes(column)
      );
    }

    return state;
  }, [
    currentColumns,
    currentTableName,
    preferences,
    preferencesId,
    tableDefaultColumns,
  ]);

  return initialState;
};
