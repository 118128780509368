import { createUseStyles } from 'react-jss';

const useCustomTagStyles = createUseStyles({
  customTag: (closable?: boolean) => ({
    fontWeight: 600,
    border: 'none',
    padding: '5px 10px 5px 5px',
    marginRight: '4px',
    cursor: closable ? 'pointer' : 'default',
    display: 'flex',
    alignItems: 'center',
    '& .anticon': {
      color: 'inherit',
      marginLeft: 5,
      minWidth: 10,
    },
  }),
});

export default useCustomTagStyles;
