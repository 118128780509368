import React, { forwardRef, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Input as AntInput } from 'antd';
import debounce from 'lodash/debounce';
import { Input } from 'components/lib/Input';
import searchSvg from 'img/icons/search-icon.svg';
import useSearchInputStyles from './styles';
import { SearchInputProps } from './types';
import { SEARCH_DEBOUNCE_INTERVAL } from './consts';
import clsx from 'clsx';
import { SEARCH_INPUT_TESTID } from 'utils/testIds';

const SearchInput = forwardRef<AntInput, SearchInputProps>(
  (
    {
      searchKey,
      handleSearchChange,
      disabled,
      className,
      placeholder,
      onInitSearch,
    },
    ref
  ) => {
    const intl = useIntl();
    const classes = useSearchInputStyles({ icon: searchSvg });

    const handleChange = useCallback(
      debounce((value: string) => {
        if (onInitSearch) onInitSearch();

        if (handleSearchChange) handleSearchChange({ value, searchKey });
      }, SEARCH_DEBOUNCE_INTERVAL),
      []
    );

    return (
      <Input
        className={clsx(classes.searchInput, className)}
        name={searchKey}
        data-testid={SEARCH_INPUT_TESTID}
        placeholder={
          placeholder ||
          intl.formatMessage({
            id: 'misc.typeToSearch',
            defaultMessage: 'Type to search',
          })
        }
        onChange={({ target: { value } }) => handleChange(value)}
        {...{ ref, disabled }}
      />
    );
  }
);

export default SearchInput;
