import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as PageNotFoundIllustration } from 'img/illustrations/404.svg';
import { Heading } from 'components/lib/Heading';
import usePageNotFoundStyles from './styles';

const PageNotFound = () => {
  const classes = usePageNotFoundStyles({});

  return (
    <div className={classes.container}>
      <PageNotFoundIllustration className={classes.illustration} />
      <Heading level={3} className={classes.title}>
        <FormattedMessage
          id='misc.pageNotFound'
          defaultMessage='Page not found'
        />
      </Heading>
    </div>
  );
};

export default PageNotFound;
