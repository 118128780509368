import { createUseStyles } from 'react-jss';

const usePasswordRulesStyles = createUseStyles({
  rulesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 15,
    paddingBottom: 63,
  },
});

export default usePasswordRulesStyles;
