import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  ChangeEvent,
} from 'react';
import { Space } from 'components/lib/Space';
import { Select, SelectOption } from 'components/lib/Select';
import { FormattedMessage } from 'react-intl';
import {
  FirstPageIcon,
  PreviousPageIcon,
  NextPageIcon,
  LastPageIcon,
} from 'components/Icon';
import { Input } from 'components/lib/Input';
import { ToolButtonWithTooltip } from 'components/ToolButton';
import useTableWrapperStyles from './styles';
import {
  PaginationGoToFirst,
  PaginationGoToLast,
  PaginationGoToPage,
  PaginationOnChange,
  PaginationOnNext,
  PaginationOnPrevious,
  PaginationProps,
} from './types';
import { TABLE_PAGINATION_ID } from 'utils/elementsIds';
import {
  TABLE_PAGINATION_COUNT_OF_PAGES_TESTID,
  TABLE_PAGINATION_CURRENT_PAGE_TESTID,
  TABLE_PAGINATION_GO_TO_FIRST_TESTID,
  TABLE_PAGINATION_GO_TO_LAST_TESTID,
  TABLE_PAGINATION_GO_TO_NEXT_TESTID,
  TABLE_PAGINATION_GO_TO_PREVIOUS_TESTID,
  TABLE_PAGINATION_SELECT_OPTION_ALL_TESTID,
  TABLE_PAGINATION_SELECT_OPTION_TESTID,
  TABLE_PAGINATION_SELECT_PER_PAGE_TESTID,
  TABLE_PAGINATION_WRAPPER_TESTID,
} from 'utils/testIds';
import { ALL_PAGE_SIZE } from 'hooks/useTableData/consts';
import clsx from 'clsx';
import { Input as AntInput } from 'antd';
import useFormatNumber from 'hooks/useFormatNumber';
import { useTableContext } from 'contexts/TableContext';

const Pagination: React.FC<PaginationProps> = ({
  onPageChange,
  totalCount,
  filteredCount,
  currentPage,
  pageSize,
  tablePageSizes,
  onPageSizeChange,
  isInfinite,
  className,
  loading,
}) => {
  const getWrapper = () =>
    document.getElementById(TABLE_PAGINATION_ID) || document.body;
  const [lastPage, setLastPage] = useState(0);
  const { paginationRender } = useTableContext();
  const [pageInputValue, setPageInputValue] = useState('');
  const formatNumberWithSeparators = useFormatNumber();

  const { isPageSizeDisabled } = useTableContext();

  const inputRef = useRef<AntInput>(null);

  useEffect(() => {
    if (isInfinite) {
      setLastPage(0);

      return;
    }

    if (filteredCount < totalCount) {
      setLastPage(Math.ceil(filteredCount / pageSize));
    } else {
      setLastPage(Math.ceil(totalCount / pageSize));
    }
  }, [filteredCount, pageSize, totalCount, isInfinite]);

  useEffect(() => {
    setPageInputValue(currentPage.toString());
  }, [currentPage]);

  useEffect(() => {
    if (lastPage > 0 && currentPage > lastPage) onPageChange(lastPage);
    else if (currentPage <= 0) onPageChange(1);
  }, [currentPage, lastPage, onPageChange]);

  const goToFirst = useCallback<PaginationGoToFirst>(() => {
    onPageChange(1);
    setPageInputValue('1');
  }, [onPageChange]);

  const goToLast = useCallback<PaginationGoToLast>(() => {
    onPageChange(lastPage);
    setPageInputValue(lastPage.toString());
  }, [lastPage, onPageChange]);

  const goToPage = useCallback<PaginationGoToPage>(
    (e: ChangeEvent<HTMLInputElement>) => {
      const page = parseInt(e.target.value);

      if (page === currentPage) return;

      if (page > lastPage) {
        onPageChange(lastPage);
        setPageInputValue(lastPage.toString());
      } else if (page === 0) {
        onPageChange(1);
        setPageInputValue('1');
      } else {
        onPageChange(page);
      }
    },
    [currentPage, lastPage, onPageChange]
  );

  const onPageKeyDown = useCallback(e => {
    if (e.key === 'Enter' && inputRef.current) {
      inputRef.current.blur();
    }
  }, []);

  const onPrevious = useCallback<PaginationOnPrevious>(() => {
    onPageChange(currentPage - 1);
    setPageInputValue((currentPage - 1).toString());
  }, [currentPage, onPageChange]);

  const onNext = useCallback<PaginationOnNext>(() => {
    onPageChange(currentPage + 1);
    setPageInputValue((currentPage + 1).toString());
  }, [currentPage, onPageChange]);

  const onChange = useCallback<PaginationOnChange>(
    (e: ChangeEvent<HTMLInputElement>) => {
      const onlyNumbersRegex = /^[0-9\b]+$/;
      const value = e.target.value;

      setPageInputValue(onlyNumbersRegex.test(value) ? value : '1');
    },
    []
  );

  const classes = useTableWrapperStyles({});

  if (paginationRender) {
    return paginationRender({
      lastPage,
      isInfinite,
      loading,
      currentPage,
      goToFirst,
      goToLast,
      goToPage,
      onNext,
      onChange,
      onPrevious,
      inputRef,
      className,
    });
  }

  return (
    <Space
      className={clsx(classes.paginationWrapper, className)}
      data-testid={TABLE_PAGINATION_WRAPPER_TESTID}
    >
      {!isInfinite && !!lastPage && (
        <>
          <Space className={classes.buttonsWrapper}>
            <ToolButtonWithTooltip
              tooltipTitle={
                <FormattedMessage
                  id='misc.firstPage'
                  defaultMessage='First page'
                />
              }
              disabled={currentPage === 1 || loading}
              onClick={goToFirst}
              icon={<FirstPageIcon size={8} />}
              data-testid={TABLE_PAGINATION_GO_TO_FIRST_TESTID}
            />
            <ToolButtonWithTooltip
              tooltipTitle={
                <FormattedMessage
                  id='misc.previousPage'
                  defaultMessage='Previous page'
                />
              }
              disabled={currentPage === 1 || loading}
              onClick={onPrevious}
              icon={<PreviousPageIcon size={8} />}
              data-testid={TABLE_PAGINATION_GO_TO_PREVIOUS_TESTID}
            />
            <ToolButtonWithTooltip
              tooltipTitle={
                <FormattedMessage
                  id='misc.nextPage'
                  defaultMessage='Next page'
                />
              }
              onClick={onNext}
              disabled={currentPage === lastPage || loading}
              icon={<NextPageIcon size={8} />}
              data-testid={TABLE_PAGINATION_GO_TO_NEXT_TESTID}
            />
            <ToolButtonWithTooltip
              tooltipTitle={
                <FormattedMessage
                  id='misc.lastPage'
                  defaultMessage='Last page'
                />
              }
              onClick={goToLast}
              disabled={currentPage === lastPage || loading}
              icon={<LastPageIcon size={8} />}
              data-testid={TABLE_PAGINATION_GO_TO_LAST_TESTID}
            />
          </Space>
          <Space className={classes.leftSpaceWrapper}>
            <span>
              <FormattedMessage id='misc.page' defaultMessage='Page:' />
            </span>
            <Space>
              <Input
                ref={inputRef}
                disabled={lastPage === 1 || loading}
                className={classes.input}
                value={pageInputValue}
                onKeyDown={onPageKeyDown}
                onChange={onChange}
                onBlur={goToPage}
                data-testid={TABLE_PAGINATION_CURRENT_PAGE_TESTID}
              />
              <span className={classes.lastPageWrapper}>
                /{' '}
                <span data-testid={TABLE_PAGINATION_COUNT_OF_PAGES_TESTID}>
                  {formatNumberWithSeparators(lastPage)}
                </span>
              </span>
            </Space>
          </Space>
        </>
      )}
      <Space className={classes.spaceWrapper}>
        <span className={classes.perPageWrapper}>
          <FormattedMessage id='misc.perPage' defaultMessage='Per page:' />
        </span>
        <Select
          disabled={
            totalCount <= Number(tablePageSizes[0]) ||
            loading ||
            !!isPageSizeDisabled
          }
          defaultValue={tablePageSizes[0]}
          value={isInfinite ? ALL_PAGE_SIZE : pageSize}
          onChange={onPageSizeChange}
          getPopupContainer={getWrapper}
          data-testid={TABLE_PAGINATION_SELECT_PER_PAGE_TESTID}
        >
          {tablePageSizes.map(size => (
            <SelectOption
              key={size}
              value={size}
              data-testid={`${TABLE_PAGINATION_SELECT_OPTION_TESTID}${size}`}
            >
              {size}
            </SelectOption>
          ))}
          <SelectOption
            value={ALL_PAGE_SIZE}
            data-testid={TABLE_PAGINATION_SELECT_OPTION_ALL_TESTID}
          >
            <FormattedMessage id='misc.all' defaultMessage='All' />
          </SelectOption>
        </Select>
      </Space>
    </Space>
  );
};

export default Pagination;
