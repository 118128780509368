export const SUPPORTED_FILE_FORMATS = [
  'pdf',
  'png',
  'jpg',
  'jpeg',
  'xls',
  'xlsx',
  'doc',
  'docx',
  'ppt',
  'pptx',
  'ppsx',
];
