import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { ACTIONS_COLUMN_WIDTH } from 'components/Table/consts';
import { getBodyRowHeight } from 'pages/MyAccount/components/MyAccountSections/components/PreferencesTab/components/RowSettings/components/styles';
import { getHeaderCellHeight } from 'components/Table/styles';
import { TableDensity } from 'components/Table/enums';

type UseChildClassTableStylesProps = {
  density: TableDensity;
};

export const useChildClassTableStyles = createUseStyles((theme: ThemeState) => {
  return {
    flex: {
      display: 'flex',
    },
    tableBorderLeft: {
      borderRight: '1px solid #D2D9E7',
      marginTop: '10px',
    },
    tableBorderRight: {
      borderLeft: '1px solid #D2D9E7',
      marginTop: '10px',
    },
    tableWrapper: {
      width: '100%',
      maxWidth: '100%',
      overflowX: 'scroll',
      position: 'relative',
      borderBottom: '1px solid #D2D9E7',
    },
    table: {
      minWidth: '100%',
      width: 'fit-content',
      position: 'relative',
      marginTop: 10,
    },
    headerRow: {
      display: 'flex',
      position: 'relative',
      backgroundColor: '#EAEDEF',
      borderTop: '1px solid #D2D9E7',
    },
    headerRowPlaceHolder: {
      backgroundColor: `${theme['primary-color']} !important`,
    },
    row: {
      display: 'flex',
      backgroundColor: '#EAEDEF',
    },
    tableHeadCellOuter: {
      backgroundColor: '#EAEDEF',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      top: 0,
      color: '#2E3F57',
      fontWeight: 700,
      fontSize: 13,
      textTransform: 'uppercase',
      height: ({ density }: UseChildClassTableStylesProps) =>
        getHeaderCellHeight(density),
      width: '100%',
      boxSizing: 'border-box',
    },
    tableHeadCell: {
      backgroundColor: '#EAEDEF',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      top: 0,
      color: '#2E3F57',
      fontWeight: 700,
      fontSize: 13,
      textTransform: 'uppercase',
      height: ({ density }: UseChildClassTableStylesProps) =>
        getHeaderCellHeight(density),
      width: '100%',
      boxSizing: 'border-box',
    },
    tableHeadCellInner: {
      backgroundColor: '#EAEDEF',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      top: 0,
      color: '#2E3F57',
      fontWeight: 700,
      fontSize: 13,
      textTransform: 'uppercase',
      height: ({ density }: UseChildClassTableStylesProps) =>
        getHeaderCellHeight(density),
      width: 'calc(100% - 2px)',
      padding: '0px 15px',
      boxSizing: 'border-box',
    },
    flexAlignCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    actionsHeader: {
      padding: '0 15px',
      backgroundColor: '#EAEDEF',
      marginLeft: 'auto',
      minWidth: ACTIONS_COLUMN_WIDTH,
      width: ACTIONS_COLUMN_WIDTH,
      maxWidth: ACTIONS_COLUMN_WIDTH,
    },
    tableBody: {
      width: '100%',
    },
    tableRows: {
      position: 'sticky',
      '& div': {
        height: (props: UseChildClassTableStylesProps) =>
          `${getBodyRowHeight(props?.density)}px`,
      },
    },
    searchBox: {
      margin: 6,
      marginLeft: 18,
      width: 'var(--search-input-width)',
    },
    resizeHandle: {
      height: 10,
      width: 10,
      backgroundColor: 'black',
    },
    removeColumnButton: {
      transition: 'background-color 0.2s ease-in-out',
      borderRadius: 3,
      height: 22,
      width: 22,
      '&:hover': {
        backgroundColor: '#D5DADE',
      },
    },
  };
});
