export const SET_CUSTOM_COMPONENT = 'SET_CUSTOM_COMPONENT';
export const APPEND_CUSTOM_COMPONENT = 'APPEND_CUSTOM_COMPONENT';
export const SET_CUSTOM_COMPONENT_FETCHING = 'SET_CUSTOM_COMPONENT_FETCHING';
export const SET_TOTAL_CUSTOM_COMPONENT = 'SET_TOTAL_CUSTOM_COMPONENT';
export const RESET_CUSTOM_COMPONENT = 'RESET_CUSTOM_COMPONENT';
export const SET_CUSTOM_COMPONENT_COLUMNS = 'SET_CUSTOM_COMPONENT_COLUMNS';
export const RESET_CUSTOM_COMPONENT_COLUMNS = 'RESET_CUSTOM_COMPONENT_COLUMNS';
export const SET_CUSTOM_COMPONENT_SELECTED_ROW =
  'SET_CUSTOM_COMPONENT_SELECTED_ROW';
export const SET_CUSTOM_COMPONENT_RESTRICTIONS =
  'SET_CUSTOM_COMPONENT_RESTRICTIONS';
