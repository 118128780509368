import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useEmailStyles = createUseStyles((theme: ThemeState) => {
  return {
    link: {
      color: theme['link-color'],
      textDecoration: 'underline',
    },
  };
});

export default useEmailStyles;
