import { createUseStyles } from 'react-jss';

const useNoMatchesFoundStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingBottom: 5,
    paddingTop: 2,
    '& > svg': {
      marginBottom: 6,
    },
  },
});

export default useNoMatchesFoundStyles;
