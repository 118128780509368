import { Parameters } from '../../types';

export const useNumberInputs = (
  parameters: Parameters,
  onChange: (newParams: Parameters) => void
) => {
  const onMultipleOfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newVal = parseFloat(event.target.value);
    //@ts-ignore
    if (Number.isNaN(newVal)) newVal = null;
    onChange({
      ...parameters,
      multipleOf: newVal,
    });
  };

  const onMinimumChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newVal = parseFloat(event.target.value);
    //@ts-ignore
    if (Number.isNaN(newVal)) newVal = null;
    // change either min or exclusiveMin depending on which one is active
    if (parameters.exclusiveMinimum) {
      onChange({
        ...parameters,
        exclusiveMinimum: newVal,
        minimum: undefined,
      });
    } else {
      onChange({
        ...parameters,
        minimum: newVal,
        exclusiveMinimum: undefined,
      });
    }
  };

  const onExlusiveMinimumCheckboxChange = () => {
    const newMin = parameters.minimum || parameters.exclusiveMinimum;
    if (parameters.exclusiveMinimum) {
      onChange({
        ...parameters,
        exclusiveMinimum: undefined,
        minimum: newMin,
      });
    } else {
      onChange({
        ...parameters,
        exclusiveMinimum: newMin,
        minimum: undefined,
      });
    }
  };

  const onMaximumChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newVal = parseFloat(event.target.value);
    //@ts-ignore
    if (Number.isNaN(newVal)) newVal = null;
    // change either max or exclusiveMax depending on which one is active
    if (parameters.exclusiveMinimum) {
      onChange({
        ...parameters,
        exclusiveMaximum: newVal,
        maximum: undefined,
      });
    } else {
      onChange({
        ...parameters,
        maximum: newVal,
        exclusiveMaximum: undefined,
      });
    }
  };

  const onExclusiveMaximumCheckboxChange = () => {
    const newMax = parameters.maximum || parameters.exclusiveMaximum;
    if (parameters.exclusiveMaximum) {
      onChange({
        ...parameters,
        exclusiveMaximum: undefined,
        maximum: newMax,
      });
    } else {
      onChange({
        ...parameters,
        exclusiveMaximum: newMax,
        maximum: undefined,
      });
    }
  };

  return {
    onMultipleOfChange,
    onMinimumChange,
    onExlusiveMinimumCheckboxChange,
    onMaximumChange,
    onExclusiveMaximumCheckboxChange,
  };
};
