import TooltipString from 'components/TooltipString';
import React from 'react';
import useUrlCellStyles from './styles';
import { UrlCellProps } from './types';

const UrlCell = ({ value, label }: UrlCellProps) => {
  const styles = useUrlCellStyles({});

  if (!value) {
    return <>-</>;
  }

  return (
    <TooltipString
      text={label ?? value}
      href={value}
      tagName='a'
      target='_blank'
      className={styles.url}
    />
  );
};

export default UrlCell;
