import routes from 'utils/routingPaths';

export const TASK_DISPLAY_DRAWER_PATHS = [
  routes.RECORDS,
  routes.RECORD,
  routes.TASKS,
];

export const RECORD_FORM_DRAWER_PATHS = [routes.RECORD, routes.CREATE_RECORD];

export const TASK_COMPLETE_FORM_MODAL_ID = 'task-complete-form';
