import { RootState } from 'store/reducers';
import { UserGroup } from 'utils/types/api/userGroups.types';

export const selectUserGroup = (state: RootState) =>
  state.userGroups.allIds.map(id => {
    const userGroup = state.userGroups.byId[id];

    return {
      ...userGroup,
      key: userGroup.id,
    };
  });

export const totalUserGroups = (state: RootState) => {
  return state.userGroups.total;
};

export const filteredUserGroupsCount = (state: RootState) => {
  return state.userGroups.filtered;
};

export const getUserGroupsColumns = (state: RootState) => {
  return state.userGroups.columns;
};

export const getUserGroupsSelectedRow = (state: RootState) => {
  return state.userGroups?.selectedRow;
};

export const getUserGroupsRow = (id: string | undefined) => (
  state: RootState
): UserGroup => {
  if (!id) return {} as UserGroup;

  return state.userGroups.byId[id];
};
