import React from 'react';
import MessageBox from '../MessageBox';
import { FieldValidationMessageProps } from './types';

const FieldValidationMessage = ({
  message,
  error,
  displayAfterParent,
  className,
  classNameOnTop,
  getContainerRef,
}: FieldValidationMessageProps) => {
  if (!!error)
    return (
      <MessageBox
        message={error}
        isError
        {...{
          displayAfterParent,
          className,
          classNameOnTop,
          getContainerRef,
        }}
      />
    );

  if (!!message)
    return (
      <MessageBox
        {...{
          message,
          displayAfterParent,
          className,
          classNameOnTop,
          getContainerRef,
        }}
      />
    );

  return null;
};

export default FieldValidationMessage;
