import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useMultiselectValuesStyles = createUseStyles((theme: ThemeState) => {
  return {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '100%',
    },
    option: {
      backgroundColor: theme['info-bg'],
      marginRight: 5,
      borderRadius: 2,
      color: theme['primary-color'],
      padding: [0, 5],
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      fontSize: 14,
      lineHeight: '26px',
    },
    dropdown: {
      minWidth: 'auto',
      padding: 15,
      '& > div > div': {
        backgroundColor: theme['info-bg'],
      },
    },
  };
});

export default useMultiselectValuesStyles;
