import React, { useMemo } from 'react';
import ActionMenuList from 'components/ActionMenu/ActionMenuList';
import { ActionsMenuProps, RowActions } from './types';
import { useIntl } from 'react-intl';
import { RemoveIcon, ViewIcon } from 'components/Icon';
import { FORM_PREVIEW_WRAPPER } from 'utils/elementsIds';
import { DefaultPermissions } from 'utils/types/api/permissions.types';

const ActionMenuItems = (props: ActionsMenuProps) => {
  const intl = useIntl();
  const {
    id,
    onActionMenuOptionClick,
    permissions: {
      view: viewPermission,
      edit: editPermission,
      list: listPermission,
    } = {} as DefaultPermissions,
    isReadOnly,
  } = props;

  // delete is a calculated permission mainly derrived from the parent (ie can edit the parent) as well as listing permissions for child
  // story #38847
  const deletePermission = !isReadOnly && listPermission && viewPermission;

  const menu = useMemo(
    () => [
      {
        title: intl.formatMessage({
          id: 'misc.open',
          defaultMessage: 'Open',
        }),
        action: () => onActionMenuOptionClick(RowActions.Open),
        icon: <ViewIcon size={18} />,
        hasPermission:
          (viewPermission || editPermission) && props.hasCreateEditViewEnabled,
      },
      {
        title: intl.formatMessage({
          id: 'misc.remove',
          defaultMessage: 'Remove',
        }),
        action: () => onActionMenuOptionClick(RowActions.RemoveChild),
        icon: <RemoveIcon size={18} />,
        hasPermission: !!deletePermission && !isReadOnly,
      },
    ],
    [
      deletePermission,
      editPermission,
      intl,
      onActionMenuOptionClick,
      props,
      viewPermission,
      isReadOnly,
    ]
  );

  return (
    <ActionMenuList
      containerId={FORM_PREVIEW_WRAPPER}
      items={menu}
      {...{ id }}
      tableId={props.tableId}
    />
  );
};

export default ActionMenuItems;
