import React from 'react';
import { Typography as AntTypography } from 'antd';
import { TextProps } from 'antd/lib/typography/Text';
import useTextStyles, { AdditionalTextProps } from './styles';

const { Text: AntText } = AntTypography;

type Props = TextProps & AdditionalTextProps;

export const Text = ({ type, ...rest }: Props) => {
  const classes = useTextStyles(rest);
  const className = type ? classes[type] : classes.default;

  return <AntText {...{ className, ...rest }} />;
};
