import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Input } from 'reactstrap';
import { CardEnumOptionProps } from './types';

// Input field corresponding to an array of values, add and remove
const CardEnumOptions: React.FC<CardEnumOptionProps> = ({
  initialValues,
  names,
  showNames,
  onChange,
  type,
}) => {
  const possibleValues = [];
  for (let index = 0; index < initialValues.length; index += 1) {
    const value = initialValues[index];
    let name = `${value}`;
    if (names && index < names.length) name = names[index];
    possibleValues.push(
      <div key={index} className='card-enum-option'>
        <Input
          value={value === undefined || value === null ? '' : value}
          placeholder='Possible Value'
          key={`val-${index}`}
          type={type === 'string' ? 'text' : 'number'}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            let newVal;
            switch (type) {
              case 'string':
                newVal = ev.target.value;
                break;
              case 'number':
              case 'integer':
                newVal = parseFloat(ev.target.value);
                if (Number.isInteger(newVal))
                  newVal = parseInt(ev.target.value, 10);
                if (Number.isNaN(newVal)) newVal = type === 'number' ? '' : 0;
                break;
              default:
                throw new Error(`Enum called with unknown type ${type}`);
            }
            onChange(
              [
                ...initialValues.slice(0, index),
                newVal,
                ...initialValues.slice(index + 1),
              ],
              names
            );
          }}
          className='card-text'
        />
        <Input
          value={name || ''}
          placeholder='Name'
          key={`name-${index}`}
          type='text'
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            if (names)
              onChange(initialValues, [
                ...names.slice(0, index),
                ev.target.value,
                ...names.slice(index + 1),
              ]);
          }}
          className='card-text'
          style={{ display: showNames ? 'initial' : 'none' }}
        />
        <i
          className='fa fa-trash'
          onClick={() => {
            // remove this value
            onChange(
              [
                ...initialValues.slice(0, index),
                ...initialValues.slice(index + 1),
              ],
              names
                ? [...names.slice(0, index), ...names.slice(index + 1)]
                : undefined
            );
          }}
        />
      </div>
    );
  }

  return (
    <>
      <div className='card-enum-header'>
        <p> Value </p>
        <h5 style={{ display: showNames ? 'initial' : 'none' }}>
          <FormattedMessage
            id='formBuilder.displayLabel'
            defaultMessage='Display label'
          />
        </h5>
      </div>
      {possibleValues}
      <i
        className='fa fa-plus'
        onClick={() => {
          // add a new dropdown option
          onChange(
            [...initialValues, type === 'string' ? '' : 0],
            names ? [...names, ''] : undefined
          );
        }}
      />
    </>
  );
};

export default CardEnumOptions;
