import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useInPlaceEmailInputStyles = createUseStyles((theme: ThemeState) => {
  return {
    emailWrapper: {
      color: theme['primary-color'],
      cursor: 'pointer',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'block',
    },
    tooltip: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  };
});

export default useInPlaceEmailInputStyles;
