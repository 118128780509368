import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { commonPredicates } from './commonPredicates';

const YEAR_FORMAT = 'YYYY-MM-DD';
export const TODAY = moment().format(YEAR_FORMAT);
const YESTERDAY = moment().subtract(1, 'days').format(YEAR_FORMAT);
const THIS_WEEK_START = moment().startOf('isoWeek').format(YEAR_FORMAT);
const THIS_WEEK_END = moment().endOf('isoWeek').format(YEAR_FORMAT);
const WEEK_FUTURE_END = moment().add(6, 'days').format(YEAR_FORMAT);
const WEEK_PAST_START = moment().subtract(7, 'days').format(YEAR_FORMAT);
const TODAY_PLUS_29_DAYS = moment().add(29, 'days').format(YEAR_FORMAT);
const TODAY_MINUS_30_DAYS = moment().subtract(30, 'days').format(YEAR_FORMAT);

export const useDateTimePredicates = () => {
  const intl = useIntl();

  return useMemo(
    () => ({
      is_null: {
        label: intl.formatMessage({
          id: 'predicates.isNull',
          defaultMessage: 'is null',
        }),
        value: 'is_null',
        query: '__isnull=true',
        args: [],
      },
      not_is_null: {
        label: intl.formatMessage({
          id: 'predicates.isNotNull',
          defaultMessage: 'is not null',
        }),
        value: 'not_is_null',
        query: '__isnull=false',
        args: [],
      },
      exact: {
        label: intl.formatMessage({
          id: 'predicates.isEqual',
          defaultMessage: 'is equal to',
        }),
        value: 'exact',
        query: '={value}',
        args: ['value'],
      },
      not_exact: {
        label: intl.formatMessage({
          id: 'predicates.isNotEqual',
          defaultMessage: 'is not equal to',
        }),
        value: 'not_exact',
        query: '!={value}',
        args: ['value'],
      },
      lt: {
        label: intl.formatMessage({
          id: 'predicates.isLessThan',
          defaultMessage: 'is less than',
        }),
        value: 'lt',
        query: '__lt={value}',
        args: ['value'],
      },
      lte: {
        label: intl.formatMessage({
          id: 'predicates.isLessOrEqual',
          defaultMessage: 'is less than or equal to',
        }),
        value: 'lte',
        query: '__lte={value}',
        args: ['value'],
      },
      gt: {
        label: intl.formatMessage({
          id: 'predicates.isGreaterThan',
          defaultMessage: 'is greater than',
        }),
        value: 'gt',
        query: '__gt={value}',
        args: ['value'],
      },
      gte: {
        label: intl.formatMessage({
          id: 'predicates.isGreaterOrEqual',
          defaultMessage: 'is greater than or equal to',
        }),
        value: 'gte',
        query: '__gte={value}',
        args: ['value'],
      },
      range: {
        label: intl.formatMessage({
          id: 'predicates.range',
          defaultMessage: 'is between',
        }),
        value: 'range',
        query: '__range={value1},{value2}',
        args: ['value1', 'value2'],
      },
      not_range: {
        label: intl.formatMessage({
          id: 'predicates.notInRange',
          defaultMessage: 'is not between',
        }),
        value: 'not_range',
        query: '__range!={value1},{value2}',
        args: ['value1', 'value2'],
      },
      today: {
        label: intl.formatMessage({
          id: 'predicates.today',
          defaultMessage: 'Today',
        }),
        value: 'today',
        query: `=${TODAY}`,
        args: [],
      },
      yesterday: {
        label: intl.formatMessage({
          id: 'predicates.yesterday',
          defaultMessage: 'Yesterday',
        }),
        value: 'yesterday',
        query: `=${YESTERDAY}`,
        args: [],
      },
      this_week: {
        label: intl.formatMessage({
          id: 'predicates.thisWeek',
          defaultMessage: 'This week',
        }),
        value: 'this_week',
        query: `__range=${THIS_WEEK_START},${THIS_WEEK_END}`,
        args: [],
      },
      within_week: {
        label: intl.formatMessage({
          id: 'predicates.withinWeek',
          defaultMessage: 'Within a week',
        }),
        value: 'within_week',
        query: `__range=${TODAY},${WEEK_FUTURE_END}`,
        args: [],
      },
      within_last_week: {
        label: intl.formatMessage({
          id: 'predicates.withinLastWeek',
          defaultMessage: 'Within the last week',
        }),
        value: 'within_last_week',
        query: `__range=${WEEK_PAST_START},${YESTERDAY}`,
        args: [],
      },
      within_30_days: {
        label: intl.formatMessage({
          id: 'predicates.within30Days',
          defaultMessage: 'Within 30 days',
        }),
        value: 'within_30_days',
        query: `__range=${TODAY},${TODAY_PLUS_29_DAYS}`,
        args: [],
      },
      within_last_30_days: {
        label: intl.formatMessage({
          id: 'predicates.withinLast30Days',
          defaultMessage: 'Within the last 30 days',
        }),
        value: 'within_last_30_days',
        query: `__range=${TODAY_MINUS_30_DAYS},${YESTERDAY}`,
        args: [],
      },
      ...commonPredicates(intl),
    }),
    [intl]
  );
};
