import { Reducer } from 'redux';
import {
  CLEAR_CHILD_CLASSES,
  SET_CHILD_CLASSES,
} from 'store/constants/childClasses.consts';
import { RootAction } from '.';
import { ChildClassesState } from './types/childClasses.types';

const initialState: ChildClassesState = [];

const ChildClassesReducer: Reducer<ChildClassesState, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_CHILD_CLASSES:
      return [...state, ...action.payload];
    case CLEAR_CHILD_CLASSES:
      return [];
    default:
      return state;
  }
};

export default ChildClassesReducer;
