export enum DroppableFormBuilderAreas {
  FormBuilderContent = 'formBuilderContent',
  FormBuilderInputList = 'formBuilderInputList',
  FormBuilderUiList = 'formBuilderUiList',
}

export enum FormBuilderDroppableTypes {
  Sections = 'sections',
  Components = 'components',
}

export enum InputTypes {
  ShortAnswer = 'shortAnswer',
  LongAnswer = 'longAnswer',
  Number = 'number',
  Checkbox = 'checkbox',
  Radio = 'radio',
  Dropdown = 'dropdown',
  Date = 'date',
  DateTime = 'date-time',
  Time = 'time',
  Email = 'email',
  CountrySelect = 'country-select',
  PhoneNumber = 'phoneNumber',
  File = 'file',
  Multiselect = 'multiselect',
  Instruction = 'instruction',
  Owners = 'owners',
  HTML = 'HTML',
  URL = 'url',
  ChildClass = 'child_class',
  JSON = 'json',
  User = 'user',
  ExternalComponent = 'external_content',
}

export enum WidgetTypes {
  MultiselectCheckbox = 'checkboxes',
}
