import React from 'react';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import DocumentTemplatesPanelTable from './components/DocumentTemplatesPanelTable';
import useDocumentTemplatesPanelStyles from './styles';

const DocumentTemplatesPanel = () => {
  const classes = useDocumentTemplatesPanelStyles();
  const { selectedResource } = useSelectedResourceContext();

  const identifier = selectedResource?.record?.identifier;
  const recordId = selectedResource?.record?.recordId;
  const permissions = selectedResource?.record?.permissions;

  return (
    <div className={classes.panelWrapper}>
      <div className={classes.identifier}>{identifier}</div>
      {recordId && (
        <DocumentTemplatesPanelTable {...{ recordId, permissions }} />
      )}
    </div>
  );
};

export default DocumentTemplatesPanel;
