import React, { createContext, useContext, useState } from 'react';
import noop from 'lodash/noop';
import {
  ConfirmationModalContextType,
  ConfirmationModalID,
  MappedObjectShouldBeDisplayed,
  MappedObjectToggleModalCallback,
  ToggleModalCallback,
} from './types';
import FlexLayoutWindows from '../utils/Enums/FlexLayoutWindows';

const ConfirmationModalContext = createContext<ConfirmationModalContextType>({
  setShouldBeDisplayed: noop,
  shouldBeDisplayed: undefined,
  storedModalFunctions: undefined,
  setStoredModalFunctions: noop,
  windowToOpen: undefined,
  setWindowToOpen: noop,
  redirectId: undefined,
  setRedirectId: noop,
});

export const useConfirmationModalContext = () =>
  useContext(ConfirmationModalContext);

const ModalProvider = ConfirmationModalContext.Provider;

export const ConfirmationModalContextProvider: React.FC = ({ children }) => {
  const [shouldBeDisplayed, setShouldBeDisplayed] = useState<
    undefined | MappedObject<boolean, ConfirmationModalID>
  >();
  const [storedModalFunctions, setStoredModalFunctions] = useState<
    MappedObjectToggleModalCallback | undefined
  >();
  const [windowToOpen, setWindowToOpen] = useState<
    FlexLayoutWindows | undefined
  >();
  const [redirectId, setRedirectId] = useState<string | number | undefined>();

  const handleSetShouldBeDisplayed = (
    id: ConfirmationModalID,
    value: boolean
  ) => {
    setShouldBeDisplayed(
      (restPanels = {} as MappedObjectShouldBeDisplayed) => ({
        ...restPanels,
        [id]: value,
      })
    );
  };

  const handleSetStoredModalFunctions = (
    id: ConfirmationModalID,
    value: ToggleModalCallback | undefined
  ) => {
    setStoredModalFunctions(
      (restPanels = {} as MappedObjectToggleModalCallback) => ({
        ...restPanels,
        [id]: value,
      })
    );
  };

  return (
    <ModalProvider
      value={{
        setShouldBeDisplayed: handleSetShouldBeDisplayed,
        shouldBeDisplayed,
        setStoredModalFunctions: handleSetStoredModalFunctions,
        storedModalFunctions,
        windowToOpen,
        setWindowToOpen,
        redirectId,
        setRedirectId,
      }}
    >
      {children}
    </ModalProvider>
  );
};
