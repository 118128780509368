import Tooltip from 'components/lib/Tooltip';
import React from 'react';
import { useDisabledButtonTooltipStyles } from './styles';
import { DisabledButtonTooltipProps } from './types';

// this component allows to create tooltip over disabled buttons
// it adds an overlay over button which allows tooltip to pick mouseEnter/mouseLeave events
// which are blocked by disabled button by mouseEvents: none

const DisabledButtonTooltip = ({
  title,
  children,
  showTooltip,
}: DisabledButtonTooltipProps) => {
  const classes = useDisabledButtonTooltipStyles({});

  if (!showTooltip) return children;

  return (
    <Tooltip {...{ title }}>
      <div className={classes.buttonWrapper}>
        {children}
        <div className={classes.overlay}></div>
      </div>
    </Tooltip>
  );
};

export default DisabledButtonTooltip;
