import { RecordPeopleWithCount } from 'pages/Records/types';
import { RootState } from 'store/reducers';

export const getObjectRecordUsersError = (state: RootState) =>
  state.objectRecord.usersError;

export const getObjectRecordUsersLoading = (state: RootState) =>
  state.objectRecord.usersFetching;

export const getObjectRecordUsersData = (recordId: string | undefined) => (
  state: RootState
) =>
  (recordId === undefined
    ? {}
    : state.objectRecord.users[recordId] || {}) as RecordPeopleWithCount;

export const getClassPermissionSets = (state: RootState) =>
  state.objectRecord.classPerissionSets;
