/**
 * Extracts a file extension from filename.
 * @param fileName full file name. Preferably with extension (e.g. .pdf, .docx etc.)
 * @returns file extension as string (e.g. "pdf") or undefined if extensions couldn't be extracted (filename has no extension, empty filename etc.).
 */
export const getFileExtension = (fileName: string): string | undefined => {
  const separated = fileName.split('.');

  if (separated.length <= 1) {
    return undefined;
  }

  return separated.pop()?.toLowerCase();
};
