import React from 'react';
import EmptyData from 'components/EmptyData';
import FlexContentWrapper from 'components/wrappers/FlexContentWrapper';
import { useIntl } from 'react-intl';
import useListStyles from './styles';
import { ListProps } from './types';
import { ReactComponent as EmptyBox } from 'img/illustrations/empty-box.svg';
import { LIST_TEST_ID } from 'utils/testIds';
import clsx from 'clsx';

const List = <ItemType extends object>({
  items = [],
  renderItem,
  shouldShowEmptyDataTitle,
  emptyDataDescription,
  listItemElementWrapperClassName,
  hideEmptyData,
}: ListProps<ItemType>) => {
  const intl = useIntl();
  const classes = useListStyles({});

  return (
    <div className={classes.listWrapper} data-testid={LIST_TEST_ID}>
      {items.length ? (
        items.map((item, index) => (
          <div
            className={clsx(
              classes.listElementWrapper,
              listItemElementWrapperClassName
            )}
            key={`listElem-${index}`}
          >
            {renderItem(item)}
          </div>
        ))
      ) : (
        <FlexContentWrapper>
          {!hideEmptyData && (
            <EmptyData
              title={
                shouldShowEmptyDataTitle
                  ? intl.formatMessage({
                      id: 'misc.noResults',
                      defaultMessage: 'No data found',
                    })
                  : ''
              }
              description={emptyDataDescription}
              image={<EmptyBox />}
            />
          )}
        </FlexContentWrapper>
      )}
    </div>
  );
};

export default List;
