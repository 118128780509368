enum ButtonType {
  Default = 'default',
  Primary = 'primary',
  Secondary = 'secondary',
  Warning = 'warning',
  Link = 'link',
  Danger = 'danger',
}

export default ButtonType;
