import { TableDensity } from 'components/Table/enums';
import { getHeaderCellHeight } from 'components/Table/styles';
import { createUseStyles } from 'react-jss';

type UseDisabledResizerStylesProps = {
  density: TableDensity;
};

export const useDisabledResizerStyles = createUseStyles({
  resizer: {
    cursor: 'col-resize',
    marginLeft: 'auto',
    padding: '0 2px',
    '&:after': {
      display: 'block',
      content: '""',
      width: 2,
      height: ({ density }: UseDisabledResizerStylesProps) =>
        getHeaderCellHeight(density),
      background: '#ced5da',
    },
    '&.disabled': {
      cursor: 'default!important',
    },
  },
});
