import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootAction, RootState } from 'store/reducers';
import {
  SET_GLOBAL_HELP_MAPPING,
  SET_GLOBAL_LANDING_PAGE,
  SET_GLOBAL_PREFERENCES_LOADING,
  SET_SAVING_GLOBAL_PREFERENCES,
} from 'store/constants/globalPreferences.consts';
import { apiCall } from 'utils/api';
import {
  UI_CONFIG_HELP_MAPPING,
  UI_CONFIG_LANDING_PAGE,
} from 'utils/endpoints';
import { isNotFound, isSuccess } from 'utils/apiUtils';
import {
  getLandingPageDataSelector,
  getMappedAppRoutesToHelpPanelSelector,
} from 'store/selectors/globalPreferencesSelectors';
import errorToast from 'utils/functions/errorToast';
import { HelpMappingValues } from 'pages/HelpMapping/components/FormWrapper/types';
import {
  LandingPageFormValues,
  LandingPagePostBody,
} from 'pages/LandingPage/types';

export interface SetGlobalHelpMapping
  extends Action<typeof SET_GLOBAL_HELP_MAPPING> {
  payload: HelpMappingValues;
}

export interface SetGlobalLandingPage
  extends Action<typeof SET_GLOBAL_LANDING_PAGE> {
  payload: LandingPageFormValues;
}
export interface SetGlobalPreferencesLoadingAction
  extends Action<typeof SET_GLOBAL_PREFERENCES_LOADING> {
  loading: boolean;
}

export interface SetSavingGlobalPreferencesAction
  extends Action<typeof SET_SAVING_GLOBAL_PREFERENCES> {
  saving: boolean;
}

export type GlobalPreferencesAction =
  | SetGlobalHelpMapping
  | SetGlobalLandingPage
  | SetGlobalPreferencesLoadingAction
  | SetSavingGlobalPreferencesAction;

export const setGlobalHelpMapping = (
  payload: HelpMappingValues
): SetGlobalHelpMapping => ({
  type: SET_GLOBAL_HELP_MAPPING,
  payload,
});

export const setGlobalLandingPage = (
  payload: LandingPageFormValues
): SetGlobalLandingPage => ({
  type: SET_GLOBAL_LANDING_PAGE,
  payload,
});

export const setGlobalPreferencesLoading = (
  loading: boolean
): SetGlobalPreferencesLoadingAction => ({
  type: SET_GLOBAL_PREFERENCES_LOADING,
  loading,
});

export const setSavingGlobalPreferences = (
  saving: boolean
): SetSavingGlobalPreferencesAction => ({
  type: SET_SAVING_GLOBAL_PREFERENCES,
  saving,
});

export const getHelpMappingPreferences = (): ThunkAction<
  Promise<void>,
  RootState,
  undefined,
  RootAction
> => async (dispatch, getState) => {
  try {
    const { data, status } = await apiCall.get<HelpMappingValues>(
      UI_CONFIG_HELP_MAPPING
    );

    if (isSuccess(status)) {
      dispatch(setGlobalHelpMapping(data));
    }
  } catch (error) {
    //help mapping not found - fallback to defaults
    if (isNotFound(error)) {
      try {
        await apiCall.post(UI_CONFIG_HELP_MAPPING, {
          content: getMappedAppRoutesToHelpPanelSelector(getState()),
        });
      } catch {
        // there is nothing we can do here
        // either the endpoint has changed or the user does not have permission
        console.warn('default help mapping not peristed');
      }
    }
  }
};

export const getLandingPagePreferences = (): ThunkAction<
  Promise<void>,
  RootState,
  undefined,
  RootAction
> => async (dispatch, getState) => {
  try {
    const { data, status } = await apiCall.get<LandingPageFormValues>(
      UI_CONFIG_LANDING_PAGE
    );

    if (isSuccess(status)) {
      dispatch(setGlobalLandingPage(data));
    }
  } catch (error) {
    //landing page not found - fallback to defaults
    if (isNotFound(error)) {
      try {
        apiCall.post(UI_CONFIG_LANDING_PAGE, {
          content: getLandingPageDataSelector(getState()),
        });
      } catch {
        // there is nothing we can do here
        // either the endpoint has changed or the user does not have permission
        console.warn('default landing page not peristed');
      }
    }
  }
};

export const updateHelpMappingPreferences = (
  helpMapping: HelpMappingValues
): ThunkAction<
  Promise<void>,
  RootState,
  undefined,
  GlobalPreferencesAction
> => async dispatch => {
  dispatch(setSavingGlobalPreferences(true));

  try {
    const { status } = await apiCall.post(UI_CONFIG_HELP_MAPPING, {
      content: helpMapping,
    });

    if (isSuccess(status)) dispatch(setGlobalHelpMapping(helpMapping));
    else errorToast();
  } catch (error) {
    errorToast();
  } finally {
    dispatch(setSavingGlobalPreferences(false));
  }
};

export const updateLandingPagePreferences = (
  landingPageData: LandingPagePostBody
): ThunkAction<
  Promise<void>,
  RootState,
  undefined,
  GlobalPreferencesAction
> => async dispatch => {
  dispatch(setSavingGlobalPreferences(true));

  const { status } = await apiCall.post(
    UI_CONFIG_LANDING_PAGE,
    landingPageData
  );

  if (isSuccess(status))
    dispatch(setGlobalLandingPage(landingPageData.content));
  else errorToast();
  dispatch(setSavingGlobalPreferences(false));
};
