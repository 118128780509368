import { StatusCodes } from 'http-status-codes';

export enum ErrorCodes {
  ERR_LIMIT_EXCEEDED = 'ERR_LIMIT_EXCEEDED',
  ERR_INVALID_TOKEN = 'ERR_INVALID_TOKEN',
}

export type ErrorStatus =
  | boolean
  | StatusCodes.FORBIDDEN
  | StatusCodes.NOT_FOUND
  | StatusCodes.BAD_REQUEST
  | StatusCodes.METHOD_NOT_ALLOWED;

export type ErrorMessages = MappedObject<string>;

export interface ResponseError {
  status: ErrorStatus;
  messages?: ErrorMessages;
}
