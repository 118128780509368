import React from 'react';
import { ReactComponent as UnsupportedFileFormatIllustration } from 'img/illustrations/unsupported-file-format.svg';
import { useUnsupportedFileFormatStyles } from './UnsupportedFileFormatNotice.styles';
import { FormattedMessage } from 'react-intl';

export const UnsupportedFileFormatNotice = () => {
  const styles = useUnsupportedFileFormatStyles();

  return (
    <div className={styles.container}>
      <UnsupportedFileFormatIllustration />
      <span className={styles.noticeText}>
        <FormattedMessage
          id={'fileViewer.unsupportedFormat'}
          defaultMessage={
            'Unable to display this file type. Please download the file.'
          }
        />
      </span>
    </div>
  );
};
