import { createUseStyles } from 'react-jss';

const useNoTasksFoundMessage = createUseStyles({
  container: {
    paddingTop: 90,
    textAlign: 'center',
  },
  illustration: {
    maxWidth: 300,
  },
  title: {
    fontWeight: 'bold!important',
    fontSize: `18px!important`,
  },
  textContainer: {
    marginTop: 35,
    textAlign: 'center',
  },
});

export default useNoTasksFoundMessage;
