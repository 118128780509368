import { ResetPasswordFields } from './enums';

export const ResetPasswordValidation = {
  [ResetPasswordFields.Password]: {
    required: true,
  },
  [ResetPasswordFields.ConfirmPassword]: {
    required: true,
  },
};
