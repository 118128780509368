import React from 'react';
import InPlaceEditDateTime from 'components/InPlaceEditControls/InPlaceEditDateTime';
import { mapCustomWidgetPropsToBaseInPlaceEditProps } from './utils';
import { Widget } from 'alx-dynamic-form';
import { Provider } from 'react-redux';
import { store } from 'store/store';

const AdaptedInPlaceEditDatepicker: Widget = widgetProps => {
  const baseInPlaceEditProps = mapCustomWidgetPropsToBaseInPlaceEditProps<
    string
  >(widgetProps);

  return (
    <Provider store={store}>
      <InPlaceEditDateTime {...{ ...baseInPlaceEditProps }} withUnderline />
    </Provider>
  );
};

export default AdaptedInPlaceEditDatepicker;
