import { createUseStyles } from 'react-jss';

type UseChildClassEmptyTableProps = {
  tableWidth: number | string;
  rowHeight: number;
};

const useChildClassEmptyTable = createUseStyles({
  emptyTable: {
    padding: '0px 15px',
    position: 'absolute',
    maxWidth: ({ tableWidth }: UseChildClassEmptyTableProps) => tableWidth,
    lineHeight: ({ rowHeight }: UseChildClassEmptyTableProps) =>
      `${rowHeight}px`,
  },
});

export default useChildClassEmptyTable;
