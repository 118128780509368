import React from 'react';
import { FormattedMessage } from 'react-intl';
import usePropertyFieldStyles from './styles';
import { PropertyFieldProps } from './types';

const PropertyField = ({ value }: PropertyFieldProps) => {
  const classes = usePropertyFieldStyles({});

  return (
    <span className={classes.disabledText}>
      {value ?? (
        <FormattedMessage
          id='formBuilder.visibleOnlyWhenEditing'
          defaultMessage='(visible only when editing)'
        />
      )}
    </span>
  );
};

export default PropertyField;
