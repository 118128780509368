import { Dispatch, SetStateAction } from 'react';

export interface OwnerToDeleteType {
  ownershipId: number;
  displayName: string;
}

export interface RemoveOwnerModalProps {
  objectRecordId: string;
  ownerToDelete: OwnerToDeleteType | undefined;
  isLoading: boolean;
  isRemovingHimself: boolean;
  setOwnerToDelete: Dispatch<SetStateAction<OwnerToDeleteType | undefined>>;
  removeObjectRecordOwner: (
    objectRecordId: string,
    ownershipId: number
  ) => Promise<void>;
}

export enum PermissionSetSelectOptions {
  All = -2,
  Owners = -1,
}
