import { findByPath, parseStackToPath } from 'hooks/useNestedRecordsTree/utils';
import isEqual from 'lodash/isEqual';
import { RootState } from 'store/reducers';
import { NestedObjectRecordsModalTypes } from 'store/reducers/types/nestedObjectRecords.types';
import { ClassID } from 'utils/types/api/table.types';

export const selectNestedObjectRecords = (classID: ClassID) => (
  state: RootState
) =>
  state.nestedObjectRecords.data[classID]?.allIds?.map(id => {
    const tableNameSingle = state.nestedObjectRecords.data[classID]?.byId[id];

    return {
      ...tableNameSingle,
      key: tableNameSingle.id,
    };
  });

export const getNestedObjectRecordsLoading = (classID: ClassID) => (
  state: RootState
) => state.nestedObjectRecords.data[classID]?.fetching;

export const getNestedObjectRecordsTotal = (classID: ClassID) => (
  state: RootState
) => state.nestedObjectRecords.data[classID]?.total;

export const getNestedObjectRecordsLimitItems = (classID: ClassID) => (
  state: RootState
) =>
  state.nestedObjectRecords.data[classID].restrictions
    .limit_items_in_object_class;

export const getNestedObjectRecordsFiltered = (classID: ClassID) => (
  state: RootState
) => state.nestedObjectRecords.data[classID]?.filtered;

export const getNestedObjectRecordsError = (classID: ClassID) => (
  state: RootState
) => state.nestedObjectRecords.data[classID].error;

export const getNestedObjectRecordsSelectedRow = (state: RootState) =>
  state.nestedObjectRecords.selectedRow;

export const selectStack = (state: RootState) => {
  return state.nestedObjectRecords.stack;
};

export const selectNestedObjectRecordsModal = (
  modalId: NestedObjectRecordsModalTypes
) => (state: RootState) =>
  state.nestedObjectRecords.nestedRecordsStackModals?.[modalId];

export const selectNestedRecordsTree = (state: RootState) =>
  state.nestedObjectRecords.tree;

export const selectNestedRecordsTreeFromTopStack = (state: RootState) => {
  const stack = selectStack(state);
  const path = parseStackToPath(stack);
  return findByPath({ root: state.nestedObjectRecords.tree, path });
};
export const selectNestedRecordsTreeFromTopStackByClassID = (
  classId: string
) => (state: RootState): number[] | undefined => {
  const stack = selectStack(state);
  const path = parseStackToPath(stack);
  const found = findByPath({
    root: state.nestedObjectRecords.tree,
    path,
  });

  if (!found?.children || !Object.keys(found.children).length) {
    return undefined;
  }

  return Object.values(found.children)
    .filter(child => child.classId === classId && !!child.isCreated) //only created relationships are fetched from the API
    .map(child => Number(child.nodeId));
};

export const selectNoRelationshipCreatedChildren = (classId: string) => (
  state: RootState
) => {
  const stack = selectStack(state);
  const targetIndex = stack.findIndex(element => element.classId === classId);
  const path = parseStackToPath(stack.slice(0, targetIndex + 1));
  const found = findByPath({
    root: state.nestedObjectRecords.tree,
    path,
  });
  if (!found?.children || !Object.keys(found.children).length) {
    return undefined;
  }

  const notLinkedChildren = Object.entries(found.children)
    .filter(([, value]) => !value.isRelationshipCreated && !!value.isCreated)
    .map(([key]) => key);

  return notLinkedChildren;
};

export const selectTopStackParent = (state: RootState) => {
  const stack = selectStack(state);
  const path = parseStackToPath(stack);
  const parentPath = path.slice(0, path.length - 1);

  return findByPath({
    root: state.nestedObjectRecords.tree,
    path: parentPath,
  });
};

export const nestedRecordByClassIdComparisonFunc = (
  prev: (number | string)[] | undefined,
  next: (number | string)[] | undefined
) => isEqual(prev, next);
