import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useChildClassFormBuilderHeaderStyles = createUseStyles(
  (theme: ThemeState) => ({
    inputIllustration: {
      width: 20,
      height: 20,
      minWidth: 20,
      color: theme['primary-color'],
      marginRight: 10,
    },
  })
);

export default useChildClassFormBuilderHeaderStyles;
