import { RootState } from 'store/reducers';
import { Filter, FilterMap, FilterValue } from 'store/actions/filtersActions';
import TablesType from 'utils/Enums/TablesType';
import { PreferencesTypes } from 'utils/types/api/preferences.types';

export const getTableFilters = (state: RootState) => state.filters;
export const getCurrentTable = (state: RootState) => state.filters.currentTable;
export const getCurrentTableFilters = (state: RootState): FilterValue[] => {
  const { currentTable, sourceId } = state.filters;
  const { selectedClassId } = state.preferences?.data[
    PreferencesTypes.GeneralPreferences
  ];
  const id = sourceId || selectedClassId;
  if (currentTable) {
    const filters = (state.filters.tables as MappedObject<FilterMap>)[
      currentTable
    ];
    if (id && Array.isArray(filters?.[id]))
      return (filters as MappedObject<Filter>)[id];

    if (Array.isArray(filters)) return filters;
  }

  return [];
};
export const shouldApplyFilters = (state: RootState) => {
  const { applyFilter } = state.filters;
  return applyFilter;
};

export const getCurrentTableColumns = (
  classID?: string,
  customTableName?: TablesType
) => (state: RootState) => {
  const currentTable = customTableName ?? state.filters.currentTable;

  if (currentTable) {
    if (currentTable === TablesType.NestedTable && classID) {
      return state.nestedObjectRecords.data[classID]?.columns;
    }
    return (state as MappedObject<any>)[currentTable].columns;
  }

  return {};
};

export const getCurrentTableFetching = (
  classID?: string,
  customTableName?: TablesType
) => (state: RootState): boolean => {
  const currentTable = customTableName ?? state.filters.currentTable;

  if (currentTable) {
    if (classID && currentTable === TablesType.NestedTable) {
      return state.nestedObjectRecords.data[classID]?.fetching;
    }
    return (state as MappedObject<any>)[currentTable].fetching;
  }

  return false;
};

export const getSourceId = (state: RootState) => state.filters.sourceId;

export const getAppliedFilters = (state: RootState) =>
  state.filters.appliedFilters;
