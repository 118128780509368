import { createUseStyles } from 'react-jss';

export const useChildClassWidgetStyles = createUseStyles({
  inputLabel: {
    textAlign: 'justify',
    paddingRight: 5,
    fontSize: 14,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 'calc(100%)',
    paddingBottom: 0,
  },
  container: {
    overflowX: 'auto',
    padding: 10,
    borderColor: '#D2D9E7',
    border: '1px solid',
    backgroundColor: '#FFFFFF',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 15,
  },
  overlayContainer: {
    flex: 1,
    maxWidth: 'calc(100% - 72px)',
    marginTop: 1.5,
  },
  resultsContainer: {
    width: 72,
  },
});

export const useChildClassComponentStyles = createUseStyles({
  container: {
    width: 'fit-content',
  },
});

export const useChildCFormBuilderlassComponentStyles = createUseStyles({
  container: {
    width: '100%',
  },
});
