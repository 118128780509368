import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import orderBy from 'lodash/orderBy';
import FormField from 'pages/TaskTemplates/components/FormField';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import PeopleListElement from 'pages/Records/RecordsListing/RecordAccessPanel/components/PeopleListElement';
import AutocompleteUsersSelect from 'components/AutocompleteUsersSelect';
import OptionAvatar from 'components/OptionAvatar';
import Popover from 'components/lib/Popover';
import { Col } from 'components/lib/Grid';
import { Alert } from 'components/lib/Alert';
import { ExclamationMarkSquare } from 'components/Icon';
import { CustomAvatarGroup } from 'components/lib/Avatar';
import { ButtonLink } from 'components/lib/Button';
import { useToggle } from 'hooks/useToggle';
import { USERS_LIST_AUTOCOMPLETE } from 'utils/endpoints';
import { ASSIGNEES_SECTION_ID } from 'utils/elementsIds';
import { SelectUserOption } from 'utils/types/selectInput.types';
import { OBJECT_CLASS_FIELD_USERS_EDIT } from 'utils/testIds';
import { ClassFieldForm, ClassFieldFormFields } from '../types';
import useClassFieldPropertiesStyles from '../styles';
import { maxUsersCount } from './consts';
import { useUsersValidation } from './hooks';

const FieldUserOptions: React.FC = () => {
  const intl = useIntl();
  const classes = useClassFieldPropertiesStyles({});
  const [isOpen, { toggleOff, toggle }] = useToggle();
  const {
    alerts: { limitUsersError, deletedUsersError, deletedUsersInfo },
    clearInfoMessage,
  } = useUsersValidation();
  const alertMessage = limitUsersError || deletedUsersError || deletedUsersInfo;
  const isCloseable =
    !!deletedUsersInfo && !deletedUsersError && !limitUsersError;

  const {
    values: { users = [] },
    setFieldValue,
  } = useFormikContext<ClassFieldForm>();

  const handleChange = useCallback(
    (user: SelectUserOption | undefined) => {
      if (users.length + 1 >= maxUsersCount) toggleOff();
      setFieldValue(ClassFieldFormFields.Users, [...(users || []), user]);
    },
    [setFieldValue, toggleOff, users]
  );

  const handleClear = useCallback(() => {
    setFieldValue(ClassFieldFormFields.Users, undefined);
  }, [setFieldValue]);

  const handleDelete = useCallback(
    (id: number) => {
      const updatedUsers = users.filter(user => user.id !== id);
      setFieldValue(ClassFieldFormFields.Users, updatedUsers);
      if (updatedUsers.length === 0) toggleOff();
    },
    [setFieldValue, toggleOff, users]
  );

  const checkIfSelected = useCallback(
    (user: { id: number }) => {
      return users.some(({ id }) => id === user.id);
    },
    [users]
  );

  const getPopupContainer = () =>
    document.getElementById(ASSIGNEES_SECTION_ID) || document.body;

  const handleOnEditClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      toggle();
    },
    [toggle]
  );

  const avatarsItems = useMemo(
    () =>
      users.map(
        ({
          first_name: firstName,
          last_name: lastName,
          id,
          account_type: accountType,
        }) => ({ firstName, lastName, id, accountType })
      ),
    [users]
  );

  const popoverContent = useMemo(
    () => (
      <div className={classes.popoverContainer}>
        {orderBy(users, [
          ({ first_name: name }) => name.toLowerCase(),
          ({ last_name: surname }) => surname.toLowerCase(),
        ]).map(
          ({
            first_name: firstName,
            last_name: lastName,
            id,
            account_type: accountType,
          }) => (
            <div key={id}>
              <PeopleListElement
                {...{ firstName, lastName, id, accountType }}
                onDelete={() => handleDelete(id)}
              />
            </div>
          )
        )}
      </div>
    ),
    [classes.popoverContainer, handleDelete, users]
  );

  return (
    <FormField gutter={0}>
      {alertMessage && (
        <Alert
          type='error'
          message={alertMessage}
          icon={<ExclamationMarkSquare size={18} />}
          closable={isCloseable}
          afterClose={clearInfoMessage}
          className={classes.alert}
          showIcon
        />
      )}
      <Col span={24}>
        <FormLabel required>
          <FormattedMessage
            id='objectClasses.fields.usersSelected'
            defaultMessage='Users that can be selected'
          />
        </FormLabel>
        <div className={classes.fieldUserOptionsWrapper}>
          <AutocompleteUsersSelect
            selectMultiple
            onChange={handleChange}
            onClear={handleClear}
            placeholder={intl.formatMessage({
              id: 'placeholders.searchForUsers',
              defaultMessage: 'Search for users',
            })}
            allowClear
            disabled={users.length >= maxUsersCount}
            autocompleteUrl={USERS_LIST_AUTOCOMPLETE}
            checkOptionIsDisabled={checkIfSelected}
            renderOption={(option, searchValue) => (
              <OptionAvatar
                disabledText={
                  checkIfSelected(option)
                    ? intl.formatMessage({
                        id: 'misc.alreadyAdded',
                        defaultMessage: 'Already added',
                      })
                    : ''
                }
                selected={checkIfSelected(option)}
                disabled={checkIfSelected(option)}
                {...{ option, searchValue }}
              />
            )}
          />
        </div>
      </Col>
      <CustomAvatarGroup items={avatarsItems} disablePopover={isOpen} />
      <Popover
        content={popoverContent}
        trigger='contextMenu'
        visible={isOpen}
        onVisibleChange={(value: boolean) => !value && toggleOff()}
        {...{ getPopupContainer }}
        overlayClassName={classes.popoverWrapper}
      >
        {!!users.length && (
          <div className={classes.avatarWrapper}>
            <ButtonLink
              onClick={handleOnEditClick}
              data-testid={OBJECT_CLASS_FIELD_USERS_EDIT}
            >
              <FormattedMessage id='misc.edit' defaultMessage='Edit' />
            </ButtonLink>
          </div>
        )}
      </Popover>
    </FormField>
  );
};

export default FieldUserOptions;
