import React from 'react';
import { ArrowSmallUp, ArrowSmallDown } from 'components/Icon';
import useTableWrapperStyles from './styles';
import clsx from 'clsx';
import OrderType from 'utils/Enums/OrderType';
import { SorterProps } from './types';
import { SORT_ASC_TESTID, SORT_DESC_TESTID } from 'utils/testIds';

const Sorter: React.FC<SorterProps> = ({
  isVisible,
  isSorted,
  isSortedDesc,
}) => {
  const classes = useTableWrapperStyles({});

  if (!isVisible) return null;

  const sortDirection = isSortedDesc
    ? OrderType.Descending
    : OrderType.Ascending;

  const order = isSorted ? sortDirection : undefined;

  return (
    <div className={classes.sorterWrapper}>
      <ArrowSmallUp
        size={6}
        className={clsx([
          classes.upperArrow,
          { [classes.active]: order === OrderType.Ascending },
        ])}
        data-testid={SORT_ASC_TESTID}
      />
      <ArrowSmallDown
        size={6}
        className={clsx({
          [classes.active]: order === OrderType.Descending,
        })}
        data-testid={SORT_DESC_TESTID}
      />
    </div>
  );
};

export default Sorter;
