import FormCategory from 'pages/TaskTemplates/components/FormCategory';
import React from 'react';
import { useSectionStyles } from './styles';
import { SectionProps } from './types';
import TooltipString from '../../../TooltipString';

const Section: React.FC<SectionProps> = ({
  children,
  title,
  hideSectionHeader,
  spacingAround,
  spacingWithin,
}) => {
  const classes = useSectionStyles({ spacingAround, spacingWithin });

  return (
    <FormCategory
      hideHeader={hideSectionHeader}
      noMarginBottom
      noMarginTop
      collapsible
      noHorizontalMargin
      className={classes.section}
      collapsibleContent={children}
    >
      <TooltipString text={title}>{title}</TooltipString>
    </FormCategory>
  );
};

export default Section;
