import React from 'react';
import usePasswordRuleChipStyles from './styles';
import { PasswordRuleChipsProps } from './types';
import { CloseIcon, CheckIcon } from 'components/Icon';
import clsx from 'clsx';
import {
  PASSWORD_RULE_SATISFIED_TESTID,
  PASSWORD_RULE_NO_SATISFIED_TESTID,
} from 'utils/testIds';

const PasswordRuleChips: React.FC<PasswordRuleChipsProps> = ({
  text,
  isFulfilled,
  passwordRule,
}) => {
  const classes = usePasswordRuleChipStyles({});

  return (
    <div
      className={clsx(classes.chips, {
        [classes.chipsSuccess]: isFulfilled,
        [classes.chipsUnSuccess]: !isFulfilled,
      })}
      data-testid={`${
        isFulfilled
          ? PASSWORD_RULE_SATISFIED_TESTID
          : PASSWORD_RULE_NO_SATISFIED_TESTID
      }${passwordRule}`}
    >
      {isFulfilled ? (
        <CheckIcon className={classes.icon} size={14} />
      ) : (
        <CloseIcon className={classes.icon} size={14} />
      )}
      <span className={classes.text}>{text}</span>
    </div>
  );
};

export default PasswordRuleChips;
