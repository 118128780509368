import {
  SET_TASK_TEMPLATES,
  APPEND_TASK_TEMPLATES,
  SET_TASK_TEMPLATES_FETCHING,
  RESET_TASK_TEMPLATES,
  RESET_TASK_TEMPLATES_COLUMNS,
  SET_TASK_TEMPLATES_SELECTED_ROW,
  SET_TASK_TEMPLATES_COLUMNS,
  SET_TASK_TEMPLATES_RESTRICTIONS,
} from '../constants/taskTemplates.consts';
import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  appendTableContent,
  resetTable,
  setTableContent,
  setTableLoading,
  resetTableColumns,
  setTableColumns,
  setSelectedRow,
} from './tablesCommonStateModificators';
import { TaskTemplate } from 'utils/types/api/tasks.types';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import { CommonTableState } from 'utils/types/api/table.types';
import cloneDeep from 'lodash/cloneDeep';

const initialState = cloneDeep(INITIAL_TABLE_STATE);

const taskTemplatesReducer: Reducer<
  CommonTableState<TaskTemplate>,
  RootAction
> = (state = initialState, action) => {
  switch (action.type) {
    case SET_TASK_TEMPLATES: {
      return setTableContent<TaskTemplate, typeof SET_TASK_TEMPLATES>(
        state,
        action
      );
    }
    case APPEND_TASK_TEMPLATES:
      return appendTableContent<TaskTemplate, typeof APPEND_TASK_TEMPLATES>(
        state,
        action
      );
    case SET_TASK_TEMPLATES_FETCHING:
      return setTableLoading<TaskTemplate, typeof SET_TASK_TEMPLATES_FETCHING>(
        state,
        action
      );

    case RESET_TASK_TEMPLATES:
      return resetTable<TaskTemplate>(state, initialState);

    case RESET_TASK_TEMPLATES_COLUMNS:
      return resetTableColumns<TaskTemplate>(state, initialState);

    case SET_TASK_TEMPLATES_COLUMNS:
      return setTableColumns<TaskTemplate, typeof SET_TASK_TEMPLATES_COLUMNS>(
        state,
        action
      );
    case SET_TASK_TEMPLATES_SELECTED_ROW:
      return setSelectedRow<
        TaskTemplate,
        typeof SET_TASK_TEMPLATES_SELECTED_ROW
      >(state, action);
    case SET_TASK_TEMPLATES_RESTRICTIONS:
      return { ...state, restrictions: action.restrictions };
    default:
      return state;
  }
};

export default taskTemplatesReducer;
