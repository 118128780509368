import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useDropdownMenuItemStyles = createUseStyles((theme: ThemeState) => {
  return {
    listItem: {
      padding: '0px 10px !important',
      margin: '0 !important',
      '&:hover': {
        backgroundColor: theme['dropdown-hover-gray'],
        fontWeight: 'bold',
        '& svg': {
          color: theme['primary-color'],
        },
      },
      '& svg': {
        color: theme['layout-trigger-color'],
      },
    },
    title: {
      marginLeft: 10,
      '&::after': {
        content: 'attr(data-text)',
        marginLeft: 28,
        display: 'block',
        fontWeight: 'bold',
        height: 0,
        overflow: 'hidden',
      },
    },
    tooltip: {
      width: 'inherit',
      overflow: 'hidden',
      display: 'block',
      textOverflow: 'ellipsis',
    },
  };
});

export default useDropdownMenuItemStyles;
