import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { apiCall } from 'utils/api';
import { CustomAvatarGroup } from 'components/lib/Avatar';
import InPlaceEditWrapper from '../components/InPlaceEditWrapper';
import { MOCKED_USERS } from 'components/FormPreview2/widgets/standard/CustomUserWidget/consts';
import {
  OBJECT_CLASS_FIELD_DETAILS,
  USERS_LIST_AUTOCOMPLETE_IN,
} from 'utils/endpoints';
import { SelectUserOption } from 'utils/types/selectInput.types';
import useInPlaceEdit from '../useInPlaceEdit';
import { InPlaceEditUserProps } from './types';
import useInPlaceEditUserStyles from './styles';
import EditUser from './EditUser';
import { useIntl } from 'react-intl';
import orderBy from 'lodash/orderBy';
import { useAvatarItems } from './hooks';

const InPlaceEditUser = <R extends object>({
  label,
  value,
  disabled,
  propertyName,
  patchUrl,
  readOnly,
  size,
  withUnderline,
  required,
  fieldId,
  objectClassId,
  minSelected,
  maxSelected,
  getPopupContainer,
  onSaveSuccess,
  meta,
}: InPlaceEditUserProps<R>) => {
  const intl = useIntl();
  const classes = useInPlaceEditUserStyles();
  const initialOptions = useMemo(() => Object.values(meta?.users ?? {}), [
    meta,
  ]);

  const [options, setOptions] = useState<SelectUserOption[]>([]);
  const [loading, setLoading] = useState(false);

  let initialValue = value ?? { users: [] };

  if (!initialValue.users) {
    initialValue.users = [];
  }

  if (options.length && value) {
    const availableIds = value.users.filter(valueId =>
      options.some(({ id, unselectable }) => !unselectable && id === valueId)
    );
    initialValue = { users: availableIds };
  }

  const {
    isSaving,
    isEditMode,
    tempValue,
    errorMessage,
    setErrorMessage,
    setTempValue,
    setIsEditModeOn,
    setIsEditModeOff,
    setIsSavingOn,
  } = useInPlaceEdit({
    initialValue,
    patchUrl,
    propertyName,
    withOutsideClick: false,
    getPopupContainer,
    onSaveSuccess,
  });

  const validateOptions = useCallback(
    (options: SelectUserOption[]) => {
      const availableOptions = options.filter(
        ({ is_deleted, unselectable }) => !is_deleted && !unselectable
      ).length;

      if (
        (minSelected && availableOptions < minSelected) ||
        (required && availableOptions === 0)
      ) {
        setErrorMessage(
          intl.formatMessage(
            {
              id: 'errors.notEnoughUsers',
              defaultMessage:
                'At least {minSelected} {minSelected, plural, one {user} other {users}} must be selected. Not enough users to select and save the record. Contact System Administrator.',
            },
            { minSelected: minSelected ?? 1 }
          )
        );
        return false;
      }

      return true;
    },

    [intl, minSelected, required, setErrorMessage]
  );

  const getUserOptions = useCallback(
    async (fieldId: number, objectClassId: number) => {
      setLoading(true);
      try {
        const objectClassDetailsUrl = generatePath(OBJECT_CLASS_FIELD_DETAILS, {
          id: objectClassId,
          fieldId,
        });
        let users: SelectUserOption[] = (
          await apiCall.get(objectClassDetailsUrl)
        ).data._meta.users;

        const unavailableIds = value?.users.filter(
          valueId => !users.some(({ id }) => id === valueId)
        );

        if (unavailableIds?.length && value?.users) {
          const queryParams = unavailableIds.join(',');
          const usersAutocompleteUrl = USERS_LIST_AUTOCOMPLETE_IN + queryParams;
          const unselectableUsers: SelectUserOption[] = (
            await apiCall.get(usersAutocompleteUrl)
          ).data.results.map((user: SelectUserOption) => ({
            ...user,
            unselectable: true,
          }));

          const availableIds = value.users.filter(valueId =>
            users.some(({ id }) => id === valueId)
          );

          setTempValue({ users: availableIds });
          users = [...users, ...unselectableUsers];
        }

        validateOptions(users);

        setOptions(
          orderBy(users, [
            ({ first_name, last_name, username }) =>
              (first_name + last_name + username).toLowerCase(),
          ])
        );
      } catch {
        setOptions([]);
      } finally {
        setLoading(false);
      }
    },
    [setTempValue, validateOptions, value]
  );

  useEffect(() => {
    if (fieldId && objectClassId && options.length === 0 && isEditMode) {
      getUserOptions(fieldId, objectClassId);
    }

    //set mocked data for preview
    if ((!fieldId || !objectClassId) && options.length === 0)
      setOptions(MOCKED_USERS);
  }, [objectClassId, fieldId, options, isEditMode, getUserOptions]);

  const avatarsItems = useAvatarItems(
    options.length ? options : initialOptions,
    value
  );

  const onClose = () => {
    setTempValue(value);
    setErrorMessage(undefined);
    validateOptions(options);
    setIsEditModeOff();
  };

  const validateField = useCallback(
    (shouldValidateOptions = true) => {
      const length = tempValue?.users.length ?? 0;

      if (shouldValidateOptions && !validateOptions(options)) return false;
      if (required && length === 0) {
        setErrorMessage(
          intl.formatMessage({
            id: 'errors.ThisFieldIsRequired',
            defaultMessage: 'This field is required',
          })
        );
        return false;
      }
      if (minSelected && length && length < minSelected) {
        setErrorMessage(
          intl.formatMessage(
            {
              id: 'errors.minSelectedUsers',
              defaultMessage: 'At least {minSelected} users must be selected',
            },
            { minSelected }
          )
        );
        return false;
      }
      if (maxSelected && length > maxSelected) {
        setErrorMessage(
          intl.formatMessage(
            {
              id: 'errors.maxSelectedUsers',
              defaultMessage:
                'Maximum {maxSelected, plural, one {# user} other {# users}} can be selected',
            },
            { maxSelected }
          )
        );
        return false;
      }

      setErrorMessage(undefined);
      return true;
    },
    [
      intl,
      maxSelected,
      minSelected,
      options,
      required,
      setErrorMessage,
      tempValue,
      validateOptions,
    ]
  );

  useEffect(() => {
    if (errorMessage) validateField();
  }, [errorMessage, validateField]);

  const onViewClick = () => {
    if (disabled) return;
    validateField(false);
    setIsEditModeOn();
  };

  const onBlur = () => {
    //remove deleted users from field value
    if (errorMessage) return;
    setTempValue(prev => {
      return {
        users:
          prev?.users.filter(id =>
            options.some(u => u.id === id && !u.is_deleted)
          ) ?? [],
      };
    });
    if (!validateField()) return;
    setIsSavingOn();
  };

  const limit = avatarsItems.filter(u => !u.isDeleted).length;
  const hiddenItems = initialValue.users.length - avatarsItems.length;
  return (
    <div className={classes.userFieldWrapper}>
      <InPlaceEditWrapper
        {...{
          isEditMode,
          isSaving,
          label,
          required,
          disabled,
          onViewClick,
          readOnly,
          size,
          withUnderline,
          maxWidth: 350,
        }}
        editContent={
          <EditUser
            {...{
              tempValue,
              setTempValue,
              options,
              loading,
              onBlur,
              errorMessage,
              minSelected,
              maxSelected,
              onClose,
            }}
          />
        }
        viewContent={
          avatarsItems?.length || hiddenItems > 0 ? (
            <div onClick={e => e.stopPropagation()}>
              <CustomAvatarGroup
                limitItems={limit < 6 ? limit + 1 : 6}
                hiddenItems={hiddenItems}
                items={avatarsItems}
                getPopupContainer={getPopupContainer}
                moreItemsTooltipMessage={intl.formatMessage({
                  id: 'misc.seeAllSelectedOptions',
                  defaultMessage: 'See all selected options',
                })}
                onClickShowMore={() => {
                  if (fieldId && objectClassId)
                    getUserOptions(fieldId, objectClassId);
                }}
              />
            </div>
          ) : (
            '-'
          )
        }
      />
    </div>
  );
};

export default InPlaceEditUser;
