import React from 'react';
import clsx from 'clsx';
import { PlusIcon } from 'components/Icon';
import { ButtonPrimary } from 'components/lib/Button';
import Tooltip from 'components/lib/Tooltip';
import { FormattedMessage } from 'react-intl';
import { TABLE_PAGINATION_ID } from 'utils/elementsIds';
import { DRAGGABLE_TABLE_NEW_BUTTON_TESTID } from 'utils/testIds';
import { useDraggableTableStyles } from './styles';
import { DraggableTablePaginationProps } from './types';
import useDynamicTablePaginationWidth from './useDynamicTablePaginationWidth';

const DraggableTablePagination = ({
  isAddNewDisabled,
  limitItems,
  maxItemsMessage,
  newButtonLabel,
  onNewClick,
}: DraggableTablePaginationProps) => {
  const draggableTableStyles = useDraggableTableStyles({});

  useDynamicTablePaginationWidth();

  return (
    <div
      className={draggableTableStyles.tablePagination}
      id={TABLE_PAGINATION_ID}
    >
      {onNewClick && limitItems !== undefined && (
        <div className={draggableTableStyles.toRightWrapper}>
          <Tooltip title={isAddNewDisabled ? maxItemsMessage : ''}>
            <div
              className={clsx({
                [draggableTableStyles.buttonNoPointerEvents]: isAddNewDisabled,
              })}
            >
              <ButtonPrimary
                icon={<PlusIcon size={10} />}
                onClick={onNewClick}
                disabled={isAddNewDisabled}
                data-testid={DRAGGABLE_TABLE_NEW_BUTTON_TESTID}
              >
                {!!newButtonLabel ? (
                  newButtonLabel
                ) : (
                  <FormattedMessage id='misc.create' defaultMessage='Create' />
                )}
              </ButtonPrimary>
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default DraggableTablePagination;
