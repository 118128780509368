import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { TextArea } from 'components/lib/Input';
import useInPlaceEditTextareaStyles from '../InPlaceEditTextarea/styles';
import { InPlaceEditJsonProps } from './types';
import useInPlaceEdit from '../useInPlaceEdit';
import InPlaceEditWrapper from '../components/InPlaceEditWrapper';
import { ReactComponent as JsonIcon } from 'img/icons/json-icon.svg';
import { useErrorMessages } from '../../../utils/useErrorMessages';
import { InPlaceEditError } from '../utils';
import clsx from 'clsx';
import useCommonInPlaceEditStyles from '../components/commonStyles';
import FieldValidationMessage from '../components/FieldValidationMessage';
import { Dropdown } from 'components/lib/Dropdown';
import InPlaceInputAfter from '../components/InPlaceInputAfter';
import { IN_PLACE_EDIT_TEXTAREA } from 'utils/testIds';
import useInPlaceEditJsonStyles from './styles';
import { useToggle } from 'hooks/useToggle';
import { FormattedMessage } from 'react-intl';
import { getViewMaxRows } from '../InPlaceEditTextarea';

const InPlaceEditJson = <R extends object>({
  label,
  required,
  value,
  disabled,
  propertyName,
  patchUrl,
  onSaveSuccess,
  getPopupContainer,
  remountChangeTarget,
  readOnly,
  previewClassName,
  size,
  withUnderline,
}: InPlaceEditJsonProps<R>) => {
  const [jsonText, setJsonText] = useState(JSON.stringify(value, null, 4));

  const handleSaveSuccess = (data: R, value: object | null | undefined) => {
    setJsonText(JSON.stringify(value, null, 4));
    if (onSaveSuccess) onSaveSuccess(data, value);
  };

  const [isMoreButtonVisible, setIsMoreButtonVisible] = useState(false);

  const [
    isPreviewExpanded,
    { toggle: toggleIsPreviewExpanded, toggleOff: closePreview },
  ] = useToggle(false);

  const {
    inputWrapperRef,
    isEditMode,
    setTempValue,
    isSaving,
    setIsEditModeOff,
    setIsEditModeOn,
    errorMessage,
    setErrorMessage,
    editWrapperRef,
  } = useInPlaceEdit({
    initialValue: value,
    patchUrl,
    propertyName,
    onSaveSuccess: handleSaveSuccess,
  });

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const previewTextareaRef = useRef<HTMLTextAreaElement>(null);

  const classes = useCommonInPlaceEditStyles({
    isSaving,
    errorMessage,
    size,
  });
  const textareaClasses = useInPlaceEditTextareaStyles({ size });
  const jsonClasses = useInPlaceEditJsonStyles({});

  const errorMessages = useErrorMessages(label);

  useEffect(() => {
    if (!isEditMode) {
      try {
        setJsonText(prev => JSON.stringify(JSON.parse(prev), null, 4));
      } catch {}
    }

    //workaround from InPlaceEditTextarea - prevents page jumping - #37884
    setTimeout(() => {
      if (!textareaRef.current) return;

      textareaRef.current.focus();
    }, 500);
  }, [isEditMode]);

  const onViewClick = () => {
    if (disabled) return;

    setIsEditModeOn();
  };

  useLayoutEffect(() => {
    if (!readOnly) return;

    // create async call to wait for browser to break text lines and correctly determine scrollHeight
    setTimeout(() => {
      if (!previewTextareaRef.current) return;

      if (
        previewTextareaRef.current.clientHeight <
        previewTextareaRef.current.scrollHeight
      )
        setIsMoreButtonVisible(true);
      else {
        setIsMoreButtonVisible(false);
        closePreview();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onIconClick = () => {
    try {
      const json = JSON.parse(jsonText);
      const beautified = JSON.stringify(json, null, 4);
      setTempValue(json);
      setJsonText(beautified);
    } catch (err) {
      return;
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;

    if (required && value.length === 0)
      setErrorMessage(errorMessages[InPlaceEditError.FIELD_IS_REQUIRED_ERROR]);
    else setErrorMessage(undefined);

    try {
      setTempValue(JSON.parse(value));
      setErrorMessage(undefined);
    } catch {
      setErrorMessage(errorMessages[InPlaceEditError.INVALID_JSON_FORMAT]);
    }
    setJsonText(value);
  };

  const getViewValue = () => {
    if (typeof value === 'string' && value === '') return '-';

    return JSON.stringify(value, null, 4);
  };

  const editAbort = () => {
    setTempValue(value);
    setJsonText(JSON.stringify(value, null, 4));
    setIsEditModeOff();
  };

  return (
    <InPlaceEditWrapper
      {...{
        isEditMode,
        isSaving,
        label,
        required,
        disabled,
        onViewClick,
        inputWrapperRef,
        readOnly,
        previewClassName,
        size,
        withUnderline,
      }}
      noAfter
      editContent={
        <Dropdown
          {...{ getPopupContainer, remountChangeTarget }}
          visible
          overlay={
            <div
              className={textareaClasses.dropdownWrapper}
              ref={editWrapperRef}
            >
              <div className={textareaClasses.dropdownContentWrapper}>
                <div
                  className={clsx(
                    classes.basicInPlaceInputWrapper,
                    textareaClasses.textareaWrapper
                  )}
                  data-testid={IN_PLACE_EDIT_TEXTAREA}
                >
                  <TextArea
                    autoSize={{ maxRows: 10 }}
                    value={jsonText}
                    onChange={onInputChange}
                    onKeyDown={({ key }) => key === 'Escape' && editAbort()}
                    className={clsx(
                      textareaClasses.textarea,
                      jsonClasses.textarea
                    )}
                    textareaElementRef={textareaRef}
                    withLineCounter
                    disableTextTrim
                    disableTrimSpaces
                  />
                  <JsonIcon
                    className={jsonClasses.beautifyIcon}
                    onClick={onIconClick}
                    width={24}
                    height={24}
                  />
                </div>
                {!isSaving && (
                  <FieldValidationMessage
                    error={errorMessage}
                    className={textareaClasses.messageBox}
                  />
                )}
              </div>
              <InPlaceInputAfter
                isLoading={isSaving}
                onCloseClick={editAbort}
              />
            </div>
          }
        >
          <div className={classes.dropdownAnchor}></div>
        </Dropdown>
      }
      viewContent={
        <div className={textareaClasses.previewWrapper}>
          <TextArea
            key={getViewMaxRows(false, readOnly, isPreviewExpanded)}
            autoSize={{
              maxRows: getViewMaxRows(false, readOnly, isPreviewExpanded),
            }}
            textareaElementRef={previewTextareaRef}
            disableResize
            value={getViewValue()}
            readOnly
            className={clsx([
              classes.basicInPlaceInput,
              textareaClasses.textarea,
              textareaClasses.textareaPreviewWrapper,
              { [textareaClasses.showOverflowY]: isPreviewExpanded },
            ])}
          />
        </div>
      }
      viewContentAfter={
        isMoreButtonVisible ? (
          <div>
            <span
              onClick={toggleIsPreviewExpanded}
              className={textareaClasses.showMoreButton}
            >
              {isPreviewExpanded ? (
                <FormattedMessage
                  id='misc.showLess'
                  defaultMessage='Show less'
                />
              ) : (
                <FormattedMessage
                  id='misc.showMore'
                  defaultMessage='Show more'
                />
              )}
            </span>
          </div>
        ) : undefined
      }
      actionButtonOnTop
    />
  );
};

export default InPlaceEditJson;
