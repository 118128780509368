import React from 'react';
import useInPlaceEdit from '../useInPlaceEdit';
import InPlaceEditWrapper from '../components/InPlaceEditWrapper';
import { useErrorMessages } from '../../../utils/useErrorMessages';
import { InPlaceEditError } from '../utils';
import FieldValidationMessage from '../components/FieldValidationMessage';
import moment from 'moment';
import useInPlaceEditDateTimeStyles from './styles';
import { Dropdown } from 'components/lib/Dropdown';
import { IN_PLACE_EDIT_TIMEPICKER } from 'utils/testIds';
import { TimePicker } from 'components/lib/TimePicker';
import { InPlaceEditProps } from '../types';

const InPlaceEditTime = <R extends Object>({
  label,
  required,
  value,
  disabled,
  propertyName,
  patchUrl,
  onSaveSuccess,
  getPopupContainer,
  remountChangeTarget,
  readOnly,
  size,
  withUnderline,
}: InPlaceEditProps<string, R>) => {
  const {
    isEditMode,
    setTempValue,
    tempValue,
    isSaving,
    setIsEditModeOn,
    errorMessage,
    setErrorMessage,
    setIsSavingOn,
    inputWrapperRef,
  } = useInPlaceEdit({
    initialValue: !!value ? value : null,
    patchUrl,
    propertyName,
    onSaveSuccess,
    withOutsideClick: false,
  });
  const styles = useInPlaceEditDateTimeStyles({
    isError: !!errorMessage,
    size,
  });
  const errorMessages = useErrorMessages();

  const onViewClick = () => {
    if (disabled) return;

    setIsEditModeOn();
  };

  const onInputChange = (value: moment.Moment | null) => {
    if (required && !value)
      setErrorMessage(errorMessages[InPlaceEditError.FIELD_IS_REQUIRED_ERROR]);
    else setErrorMessage(undefined);

    if (value) setTempValue(value.format('HH:mm:ss'));
    else setTempValue(null);

    if (required && !value) return;

    setIsSavingOn();
  };

  const handleOnOpenChange = (isOpen: boolean) => {
    if (!isOpen)
      setTimeout(() => {
        // we need setTimeout to wait for correct value from input below (after clicking "Clear" icon)
        const val = inputWrapperRef?.current?.querySelector('input')?.value;

        if (!val && required) {
          setTempValue(null);
          setErrorMessage(
            errorMessages[InPlaceEditError.FIELD_IS_REQUIRED_ERROR]
          );

          return;
        }

        setIsSavingOn();
      });
  };

  return (
    <InPlaceEditWrapper
      {...{
        isEditMode,
        isSaving,
        label,
        required,
        disabled,
        onViewClick,
        inputWrapperRef,
        readOnly,
        size,
        withUnderline,
      }}
      maxWidth={200}
      editContent={
        <Dropdown
          {...{ getPopupContainer, remountChangeTarget }}
          visible={!isSaving && !!errorMessage}
          overlay={
            <FieldValidationMessage error={errorMessage} displayAfterParent />
          }
        >
          <TimePicker
            allowClear
            defaultOpen
            onChange={onInputChange}
            value={!!tempValue ? moment(tempValue, 'HH:mm') : undefined}
            className={styles.picker}
            {...{ getPopupContainer }}
            onOpenChange={handleOnOpenChange}
            onFocus={() => setErrorMessage(undefined)}
            data-testid={IN_PLACE_EDIT_TIMEPICKER}
            popupClassName={styles.pickerDropdown}
            format='HH:mm'
          />
        </Dropdown>
      }
      viewContent={
        !!value ? <span>{moment(value, 'HH:mm:ss').format('HH:mm')}</span> : '-'
      }
    />
  );
};

export default InPlaceEditTime;
