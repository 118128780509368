import { noApiUpdatePanels } from 'components/lib/FlexLayout/flexLayoutHooks';
import { useDispatch, useSelector } from 'react-redux';
import { updatePreferences } from 'store/actions/preferencesActions';
import {
  getLayoutPreferences,
  getOpenedPanels,
} from 'store/selectors/preferencesSelectors';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { PreferencesTypes } from 'utils/types/api/preferences.types';

const usePanels = () => {
  const dispatch = useDispatch();
  const openedPanels = useSelector(getOpenedPanels) || [];
  const preferences = useSelector(getLayoutPreferences);

  const togglePanelsPreferences = (
    panel: FlexLayoutWindows,
    isClosed: boolean
  ) => {
    const isAdded = openedPanels.find(item => item[panel] !== undefined);

    if (!noApiUpdatePanels.includes(panel)) {
      dispatch(
        updatePreferences(PreferencesTypes.LayoutPreferences, {
          panels: {
            ...preferences.panels,
            openedPanels: isAdded
              ? openedPanels.map(item =>
                  item[panel] === undefined ? item : { [panel]: !isClosed }
                )
              : [...openedPanels, { [panel]: !isClosed }],
          },
        })
      );
    }
  };

  const updateClosedPanelsPreferences = (panels: FlexLayoutWindows[]) => {
    dispatch(
      updatePreferences(PreferencesTypes.LayoutPreferences, {
        panels: {
          ...preferences.panels,
          openedPanels: openedPanels.map(item => {
            const panelToClose = panels.find(panel => panel in item);

            return panelToClose ? { [panelToClose]: false } : item;
          }),
        },
      })
    );
  };

  return {
    openedPanels,
    togglePanelsPreferences,
    updateClosedPanelsPreferences,
  };
};

export default usePanels;
