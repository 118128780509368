import { CheckboxGroupCustom } from 'components/lib/Checkbox';
import React from 'react';
import ClassFieldCheckbox from '../ClassFieldCheckbox';
import { ClassFieldCheckboxListProps } from './types';

const ClassFieldCheckboxList = ({
  classFields,
  onSelectClassFields,
  isColumnLimitReached,
  selectedClassFields,
  className,
}: ClassFieldCheckboxListProps) => (
  <CheckboxGroupCustom vertical value={selectedClassFields}>
    {classFields?.map(field => {
      const { label, id } = field;

      return (
        <ClassFieldCheckbox
          id={id.toString()}
          label={label}
          key={id}
          className={className}
          onSelectClassFields={onSelectClassFields}
          isColumnLimitReached={isColumnLimitReached}
          selectedClassFields={selectedClassFields ?? []}
          field={field}
        />
      );
    })}
  </CheckboxGroupCustom>
);

export default React.memo(ClassFieldCheckboxList);
