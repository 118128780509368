import React from 'react';
import TooltipString from 'components/TooltipString';
import { useHighlightedAppliedFilterValue } from 'hooks/useHighlightedAppliedFilterValue';
import { CommaSeparatedStringProps } from './types';

const CommaSeparatedString: React.FC<CommaSeparatedStringProps> = ({
  values,
  highlightedColumnKey,
}) => {
  const { rawText, highlightedText } = useHighlightedAppliedFilterValue(
    values,
    highlightedColumnKey
  );

  return <TooltipString text={rawText} modifiedText={highlightedText} />;
};

export default CommaSeparatedString;
