import { Reducer } from 'redux';
import { SET_PLACEHOLDER_PROPS } from 'store/constants/formBuilder.consts';
import { RootAction } from '.';
import { FormBuilderState } from './types/formBuilder.types';

const initialState: FormBuilderState = {
  placeholderProps: undefined,
};

const formBuilderReducer: Reducer<FormBuilderState, RootAction> = (
  state = initialState,
  action
) => {
  if (action.type === SET_PLACEHOLDER_PROPS) {
    return {
      ...state,
      placeholderProps: action.payload,
    };
  } else {
    return state;
  }
};

export default formBuilderReducer;
