import React from 'react';
import { Field, useField, useFormikContext } from 'formik';
import { AutocompleteUsersSelectProps } from './types';
import { FormikFormItem } from 'components/lib/Form';
import { useSetRequiredField } from 'components/Inputs/hooks';
import { AUTOCOMPLETE_SELECT_TESTID } from 'utils/testIds';
import AutocompleteUsersSelect from '.';
import { SelectUserOption } from 'utils/types/selectInput.types';

const FAutocompleteUsersSelect: React.FC<
  AutocompleteUsersSelectProps & { name: string; required: boolean }
> = ({
  name,
  value,
  autocompleteUrl,
  placeholder,
  required,
  onChange,
  ...rest
}) => {
  const [{ value: selectedValues }, { touched, error }] = useField<string>(
    name
  );
  const { setFieldTouched, setFieldValue } = useFormikContext();

  useSetRequiredField(name, required);

  const handleChange = (option: SelectUserOption | undefined) => {
    if (!option) return;

    if (onChange) {
      onChange(option);
    }

    const parsedOptions = [
      ...(selectedValues ? selectedValues.split(', ') : []),
      option.id,
    ].join(', ');

    setFieldValue(name, parsedOptions);
  };

  return (
    <FormikFormItem
      {...{ name }}
      validateStatus={touched && error ? 'error' : undefined}
      data-testid={`${AUTOCOMPLETE_SELECT_TESTID}${name}`}
    >
      <Field {...{ name }}>
        {() => (
          <AutocompleteUsersSelect
            {...{
              autocompleteUrl,
              value,
              placeholder,
              ...rest,
            }}
            handleOnBlur={() => setFieldTouched(name, true)}
            onChange={handleChange}
            selectMultiple
          />
        )}
      </Field>
    </FormikFormItem>
  );
};

export default FAutocompleteUsersSelect;
