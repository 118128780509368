import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ViewFieldUsageWindow from 'components/ViewFieldUsageWindow';
import useFlexLayoutWindow from 'hooks/useFlexLayoutWindow';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import {
  getObjectClassesRow,
  getObjectClassesSelectedRow,
} from 'store/selectors/objectClassesSelectors';
import useObjectClassViewUsage from './hooks';
import ErrorComponent from 'components/ErrorComponent';

const ObjectClassViewUsagePanel = () => {
  const intl = useIntl();
  const { closeComponent, isExternal } = useFlexLayoutWindow(
    FlexLayoutWindows.ObjectClassViewUsage
  );
  const selectedRowId = useSelector(getObjectClassesSelectedRow);
  const { name = '', id } = useSelector(getObjectClassesRow(selectedRowId));

  const { displayConfig, loading, error } = useObjectClassViewUsage(id);

  useEffect(() => {
    if (!selectedRowId) closeComponent(FlexLayoutWindows.ObjectClassViewUsage);
  }, [closeComponent, selectedRowId]);

  if (error) return <ErrorComponent error={error?.status} />;

  return (
    <ViewFieldUsageWindow
      {...{ displayConfig, isExternal }}
      usageLabel={intl.formatMessage({
        id: 'objectClasses.areasExplanationClassScope',
        defaultMessage: 'This class is used in the following system areas:',
      })}
      noUsageLabel={intl.formatMessage({
        id: 'objectClasses.noUsageClassScope',
        defaultMessage: 'This class is not used anywhere in the system.',
      })}
      id={selectedRowId}
      label={name}
      isLoading={loading}
      isLight
    />
  );
};

export default ObjectClassViewUsagePanel;
