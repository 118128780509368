import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { getIdentifier } from 'styles/panelsStyles';

export const useAddUsersAsStyles = createUseStyles((theme: ThemeState) => {
  return {
    identifier: getIdentifier(theme),
    permissionSelect: {
      width: 'calc(100% - 52px)',
      '& .ant-select-selector': {
        height: '35px !important',
        padding: '1px 11px !important',
      },
    },
    loader: {
      height: '100%',
      '& .ant-spin-container': {
        height: '100%',
      },
    },
    limitWrapper: {
      width: '100%',
      '& > div > div': {
        width: '100%',
      },
    },
    contentWrapper: {
      height: '100%',
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: 15,
    },
    headerTitle: {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 7,
      lineHeight: 1.3,
      color: theme['heading-color'],
      paddingRight: 16,
    },
    panelTitle: {
      fontSize: 16,
      marginTop: 10,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: [11, 0],
    },
    selectWrapper: {
      gridGap: 8,
      display: 'flex',
      whiteSpace: 'nowrap',
      alignItems: 'center',
      width: '50%',
    },
    bottomGap: {
      marginBottom: 10,
      position: 'relative',
    },
    emptyBox: {
      margin: [35, 0, 10],
    },
    text: {
      color: theme['text-color'],
      opacity: '.6',
      fontSize: 12,
    },
    scrollableContainer: {
      overflow: 'auto',
      height: '100%',
      position: 'relative',
    },
    autocompleteSelect: {
      '& .ant-select-selector': {
        height: '34px !important',
      },
      '& .ant-select-selection-search-input': {
        height: '32px !important',
      },
      '& .ant-select-selection-placeholder': {
        lineHeight: '32px !important',
      },
    },
    buttonsWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& .ant-btn:first-child': {
        marginRight: 8,
      },
    },
  };
});
