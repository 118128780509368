import React from 'react';
import { FileViewerProps } from './FileViewer.types';
import { FileViewerDisplay } from './components/FileViewerDisplay';
import { isSupportedFileFormat } from './utils';
import { UnsupportedFileFormatNotice } from './components/UnsupportedFileFormatNotice';
import { useFileViewerStyles } from './FileViewer.styles';

/**
 * Allows you to load up and display PDF / image / docx files using PSPDF library.
 */
export const FileViewer = ({
  fileUrl,
  fileName,
  className,
}: FileViewerProps) => {
  const styles = useFileViewerStyles();
  const isSupportedFileType = isSupportedFileFormat(fileName ?? '');

  return (
    <div className={className}>
      <div className={styles.viewerContainer}>
        {isSupportedFileType ? (
          <FileViewerDisplay fileName={fileName} fileUrl={fileUrl} />
        ) : (
          <UnsupportedFileFormatNotice />
        )}
      </div>
    </div>
  );
};
