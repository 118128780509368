import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { TaskFormFields, TaskFormType } from './hooks/types';
import useTasksPanelStyles from '../styles';
import FormCategory from 'pages/TaskTemplates/components/FormCategory';
import Editor from 'components/Inputs/Editor';
import { BASIC_TOOLBAR } from '../../../../../components/HTMLEditorWrapper/consts';

const InstructionsSection: React.FC = () => {
  const intl = useIntl();
  const classes = useTasksPanelStyles({ withMinHeight: true });
  const {
    values: { [TaskFormFields.Instructions]: instructions },
  } = useFormikContext<TaskFormType>();

  return (
    <FormCategory
      noMarginTop
      collapsible
      collapsibleContent={
        <Editor
          data={instructions || ''}
          name={TaskFormFields.Instructions}
          placeholder={intl.formatMessage({
            id: 'tasksPanel.form.instructionsPlaceholder',
            defaultMessage: 'Add a comment for the completer',
          })}
          customToolbar={BASIC_TOOLBAR}
          minHeight={100}
        />
      }
      className={classes.sectionWrapper}
    >
      <FormattedMessage
        id='tasksPanel.form.instructions'
        defaultMessage='Instructions'
      />
    </FormCategory>
  );
};

export default InstructionsSection;
