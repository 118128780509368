import GTag from 'providers/IntlProviderWrapper/gTag';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserDetails } from 'store/selectors/authSelectors';

const useInitTelemetry = () => {
  const userData = useSelector(getUserDetails);

  useEffect(() => {
    if (userData?.id) {
      GTag.init({
        userId: userData?.id,
        userEmail: userData?.username,
      });
    } else {
      GTag.init();
    }
  }, [userData]);
};

export default useInitTelemetry;
