import React from 'react';

export enum SequenceTaskDefaultAssigneesPanelModes {
  List = 'list',
  AddAssignees = 'addAssignees',
}

export interface DefaultAssigneesListProps {
  setMode: React.Dispatch<
    React.SetStateAction<SequenceTaskDefaultAssigneesPanelModes>
  >;
}

export interface AddDefaultAssigneesProps {
  setMode: React.Dispatch<
    React.SetStateAction<SequenceTaskDefaultAssigneesPanelModes>
  >;
}
