import { useState, useCallback, useEffect } from 'react';
import { Actions, DockLocation, Layout, Model, Node } from 'flexlayout-react';

import useCallbackRef from 'hooks/useCallbackRef';
import { ComponentsDictionary, ExternalComponent } from './types';
import { toast } from '../toast';
import { useIntl } from 'react-intl';
import ToastType from 'utils/Enums/ToastType';
import { useDispatch } from 'react-redux';
import { toggleSidebar } from 'store/actions/flexLayoutActions';
import usePanels from 'hooks/usePanels';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { updatePreferences } from 'store/actions/preferencesActions';
import { PreferencesTypes } from 'utils/types/api/preferences.types';

export const noApiUpdatePanels: string[] = [
  FlexLayoutWindows.ObjectClassEditField,
  FlexLayoutWindows.ObjectClassAddField,
];
//I'm 99% sure that strings which will be validated against this array will be of type FlexLayoutWindows,
//but from current implementation it looks like it might be any string

const useFlexLayout = (
  model: Model,
  componentsDictionary: ComponentsDictionary,
  externalComponents: ExternalComponent[]
) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { togglePanelsPreferences } = usePanels();

  const [selectedTabSetId, setSelectedTabSetId] = useState<string | undefined>(
    undefined
  );

  const [layoutRef, setLayoutRef] = useCallbackRef<Layout | null>(ref => {
    // Workaround for flex layout to initialize properly
    if (ref) {
      setTimeout(() => ref.forceUpdate(), 500);
    }
  });

  useEffect(() => {
    // Workaround for flexLayout to correctly resize when sidebar is collapsed/expanded
    if (!!layoutRef.current) {
      layoutRef.current.forceUpdate();
    }
  }, [layoutRef]);

  const getFirstTabSetId = useCallback(() => {
    const queryFn = (node: Node) =>
      (node.getType() === 'tabset' || node.getType() === 'row') &&
      node.getId() !== 'childrenTabset';

    let firstNode = model.getRoot().getChildren().find(queryFn);

    while (firstNode?.getType() === 'row') {
      firstNode = firstNode.getChildren().find(queryFn);
    }

    return firstNode?.getId();
  }, [model]);

  const onRenameTab = useCallback(
    (componentKey: string, newName: string) => {
      if (!layoutRef.current || !componentsDictionary[componentKey]) {
        return;
      }

      if (model.getNodeById(componentKey)) {
        model.doAction(Actions.renameTab(componentKey, newName));
      }
    },
    [layoutRef, componentsDictionary, model]
  );

  const onAddTab = useCallback(
    (componentKey: string, noExternalCheck?: boolean) => {
      if (!layoutRef.current || !componentsDictionary[componentKey]) {
        return;
      }

      if (
        !noExternalCheck &&
        externalComponents.find(component => component.id === componentKey) !==
          undefined
      ) {
        toast(
          {
            title: intl.formatMessage({
              id: 'misc.info',
              defaultMessage: 'Info!',
            }),
            subtitle: intl.formatMessage({
              id: 'flexLayout.windowAlreadyOpened',
              defaultMessage: 'Window is already opened.',
            }),
          },
          ToastType.Info
        );

        return;
      }

      if (model.getNodeById(componentKey)) {
        model.doAction(Actions.selectTab(componentKey));
        if (!noApiUpdatePanels.includes(componentKey)) {
          //it is here because we can not save preferences in model change method there was too many requests and onAction don't handle this
          setTimeout(() => {
            dispatch(
              updatePreferences(PreferencesTypes.LayoutPreferences, {
                flexLayoutJSONModel: model.toJson(),
              })
            );
          }, 0);
        }

        return;
      }

      const { name, disableUndockButton } = componentsDictionary[componentKey];

      togglePanelsPreferences(componentKey as FlexLayoutWindows, false);
      const tabConfig = {
        component: componentKey,
        name,
        id: componentKey,
        enableFloat: !disableUndockButton,
      };

      if (selectedTabSetId) {
        layoutRef.current.addTabToTabSet(selectedTabSetId, tabConfig);
        setSelectedTabSetId(undefined);
      } else {
        const firstTabset = getFirstTabSetId();
        dispatch(toggleSidebar(true));

        // if there are no tabsets to add tab we dock new tab on right side of mainRow
        model.doAction(
          Actions.addNode(
            {
              type: 'tab',
              ...tabConfig,
            },
            firstTabset || 'mainRow',
            firstTabset ? DockLocation.CENTER : DockLocation.RIGHT,
            0
          )
        );
      }
      //it is here because we can not save preferences in model change method there was too many requests and onAction don't handle this
      if (!noApiUpdatePanels.includes(componentKey))
        setTimeout(() => {
          dispatch(
            updatePreferences(PreferencesTypes.LayoutPreferences, {
              flexLayoutJSONModel: model.toJson(),
            })
          );
        }, 0);
    },
    [
      layoutRef,
      componentsDictionary,
      externalComponents,
      model,
      togglePanelsPreferences,
      selectedTabSetId,
      intl,
      dispatch,
      getFirstTabSetId,
    ]
  );

  const onRemoveTab = useCallback(
    componentKey => {
      model.doAction(Actions.deleteTab(componentKey));
    },
    [model]
  );

  const onFocusTab = useCallback(
    componentKey => {
      model.doAction(Actions.selectTab(componentKey));
    },
    [model]
  );

  const onDrawerClose = useCallback(() => setSelectedTabSetId(undefined), []);

  const checkIfComponentExists = useCallback(
    componentKey => model.getNodeById(componentKey) !== undefined,
    [model]
  );

  /**
   * Will search for a tab with specified Id and move it to the beginning in relation of it's sibling (e.g. to put the tab as being first)).
   * Will do nothing if tab is not found.
   */
  const moveTabToFront = useCallback(
    (componentKey: string) => {
      const node = model.getNodeById(componentKey);

      if (!node) {
        return;
      }

      const parent = node.getParent();

      if (!parent) {
        return;
      }

      const children = parent.getChildren();
      const index = children.indexOf(node);

      if (index > -1) {
        children.splice(index, 1);
        children.unshift(node);

        // force update to avoid flicker when changing tab states in parallel.
        if (layoutRef.current) {
          layoutRef.current.forceUpdate();
        }
      }
    },
    [model, layoutRef]
  );

  return {
    onAddTab,
    onRemoveTab,
    moveTabToFront,
    selectedTabSetId,
    onDrawerClose,
    setLayoutRef,
    checkIfComponentExists,
    onFocusTab,
    onRenameTab,
  };
};

export default useFlexLayout;
