import React from 'react';
import InPlaceEditTextInput from 'components/InPlaceEditControls/InPlaceEditTextinput';
import { mapCustomWidgetPropsToBaseInPlaceEditProps } from './utils';
import { Widget } from 'alx-dynamic-form';
import { Provider } from 'react-redux';
import { store } from 'store/store';

const AdaptedInPlaceEditTextInput: Widget = widgetProps => {
  const baseInPlaceEditProps = mapCustomWidgetPropsToBaseInPlaceEditProps<
    string,
    any
  >(widgetProps);

  const { maxLength } = widgetProps;

  return (
    <Provider store={store}>
      <InPlaceEditTextInput
        {...{ ...baseInPlaceEditProps, maxLength }}
        withUnderline
      />
    </Provider>
  );
};

export default AdaptedInPlaceEditTextInput;
