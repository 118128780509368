import * as yup from 'yup';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  Validators,
  OptionSelect,
  ItemsValidator,
  TypeValidator,
  SizeValidator,
} from 'utils/types/selectInput.types';
import { FlatValidatorsObject } from 'utils/types';
import { URL_REGEX, EMAIL_REGEX } from 'utils/consts';
import isPlainObject from 'lodash/isPlainObject';

type CommonValues<T> = valueof<T> & OptionSelect;
type Options<T> = MappedObject<CommonValues<T>, keyof T>;
type StringSchema = yup.StringSchema<string | null | undefined>;

enum UniqueFieldType {
  Int = 'int',
  Email = 'email',
  Date = 'date',
  Url = 'url',
  Json = 'json',
  JsonObject = 'json_object',
}

const useValidationSchemaBuilder = <T>(
  options: Options<T> | undefined,
  additionalValidation?: object
) => {
  const intl = useIntl();
  const Yup = yup as MappedObject<any>;

  const fieldsWithValidation = options
    ? Object.entries<CommonValues<T>>(options).reduce<Options<T> | {}>(
        (result, [key, value]) => {
          if (!!value.required || !!value.validators) {
            return {
              ...result,
              [key]: value,
            };
          }

          return result;
        },
        {}
      )
    : {};

  const rawValidationSchema = Object.entries<CommonValues<T>>(
    fieldsWithValidation
  ).reduce<FlatValidatorsObject>((all, [key, { validators, ...rest }]) => {
    all[key] = rest;

    if (!!validators) {
      validators.forEach((validator: Validators) => {
        if (validator.type && typeof validator.type === 'string') {
          (all as MappedObject<any>)[key][validator.type] =
            validator.length ||
            ((validator as unknown) as ItemsValidator).items ||
            ((validator as unknown) as TypeValidator)?.extensions ||
            ((validator as unknown) as SizeValidator)?.size;
        }

        return all[key];
      });
    }

    return all;
  }, {});

  const getRelevantType = (type: string | undefined) => {
    if (!type) {
      return 'mixed';
    }

    switch (type) {
      case UniqueFieldType.Int:
        return 'number';
      case UniqueFieldType.Email:
      case UniqueFieldType.Url:
      case UniqueFieldType.Json:
      case UniqueFieldType.JsonObject:
        return 'string';
      default:
        return type;
    }
  };

  const buildValidationSchema = useCallback(() => {
    if (options) {
      const validationSchema = Object.entries<CommonValues<T>>(
        fieldsWithValidation
      ).reduce<MappedObject<StringSchema>>((all, [key, value]) => {
        const { type, required, validators } = value;

        const fixedType = getRelevantType(type);
        const yupType = !!Yup[fixedType] ? Yup[fixedType]() : yup.mixed();

        all[key] = yupType;

        if (!!required) {
          all[key] = all[key].required(
            intl.formatMessage({
              id: 'errors.fieldIsRequired',
              defaultMessage: 'Field is required',
            })
          );
        }

        // use fb email regex to unify as per URL
        if (type === UniqueFieldType.Email) {
          all[key] = all[key].matches(
            EMAIL_REGEX,
            intl.formatMessage({
              id: 'errors.mustBeAValidEmail',
              defaultMessage: 'Enter a valid email address',
            })
          );
        }

        if (type === UniqueFieldType.Url) {
          all[key] = all[key].matches(
            URL_REGEX,
            intl.formatMessage({
              id: 'errors.enter_a_valid_url',
              defaultMessage: 'Enter a valid URL',
            })
          );
        }

        if (type === UniqueFieldType.Date) {
          all[key] = all[key].nullable();
        }

        if (type === UniqueFieldType.Json) {
          all[key] = all[key].test(
            'isValidJsonFormat',
            intl.formatMessage({
              id: 'errors.enter_a_valid_json',
              defaultMessage: 'Enter a valid JSON',
            }),
            value => {
              if (typeof value !== 'string') {
                return true;
              }

              try {
                JSON.parse(value);

                return true;
              } catch {
                return false;
              }
            }
          );
        }

        if (type === UniqueFieldType.JsonObject) {
          all[key] = all[key].test(
            'isValidJsonObjectFormat',
            intl.formatMessage({
              id: 'errors.enter_a_valid_json_object',
              defaultMessage: 'Enter a valid JSON object',
            }),
            value => {
              if (typeof value !== 'string') {
                return true;
              }

              try {
                const parsedValue = JSON.parse(value);

                return isPlainObject(parsedValue);
              } catch {
                return false;
              }
            }
          );
        }

        if (validators) {
          validators.forEach(({ type, length }: Validators) => {
            if (
              type === 'min_length' &&
              typeof length === 'number' &&
              length > 0
            ) {
              all[key] = all[key].test(
                `Min length: ${length}`,
                intl.formatMessage(
                  {
                    id: 'errors.minStringLength',
                    defaultMessage:
                      'Field requires minimum {length} {length, plural, one {character} other {characters}}',
                  },
                  {
                    length,
                  }
                ),
                value => {
                  if (value === undefined || value === null) return false;

                  return value.length >= length;
                }
              );
            }

            if (
              type === 'max_length' &&
              typeof length === 'number' &&
              !all[key]
            ) {
              all[key] = all[key].max(
                length,
                intl.formatMessage(
                  {
                    id: 'errors.maxStringLength',
                    defaultMessage:
                      'Field maximum length is {length} characters',
                  },
                  {
                    length,
                  }
                )
              );
            }

            if (type === 'email') {
              all[key] = all[key].email(
                intl.formatMessage({
                  id: 'errors.mustBeAValidEmail',
                  defaultMessage: 'Enter a valid email address',
                })
              );
            }
          });
        }

        return all;
      }, {});

      return yup.object().shape({
        ...validationSchema,
        ...(additionalValidation ? additionalValidation : {}),
      });
    }

    return yup.object().shape({});
  }, [Yup, fieldsWithValidation, intl, options, additionalValidation]);

  return {
    buildValidationSchema,
    rawValidationSchema,
  };
};

export default useValidationSchemaBuilder;
