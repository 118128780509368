import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseNestedChildClassTableStylesProps = {
  isDisabled?: boolean;
};

export const useNestedChildClassTableStyles = createUseStyles(
  (theme: ThemeState) => ({
    headerText: {
      display: 'flex',
      alignSelf: 'center',
      marginBottom: 5,
    },
    optionContainer: {
      width: '100%',
    },
    disabledText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 12,
      color: theme['disabled-switch-gray'],
    },
    bottomText: {
      display: 'grid',
      gridTemplateColumns: 'minmax(10px, 1fr) max-content',
      justifyContent: 'space-between',
      fontSize: 12,
      maxWidth: '100%',
      color: theme['secondary-color'],
    },
    searchBox: {
      margin: 6,
      windth: 225,
      height: 32,
      marginLeft: 5,
      width: 'var(--search-input-width)',
      marginBottom: 0,
    },
    plusIcon: {
      marginRight: 0,
      marginLeft: -5,
    },
    tooltip: {
      display: 'flex',
      pointerEvents: ({ isDisabled }: UseNestedChildClassTableStylesProps) =>
        isDisabled && 'none',
    },
    buttonWrapper: {
      height: 32,
      marginRight: 10,
      display: 'inline-block',
    },
    buttonWrapperDisabled: {
      pointerEvents: 'none',
    },
    selectItem: {
      minHeight: 47,
      lineHeight: '19px',
      '& .ant-select-item-option-content': {
        display: 'flex',
        alignItems: 'center',
        '& > div:first-child': {
          marginRight: 6,
        },
      },
      '& mark': {
        padding: 0,
        backgroundColor: 'inherit',
      },
      '&.ant-select-item-option-disabled ': {
        '& .ant-avatar': {
          background: theme['border-color-base'],
        },
        '& .ant-select-item-option-content > div:not(:first-child)': {
          color: theme['disabled-switch-gray'],
          '& mark': {
            color: theme['disabled-switch-gray'],
          },
        },
      },
    },
    limitNotification: {
      padding: '0 0 0 15px',
      width: 'fit-content',
    },
    clearIconWrapper: {
      display: 'flex',
      justifyContent: 'center',
      padding: '0px 10px 0px 10px',
      alignItems: 'center',
      color: theme['primary-color'],
      backgroundColor: theme['primary-color-light'],
      marginLeft: 2,
      cursor: 'pointer',
      borderRadius: 2,
      border: '1px solid transparent',
      transition: 'border 500ms ease-out',
      '&:hover': {
        border: `1px solid ${theme['primary-color']}`,
      },
    },
  })
);
