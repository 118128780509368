import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useChildClassControlsStyles = createUseStyles(
  (theme: ThemeState) => ({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    buttonWrapper: {
      height: 32,
      width: 70,
      display: 'inline-block',
      '&:not(.ant-btn-icon-only)': {
        '& svg': {
          marginRight: '2px !important',
        },
      },
    },
    paginationWrapper: { color: theme['secondary-color'], marginLeft: 17 },
    buttonsWrapper: {
      '& > .ant-space-item:not(:last-child)': {
        marginRight: '5px !important',
      },
    },
    leftSpaceWrapper: {
      marginLeft: 10,
    },
    input: {
      width: 50,
    },
    lastPageWrapper: { display: 'block', whiteSpace: 'nowrap' },
    searchBox: {
      margin: 6,
      windth: 225,
      height: 32,
      marginLeft: 5,
      width: 'var(--search-input-width)',
    },
    plusIcon: {
      marginRight: 0,
      marginLeft: -5,
    },
  })
);
