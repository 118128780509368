import { RootState } from 'store/reducers';
import { TaskGroup } from 'utils/types/api/taskGroups.types';

export const selectTaskGroups = (state: RootState) =>
  state.taskGroups.allIds.map(id => {
    const taskGroup = state.taskGroups.byId[id];

    return {
      ...taskGroup,
      key: taskGroup.id,
    };
  });

export const totalTaskGroups = (state: RootState) => {
  return state.taskGroups.total;
};

export const filteredTaskGroupsCount = (state: RootState) => {
  return state.taskGroups.filtered;
};
export const getTaskGroupsColumns = (state: RootState) => {
  return state.taskGroups.columns;
};

export const getTaskGroupRow = (id: string | undefined) => (
  state: RootState
): TaskGroup | undefined => {
  if (!id) return;

  return state.taskGroups.byId[id];
};

export const getTaskGroupsSelectedRow = (state: RootState) => {
  return state.taskGroups?.selectedRow;
};
