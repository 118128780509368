import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';

export const blockedWindows = [
  FlexLayoutWindows.ObjectClassEditField,
  FlexLayoutWindows.ObjectClassAddField,
  FlexLayoutWindows.ClassPermissionsOwners,
  FlexLayoutWindows.RecordAccess,
  FlexLayoutWindows.Tasks,
  FlexLayoutWindows.UserGroupPermissionSetAssignees,
];
