import { useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import { generateRouteUrlWithParams } from 'utils/functions/generateRouteUrlWithParams';
import { TableQueryParams } from 'utils/types/routing.types';

const useBackToList = (withParams = true) => {
  const {
    state: {
      currentPage = 1,
      pageSize = 10,
      url = undefined,
      isInfinite = false,
      sort = '-id',
    } = {},
    pathname,
  } = useLocation<TableQueryParams>();

  const generateBackPath = useCallback(
    (path: string) => {
      if (withParams) {
        const queryParams = isInfinite
          ? { isInfinite, sort }
          : {
              currentPage: currentPage.toString(),
              pageSize: pageSize.toString(),
              sort,
            };

        return generateRouteUrlWithParams<TableQueryParams>(path, queryParams);
      } else {
        return path;
      }
    },
    [currentPage, isInfinite, pageSize, sort, withParams]
  );

  return {
    generateBackPath,
    url,
    pathname,
  };
};

export default useBackToList;
