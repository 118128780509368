import { TagProps } from 'antd/lib/tag';
import { Tag } from 'components/lib/Tag';
import React from 'react';
import useCustomTagStyles from './styles';
import clsx from 'clsx';

const CustomTag: React.FC<TagProps> = ({ className, closable, ...rest }) => {
  const classes = useCustomTagStyles(closable);

  return (
    <Tag
      className={clsx([classes.customTag, className])}
      {...{ closable, ...rest }}
    />
  );
};

export default CustomTag;
