import { ExtendedUiSchema } from 'components/FormPreview/customFields/ObjectField/types';
import { ExtendedJsonSchema } from 'components/FormPreview/types';
import { getUiSections } from '../../utils';
import { MigrationFunction } from '../types';

/*
This migration function removes "secondary_owners" field and replaces "primary_owners" field with "owners" field
*/
const migrationToV4: MigrationFunction = ({ schema, uiSchema }) => {
  const migratedSchema = migrateSchema(schema);
  const migratedUiSchema = migrateUiSchema(uiSchema);

  return { schema: migratedSchema, uiSchema: migratedUiSchema };
};

const migrateSchema = (schema: ExtendedJsonSchema): ExtendedJsonSchema => {
  const sectionsEntries = Object.entries(schema.properties || {});

  const migratedSectionEntries = sectionsEntries.map(
    ([sectionAlias, section]) => {
      const { primary_owners, secondary_owners, ...restFields } =
        section.properties || {};

      const migratedSection = {
        ...section,
        properties: {
          ...restFields,
          ...(!!primary_owners ? { owners: primary_owners } : {}),
        },
      };

      return [sectionAlias, migratedSection];
    }
  );

  return {
    ...schema,
    schemaVersion: 4,
    properties: Object.fromEntries(migratedSectionEntries),
  };
};

const migrateUiSchema = (uiSchema: ExtendedUiSchema): ExtendedUiSchema => {
  const uiSectionsEntries = getUiSections(uiSchema);

  const migratedUiSectionsEntries = uiSectionsEntries.map(
    ([uiSectionKey, uiSection]) => {
      const migratedUiSectionColumns = uiSection['ui:columns'].map(
        uiSectionColumn => {
          const primary_owners_index = uiSectionColumn.components.findIndex(
            componentAlias => componentAlias === 'primary_owners'
          );
          const secondary_owners_index = uiSectionColumn.components.findIndex(
            componentAlias => componentAlias === 'secondary_owners'
          );

          const migratedUiSectionColumnComponents = uiSectionColumn.components;

          if (primary_owners_index !== -1)
            migratedUiSectionColumnComponents[primary_owners_index] = 'owners';

          if (secondary_owners_index !== -1)
            migratedUiSectionColumnComponents.splice(secondary_owners_index, 1);

          return {
            ...uiSectionColumn,
            components: migratedUiSectionColumnComponents,
          };
        }
      );

      // cant type uiSection properly because of its definition
      //@ts-ignore
      const { primary_owners, secondary_owners, ...rest } = uiSection;

      const migratedUiSection = {
        ...rest,
        owners: primary_owners,
        'ui:columns': migratedUiSectionColumns,
      };

      return [uiSectionKey, migratedUiSection];
    }
  );

  return { ...uiSchema, ...Object.fromEntries(migratedUiSectionsEntries) };
};

export default migrationToV4;
