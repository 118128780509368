import { ExclamationMarkSquare } from 'components/Icon';
import { Alert } from 'components/lib/Alert';
import React from 'react';
import { useIntl } from 'react-intl';
import { COLUMNS_LIMIT } from '../../consts';
import { useColumnsAmmountAlertStyles } from './styles';
import { ColumnsAmountAlertProps } from './types';

const MaxColumnsAlert = ({ currentAmount }: ColumnsAmountAlertProps) => {
  const intl = useIntl();

  const classes = useColumnsAmmountAlertStyles({});

  const message = intl.formatMessage(
    {
      id: 'formBuilder.maxChildColumnsAlert',
      defaultMessage:
        'The maximum number of columns ({currentAmount}) have been reached.',
    },
    { currentAmount }
  );

  return currentAmount >= COLUMNS_LIMIT ? (
    <Alert
      banner
      {...{ message }}
      icon={<ExclamationMarkSquare size={50} />}
      className={classes.alert}
    />
  ) : null;
};

export default MaxColumnsAlert;
