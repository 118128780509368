import { useRef } from 'react';

const useCallbackRef = <T>(onSetRef: (ref: T) => void) => {
  const ref = useRef<T>();

  const setRef = (passedRef: T) => {
    ref.current = passedRef;
    onSetRef(ref.current);
  };

  return [ref, setRef] as [typeof ref, typeof setRef];
};

export default useCallbackRef;
