export interface ObjectClassModelClass {
  object_class: number;
  multiplicity: string;
  parent: number;
  _meta?: {
    labels?: {
      object_class?: string;
    };
  };
}

export interface ObjectClassModel {
  id: number;
  description: string;
  name: string;
  object_classes: Array<ObjectClassModelClass>;
}

export enum ChildClassMultiplicity {
  ZERO_OR_MORE = 'zero_or_more',
  ZERO_OR_ONE = 'zero_or_one',
}
export interface ObjectClassModelChildClass {
  id: number;
  name: string | null;
  multiplicity: ChildClassMultiplicity;
  _meta: {
    permissions: {
      view: boolean;
      create: boolean;
      delete: boolean;
      object_records: {
        create: boolean;
        list: boolean;
      };
    };
  };
}
