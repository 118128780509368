import { Moment } from 'moment';
import { Key } from 'react';
import { ObjectClassFieldTypes } from 'utils/types/api/objectClassesFields.types';
import { AutocompleteSelectValue } from 'utils/types/selectInput.types';
import { FieldFilterAutocomplete } from './components/EventFilters/types';

export enum EventType {
  RecordCreated = 'record_created',
  OwnerInitialized = 'owner_initialized',
  OwnersAdded = 'owners_added',
  OwnersRemoved = 'owners_removed',
  AssigneesAdded = 'assignees_added',
  AssigneesRemoved = 'assignees_removed',
  FieldValuesChanged = 'field_values_changed',
  DocumentGenerated = 'document_generated',
  Deleted = 'deleted',
  Error = 'error',
}

export enum DocumentSourceType {
  All = '',
  User = 'user',
  Sequence = 'sequence',
}

export interface EventQueryPredicates {
  event_type__in?: EventType[];
  source_id__in?: AutocompleteSelectValue[];
  event_datetime__range?: Moment[];
  field_id?: FieldFilterAutocomplete;
  source_type__in?: DocumentSourceType;
}

export type EventQueryParams = {
  [K in keyof EventQueryPredicates]: string;
};

export enum EventFieldType {
  'string' = ObjectClassFieldTypes.String,
  'email' = ObjectClassFieldTypes.Email,
  'phone' = ObjectClassFieldTypes.Phone,
  'int' = ObjectClassFieldTypes.Int,
  'float' = ObjectClassFieldTypes.Float,
  'enum' = ObjectClassFieldTypes.Enum,
  'bool' = ObjectClassFieldTypes.Bool,
  'date' = ObjectClassFieldTypes.Date,
  'datetime' = ObjectClassFieldTypes.Datetime,
  'time' = ObjectClassFieldTypes.Time,
  'document' = ObjectClassFieldTypes.Document,
  'set' = ObjectClassFieldTypes.Set,
  'url' = ObjectClassFieldTypes.Url,
  'json' = ObjectClassFieldTypes.Json,
  'user' = ObjectClassFieldTypes.User,
}

export enum SourceType {
  User = 'user',
  Sequence = 'sequence',
  System = 'system',
}

export type EventHistory = {
  event_id: string;
  event_datetime: string;
  source_type: SourceType;
  source_id: number;
  source_name: string | null;
  key?: string | number;
} & (
  | {
      event_type: EventType.RecordCreated | EventType.Deleted;
      event_data: [];
    }
  | {
      event_type:
        | EventType.OwnerInitialized
        | EventType.OwnersAdded
        | EventType.OwnersRemoved;
      event_data: OwnersData;
    }
  | {
      event_type: EventType.AssigneesAdded | EventType.AssigneesRemoved;
      event_data: AssigneesData;
    }
  | {
      event_type: EventType.FieldValuesChanged;
      event_data: FieldValueData;
    }
  | {
      event_type: EventType.DocumentGenerated;
      event_data: DocumentData[];
    }
);

export interface EventUser {
  type: 'user';
  id: number;
  name: string | null;
}

export type EventData =
  | (EventUser | FieldValueUpdate | DocumentData)[]
  | AssigneesData;

export type OwnersData = EventUser[];

export type FieldValueData = FieldValueUpdate[];
export interface AssigneesData {
  permission_set: {
    id: number;
    name: string | null;
  };
  assignees: EventUser[];
}

export type FieldValueType =
  | string
  | string[]
  | number
  | boolean
  | null
  | undefined;

export type UserFieldValueUpdate = FieldValueUpdate & {
  value_labels: UserFieldValueUpdateData;
};
export interface FieldValueUpdate {
  id: number;
  type: EventFieldType;
  name: string | null;
  value: FieldValueType;
  value_labels?: string[] | UserFieldValueUpdate;
}

export interface Items {
  id: Key;
  value: FieldValueType;
  valueLabels?: string[];
}

export type UserFieldValueUpdateData = {
  users: string[];
  // in future probably groups will be added
};

export interface DocumentData {
  document_template_id: string;
  file_name: string;
  file_id: string;
}
