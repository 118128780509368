import { useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { apiCall } from 'utils/api';
import { StatusCodes } from 'http-status-codes';
import { InPlaceEditApiError, UseInPlaceEditProps } from './types';
import { useToggle } from 'hooks/useToggle';
import { AxiosError } from 'axios';
import { parseFirstInPlaceEditApiError } from './utils';
import { useErrorMessages } from 'utils/useErrorMessages';
import noop from 'lodash/noop';
import { useAutoSaveErrorModalContext } from 'contexts/AutoSaveErrorModalContext';
import { fixOnlyMultipleSpacesInText } from 'components/Inputs/hooks';

// V - value type, R - api patch response type

const useInPlaceEdit = <V, R>({
  initialValue,
  propertyName,
  patchUrl,
  onSaveSuccess,
  validateBeforeSubmit,
  withOutsideClick = true,
  preventPatch,
}: UseInPlaceEditProps<V, R>) => {
  const {
    showErrorModal,
    isVisibleErrorModal,
  } = useAutoSaveErrorModalContext();
  const errorMessages = useErrorMessages();
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const editWrapperRef = useRef<HTMLDivElement>(null);

  const [tempValue, setTempValue] = useState(initialValue);
  const [
    isEditMode,
    { toggleOn: setIsEditModeOn, toggleOff: setIsEditModeOff },
  ] = useToggle(false);
  const [
    isSaving,
    { toggleOn: setIsSavingOn, toggleOff: setIsSavingOff },
  ] = useToggle(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  // ref for "handleClickOutside" callback
  const refProps = useRef({
    tempValue,
    initialValue,
    isSaving,
    errorMessage,
    isTouched: false,
    isValueChanged: false,
  });

  refProps.current = {
    ...refProps.current,
    tempValue,
    initialValue,
    isSaving,
    errorMessage,
  };

  useEffect(() => {
    refProps.current.isValueChanged = !isEqual(initialValue, tempValue);
  }, [initialValue, tempValue]);

  const handleClickOutside = (event: MouseEvent) => {
    const eventTarget = event.target as HTMLElement;

    // this is for ignoring clicks in svg which are used in remove icons
    // because they dissapear when clicked thus causing click outside
    if (eventTarget?.tagName === 'path' || eventTarget.tagName === 'circle')
      return;

    if (
      (!editWrapperRef.current &&
        inputWrapperRef.current &&
        !inputWrapperRef.current.contains(event.target as Node)) ||
      (editWrapperRef.current &&
        !editWrapperRef.current.contains(event.target as Node) &&
        !inputWrapperRef.current?.contains(event.target as Node))
    ) {
      if (refProps.current.isValueChanged && !preventPatch) setIsSavingOn();
      else setIsEditModeOff();
    }
  };

  // add/remove click listener & make sure tempValue is equal initialValue after going out of editMode
  useEffect(() => {
    if (!withOutsideClick) return;

    if (!isEditMode) {
      setErrorMessage(undefined);

      return;
    }

    if (!!errorMessage) return;

    // if there is no editWrapperRef we check only for clicks outside inputWrapperRef
    // else only editWrapperRef is checked

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, errorMessage, withOutsideClick]);

  useEffect(() => {
    if (!isEditMode) return;

    refProps.current.isTouched = true;
    setTempValue(initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  useEffect(() => {
    if (!isSaving) return;

    const beforeSubmitValidationMessage = !!validateBeforeSubmit
      ? validateBeforeSubmit(refProps.current.tempValue)
      : undefined;

    if (beforeSubmitValidationMessage) {
      setErrorMessage(beforeSubmitValidationMessage);
      setIsSavingOff();

      return;
    }

    if (!patchUrl || !refProps.current.isValueChanged) {
      setIsSavingOff();
      setIsEditModeOff();

      return;
    }

    if (!preventPatch) saveChangesToApi(patchUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaving]);

  // before unmount try to save new walue if its valid
  useEffect(() => {
    return () => {
      const {
        errorMessage,
        isValueChanged,
        tempValue,
        isSaving,
        isTouched,
      } = refProps.current;

      if (
        !patchUrl ||
        !!errorMessage ||
        !isValueChanged ||
        isSaving ||
        !isTouched ||
        preventPatch
      )
        return;

      if (
        !!validateBeforeSubmit &&
        validateBeforeSubmit(tempValue) !== undefined
      )
        return;

      const patchData = preparePatchData(propertyName, tempValue);

      apiCall.patch(patchUrl, patchData).catch(noop);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveChangesToApi = async (validPatchUrl: string) => {
    if (isVisibleErrorModal) return;

    const patchData = preparePatchData(propertyName, tempValue);
    const parsedValue =
      typeof tempValue === 'string'
        ? fixOnlyMultipleSpacesInText(tempValue).trim()
        : tempValue;

    try {
      const result = await apiCall.patch(validPatchUrl, patchData);

      if (result.status === StatusCodes.OK && !!onSaveSuccess)
        onSaveSuccess(result.data, parsedValue as V);

      setIsEditModeOff();
    } catch (error) {
      const { response: { data, status = 0 } = {} } = error as AxiosError<
        InPlaceEditApiError
      >;

      const firstParsedError = parseFirstInPlaceEditApiError(data);

      if (
        showErrorModal &&
        [StatusCodes.FORBIDDEN, StatusCodes.NOT_FOUND].includes(status)
      ) {
        showErrorModal(error);

        return;
      }

      setErrorMessage(errorMessages[firstParsedError]);
    } finally {
      setIsSavingOff();
    }
  };

  const preparePatchData = (propertyName: string, tempValue: unknown) => {
    const parsedValue =
      typeof tempValue === 'string'
        ? fixOnlyMultipleSpacesInText(tempValue).trim()
        : tempValue;

    return {
      [propertyName]: parsedValue,
    };
  };

  return {
    isEditMode,
    tempValue,
    setTempValue,
    inputWrapperRef,
    editWrapperRef,
    isSaving,
    setIsEditModeOn,
    setIsEditModeOff,
    errorMessage,
    setErrorMessage,
    setIsSavingOn,
  };
};

export default useInPlaceEdit;
