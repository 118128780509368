import React, { useCallback, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { languageSelector } from 'store/selectors/preferencesSelectors';
import AppLocale from './AppLocale';
import useTimezone from 'hooks/useTimezone';
import { updatePreferences } from 'store/actions/preferencesActions';
import SupportedLanguages from 'utils/Enums/SupportedLanguages';
import moment from 'moment-timezone';
import { PreferencesTypes } from 'utils/types/api/preferences.types';

interface IntlProviderWrapperProps {
  children: React.ReactNode;
}

function IntlProviderWrapper({
  children,
}: IntlProviderWrapperProps): React.ReactElement {
  const language = useSelector(languageSelector);
  const { timezone } = useTimezone();
  const dispatch = useDispatch();

  // if user has language in preferences and this language does not exist in the app - set en-GB
  useEffect(() => {
    if (!Object.values(SupportedLanguages).includes(language)) {
      dispatch(
        updatePreferences(PreferencesTypes.GeneralPreferences, {
          language: SupportedLanguages.enGB,
        })
      );
    }
  }, [dispatch, language]);

  useEffect(() => {
    moment.tz.setDefault(timezone);
  }, [timezone]);

  const onIntlError = useCallback(e => {
    console.warn(e);
  }, []);

  return (
    <IntlProvider
      locale={language}
      messages={AppLocale[language]}
      onError={onIntlError}
      timeZone={timezone}
    >
      {children}
    </IntlProvider>
  );
}

export default IntlProviderWrapper;
