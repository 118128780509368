import { UseCurrentTableParams } from 'hooks/useCurrentTable/types';
import {
  HeaderGroup,
  TableState,
  UseColumnOrderInstanceProps,
  UseTableInstanceProps,
} from 'react-table';
import { ListProps } from 'react-window';
import { RootState } from 'store/reducers';
import { DefaultPermissions } from 'utils/types/api/permissions.types';
import {
  ActionConfirmationData,
  ActionConfirmationType,
  CustomDataGroupsType,
  EmptyTableRenderProp,
  PaginationRender,
  RenderHeaderLimit,
} from 'contexts/types';
import React, {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  SetStateAction,
} from 'react';
import { SelectOptionItem } from 'utils/types/selectInput.types';
import { SearchDataParams } from 'components/SearchInput/types';
import { TaskStatus } from 'utils/Enums/TaskStatus';
import { SequenceState } from 'utils/types/api/sequences.types';
import Membership from 'utils/Enums/Membership';
import TablesType from 'utils/Enums/TablesType';

export interface HandleOrderChangeParams {
  id: number;
  movedToIndex: number;
}
export interface ActionCellProps {
  id: string;
  uuid?: string;
  permissions: DefaultPermissions;
  name?: string;
  first_name?: string;
  last_name?: string;
  username?: string;
  isReadOnly?: boolean;
  label?: string;
  usage?: number;
  num_of_members?: number;
  object_name?: string;
  actionId?: string;
  membership?: Membership;
  fetchData?: () => Promise<void>;
  setBulkSelectionList?: Dispatch<SetStateAction<number[]>>;
  status?: TaskStatus;
  object_record?: number;
  state?: SequenceState;
}

export interface BulkSelectionActionProps
  extends Pick<ActionCellProps, 'fetchData' | 'setBulkSelectionList'> {
  ids: number[];
}

export interface TableActionConfirmationData {
  title?: string;
  getTitle?: (params?: ActionConfirmationData) => React.ReactNode;
  subtitle?: string;
  getSubtitle?: (params?: ActionConfirmationData) => React.ReactNode;
  confirmActionOnItemMethod: (params?: ActionConfirmationData) => void;
  image?: string;
  confirmationButtonIcon?: JSX.Element;
  cancelButtonLabel?: string;
  confirmationButtonLabel?: string;
  shouldRefetchOnConfirm?: boolean;
}

export enum RowsDisplay {
  FOLLOW_NUMBER_OF_RESULTS = 'FOLLOW_NUMBER_OF_RESULTS',
  FIXED = 'FIXED',
}

export enum CustomOverLimitStatus {
  UNDER = 'under',
  OVER = 'over',
}

export interface TableWrapperProps<T> extends UseCurrentTableParams<T> {
  preventFromHideColumns: string[];
  additionalHeaderActions?: JSX.Element;
  withFiltersDefault?: boolean;
  customCells?: string[];
  onActionCellClick?: (props: ActionCellProps) => void;
  setSelectedRow: (data: string | undefined) => void;
  getSelectedRow: (state: RootState) => string | undefined;
  onDoubleClick?: (value: ActionCellProps) => void;
  onSingleClick?: (value: ActionCellProps) => void;
  withPagination?: boolean;
  withDraggableRows?: boolean;
  handleOrderChange?: (values: HandleOrderChangeParams) => Promise<void>;
  newButtonLabel?: string;
  onNewClick?: () => void;
  newButtonDisabled?: boolean;
  confirmationData?: PartialRecord<
    ActionConfirmationType,
    TableActionConfirmationData
  >;
  isDraggingEnabled?: boolean;
  children?: React.ReactNode;
  modalData?: ActionConfirmationModalData;
  setModalData?: ActionConfirmationModalProps['setModalData'];
  withColumnConfiguration?: boolean;
  filterSelectOptions?: SelectOptionItem[];
  getEmptyMessageDescription?: (
    defaultMessage: string,
    value?: SearchDataParams,
    showDefaultMessage?: boolean
  ) => string;
  onBulkSelectionRemoveAction?: (
    props: BulkSelectionActionProps
  ) => Promise<void>;
  onBulkSelectionAddAction?: (props: BulkSelectionActionProps) => Promise<void>;
  hasListPermissions?: boolean;
  resetCustomOffset?: () => void;
  customDataGroups?: CustomDataGroupsType;
  preferencesId?: string | number;
  defaultSortColumn?: string;
  refetchMethodRef?: MutableRefObject<undefined | VoidFunction>;
  loading?: boolean;
  isPageSizeDisabled?: boolean;
  rowsDisplay?: RowsDisplay;
  preventInteractionWithColumns?: boolean; //disallows resizing of column header, removes the cursor:pointer on column header. (nestedTable property)
  preventOverflow?: boolean;
  paginationRender?: React.FC<PaginationRender>;
  withBordersAroundTable?: boolean;
  fullWidth?: boolean;
  renderEmptyTable?: EmptyTableRenderProp; //render prop to customise EmptyTable message
  pageSize?: number;
  renderHeaderLimit?: RenderHeaderLimit;
  alwaysDisplayPagination?: boolean;
  customOverLimit?: CustomOverLimitStatus;
  customTableName?: TablesType; //make the table independent from current table property set in redux store. This let to display many tables in the same view
  customTableId?: string; //add suffix for table ids to make it independent from global document query methods
}

type PartialRecord<K extends string | number | symbol, T> = { [P in K]?: T };

export type PaginationGoToFirst = VoidFunction;
export type PaginationGoToLast = VoidFunction;
export type PaginationGoToPage = (e: ChangeEvent<HTMLInputElement>) => void;
export type PaginationOnNext = VoidFunction;
export type PaginationOnChange = (e: ChangeEvent<HTMLInputElement>) => void;
export type PaginationOnPrevious = VoidFunction;

export interface PaginationProps {
  onPageChange: (page: number) => void;
  totalCount: number;
  filteredCount: number;
  currentPage: number;
  pageSize: number;
  tablePageSizes: Array<string | number>;
  onPageSizeChange: (pageSize: number) => void;
  isInfinite: boolean;
  className?: string;
  loading?: boolean;
}

export interface PaginationWrapperProps {
  tableState: TableState<object>;
  setCancelConfigModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldShowConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  hasChanges: boolean;
}

export interface SorterProps {
  isVisible: boolean;
  isSorted: boolean;
  isSortedDesc: boolean | undefined;
}

export interface UseScrollbarsProps {
  appliedFilters: boolean;
}

export interface TableModalsProps
  extends UseColumnOrderInstanceProps<object>,
    Pick<UseTableInstanceProps<object>, 'setHiddenColumns'> {
  state: TableState<object>;
  defaultTableColumns: string[] | undefined;
  onConfirmLeaveConfigMode: () => void;
  shouldShowConfirmation: boolean;
  hasChanges: boolean;
  cancelConfigModalVisible: boolean;
  setShouldShowConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  setCancelConfigModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  resetResizing: any;
}

export interface OuterProps
  extends ListProps,
    Pick<UseTableInstanceProps<object>, 'getTableBodyProps'> {
  onBodyScroll: (e: any) => void;
  tableBodyClassName: string;
}

export interface RowSelectionState {
  wasPageChanged: boolean;
  rowId?: number;
}

export interface CustomColumnProperties extends HeaderGroup<object> {
  searchKey?: string;
}

export interface ActionConfirmationModalData {
  data: ActionConfirmationData;
  type: ActionConfirmationType;
}

export interface ActionConfirmationModalProps {
  data: ActionConfirmationData;
  type: ActionConfirmationType;
  refetchTable: () => Promise<void>;
  confirmationData: PartialRecord<
    ActionConfirmationType,
    TableActionConfirmationData
  >;
  setModalData: React.Dispatch<
    React.SetStateAction<ActionConfirmationModalData | undefined>
  >;
}
