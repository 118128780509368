import { StatusCodes } from 'http-status-codes';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from 'store/reducers';
import errorToast from 'utils/functions/errorToast';
import {
  SET_PERMISSIONS,
  SET_PERMISSIONS_LOADING,
  RESET_PERMISSIONS,
} from 'store/constants/permissions.consts';
import { apiCall } from 'utils/api';
import { PERMISSIONS } from 'utils/endpoints';
import { PermissionsState } from 'utils/types/api/permissions.types';

export interface SetPermissionsAction extends Action<typeof SET_PERMISSIONS> {
  payload: PermissionsState;
}

export interface SetPermissionsLoadingAction
  extends Action<typeof SET_PERMISSIONS_LOADING> {
  loading: boolean;
}

export type ResetPermissionsAction = Action<typeof RESET_PERMISSIONS>;

export type PermissionsAction =
  | SetPermissionsAction
  | SetPermissionsLoadingAction
  | ResetPermissionsAction;

export const setPermissions = (
  payload: PermissionsState
): SetPermissionsAction => {
  return {
    type: SET_PERMISSIONS,
    payload,
  };
};

export const setPermissionsLoading = (
  loading: boolean
): SetPermissionsLoadingAction => {
  return {
    type: SET_PERMISSIONS_LOADING,
    loading,
  };
};

export const resetPermissions = (): ResetPermissionsAction => {
  return {
    type: RESET_PERMISSIONS,
  };
};

export const getPermissions = (
  callback?: (data: PermissionsState) => void
): ThunkAction<
  Promise<boolean>,
  RootState,
  undefined,
  PermissionsAction
> => async dispatch => {
  try {
    const { data, status } = await apiCall.get(PERMISSIONS);

    if (status === StatusCodes.OK) {
      dispatch(setPermissions(data));

      if (callback) callback(data);

      return true;
    } else if (status === StatusCodes.UNAUTHORIZED) {
      errorToast();
    }
    return false;
  } catch {
    return false;
  }
};
