import {
  DraggableStateSnapshot,
  DraggableProvidedDraggableProps,
} from 'react-beautiful-dnd';

export const getItemStyle = (
  { isDragging, isDropAnimating }: DraggableStateSnapshot,
  draggableStyle: DraggableProvidedDraggableProps
) => ({
  ...draggableStyle.style,
  ...(isDragging && {
    boxShadow: '10px 10px 20px -10px rgba(0,0,0,0.75)',
    borderLeft: '2px solid #ced5da',
  }),
  ...(isDropAnimating && { transitionDuration: '0.01s' }),
});
