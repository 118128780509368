import React, { createContext, useContext, useEffect, useState } from 'react';
import { AutoSaveErrorModalContextType } from '../types';
import { AxiosError } from 'axios';
import ResourceNotFoundOnSave from 'contexts/AutoSaveErrorModalContext/ResourceNotFoundOnSave';
import { StatusCodes } from 'http-status-codes';
import usePermissionRedirect from 'hooks/usePermissionRedirect';
import noop from 'lodash/noop';

const AutoSaveErrorModalContext = createContext<AutoSaveErrorModalContextType>({
  showErrorModal: undefined,
  isVisibleErrorModal: false,
  setTitle: noop,
});

export const useAutoSaveErrorModalContext = () =>
  useContext(AutoSaveErrorModalContext);

const AutoSaveErrorModalProvider = AutoSaveErrorModalContext.Provider;

export const AutoSaveErrorModalContextProvider: React.FC<{
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  onCloseErrorModal?: () => Promise<void>;
  redirectUrl?: string | string[];
}> = ({
  title: propsTitle,
  subtitle,
  redirectUrl,
  onCloseErrorModal,
  children,
}) => {
  const [error, setError] = useState<AxiosError | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState(propsTitle);
  const urlArray =
    typeof redirectUrl === 'string' ? [redirectUrl] : redirectUrl || [];

  const { redirect } = usePermissionRedirect(urlArray);

  useEffect(() => {
    setIsOpen(
      [StatusCodes.NOT_FOUND, StatusCodes.FORBIDDEN].includes(
        error?.response?.status as StatusCodes
      )
    );
  }, [error]);

  const onModalClose = () => {
    if (urlArray.length > 0) redirect();
    else {
      setIsOpen(false);
    }
  };

  return (
    <AutoSaveErrorModalProvider
      value={{
        showErrorModal: (error: AxiosError) => setError(error),
        isVisibleErrorModal: isOpen,
        setTitle,
      }}
    >
      {children}
      <ResourceNotFoundOnSave
        closeModal={onModalClose}
        {...{
          title: propsTitle ?? title,
          redirectUrl,
          onCloseErrorModal,
          isOpen,
          subtitle,
        }}
      />
    </AutoSaveErrorModalProvider>
  );
};
