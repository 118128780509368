import clsx from 'clsx';
import useFormPreviewStyles from 'components/FormPreview/styles';
import useFormatNumber from 'hooks/useFormatNumber';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MinMaxInfoProps } from './types';

const MinMaxInfo: React.FC<MinMaxInfoProps> = ({
  minimum,
  maximum,
  checkboxField,
  className,
}) => {
  const classes = useFormPreviewStyles({});
  const formatNumberWithSeparators = useFormatNumber();
  const isMinNumberSet = !isNaN(Number(minimum));
  const isMaxNumberSet = !isNaN(Number(maximum));

  if ((isMaxNumberSet || isMinNumberSet) && !checkboxField) {
    return (
      <div className={clsx(classes.description, className)}>
        {isMinNumberSet && !isMaxNumberSet && (
          <FormattedMessage
            id='formBuilder.min'
            defaultMessage='Min: {value}'
            values={{
              value: formatNumberWithSeparators(minimum),
            }}
          />
        )}
        {isMaxNumberSet && !isMinNumberSet && (
          <FormattedMessage
            id='formBuilder.max'
            defaultMessage='Max: {value}'
            values={{
              value: formatNumberWithSeparators(maximum),
            }}
          />
        )}
        {isMinNumberSet && isMaxNumberSet && (
          <div className={classes.maxMinWrapper}>
            <div>
              <FormattedMessage
                id='formBuilder.min'
                defaultMessage='Min: {value}'
                values={{
                  value: formatNumberWithSeparators(minimum),
                }}
              />
              ,
            </div>
            <div>
              <FormattedMessage
                id='formBuilder.max'
                defaultMessage='Max: {value}'
                values={{
                  value: formatNumberWithSeparators(maximum),
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  if ((isMaxNumberSet || isMinNumberSet) && checkboxField) {
    return (
      <div className={clsx(classes.description, className)}>
        {isMinNumberSet && !isMaxNumberSet && (
          <FormattedMessage
            id='formBuilder.minCheckboxField'
            defaultMessage='Min selection: {value}'
            values={{
              value: formatNumberWithSeparators(minimum),
            }}
          />
        )}
        {isMaxNumberSet && !isMinNumberSet && (
          <FormattedMessage
            id='formBuilder.maxCheckboxField'
            defaultMessage='Max selection: {value}'
            values={{
              value: formatNumberWithSeparators(maximum),
            }}
          />
        )}
        {isMinNumberSet && isMaxNumberSet && (
          <div className={classes.maxMinWrapper}>
            <div>
              <FormattedMessage
                id='formBuilder.minCheckboxField'
                defaultMessage='Min selection: {value}'
                values={{
                  value: formatNumberWithSeparators(minimum),
                }}
              />
              ,
            </div>
            <div>
              <FormattedMessage
                id='formBuilder.maxCheckboxField'
                defaultMessage='Max selection: {value}'
                values={{
                  value: formatNumberWithSeparators(maximum),
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default MinMaxInfo;
