import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useCustomWidgetsStyles = createUseStyles((theme: ThemeState) => {
  return {
    counter: {
      display: 'flex',
      justifyContent: 'flex-end',
      fontWeight: 400,
      color: theme['disabled-color'],
      fontSize: 12,
      marginTop: 'var(--spacingWithin, 4px)',
      marginLeft: 9,
    },
    textAreaWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      '& [class*="errorMessage-"] ': {
        marginTop: 6,
      },
    },
    fixedSize: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      flex: 1,
    },
    onlyCounter: {
      justifyContent: 'flex-end',
    },
    descriptionGrow: {
      flexGrow: 1,
    },
    counterShrink: {
      flexShrink: 0,
    },
    limitedMaxSizeInput: {
      maxWidth: 200,
      width: 'auto',
    },
    limitedMaxSizeInputFull: {
      maxWidth: 700,
    },
    textAreaWidget: {
      position: 'relative',
      '& .ant-input': {
        borderColor: theme['border-color-base'],

        '&:hover': {
          borderColor: theme['primary-color'],
        },
        '&:focus': {
          borderColor: `${theme['primary-color']} !important`,
          boxShadow: `${theme['input-focus-shadow']} !important`,
        },
      },
    },
    textWidget: {
      '& .ant-input': {
        borderColor: theme['border-color-base'],

        '&:hover': {
          borderColor: theme['primary-color'],
        },
        '&:focus': {
          borderColor: `${theme['primary-color']} !important`,
          boxShadow: `${theme['input-focus-shadow']} !important`,
        },
      },
    },
    textWidgetWrapper: {
      position: 'relative',
      maxWidth: 700,
    },
    instructionTextArea: {
      overflow: 'hidden',
      border: `1px solid ${theme['border-color-base']}`,
      borderRadius: 2,
      height: 76,
    },
    textareaIconWrapper: {
      position: 'absolute',
      display: 'flex',
      top: 19,
      right: 11,
    },
    inputIconWrapper: {
      position: 'absolute',
      display: 'flex',
      top: '0',
      right: 11,
      height: '100%',
    },
    icon: {
      width: '16px',
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
      color: theme['primary-color'],
    },
  };
});

export default useCustomWidgetsStyles;
