import React from 'react';
import { DragAndDrop as DndUpload } from 'components/lib/FileUpload';
import useCustomWidgetsStyles from '../../customWidgetsStyles';
import { FormLabelWithToolTip } from 'pages/TaskTemplates/components/FormLabel';
import { FormItem } from 'components/lib/Form';
import { Widget } from 'alx-dynamic-form';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { CustomFileItem } from 'components/lib/FileUpload/types';
import { useFileUploadContext } from 'components/FormPreview2/FileUploadContext';
import clsx from 'clsx';
import { FileList } from './components/FileList';

const CustomFileUploadWidget: Widget = ({
  value,
  onChange,
  maxItems,
  description,
  required,
  label,
  errors,
  fieldAlias,
  disabled,
  additionalFieldProps: {
    recordIdentifier,
    skipUploading,
    readOnly = false,
    inPlaceEditUrl = '',
    customUploadUrl = '',
    shouldDownloadFileOnClick = false,
  } = {},
}) => {
  const classes = useCustomWidgetsStyles({});
  const { updateUploadingFiles } = useFileUploadContext();

  return (
    <Provider store={store}>
      <FormLabelWithToolTip
        dynamicSpacing
        {...{ required }}
        className={clsx([classes.inputLabel, classes.inputLabelEllipsis])}
        label={label}
      />
      <FormItem
        dynamicSpacing
        validateStatus={errors?.length ? 'error' : undefined}
      >
        {readOnly ? (
          <FileList
            files={(value as CustomFileItem[]) ?? []}
            recordIdentifier={recordIdentifier}
            fieldLabel={label}
          />
        ) : (
          <div
            className={classes.limitedMaxSizeInputFull}
            data-testid={`file-upload-${fieldAlias}`}
          >
            <DndUpload
              multiple
              recordIdentifier={recordIdentifier}
              fieldLabel={label}
              fileList={value as CustomFileItem[] | null}
              maxItems={maxItems ?? undefined}
              disabled={
                disabled ||
                readOnly || // this line and the one below are temporary until InPlaceEdit version of this component is not implemented
                inPlaceEditUrl
              }
              {...{
                customUploadUrl,
                description,
                onChange,
                skipUploading,
              }}
              shouldDownloadFileOnClick={shouldDownloadFileOnClick}
              onUpload={updateUploadingFiles}
            />
          </div>
        )}
      </FormItem>
    </Provider>
  );
};

export default CustomFileUploadWidget;
