import {
  getCurrentTable,
  getSourceId,
  shouldApplyFilters,
} from 'store/selectors/filtersSelectors';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataFetchType from 'utils/Enums/DataFetchType';
import { isCurrentlyFetchingTableData } from 'store/selectors/generalSelectors';
import { TableState } from 'react-table';
import usePreferences from '../usePreferences';
import { useHistory, useParams } from 'react-router-dom';
import { Params, UseTableDataReturnType } from '../useCurrentTable/types';
import { usePaginationAndSorting } from './usePaginationAndSorting';
import { useInitialState } from './useInitialState';
import { useGeneratedParamsString } from './useGeneratedParamsString';
import { SearchDataParams } from 'components/SearchInput/types';
import { useErrorStatusContext } from 'contexts/ErrorStatusContext';
import { AxiosResponse } from 'axios';
import { setApplyFilter } from 'store/actions/filtersActions';
import { Location } from 'history';
import { permissionsSelector } from 'store/selectors/permissionsSelectors';
import TablesType from 'utils/Enums/TablesType';
import { UseTableDataProps } from './types';
import { getObjectRecordsColumnConfiguration } from 'store/actions/objectRecordsActions';

const useTableData = ({
  defaultIsVisibleBulkSelectionColumn = false,
  reduxAction,
  resetColumns,
  withFiltersDefault = true,
  withPagination,
  withoutSort = false,
  defaultSearchValue,
  isCustomSearch,
  preferencesId,
  additionalQueryParams,
  defaultSortColumn,
  loading,
  pageSizes,
  isPaginationUsingURL,
  resetPaginationRef,
  customTableName,
}: UseTableDataProps): UseTableDataReturnType => {
  const dispatch = useDispatch();
  const currentTableNameStore = useSelector(getCurrentTable);
  const currentTableName = customTableName ?? currentTableNameStore;
  const isFetching = useSelector(
    isCurrentlyFetchingTableData(preferencesId?.toString())
  );

  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const [navigateTo, setNavigateTo] = useState<Location | undefined>();
  const [
    isVisibleBulkSelectionColumn,
    setIsVisibleBulkSelectionColumn,
  ] = useState(defaultIsVisibleBulkSelectionColumn);
  const [bulkSelectionList, setBulkSelectionList] = useState<number[]>([]);
  const initialState = useInitialState(preferencesId, customTableName);
  const params = useParams<Params>();
  const { setErrorStatus } = useErrorStatusContext();
  const [searchValue, setSearchValue] = useState<SearchDataParams | undefined>(
    defaultSearchValue
  );
  const [withFilters, setWithFilters] = useState(withFiltersDefault);
  const [withPaginationControls, setWithPaginationControls] = useState(true);
  const applyFilters = useSelector(shouldApplyFilters);

  const {
    isInfinite,
    pagination,
    sort,
    ...paginationMethods
  } = usePaginationAndSorting({
    isFetching,
    isUsingURL: isPaginationUsingURL,
    withPagination,
    withoutSort,
    defaultSortColumn,
    pageSizes,
    resetPaginationRef,
    customTableName,
  });

  const {
    updateTableColumnSizePreferences,
    updateTablePreferences,
  } = usePreferences(preferencesId);
  const history = useHistory();

  useEffect(() => {
    return () => {
      if (resetColumns) dispatch(resetColumns());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateParamString = useGeneratedParamsString({
    pagination: {
      ...pagination,
      pageSize: pagination.pageSize,
    },
    sort,
    withFilters,
    classID: preferencesId?.toString(),
    customTableName,
  });

  const fetchData = useCallback(async () => {
    if (editModeEnabled) {
      return;
    }
    const queryParams = generateParamString();
    const search = searchValue?.value;
    const queryParamsWithSearch = search
      ? `${queryParams}&${searchValue?.searchKey}=${encodeURIComponent(search)}`
      : queryParams;

    const queryParamsWithAdditionalData = additionalQueryParams
      ? `${queryParamsWithSearch}${additionalQueryParams}`
      : queryParamsWithSearch;

    const isStartedOffset = pagination.currentPage === 1;

    const response = dispatch(
      reduxAction(
        queryParamsWithAdditionalData,
        isInfinite && !isStartedOffset
          ? DataFetchType.Append
          : DataFetchType.Overwrite,
        params
      )
    ) as AxiosResponse | undefined;

    const { status = false } = response || {};

    if (status) setErrorStatus(status);
  }, [
    editModeEnabled,
    generateParamString,
    searchValue,
    additionalQueryParams,
    pagination.currentPage,
    dispatch,
    reduxAction,
    isInfinite,
    params,
    setErrorStatus,
  ]);

  const permissions = useSelector(permissionsSelector);

  const mapTableNameToPermissions: MappedObject<{ list?: boolean }> = {
    [TablesType.TaskTemplates]: permissions?.task_templates,
    [TablesType.ObjectRecords]: permissions?.object_records,
    [TablesType.TaskGroups]: permissions?.task_group_templates,
    [TablesType.ObjectClasses]: permissions?.object_classes,
    [TablesType.Users]: permissions?.users,
    [TablesType.UserGroups]: permissions?.user_groups,
    [TablesType.Roles]: permissions?.roles,
    [TablesType.Forms]: permissions?.forms,
    [TablesType.MessageTemplates]: permissions?.message_templates,
    [TablesType.EmailCollectors]: permissions?.email_collectors,
    [TablesType.SingleSignOn]: permissions?.single_sign_on,
    [TablesType.AuthenticationObjects]: permissions?.authentication_objects,
  };

  const hasListPermissions =
    mapTableNameToPermissions[currentTableName as string]?.list ?? true;

  const sourceId = useSelector(getSourceId);

  useEffect(() => {
    // queryParamsWithSearch
    if (
      !isFetching &&
      !loading &&
      hasListPermissions &&
      additionalQueryParams !== undefined
    )
      (async () => await fetchData())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.pageSize,
    pagination.currentPage,
    sort,
    isInfinite,
    additionalQueryParams,
    sourceId,
    loading,
  ]);

  useEffect(() => {
    // this will be invoke when variable "applyFilters" will be "true" and pagination.currentPage won't be changing after settting filters.
    // If pagination.currentPage will be changed then useEffect above will be invoke and here variable "isFetching" should be equal true
    if (
      !isFetching &&
      hasListPermissions &&
      !loading &&
      applyFilters &&
      additionalQueryParams !== undefined
    )
      (async () => await fetchData())();

    if (applyFilters) dispatch(setApplyFilter(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyFilters, loading]);

  useEffect(() => {
    if (navigateTo) {
      history.push(navigateTo.pathname);
    }
  }, [history, navigateTo]);

  const onSaveTableConfiguration = useCallback(
    async (tableState: Partial<TableState>) => {
      if (currentTableName) {
        const update = await updateTablePreferences(
          currentTableName,
          tableState
        );

        if (update) {
          dispatch(getObjectRecordsColumnConfiguration(preferencesId));
          setEditModeEnabled(false);

          return update;
        }

        return false;
      }
    },
    [currentTableName, preferencesId, dispatch, updateTablePreferences]
  );

  const onColumnResize = useCallback(
    (columnKey: string, width: number) => {
      if (currentTableName && !editModeEnabled) {
        updateTableColumnSizePreferences(currentTableName, columnKey, width);
      }
    },
    [currentTableName, editModeEnabled, updateTableColumnSizePreferences]
  );

  const onRejectSaveChanges = useCallback((navigateTo?: Location) => {
    if (navigateTo) {
      setNavigateTo(navigateTo);
      setEditModeEnabled(false);
    }
  }, []);

  const onSaveChangesBeforeLeave = useCallback(
    async (tableState: Partial<TableState>) => {
      return await onSaveTableConfiguration(tableState);
    },
    [onSaveTableConfiguration]
  );

  const searchData = useCallback(async () => {
    dispatch(setApplyFilter(true));
  }, [dispatch]);

  const handleSearchChange = (
    params: SearchDataParams,
    blockSearchData?: boolean // to avoid double API requests
  ) => {
    setSearchValue(params);

    if (!isCustomSearch && !blockSearchData) searchData();
  };

  return {
    currentPage: pagination.currentPage,
    pageSize: pagination.pageSize,
    isInfinite,
    onSaveTableConfiguration,
    editModeEnabled,
    setEditModeEnabled,
    onColumnResize,
    onRejectSaveChanges,
    onSaveChangesBeforeLeave,
    initialState,
    currentTableName,
    sort,
    fetchData,
    searchValue,
    handleSearchChange,
    isVisibleBulkSelectionColumn,
    setIsVisibleBulkSelectionColumn,
    bulkSelectionList,
    setBulkSelectionList,
    applyFilters,
    withFilters,
    setWithFilters,
    withPaginationControls,
    setWithPaginationControls,
    ...paginationMethods,
  };
};

export default useTableData;
