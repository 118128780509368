enum CustomCells {
  Status = 'status',
  ObjectRecordGroups = 'object_classes',
  ObjectRecord = 'object_record',
  ObjectName = 'object_name',
  Levels = 'levels',
  TaskGroups = 'task_group_templates',
  Relations = 'relations',
  Roles = 'roles',
  State = 'state',
  APIActorsNames = 'api_actor_names',
  Label = 'label',
  Required = 'is_required',
  Unique = 'is_unique',
  AutoCreateUse = 'auto_create_user',
  AddInEnabled = 'is_word_addin_enabled',
  Enabled = 'is_enabled',
  Membership = 'membership',
  Owners = 'owners',
  DueDate = 'due_date',
  ObjectClass = 'object_class',
  Name = 'name',
  Uuid = 'uuid',
  TemplateFileName = 'template_file_name',
  Assignees = 'current_assignees',
  GeneratedDocument = 'generated_document',
  Publisher = 'publisher',
  ManualDocumentGeneration = 'allow_on_demand_generation',
}

export default CustomCells;
