import { createUseStyles } from 'react-jss';

const useDetailsStyles = createUseStyles({
  wrapper: {
    width: '100%',
    fontSize: 12,
    '& > div:last-of-type': {
      marginBottom: 5,
    },
  },
  centerLoader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  titleWrapper: {
    marginRight: 10,
    marginBottom: 10,
    fontWeight: 700,
    fontSize: 14,
  },
  title: {
    marginLeft: 10,
    fontWeight: 700,
    maxWidth: 'calc(100% - 35px)',
  },
  job: { marginLeft: 35, marginBottom: 5 },
  description: { marginLeft: 35, marginBottom: 15 },
  iconItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
  },
  icon: {
    marginRight: 10,
    color: '#9EABCB',
  },
  link: {
    textDecoration: 'underline',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  wrapText: {
    wordWrap: 'break-word',
  },
  iconText: {
    maxWidth: 'calc(100% - 26px)',
  },
  descriptionWrapper: {
    maxHeight: '50vh',
    overflow: 'auto',
  },
});

export default useDetailsStyles;
