import { ColumnResizingState } from 'hooks/useCurrentTable/types';
import { Column } from 'react-table';
import { ColumnsMetadata } from 'utils/types/api/table.types';

export const makeKnownColumns = (columns: Column<object>[]) =>
  columns
    .map(column => column.id)
    .filter((id): id is string => id !== undefined);

export const composeAllHiddenColumns = (
  hiddenColumns: string[] | null | undefined,
  knownColumns: string[] | null | undefined,
  allColumns: MappedObject<ColumnsMetadata>
) => {
  if (hiddenColumns === null || hiddenColumns === undefined) return null;

  return [
    ...(hiddenColumns || []),
    ...Object.keys(allColumns).filter(
      column => knownColumns !== undefined && !knownColumns?.includes(column)
    ),
  ];
};

export const composeResizingColumnWidths = (
  columnResizing: ColumnResizingState,
  initialColumnResizing: ColumnResizingState | undefined
) => ({
  ...columnResizing,
  columnWidths: {
    ...initialColumnResizing?.columnWidths,
    ...columnResizing.columnWidths,
  },
});

export const getColumnVarName = (columnKey: string) =>
  `--table-column-${columnKey}`;

export const getWidth = (el: HTMLDivElement | null) => {
  const bcr = el?.getBoundingClientRect();

  return Math.floor(bcr?.width ?? 0);
};
