import { createUseStyles } from 'react-jss';
import { ACTIONS_COLUMN_WIDTH } from 'components/Table/consts';
import { ThemeState } from 'store/reducers/types/theme.types';
import { getBodyRowHeight } from 'pages/MyAccount/components/MyAccountSections/components/PreferencesTab/components/RowSettings/components/styles';
import { getHeaderCellHeight } from 'components/Table/styles';
import { TableDensity } from 'components/Table/enums';

type UseChildClassTableStylesProps = {
  density: TableDensity;
  ACTIONS_COLUMN_WIDTH?: number;
};

export const useChildClassTableStyles = createUseStyles((theme: ThemeState) => {
  return {
    table: {
      width: '100%',
      border: `1px solid ${theme['border-color-base']}`,
      marginTop: 10,
      overflowX: 'auto',
    },
    headerRow: {
      backgroundColor: theme['dropdown-active-gray'],
    },
    tableCell: ({
      ACTIONS_COLUMN_WIDTH,
    }: {
      ACTIONS_COLUMN_WIDTH?: number;
    }) => ({
      width: ACTIONS_COLUMN_WIDTH || 100,
    }),
    row: {
      display: 'flex',
      '& > th:first-child': {
        flex: 1,
        paddingLeft: '15px',
        height: (props: UseChildClassTableStylesProps) =>
          getBodyRowHeight(props?.density),
        backgroundColor: theme['dropdown-active-gray'],
      },
    },
    tableHeadCell: {
      height: (props: UseChildClassTableStylesProps) =>
        getHeaderCellHeight(props?.density),
      paddingLeft: '15px',
      backgroundColor: theme['dropdown-active-gray'],
      position: 'sticky',
      display: 'flex',
      alignItems: 'center',
      top: 0,
      color: theme['heading-color'],
      fontSize: 13,
      fontWeight: '700',
      '&:first-of-type': {
        borderRadius: '3px 0 0 3px',
      },
      '&:last-of-type': {
        borderRadius: '0 3px 3px 0',
      },
      textTransform: 'uppercase',
    },
    flexAlignCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    actionsHeader: {
      padding: '0 15px',
      backgroundColor: theme['dropdown-active-gray'],
    },
    tableSearch: {
      '& div': {
        height: '45px',
      },
    },
    tableRows: {
      width: 'fit-content',
      minWidth: '100%',
      '& div': {
        height: (props: UseChildClassTableStylesProps) =>
          getBodyRowHeight(props?.density),
      },
    },
    tableRow: {
      display: 'flex',
    },
    searchBox: {
      maxWidth: '70%',
      width: '70%',
      margin: 6,
      marginLeft: 18,
    },
    actionsHeaderCell: {
      borderRadius: '0px 3px 3px 0px',
      display: 'flex',
      justifyContent: 'end',
      width: '100% !important',
      minWidth: ACTIONS_COLUMN_WIDTH,
    },
  };
});

export const useNestedChildClassTableStyles = createUseStyles({
  buttonWrapper: {
    height: 35,
  },
  headerText: {
    display: 'flex',
    alignSelf: 'center',
    marginBottom: 5,
  },
});
