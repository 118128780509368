import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useNestedRecordsBreadcrumbsStyles = createUseStyles(
  (theme: ThemeState) => {
    return {
      separator: {
        margin: '0 8px',
      },
      recordLink: {
        fontWeight: 400,
        color: `${theme['primary-color']} !important`,
        '&:hover, &:active, &:focus': {
          color: `${theme['primary-color']} !important`,
          textDecoration: 'underline !important',
          cursor: 'pointer',
        },
      },
      breadcrumbText: {
        cursor: 'default',
        color: theme['text-color'],
      },
      lastArrow: {
        color: theme['default-red'],
      },
      textColor: {
        color: theme['text-color'],
      },
      bold: {
        fontWeight: 'bold',
      },
      rotatedDots: {
        transform: 'rotate(90deg)',
      },
      tooltip: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    };
  }
);

export default useNestedRecordsBreadcrumbsStyles;
