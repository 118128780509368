import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { LabelPosition } from '.';

const getWrapperDirection = (position?: LabelPosition) => {
  switch (position) {
    case LabelPosition.top:
      return 'column';
    case LabelPosition.right:
      return 'row-reverse';
    default:
      return 'row';
  }
};

type UseSwitchStylesProps = {
  position?: LabelPosition;
};

const useSwitchStyles = createUseStyles((theme: ThemeState) => {
  return {
    switch: {
      border: `1px solid ${theme['primary-color']}`,
      '&.ant-switch-checked': {
        background: theme['primary-color'],
        borderColor: theme['primary-color'],
        '& .ant-switch-handle': {
          left: 'calc(100% - 18px)',
          '&::before': {
            background: '#fff',
          },
        },
      },
      '&:focus': {
        borderColor: theme['primary-color'],
        boxShadow: 'none',
      },
      '& .ant-switch-handle': {
        width: 16,
        height: 16,
        '&::before': {
          background: theme['primary-color'],
          boxShadow: 'none',
        },
      },
      backgroundColor: '#fff',
    },
    switchWrapper: ({ position }: UseSwitchStylesProps) => ({
      display: 'flex',
      alignItems: position === LabelPosition.top ? 'flex-start' : 'center',
      flexDirection: getWrapperDirection(position),
      minWidth: 80,
    }),
    label: ({ position }: UseSwitchStylesProps) => ({
      [position === LabelPosition.right ? 'marginLeft' : 'marginRight']: 10,
      marginBottom: position === LabelPosition.top ? 10 : 0,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }),
    boldLabel: {
      fontWeight: 700,
    },
  };
});

export default useSwitchStyles;
