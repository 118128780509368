import { createUseStyles } from 'react-jss';

export const menuWidth = 300;

type DropdownMenuStylesProps = {
  x: number;
};

const useDropdownMenuStyles = createUseStyles({
  list: {
    minWidth: 130,
    maxWidth: 300,
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.25) !important',
    padding: [4, 0],
  },
  actionsWrapper: ({ x }: DropdownMenuStylesProps) => ({
    position: 'absolute',
    zIndex: 2,
    top: '35px',
    left: x,
  }),
});

export default useDropdownMenuStyles;
