import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useDatePickerStyles = createUseStyles((theme: ThemeState) => {
  return {
    datePicker: {
      width: '100%',
      height: 35,
      '& .ant-input': {
        minHeight: 32,
        borderColor: `${theme['border-color-base']} !important`,
      },
    },
    pointer: {
      cursor: 'pointer',
    },
    readonly: {
      '&, &:focus, &:active, &:hover, & span, & span:focus, & span:focus-within, & input, & input:focus, & input:focus-within, & input:active, & input:hover': {
        borderColor: `${theme['border-color-base']} !important`,
        boxShadow: 'none !important',
        cursor: 'default',
        backgroundColor: `${theme['disabled-input-gray']} !important`,
        color: `${theme['placeholder-text-color']}!important`,
      },
    },
  };
});

export default useDatePickerStyles;
