import React, { useState, Fragment } from 'react';
import moment, { Moment } from 'moment';
import { DatePickerInput } from 'components/Inputs/DatePicker';
import useDateWidgetStyles from './styles';
import { FormattedMessage } from 'react-intl';
import useFormPreviewStyles from 'components/FormPreview2/styles';
import useFormPreview2Container from 'components/FormPreview2/hooks/useFormPrevievContainer';
import { WidgetProps } from 'alx-dynamic-form';
import { CUSTOM_DATE_FORMAT } from 'pages/TaskTemplates/components/TaskForm/consts';

const DateWidget: React.FC<Partial<WidgetProps>> = ({
  disabled,
  onChange,
  value,
  dateGreaterThanToday,
  reValidateField,
  fieldAlias,
}) => {
  const [componentKey, setComponentKey] = useState(new Date().toString());
  const getPopupContainer = useFormPreview2Container();
  const classes = useDateWidgetStyles({});
  const formClasses = useFormPreviewStyles({});

  const disabledDate = dateGreaterThanToday
    ? (currentDate: Moment) => moment(currentDate).isSameOrBefore(moment(), 'D')
    : undefined;

  const handleChange = (nextValue: Moment | null) => {
    if (!onChange) return;

    onChange(nextValue?.format(CUSTOM_DATE_FORMAT) || null);

    if (reValidateField) reValidateField();

    setComponentKey(new Date().toString());
  };

  return (
    // adding key to Fragment it's temporary solution to force mount DatePickerInput, it helps to update method getPopupContainer inside DatePicker
    <Fragment key={componentKey}>
      <DatePickerInput
        onChange={handleChange}
        showTime={false}
        className={classes.widget}
        value={!!value ? moment(value as string) : undefined}
        showNow={!dateGreaterThanToday}
        {...{ getPopupContainer, disabledDate, dateGreaterThanToday, disabled }}
        onBlur={reValidateField}
        data-testid={`date-picker-${fieldAlias}`}
      />
      {!!dateGreaterThanToday && (
        <span className={formClasses.description}>
          <FormattedMessage
            id='formBuilder.onlyDatesInTheFuture'
            defaultMessage='Only dates in the future'
          />
        </span>
      )}
    </Fragment>
  );
};

export default DateWidget;
