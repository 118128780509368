export const onFocusNonAutoCompleteInput = (
  element: React.FocusEvent<HTMLInputElement>
) => {
  const { target } = element;

  if (target.hasAttribute('readonly')) {
    target.removeAttribute('readonly');
    target.focus();
  }
};
