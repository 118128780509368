import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseEventDataStylesProps = {
  isExpanded?: boolean;
};

const useEventDataStyles = createUseStyles((theme: ThemeState) => ({
  eventDataWrapper: {
    alignItems: 'center',
    display: 'flex',
    gap: '12px',
  },
  eventDataLabel: {
    fontSize: 14,
    margin: 0,
    wordBreak: 'break-word',
    '&> strong': {
      fontWeight: 700,
    },
  },
  eventDataExpand: {
    color: theme['secondary-color'],
    transform: ({ isExpanded }: UseEventDataStylesProps) =>
      isExpanded ? 'rotate(90deg)' : 'none',
    transition: 'transform 200ms ease',
  },
}));

export default useEventDataStyles;
