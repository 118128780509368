import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useCustomCellStyles = createUseStyles((theme: ThemeState) => {
  return {
    tag: {
      borderRadius: '2px',
      marginLeft: '8px',
      padding: '2px 8px 2px 8px',
      fontWeight: 600,
    },
    system: {
      color: theme['primary-color'],
      backgroundColor: theme['primary-color-light'],
    },
    identifier: {
      color: theme['field-secondary-tag-font-color'],
      backgroundColor: theme['field-secondary-tag-bg-color'],
    },
    booleanCheck: {
      color: theme['chips-color'],
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  };
});
