import { Drawer } from 'antd';
import { CloseIcon } from 'components/Icon';
import React, { useEffect } from 'react';
import { CONTENT_ID, DRAWER_CONTENT_WRAPPER } from 'utils/elementsIds';
import { CONTENT_DRAWER, CONTENT_DRAWER_CLOSE_ICON } from 'utils/testIds';
import useTaskDrawerStyles from './styles';
import { TaskDrawerProps } from './types';

const ContentDrawer: React.FC<TaskDrawerProps> = ({
  isOpen,
  onCloseClick,
  children,
}) => {
  const classes = useTaskDrawerStyles({});

  const drawerContainer = document.getElementById(CONTENT_ID);

  // make sure that there is no scroll on the parent container when drawer is open
  useEffect(() => {
    if (!drawerContainer) return;

    if (isOpen) {
      drawerContainer.style.overflow = 'hidden';
      drawerContainer.scrollTop = 0;
    }

    return () => {
      drawerContainer.style.overflow = 'auto';
    };
  }, [isOpen, drawerContainer]);

  if (!drawerContainer) return null;

  return (
    <Drawer
      destroyOnClose
      data-testid={CONTENT_DRAWER}
      visible={isOpen}
      className={classes.drawer}
      getContainer={drawerContainer}
      bodyStyle={{
        padding: 0,
        display: 'flex',
      }}
      width='100%'
      closable={false}
    >
      <div className={classes.closeButtonContainer}>
        <CloseIcon
          data-testid={CONTENT_DRAWER_CLOSE_ICON}
          size={18}
          style={{ cursor: 'pointer' }}
          onClick={onCloseClick}
        />
      </div>
      <div className={classes.drawerContentWrapper} id={DRAWER_CONTENT_WRAPPER}>
        {children}
      </div>
    </Drawer>
  );
};

export default ContentDrawer;
