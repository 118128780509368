import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { getFieldNameTranslation } from './formErrorFieldTranslation';
import { parseToIntlKey } from './parseToIntlKey';

export const getParsedErrorMessage = (
  value: string[],
  key: string,
  formPrefix?: string
) => {
  const genericFieldSubstitute =
    GlobalIntlSingleton.dynamicFormatMessage({
      id: `forms.value`,
    }) || key;

  const errors = value.map(error => {
    const errorKey = parseToIntlKey(error);
    const formFieldSpecificTranslationKey = `${formPrefix}.fieldErrors.${key}.${errorKey}`;

    // if there is specific error translation dedicated for a field in a form
    if (GlobalIntlSingleton.intl.messages[formFieldSpecificTranslationKey]) {
      return GlobalIntlSingleton.dynamicFormatMessage({
        id: formFieldSpecificTranslationKey,
      });
    }

    const formSpecificKey = `${formPrefix}.errors.${errorKey}`;

    // if there is error translation for all fields in a form
    if (GlobalIntlSingleton.intl.messages[formSpecificKey])
      return GlobalIntlSingleton.dynamicFormatMessage(
        {
          id: formSpecificKey,
        },
        {
          fieldKey: formPrefix
            ? getFieldNameTranslation(formPrefix, key)
            : genericFieldSubstitute,
        }
      );

    // if none of above, display generic error message
    return GlobalIntlSingleton.dynamicFormatMessage(
      {
        id: `errors.${errorKey}`,
      },
      {
        fieldKey: formPrefix
          ? getFieldNameTranslation(formPrefix, key)
          : genericFieldSubstitute,
      }
    );
  });

  return errors.join(' ');
};
