import React from 'react';
import { FILES_LIST, FILES_LIST_ITEM } from 'utils/testIds';
import { useFileListStyles } from './FileList.styles';
import { FileListProps } from './FileList.types';
import { FileViewerLauncher } from 'components/FileViewerLauncher';
import { FileActionsMenu } from 'components/FileActionsMenu';

export const FileList = ({
  files,
  recordIdentifier,
  fieldLabel,
}: FileListProps) => {
  const styles = useFileListStyles();

  // temporary solution - should be remove if api return files in meta on listing
  if (files && files.length > 0 && typeof files[0] !== 'object') {
    return <p>-</p>;
  }

  if (!files.length) {
    return <p>-</p>;
  }

  return (
    <ul className={styles.fileList} data-testid={FILES_LIST}>
      {files.map(({ name, url, id }) => {
        if (!url) {
          return <></>;
        }

        return (
          <li
            key={id}
            className={styles.fileItem}
            data-testid={FILES_LIST_ITEM}
          >
            <FileViewerLauncher
              fileName={name}
              fileUrl={url}
              fieldLabel={fieldLabel}
              recordIdentifier={recordIdentifier}
            />

            <FileActionsMenu fileName={name} fileUrl={url} showDelete={false} />
          </li>
        );
      })}
    </ul>
  );
};
