const fixValue = (
  value: number,
  multipleOf: number | undefined,
  isDecimal: undefined | boolean = true
) => {
  if (!multipleOf) return value;

  if (multipleOf === 1) return Math.round(value);

  const [beforeDot, afterDot] = value.toString().split(/[,.]/);

  if (!isDecimal) return Number(beforeDot);

  const preciseValue = `${beforeDot}${
    afterDot !== undefined
      ? '.' + afterDot?.slice(0, Math.log10(multipleOf) * -1)
      : ''
  }`;

  return parseFloat(preciseValue);
};

export default fixValue;
