import React from 'react';
import clsx from 'clsx';
import useDatePickerStyles from './styles';
import moment from 'moment';
import { Input } from 'components/lib/Input';
import { CalendarOutlined } from '@ant-design/icons';
import useGlobalStyle from 'hooks/useGlobalStyle';
import { ReadonlyDatepickerProps } from './types';

const ReadonlyDatepicker: React.FC<ReadonlyDatepickerProps> = ({
  value,
  format,
}) => {
  const classes = useDatePickerStyles({});
  const globalClasses = useGlobalStyle();
  const inputValue = moment(value).format(format);

  return (
    <Input
      readOnly
      value={inputValue}
      suffix={<CalendarOutlined />}
      className={clsx([
        'ant-picker',
        classes.datePicker,
        classes.pointer,
        classes.readonly,
        globalClasses.disabledInput,
      ])}
    />
  );
};

export default ReadonlyDatepicker;
