import axios, { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { ErrorCodes } from './types/errorResponse';
const SUCCESS_CODES = [StatusCodes.OK, StatusCodes.CREATED];

export const isSuccess = (code: number) => SUCCESS_CODES.includes(code);
export const isNotFound = (error: unknown): error is AxiosError =>
  axios.isAxiosError(error) &&
  error?.response?.status === StatusCodes.NOT_FOUND;

export const isBadRequest = (error: unknown): error is AxiosError =>
  axios.isAxiosError(error) &&
  error?.response?.status === StatusCodes.BAD_REQUEST;

export const isForbidden = (error: unknown): error is AxiosError =>
  axios.isAxiosError(error) &&
  error?.response?.status === StatusCodes.FORBIDDEN;

export const isInvalidTokenError = (
  error: AxiosError<{ error_code: ErrorCodes }>
) => {
  return error.response?.data?.error_code === ErrorCodes.ERR_INVALID_TOKEN;
};
