import useModalStyles from 'components/ConfirmationModal/styles';
import { ButtonSecondaryOutlined, ButtonTertiary } from 'components/lib/Button';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import useFormBuilderModalStyles from './styles';
import { FormBuilderHeaderProps } from './types';
import {
  FORM_BUILDER_HEADER_CLOSE_TESTID,
  FORM_BUILDER_HEADER_REVERT_FORM_TESTID,
  FORM_BUILDER_HEADER_SUBMIT_TESTID,
} from 'utils/testIds';

const FormBuilderHeader: React.FC<FormBuilderHeaderProps> = ({
  hasSubmit,
  onCancel,
  saving,
  hasErrors,
  resetForm,
  title,
  isFormTouched,
  submitVisibleData,
  submitButtonContent,
  submitButtonIcon,
  isCreateMode,
}) => {
  // do not ever pass an un-memoized reference type to any use<Name>Styles in this file
  // it will cuase severe performance issues.
  const useFormBuilderModalStylesProps = useMemo(() => {
    return {};
  }, []);
  const useModalStylesProps = useMemo(() => {
    return {};
  }, []);
  const classes = useFormBuilderModalStyles(useFormBuilderModalStylesProps);
  const modalClasses = useModalStyles(useModalStylesProps);

  const closeButton = (preview = true) => (
    <ButtonSecondaryOutlined
      onClick={onCancel}
      data-testid={FORM_BUILDER_HEADER_CLOSE_TESTID}
    >
      {preview ? (
        <FormattedMessage id='misc.close' defaultMessage='Close' />
      ) : (
        <FormattedMessage id='formBuilder.cancel' defaultMessage='Cancel' />
      )}
    </ButtonSecondaryOutlined>
  );

  return (
    <div className={classes.formHeader}>
      {!!hasSubmit ? (
        <div className={classes.submitHeaderWrapper}>
          {!!title && <span className={classes.title}>{title}</span>}
          <div className={classes.resetButtonWrapper}>
            <ButtonSecondaryOutlined
              disabled={!isFormTouched}
              onClick={() => !!resetForm && resetForm()}
              data-testid={FORM_BUILDER_HEADER_REVERT_FORM_TESTID}
            >
              <FormattedMessage id='misc.revert' defaultMessage='Revert' />
            </ButtonSecondaryOutlined>
          </div>
          <div className={classes.submitWrapper}>
            {closeButton(false)}
            <ButtonTertiary
              icon={submitButtonIcon}
              htmlType='submit'
              disabled={
                saving || hasErrors || (!isCreateMode && !isFormTouched)
              }
              loading={saving}
              onClick={submitVisibleData}
              className={modalClasses.submitButton}
              data-testid={FORM_BUILDER_HEADER_SUBMIT_TESTID}
            >
              {submitButtonContent || (
                <FormattedMessage id='misc.save' defaultMessage='Save' />
              )}
            </ButtonTertiary>
          </div>
        </div>
      ) : (
        <div className={classes.previewHeaderWrapper}>{closeButton()}</div>
      )}
    </div>
  );
};

export default FormBuilderHeader;
