import React from 'react';
import useInformationSectionStyle from './styles';
import { Col } from '../lib/Grid';
import { FormattedMessage } from 'react-intl';
import {
  INFORMATION_SUBTITLE_TESTID,
  INFORMATION_TITLE_TESTID,
  INFORMATION_WRAPPER_TESTID,
} from 'utils/testIds';
import { useSelector } from 'react-redux';
import { customThemeSelector } from 'store/selectors/customThemeSelectors';

const AutologyxInformation: React.FC = () => {
  const classes = useInformationSectionStyle();
  const {
    textOverrides: { headingText = undefined, bodyText = undefined } = {},
    isEnabled,
  } = useSelector(customThemeSelector);

  return (
    <div className={classes.wrapper} data-testid={INFORMATION_WRAPPER_TESTID}>
      <Col className={classes.informationWrapper}>
        <p className={classes.title} data-testid={INFORMATION_TITLE_TESTID}>
          {isEnabled && headingText !== undefined ? (
            headingText
          ) : (
            <FormattedMessage
              id='login.header'
              defaultMessage='The {operationsPlatform} for Legal and Compliance'
              values={{
                operationsPlatform: (
                  <strong>
                    <FormattedMessage
                      id='login.OperationsPlatform'
                      defaultMessage='Operations Platform'
                    />
                  </strong>
                ),
              }}
            />
          )}
        </p>
        <p
          className={classes.subtitle}
          data-testid={INFORMATION_SUBTITLE_TESTID}
        >
          {isEnabled && bodyText !== undefined ? (
            bodyText
          ) : (
            <FormattedMessage
              id='login.supportingText'
              defaultMessage='Build digital solutions for your workforce and customers on a single platform. Turn ideas into reality, fast.'
            />
          )}
        </p>
      </Col>
    </div>
  );
};

export default AutologyxInformation;
