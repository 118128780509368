import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logoutUser } from 'store/actions/authActions';
import routes from 'utils/routingPaths';

const Logout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const logout = async () => {
      await dispatch(logoutUser());
      history.push(routes.HOMEPAGE);
    };

    logout();
  }, [dispatch, history]);

  return <div></div>;
};

export default Logout;
