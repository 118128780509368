export const SET_OBJECT_CLASSES_FIELDS = 'SET_OBJECT_CLASSES_FIELDS';
export const APPEND_OBJECT_CLASSES_FIELDS = 'APPEND_OBJECT_CLASSES_FIELDS';
export const SET_OBJECT_CLASSES_FIELDS_FETCHING =
  'SET_OBJECT_CLASSES_FIELDS_FETCHING';
export const RESET_OBJECT_CLASSES_FIELDS = 'RESET_OBJECT_CLASSES_FIELDS';
export const SET_OBJECT_CLASSES_FIELDS_COLUMNS =
  'SET_OBJECT_CLASSES_FIELDS_COLUMNS';
export const RESET_OBJECT_CLASSES_FIELDS_COLUMNS =
  'RESET_OBJECT_CLASSES_FIELDS_COLUMNS';
export const SET_OBJECT_CLASSES_FIELDS_SELECTED_ROW =
  'SET_OBJECT_CLASSES_FIELDS_SELECTED_ROW';
export const SET_OBJECT_CLASSES_FIELDS_DETAILS =
  'SET_OBJECT_CLASSES_FIELDS_DETAILS';
export const ADD_FIELD = 'ADD_FIELD';
export const REMOVE_FIELD = 'REMOVE_FIELD';
export const UPDATE_FIELD = 'UPDATE_FIELD';
export const REORDER_FIELDS = 'REORDER_FIELDS';
export const RESET_OBJECT_CLASSES_FIELDS_CUSTOM_OFFSET =
  'RESET_OBJECT_CLASSES_FIELDS_CUSTOM_OFFSET';
export const REMOVE_OBJECT_CLASSES_FIELDS = 'REMOVE_OBJECT_CLASSES_FIELDS';
export const SET_OBJECT_CLASS_FIELDS_INITIAL_VALUES =
  'SET_OBJECT_CLASS_FIELDS_INITIAL_VALUES';
