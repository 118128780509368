import { useToggle } from 'hooks/useToggle';
import { useCallback, useState } from 'react';
import { SelectUserOption } from 'utils/types/selectInput.types';
import { UsersSelectProps } from './types';
import { useIntl } from 'react-intl';

const useSelectUsers = ({
  users,
  setUsers,
  options,
  disabled,
  onChange,
  reValidateField,
}: UsersSelectProps) => {
  const [
    isOpenSelect,
    { toggleOn: openSelect, toggleOff: closeSelect },
  ] = useToggle(false);

  const handleOpenSelect = useCallback(() => {
    if (disabled) return;
    openSelect();
  }, [disabled, openSelect]);

  const handleCloseSelect = useCallback(() => {
    if (onChange) onChange(users);
    if (reValidateField) reValidateField();
    closeSelect();
  }, [users, onChange, reValidateField, closeSelect]);

  const checkIfSelected = useCallback(
    (user: SelectUserOption) => {
      return users.some(({ id }) => id === user.id);
    },
    [users]
  );

  const handleChange = useCallback(
    ({ value }) => {
      const user = options.find(u => u.id === value);
      if (user) {
        setUsers(users =>
          checkIfSelected(user)
            ? users.filter(({ id }) => user.id !== id)
            : [...users, user]
        );
      }
    },
    [checkIfSelected, options, setUsers]
  );

  return {
    isOpenSelect,
    checkIfSelected,
    handleOpenSelect,
    handleCloseSelect,
    handleChange,
  };
};

export const useHandleDeletedUsers = () => {
  const intl = useIntl();
  const [tooltip, setTooltip] = useState('');
  const [customError, setCustomError] = useState('');

  const noUsersToSelect = intl.formatMessage({
    id: 'errors.noUsersToSelect',
    defaultMessage: 'No users to select',
  });
  const notEnoughToSelect = intl.formatMessage({
    id: 'errors.notEnoughToSelect',
    defaultMessage: 'Not enough users to select',
  });
  const noUsersToSelectError = intl.formatMessage({
    id: 'errors.noUsersToSelectError',
    defaultMessage:
      'No users to select and save the record. Contact System Administrator.',
  });
  const notEnoughToSelectError = intl.formatMessage({
    id: 'errors.notEnoughToSelectError',
    defaultMessage:
      'Not enough users to select and save the record. Contact System Administrator.',
  });

  const handleDeletedUsers = useCallback(
    (activeUsers: number, required: boolean, minValues = 0) => {
      if (activeUsers && activeUsers >= minValues) return;

      setTooltip(activeUsers ? notEnoughToSelect : noUsersToSelect);
      if (required)
        setCustomError(
          activeUsers ? notEnoughToSelectError : noUsersToSelectError
        );
    },
    [
      noUsersToSelect,
      noUsersToSelectError,
      notEnoughToSelect,
      notEnoughToSelectError,
    ]
  );

  return { handleDeletedUsers, tooltip, customError };
};

export default useSelectUsers;
