export const SET_MESSAGE_TEMPLATES = 'SET_MESSAGE_TEMPLATES';
export const APPEND_MESSAGE_TEMPLATES = 'APPEND_MESSAGE_TEMPLATES';
export const SET_MESSAGE_TEMPLATES_FETCHING = 'SET_MESSAGE_TEMPLATES_FETCHING';
export const SET_TOTAL_MESSAGE_TEMPLATES = 'SET_TOTAL_MESSAGE_TEMPLATES';
export const RESET_MESSAGE_TEMPLATES = 'RESET_MESSAGE_TEMPLATES';
export const SET_MESSAGE_TEMPLATES_COLUMNS = 'SET_MESSAGE_TEMPLATES_COLUMNS';
export const RESET_MESSAGE_TEMPLATES_COLUMNS =
  'RESET_MESSAGE_TEMPLATES_COLUMNS';
export const SET_MESSAGE_TEMPLATES_SELECTED_ROW =
  'SET_MESSAGE_TEMPLATES_SELECTED_ROW';
