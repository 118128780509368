import { useEffect, useRef, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { apiCall } from 'utils/api';
import { UseVerifyIfObjectExists } from './types';

const useVerifyIfObjectExists = <T extends { filtered_count: number }>({
  validate,
  autocompleteUrl,
  replaceValues,
}: UseVerifyIfObjectExists) => {
  const [isLoading, setIsLoading] = useState(false);
  const [doesExist, setDoesExist] = useState(false);
  const hasFetched = useRef(false);

  useEffect(() => {
    const fetch = async () => {
      if (hasFetched.current) {
        return;
      }

      if (isLoading) {
        return;
      }

      if (!validate || !autocompleteUrl) {
        setDoesExist(false);

        return;
      }
      setIsLoading(true);

      const url = Object.entries(replaceValues ?? []).reduce(
        (url, [key, value]) => url.replace(key, value),
        autocompleteUrl
      );

      try {
        const {
          data: { filtered_count },
        } = await apiCall.get<T>(
          generatePath(url, {
            limit: -1,
          })
        );
        setDoesExist(!!filtered_count);
      } catch {
        setDoesExist(false);
      } finally {
        hasFetched.current = true;
        setIsLoading(false);
      }
    };

    fetch();
  }, [autocompleteUrl, validate, replaceValues, isLoading]);

  return { doesExist, isLoading };
};

export default useVerifyIfObjectExists;
