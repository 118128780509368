import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import { useConfirmationModalContext } from 'contexts/ConfirmationModalContext';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import { FormMode } from 'utils/Enums/FormMode';
import ClassFieldFormWrapper from '../NewAddField';

const ObjectClassModifyField = () => {
  const dispatch = useDispatch();
  const {
    globalMethods: { closeComponent },
    isComponentOpen,
  } = useFlexLayoutContext();
  const { windowToOpen } = useConfirmationModalContext();

  useEffect(() => {
    if (
      windowToOpen === FlexLayoutWindows.ObjectClassEditField &&
      isComponentOpen(FlexLayoutWindows.ObjectClassAddField)
    ) {
      closeComponent(FlexLayoutWindows.ObjectClassAddField);
      dispatch(setSidebarData(FlexLayoutWindows.ObjectClassAddField, {}));
    }
  }, [closeComponent, dispatch, isComponentOpen, windowToOpen]);

  useEffect(() => {
    return () => {
      window.history.replaceState({}, '');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ClassFieldFormWrapper mode={FormMode.Edit} />;
};

export default ObjectClassModifyField;
