import { createContext, useContext } from 'react';
import noop from 'lodash/noop';
import { ErrorStatusContextType } from './types';

const ErrorStatusContext = createContext<ErrorStatusContextType>({
  errorStatus: undefined,
  setErrorStatus: noop,
});

export const ErrorStatusContextProvider = ErrorStatusContext.Provider;
export const useErrorStatusContext = () => useContext(ErrorStatusContext);
