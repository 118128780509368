import React from 'react';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import ChildClassTable from './components/ChildClassTable';
import { ChildClassTableProps } from './components/ChildClassTable/types';

const ChildClassTableWrapper = ({
  classId,
  readOnly,
  identifier,
  recordId,
  label,
  config,
  hasCreateEditViewEnabled,
  multiplicity,
}: ChildClassTableProps) => {
  return (
    <Provider store={store}>
      <ChildClassTable
        hasCreateEditViewEnabled={hasCreateEditViewEnabled}
        multiplicity={multiplicity}
        classId={classId}
        readOnly={readOnly}
        label={label}
        recordId={recordId}
        config={config}
        identifier={identifier}
      />
    </Provider>
  );
};

export default ChildClassTableWrapper;
