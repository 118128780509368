import { useCallback } from 'react';
import { apiCall } from 'utils/api';
import { FileType, UseClickLinkProps } from './types';

const useClickLink = () => {
  const onClick = useCallback(
    async ({ path, downloadedFileName, label, type }: UseClickLinkProps) => {
      const { data } = await apiCall.get(path, { responseType: 'blob' });
   
      const link = document.createElement('a');
      link.href = URL.createObjectURL(data);
      link.setAttribute('target', '_blank');
      if (type === FileType.Document) {
        link.download = label ?? 'file.docx';
      } else {
        if (downloadedFileName) {
          link.download = downloadedFileName;
        } else {
          link.download = label ? `${label}.docx` : 'file.docx';
        }
      }

      link.click();
      URL.revokeObjectURL(link.href);
    },
    []
  );

  return {
    onClick,
  };
};

export default useClickLink;
