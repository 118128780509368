export interface SingleProvider {
  id: string;
  name: string;
}

export enum LoginErrorType {
  Credentials = 'credentials',
  SSO = 'sso',
}

export interface LoginPageLocationState {
  error?: LoginErrorType;
}
