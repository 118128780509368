import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import useDateFormat from 'hooks/useDateFormat';
import { TaskStatus } from 'utils/Enums/TaskStatus';
import { TASK_1TC_HEADER } from 'utils/testIds';
import useCompleteTaskBy1TCStyles from './styles';
import { TaskHeaderProps } from './types';

const TaskHeader = ({ id, name, due_date, status }: TaskHeaderProps) => {
  const { dateFormat } = useDateFormat();
  const classes = useCompleteTaskBy1TCStyles();

  return (
    <header
      className={classes.taskTitleContainer}
      data-testid={TASK_1TC_HEADER}
    >
      <h1 className={classes.taskTitle}>{`${name} (${id})`}</h1>
      <h2 className={classes.taskDue}>
        <span className={classes.taskDueLabel}>
          <FormattedMessage id='task.dueDate' defaultMessage='Due date: ' />
        </span>
        {due_date ? moment(due_date).format(dateFormat) : '-'}
      </h2>
      {status === TaskStatus.Overdue && (
        <span className={classes.taskOverdue}>
          <FormattedMessage id='tasksPanel.overdue' defaultMessage='Overdue' />
        </span>
      )}
    </header>
  );
};

export default TaskHeader;
