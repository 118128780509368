enum ColumnDataTypes {
  String = 'string',
  Int = 'int',
  Enum = 'enum',
  DateTime = 'datetime',
  Boolean = 'bool',
  Float = 'float',
  User = 'user',
  URL = 'url',
  JSON = 'json',
}

export default ColumnDataTypes;
