import React from 'react';
import { FormBuilderConfirmationModalsProps } from './types';
import CustomPrompt from '../CustomPrompt';
import { useSaveChangesModal } from '../FormHeader/hooks';
import { RevertModal } from '../RevertModal';
import { CustomPromptType } from '../CustomPrompt/types';
import { Location } from 'history';

const FormBuilderConfirmationModals: React.FC<FormBuilderConfirmationModalsProps> = ({
  confirmRevertData,
  toggleRevertModal,
  revertModalVisible,
  confirmSaveModalActive,
  confirmSaveModal,
}) => {
  const { navigateTo, handleLocationChange } = useSaveChangesModal();

  const confirmResetAndClose = () => {
    confirmRevertData();
    toggleRevertModal();
  };

  const onCancelSaveModal = (navigateTo?: Location) => {
    if (navigateTo) handleLocationChange(navigateTo.pathname);
  };

  return (
    <>
      <RevertModal
        {...{
          visible: revertModalVisible,
          onConfirm: confirmResetAndClose,
          onClose: toggleRevertModal,
        }}
      />
      <CustomPrompt
        when={!navigateTo && confirmSaveModalActive && !revertModalVisible}
        onCancel={onCancelSaveModal}
        onConfirm={confirmSaveModal}
        type={CustomPromptType.CANCEL}
      />
    </>
  );
};

export default FormBuilderConfirmationModals;
