import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useCustomCheckboxWidgetStyles = createUseStyles((theme: ThemeState) => {
  return {
    checkboxWrapper: {
      '& .ant-checkbox-inner': {
        height: 20,
        width: 20,
        borderColor: theme['checkbox-border-color'],
        borderWidth: 1.5,
        '&.ant-checkbox-checked': {
          backgroundColor: theme['primary-color'],
          borderColor: theme['primary-color'],
        },
      },
    },
    checkboxLabel: {
      paddingLeft: 8,
    },
  };
});

export default useCustomCheckboxWidgetStyles;
