import React from 'react';
import { useViewerPanelStyles } from './styles';
import { useSelector } from 'react-redux';
import { getViewerPanelData } from 'store/selectors/flexLayoutSelectors';
import FileClickLink from 'components/FileLink';
import { FileType } from 'components/FileLink/types';
import { useViewerPanelClosingBehaviour } from './hooks/useViewerPanelClosingBehaviour';
import {
  VIEWER_PANEL_FIELD_LABEL_TESTID,
  VIEWER_PANEL_FILE_DOWNLOAD_TESTID,
  VIEWER_PANEL_RECORD_IDENTIFIER_TESTID,
} from 'utils/testIds';
import TooltipString from 'components/TooltipString';
import { FileViewer } from './components/FileViewer';

export const ViewerPanel = () => {
  const styles = useViewerPanelStyles();

  const panelData = useSelector(getViewerPanelData);

  const { fileName, fileUrl, fieldLabel, recordIdentifier } = panelData ?? {};

  useViewerPanelClosingBehaviour();

  /**
   * Don't return panel view when no `panelData` is present (as it's going to be closed anyway by
   * useViewerPanelClosingBehaviour hook) to avoid unnecessary errors and to don't waste resources
   * on rendering components.
   */
  if (!panelData) {
    return <></>;
  }

  return (
    <div className={styles.panelWrapper}>
      <header>
        <h3
          className={styles.recordIdentifier}
          data-testid={VIEWER_PANEL_RECORD_IDENTIFIER_TESTID}
        >
          {recordIdentifier}
        </h3>

        <TooltipString
          className={styles.fieldLabel}
          text={fieldLabel ?? ''}
          testId={VIEWER_PANEL_FIELD_LABEL_TESTID}
        />

        <FileClickLink
          label={fileName}
          type={FileType.Document}
          url={fileUrl ?? ''}
          testId={VIEWER_PANEL_FILE_DOWNLOAD_TESTID}
        />
      </header>
      <FileViewer
        fileUrl={fileUrl}
        fileName={fileName}
        className={styles.pdfViewContainer}
      />
    </div>
  );
};
