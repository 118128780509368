import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { getTablesPreferences } from 'store/selectors/preferencesSelectors';
import { updatePreferences } from 'store/actions/preferencesActions';
import { TableState, IdType } from 'react-table';
import { createTablePreferences } from './utils';
import { PreferencesTypes } from 'utils/types/api/preferences.types';

const usePreferences = (preferencesId?: string | number) => {
  const dispatch = useDispatch();
  const tables = useSelector(getTablesPreferences);

  const updateTableColumnSizePreferences = useCallback(
    (table: string, columnKey: string, width: number) => {
      const currentTable = (tables as MappedObject<Partial<TableState>>)[table];
      const currentTableState =
        (preferencesId
          ? (currentTable as MappedObject<Partial<TableState>>)?.[preferencesId]
          : currentTable) ?? {};

      const newPreferences = createTablePreferences(
        currentTable,
        {
          columnResizing: {
            ...(currentTableState.columnResizing ?? {}),
            columnWidths: {
              ...(currentTableState.columnResizing?.columnWidths ?? {}),
              [columnKey]: width,
            },
          },
        },
        preferencesId
      );

      dispatch(
        updatePreferences(PreferencesTypes.TablePreferences, {
          tables: {
            ...tables,
            [table]: newPreferences,
          },
        })
      );
    },
    [dispatch, preferencesId, tables]
  );

  const updateTableColumnOrderPreferences = useCallback(
    async (table: string, columnOrder: IdType<string>[]) => {
      const currentTable = (tables as MappedObject<Partial<TableState>>)[table];

      if (columnOrder) {
        const newPreferences = createTablePreferences(
          currentTable,
          { columnOrder },
          preferencesId
        );

        return dispatch(
          updatePreferences(PreferencesTypes.TablePreferences, {
            tables: {
              ...tables,
              [table]: newPreferences,
            },
          })
        );
      }

      return false;
    },
    [dispatch, preferencesId, tables]
  );

  const updateTablePreferences = useCallback(
    async (table: string, preferences: Partial<TableState>) => {
      const currentTable = (tables as MappedObject<Partial<TableState>>)[table];

      if (preferences) {
        const newPreferences = createTablePreferences(
          currentTable,
          preferences,
          preferencesId
        );

        return dispatch(
          updatePreferences(PreferencesTypes.TablePreferences, {
            tables: {
              ...tables,
              [table]: newPreferences,
            },
          })
        );
      }

      return false;
    },
    [dispatch, preferencesId, tables]
  );

  return {
    updateTableColumnSizePreferences,
    updateTableColumnOrderPreferences,
    updateTablePreferences,
  };
};

export default usePreferences;
