import { Filter } from 'store/actions/filtersActions';
import { RootState } from 'store/reducers';
import SupportedLanguages from 'utils/Enums/SupportedLanguages';
import {
  PreferencesDataState,
  PreferencesTypes,
} from 'utils/types/api/preferences.types';

export const preferencesSelector = <T extends keyof PreferencesDataState>(
  state: RootState,
  key: T
) => state.preferences.data[key];

export const getAllPreferences = (state: RootState) => state.preferences.data;

export const getGeneralPreferences = (state: RootState) =>
  state.preferences.data[PreferencesTypes.GeneralPreferences];

export const languageSelector = (state: RootState): SupportedLanguages =>
  state.preferences.data[PreferencesTypes.GeneralPreferences].language;

export const isSavingPreferences = (state: RootState) =>
  !!state.preferences.saving;

export const isSidebarCollapsedSelector = (state: RootState) =>
  state.preferences.data[PreferencesTypes.GeneralPreferences]
    .isSidebarCollapsed;

export const preferenceFiltersSelector = (state: RootState) =>
  state.preferences.data[PreferencesTypes.FilterPreferences].filters;

export const getCurrentTableColumnPreferences = (state: RootState) => {
  const { currentTable } = state.filters;

  if (currentTable) {
    return state.preferences.data[PreferencesTypes.TablePreferences].tables[
      currentTable
    ];
  }

  return {};
};

export const flexLayoutJSONModelSelector = (state: RootState) =>
  state.preferences.data[PreferencesTypes.LayoutPreferences]
    .flexLayoutJSONModel;

export const getCurrentTablePreferenceFilter = (state: RootState) => {
  const { currentTable, sourceId } = state.filters;
  const { selectedClassId } = state.preferences.data[
    PreferencesTypes.GeneralPreferences
  ];
  const id = sourceId || selectedClassId;

  if (currentTable) {
    const filters =
      state.preferences.data[PreferencesTypes.FilterPreferences].filters[
        currentTable
      ] ?? [];

    if (id && Array.isArray((filters as MappedObject<Filter, string>)?.[id]))
      return (filters as MappedObject<Filter, string>)[id];

    if (Array.isArray(filters)) return filters;
  }

  return [];
};

export const getTableDensity = (state: RootState) =>
  state.preferences.data[PreferencesTypes.TableLayoutPreferences].tableDensity;

export const getUserTimezone = (state: RootState) =>
  state.preferences.data[PreferencesTypes.GeneralPreferences].timezone;

export const getUserDateFormat = (state: RootState) =>
  state.preferences.data[PreferencesTypes.GeneralPreferences].dateFormat;

export const getDecimalFormat = (state: RootState) =>
  state.preferences.data[PreferencesTypes.GeneralPreferences].decimalFormat;

export const getThousandSeparator = (state: RootState) =>
  state.preferences.data[PreferencesTypes.GeneralPreferences].thousandSeparator;

export const getFormBuilderToolboxWidth = (state: RootState) =>
  state.preferences.data[PreferencesTypes.GeneralPreferences]
    .formBuilderToolboxWidth;

export const getNavigationMenuPinned = (state: RootState) =>
  state.preferences.data[PreferencesTypes.GeneralPreferences]
    .isNavigationMenuPinned;

export const getSelectedObjectClassId = (state: RootState) =>
  state?.preferences?.data?.[PreferencesTypes.GeneralPreferences]
    .selectedClassId;

export const getLayoutPreferences = (state: RootState) =>
  state.preferences.data[PreferencesTypes.LayoutPreferences];

export const getOpenedPanels = (state: RootState) =>
  state.preferences.data?.[PreferencesTypes.LayoutPreferences].panels
    ?.openedPanels;

export const getActivePanels = (state: RootState) =>
  state.preferences?.data[PreferencesTypes.LayoutPreferences].panels
    ?.activePanels;

export const getTablesViewMode = (state: RootState) =>
  state.preferences.data[PreferencesTypes.TableLayoutPreferences]
    .tablesViewMode;

export const getTablesState = (state: RootState) =>
  state.preferences.data[PreferencesTypes.TableLayoutPreferences].tablesState;

export const getTablesPreferences = (state: RootState) =>
  state.preferences.data[PreferencesTypes.TablePreferences].tables;

export const getTaskPropertiesOpen = (state: RootState) =>
  !!state.preferences.data[PreferencesTypes.LayoutPreferences].tasks
    ?.taskPropertiesOpen;

export const getSelectedColumns = (state: RootState) =>
  state.preferences.data[PreferencesTypes.TableLayoutPreferences]
    .selectedColumns;

export const getSelectedColumnsByClassId = (classID?: string) => (
  state: RootState
) =>
  state.preferences.data[PreferencesTypes.TableLayoutPreferences]
    .selectedColumns?.[
    classID ??
      state.preferences.data[PreferencesTypes.GeneralPreferences]
        .selectedClassId ??
      '0'
  ];

export const getTasksQuickFilters = (state: RootState) =>
  state.preferences.data[PreferencesTypes.FilterPreferences].quickFilters.tasks;
