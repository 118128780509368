import { COLUMNS_LIMIT } from '../../consts';
import ClassFieldCheckbox from '../ClassFieldCheckbox';
import React from 'react';
import { ClassFieldCheckboxListProps } from './types';

const ClassFieldCheckboxList = ({
  classFields,
  colSets,
  onChange,
}: ClassFieldCheckboxListProps) => {
  return (
    <>
      {classFields?.map(field => {
        const alias = field.alias;
        const itemDisabled =
          field.alias === 'id' ||
          (colSets.currentVal.size >= COLUMNS_LIMIT &&
            !colSets.currentVal.has(field.alias));
        return (
          <ClassFieldCheckbox
            key={alias}
            alias={alias}
            onChange={onChange}
            itemDisabled={itemDisabled}
            label={field.label}
          />
        );
      })}
    </>
  );
};

export default ClassFieldCheckboxList;
