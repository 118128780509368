import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useCustomSelectWidgetStyles = createUseStyles((theme: ThemeState) => {
  return {
    selectStyles: {
      width: 350,
      position: 'relative',
      borderColor: theme['border-color-base'],
      '& .ant-select-selection-search': {
        paddingLeft: 35,
      },
      '& .ant-select-selector': {
        borderColor: theme['border-color-base'],
      },
    },
    selectPreview: {
      width: '100%',
      borderColor: theme['border-color-base'],
      '& .ant-select-selector': {
        borderColor: theme['border-color-base'],
      },
    },
    selectOption: {
      display: 'flex',
      alignItems: 'center',
    },
    countryFlag: {
      height: 15,
      width: 21,
      marginRight: 15,
      objectFit: 'contain',
      alignSelf: 'center',
    },
    countryFlagPlaceholder: {
      position: 'absolute',
      top: '10px',
      left: '16px',
      width: '20px',
      height: '15px',
    },
    limitedMaxSizeMid: {
      maxWidth: 350,
      width: 'auto',
    },
    customArrowIcon: {
      marginTop: -5,
      marginLeft: -2,
    },
  };
});

export default useCustomSelectWidgetStyles;
