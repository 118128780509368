import {
  SET_TOKENS,
  CLEAR_TOKENS,
  SET_USER_DETAILS,
} from 'store/constants/auth.consts';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState, RootAction } from 'store/reducers';
import { apiCall } from 'utils/api';
import { LOGIN, LOGOUT, USER_ME } from 'utils/endpoints';
import { toast } from 'components/lib/toast';
import ToastType from 'utils/Enums/ToastType';
import { TOAST_AUTO_CLOSE_TIME, REQUEST_SOURCE_NAME } from 'utils/consts';
import globalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { resetStore } from './storeActions';
import { StatusCodes } from 'http-status-codes';
import { AuthState } from 'utils/types/api/auth.types';
import { getAccessToken } from 'store/selectors/authSelectors';
import { UserMe } from 'utils/types/api/users.types';
import { getCustomTheme } from './customThemeActions';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';
import set from 'lodash/set';
import { getTableFilters } from 'store/selectors/filtersSelectors';
import { Filter, setAllFilters } from './filtersActions';
import { updatePreferences } from './preferencesActions';
import { PreferencesTypes } from 'utils/types/api/preferences.types';

export interface SetTokensAction extends Action<typeof SET_TOKENS> {
  payload: AuthState;
}

export type ClearTokens = Action<typeof CLEAR_TOKENS>;

export interface SetUserDetailsAction extends Action<typeof SET_USER_DETAILS> {
  payload: UserMe;
}

export type AuthAction = SetTokensAction | ClearTokens | SetUserDetailsAction;

export const setTokens = (payload: AuthState): SetTokensAction => {
  return {
    type: SET_TOKENS,
    payload,
  };
};

export const clearTokens = (): ClearTokens => {
  return {
    type: CLEAR_TOKENS,
  };
};

export const setUserDetails = (payload: UserMe): SetUserDetailsAction => {
  return {
    type: SET_USER_DETAILS,
    payload,
  };
};

// update all filters which includes updated user
export const updateUserFilters = (
  user: UserMe
): ThunkAction<Promise<void>, RootState, undefined, RootAction> => async (
  dispatch,
  getState
) => {
  const { id, first_name, last_name, username } = user;
  const filters = getTableFilters(getState());

  const mapUser = (filterUser: UserMe) => {
    const filterId = get(filterUser, 'id');
    if (filterId === id) {
      set(filterUser, 'first_name', first_name);
      set(filterUser, 'last_name', last_name);
      set(filterUser, 'username', username);
    }
  };

  const mapFilters = (filters: Filter) => {
    return filters.map(filter => {
      if (filter.type === 'user') {
        const value = get(filter, 'value.predicateValues.value');

        if (Array.isArray(value)) {
          value.forEach(mapUser);
        } else {
          mapUser(value);
        }
      }

      return filter;
    });
  };

  const table = mapValues(filters.tables, filterSet => {
    if (Array.isArray(filterSet)) {
      return mapFilters(filterSet);
    } else
      return mapValues(filterSet, nestedFilterSet => {
        return mapFilters(nestedFilterSet);
      });
  });

  dispatch(setAllFilters(table));
  dispatch(
    updatePreferences(PreferencesTypes.FilterPreferences, {
      filters: table,
    })
  );
};

export const loginUser = (
  username: string,
  password: string
): ThunkAction<
  Promise<boolean>,
  RootState,
  undefined,
  AuthAction
> => async dispatch => {
  try {
    const { data, status } = await apiCall.post(
      LOGIN,
      {
        username,
        password,
        source: REQUEST_SOURCE_NAME,
      },
      {
        //@ts-ignore
        skipAuthRefresh: true,
      }
    );
    if (status === StatusCodes.OK) {
      dispatch(setTokens(data));
      localStorage.setItem('authenticated', 'true');
      return Promise.resolve(true);
    } else if (status === StatusCodes.UNAUTHORIZED) {
      toast(
        {
          title: globalIntlSingleton.intl.formatMessage({
            id: 'user.enterCorrectCredentials',
            defaultMessage:
              'Please enter a correct email address and password. Note that both fields may be case-sensitive.',
          }),
        },
        ToastType.Error,
        { autoClose: TOAST_AUTO_CLOSE_TIME }
      );
    }
    return Promise.resolve(false);
  } catch ({ response: { data } }) {
    return Promise.reject(data);
  }
};

export const logoutUser = (): ThunkAction<
  void,
  RootState,
  undefined,
  RootAction
> => async dispatch => {
  try {
    await apiCall
      .post(LOGOUT, {
        source: REQUEST_SOURCE_NAME,
      })
      .then(() => {
        localStorage.setItem('authenticated', 'false');
      });
  } catch {
  } finally {
    dispatch(resetStore());
    dispatch(getCustomTheme());
  }
};

export const fetchUserDetails = (): ThunkAction<
  Promise<boolean>,
  RootState,
  undefined,
  AuthAction
> => async (dispatch, getState) => {
  try {
    const accessToken = getAccessToken(getState());

    if (!accessToken) return false;

    const { data, status } = await apiCall.get<UserMe>(USER_ME);

    if (status === StatusCodes.OK) {
      dispatch(setUserDetails(data));

      return Promise.resolve(true);
    } else if (status === StatusCodes.UNAUTHORIZED) {
      return Promise.resolve(false);
    }

    return Promise.resolve(false);
  } catch ({ response: { data } }) {
    return Promise.reject(data);
  }
};
