import { GtagEvent } from './types';

const GTAG_ID = 'G-4HT086SX4H';

class GTag {
  private static instance: GTag;
  private _GTag: any;
  private userData: Object;

  private constructor(userData?: Object) {
    //@ts-ignore
    this._GTag = window?.gtag || undefined;
    this.userData = userData || {};

    if (!this._GTag) return;

    this._GTag('js', new Date());
    this._GTag('config', GTAG_ID);
  }

  static init(userData?: Object): any {
    GTag.instance = new GTag(userData);
    return GTag.instance._GTag;
  }

  private static get gTag(): any {
    return GTag?.instance?._GTag || this.init();
  }

  private static get userData(): Object {
    return GTag?.instance?.userData || {};
  }

  static pushGtagEvent = (eventName: GtagEvent, eventData: Object = {}) => {
    const gTag = GTag.gTag;
    const userData = GTag.userData;

    if (!gTag) return;

    gTag('event', eventName, {
      ...userData,
      ...eventData,
    });
  };

  static pushGtagButtonEvent = (buttonName: string) =>
    GTag.pushGtagEvent(GtagEvent.ButtonClick, { buttonName });
}

export default GTag;
