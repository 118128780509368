import clsx from 'clsx';
import ErrorLabel from 'components/ErrorLabel';
import React from 'react';
import useFormLabelStyles from './styles';
import { FormLabelProps, FormLabelWithToolTipProps } from './types';
import TooltipString from 'components/TooltipString';
import { createTestId } from 'utils/functions/createTestId';
import { FORM_LABEL_TESTID } from 'utils/testIds';

const FormLabel: React.FC<FormLabelProps> = ({
  children,
  required,
  asteriskSize,
  inline,
  withPaddingBottom,
  className,
  dynamicSpacing,
  bolded = true,
  dataTestId,
}) => {
  const classes = useFormLabelStyles({
    asteriskSize,
    inline,
    withPaddingBottom,
    spacingWithin: dynamicSpacing ? 'var(--spacingWithin)' : 6,
    bolded,
  });

  return (
    <span className={clsx([classes.label, className])} data-testid={dataTestId}>
      {children}{' '}
      {required && (
        <ErrorLabel asteriskIsSpan asteriskClassName={classes.required} />
      )}
    </span>
  );
};

export const FormLabelWithToolTip: React.FC<FormLabelWithToolTipProps> = ({
  required,
  asteriskSize,
  inline,
  withPaddingBottom,
  className,
  dynamicSpacing,
  bolded = true,
  label,
}) => {
  const classes = useFormLabelStyles({
    asteriskSize,
    inline,
    withPaddingBottom,
    spacingWithin: dynamicSpacing ? 'var(--spacingWithin)' : 6,
    bolded,
  });

  return (
    <span
      className={clsx([classes.label, classes.flex, className])}
      data-testid={createTestId(FORM_LABEL_TESTID, label)}
    >
      <TooltipString text={label ?? ''}>{label}</TooltipString>{' '}
      {required && (
        <ErrorLabel asteriskIsSpan asteriskClassName={classes.required} />
      )}
    </span>
  );
};

export default FormLabel;
