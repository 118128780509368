import { RootState } from 'store/reducers';
import { TaskTemplate } from 'utils/types/api/tasks.types';

export const selectTaskTemplates = (state: RootState) =>
  state.taskTemplates.allIds.map(id => {
    const task = state.taskTemplates.byId[id];

    return {
      ...task,
      key: task.id,
    };
  });

export const totalTaskTemplates = (state: RootState) => {
  return state.taskTemplates.total;
};

export const filteredTaskTemplatesCount = (state: RootState) => {
  return state.taskTemplates.filtered;
};
export const getTaskTemplatesColumns = (state: RootState) => {
  return state.taskTemplates.columns;
};

export const getTaskTemplatesSelectedRow = (state: RootState) => {
  return state.taskTemplates?.selectedRow;
};

export const getTaskTemplatesRow = (id: string | undefined) => (
  state: RootState
): TaskTemplate => {
  if (!id) return {} as TaskTemplate;

  return state.taskTemplates.byId[id];
};
