import { createUseStyles } from 'react-jss';
import { InPlaceEditInputSize } from '../types';
import { ThemeState } from 'store/reducers/types/theme.types';

export const getInPlaceEditFontSize = (size?: InPlaceEditInputSize) => {
  switch (size) {
    case 'small':
      return 12;
    case 'medium':
      return 14;
    default:
      return 12;
  }
};

export const getInPlaceEditIconSize = (size?: InPlaceEditInputSize) => {
  switch (size) {
    case 'small':
      return 16;
    case 'medium':
      return 20;
    default:
      return 16;
  }
};

type UseCommonInPlaceEditStylesProps = {
  isSaving?: boolean;
  errorMessage?: string;
  size?: InPlaceEditInputSize;
};

const useCommonInPlaceEditStyles = createUseStyles((theme: ThemeState) => {
  return {
    basicInPlaceInputWrapper: ({
      isSaving,
      errorMessage,
      size,
    }: UseCommonInPlaceEditStylesProps = {}) => ({
      fontSize: getInPlaceEditFontSize(size),
      position: 'relative',
      height: 21,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      borderRadius: 3,
      padding: '0px 2px',
      border: `1px solid ${
        !!errorMessage ? theme['error-color'] : theme['primary-color']
      }`,
      boxShadow: !!errorMessage
        ? theme['input-focus-shadow-error']
        : theme['input-focus-shadow'],
      '& > input:hover, & > input:focus, & > input:active': {
        border: 'none',
        boxShadow: 'none',
      },
      '& > svg': {
        color: theme['primary-color'],
      },
      opacity: isSaving ? 0.65 : 1,
      flex: 1,
    }),
    basicInPlaceInput: ({
      isSaving,
      size,
    }: UseCommonInPlaceEditStylesProps = {}) => ({
      height: '100%',
      fontSize: getInPlaceEditFontSize(size),
      background: '#FFFFFF',
      border: 'none',
      padding: '0px',
      pointerEvents: isSaving ? 'none' : 'all',
    }),
    dropdownAnchor: {
      width: '100%',
    },
  };
});

export default useCommonInPlaceEditStyles;
