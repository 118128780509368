import { useCallback, useRef, useEffect } from 'react';
import { TABLE_BODY_ID, TABLE_HEAD_ID } from 'utils/elementsIds';

export const useScrollbars = ({
  appliedFilters,
  customTableId = '',
}: {
  appliedFilters: boolean;
  customTableId?: string;
}) => {
  const headerScroll = useRef(null);

  const onBodyScroll = useCallback(
    e => {
      const tableHead = document.getElementById(TABLE_HEAD_ID + customTableId);

      if (tableHead && headerScroll.current !== e.target.scrollLeft) {
        tableHead.scrollLeft = e.target.scrollLeft;
      }
    },
    [headerScroll, customTableId]
  );

  const onHeaderScroll = useCallback(
    e => {
      const tableBody = document.getElementById(TABLE_BODY_ID + customTableId);

      if (tableBody && tableBody?.scrollLeft !== e.target.scrollLeft) {
        headerScroll.current = e.target.scrollLeft;
        tableBody.scrollLeft = e.target.scrollLeft;
      }
    },
    [customTableId]
  );

  useEffect(() => {
    const tableBody = document.getElementById(TABLE_BODY_ID + customTableId);
    const tableHead = document.getElementById(TABLE_HEAD_ID + customTableId);

    if (tableBody) tableBody.scrollLeft = 0;

    if (tableHead) tableHead.scrollLeft = 0;
  }, [appliedFilters, customTableId]);

  return {
    onBodyScroll,
    onHeaderScroll,
  };
};
