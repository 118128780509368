import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { useSelector } from 'react-redux';
import { customThemeSelector } from 'store/selectors/customThemeSelectors';
import defaultTheme from 'utils/defaultTheme';

export const mergeColours = (
  defaultColours: MappedObject<string>,
  customColours: MappedObject<string>,
  enabled: boolean
) => {
  if (!enabled) {
    return defaultColours;
  }
  return {
    ...defaultColours,
    ...customColours,
  };
};

const ThemeWrapper = ({ children }: { children: React.ReactNode }) => {
  const customTheme = useSelector(customThemeSelector);
  const colours = (customTheme.colours as unknown) as MappedObject<string>;
  const [mergedTheme, setMergedTheme] = useState(
    mergeColours(defaultTheme, colours, customTheme.isEnabled)
  );

  useEffect(() => {
    setMergedTheme(mergeColours(defaultTheme, colours, customTheme.isEnabled));
  }, [customTheme, colours]);

  return <ThemeProvider theme={mergedTheme}>{children}</ThemeProvider>;
};

export default ThemeWrapper;
