import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useEventDataExpandStyles = createUseStyles((theme: ThemeState) => ({
  eventDataExpandWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    margin: [2, 0, 2, 45],
    maxHeight: (isExpanded: boolean) => (isExpanded ? 178 : 0),
    overflowY: 'auto',
    padding: (isExpanded: boolean) => (isExpanded ? [8, 0] : 0),
    transition: 'max-height 200ms ease',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  eventDataExpandItem: {
    fontSize: 12,
    fontWeight: 700,
  },
  eventDataGray: {
    fontSize: 12,
    fontWeight: 700,
    color: theme['disabled-switch-gray'],
  },
  eventDataDocumentDownload: {
    fontSize: 12,
    fontWeight: 700,
    color: theme['primary-color'],
    cursor: 'pointer',
    width: 'fit-content',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export const useEventDataJsonStyles = createUseStyles((theme: ThemeState) => ({
  pre: {
    fontFamily: theme['font-family-consolas'],
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '14px',
    counterReset: 'line-numbering',
    color: theme['text-color'],
  },
  line: {
    lineHeight: '14px',
    display: 'block',
    '&::before': {
      content: 'counter(line-numbering)',
      counterIncrement: 'line-numbering',
      paddingRight: '1.5em',
      textAlign: 'right',
      lineHeight: '14px',
      display: 'inline-block',
      color: theme['layout-trigger-color'],
    },
  },
}));

export default useEventDataExpandStyles;
