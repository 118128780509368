import React from 'react';
import UserAvatar from 'components/UserAvatar';
import usePeopleListElementStyles from './styles';
import {
  USERS_LIST_ITEM_DELETE_BUTTON_TEST_ID,
  USERS_LIST_ITEM_NAME_TEST_ID,
  USERS_LIST_ITEM_TEST_ID,
} from 'utils/testIds';
import { CloseIcon } from 'components/Icon';
import { PeopleListElementProps } from './types';
import CustomTag from 'components/CustomTag';
import { FormattedMessage } from 'react-intl';
import PermissionSetList from './PermissionSetList';

const PeopleListElement = ({
  firstName,
  lastName,
  id,
  userId,
  actionElement,
  onDelete,
  company = '',
  email = '',
  disablePopover = false,
  accountType,
  permissionSetIds,
  isOwner,
  permissionSetNames,
  getUsers,
}: PeopleListElementProps) => {
  const classes = usePeopleListElementStyles({});

  return (
    <div className={classes.wrapper} data-testid={USERS_LIST_ITEM_TEST_ID}>
      <div className={classes.box}>
        <UserAvatar
          {...{ firstName, lastName, disablePopover, accountType }}
          id={userId || id}
          size='large'
        />
        <div
          className={classes.userData}
          data-testid={USERS_LIST_ITEM_NAME_TEST_ID}
        >
          <p className={classes.name}>{`${firstName} ${lastName}${
            company && ` (${company})`
          }`}</p>
          {permissionSetNames ? (
            <PermissionSetList
              {...{
                permissionSetNames,
                permissionSetIds,
                getUsers,
                firstName,
                lastName,
              }}
              userId={userId || id}
            />
          ) : (
            email && <div className={classes.bottomText}>{email}</div>
          )}
        </div>
      </div>
      {!!actionElement && actionElement}
      {!!onDelete &&
        (isOwner ? (
          <CustomTag
            className={classes.tag}
            closable
            onClose={onDelete}
            data-testid={USERS_LIST_ITEM_DELETE_BUTTON_TEST_ID}
          >
            <FormattedMessage id='formBuilder.owner' defaultMessage='Owner' />
          </CustomTag>
        ) : (
          !permissionSetNames && (
            <span data-testid={USERS_LIST_ITEM_DELETE_BUTTON_TEST_ID}>
              <CloseIcon onClick={onDelete} size={16} />
            </span>
          )
        ))}
    </div>
  );
};

export default PeopleListElement;
