import React from 'react';
import { useEventDataJsonStyles } from './styles';
import { EventDataJsonProps } from './types';

const EventDataJson = ({ children }: EventDataJsonProps) => {
  const styles = useEventDataJsonStyles({});

  const validateJson = (value: string | null) => {
    if (value === '') return '""';
    if (!value) return String(value);
    try {
      return JSON.stringify(JSON.parse(value), null, 4);
    } catch (e) {
      return value;
    }
  };

  const jsonValue = validateJson(children);
  const lines = jsonValue.split(/[\r\n]+/g);

  return (
    <pre className={styles.pre}>
      {lines.map((value, index) => (
        <span className={styles.line} key={index}>
          {value}
        </span>
      ))}
    </pre>
  );
};

export default React.memo(EventDataJson);
