import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useSummaryPanelStyles = createUseStyles((theme: ThemeState) => {
  return {
    panelWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      '& #root .ant-collapse-content-box > div > .ant-row': {
        padding: 0,
      },
      '& #formBody': {
        maxHeight: '100%',
      },
      '& #form-preview-wrapper': {
        '& .ant-collapse-header': {
          backgroundColor: theme['background-color-light'],
          color: `${theme['secondary-color']} !important`,
        },
        '& .ant-collapse-content': {
          backgroundColor: '#fff',
        },
        '& .ant-picker-suffix': {
          color: `${theme['primary-color']} !important`,
        },
      },
    },
    container: {
      height: '100%',
      '& .ant-spin-nested-loading, .ant-spin-container': {
        height: '100%',
      },
    },
    ownersWrapper: {
      marginBottom: 22,
      '&>p': {
        marginBottom: 11,
        fontSize: 12,
      },
    },
  };
});

export default useSummaryPanelStyles;
