import React from 'react';
import { Row } from 'components/lib/Grid';
import useFormFieldStyles from './styles';
import { FormFieldProps } from './types';

const FormField: React.FC<FormFieldProps> = ({
  children,
  customBottomMargin,
  gutter = 12,
}) => {
  const classes = useFormFieldStyles({});

  return (
    <Row
      className={classes.formField}
      {...{ gutter }}
      style={{ marginBottom: customBottomMargin || 15 }}
    >
      {children}
    </Row>
  );
};

export default FormField;
