import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
export interface AdditionalTextProps {
  fontSize?: number;
  fontWeight?: number;
}

type UseTextStylesProps = {
  fontSize?: string | number;
  fontWeight?: string | number;
};

const useTextStyles = createUseStyles((theme: ThemeState) => {
  return {
    success: ({
      fontSize = 'initial',
      fontWeight = 'initial',
    }: UseTextStylesProps) => ({
      color: theme['success-color'],
      '& .ant-typography-expand, .ant-typography-edit, .ant-typography-copy': {
        color: theme['primary-color'],
      },
      fontSize,
      fontWeight,
    }),
    secondary: ({
      fontSize = 'initial',
      fontWeight = 'initial',
    }: UseTextStylesProps) => ({
      color: theme['text-color-secondary'],
      '& .ant-typography-expand, .ant-typography-edit, .ant-typography-copy': {
        color: theme['primary-color'],
      },
      fontSize,
      fontWeight,
    }),
    warning: ({
      fontSize = 'initial',
      fontWeight = 'initial',
    }: UseTextStylesProps) => ({
      color: theme['warning-color'],
      '& .ant-typography-expand, .ant-typography-edit, .ant-typography-copy': {
        color: theme['primary-color'],
      },
      fontSize,
      fontWeight,
    }),
    danger: ({
      fontSize = 'initial',
      fontWeight = 'initial',
    }: UseTextStylesProps) => ({
      color: theme['error-color'],
      '& .ant-typography-expand, .ant-typography-edit, .ant-typography-copy': {
        color: theme['primary-color'],
      },
      fontSize,
      fontWeight,
    }),
    default: ({
      fontSize = 'initial',
      fontWeight = 'initial',
    }: UseTextStylesProps) => ({
      '& .ant-typography-expand, .ant-typography-edit, .ant-typography-copy': {
        color: theme['primary-color'],
      },
      fontSize,
      fontWeight,
    }),
    highlighted: ({ fontWeight = 'initial' }: UseTextStylesProps) => ({
      fontWeight,
    }),
  };
});

export default useTextStyles;
