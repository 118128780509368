import { useSelector } from 'react-redux';
import {
  getCurrentTableFilteredCount,
  getCurrentTableTotalCount,
} from 'store/selectors/generalSelectors';
import { ClassID } from 'utils/types/api/table.types';

const useTableCount = (classID?: ClassID) => {
  const total = useSelector(getCurrentTableTotalCount(classID?.toString()));
  const filtered = useSelector(
    getCurrentTableFilteredCount(classID?.toString())
  );

  return { total, filtered };
};

export default useTableCount;
