import { useEffect } from 'react';
import {
  generatePath,
  useParams,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { apiCall } from 'utils/api';
import { StatusCodes } from 'http-status-codes';
import { setTokens } from 'store/actions/authActions';
import { SINGLE_SIGN_ON_CALLBACK_API } from 'utils/endpoints';
import routes, { nonProtectedRoutes } from 'utils/routingPaths';
import { LoginErrorType } from 'pages/Login/types';

const useSignSignOnCallback = () => {
  const { id } = useParams<{ id: string }>();
  const { hash } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const providerResponseData = queryString.parse(hash.replace('#', '?'));

        const { data, status } = await apiCall.post(
          generatePath(SINGLE_SIGN_ON_CALLBACK_API, {
            id,
          }),
          providerResponseData
        );

        if (status === StatusCodes.OK) {
          dispatch(setTokens(data));
          localStorage.setItem('authenticated', 'true');
          const redirectPath = localStorage.getItem('redirectPath');

          if (redirectPath) localStorage.removeItem('redirectPath');
          history.replace(redirectPath ?? routes.WORKSPACE);
        }
      } catch (error) {
        history.replace(nonProtectedRoutes.LOGIN, {
          error: LoginErrorType.SSO,
        });
      }
    })();
  }, [dispatch, hash, history, id]);
};

export default useSignSignOnCallback;
