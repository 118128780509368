import React, { useMemo } from 'react';
import { Menu } from 'antd';
import { useSideMenuContext } from './context/SideMenuContext';
import { CollapsedMenuItemProps } from './types';
import useLayoutStyles from 'components/lib/Layout/styles';

const CollapsedMenuItem = ({
  route,
  isSubmenu,
  Icon,
  ...rest
}: CollapsedMenuItemProps) => {
  const { activeMenuKey } = useSideMenuContext();
  const isActive = useMemo(() => activeMenuKey.includes(route), [
    activeMenuKey,
    route,
  ]);
  const classes = useLayoutStyles({ isActive });

  return isSubmenu ? (
    <Menu.SubMenu
      className={classes.collapsedMenuItem}
      key={route}
      icon={Icon ? <Icon size={25} /> : null}
      {...rest}
    />
  ) : (
    <Menu.Item
      className={classes.collapsedMenuItem}
      icon={Icon ? <Icon size={18} /> : null}
      key={route}
      {...rest}
    />
  );
};

export default CollapsedMenuItem;
