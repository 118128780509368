import { useParams } from 'react-router';
import useData from '../../hooks/useData';
import { generatePath, useHistory } from 'react-router-dom';
import { RESET_PASSWORD, RESET_PASSWORD_TOKEN } from '../../utils/endpoints';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { usePostWithToasts } from '../../hooks/usePostWithToasts';
import { AuthState } from '../../utils/types/api/auth.types';
import { FormMode } from '../../utils/Enums/FormMode';
import { useEffect, useMemo, useState } from 'react';
import useValidationSchemaBuilder from '../../hooks/useValidationSchemaBuilder';
import { OptionSelect } from '../../utils/types/selectInput.types';
import { setTokens } from '../../store/actions/authActions';
import routes, { nonProtectedRoutes } from '../../utils/routingPaths';
import { FormikHelpers } from 'formik';
import { ExpiredPasswordFields } from './enums';
import { ExpiredPasswordValidation } from './consts';
import { ExpiredPasswordFormValues, ExpiredPasswordParams } from './types';
import {
  ActivateAccountData,
  ActivateAccountParams,
} from '../ActivateAccount/types';
import { getAccessToken } from '../../store/selectors/authSelectors';
import { StatusCodes } from 'http-status-codes';

export const useExpiredPasswordData = () => {
  const { token } = useParams<ActivateAccountParams>();
  const history = useHistory();
  const accessToken = useSelector(getAccessToken);
  const [activateAccountData, { loading, error }] = useData<
    ActivateAccountData
  >(generatePath(RESET_PASSWORD_TOKEN, { token: token || !token }), {
    fetchOnLoad: !!token,
  });

  useEffect(() => {
    if (error?.status === StatusCodes.FORBIDDEN)
      history.replace(nonProtectedRoutes.LOGIN);
  }, [history, accessToken, error]);

  useEffect(() => {
    if (!!accessToken) history.replace(routes.HOMEPAGE);
  }, [history, accessToken]);

  return {
    activateAccountData,
    loadingActivateAccountData: loading,
    error,
  };
};

export const useExpiredPasswordForm = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useParams<ExpiredPasswordParams>();
  const { sendData } = usePostWithToasts<AuthState, AuthState>(FormMode.Create);
  const [isPasswordFulfilled, setIsPasswordFulfilled] = useState(false);
  const validationBuilder = useValidationSchemaBuilder<
    OptionSelect | undefined
  >(ExpiredPasswordValidation);

  const initialValues = useMemo(
    () => ({
      [ExpiredPasswordFields.Password]: '',
      [ExpiredPasswordFields.ConfirmPassword]: '',
    }),
    []
  );

  const callback = (data?: AuthState) => {
    if (data) dispatch(setTokens(data));

    history.push(routes.HOMEPAGE);
  };

  const onSubmit = async (
    values: ExpiredPasswordFormValues,
    {
      setErrors,
      setFieldError,
      setSubmitting,
    }: FormikHelpers<ExpiredPasswordFormValues>
  ) => {
    if (
      values[ExpiredPasswordFields.Password] !==
      values[ExpiredPasswordFields.ConfirmPassword]
    ) {
      return setFieldError(
        ExpiredPasswordFields.ConfirmPassword,
        intl.formatMessage({
          defaultMessage: 'Passwords do not match',
          id: 'errors.noMatchPassword',
        })
      );
    }

    await sendData({
      url: generatePath(RESET_PASSWORD, { token }),
      data: { password: values[ExpiredPasswordFields.Password] },
      fields: ExpiredPasswordFields,
      callback,
      setErrors,
      setSubmitting,
    });
  };

  return {
    initialValues,
    onSubmit,
    setIsPasswordFulfilled,
    isPasswordFulfilled,
    ...validationBuilder,
  };
};
