import { createUseStyles } from 'react-jss';

type UseInPlaceEditCheckboxStylesProps = {
  readOnly?: boolean;
};

const useInPlaceEditCheckboxStyles = createUseStyles({
  checkboxRow: {
    zIndex: 0,
  },
  checkbox: {
    display: 'flex',
    fontSize: 12,
    '&:hover .ant-checkbox::after': {
      border: 'none',
    },
    '& .ant-checkbox': {
      width: 16,
      height: 16,
    },
    '& .ant-checkbox-inner': {
      width: 16,
      height: 16,
      '&::after': {
        left: '22%',
      },
    },
    '& .ant-checkbox-input': {
      cursor: ({ readOnly }: UseInPlaceEditCheckboxStylesProps = {}) =>
        readOnly ? 'default' : 'pointer',
    },
  },
});

export default useInPlaceEditCheckboxStyles;
