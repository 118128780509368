import React, { forwardRef } from 'react';
import { Checkbox as AntCheckbox } from 'antd';
import useCheckboxStyles from './styles';
import {
  CheckboxProps,
  CheckboxGroupProps,
  CheckboxOptionType,
  CheckboxChangeEvent,
} from 'antd/lib/checkbox';
import clsx from 'clsx';
import {
  Checkbox as FCheckbox,
  CheckboxProps as FCheckboxProps,
  CheckboxGroupProps as FCheckboxGroupProps,
} from 'formik-antd';
import { typedMemo } from 'utils/types';
import { areEqual } from 'react-window';

interface CheckboxPropsExtended extends CheckboxProps {
  color?: string;
}

interface FormikCheckboxPropsExtended extends FCheckboxProps {
  color?: string;
}

export const Checkbox = typedMemo(
  forwardRef<AntCheckbox, CheckboxPropsExtended>(
    ({ color, className, ...rest }, ref) => {
      const classes = useCheckboxStyles({ color });
      return (
        <AntCheckbox
          {...rest}
          className={clsx([
            className,
            color ? classes.customCheckbox : classes.checkbox,
          ])}
          {...{ ref }}
        />
      );
    }
  ),
  areEqual
);

export const CheckboxWithAdditionalData = typedMemo(
  forwardRef<AntCheckbox, CheckboxPropsExtended & { additionalData: unknown }>(
    ({ color, className, ...rest }, ref) => {
      const classes = useCheckboxStyles({ color });
      return (
        <AntCheckbox
          {...rest}
          onChange={e => {
            if (rest.onChange)
              rest.onChange(({
                value: rest.additionalData,
                checked: e.target.checked,
              } as unknown) as CheckboxChangeEvent);
          }}
          className={clsx([
            className,
            color ? classes.customCheckbox : classes.checkbox,
          ])}
          {...{ ref }}
        />
      );
    }
  ),
  areEqual
);

export const FormikCheckbox = forwardRef<
  AntCheckbox,
  FormikCheckboxPropsExtended
>(({ color, className, ...rest }, ref) => {
  const classes = useCheckboxStyles({ color });

  return (
    <FCheckbox
      {...rest}
      className={clsx([
        className,
        color ? classes.customCheckbox : classes.checkbox,
      ])}
    />
  );
});

interface CheckboxGroupPropsExtended extends CheckboxGroupProps {
  vertical?: boolean;
}

interface FormikCheckboxGroupPropsExtended extends FCheckboxGroupProps {
  vertical?: boolean;
}

export const CheckboxGroupCustom: React.FC<CheckboxGroupPropsExtended> = ({
  vertical,
  className,
  ...rest
}) => (
  <AntCheckbox.Group
    {...rest}
    className={clsx([
      className,
      {
        'checkbox-group-vertical': vertical,
      },
    ])}
  />
);

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  options,
  ...rest
}) => (
  <AntCheckbox.Group {...rest}>
    {(options as CheckboxOptionType[])?.map(option => (
      <Checkbox key={option.value.toString()} value={option.value}>
        {option.label}
      </Checkbox>
    ))}
  </AntCheckbox.Group>
);

export const FormikCheckboxGroup: React.FC<FCheckboxGroupProps> = ({
  ...rest
}) => <FCheckbox.Group {...rest} />;

export const FormikCheckboxGroupCustom: React.FC<FormikCheckboxGroupPropsExtended> = ({
  vertical,
  className,
  ...rest
}) => (
  <FCheckbox.Group
    {...rest}
    className={clsx([
      className,
      {
        'checkbox-group-vertical': vertical,
      },
    ])}
  />
);
