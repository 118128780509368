import clsx from 'clsx';
import useShouldRenderOnTop from 'hooks/useShouldRenderOnTop';
import React from 'react';
import useMessageBoxStyles from './styles';
import { MessageBoxProps } from './types';

const MessageBox = ({
  message,
  isError,
  displayAfterParent,
  fitContent,
  className,
  classNameOnTop,
  getContainerRef,
}: MessageBoxProps) => {
  const { elementRef, shouldBeOnTop, customHeight } = useShouldRenderOnTop<
    HTMLDivElement
  >(getContainerRef);

  const classes = useMessageBoxStyles({
    isError,
    shouldBeOnTop: displayAfterParent && shouldBeOnTop,
    fitContent,
  });

  return (
    <div
      style={{ height: customHeight }}
      ref={elementRef}
      className={clsx(
        classes.box,
        {
          [classes.displayAfterParent]: displayAfterParent,
        },
        className,
        {
          [classNameOnTop || '']: shouldBeOnTop,
        }
      )}
    >
      {message}
    </div>
  );
};

export default MessageBox;
