import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseProgressStylesProps = {
  iconSize?: string | number;
};

const useProgressStyle = createUseStyles((theme: ThemeState) => {
  return {
    progressWrapper: ({ iconSize }: UseProgressStylesProps) => ({
      display: 'flex',
      width: iconSize,
      '&:hover > div:first-child': {
        display: 'none',
      },
    }),
    progress: {
      width: '100%',
      textAlign: 'center',
      margin: 'auto',
      '& .ant-progress-bg': {
        backgroundColor: theme['primary-color'],
      },
      '&.ant-progress-status-exception .ant-progress-bg': {
        backgroundColor: theme['error-color'],
      },
      '&.ant-progress-status-exception .ant-progress-text': {
        color: theme['error-color'],
      },
      '&.ant-progress-status-success .ant-progress-bg': {
        backgroundColor: theme['success-color'],
      },
      '&.ant-progress-status-success .ant-progress-text': {
        color: theme['success-color'],
      },
      '&.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path': {
        stroke: theme['error-color'],
      },
      '& .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path': {
        stroke: theme['primary-color'],
      },
      '&.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path': {
        stroke: theme['success-color'],
      },
    },
    removeButtonBg: {
      width: '100%',
      textAlign: 'center',
      margin: 'auto',
      '& circle': {
        color: theme['placeholder-border-color'],
      },
      '& path': {
        color: theme['secondary-color'],
      },
    },
    buttonWrapper: ({ iconSize }: UseProgressStylesProps) => ({
      width: '100%',
      textAlign: 'center',
      marginTop: 'auto',
      marginBottom: 'auto',
      zIndex: 1,
      height: iconSize,
      '& > button': {
        display: 'none',
      },
      '&:hover > button': {
        display: 'initial',
      },
    }),
    removeButton: {
      padding: 0,
      background: 'none',
      border: 'none',
      '&:focus': {
        outline: 'none',
      },
      '& circle': {
        color: theme['placeholder-border-color'],
      },
      '& path': {
        color: theme['secondary-color'],
      },
    },
  };
});

export default useProgressStyle;
