import React, { useEffect, useRef } from 'react';
import UserAvatar from 'components/UserAvatar';
import BoldedPartOfText from 'components/CustomCell/BoldedPartOfText';
import useOptionAvatarStyles from './styles';
import { OptionAvatarProps } from './types';
import {
  OPTION_AVATAR_BOTTOM_TEXT,
  OPTION_AVATAR_DISABLED_TEXT,
  OPTION_AVATAR_TEXT_WRAPPER,
} from 'utils/testIds';
import clsx from 'clsx';

const OptionAvatar = ({
  option: {
    id,
    company_name: company,
    first_name: firstName,
    last_name: lastName,
    username: userEmail,
    account_type: accountType,
  },
  searchValue,
  disabledText,
  disabled,
  selected,
}: OptionAvatarProps) => {
  const classes = useOptionAvatarStyles({});
  const optionTextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // optionTextRef.current.parentElement.parentElement === .ant-select-item-option
    if (optionTextRef.current?.parentElement?.parentElement?.removeAttribute)
      optionTextRef.current.parentElement.parentElement.removeAttribute(
        'title'
      );
  }, []);

  return (
    <>
      <UserAvatar
        {...{ firstName, lastName, id, accountType, disabled }}
        size='large'
        disablePopover
      />
      <div
        ref={optionTextRef}
        className={clsx(classes.textWrapper, {
          [classes.highlightText]: selected,
        })}
        data-testid={OPTION_AVATAR_TEXT_WRAPPER}
      >
        <BoldedPartOfText
          value={`${firstName} ${lastName}`}
          noBoldedValue={company && ` (${company})`}
          boldPhrase={searchValue}
        />
        <div
          className={clsx(classes.bottomText, {
            [classes.disabledBottomText]: disabled,
            [classes.highlightText]: selected,
          })}
          data-testid={OPTION_AVATAR_BOTTOM_TEXT}
        >
          {!!userEmail && (
            <BoldedPartOfText value={userEmail} boldPhrase={searchValue} />
          )}
          {!!disabledText && (
            <span
              className={classes.disabledText}
              data-testid={OPTION_AVATAR_DISABLED_TEXT}
            >
              {disabledText}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default OptionAvatar;
