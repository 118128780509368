import React from 'react';
import { Space } from 'components/lib/Space';
import useUserIdentificationStyles from './styles';
import { USER_NAME_TESTID } from 'utils/testIds';
import UserAvatar from 'components/UserAvatar';
import { useSelector } from 'react-redux';
import { getUserDetails } from 'store/selectors/authSelectors';

const IdentificationItem: React.FC = ({ children }) => {
  const classes = useUserIdentificationStyles({});

  return (
    <p className={classes.item} data-testid={USER_NAME_TESTID}>
      {children}
    </p>
  );
};

const UserIdentification: React.FC = () => {
  const userDetails = useSelector(getUserDetails);
  const classes = useUserIdentificationStyles({});

  if (!userDetails) return null;

  const { first_name: firstName, last_name: lastName, id } = userDetails;

  return (
    <Space className={classes.wrapper}>
      <UserAvatar disablePopover {...{ firstName, lastName, id }} />
      <IdentificationItem>
        <span className={classes.namePart}>{firstName}</span>
        <span className={classes.namePart}>{lastName}</span>
      </IdentificationItem>
    </Space>
  );
};

export default UserIdentification;
