import { StatusCodes } from 'http-status-codes';
import { useCallback, useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import {
  getUserGroupPermissionSetAssigneesGroups,
  getUserGroupPermissionSetAssigneesGroupsConfiguration,
  setUserGroupPermissionsGroupOwnersIsRemovingHimself,
  setUserGroupPermissionSetAssigneesGroupsFetching,
  setUserGroupSelectedPermissionSet,
} from 'store/actions/userGroupPermissionsActions';
import {
  getUserGroupPermissionSetAssigneesGroupsAvatars,
  getUserGroupPermissionSetAssigneesGroupsCount,
  getUserGroupPermissionSetAssigneesGroupsItemsLimit,
  getUserGroupPermissionsGetSelectedPermissionSet,
} from 'store/selectors/userGroupPermissionsSelectors';
import { apiCall } from 'utils/api';
import { USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS } from 'utils/endpoints';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import errorToast from 'utils/functions/errorToast';
import { OwnersMode } from 'pages/ObjectClasses/components/ObjectClassForm/ObjectClassPermissions/components/AddUsersPanel/types';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import { AssigneeToDeleteType } from './types';
import { AvatarItem } from 'components/lib/Avatar/types';
import { getUserDetails } from 'store/selectors/authSelectors';

const useManageAssigneesPanel = () => {
  const dispatch = useDispatch();
  const { id: groupId = '0' } = useParams<{ id: string }>();
  const selectedPermissionSet = useSelector(
    getUserGroupPermissionsGetSelectedPermissionSet
  );

  const userId = useSelector(getUserDetails)?.id;

  const [assigneeToDelete, setAssigneeToDelete] = useState<
    AssigneeToDeleteType | undefined
  >(undefined);

  const permissionSetId = selectedPermissionSet?.id?.toString() || '';

  const assigneesAvatars =
    useSelector(
      getUserGroupPermissionSetAssigneesGroupsAvatars(permissionSetId)
    ) || [];

  useEffect(() => {
    if (groupId !== undefined && permissionSetId.length > 0) {
      dispatch(
        getUserGroupPermissionSetAssigneesGroupsConfiguration(
          groupId,
          permissionSetId
        )
      );
    }
  }, [dispatch, groupId, permissionSetId]);

  const { closePanelIfOpen } = useFlexLayoutContext();

  const handleCancel = () => {
    closePanelIfOpen(FlexLayoutWindows.UserGroupPermissionSetAssignees);
    dispatch(setUserGroupSelectedPermissionSet(undefined));
  };

  const limitAssigneesItems = useSelector(
    getUserGroupPermissionSetAssigneesGroupsItemsLimit(permissionSetId)
  );

  const assigneesCount =
    useSelector(
      getUserGroupPermissionSetAssigneesGroupsCount(permissionSetId)
    ) || 0;

  const isLimitExceeded =
    limitAssigneesItems !== undefined && assigneesCount >= limitAssigneesItems;

  const openAddOwnersPanel = useCallback(
    () =>
      dispatch(
        setSidebarData(FlexLayoutWindows.UserGroupPermissionSetAssignees, {
          mode: OwnersMode.Adding,
        })
      ),
    [dispatch]
  );

  const removePermissionSetAssignee = useCallback(async () => {
    dispatch(
      setUserGroupPermissionSetAssigneesGroupsFetching(permissionSetId, true)
    );

    try {
      const { status } = await apiCall.request({
        method: 'delete',
        url: generatePath(USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS, {
          groupId,
          permissionSetId,
        }),
        data: [assigneeToDelete?.id],
      });

      if (status === StatusCodes.NO_CONTENT) {
        await dispatch(
          getUserGroupPermissionSetAssigneesGroups(groupId, permissionSetId)
        );
      }
    } catch (e) {
      errorToast();
    } finally {
      dispatch(
        setUserGroupPermissionSetAssigneesGroupsFetching(permissionSetId, false)
      );
    }
  }, [assigneeToDelete, dispatch, groupId, permissionSetId]);

  const onDeleteFabric = (avatar: AvatarItem) => async () => {
    const { id, userGroup, firstName, lastName } = avatar;

    dispatch(
      setUserGroupPermissionsGroupOwnersIsRemovingHimself(userId === id)
    );

    setAssigneeToDelete({
      permissionSetId,
      id,
      displayName: userGroup ? userGroup : `${firstName} ${lastName}`,
    });
  };

  return {
    handleCancel,
    assigneesCount,
    limitAssigneesItems,
    isLimitExceeded,
    assigneesAvatars,
    selectedPermissionSet,
    openAddOwnersPanel,
    onDeleteFabric,
    groupId,
    assigneeToDelete,
    setAssigneeToDelete,
    removePermissionSetAssignee,
  };
};

export default useManageAssigneesPanel;
