import { createUseStyles } from 'react-jss';

export const useActionButtonStyles = createUseStyles({
  button: {
    margin: '0 10px',
    height: 29,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});
