import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  SET_CLASS_FORMS,
  SET_CLASS_FORMS_FETCHING,
  APPEND_CLASS_FORMS,
  RESET_CLASS_FORMS,
  SET_CLASS_FORMS_COLUMNS,
  RESET_CLASS_FORMS_COLUMNS,
  SET_CLASS_FORMS_SELECTED_ROW,
  SET_CLASS_FORMS_RESTRICTIONS,
} from 'store/constants/classForms.consts';
import {
  appendTableContent,
  resetTable,
  setTableContent,
  setTableLoading,
  resetTableColumns,
  setTableColumns,
  setSelectedRow,
} from './tablesCommonStateModificatorsUuid';
import { ClassForm } from 'utils/types/api/classForms.types';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import { CommonTableState } from 'utils/types/api/table.types';
import cloneDeep from 'lodash/cloneDeep';

const initialState = cloneDeep(INITIAL_TABLE_STATE);
const classForms: Reducer<CommonTableState<ClassForm>, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_CLASS_FORMS: {
      return setTableContent<ClassForm, typeof SET_CLASS_FORMS>(state, action);
    }
    case APPEND_CLASS_FORMS:
      return appendTableContent<ClassForm, typeof APPEND_CLASS_FORMS>(
        state,
        action
      );

    case SET_CLASS_FORMS_FETCHING:
      return setTableLoading<ClassForm, typeof SET_CLASS_FORMS_FETCHING>(
        state,
        action
      );

    case RESET_CLASS_FORMS:
      return resetTable<ClassForm>(state, initialState);

    case RESET_CLASS_FORMS_COLUMNS:
      return resetTableColumns<ClassForm>(state, initialState);

    case SET_CLASS_FORMS_COLUMNS:
      return setTableColumns<ClassForm, typeof SET_CLASS_FORMS_COLUMNS>(
        state,
        action
      );
    case SET_CLASS_FORMS_SELECTED_ROW:
      return setSelectedRow<ClassForm, typeof SET_CLASS_FORMS_SELECTED_ROW>(
        state,
        action
      );
    case SET_CLASS_FORMS_RESTRICTIONS:
      return { ...state, restrictions: action.restrictions };
    default:
      return state;
  }
};

export default classForms;
