import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { getRadioStyles } from 'components/lib/Radio/consts';

const useCustomRadioWidgetStyles = createUseStyles((theme: ThemeState) => {
  return {
    radioStyles: {
      '& .ant-radio-group': {
        maxWidth: '100%',

        '& .ant-radio-wrapper': {
          display: 'flex',
          alignItems: 'center',
          '&:not(:last-child)': {
            marginBottom: 10,
          },

          '& > span:not(.ant-radio)': {
            whiteSpace: 'break-spaces',
          },
          '& .ant-radio': {
            marginRight: 5,

            '& .ant-radio-inner': getRadioStyles(theme),
          },
        },
      },
    },
  };
});

export default useCustomRadioWidgetStyles;
