import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { panelWrapper, getIdentifier } from 'styles/panelsStyles';

const useActivityLogStyles = createUseStyles((theme: ThemeState) => {
  return {
    panelWrapper,
    identifier: getIdentifier(theme),
    errorMessage: {
      fontSize: 12,
      color: theme['error-color-light'],
      paddingTop: 16,
    },
    emptyData: {
      marginTop: 67,
    },
  };
});

export default useActivityLogStyles;
