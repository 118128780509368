import { useCallback } from 'react';

const useCursor = () => {
  /*
  We use this callback to change the cursor style to default when user lifts mouse button
  outside of component that has changed the cursor. This one is used in pair wtih "Resizer" component
  "onMouseDown" event see src/components/Table/Resizer.tsx
  */
  const handleResizeCursor = useCallback(() => {
    const bodyStyle = document.body.getAttribute('style');

    if (bodyStyle?.includes('cursor: col-resize')) {
      document.body.setAttribute('style', '');
    }
  }, []);

  return { handleResizeCursor };
};

export default useCursor;
