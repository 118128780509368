import React from 'react';
import { Link } from 'react-router-dom';
import useLayoutStyles from 'components/lib/Layout/styles';
import { SubmenuItemProps } from './types';
import { HorizontalLineIcon } from 'components/Icon';

const SubmenuItem = ({
  message,
  link,
  showLineIcon = false,
}: SubmenuItemProps) => {
  const classes = useLayoutStyles({});

  return (
    <Link className={classes.sideMenuLink} to={link}>
      {showLineIcon && (
        <HorizontalLineIcon
          size={15}
          width={8}
          className={classes.submenuItemLinkIcon}
        />
      )}
      <div className={classes.submenuItemLink}>{message}</div>
    </Link>
  );
};

export default SubmenuItem;
