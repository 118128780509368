import React from 'react';
import InPlaceEditMultiselect from 'components/InPlaceEditControls/InPlaceEditMultiSelect';
import { mapCustomWidgetPropsToBaseInPlaceEditProps } from './utils';
import { Widget } from 'alx-dynamic-form';
import { Provider } from 'react-redux';
import { store } from 'store/store';

const AdaptedInPlaceEditMultiSelect: Widget = widgetProps => {
  const baseInPlaceEditProps = mapCustomWidgetPropsToBaseInPlaceEditProps<
    string[],
    any
  >(widgetProps);

  const { options, minValues, maxValues } = widgetProps;

  return (
    <Provider store={store}>
      <InPlaceEditMultiselect
        {...{ ...baseInPlaceEditProps, options }}
        minSelected={minValues}
        maxSelected={maxValues}
        withUnderline
      />
    </Provider>
  );
};

export default AdaptedInPlaceEditMultiSelect;
