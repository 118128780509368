import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useFileListStyles = createUseStyles((theme: ThemeState) => ({
  fileList: {
    display: 'grid',
    gap: '4px',

    maxWidth: '700px',
    padding: 0,
    margin: 0,

    listStyle: 'none',
  },
  fileItem: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr min-content',
    gap: '8px',

    padding: '4px 6px 4px 12px',

    fontSize: '0.75rem',
    backgroundColor: theme['white-color'],

    borderRadius: '4px',
    border: `1px solid ${theme['placeholder-border-color']}`,
  },
}));
