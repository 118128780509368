import React from 'react';
import { Modal as AntModal } from 'antd';

import { ButtonSecondary } from '../Button';
import ButtonType from 'utils/Enums/ButtonType';
import getButtonByType from 'utils/functions/getButtonByType';
import useModalStyles from './styles';

export interface ModalProps {
  /** Whether the dialog is visible */
  visible?: boolean;
  /** Confirm button loading */
  confirmLoading?: boolean;
  /** title */
  title?: React.ReactNode | string;
  /** Whether to show the close button in the upper right corner */
  closable?: boolean;
  /** Click OK to callback */
  onOk?: (e: React.MouseEvent<HTMLElement>) => void;
  /** When user presses cancel, upper right corner cross or hits ESC key */
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
  afterClose?: () => void;
  /** Vertically centered */
  centered?: boolean;
  /** width */
  width?: string | number;
  /** Custom modal footer*/
  footer?: React.ReactNode;
  /** Confirm button text */
  okText?: React.ReactNode;
  /** Confirm button type */
  okType?: ButtonType;
  /** Cancel button type */
  cancelText?: React.ReactNode;
  /** Allow closing modal with outside click */
  maskClosable?: boolean;
  /** Mandatory rendering modal */
  forceRender?: boolean;
  destroyOnClose?: boolean;
  style?: React.CSSProperties;
  wrapClassName?: string;
  maskTransitionName?: string;
  transitionName?: string;
  className?: string;
  getContainer?: string | HTMLElement | false | null;
  zIndex?: number;
  bodyStyle?: React.CSSProperties;
  maskStyle?: React.CSSProperties;
  mask?: boolean;
  keyboard?: boolean;
  wrapProps?: any;
  prefixCls?: string;
  closeIcon?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  confirmLoading,
  onOk,
  onCancel,
  footer,
  okText,
  okType,
  cancelText,
  ...rest
}) => {
  const ConfirmButton = getButtonByType(okType);
  const classes = useModalStyles({});

  return (
    <AntModal
      className={classes.modalWrapper}
      onCancel={onCancel}
      footer={
        !!footer || footer === null
          ? footer
          : [
              <ButtonSecondary key='back' onClick={onCancel}>
                {cancelText || 'Return'}
              </ButtonSecondary>,
              <ConfirmButton
                key='submit'
                loading={confirmLoading}
                onClick={onOk}
              >
                {okText || 'Submit'}
              </ConfirmButton>,
            ]
      }
      {...rest}
    />
  );
};

export { Modal };
