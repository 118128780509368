import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Menu } from 'antd';
import { ButtonPrimary, ButtonSecondary } from 'components/lib/Button';
import { Modal } from 'components/lib/Modal';
import Tooltip from 'components/lib/Tooltip';
import {
  DOCUMENTS_TABLE,
  DOCUMENT_TEMPLATE_FIELD_LIST,
} from 'utils/elementsIds';
import useDocumentTemplatesPanelStyles from '../styles';
import { SaveDocumentModalProps } from '../types';
import { useSaveDocumentModal } from './hooks';
import LoaderOverlay from 'components/LoaderOverlay';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  DOCUMENT_TEMPLATES_PANEL_CANCEL,
  DOCUMENT_TEMPLATES_PANEL,
  DOCUMENT_TEMPLATES_PANEL_SAVE_FIELD,
  DOCUMENT_TEMPLATES_PANEL_SAVE_MODAL,
} from 'utils/testIds';

const SaveDocumentModal = ({
  selectedDocumentId,
  recordId,
  closeModal,
}: SaveDocumentModalProps) => {
  const intl = useIntl();
  const classes = useDocumentTemplatesPanelStyles();
  const [fieldAlias, setFieldAlias] = useState('');
  const {
    documentFields,
    loading,
    saveDocumentTemplateToField,
    fetchNextPage,
    clearDocumentFields,
  } = useSaveDocumentModal(recordId, selectedDocumentId);

  const onCancel = useCallback(() => {
    setFieldAlias('');
    clearDocumentFields();
    closeModal();
  }, [clearDocumentFields, closeModal]);

  const getContainer = document.getElementById(DOCUMENTS_TABLE);
  const handleSubmit = useCallback(async () => {
    try {
      if (fieldAlias) saveDocumentTemplateToField(fieldAlias);
      onCancel();
    } catch {}
  }, [fieldAlias, onCancel, saveDocumentTemplateToField]);

  const maximumMessage = (value: number) => {
    return intl.formatMessage(
      {
        id: 'documentTemplatesPanel.maximumFiles',
        defaultMessage: 'Maximum files ({value}) has been reached',
      },
      { value }
    );
  };

  return (
    <Modal
      className={classes.modal}
      visible={!!selectedDocumentId}
      onCancel={onCancel}
      afterClose={onCancel}
      closable={false}
      wrapProps={{ 'data-testid': DOCUMENT_TEMPLATES_PANEL_SAVE_MODAL }}
      getContainer={getContainer}
      width={280}
      footer={
        <>
          <ButtonSecondary
            onClick={onCancel}
            data-testid={DOCUMENT_TEMPLATES_PANEL_CANCEL}
          >
            <FormattedMessage id='misc.cancel' defaultMessage='Cancel' />
          </ButtonSecondary>
          <ButtonPrimary
            onClick={handleSubmit}
            disabled={!fieldAlias}
            className={classes.submitButton}
            data-testid={DOCUMENT_TEMPLATES_PANEL_SAVE_FIELD}
          >
            <FormattedMessage
              id='misc.selectField'
              defaultMessage='Select field'
            />
          </ButtonPrimary>
        </>
      }
    >
      {loading && <LoaderOverlay />}
      <InfiniteScroll
        next={fetchNextPage}
        hasMore={!!documentFields?.next}
        loader={null}
        dataLength={documentFields?.total_count ?? 0}
        scrollableTarget={DOCUMENT_TEMPLATE_FIELD_LIST}
      >
        <Menu
          className={classes.menu}
          onSelect={e => setFieldAlias(e.key.toString())}
          selectedKeys={[fieldAlias]}
          id={DOCUMENT_TEMPLATE_FIELD_LIST}
        >
          {documentFields?.results.map(
            ({ alias, label, is_available, max_num_of_files }) => (
              <Menu.Item
                key={alias}
                data-testid={`${DOCUMENT_TEMPLATES_PANEL}-${alias}`}
                disabled={!is_available}
              >
                <Tooltip
                  shouldBeRendered={!is_available}
                  mouseEnterDelay={0.1}
                  overlay={maximumMessage(max_num_of_files)}
                >
                  {label}
                </Tooltip>
              </Menu.Item>
            )
          )}
        </Menu>
      </InfiniteScroll>
    </Modal>
  );
};

export default SaveDocumentModal;
