import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useLimitMessageStyles = createUseStyles((theme: ThemeState) => {
  return {
    wrapper: {
      marginTop: 15,
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
    },
    alert: {
      display: 'flex',
      width: '100% !important',
      alignItems: 'center',
      padding: '9px 7px 7px 9px',
      backgroundColor: `${theme['error-bg-light']} !important`,
      '& svg': {
        position: 'initial',
        marginRight: 7,
        color: `${theme['error-color']} !important`,
      },
      '& span.ant-alert-message': {
        color: `${theme['error-color']} !important`,
        fontSize: 11,
        fontWeight: 400,
        lineHeight: 1.36,
      },
    },
  };
});
