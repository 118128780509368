import React from 'react';
import useRequestSentPageStyles from './styles';
import EnvelopeSent from '../../img/envelope-sent.png';
import CardWrapper from '../CardWrapper';
import { RequestSendPageParams } from './types';
import {
  REQUEST_SENT_CARD_SUBTITLE_TESTID,
  REQUEST_SENT_CARD_TITLE_TESTID,
} from 'utils/testIds';

const RequestSentPage: React.FC<RequestSendPageParams> = ({
  title,
  subtitle,
}) => {
  const classes = useRequestSentPageStyles({});

  return (
    <CardWrapper>
      <img
        src={EnvelopeSent}
        className={classes.icon}
        alt='Expired Activation Link'
      />
      <p className={classes.title} data-testid={REQUEST_SENT_CARD_TITLE_TESTID}>
        {title}
      </p>
      <p
        className={classes.subtitle}
        data-testid={REQUEST_SENT_CARD_SUBTITLE_TESTID}
      >
        {subtitle}
      </p>
    </CardWrapper>
  );
};

export default RequestSentPage;
