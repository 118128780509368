import useFormatNumber from 'hooks/useFormatNumber';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReassignTaskHeaderProps } from '../types';
import useReassignTaskStyles from './styles';

const ReassignTaskHeader: React.FC<ReassignTaskHeaderProps> = ({
  recordId = '',
  taskName = '',
  children,
}) => {
  const classes = useReassignTaskStyles({});
  const formatNumberWithSeparators = useFormatNumber();

  return (
    <>
      <div className={classes.headerWrapper}>
        <div className={classes.panelTitle}>
          <FormattedMessage
            id='misc.reassignTask'
            defaultMessage='Reassign task'
          />
        </div>
        <div className={classes.resultsInfo}>
          <FormattedMessage
            id='tasksPanel.recordId'
            defaultMessage='Record ID: {recordId}'
            values={{ recordId: formatNumberWithSeparators(Number(recordId)) }}
          />
        </div>
      </div>
      <div className={classes.nameWrapper}>
        <p className={classes.taskName}>{taskName}</p>
        {children}
      </div>
    </>
  );
};

export default ReassignTaskHeader;
