import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import { useWindowContext } from 'components/lib/FlexLayout/WindowContext';
import { useCallback } from 'react';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';

const useFlexLayoutWindow = (windowName: FlexLayoutWindows) => {
  const {
    flexLayoutMethods: { onAddTab },
    externalWindowsMethods: { checkIfExternalWindowExists = () => false },
    globalMethods: { focusComponent, closeComponent },
    isComponentOpen,
  } = useFlexLayoutContext();

  const { windowRef } = useWindowContext();

  const onOpenTab = useCallback(() => {
    if (isComponentOpen(windowName)) {
      focusComponent(windowName);
      focusComponent(FlexLayoutWindows.MainWindow); //bring focus back to the main window - needed for buttons
      return;
    }
    onAddTab(windowName);
    focusComponent(FlexLayoutWindows.MainWindow); //bring focus back to the main window - needed for buttons
  }, [focusComponent, isComponentOpen, onAddTab, windowName]);

  const isExternal = checkIfExternalWindowExists(windowName);

  return {
    isExternal: Object.keys(windowRef || {}).length > 0 || isExternal,
    onOpenTab,
    closeComponent,
  };
};

export default useFlexLayoutWindow;
