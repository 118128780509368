import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Loader } from 'components/lib/Loader';
import Tooltip from 'components/lib/Tooltip';
import { CloseIcon } from 'components/Icon';
import { cancelDocumentTemplateGeneration } from 'store/actions/documentTemplatesPanelActions';
import {
  DOCUMENT_TEMPLATES_PANEL_CANCEL_ICON,
  DOCUMENT_TEMPLATES_PANEL_LOADER_ICON,
} from 'utils/testIds';
import { useDocumentTemplateStatusStyles } from './styles';
import { DocumentTemplateProcessingProps } from './types';

const DocumentTemplateProcessing = ({
  recordId,
  documentTemplateId,
}: DocumentTemplateProcessingProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useDocumentTemplateStatusStyles();

  const cancelDocumentGenerate = useCallback(() => {
    dispatch(cancelDocumentTemplateGeneration(recordId, documentTemplateId));
  }, [dispatch, documentTemplateId, recordId]);

  return (
    <>
      <Tooltip
        mouseEnterDelay={0}
        overlay={intl.formatMessage({
          id: 'documentTemplatesPanel.processing',
          defaultMessage: 'Processing',
        })}
      >
        <Loader data-testid={DOCUMENT_TEMPLATES_PANEL_LOADER_ICON} />
      </Tooltip>
      <Tooltip
        mouseEnterDelay={0}
        overlay={intl.formatMessage({
          id: 'documentTemplatesPanel.cancelDocumentGeneration',
          defaultMessage: 'Cancel document generation',
        })}
      >
        <CloseIcon
          onClick={cancelDocumentGenerate}
          className={classes.grayIcon}
          size={18}
          data-testid={DOCUMENT_TEMPLATES_PANEL_CANCEL_ICON}
        />
      </Tooltip>
    </>
  );
};

export default DocumentTemplateProcessing;
