import { TaskTypes } from 'pages/TaskTemplates/enums';
import { OptionsDetailsSchema, OptionsResponse } from 'utils/types';
import {
  OptionAutocomplete,
  OptionSelect,
} from 'utils/types/selectInput.types';

export enum TaskFormFields {
  Name = 'name',
  TaskTemplate = 'task_template',
  DueDate = 'due_date',
  Instructions = 'instructions',
  Stage1 = 'stage1',
  TaskType = 'task_type',
}

export enum TaskFormStagesFields {
  Complete = 'complete',
}

export interface StagesOptions {
  limit_assignees_standard: number;
  limit_assignees_one_time_completion: number;
  autocomplete: string;
  required: boolean;
  type: string;
}

export interface TaskSchema {
  [TaskFormFields.Name]: Omit<OptionSelect, 'values'>;
  [TaskFormFields.TaskTemplate]: Omit<OptionSelect, 'values'> &
    OptionAutocomplete;
  [TaskFormFields.DueDate]: Omit<OptionSelect, 'values'>;
  [TaskFormFields.TaskType]: OptionSelect;
}

export type TaskSchemaWithAutocomplete = TaskSchema &
  {
    [key in TaskTypes]: {
      autocomplete: string | undefined;
    };
  };

export interface TaskFormType {
  [TaskFormFields.Name]: string;
  [TaskFormFields.TaskTemplate]?: string;
  [TaskFormFields.DueDate]?: Date;
  [TaskFormFields.Instructions]?: string;
  [TaskFormFields.Stage1]?: string;
  [TaskFormFields.TaskType]: string;
}

export interface TaskFormDataToAPI {
  name: string;
  instructions?: string;
  object_record: string | undefined;
  task_template: number;
  due_date: string | null;
}

export interface TasksOptions extends Omit<OptionsResponse, 'details'> {
  details: {
    schema: OptionsDetailsSchema[];
  };
}

export interface TaskStagesOptions {
  batch: Pick<StagesOptions, 'required' | 'autocomplete' | 'type'>;
  restrictions: Omit<StagesOptions, 'required' | 'autocomplete' | 'type'>;
}

export interface TaskStagesSchema {
  [TaskFormFields.Stage1]: StagesOptions;
}

export type MergedTaskSchema = TaskStagesSchema & TaskSchemaWithAutocomplete;

export interface UseLimitAlertParmas {
  currentAssigneesCount: number;
  limit: number | undefined;
}

export type UseVerifyIfObjectExists = {
  validate: boolean;
  autocompleteUrl: string | undefined;
  replaceValues?: { [key: string]: string };
};
