import React, { useState, useCallback, useEffect } from 'react';
import AddColumnList from './AddColumnList';
import Popover from 'components/lib/Popover';
import { ButtonLink } from 'components/lib/Button';
import { AddColumnIcon } from 'components/Icon';
import Tooltip from 'components/lib/Tooltip';
import { useIntl } from 'react-intl';
import useAddColumnStyles from './styles';
import { useContentWrapperContext } from 'contexts/ContentWrapperContext';
import { AddColumnProps } from './types';
import { ADD_COLUMN_PIN_TESTID } from 'utils/testIds';
import { COLUMNS_LIMIT } from './consts';
import useCurrentTableSupportedColumns from 'hooks/useCurrentTableSupportedColumns';

const AddColumn: React.FC<AddColumnProps> = ({
  hiddenColumns,
  onTogglePopover,
  onAddNewColumns,
  sourceIndex,
  visible,
  indexToAdd,
  defaultPopoverVisible = false,
}) => {
  const [popoverVisible, setPopoverVisible] = useState(defaultPopoverVisible);
  const intl = useIntl();
  const classes = useAddColumnStyles({});
  const { toggleDisabledEdition } = useContentWrapperContext();
  const currentTableColumns = useCurrentTableSupportedColumns();

  useEffect(() => {
    if (popoverVisible) {
      onTogglePopover(sourceIndex);
    } else {
      onTogglePopover(undefined);
    }
  }, [onTogglePopover, popoverVisible, sourceIndex]);

  const onButtonClick = useCallback(() => {
    setPopoverVisible(true);
    toggleDisabledEdition(true);
  }, [toggleDisabledEdition]);

  const hidePopover = useCallback(() => {
    setPopoverVisible(false);
    toggleDisabledEdition(false);
  }, [toggleDisabledEdition]);

  const onAddColumns = useCallback(
    (columns: string[]) => {
      hidePopover();
      onAddNewColumns(columns);
    },
    [hidePopover, onAddNewColumns]
  );

  const buttonColumnAdd = (
    <ButtonLink
      onClick={onButtonClick}
      className={classes.pin}
      id={`pin-${sourceIndex}`}
      data-testid={`${ADD_COLUMN_PIN_TESTID}${sourceIndex}`}
    >
      <AddColumnIcon
        className={
          indexToAdd ? classes.disabledColumnIndicator : classes.columnIndicator
        }
      />
    </ButtonLink>
  );

  const columnsCount =
    Object.keys(currentTableColumns).length - (hiddenColumns || []).length;

  const isColumnLimitReached = columnsCount >= COLUMNS_LIMIT;

  if (!visible || isColumnLimitReached) return null;

  return (
    <div className={classes.popoverWrapper}>
      <Popover
        visible={popoverVisible}
        placement='rightTop'
        content={
          popoverVisible && (
            <AddColumnList
              hiddenColumns={hiddenColumns}
              onCancel={hidePopover}
              onAddColumns={onAddColumns}
            />
          )
        }
      >
        {indexToAdd === undefined ? (
          <Tooltip
            placement='top'
            title={intl.formatMessage({
              id: 'misc.addColumn',
              defaultMessage: 'Add column',
            })}
          >
            {buttonColumnAdd}
          </Tooltip>
        ) : (
          buttonColumnAdd
        )}
      </Popover>
    </div>
  );
};

export default AddColumn;
