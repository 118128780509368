import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  SET_EMAIL_COLLECTORS,
  SET_EMAIL_COLLECTORS_FETCHING,
  APPEND_EMAIL_COLLECTORS,
  RESET_EMAIL_COLLECTORS,
  SET_EMAIL_COLLECTORS_COLUMNS,
  RESET_EMAIL_COLLECTORS_COLUMNS,
  SET_EMAIL_COLLECTORS_SELECTED_ROW,
} from 'store/constants/emailCollectors.consts';
import {
  appendTableContent,
  resetTable,
  setTableContent,
  setTableLoading,
  resetTableColumns,
  setTableColumns,
  setSelectedRow,
} from './tablesCommonStateModificators';
import { EmailCollector } from 'utils/types/api/emailCollectors.types';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import { CommonTableState } from 'utils/types/api/table.types';

import cloneDeep from 'lodash/cloneDeep';
const initialState = cloneDeep(INITIAL_TABLE_STATE);
const emailCollectors: Reducer<CommonTableState<EmailCollector>, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_EMAIL_COLLECTORS: {
      return setTableContent<EmailCollector, typeof SET_EMAIL_COLLECTORS>(
        state,
        action
      );
    }
    case APPEND_EMAIL_COLLECTORS:
      return appendTableContent<EmailCollector, typeof APPEND_EMAIL_COLLECTORS>(
        state,
        action
      );

    case SET_EMAIL_COLLECTORS_FETCHING:
      return setTableLoading<
        EmailCollector,
        typeof SET_EMAIL_COLLECTORS_FETCHING
      >(state, action);

    case RESET_EMAIL_COLLECTORS:
      return resetTable<EmailCollector>(state, initialState);

    case RESET_EMAIL_COLLECTORS_COLUMNS:
      return resetTableColumns<EmailCollector>(state, initialState);

    case SET_EMAIL_COLLECTORS_COLUMNS:
      return setTableColumns<
        EmailCollector,
        typeof SET_EMAIL_COLLECTORS_COLUMNS
      >(state, action);
    case SET_EMAIL_COLLECTORS_SELECTED_ROW:
      return setSelectedRow<
        EmailCollector,
        typeof SET_EMAIL_COLLECTORS_SELECTED_ROW
      >(state, action);
    default:
      return state;
  }
};

export default emailCollectors;
