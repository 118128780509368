import { createUseStyles } from 'react-jss';
import defaultTheme from 'utils/defaultTheme';

const theme = defaultTheme

const useActivateAccountFormStyles = createUseStyles({
  buttonWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  description: {
    fontSize: 12,
    marginTop: 2,
    textAlign: 'justify',
    fontWeight: 400,
    color: theme['disabled-color'],
    display: 'block',
    wordBreak: 'break-word',
  },
});

export default useActivateAccountFormStyles;
