import React from 'react';
import { FormItem } from 'components/lib/Form';
import { Widget } from 'alx-dynamic-form';
import StandardFieldWrapper from 'components/FormPreview2/components/StandardFieldWrapper';
import ExternalComponentWidget from 'components/ExternalComponentWidget';

const CustomExternalComponentWidget: Widget = ({
  description,
  errors,
  passThrough,
  fieldAlias,
  additionalFieldProps,
}) => {
  const classId =
    additionalFieldProps?.classId ?? additionalFieldProps?.objectClassId;
  const objectRecordId = additionalFieldProps?.recordId;

  return (
    <>
      <FormItem
        dynamicSpacing
        validateStatus={errors?.length ? 'error' : undefined}
      >
        <StandardFieldWrapper {...{ description, errors }}>
          <div>
            <div>
              <ExternalComponentWidget
                index_url={passThrough?.externalComponentParams?.index_url}
                name={fieldAlias}
                height={passThrough?.externalComponentParams?.height}
                dynamicParams={
                  passThrough?.externalComponentParams?.dynamicParams ?? []
                }
                classId={classId}
                recordId={objectRecordId}
              />
            </div>
          </div>
        </StandardFieldWrapper>
      </FormItem>
    </>
  );
};

export default CustomExternalComponentWidget;
