import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAccessToken } from '../../store/selectors/authSelectors';
import { useEffect, useState } from 'react';
import routes, { nonProtectedRoutes } from '../../utils/routingPaths';
import { FORGOTTEN_PASSWORD } from '../../utils/endpoints';
import { apiCall } from '../../utils/api';
import { ResetPasswordForm } from './types';
import useValidationSchemaBuilder from '../../hooks/useValidationSchemaBuilder';
import { OptionSelect } from '../../utils/types/selectInput.types';
import { validationObject } from './const';

const useForgotPasswordForm = () => {
  const [initialValues, setInitialForm] = useState<ResetPasswordForm>({
    email: '',
  });
  const {
    rawValidationSchema,
    buildValidationSchema,
  } = useValidationSchemaBuilder<OptionSelect | undefined>(validationObject);
  const [isSuccessResetPassword, setSuccessResetPassword] = useState(false);
  const history = useHistory();
  const token = useSelector(getAccessToken);

  const callback = ({ email }: ResetPasswordForm) => {
    setInitialForm(() => ({ email }));
    setSuccessResetPassword(true);
  };

  const onSubmit = async (values: ResetPasswordForm) => {
    await apiCall
      .post(FORGOTTEN_PASSWORD, values)
      .finally(() => callback(values));
  };

  const navigateToReset = () => {
    history.push(nonProtectedRoutes.FORGOTTEN_PASSWORD);
  };

  useEffect(() => {
    if (!!token) history.replace(routes.HOMEPAGE);
  }, [history, token]);

  return {
    initialValues,
    isSuccessResetPassword,
    rawValidationSchema,
    onSubmit,
    validationSchema: buildValidationSchema(),
    navigateToReset,
  };
};

export default useForgotPasswordForm;
