import { ActivateAccountFormFields } from './enums';

export const ActivateAccountOptions = {
  [ActivateAccountFormFields.Password]: {
    required: true,
  },
  [ActivateAccountFormFields.ConfirmPassword]: {
    required: true,
  },
};
