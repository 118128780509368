import { createUseStyles } from 'react-jss';
import { getInPlaceEditFontSize } from '../components/commonStyles';

type UseInPlaceEditNumberInputStylesProps = {
  size?: 'small' | 'medium';
};

const useInPlaceEditNumberInputStyles = createUseStyles(() => ({
  numberInput: ({ size }: UseInPlaceEditNumberInputStylesProps) => ({
    flex: 1,
    display: 'flex',
    '& .ant-input-number-focused': {
      boxShadow: 'none !important',
    },
    '& .ant-input-number': {
      height: '100%',
      border: 'none',
      '& .ant-input-number-input-wrap': {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      },
      '& input': {
        paddingLeft: 2,
        height: '100%',
        outline: 'none',
        fontSize: getInPlaceEditFontSize(size),
      },
      '&:focus, &:focus-within': {
        boxShadow: 'none',
      },
    },
    '& #arrows-wrapper': {
      width: 20,
      '& > div:first-child': {
        alignItems: 'flex-start',
      },
      '& > div:last-child': {
        alignItems: 'flex-end',
      },
    },
  }),
  messageWrapper: {
    marginTop: 0,
  },
}));

export default useInPlaceEditNumberInputStyles;
